import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';
import {ELASTICSEARCH_PACKAGE_ID} from '../../elasticsearch/shared';
import {ASPIRE_COMPS_COMPETITIONS_PACKAGE_ID} from '../../aspire-comps-competitions/shared';

export const ASPIRE_COMPS_PRIZE_CLAIMING_PACKAGE_ID = 'aspire-comps-prize-claiming';


export class AspireCompsPrizeClaimingPackage extends Package {
    id: string = ASPIRE_COMPS_PRIZE_CLAIMING_PACKAGE_ID;
    name: string = 'Aspire comps prize claiming';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, ASPIRE_COMPS_COMPETITIONS_PACKAGE_ID, ELASTICSEARCH_PACKAGE_ID];

}