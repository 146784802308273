import {injectBunnyHooks} from '../DecoratorsHelper';
import {BunnyElement} from '../../components/bunny-element.ts';

export declare type HasEventListener<P extends string> = {
    [K in P]: (e: Event) => void;
};

export function listen(eventName: string, target?: string | EventTarget): <P extends string, El extends Element & HasEventListener<P>>(proto: El, methodName: P) => void {
    return (proto, methodName) => {
        injectBunnyHooks(proto, 'init', function (this: any) {
            this[methodName] = this[methodName].bind(this);
        });


        if (typeof target === 'string') {
            injectBunnyHooks(proto, 'init', function (this: any) {
                queueMicrotask(() => {
                    queueMicrotask(() => {
                        queueMicrotask(() => {
                            let targetElement = (this as BunnyElement).shadowRoot?.querySelector(`#${target}`);
                            if (!targetElement) {
                                debugger;
                                return;
                            }

                            targetElement.addEventListener(eventName, this[methodName]);
                        });
                    });
                });
            });

        } else {
            injectBunnyHooks(proto, 'connectedCallback', function (this: any) {
                (target || this).addEventListener(eventName, this[methodName]);
            });
            injectBunnyHooks(proto, 'disconnectedCallback', function (this: any) {
                (target || this).removeEventListener(eventName, this[methodName]);
            });
        }
    };
}
