import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

@customElement('component-aspire-comps-guaranteed-draw')
class ComponentAspireCompsGuaranteedDraw extends BunnyElement {

    @property({type: Object})
    drawAt: Date;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                font-weight: bold;
                text-align: center;
            }
        `
    ];

    override render(){
        return html`
            ${this.drawAt ? html`
                Guaranteed Draw ${RenderingHelper._dateFormat(this.drawAt, 'EEEE do MMM')} - Regardless of Ticket Sales!
            ` : undefined}
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-guaranteed-draw': ComponentAspireCompsGuaranteedDraw;
    }
}