import {BaseEventParams, EventType} from '../helpers/TrackingHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {requestIdleCallback} from '../../../__internal/local/helpers/TaskHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {AspireApp} from '../../../../aspire-app';

export abstract class ComponentAnalytics extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Object})
    eventHandlers: { [key in EventType]: (params: any) => void };

    @property({type: Array})
    processEvents: (() => void)[] = [];

    @property({type: Boolean, notify: true})
    isSetup: boolean = false;

    @property({type: String})
    userId: string;

    @property({type: Array})
    delayStartupUntilEvent: string[] | undefined;

    private delayStartupUntilEventCallback: (() => void) | undefined;

    override render() {
        return html`
        `;
    }

    connectedCallback(): void {
        super.connectedCallback();

        requestIdleCallback(() => {
            this._init();
        });
    }

    private async _init() {
        //Dont init any analytics packages during test mode
        if (localStorage['testMode']) return;

        let location = window.location;
        if (location.pathname.startsWith('/admin/competitions/livestream')) return;


        this.eventHandlers = this.generateEventHandlers();


        if (this.delayStartupUntilEvent) {
            await new Promise<void>((s) => {
                this.delayStartupUntilEventCallback = s;
            });
            delete this.delayStartupUntilEventCallback;
            delete this.delayStartupUntilEvent;
        }


        while (!AspireApp.getInstance().finishedLoading) {
            await delayPromise(250);
        }

        await delayPromise(1000);


        this.startup()
            .then(() => {
                this.isSetup = true;
            })
            .catch((e) => {
                console.error('Failed starting analytics component', this, e);
            });
    }

    protected abstract generateEventHandlers(): { [key in EventType]: (params: any) => void };

    protected abstract startup(): Promise<void>;

    protected abstract setUserId(id: string | null, options?: any): void;

    protected abstract setCurrentScreen(id: string, options?: any): void;

    protected logEvent(eventName: EventType, eventParams: BaseEventParams = {}, _options: any = {}) {
        if (this.delayStartupUntilEvent && this.delayStartupUntilEvent.includes(eventName)) {
            //its in the list of delayed start until events, resume the startup
            if (this.delayStartupUntilEventCallback) {
                this.delayStartupUntilEventCallback();
            }

            delete this.delayStartupUntilEvent;
        }


        eventParams.__eventTs = Date.now();
        this.receiveEvent(() => {
            this.eventHandlers[eventName](eventParams);
        });
    }

    protected abstract setUserProperties(properties: object, options?: any): void;

    private isProcessing = false;

    private async doProcessEvents() {
        try {
            if (this.isProcessing) return;
            this.isProcessing = true;

            while (this.processEvents.length) {
                let processEvents = [...this.processEvents];
                this.processEvents = [];


                if ('requestIdleCallback' in window) {
                    await new Promise(_ => requestIdleCallback(_, {timeout: 100}));

                } else {
                    await delayPromise(100);
                }

                for (let processEvent of processEvents) {
                    processEvent();
                }
            }

        } finally {
            this.isProcessing = false;
        }
    }

    @observe('isSetup', 'processEvents')
    onSetup(isSetup: boolean, processEvents: (() => void)[]) {
        if (isSetup && processEvents.length) {
            this.doProcessEvents();
        }
    }

    receiveEvent(event: () => void) {
        this.processEvents.push(event);
        //TODO trigger event
        this.requestUpdate('processEvents');
    }

    @observe('auth')
    onAccountChanged(auth: Auth) {
        this.setUserId(auth.user?.uid || null);
    }

    @listen('track-event', window)
    trackEvents(e: Event) {
        let detail = (e as CustomEvent).detail;
        if (!detail) return;

        this.logEvent(detail.event, detail.params);
    }

}
