import {customElement} from 'lit/decorators.js';
import {html, unsafeStatic} from 'lit/static-html.js';
import {spread} from '@open-wc/lit-helpers';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-input')
export class ComponentInput extends BunnyElement {
    @property({type: Boolean, reflect: true})
    hasValue = false;

    @property({type: String})
    label = '';

    @property({type: String})
    placeholder = '';

    @property({type: String, notify: true})
    value: any = '';

    @property({type: String})
    type: string = 'text';

    get nativeInput(): HTMLInputElement {
        return this.querySelector('input') as HTMLInputElement;
    };

    @property({type: Boolean})
    required: boolean = false;

    @property({type: Boolean, reflect: true})
    disabled: boolean = false;

    @property({type: String})
    name?: string;

    @property({type: String})
    valueAttribute = 'value';

    @property({type: Boolean})
    autofocus = false;

    @property({type: String})
    autocomplete: 'off' | 'on' | 'name' | 'autocomplete' | 'honorific-prefix' | 'given-name' | 'additional-name' | 'family-name' | 'honorific-suffix' | 'nickname' | 'email' | 'username' | 'new-password' | 'current-password' | 'one-time-code' | 'organization-title' | 'organization' | 'street-address' | 'address-line1' | 'address-line2' | 'address-line3' | 'address-level4' | 'address-level3' | 'address-level2' | 'address-level1' | 'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-given-name' | 'cc-additional-name' | 'cc-family-name' | 'cc-number' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' | 'transaction-amount' | 'language' | 'bday' | 'bday-day' | 'bday-month' | 'bday-year' | 'sex' | 'tel' | 'tel-country-code' | 'tel-national' | 'tel-area-code' | 'tel-local' | 'tel-local-prefix' | 'tel-local-suffix' | 'tel-extension' | 'impp' | 'url' | 'photo' | 'webauthn';

    @property({type: String})
    inputmode: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';

    @property({type: String})
    pattern: string;

    @property({type: Boolean})
    invalid: boolean;

    @property({type: String})
    errorMessage: string;

    @property({type: Number})
    minlength: number;

    @property({type: Number})
    maxlength: number;

    @property({type: Object})
    get extraAttributes() {
        return {
            ...(this.pattern ? {
                pattern: this.pattern,
            } : {}),
            ...(this.maxlength ? {
                maxlength: this.maxlength,
            } : {}),
        };
    }

    // language=SCSS
    static override styles = [
        scss`
            :host {
                display: block;
                --placeholder-label-indent: 11px;
                --label-overflow: hidden;
                --input-container-overflow: hidden;
                --input-container-border: solid #a2a7b5 1px;
                --input-container-border-radius: 3px;
                margin-top: 15px;
            }


            .label {
                font-size: 13px;
                transform-origin: top left;
                transform: scale(1.1) translateY(31px) translateX(var(--placeholder-label-indent));
                transition: .125s;
                margin-bottom: 3px;
                margin-left: 5px;
                //position: relative;
                //z-index: 1;
                pointer-events: none;
            }

            :host([has-value]) .label {
                transform: scale(1) translateY(0px);
                pointer-events: all;
            }

            :host(:not([has-value])) label:focus-within .label {
                opacity: .5;
            }

            .required {
                color: red;
            }

            :host([has-value]) {
                .required {
                    display: none;
                }
            }

            .inputContainer {
                border: var(--input-container-border);
                border-radius: var(--input-container-border-radius);
                position: relative;
                overflow: var(--input-container-overflow);
                display: flex;
                flex-direction: var(--input-container-flex-direction);
                align-items: var(--input-container-align-items, center);

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 2px;
                    left: 0;
                    right: 0;
                    transform: scale(0);
                    transform-origin: 15px bottom;
                    opacity: 0;
                    width: 100%;
                    transition: .125s;
                    background: var(--primary-color);
                }

                &:focus-within:after {
                    transform: scale(1);
                    opacity: 1;
                }

                &:focus-within {
                    border-bottom: solid var(--primary-color) 1px;
                }

                ::slotted(input), ::slotted(textarea) {
                    padding: 12px 15px;
                    border: none;
                    display: block;
                    width: 100%;
                    background: none;
                    box-sizing: border-box;
                    flex: 1;
                    font-size: inherit;
                }

                ::slotted(input:focus), ::slotted(textarea:focus) {
                    outline: none;
                }

                slot {
                    display: inline-block;
                }
            }

            :host(.borderBottomOnly) .inputContainer {
                border-top: 0;
                border-left: 0;
                border-right: 0;
            }
        `,
    ];

    renderInput() {
        return html`
            <input id="nativeInput"
                   type="${this.type}"
                   ?autofocus="${this.autofocus}"
                   autocomplete="${this.autocomplete}"
                   .${unsafeStatic(this.valueAttribute)}="${this.convertValueToNative(this.value)}"
                   ${spread(this.extraAttributes)}
                   @input="${this.onInput}"
                   @blur="${(e: FocusEvent) => this.forwardEvent('blur', e)}"
                   @focus="${(e: FocusEvent) => this.forwardEvent('focus', e)}"
                   @change="${this.onInput}"
                   ?required="${this.required}"
                   placeholder="${this.placeholder}"
                   name="${this.name}"
                   ?disabled="${this.disabled}"
                   @invalid="${this.doValidation}"
            >
        `;
    }

    override render() {
        return html`
            <label title="${this.label}${this.required ? ' is required' : ''}"
                   style="overflow: var(--label-overflow); display: block">
                <div class="label">
                    ${this.label}
                    ${this.required ? html`<span class="required">*</span>` : ''}
                </div>
                <div class="inputContainer">
                    <slot name="prefix"></slot>
                    ${this.renderInput()}
                    <slot style="flex: 1"></slot>
                    <slot name="suffix"></slot>
                </div>
            </label>
        `;
    }

    constructor() {
        super();

        requestAnimationFrame(() => {
            let inputNode = this.shadowRoot?.querySelector<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
            if (!inputNode) return;

            this.appendChild(inputNode);
        });
    }

    @observe('value')
    clearValidation() {
        this.invalid = false;
        this.errorMessage = '';
    }

    doValidation() {
        if (!this.value) return;


        if (this.minlength) {
            if (this.value.length < this.minlength) {
                this.invalid = true;
                this.errorMessage = `The minimum length of this field is: ${this.minlength}`;
            }
        }

        if (this.maxlength) {
            if (this.value.length > this.maxlength) {
                this.invalid = true;
                this.errorMessage = `The maximum length of this field is: ${this.maxlength}`;
            }
        }
    }

    convertValueToNative(value: any) {
        return value;
    }

    convertNativeToValue(native: any) {
        return native;
    }

    protected onInput() {
        this.value = this.convertNativeToValue((this.nativeInput as any)[this.valueAttribute]);
    }

    @observe('value', 'placeholder')
    updateHasValue(value: any, placeholder: any) {
        this.hasValue = !!value || !!placeholder;
    }

    forwardEvent(eventName: string, event: Event) {
        if (event instanceof FocusEvent) {
            this.dispatchEvent(new FocusEvent(eventName, {
                composed: true,
                relatedTarget: event.relatedTarget,
            }));

        } else {
            this.dispatchEvent(new CustomEvent(eventName, {
                composed: true,
            }));
        }
    }

    validate() {
        return this.nativeInput.reportValidity();
    }

    selectText() {
        let nativeInput = this.nativeInput;
        nativeInput.select();
        nativeInput.setSelectionRange(0, 99999);
    }

    copyText() {
        this.selectText();
        document.execCommand('copy');
    }

    @observe('value')
    applyDefaultValueOnUndefined(value: any) {
        if (value !== undefined) return;

        this.value = '';
    }


    @observe('errorMessage', 'invalid')
    updateCustomValidity() {
        let message = this.invalid ? this.errorMessage : '';
        this.nativeInput?.setCustomValidity(message);
    }

    @observe('minlength', 'maxlength')
    applyMinMaxLengthToPattern(minlength: number, maxlength: number) {
        if (!minlength && !maxlength) return;

        if (minlength && maxlength) {
            this.pattern = `^.{${minlength},${maxlength}}$`;

        } else if (minlength) {
            this.pattern = `^.{${minlength},}$`;

        } else if (maxlength) {
            this.pattern = `^.{0,${maxlength}}$`;
        }
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'component-input': ComponentInput;
    }
}
