import {DateableDocument, RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {MediaReferenceField} from '../../../media/shared/helpers/FirebaseHelper';

export const FIRESTORE_COLLECTION_COMPETITIONS = 'competitions';
export const FIRESTORE_COLLECTION_COMPETITIONS_SUB_TICKETS = 'tickets';
export const FIRESTORE_COLLECTION_COMPETITION_WINNERS = 'competitionWinners';
export const FIRESTORE_COLLECTION_COMPETITION_CHARITIES = 'competitionCharities';


declare global {
    interface Collections {
        competitions: FirebaseFirestore.CollectionReference<CompetitionDocument>;
        competitionWinners: FirebaseFirestore.CollectionReference<CompetitionWinnerDocument>;
        competitionCharities: FirebaseFirestore.CollectionReference<CompetitionCharityDocument>;
    }
}

export interface CompetitionDatabaseDocumentTicket {
    _key: string;
    created?: number;
    updated?: number;
    ownerId: string;
    orderId: string;
    state: 'pending' | 'assigned' | 'invalid';
    assigned?: number;
    invalidated?: number;
    answer: string;
    number: number;
    orderNumber: number;
    ownerName: string;
    wasOnSale: boolean;
}

export interface CompetitionDatabaseDocument extends DateableDocument {
    _key: string;
    nextTicketNumber: number;
    tickets: { [key: string]: CompetitionDatabaseDocumentTicket };
}

export type CompetitionType = 'livestream' | 'offline' | 'autoDraw';

export interface CompetitionDocument extends RefferableDocument, DateableDocument {
    name: string;
    drawn?: Date;
    type?: CompetitionType;
    state: 'live' | 'past' | 'draft';
    shopVariant?: FirestoreDocumentReference;
    shopProduct?: FirestoreDocumentReference;
    nextTicketNumber: number;
    correctAnswer?: string;
    flatTicketsSrc?: string;
    totalTickets?: number;
    maxPossibleTickets?: number;
    first10Tickets?: ({
        number: number,
        name: string,
        orderId?: number,
        owner: string,
    } | null | undefined)[];
    winningTickets?: {
        number: number,
        name: string,
        orderId?: number,
        owner: string,
    }[];
    invalidatedTickets?: number[];
    finished?: boolean | null;
    sort: number;
    minTicketNumber?: number;
    maxTicketNumber?: number;
    rippleWinners?: Record<string, {
        textContent: string;
        value?: number;
        class: string;
        style: string;
    }>;
}

export interface Location {
    search?: string;
    formattedAddress?: string;
    address?: string;
    city?: string;
    country?: string;
    state?: string;
    name?: string;
    postCode?: string;
    route?: string;
    county?: string;
    phoneNumber?: string;
    placeId?: string;
    lat?: number;
    long?: number;
}


export interface CompetitionWinnerDocument extends RefferableDocument, DateableDocument {
    drawn: Date;
    winnerName: string;
    ticketNumber: string;
    productImage?: MediaReferenceField | null;
    productName: string;
    productCategory?: { content: string }[] | null;
    winnerImage?: MediaReferenceField | null;
    winnerQuote?: string | null;
    location?: Location;
    watchLiveLink?: string | null;
    competition?: FirestoreDocumentReference;
    meta?: {
        websiteCreditAutoAssign?: number;
        winnerAccount?: FirestoreDocumentReference
    };
}

export interface CompetitionCharityDocument extends RefferableDocument, DateableDocument {
    charityName: string;
    charityWebsite: string;
    charityImage: MediaReferenceField;
    amountDonated: number;
    description: string;
    suggestedBy: string;
    donationDate: any;
}