export const seededRandom = function (seed: number | string) {
    if (typeof seed === 'string') {
        seed = seed.toLowerCase().replace(/[^a-z0-9]/, '');
        seed = parseInt(seed, 36);
    }


    // Really dumb LCG, using the glibc constants
    if (!Number.isFinite(seed)) throw Error('Math.seededRandom() takes a numeric seed.');
    const a = 1103515245;
    const c = 12345;
    const mod = Math.pow(2, 31) - 1;
    seed = (+seed) % mod;
    seed = (seed * a + c) % mod;
    return (seed & 0x3fffffff) / 0x3fffffff;
};

export const seededRandomColor = function (seed: number | string) {
    if (typeof seed === 'string') {
        seed = seed.toLowerCase().replace(/[^a-z0-9]/, '');
        seed = parseInt(seed, 36);
    }


    return '#' + Math.floor((Math.abs(Math.sin(seed) * 16777215)) % 16777215).toString(16).padStart(6, '0');
};