import {html} from 'lit/static-html.js';
import {spreadProps} from '@open-wc/lit-helpers';
import {render, TemplateResult} from 'lit';
import '../components/component-toast';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper.ts';

let toastContainer: HTMLDivElement;

function getToastContainer() {
    if (!toastContainer) {
        toastContainer = document.body.appendChild(document.createElement('div'));
        toastContainer.id = 'toastContainer';
    }

    return toastContainer;
}

export const showToast = (value?: string | TemplateResult, options?: {
    heading?: string,
    dismissable?: boolean,
    autoDismiss?: number
}) => {
    let toastContainer = getToastContainer();

    let toast = html`
        ${value ? html`
            <component-toast ${options ? spreadProps(options) : undefined}>
                ${value}
            </component-toast>
        ` : undefined}
    `;

    render(undefined, toastContainer);
    render(toast, toastContainer);


    if (value) {
        track('toastShown', {
            content: toastContainer.textContent?.trim() as string,
            path: location.href.replace(location.origin, ''),
        });
    }
};
