import {ReactiveControllerHost} from 'lit';
import {parseQueryString} from '../../../__internal/local/helpers/UrlHelper';
import {BunnyController} from '../../../__internal/local/controllers/BunnyController';
import {InternalLocationChangedEvent} from '../../../__internal/local/helpers/HistoryHelper';

let instance: Route;

interface RouteState {
    path: any;
    query: Record<string, string>;
    data?: any;
    hash?: string;
}

export interface HistoryStatePageScroll {
    height: number,
    x: number,
    y: number,
}

export const HISTORY_STATE_KEY_CURRENT_PAGE_SCROLL = 'routing-currentPageScroll';
export const HISTORY_STATE_KEY_LAST_PAGE_SCROLL = 'routing-lastPageScroll';
export const HISTORY_STATE_KEY_INTERNAL_LOCATION = 'INTERNAL_LOCATION';

// export const HISTORY_STATE_KEY_INTERNAL_LOCATION = 'routing-internalLocation';

const SPECIAL_LINKS = [
    'tel:',
    'mailto:',
    'sms:',
];

export class Route extends BunnyController {

    current: RouteState = {
        path: '',
        query: {},
    };

    previous: RouteState = {
        path: '',
        query: {},
    };

    static getInstance(host?: ReactiveControllerHost) {
        if (!instance) {
            instance = new Route();
        }

        if (host) {
            instance.addHost(host);
        }

        return instance;
    }

    private loadUrl(url: {
        search?: string | Record<string, string>,
        pathname: string,
        hash?: string
    }) {
        this.previous = this.current;
        let search = url.search;
        search ??= '?';
        if (typeof search === 'string') {
            search = search.substring(1);
        }

        this.current = {
            path: url.pathname,
            hash: url.hash,
            query: typeof search === 'string' ? parseQueryString(search) : search,
            data: history.state,
        };

        this.notifyUpdated();
    }

    private loadUrlFromLocation() {
        let historyInternalLocation = (history.state || {})[HISTORY_STATE_KEY_INTERNAL_LOCATION];
        if (historyInternalLocation) {
            this.loadUrl({
                pathname: historyInternalLocation.path,
                search: historyInternalLocation.query,
                hash: historyInternalLocation.hash,
            });

        } else {
            this.loadUrl(location);
        }
    }

    private constructor() {
        super();
        this.loadUrlFromLocation();

        window.addEventListener('internal-location-changed', ((e: CustomEvent<InternalLocationChangedEvent>) => {
            this.loadUrl({
                pathname: e.detail.path,
                search: e.detail.query,
                hash: e.detail.hash,
            });
        }) as any);
        window.addEventListener('location-changed', () => {
            this.loadUrlFromLocation();
        });
        window.addEventListener('hashchange', () => {
            this.loadUrlFromLocation();
        });

        window.addEventListener('click', (e) => {
            if (e.defaultPrevented) return;
            let elements = e.composedPath();

            let link = elements.find(_ => _ instanceof HTMLAnchorElement) as HTMLAnchorElement | undefined;
            if (!link) return;

            if (e.ctrlKey || e.metaKey) return;
            if (link.target) return;

            let linkOrigin = link.origin;
            if (linkOrigin === 'null') {
                //safari returns 'null' instead of '' for links missing a href
                linkOrigin = '';
            }
            if (linkOrigin && linkOrigin !== location.origin) return;

            let href = link.href;

            for (let SPECIAL_LINK of SPECIAL_LINKS) {
                if (href.startsWith(SPECIAL_LINK)) return;
            }

            let data: Record<string, any> = {};
            let method: 'push' | 'replace' = (link as any).method || 'push';
            if ('historyData' in link) {
                data = (link as any).historyData;
            }
            if ('extendHistoryData' in link) {
                data = {...(history.state || {})};//dupe it so that history.state isnt being effeceted
                Object.assign(data, (link as any).extendHistoryData);
            }

            if (!href) {
                //if its going to the current page then store the internal location as it might of already internal redirected
                data[HISTORY_STATE_KEY_INTERNAL_LOCATION] = {
                    hash: this.current.hash,
                    path: this.current.path,
                    query: this.current.query,
                };
            }

            data[HISTORY_STATE_KEY_LAST_PAGE_SCROLL] = {
                height: document.body.scrollHeight,
                x: scrollX,
                y: scrollY,
            } as HistoryStatePageScroll;
            if (method === 'push') {
                let oldData = history.state || {};
                oldData[HISTORY_STATE_KEY_CURRENT_PAGE_SCROLL] = data[HISTORY_STATE_KEY_LAST_PAGE_SCROLL];

                if (!href) {
                    //if its going to the current page then store the internal location as it might of already internal redirected
                    oldData[HISTORY_STATE_KEY_INTERNAL_LOCATION] = data[HISTORY_STATE_KEY_INTERNAL_LOCATION];
                }

                history.replaceState(oldData, '', location.href);
            }

            e.preventDefault();
            history[`${method}State`](data, '', href);
            this.loadUrlFromLocation();
        });

        window.addEventListener('popstate', () => {
            this.loadUrlFromLocation();
        });
    }
}
