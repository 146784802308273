import {customElement} from 'lit/decorators.js';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';


@customElement('component-prize-claiming-messages-close')
class ComponentPrizeClaimingMessagesClose extends ComponentPrizeClaimingMessagesMessage {

    static override styles = [
        ...ComponentPrizeClaimingMessagesMessage.styles,
        // language=SCSS
        scss`
            :host {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: 150px !important;
                margin-bottom: 100px !important;
                background: var(--primary-text-color) !important;
            }
        `,
    ];

}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-close': ComponentPrizeClaimingMessagesClose;
    }
}