import Package from '../../__internal/shared/Package';
import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import {FIRESTORE_EVENTS_PACKAGE_ID} from '../../firestoreEvents/shared';

export const ELASTICSEARCH_PACKAGE_ID = 'elasticsearch';
export const ELASTICSEARCH_TEST_COLLECTION_PAGINATION = 'elasticsearch-test-collection-pagination';
export const ELASTICSEARCH_TEST_COLLECTION_AGGREGATION = 'elasticsearch-test-collection-aggregation';
export const ELASTICSEARCH_TEST_COLLECTION_LIST = 'elasticsearch-test-collection-list';
export const ELASTICSEARCH_TEST_COLLECTION_CONTROL_SEARCH = 'elasticsearch-test-collection-control-search';
export const ELASTICSEARCH_TEST_COLLECTION_QUERY = 'elasticsearch-test-collection-query';


export class ElasticsearchPackage extends Package {
    id: string = ELASTICSEARCH_PACKAGE_ID;
    name: string = 'Elasticsearch';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, FIRESTORE_EVENTS_PACKAGE_ID];
}
