import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {PageContent, PageContentComponent} from '../../shared/helpers/FirestoreHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {html} from 'lit';
import {scss} from '../../../__internal/local/helpers/StyleHelper';

@customElement('component-editor-content')
class ComponentEditorContent extends BunnyElement {

    @property({type: String})
    label: string;

    @property({type: Array, notify: true})
    value: PageContentComponent[];

    @property({type: Object})
    internalValue: PageContent;

    @property({type: Object})
    data: any;

    static override styles = [
        // language=SCSS
        scss`
            *{
                --nothing:1
            }
        `,
    ];

    override render() {
        return html`
            <component-page-editor-content id="editor"
                                           label="${this.label}"
                                           .value="${this.internalValue}"
                                           .data="${this.data}"
                                           .allowLayoutChange="${false}"></component-page-editor-content>
        `;
    }

    @observe('value')
    populateInternalValue(value: PageContentComponent[]) {
        this.internalValue = {
            _layout: 'page-layout-none',
            none: value || [],
        };
    }

    @observe('internalValue')
    populateExternalValue(value: any) {
        this.value = value.none;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-editor-content': ComponentEditorContent;
    }
}