import {customElement} from 'lit/decorators.js';
import {ComponentInput} from './component-input';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper.ts';

@customElement('component-input-date')
export class ComponentInputDate extends ComponentInput {

    @property()
    type = 'date';

    @property()
    valueAttribute = 'valueAsDate';

    @property({type: Object})
    min!: Date;

    @property({type: Object})
    max!: Date;

    @property({type: Object})
    @computed('min', 'max')
    get extraAttributes() {
        return {
            ...(this.min ? {min: RenderingHelper._dateFormat(this.min, 'yyyy-MM-dd')} : undefined),
            ...(this.max ? {max: RenderingHelper._dateFormat(this.max, 'yyyy-MM-dd')} : undefined),
        } as any;
    }

    @observe('value')
    updateHasValue(_value: any) {
        this.hasValue = true;
    }

    @observe('value')
    applyDefaultValueOnUndefined(value: any) {
        if (value instanceof Date && !isNaN(value.getTime())) return;

        this.value = new Date();
    }

    convertValueToNative(value: any): any {
        if (typeof value === 'string') {
            this.value = new Date(value);
            value = this.value;
        }

        return super.convertValueToNative(value);
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'component-input-date': ComponentInputDate;
    }
}
