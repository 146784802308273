import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS} from '../../shared/helpers/FirebaseHelper';
import {collection, doc} from 'firebase/firestore';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';


@customElement('component-shop-admin-order-create-products')
class ComponentShopAdminOrderCreateProducts extends BunnyElement {

    @property({type: Object})
    newProduct = {
        quantity: 1,
        product: null as any,
    };

    @property({type: Object})
    selectProductRenderer = {
        component: 'component-button',
        properties: {
            innerHTML: ':highlightedResult.name:',
        },
    };

    @property({type: Object})
    selectProductValueFormat = {
        document: ':result._ref:',
        product: ':result:',
        content: ':result.name:',
    };

    @property({type: Array, notify: true})
    value: {
        name: string;
        productVariant: any;
        quantity: number;
    }[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            <div class="gridContainer">
                <div style="--col: 8">
                    Name
                </div>
                <div style="--col: 4">
                    Quantity
                </div>

                ${this.value?.map(item=>html`
                    <div style="--col: 8">
                        ${item.name}
                        (${item.productVariant.path})
                    </div>
                    <div style="--col: 4">
                        <component-input-number .value="${item.quantity}" @value-changed="${(e:CustomEvent)=>{
                            item.quantity = e.detail.value;
                            this.requestUpdate('value');
                        }}"></component-input-number>
                    </div>
                `)}
            </div>

            <div class="gridContainer">
                <div style="--col-md: 7; background-color: rgba(32, 32, 32, .1);">
                    <component-input-elasticsearch-select .value="${this.bindDeep('newProduct.product')}"
                                                          .renderer="${this.selectProductRenderer}"
                                                          .valueFormat="${this.selectProductValueFormat}"
                                                          label="Product"
                                                          .searchId="${'searchShopProductsBackend'}"></component-input-elasticsearch-select>
                </div>
                <div style="--col-md: 2; background-color: rgba(32, 32, 32, .1);">
                    <component-input-number label="Quantity"
                                 .value="${this.bindDeep('newProduct.quantity')}"></component-input-number>
                </div>
                <div style="--col-md: 3; background-color: rgba(32, 32, 32, .1); color: white">
                    <component-button
                            style="min-width: 0; width: 100%; margin: 10px"
                            @click="${this.addProduct}">
                        Add
                    </component-button>
                </div>
            </div>

        `;
    }

    async addProduct() {
        let product = this.newProduct.product.product;
        let variant = doc(collection(product._ref, FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS), Object.keys(product.variants)[0]);

        this.value ??= [];
        this.value.push({
            name: product.name,
            productVariant: variant,
            quantity: this.newProduct.quantity,
        });
        this.requestUpdate('value');



        this.newProduct.product = null;
        this.newProduct.quantity = 1;
        this.requestUpdate('newProduct');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-order-create-products': ComponentShopAdminOrderCreateProducts;
    }
}