const DEFAULT_TYPE_DEFAULT = {name: '', description: '', default: false, configurable: false, campaignable: false};
const DEFAULT_TYPE_SYSTEM = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'System',
    description: 'Account information updates, password reset',
    campaignable: true,
};
const DEFAULT_TYPE_SHOP_INFO = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'Order information',
    description: 'Order completions, refunds, and notes',
};
const DEFAULT_TYPE_COMPETITION_INFO = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'Competition information',
    description: 'Your tickets, winner summaries, prize delivery, and live draw ripple wins',
};
const DEFAULT_TYPE_SHOP_NEW_PRODUCTS = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'New competitions',
    description: 'This is sent on a Tuesday morning to inform you of the competitions that are live for the week',
    campaignable: true,
};
const DEFAULT_TYPE_COMPETITION_LIVE_DRAW = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'We\'re going Live',
    description: 'This is sent on a Wednesday evening when the live draws go live on Facebook and YouTube',
};
const DEFAULT_TYPE_COMPETITION_LIVE_RESULTS = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'Live draw results',
    description: 'This is sent on a Thursday morning to inform you about the live draw results',
    campaignable: true,
};
const DEFAULT_TYPE_COMPETITION_MY_RESULTS = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'My competitions results',
    description: 'These are sent when a competition you have entrered has been drawn and the results are available',
};
const DEFAULT_TYPE_SHOP_PRODUCTS_ODDS = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'Live draw competitions ending',
    description: 'This is sent a few hours before a competition closes as long as the odds are better than 75% sold',
    campaignable: true,
};
const DEFAULT_TYPE_SHOP_PRODUCTS_ENDING_TODAY = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'Auto draw comps ending today',
    description: 'All Competitions that end today up until Midnight. Note Auto Draw competitions can sell out sooner. Once sold out the draw automatically takes place',
};
const DEFAULT_TYPE_SITE_MARKETING = {
    ...DEFAULT_TYPE_DEFAULT,
    name: 'General marketing',
    description: 'First alert for free prize giveaways and extra goodies, live draw competitions ending soon',
    campaignable: true,
};


export interface NotificationChannelType {
    default: boolean;
    configurable: boolean;
    name: string;
    description: string;
    campaignable: boolean;
}

export type NotificationChannel = Record<string, NotificationChannelType>;

export const NOTIFICATION_CHANNELS_INFO: Record<string, NotificationChannel> = {
    email: {
        'system': {
            ...DEFAULT_TYPE_SYSTEM,
            default: true,
        },
        'shop-info': {
            ...DEFAULT_TYPE_SHOP_INFO,
            default: true,
        },
        'competition-info': {
            ...DEFAULT_TYPE_COMPETITION_INFO,
            default: true,
        },
        'shop-new-products': {
            ...DEFAULT_TYPE_SHOP_NEW_PRODUCTS,
            default: true,
            configurable: true,
        },
        // 'competition-live-draw': {
        //     ...DEFAULT_TYPE_COMPETITION_LIVE_DRAW,
        //     default: true,
        //     configurable: true,
        // },
        'competition-live-results': {
            ...DEFAULT_TYPE_COMPETITION_LIVE_RESULTS,
            default: true,
            configurable: true,
        },
        'competition-winners': {
            ...DEFAULT_TYPE_COMPETITION_MY_RESULTS,
            default: true,
            configurable: true,
        },
        'shop-products-odds': {
            ...DEFAULT_TYPE_SHOP_PRODUCTS_ODDS,
            configurable: true,
        },
        'shop-products-ending-today': {
            ...DEFAULT_TYPE_SHOP_PRODUCTS_ENDING_TODAY,
            default: false,
            configurable: true,
        },
        'site-marketing': {
            ...DEFAULT_TYPE_SITE_MARKETING,
            default: true,
            configurable: true,
        },
    },
    push: {
        'shop-info': {
            ...DEFAULT_TYPE_SHOP_INFO,
            default: true,
            configurable: true,
        },
        'competition-info': {
            ...DEFAULT_TYPE_COMPETITION_INFO,
            default: true,
            configurable: true,
        },
        'shop-new-products': {
            ...DEFAULT_TYPE_SHOP_NEW_PRODUCTS,
            default: true,
            configurable: true,
        },
        // 'competition-live-draw': {
        //     ...DEFAULT_TYPE_COMPETITION_LIVE_DRAW,
        //     default: true,
        //     configurable: true,
        // },
        'competition-live-results': {
            ...DEFAULT_TYPE_COMPETITION_LIVE_RESULTS,
            default: true,
            configurable: true,
        },
        'competition-winners': {
            ...DEFAULT_TYPE_COMPETITION_MY_RESULTS,
            default: true,
            configurable: true,
        },
        'shop-products-odds': {
            ...DEFAULT_TYPE_SHOP_PRODUCTS_ODDS,
            default: true,
            configurable: true,
        },
        'shop-products-ending-today': {
            ...DEFAULT_TYPE_SHOP_PRODUCTS_ENDING_TODAY,
            default: false,
            configurable: true,
        },
        'site-marketing': {
            ...DEFAULT_TYPE_SITE_MARKETING,
            default: true,
            configurable: true,
        },
    },
    sms: {
        'competition-live-draw': {
            ...DEFAULT_TYPE_COMPETITION_LIVE_DRAW,
            default: true,
            configurable: false,
        },
        'shop-products-odds': {
            ...DEFAULT_TYPE_SHOP_PRODUCTS_ODDS,
            default: true,
            configurable: true,
        },
    },
};