import {prepareFunction} from '../../../__internal/local/helpers/FirebaseFunctionHelper';
import {ShopOrderAddress} from '../../shared/helpers/FirebaseHelper';
import {debouncePromise} from '../../../__internal/shared/helpers/DebounceHelper';
import {ENV} from '../../../../config';

const internalShopCall = async (action: string, args: any) => {
    let OrderCall = await prepareFunction('shop-ordercallv2', ENV === 'local' ? undefined : `${location.origin}/_`);

    let response = await OrderCall({
        action: action,
        args: args,
    });

    return response.data as any;
};


export const shopCall = {
    startCheckout(cartId: string, fallbackOrderId: string, clientMeta?: Record<string, any>) {
        return debouncePromise(`shop-startCheckout-cartId:${cartId}`, async () => {
            return internalShopCall('startCheckout', {
                cartId: cartId,
                fallbackOrderId: fallbackOrderId,
                clientMeta: clientMeta,
            });
        });
    },
    placeOrder(orderId: string, paymentHandler: string, billingContact: ShopOrderAddress, shippingContact: ShopOrderAddress) {
        return debouncePromise(`shop-placeOrder-orderId:${orderId}`, async () => {
            return internalShopCall('placeOrder', {
                orderId: orderId,
                paymentHandler: paymentHandler,
                billingContact: billingContact,
                shippingContact: shippingContact,
            });
        });
    },
    attemptOrderCompletion(orderId: string, queryParams: any) {
        return internalShopCall('attemptOrderCompletion', {
            orderId: orderId,
            queryParams: queryParams,
        });
    },
    fbConversion(orderId: string, browserEventSourceUrl: string) {
        return internalShopCall('fbConversion', {
            orderId: orderId,
            browserEventSourceUrl: browserEventSourceUrl,
        });
    },
    cancelDraftOrder(orderId: string) {
        return internalShopCall('cancelDraftOrder', {
            orderId: orderId,
        });
    },
    async calculateLocalTimeOffset() {
        let fetchTimeStart = Date.now();
        let serverTime = (await internalShopCall('getServerTime', {})).serverTime;

        let fetchTimeDuration = Date.now() - fetchTimeStart;
        let fetchTimeDurationHalf = fetchTimeDuration / 2;

        let localTime = Date.now();
        return (serverTime + fetchTimeDurationHalf) - localTime;
    },
    paymentHandlerCustomCallback(orderId: string, transactionId: string, customCallbackSignature: string, customCallback: string, data: any) {
        return debouncePromise(`shop-paymentHandlerCustomCallback-orderId:${orderId}-transactionId:${transactionId}`, async () => {
            return internalShopCall('paymentHandlerCustomCallback', {
                orderId: orderId,
                transactionId: transactionId,
                customCallback: customCallback,
                customCallbackSignature: customCallbackSignature,
                data: data,
            });
        });
    },
};