import LocalPackage from '../../__internal/local/LocalPackage';
import {ShopPointsPackage} from '../shared';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';
import './components/component-shop-points-side-bar';
import './components/component-shop-points-panel';
import './components/component-shop-points-cart-points-display';
import './components/component-shop-points-admin-user-heading';


export class ShopPointsLocalPackage extends ShopPointsPackage implements LocalPackage {


    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            if (location.pathname === '/__render-element') return;

            e.registerPostComponent({
                component: 'component-shop-points-side-bar',
                properties:{
                    urlBlacklists: [
                        '^/admin',
                        '^/shop/checkout',
                        '^/entry-list',
                    ]
                }
            }, undefined, true);
        },
    };
}
