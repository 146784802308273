import {customElement} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {FriendlyMessage} from '../../shared/helpers/ExceptionHelper';


export interface ConfirmationDialogOptions {
    title?: string;
    body: string | HTMLElement | ((...args: any[]) => string | HTMLElement);
    failButtonText?: string;
    successButtonText?: string;
}

@customElement('component-confirmation-dialog')
export class ComponentConfirmationDialog extends BunnyElement {

    @property({type: Object})
    response: Promise<void>;

    @property({type: Object})
    success: () => void;

    @property({type: Object})
    fail: (e: Error) => void;

    @property({type: String})
    failButtonText: string = 'Cancel';

    @property({type: String})
    successButtonText: string = 'Ok';

    static async showConfirmationDialog(options: ConfirmationDialogOptions, callerParent: BunnyElement, args: any[]) {
        let dialogNode = document.createElement('component-confirmation-dialog') as ComponentConfirmationDialog;
        if (options.title) {
            dialogNode.title = options.title;
        }
        if (options.failButtonText) {
            dialogNode.failButtonText = options.failButtonText;
        }
        if (options.successButtonText) {
            dialogNode.successButtonText = options.successButtonText;
        }

        if (typeof options.body === 'function') {
            options.body = options.body.apply(callerParent, args);
        }

        if (typeof options.body === 'string') {
            dialogNode.textContent = options.body;

        } else {
            dialogNode.appendChild(options.body);
        }


        let parent = document.body;
        parent.append(dialogNode);
        try {
            await dialogNode.response;

        } finally {
            parent.removeChild(dialogNode);
        }
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `];


    override render() {
        return html`
            <component-dialog opened .showCloseButton="${false}" @opened-changed="${(e: CustomEvent) => {
                if (!e.detail.value) {
                    this.doFail();
                }
            }}">
                <h1 slot="heading">
                    ${this.title}
                </h1>

                <slot></slot>

                <component-button slot="footer" @click="${this.doFail}" style=" margin: 0">
                    ${this.failButtonText}
                </component-button>
                <component-button slot="footer" @click="${this.success}" style="margin-left: auto; margin-right: 0">
                    ${this.successButtonText}
                </component-button>
            </component-dialog>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.response = new Promise<void>((s, f) => {
            this.success = s;
            this.fail = f;
        }) as any;
    }

    doFail() {
        this.fail(new FriendlyMessage('User canceled'));
    }
}

if (localStorage['testMode']) {
    (window as any).__ComponentConfirmationDialog = ComponentConfirmationDialog;
}


declare global {
    interface HTMLElementTagNameMap {
        'component-confirmation-dialog': ComponentConfirmationDialog;
    }
}