import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element.ts';
import {sharedStyles} from '../../../../shared-styles.ts';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {NotificationAction} from '../../shared/helpers/FirestoreHelper.ts';

const SMS_SUFFIX = 'AspireSMS.uk/optout';

@customElement('component-input-notification-campaign-channel-overrides')
class ComponentInputNotificationCampaignChannelOverrides extends BunnyElement {

    @property({type: Object, notify: true})
    value: any;

    @property({type: Object})
    defaultValue: any;

    private updateDefaultValueIntervalId: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
            }
        `,
    ];

    override render() {
        let value = this.value;
        let defaultValue = this.defaultValue;

        let smsLength = this.calculateSms(value?.sms, defaultValue).length;
        let pushLength = this.calculatePush(value?.push, defaultValue).length;


        return html`
            <h3>Email</h3>
            ${this.renderEmail()}

            <h3>Sms: ${smsLength}/140 (${Math.ceil(smsLength / 140)})</h3>
            ${this.renderSms()}

            <h3>
                Push:
                ios ${pushLength}/178,
                android-title: ${value?.push?.title?.length || defaultValue?.title?.length}/65},
                android-body: ${value?.push?.body?.length || defaultValue?.body?.length}/240
            </h3>
            ${this.renderPush()}
        `;
    }

    renderEmail() {
        let defaultValue = this.defaultValue;

        return html`
            <component-input
                    label="Title"
                    .value="${this.bindDeep('value.email.title')}"
                    placeholder="${defaultValue?.title}"></component-input>
            <component-input
                    label="Preview text"
                    .value="${this.bindDeep('value.email.meta.emailPreviewText')}"></component-input>
            <component-input-textarea
                    label="Body"
                    .value="${this.bindDeep('value.email.body')}"
                    placeholder="${defaultValue?.body}"></component-input-textarea>
            <component-input-textarea
                    label="Signoff text"
                    .value="${this.bindDeep('value.email.meta.emailSignoff')}"></component-input-textarea>
            <component-input-json
                    label="Actions"
                    .value="${this.bindDeep('value.email.actions')}"
                    .asString="${false}"
                    placeholder="."></component-input-json>
        `;
    }

    renderSms() {
        let defaultValue = this.defaultValue;

        return html`
            <component-input
                    label="Title"
                    .value="${this.bindDeep('value.sms.title')}"
                    placeholder="${defaultValue?.title}"></component-input>
            <component-input-textarea
                    label="Body"
                    .value="${this.bindDeep('value.sms.body')}"
                    style="--input-container-flex-direction: column; --input-container-align-items: normal"
                    placeholder="${defaultValue?.body}">
                <span slot="suffix" style="padding: 15px; display: block">
                    ${this.calculateSmsActions(this.value?.sms?.actions || defaultValue?.actions)
                            .map(_ => html`
                                <div>${_}</div>
                            `)}
                    <br>
                    ${SMS_SUFFIX}
                </span>
            </component-input-textarea>
            <component-input-json
                    label="Actions"
                    .value="${this.bindDeep('value.sms.actions')}"
                    .asString="${false}"
                    placeholder="."></component-input-json>
        `;
    }

    renderPush() {
        let defaultValue = this.defaultValue;

        return html`
            <component-input
                    label="Title"
                    .value="${this.bindDeep('value.push.title')}"
                    maxlength="65"
                    placeholder="${defaultValue?.title}"></component-input>
            <component-input-textarea
                    label="Body"
                    .value="${this.bindDeep('value.push.body')}"
                    maxlength="240"
                    placeholder="${defaultValue?.body}"></component-input-textarea>
            <component-input-json
                    label="Actions"
                    .value="${this.bindDeep('value.push.actions')}"
                    .asString="${false}"
                    placeholder="."></component-input-json>
        `;
    }

    connectedCallback() {
        super.connectedCallback();


        const recursiveNukeReinject = (obj: any)=>{
            for(let i in obj) {
                delete obj[`_reinject_${i}`];

                if(typeof obj[i] === 'object'){
                    recursiveNukeReinject(obj[i]);
                }
            }
        };

        const populateDefaultValue = ()=>{
            recursiveNukeReinject(this.value); //hack work around for flakey reinject and forms
            this.defaultValue = (this as any).__formGroup.value;
            this.requestUpdate('defaultValue');
        };

        this.updateDefaultValueIntervalId = window.setInterval(populateDefaultValue, 1000);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        clearInterval(this.updateDefaultValueIntervalId);
    }

    protected calculateSms(content: any, defaultValue: any) {
        return `${content?.title || defaultValue?.title || ''}
${content?.body || defaultValue?.body || ''}

${this.calculateSmsActions(content?.actions || defaultValue?.actions)
            .join('\n')}

${SMS_SUFFIX}`;
    }

    protected calculateSmsActions(actions?: NotificationAction[]) {
        if (!actions) return [];

        return actions.filter(_ => _.type === 'url')
            .map(_ => ((_.url as string)[0] === '/' ? new URL(_.url as string, location.origin).toString() : (_.url as string)).replace(/https?:\/\//, '').replace(/www\./, ''));
    }

    protected calculatePush(content: any, defaultValue: any) {
        return `${content?.title || defaultValue?.title || ''}
${content?.body || defaultValue?.body || ''}`;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-notification-campaign-channel-overrides': ComponentInputNotificationCampaignChannelOverrides;
    }
}