import {ComponentElasticsearchAdvancedSearchItem} from './component-elasticsearch-advanced-search-item';
import {ComponentInput} from '../../../inputs/local/components/component-input';
import {customElement, query} from 'lit/decorators.js';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';
import {html, unsafeStatic} from 'lit/static-html.js';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';

export type FilterOperator = '==' | '>=' | '<=' | '!=' | '>' | '<';
export type FilterURLArray = [string, FilterOperator, string, boolean]

@customElement('component-elasticsearch-advanced-search-filter')
export class ComponentElasticsearchAdvancedSearchFilter extends ComponentElasticsearchAdvancedSearchItem {

    @query('#input')
    inputElement: ComponentInput;

    @property({type: String})
    value: any = '';

    @query('#textMeasure')
    textMeasureDiv: HTMLDivElement;

    @property({type: String})
    selectedProperty: string;

    @property({type: String})
    operator: FilterOperator = '==';

    @property({type: Boolean})
    isFuzzy: boolean = false;

    @property({type: String})
    @computed('selectedProperty')
    get inputComponent() {
        return this.getInputComponent(this.selectedProperty);
    }

    @computed('operator', 'inputComponent')
    get enableFuzzyButton() {
        return this.operator === '==' && this.inputComponent === 'component-input';
    }

    @computed('isFuzzy', 'enableFuzzyButton')
    get fuzzyButtonStyle() {
        if (!this.enableFuzzyButton) {
            return 'background-color: gray;';
        }

        return this.isFuzzy ? 'background-color: #8affa3;' : '';
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: inline-block;
                position: relative;
            }

            component-input-select, #input {
                display: inline-block;
                padding: 0;
            }

            component-input-select {
                width: 150px;
            }

            #input {
                min-width: 50px;
                vertical-align: bottom;
            }

            component-button {
                display: inline-block;
                vertical-align: middle;
                font-size: 10px;
                padding: 2px 5px;
                font-weight: bold;
                text-transform: none;
                text-align: center;
                min-width: 32px;
            }

            #buttons {
                position: absolute;
                right: 0px;
                top: -10px;
                z-index: 1;
            }

            #buttons > component-button {
                min-width: 10px;
                margin: 0;
                padding: 0;
                background-color: #ff8a8a;
                display: inline-block;
            }

            #textMeasure {
                visibility: hidden;
                position: absolute;
                z-index: -99999;
                top: 0;
                left: 0;
                pointer-events: none;
            }

            #operator {
                border: none;
                padding: 2px;
            }

            #operator:focus-visible {
                outline: none;
            }
        `,
    ];

    override render() {
        let inputComponentTag = unsafeStatic(this.inputComponent ?? 'component-input');

        return html`
            <div id="textMeasure"></div>

            <div id="buttons">
                <component-button id="toggleFuzzyButton" @click="${this.toggleFuzzy}" title="Toggle fuzzy"
                                  style="${this.fuzzyButtonStyle}" ?disabled="${!this.enableFuzzyButton}">~
                </component-button>
                <component-button id="deleteFilterButton" @click="${this.onDelete}" title="Delete filter">X
                </component-button>
            </div>

            <component-input-select label="Search by"
                                    id="propertySelector"
                                    .value="${this.bind.selectedProperty}"
                                    .options="${this.searchComponent.propertySelectOptions}"
            >
            </component-input-select>

            <component-input-select .value="${this.bind.operator}" style="width: 75px;" id="operator">
                <component-input-select-item title="is equal to" .value="${'=='}">=</component-input-select-item>
                <component-input-select-item title="is not equal to" .value="${'!='}">≠</component-input-select-item>
                <component-input-select-item title="is more than or equal to" .value="${'>='}">≥
                </component-input-select-item>
                <component-input-select-item title="is less than or equal to" .value="${'<='}">≤
                </component-input-select-item>
                <component-input-select-item title="is more than" .value="${'>'}">&gt;</component-input-select-item>
                <component-input-select-item title="is less than" .value="${'<'}">&lt;</component-input-select-item>
            </component-input-select>

            <${inputComponentTag} id="input" style="width: 0" label=""
                                  .value="${this.bind.value}">
            </${inputComponentTag}>
        `;
    }

    getInputComponent(selectedProperty: string) {
        return this.searchComponent.properties[selectedProperty]?.inputComponent || 'component-input' as any;
    }

    onDelete() {
        this.parentGroup?.deleteFilter(this);
    }

    @observe('value', 'operator', 'selectedProperty')
    filterChanged() {
        this.searchComponent.updateQuery();
    }

    @observe('value', 'inputComponent')
    setInputBoxWidth() {
        let typeSpecificSpacing: Record<string, number> = {
            'component-input-number': 20,
            'component-input-date': 40,
            'component-input': 0,
        };

        let typeSpace = typeSpecificSpacing[this.inputComponent] || 0;
        let additionalSpace = 30;


        if (this.inputComponent === 'component-input-date') {
            this.textMeasureDiv.innerText = '00/00/0000';

        } else {
            this.textMeasureDiv.innerText = this.value;
        }

        this.inputElement.style.width = `${this.textMeasureDiv.offsetWidth + additionalSpace + typeSpace}px`;
    }

    override buildQuery() {
        if (!this.selectedProperty || (!this.value && this.value !== 0)) {
            return undefined;
        }

        if (this.operator === '==') {
            if (this.isFuzzy) {
                return {
                    'fuzzy': {
                        [this.selectedProperty]: {
                            'value': this.value,
                        },
                    },
                };
            }

            return {
                'match_phrase': {
                    [this.selectedProperty]: this.value,
                },
            };

        } else if (this.operator === '>=') {
            return {
                'range': {
                    [this.selectedProperty]: {
                        'gte': this.value,
                    },
                },
            };

        } else if (this.operator === '<=') {
            return {
                'range': {
                    [this.selectedProperty]: {
                        'lte': this.value,
                    },
                },
            };

        } else if (this.operator === '!=') {
            return {
                'bool': {
                    'must_not': [{
                        'match': {
                            [this.selectedProperty]: this.value,
                        },
                    }],
                },
            };

        } else if (this.operator === '>') {
            return {
                'range': {
                    [this.selectedProperty]: {
                        'gt': this.value,
                    },
                },
            };

        } else if (this.operator === '<') {
            return {
                'range': {
                    [this.selectedProperty]: {
                        'lt': this.value,
                    },
                },
            };
        }

        throw new Error(`Invalid operator "${this.operator}"`);
    }

    override buildUrl(): FilterURLArray | undefined {
        if (!this.selectedProperty || (!this.value && this.value !== 0)) {
            return undefined;
        }

        return [
            this.selectedProperty,
            this.operator,
            this.value,
            this.isFuzzy,
        ];
    }

    loadQuery(filter: FilterURLArray) {
        this.selectedProperty = filter[0];
        this.operator = filter[1];
        this.value = filter[2];
        this.isFuzzy = filter[3];

        let inputComponent = this.getInputComponent(this.selectedProperty);

        if (inputComponent === 'component-input-date') {
            this.value = new Date(this.value);
        }
    }

    toggleFuzzy() {
        this.isFuzzy = !this.isFuzzy;

        this.searchComponent.updateQuery();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-elasticsearch-advanced-search-filter': ComponentElasticsearchAdvancedSearchFilter;
    }
}
