import {customElement} from 'lit/decorators.js';
import '../../../__internal/local/components/component-grid';
import {
    FIRESTORE_COLLECTION_SHOP_PRODUCTS,
    FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS,
    ShopProductDocument,
    ShopProductVariantDocument,
} from '../../shared/helpers/FirebaseHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ShopProductPrice} from '../../shared/helpers/ShopProductPriceHelper';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {FirestoreCollection} from '../../../__internal/local/controllers/FirestoreCollection';
import {storageBoundSessionStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {SHOP_PRODUCT_TYPES} from './component-shop-collection-list-item';
import {config} from '../../../../config';
import {RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';

const LOADING_COMPONENTS = [
    {
        component: 'component-loading',
    },
];

@customElement('component-shop-product')
export class ComponentShopProduct extends BunnyElement {
    @property({type: String})
    productId: string;

    @property({type: Object})
    @computed('productId')
    get product() {
        if (!this.productId) return undefined;

        return new FirestoreDocument<ShopProductDocument>(this, `${FIRESTORE_COLLECTION_SHOP_PRODUCTS}/${this.productId}`, {method: FetchMethod.CACHE_FIRST});
    };

    @property({type: Object})
    @computed('productId')
    get productVariants() {
        if (!this.productId) return undefined;

        return new FirestoreCollection<ShopProductVariantDocument>(this, `${FIRESTORE_COLLECTION_SHOP_PRODUCTS}/${this.productId}/${FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS}`, {method: FetchMethod.CACHE_FIRST});
    };

    @property({type: String, notify: true})
    @storageBoundSessionStorage('shop-product-selected-variant')
    selectedVariantDocPath: string;

    @property({type: Object})
    selectedVariant: ShopProductVariantDocument;

    @property({type: Object})
    @computed('selectedVariant')
    get selectedProductVariantStockLockState() {
        let path = this.selectedVariant?._ref?.path;
        if (!path) return undefined;

        return new FirestoreDocument<{}>(this, `${path}/stockLock/state`, {method: FetchMethod.LIVE});
    }

    @property({type: Object})
    formData: any = {
        quantity: 0,
    };

    @property({type: Array})
    @computed('product')
    get components() {
        if (!this.product?.data) return LOADING_COMPONENTS;

        return SHOP_PRODUCT_TYPES[this.product.data.type].productPageComponents;
    }

    @property({type: Object})
    @computed('product', 'productVariants', 'selectedVariant', 'selectedProductVariantStockLockState', 'formData')
    get componentsGridData() {
        return {
            product: this.product?.data,
            productVariants: this.productVariants?.data,
            selectedVariant: this.selectedVariant,
            selectedProductVariantStockLockState: this.selectedProductVariantStockLockState?.data,
            formData: this.formData,
        };
    }

    private trackedEventForProductId?: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            h1 {
                background: var(--primary-color);
                color: var(--secondary-color);
                text-align: center;
                line-height: 1.1;
                font-size: 24px;
                font-size: calc(16px + 1vw);
                padding: 10px 35px;
                padding: 10px calc(5px + 2vw);
                --angled-indent: 25px;
            }
        `,
    ];

    override render() {
        return html`
            <firestore-preloader handler="shopProduct" resourceId="${this.productId}"></firestore-preloader>
            <div class="gridContainer row" style="max-width: none" @select-variant="${this.onSelectVariant}">
                <div style="margin: 0;" class="titleContainer">
                    <div style="overflow:hidden; ">
                        <h1 empty-loading-alpha empty-loading="              "
                            ?empty-loading-loaded="${this.product?.data?.name}"
                            style="margin: 0">
                            ${this.product?.data?.name}
                        </h1>
                    </div>
                </div>
                <div style="--col-md: 5; --col-lg: 6" class="productMediaContainer">
                    <component-media-view-collection type="default-thumbnail"
                                                     .items="${this.product?.data?.productImages || []}"
                                                     fetch-method="cacheFirst"
                                                     style="transform: translateZ(0)"></component-media-view-collection>
                </div>

                <component-grid .components="${this.components || LOADING_COMPONENTS}"
                                style="--col-md: 7; --col-lg: 6"
                                .data="${this.componentsGridData}"></component-grid>
            </div>
        `;
    }

    @observe('selectedVariant')
    reflectSelectedVariantToSelectedVariantDocPath(selectedVariant: ShopProductVariantDocument) {
        this.selectedVariantDocPath = selectedVariant?._ref?.id as any;
    }

    selectVariant(variant: ShopProductVariantDocument) {
        this.selectedVariant = variant;
        this.formData = {
            quantity: 1,
        };

        // this.notifyPath('componentsGridData.selectedVariant', variant);
    }


    @observe('productVariants')
    onProductVariantsChanged(productVariants: FirestoreCollection<ShopProductVariantDocument>) {
        if (!productVariants?.data) return;

        this.selectVariant(productVariants.data[0]);
    }

    onSelectVariant(e: CustomEvent) {
        this.selectVariant(e.detail.variant);
    }

    //TODO remove this and place with proper firestore routing resolvers/preresolvers
    @observe('product')
    populatePageTitle(product?: FirestoreDocument<ShopProductDocument>) {
        if (!product?.data) return;

        document.title = [
            product?.data?.name,
            config.title,
        ].filter(_ => _).join(' · ');
    }

    @observe('product', 'selectedVariant', 'selectedProductVariantStockLockState')
    trackProductView(product: FirestoreDocument<ShopProductDocument> | undefined, selectedVariant: ShopProductVariantDocument, selectedProductVariantStockLockState: FirestoreDocument<RefferableDocument> | undefined) {
        if (!product?.data || !selectedVariant || !selectedProductVariantStockLockState?.data) return;
        if ((selectedProductVariantStockLockState?.data as any)._metadata.fromCache) return;

        if (this.trackedEventForProductId === this.productId) return;
        this.trackedEventForProductId = this.productId;

        let productPrice = new ShopProductPrice(product.data, selectedVariant, selectedProductVariantStockLockState);

        track('viewContent', {
            type: 'product',
            name: product.data.name,
            id: this.productId,
            value: productPrice.getPrice() / 100,
            currency: 'GBP',
            categories: product.data.category?.map(_ => _.content),
            path: location.href.replace(location.origin, ''),
            image: (product?.data?.productImages || [])[0]?.media?.id as string,
            meta: {
                stock: (selectedProductVariantStockLockState?.data as any)?.stock,
                maxStock: selectedVariant?.maxStock,
                onSale: productPrice.isSale() ? 1 : 0,
                inStock: (selectedProductVariantStockLockState?.data as any)?.stock as number > 0 ? 1 : 0,
            },
        });
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-product': ComponentShopProduct;
    }
}