export const nodeParentSelectorSearch = (node: Element, selector: string): Element | null => {
    do {
        if (node && node.matches && node.matches(selector)) {
            break;
        }
    } while (node && ((node.parentNode && (node = node.parentNode as Element)) || (((node as any).host && (node = (node as any).host)))));


    if (node instanceof HTMLDocument) {
        return null;
    }

    return node;
};

if (localStorage['testMode']) {
    (window as any).__nodeParentSelectorSearch = nodeParentSelectorSearch;
}