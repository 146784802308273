import {
    ComponentElasticsearchAdvancedSearchFilter,
    FilterURLArray,
} from './component-elasticsearch-advanced-search-filter';
import {ComponentElasticsearchAdvancedSearchItem} from './component-elasticsearch-advanced-search-item';
import {html} from 'lit';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {customElement, query} from 'lit/decorators.js';
import {sharedStyles} from '../../../../shared-styles.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';

export type GroupMode = 'AND' | 'OR';
export type GroupURLArray = [GroupMode, (GroupURLArray | FilterURLArray)[]]

@customElement('component-elasticsearch-advanced-search-group')
export class ComponentElasticsearchAdvancedSearchGroup extends ComponentElasticsearchAdvancedSearchItem {

    @property({type: Boolean, attribute: true, reflect: true})
    main: boolean;

    @query('#insertPoint')
    insertPoint: HTMLSpanElement;

    filters: (ComponentElasticsearchAdvancedSearchGroup | ComponentElasticsearchAdvancedSearchFilter | ComponentElasticsearchAdvancedSearchItem)[] = [];

    @property({type: String})
    mode: 'AND' | 'OR' = 'AND';

    @property({type: Boolean})
    displayModeButton: boolean = false;

    @property({type: String})
    @computed('mode')
    get modePrompt() {
        return this.mode === 'AND' ? 'All filters in this group must be true' : 'Only one filter in this group needs to be true';
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;
                display: inline-block;
                margin: 0 10px;
                background-color: #00000007;
                padding: 0 20px;
                border-radius: 500px;
            }

            component-button {
                display: inline-block;
                vertical-align: middle;
                font-size: 10px;
                padding: 2px 5px;
                font-weight: bold;
                text-transform: none;
                text-align: center;
                min-width: 20px;
            }

            .addButton {
                margin: 15px 0;
            }

            #delete {
                min-width: 10px;
                padding: 0;
                position: absolute;
                right: -10px;
                top: -5px;
                background-color: #ff8a8a;
                display: block;
                z-index: 1;
            }

            #background {
                background-color: red;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: none;
                border-radius: 100px;
                opacity: 0.3;
            }

            #delete:hover + #background {
                display: block;
            }

            component-elasticsearch-advanced-search-filter + component-elasticsearch-advanced-search-filter {
                margin-left: 10px;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        `];

    // language=HTML
    override render() {
        return html`
            <div id="background"></div>

            ${!this.main ? html`
                <component-button id="delete" @click="${this.onDelete}" title="Delete group">X</component-button>
            ` : ''}

            ${this.displayModeButton ? html`
                <component-button @click="${this.toggleMode}" id="toggleModeButton" title="${this.modePrompt}">
                    ${this.mode}
                </component-button>
            ` : ''}

            ${this.filters}

            <component-button class="addButton" id="addFilter" @click="${this.addFilter}" title="Add filter">
                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256"
                     xml:space="preserve"><g style="stroke:none;stroke-width:0;stroke-dasharray:none;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;fill:none;fill-rule:nonzero;opacity:1"><path d="M52.537 80.466V45.192L84.53 2.999C85.464 1.768 84.586 0 83.041 0H6.959C5.414 0 4.536 1.768 5.47 2.999l31.994 42.192v43.441a1.365 1.365 0 0 0 2.073 1.167l11.758-7.127a2.581 2.581 0 0 0 1.242-2.206z" style="stroke:none;stroke-width:1;stroke-dasharray:none;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;fill:#000;fill-rule:nonzero;opacity:1" transform="matrix(2.81 0 0 2.81 1.407 1.407)"/></g></svg>
            </component-button>
            <component-button class="addButton" id="addGroup" @click="${this.addGroup}" title="Add group">
                <svg xmlns="http://www.w3.org/2000/svg" width="163.84" height="163.84" viewBox="0 0 122.88 122.88">
                    <path fill="none" stroke="#000" stroke-width="9.6" stroke-linecap="square" stroke-linejoin="bevel"
                          d="M4.8 47.04h28.8v28.8H4.8z"/>
                    <path fill="none" stroke="#000" stroke-width="9.6" stroke-linecap="square" stroke-linejoin="round"
                          d="M54.72 50.64h21.6v21.6h-21.6z"/>
                    <path fill="none" stroke="#000" stroke-width="9.6" stroke-linecap="square" stroke-linejoin="bevel"
                          d="M89.28 4.8h28.8v28.8h-28.8zm0 84.48h28.8v28.8h-28.8z"/>
                    <path fill="none" stroke="#000" stroke-width="7.2" stroke-linecap="square" stroke-miterlimit="2"
                          d="M33.687 61.44h20.365"/>
                    <path fill="none" stroke="#000" stroke-width="7.2" stroke-linecap="square" stroke-linejoin="round"
                          d="M65.28 48.24v-19.8c0-5.5 4.46-9.96 9.96-9.96h11.04M65.34 74.64v19.8c0 5.5 4.46 9.96 9.96 9.96h11.04"/>
                </svg>
            </component-button>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        const url = new URL(window.location.href);
        const urlQuery = url.searchParams.get('query');

        if (urlQuery) {
            this.loadQuery(JSON.parse(urlQuery));
        } else {
            this.addFilter();
        }
    }

    toggleMode() {
        this.mode = this.mode === 'AND' ? 'OR' : 'AND';
        this.searchComponent.updateQuery();
    }

    addFilterItem(element: ComponentElasticsearchAdvancedSearchItem): ComponentElasticsearchAdvancedSearchItem {
        element.searchComponent = this.searchComponent;
        element.parentGroup = this;
        this.filters.push(element);
        this.requestUpdate('filters');

        this.displayModeButton = this.filters.length > 1;

        return element;
    }

    addGroup(): ComponentElasticsearchAdvancedSearchGroup {
        return this.addFilterItem(document.createElement('component-elasticsearch-advanced-search-group') as ComponentElasticsearchAdvancedSearchGroup) as ComponentElasticsearchAdvancedSearchGroup;
    }

    addFilter(): ComponentElasticsearchAdvancedSearchFilter {
        return this.addFilterItem(document.createElement('component-elasticsearch-advanced-search-filter') as ComponentElasticsearchAdvancedSearchFilter) as ComponentElasticsearchAdvancedSearchFilter;
    }

    deleteFilter(filter: ComponentElasticsearchAdvancedSearchGroup | ComponentElasticsearchAdvancedSearchFilter) {
        const elementIndex = this.filters.indexOf(filter);

        if (elementIndex === -1) {
            throw new Error('filter is not part of this group');
        }

        this.filters.splice(elementIndex, 1);
        this.requestUpdate('filters');

        this.displayModeButton = this.filters.length > 1;

        this.searchComponent.updateQuery();
    }

    onDelete() {
        this.parentGroup?.deleteFilter(this);
    }

    override buildQuery() {
        const filters = this.buildFilters();

        if (filters.length === 0) {
            return undefined;
        }

        return {
            'bool': {
                [this.mode === 'OR' ? 'should' : 'must']: this.buildFilters(),
            },
        };
    }

    buildFilters() {
        const filters: any[] = [];
        for (const filter of this.filters) {
            const filterQuery = filter.buildQuery();
            if (!filterQuery) continue;
            filters.push(filterQuery);
        }
        return filters;
    }

    override buildUrl(): GroupURLArray | undefined {
        const filters = this.filters.map<GroupURLArray | FilterURLArray>(_ => _.buildUrl()).filter(_ => _ !== undefined);

        if (filters.length === 0) return undefined;

        return [
            this.mode,
            filters,
        ];
    }

    loadQuery(group: GroupURLArray) {
        this.mode = group[0];

        for (const filter of group[1]) {
            if (filter.length === 2) {
                this.addGroup().loadQuery(filter);
            } else if (filter.length === 4) {
                this.addFilter().loadQuery(filter);
            } else {
                throw new Error(`invalid filter: ${JSON.stringify(filter)}`);
            }
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-elasticsearch-advanced-search-group': ComponentElasticsearchAdvancedSearchGroup;
    }
}
