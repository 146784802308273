import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {storageBoundLocalStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {Auth} from '../../../auth/local/controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-analytics-cookie-banner')
export class ComponentAnalyticsCookieBanner extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Number, notify: true})
    @storageBoundLocalStorage('cookieAcceptance', '0', parseInt)
    cookieAcceptanceTimestamp: number;

    @computed('cookieAcceptanceTimestamp', 'auth')
    @property({type: Boolean, reflect: true})
    get show() {
        return this.cookieAcceptanceTimestamp < Date.now() && !this.auth.user;
    };

    set show(_value: boolean) {
        //ignore, just to have a setter for the attribute
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;

                display: none;
                justify-content: center;
                align-items: center;

                padding: 6px 15px;

                color: white;
                background: var(--primary-text-color);

                font-size: 12px;
                text-align: center;
                z-index: 99;
            }

            :host([show]) {
                display: flex;
            }
        `,
    ];

    override render() {
        return html`
            <p style="margin-right: 15px">
                At Aspire Competitions, we use cookies to ensure that we can give you the best possible experience on
                our website. By continuing to use the website, you agree to our use of cookies. For further information
                on how we use these cookies, <a
                    href="/cookie-policy?utm_source=aspire&utm_medium=cookiebanner&utm_content=please click here">please
                click here</a>.
            </p>
            <component-button style="min-width: 0; flex-shrink: 0; --padding: 5px; min-width: 50px; line-height: 40px"
                              @click="${this.dismiss}">
                <component-icon icon="icons:close"></component-icon>
            </component-button>
        `;
    }

    dismiss() {
        this.cookieAcceptanceTimestamp = Date.now() + 31449600000;
    }

    @observe('route')
    onRouteChange(route: Route) {
        if (!route.previous.path) return;

        this.dismiss();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-cookie-banner': ComponentAnalyticsCookieBanner;
    }
}
