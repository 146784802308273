import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';


@customElement('component-prize-claiming-messages-confirm-bank-details-request')
class ComponentPrizeClaimingMessagesConfirmBankDetailsRequest extends ComponentPrizeClaimingMessagesMessage {

    override render() {
        return html`
            ${super.render()}


            <component-native-form @submit="${this.sendCallback}" class="footer"
                                   style="--loading-inset: -500px; padding-bottom: 0; padding-top: 0">
                <component-input
                        name="accountName"
                        label="Name on account"
                        required
                        .value="${this.bindDeep('item.meta.bankDetails.accountName')}"
                ></component-input>
                <component-input
                        name="accountNumber"
                        label="Bank account number"
                        required
                        placeholder="000000000"
                        .value="${this.bindDeep('item.meta.bankDetails.accountNumber')}"
                ></component-input>
                <component-input
                        name="sortCode"
                        label="Bank sort code"
                        required
                        placeholder="00-00-00"
                        .value="${this.bindDeep('item.meta.bankDetails.sortCode')}"
                ></component-input>

                <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                    Submit
                </button>

                <div slot="submitButton">
                    <component-button raised
                                      style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                        Confirm my bank details
                    </component-button>
                </div>
            </component-native-form>
        `;
    }

    @bind()
    async sendCallback(_e: FormSubmitEvent) {
        this.dispatchEvent(new CustomEvent('sendMessageRequest', {
            detail: {
                newMessage: {
                    type: 'confirmBankDetailsResponse',
                    body: 'My bank details are the following...',
                    meta: {
                        bankDetails: this.item.meta?.bankDetails,
                    },
                },
            },
        }));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-confirm-bank-details-request': ComponentPrizeClaimingMessagesConfirmBankDetailsRequest;
    }
}
