import {customElement} from 'lit/decorators.js';
import {ComponentInputNumber} from './component-input-number';

@customElement('component-input-percentage')
class ComponentInputPercentage extends ComponentInputNumber {
    protected onInput() {
        this.value = this.nativeInput.valueAsNumber / 100;
    }

    convertValueToNative(value: any): any {
        return value * 100;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-percentage': ComponentInputPercentage;
    }
}