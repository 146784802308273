import {customElement} from 'lit/decorators.js';
import {ComponentFirestoreCollectionListItemCell} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {sharedStyles} from '../../../../shared-styles';

@customElement('component-aspire-comps-charity-item')
class ComponentAspireCompsCharityItem extends ComponentFirestoreCollectionListItemCell {

    @property({type: String, reflect: true})
    displayMode: 'condensed' | 'full' = 'full';

    // language=CSS
    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .itemContainer {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding-bottom: 15px;
            }

            :host([display-mode="condensed"]) .itemContainer {
                padding-bottom: 0;
            }

            h4 {
                background: var(--primary-color);
                color: white;
                padding: 10px 15px;
                margin: 0;
            }

            p {
                padding: 0 15px;
                margin-bottom: 0;
            }

            component-media-view-image {
                background-color: #f2f2f2;
                position: relative;
                box-shadow: 0 1px 0 0 rgba(142, 146, 135, .3);
            }
        `,
    ];

    override render() {
        return html`
            <div class="itemContainer">
                <a href="/charities-we-support/${this.item.id}">
                    ${this.displayMode === 'full' ? html`
                        <h4>${this.item._data.charityName}</h4>
                    ` : undefined}

                    <component-media-view-image .media="${this.item._data.charityImage}"
                                                type="default-thumbnail"
                                                fetch-method="cacheFirst"
                                                width="400"
                                                height="300"></component-media-view-image>
                </a>

                ${this.displayMode === 'full' ? html`
                    <p>
                        <strong>Donation</strong> · &pound;${this.item._data.amountDonated}
                        <small>on</small> ${RenderingHelper._dateFormat(this.item._data.donationDate, 'dd/MM/yyyy')}
                    </p>
                    ${this.item._data.suggestedBy ? html`
                        <p>
                            <strong>Suggested by</strong> · <a href="${this.generateWinnersLink(this.item._data.suggestedBy)}"
                                                               class="colorPrimary">${this.item._data.suggestedBy}</a>
                        </p>
                    ` : undefined}
                    <p>
                        ${this.item._data.description}
                    </p>
                    ${this.item._data.charityWebsite ? html`
                        <p>
                            <a href="${this.item._data.charityWebsite}" class="colorPrimary" style="word-break: break-all"
                               target="_blank">
                                ${this.item._data.charityWebsite}
                            </a>
                        </p>
                    ` : undefined}
                ` : undefined}
            </div>
        `;
    }

    generateWinnersLink(winnerName: string) {
        if (['Aspire Competitions', 'Owen', 'Jimmy', 'Owen & Jimmy'].includes(winnerName)) return '/';

        return `/previous-winners?q=${encodeURIComponent(`winnerName:"${winnerName}"`)}`;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-charity-item': ComponentAspireCompsCharityItem;
    }
}