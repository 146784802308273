import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {prepareFunction} from '../../../__internal/local/helpers/FirebaseFunctionHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';

@customElement('component-notifications-do-action')
class ComponentNotificationsDoAction extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    @computed('route')
    get notificationId() {
        return this.route.current.query.notificationId;
    }

    @property({type: String})
    @computed('route')
    get accountId() {
        return this.route.current.query.accountId;
    }

    @property({type: String})
    @computed('route')
    get action() {
        return this.route.current.query.action;
    }

    @property({type: String})
    @computed('route')
    get authlessActionSignature() {
        return this.route.current.query.authlessActionSignature;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <h1>Actioning: ${this.action}</h1>
        `;
    }


    @toastProgressWrapper({
        failedMessage: 'Failed applying action to notification, please try again: {{e}}',
        progressMessage: 'Actioning',
        successMessage: 'Actioned',
    })
    @observe('accountId', 'notificationId', 'action', 'authlessActionSignature')
    async doAction(accountId: string, notificationId: string, action: string, authlessActionSignature: string) {
        if (!accountId || !notificationId || !action || !authlessActionSignature) return;


        let doAction = await prepareFunction('notifications-DoAction');

        let response = await doAction({
            notificationId: notificationId,
            accountId: accountId,
            action: action,
            authlessActionSignature: authlessActionSignature,
        });
        let responseData = response.data as any;


        if (responseData.action === 'redirect') {
            HistoryHelper.redirect(responseData.url);
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-do-action': ComponentNotificationsDoAction;
    }
}