// language=SCSS
import {scss} from './packages/__internal/local/helpers/StyleHelper';

export const sharedStyles = scss`
    :host {
        display: block;
    }

    .gridContainer {
        --grid-columns: 12;
        --col-margin-scale: 5px;
        --col-margin-top: 0;
        --col-margin-bottom: 3;
        --col-margin-left: 0;
        --col-margin-right: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1140px;
        margin: auto;
    }

    .gridContainer > * {
        --col: var(--grid-columns);
        --col-sm: var(--col);
        --col-md: var(--col-sm);
        --col-lg: var(--col-md);
        --col-xl: var(--col-xl);
        --col-current: var(--col);
        padding-left: 15px;
        padding-right: 15px;
        flex-grow: 0;
        box-sizing: border-box;
        width: calc((1 / var(--grid-columns)) * var(--col-current) * 100%);

        /* Grid column spacing */
        margin-top: calc(var(--col-margin-top) * var(--col-margin-scale));
        margin-bottom: calc(var(--col-margin-bottom) * var(--col-margin-scale));
        margin-left: calc(var(--col-margin-left) * var(--col-margin-scale));
        margin-right: calc(var(--col-margin-right) * var(--col-margin-scale));
    }

    .gridContainer.row,
    .gridContainer > * > .gridContainer,
    .gridContainer > * > .gridWrapDebug > .gridContainer {
        margin-left: -15px;
        margin-right: -15px;
    }

    @media (min-width: 576px) {
        .gridContainer > * {
            --col-current: var(--col-sm);
            --col-md: var(--col-sm);
            --col-lg: var(--col-sm);
            --col-xl: var(--col-sm);
        }
    }

    @media (min-width: 768px) {
        .gridContainer > * {
            --col-current: var(--col-md);
            --col-lg: var(--col-md);
            --col-xl: var(--col-md);
        }
    }

    @media (min-width: 992px) {
        .gridContainer > * {
            --col-current: var(--col-lg);
            --col-xl: var(--col-lg);
        }
    }

    @media (min-width: 1200px) {
        .gridContainer > * {
            --col-current: var(--col-xl);
        }
    }


    @media (min-width: 576px) {
        .hidden-sm {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .hidden-md {
            display: none;
        }
    }

    @media (min-width: 992px) {
        .hidden-lg {
            display: none;
        }
    }

    @media (min-width: 1200px) {
        .hidden-xl {
            display: none;
        }
    }

    @media (max-width: 575px) {
        .hidden-sm-down {
            display: none;
        }
    }

    @media (max-width: 767px) {
        .hidden-md-down {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .hidden-lg-down {
            display: none;
        }
    }

    @media (max-width: 1199px) {
        .hidden-xl-down {
            display: none;
        }
    }

    //shared styles
    .gridContainer {
        max-width: 1300px;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 25px;
    }

    h1 {
        margin-bottom: 25px;
    }

    h2 {
        margin-bottom: 20px;
    }

    h3 {
        margin-bottom: 15px;
    }

    h4 {
        margin-bottom: 10px;
    }

    h4 {
        margin-bottom: 5px;
    }

    h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
        margin-top: 0;
    }

    img {
        max-width: 100%;
    }

    a,
    a:hover,
    a:focus {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }


    ul {
        list-style-type: none;
    }

    ul > li + li {
        margin-top: 5px;
    }


    [empty-loading]:not([empty-loading-loaded]) {
        max-width: 100%;
        overflow: hidden;
        font-family: monospace, serif;
    }

    [empty-loading]:not([empty-loading-loaded]) .emptyLoadingHide {
        display: none;
    }

    [empty-loading]:not([empty-loading-loaded]):before {
        background-color: #f9f9f9;
        content: attr(empty-loading);
        white-space: pre;
    }

    [empty-loading][empty-loading-alpha]:not([empty-loading-loaded]):before {
        background-color: rgba(128, 128, 128, .1)
    }

    span[empty-loading]:not([empty-loading-loaded]) {
        display: inline-block;
    }

    [empty-loading][empty-loading-multiline]:not([empty-loading-loaded]):before {
        box-shadow: -2em 2em 0 #f9f9f9, -4em 4em 0 #f9f9f9;
    }

    [empty-loading][empty-loading-alpha][empty-loading-multiline]:not([empty-loading-loaded]):before {
        box-shadow: -2em 2em 0 rgba(249, 249, 249, 0.2), -4em 4em 0 rgba(249, 249, 249, 0.2);
    }

    [empty-loading][empty-loading-multiline]:not([empty-loading-loaded]):after {
        content: ' ';
        height: 4em;
        display: block;
    }

    h1, h2, h3 {
        text-transform: uppercase;
        font-family: "proxima-nova", serif;
    }

    component-button, component-button-toggle {
        font-family: "proxima-nova", Helvetica, Arial, serif;
    }


    @media screen and (min-width: 768px) {
        .angledContainer {
            transform: skewX(25deg) translateX(var(--angled-indent, 15px));
        }
        .angledContainer > *, .angledContainer .unangled {
            transform: skewX(-25deg) translateX(calc(var(--angled-indent, 15px) * -1));
            display: block;
        }
        .angledContainer .continueAngled {
            transform: none;
        }
    }

    @media screen and (max-width: 767px) {
        .angledContainer:not(.forceMobileAngledContainer) .resetCentering {
            margin-left: 0 !important;
        }
    }

    .angledContainer.forceMobileAngledContainer {
        transform: skewX(25deg) translateX(var(--angled-indent, 15px));
    }

    .angledContainer.forceMobileAngledContainer > *, .angledContainer.forceMobileAngledContainer .unangled {
        transform: skewX(-25deg) translateX(calc(var(--angled-indent, 15px) * -1));
        display: block;
    }

    .angledContainer.forceMobileAngledContainer .continueAngled {
        transform: none;
    }

    .colorPrimary {
        color: var(--primary-color);
    }

    .contentContainer a {
        color: var(--primary-color);
    }

    .contentContainer ul {
        list-style: disc;
    }

    .contentStep {
        padding: 25px 15px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 1px 3px 0 rgba(0, 0, 0, .09), 0 1px 1px -1px rgba(0, 0, 0, .13);
        margin-bottom: 2em;
    }

    paper-input, paper-input-form {
        --secondary-text-color: black;
    }

    ul.breadcrumbs {
        margin: 0;
        border-bottom: solid rgba(32, 32, 32, .1) 1px;

        background: var(--primary-text-color);
        color: white;
        padding: 3px 0px;
        box-shadow: 25vw 0 0 var(--primary-text-color), -25vw 0 0 var(--primary-text-color--lighter);

        padding-left: 15px;
        --angled-indent: 0px;
        display: flex;
        overflow: hidden;
        line-height: normal;

        li {
            display: inline-block;
            padding: 7px 0 7px 3px;
            margin: 0 !important;
            flex-shrink: 0;

            &.active span {
                font-weight: bold;
            }

            &.prev {
            }

            &.next {
                opacity: .6;
                pointer-events: none;
            }

            + li::before {
                content: ">";
                display: inline-block;
                margin-left: 3px;
                margin-right: 3px;
                font-size: 50%;
                vertical-align: middle;
            }

            a {
                color: white;
            }
        }

        &.angledContainer > * {
            display: inline-block !important;
        }
    }
`;