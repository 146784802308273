import {PropertyDeclaration} from './decorators/PropertyDecoratorHelper';
import {ElementPropertyObserver} from './decorators/ObserveDecoratorHelper';
import {ElementPropertyComputed} from './decorators/ComputedDecotratorHelper';
import {BunnyElement} from '../components/bunny-element';


export interface ElementConstructor extends Function {
    is?: string;
    properties?: {
        [prop: string]: PropertyDeclaration;
    };
    observers?: ElementPropertyObserver[];
    computeds?: ElementPropertyComputed[];
    _addDeclarativeEventListener?: (target: string | EventTarget, eventName: string, handler: (ev: Event) => void) => void;
}

export interface ElementPrototype extends BunnyElement {
    constructor: ElementConstructor;
}



const BUNNY_HOOKS_NAMESPACE = '_bunnyHooks';
const BUNNY_HOOKS_CACHED_NAMESPACE = '_bunnyHooksCached';

export type HookName = 'init' | 'connectedCallback' | 'disconnectedCallback';

export function injectBunnyHooks(prototype: any, hookName: HookName, hook: () => any) {
    if (!prototype.constructor.hasOwnProperty(BUNNY_HOOKS_NAMESPACE)) {
        Object.defineProperty(prototype.constructor, BUNNY_HOOKS_NAMESPACE, {
            value: {},
        });
    }

    let bunnyHooks = prototype.constructor[BUNNY_HOOKS_NAMESPACE];
    bunnyHooks[hookName] ??= [];

    bunnyHooks[hookName].push(hook);
}

const getBunnyHooks = (object: any) => {
    let objectConstructor = object.constructor;
    if (objectConstructor.hasOwnProperty(BUNNY_HOOKS_CACHED_NAMESPACE)) return objectConstructor[BUNNY_HOOKS_CACHED_NAMESPACE];

    let hooks: Record<string, any[]> | undefined = undefined;
    while ((object = object.__proto__)) {
        let objectConstructor = object.constructor;
        if (objectConstructor === BunnyElement) break;

        if (hooks === undefined) {
            hooks = {};
            objectConstructor[BUNNY_HOOKS_CACHED_NAMESPACE] = hooks;
        }

        let objectLevelHooks = objectConstructor[BUNNY_HOOKS_NAMESPACE];
        for (let hookName in objectLevelHooks) {
            hooks[hookName] ??= [];
            hooks[hookName].push(...objectLevelHooks[hookName]);
        }
    }


    return hooks;
};

export function runBunnyHooks(this: Object, hookName: HookName) {
    let bunnyHooks = getBunnyHooks(this);
    if (!bunnyHooks) return;

    bunnyHooks = bunnyHooks[hookName];
    if (!bunnyHooks) return;


    for (let bunnyHook of bunnyHooks) {
        bunnyHook.apply(this);
    }
}

