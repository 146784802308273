import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-aspire-comps-product-info')
class ComponentAspireCompsProductInfo extends BunnyElement {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                text-align: center;
                font-size: 14px;
                display: inline-flex;
                align-items: center;
            }

            div {
                line-height: 1.2;
            }
        `
    ];

    override render(){
        return html`
            <img src="/images/aspire-image-icon-tickets.png"
                 style="height: 40px; vertical-align: middle">

            <div>
                <slot></slot>
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-product-info': ComponentAspireCompsProductInfo;
    }
}
