import {__InternalPackage} from '../shared';
import LocalPackage from './LocalPackage';
import './components/component-grid';
import './components/component-firestore-collection-list';
import './components/component-firestore-collection-list-header-cell';
import './components/component-firestore-collection-list-item-cell';
import './components/component-firestore-collection-list-control-pagination-limit';
import './pages/page-render-element';
import './components/component-tab-panels';
import './components/component-dialogable-link';
import './components/component-confirmation-dialog';
import './components/component-draggable-grid';
import './components/firestore-preloader';
import './components/component-pwa-install-prompt';
import './components/bunny-file-supplier';
import './components/component-icon';
import './components/component-icon/app';
import './components/component-icon/communication';
import './components/component-icon/icons';
import './components/component-icon/notification';
import './components/component-icon/social';
import './components/component-icon/papinpplc';
import './components/component-progress';
import './components/component-loading';
import './components/component-dialog';
import './components/firebase-storage-file';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from './helpers/SetupAppReadyEvent';
import './tests/testing-components';


export class __InternalLocalPackage extends __InternalPackage implements LocalPackage {

    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            e.registerPreComponent({
                component: 'component-pwa-install-prompt',
            }, 'pwaInstallPrompt');
        },
    };
}