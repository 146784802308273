import {customElement} from 'lit/decorators.js';
import '../../../../shared-styles';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {Route} from '../../../routing/local/controllers/Route';


@customElement('component-menus-item')
export class ComponentMenusItem extends BunnyElement {
    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    href: string;

    @property({type: String})
    icon: string = '';

    @property({type: Boolean, reflect: true})
    @computed('route', 'href')
    get active() {
        if (!this.route || !this.href) return false;

        return this.route.current.path.startsWith(this.href);
    };

    set active(_value: boolean) {
        //ignore, just to have a setter for the attribute
    };

    // @property({type: Object})
    // @globalProvider('route')
    // route: any;


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: inline-block;
                flex-shrink: 0;
                text-align: center;
            }

            a {
                display: block;
            }

            component-button {
                display: block;
                margin: 0;
                min-width: 0;
                background: none;
                color: inherit;
                box-shadow: none;
                text-align: inherit;
            }
        `,
    ];

    override render() {
        return html`
            <a href="${this.href}">
                <component-button>
                    ${this.icon ? html`
                        <component-icon .icon="${this.icon}" style="margin-top: -5px"></component-icon>
                    ` : undefined}

                    <slot></slot>
                </component-button>
            </a>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-menus-item': ComponentMenusItem;
    }
}