import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {html} from 'lit';

const DEBUG: boolean = location.search.includes('__clickDebug=1');

@customElement('component-page-preloader')
class ComponentPagePreloader extends BunnyElement {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        // window.addEventListener('mousedown', this._mouseDown, {passive: true});
        window.addEventListener('touchstart', this._mouseDown, {passive: true});
        if (DEBUG) {
            window.addEventListener('click', this._click, {passive: true});
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        // window.removeEventListener('mousedown', this._mouseDown);
        window.removeEventListener('touchstart', this._mouseDown);
        if (DEBUG) {
            window.removeEventListener('click', this._click);
        }
    }

    @bind()
    _mouseDown(e: MouseEvent|TouchEvent) {
        let link: Element | undefined = (e.composedPath() as Element[]).find(_ => _ instanceof HTMLAnchorElement);
        if (!link) return;

        let origin = (link as HTMLAnchorElement).origin;
        if (origin !== location.origin) return;

        let href = (link as HTMLAnchorElement).href.substr(origin.length);
        console.log(`TODO: preload page resolver: ${href}`);


        if (DEBUG) {
            console.log('did clickly', (e as TouchEvent).touches[0]);
            (link as any).__clickDebug = Date.now();
        }
    }

    @bind()
    _click(e: MouseEvent) {
        console.log('click');
        let link: Element | undefined = (e.composedPath() as Element[]).find(_ => _ instanceof HTMLAnchorElement);
        if (!link) return;

        let clickDebug = (link as any).__clickDebug;
        if (!clickDebug) return;

        delete (link as any).__clickDebug;
        let href = (link as HTMLAnchorElement).href.substr(origin.length);
        console.info(`[Preloader] ${href} preloaded ahead by ${Date.now() - clickDebug}`);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-page-preloader': ComponentPagePreloader;
    }
}