import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-aspire-comps-review-item')
class ComponentAspireCompsReviewItem extends BunnyElement {

    @property({type: String})
    name: string;

    @property({type: String})
    cite: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            figure {
                margin-left: 15px;
                margin-right: 15px;
            }

            blockquote {
                margin-left: 0;
                margin-right: 0;
            }

            blockquote:before, blockquote:after {
                content: '"';
                font-size: 50px;
                color: var(--primary-color);
                line-height: 0;
                vertical-align: middle;
            }

            blockquote:after {
                transform: rotateX(180deg);
                display: inline-block;
            }

            figcaption {
                color: var(--primary-color);
            }

            cite {
                color: var(--primary-text-color)
            }
        `
    ];

    override render(){
        return html`
            <figure>
                <figcaption>
                    <a href="${this.cite}">${this.name}, <cite>Trustpilot</cite></a>
                </figcaption>
                <blockquote cite="${this.cite}">
                    <slot></slot>
                </blockquote>
            </figure>
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-review-item': ComponentAspireCompsReviewItem;
    }
}