import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../components/bunny-element';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {JSONParseLocal} from '../helpers/DataHelper';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper';
import {PageContentComponent} from '../../../routing/shared/helpers/FirestoreHelper';
import {delayPromise} from '../helpers/PromiseHelper';
import {computed} from '../helpers/decorators/ComputedDecotratorHelper.ts';

@customElement('page-render-element')
class PageRenderElement extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Object})
    renderComponent?: PageContentComponent;

    @property({type: String})
    @computed('route')
    get testTitle() {
        return this.route.current.query['testTitle'];
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            Current test: ${this.testTitle || ''}<br>
            Element: <br>
            <div id="element">
                ${this.renderComponent ? createComponent(this.renderComponent, {}) : undefined}
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();


        (window as any).__elementRenderer = this;
    }

    @observe('route')
    loadElement(route: Route) {
        let currentRoute = route.current;
        if (route.current.path !== '/__render-element') return;

        let currentRouteQuery = currentRoute.query;
        let element: string = currentRouteQuery.element;
        if (!element) return;

        this.doRender(element, currentRouteQuery.__reinject_props || currentRouteQuery.props);
    }

    async doRender(element: string, _props: string) {
        let props = JSONParseLocal(_props || '{}');

        if (this.renderComponent) {
            this.renderComponent = undefined;
            await delayPromise();
        }

        this.renderComponent = {
            component: element,
            properties: props,
        };
        await delayPromise();

        (window as any).__renderedElement = this.shadowRoot?.querySelector('#element>*');
        return (window as any).__renderedElement;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'page-render-element': PageRenderElement;
    }
}