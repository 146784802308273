import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-aspire-comps-info-step')
class ComponentAspireCompsInfoStep extends BunnyElement {

    @property({type: Number})
    index: number;

    @property({type: String})
    title: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                text-align: center;
            }
            
            @media screen and (max-width: 767px){
                :host {
                    padding: 0!important;
                }
            }

            .stepIndex {
                display: inline-block;
                height: 40px;
                width: 40px;
                background-color: var(--primary-text-color);
                color: white;
                line-height: 40px;
                border-radius: 100%;
                font-size: 25px;
                font-weight: bold;
            }

            h3 {
                margin-top: 10px;
                margin-bottom: 5px;
                margin-left: 15px;
            }
        `
    ];

    override render(){
        return html`
            ${this.index ? html`
                <span class="stepIndex" style="margin-bottom: 15px">
                    ${this.index}
                </span>
            ` : undefined}
            
            <div class="contentStep" style="margin: 0">
                <h3 style="text-align: center; margin: -25px -15px 25px -15px; background-color: var(--primary-color); color: white; padding: 7px">
                    ${this.title}
                </h3>
                
                <slot></slot>
            </div>
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-info-step': ComponentAspireCompsInfoStep;
    }
}