import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {track} from '../helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';

let visibilityInstanceCount = 0;
let sharedVisibilityObserver: IntersectionObserver;
const getSharedObserver = (): IntersectionObserver => {
    if (!sharedVisibilityObserver) {
        sharedVisibilityObserver = new IntersectionObserver((entries) => {
            for (let entry of entries) {
                ((entry.target as any).__interactableTracking as ComponentTrackingInteractable).interactableVisible = entry.isIntersecting;
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        });
    }

    return sharedVisibilityObserver;
};


const getShadowInfoText = (element: HTMLElement) => {
    let getShadowText = (element: HTMLElement) => {
        const BANNED_NODES = ['svg', 'PICTURE', 'SCRIPT', 'STYLE', '#comment'];
        let text = '';
        for (let child of element.childNodes) {
            if (BANNED_NODES.includes(child.nodeName)) continue;


            if ('title' in child && child.title) {
                text += child.title;
            }

            if ((child as any).shadowRoot) {
                text += getShadowText((child as any).shadowRoot);

            } else if (child instanceof HTMLSlotElement) {
                text += getShadowText({childNodes: child.assignedNodes()} as any);

            } else if (child instanceof Text || !(child as HTMLElement).children.length) {
                let textContent = (child as Text).textContent?.trim();

                text += textContent ? `${textContent}\n` : '';

            } else {
                text += getShadowText(child as HTMLElement);
            }
        }

        return text;
    };

    if (element.shadowRoot) {
        return getShadowText({childNodes: [element]} as any).trim();

    } else {
        return getShadowText(element).trim();
    }
};

const calculateNodeId = (element: HTMLElement) => {
    let parent = element;
    let parentCustomElements = [
        element.nodeName.toLowerCase(),
    ];

    while (parent) {
        parent = parent.parentElement || (parent.getRootNode() as any)?.host;
        if (!parent) continue;
        if (!customElements.get(parent.nodeName.toLowerCase())) continue;

        parentCustomElements.push(parent.nodeName.toLowerCase());
    }


    return parentCustomElements;
};


//TODO create a way to make a interactable id from the page structure/element content

const MIN_VISIBILITY_FOR_REPORT = 500;

@customElement('component-tracking-interactable')
class ComponentTrackingInteractable extends BunnyElement {

    @property({type: Boolean})
    trackClicks = true;

    @property({type: Boolean})
    trackVisibility = true;

    @property({type: Boolean})
    @computed('interactableVisible', 'pageVisible')
    get visible() {
        return this.interactableVisible && this.pageVisible;
    };

    @property({type: Boolean})
    interactableVisible: boolean = false;

    @property({type: Boolean})
    pageVisible: boolean = true;

    @property({type: String})
    type: string;

    @property({type: String})
    content: string;

    @property({type: String})
    contentName: string;

    @property({type: String})
    contentImage: string;

    @property({type: Number})
    contentValue: number;

    @property({type: Object})
    contentMeta: any;

    @property({type: Array})
    tags: string[];

    @property({type: Number})
    visibilityStart: number = 0;

    @property({type: Number})
    visibilityEnd: number = 0;

    private visibilityTrackingTimeoutId: number;

    private visibilityInstanceId: number;

    private parentSelector: string[];

    private pathLastVisible: string;

    protected createRenderRoot() {
        return this;
    }

    protected render() {
        return undefined;
    }

    connectedCallback() {
        this.initLazyLoading();
        super.connectedCallback();

        this.visibilityInstanceId = ++visibilityInstanceCount;
    }

    disconnectedCallback() {
        super.disconnectedCallback();


        //fire off the hidden ones before they are nuked
        this.reportView(this.visibilityStart, Date.now());


        this.deinitLazyLoading();
    }

    getParent() {
        return this.parentElement || (this.getRootNode() as any)?.host;
    }

    getContextInfo() {
        let parent = this.getParent();

        let parentElement = parent;
        let bailCounter = 50;
        while (parentElement && !(parentElement instanceof BunnyElement)) {
            parentElement = parentElement.parentElement || parentElement.getRootNode()?.host;

            if (bailCounter-- === 0) break;
        }

        let parentElementRect = parentElement.getBoundingClientRect();

        return {
            parentTextContent: getShadowInfoText(parent).split('\n')
                .map((_: string) => _.trim())
                .filter((_: string) => _)
                .join('\n'),
            parentSelector: this.parentSelector,
            parent: {
                width: Math.round(parentElementRect.width),
                height: Math.round(parentElementRect.height),
                left: Math.round(parentElementRect.left),
                top: Math.round(parentElementRect.top),
            },
            screen: {
                width: screen.availWidth,
                height: screen.availHeight,
                portrait: window.matchMedia('(orientation: portrait)').matches ? 1 : 0,
            },
        };
    }

    initLazyLoading() {
        let interactElement = this.getParent();
        this.parentSelector = calculateNodeId(interactElement);

        if (this.trackClicks) {
            interactElement.addEventListener('click', this.reportClick);
        }

        if (this.trackVisibility) {
            if ('IntersectionObserver' in window) {
                (interactElement as any).__interactableTracking = this;
                getSharedObserver().observe(interactElement);
            }
        }
    }

    deinitLazyLoading() {
        let interactElement = this.getParent();

        if (this.trackClicks) {
            interactElement.removeEventListener('click', this.reportClick);
        }

        if (this.trackVisibility) {
            if ('IntersectionObserver' in window) {
                delete (interactElement as any).__interactableTracking;
                getSharedObserver().unobserve(interactElement);
            }
        }
    }

    @observe('visible')
    async visibleChanged(visible: boolean) {
        clearTimeout(this.visibilityTrackingTimeoutId);

        if (visible) {
            this.pathLastVisible = location.href.replace(location.origin, '');
            let visibilityStart = Date.now();

            this.visibilityTrackingTimeoutId = window.setTimeout(() => {
                this.visibilityStart = visibilityStart;
            }, MIN_VISIBILITY_FOR_REPORT);

        } else if (!visible && this.visibilityStart) {
            this.visibilityEnd = Date.now();
            await delayPromise();

            this.visibilityStart = 0;
            this.visibilityEnd = 0;
        }
    }

    @bind()
    @listen('visibilitychange', window)
    async pageVisibilityTracker() {
        this.pageVisible = document.visibilityState === 'visible';
    }

    @observe('visibilityStart', 'visibilityEnd')
    reportView(visibilityStart: number, visibilityEnd: number) {
        if (!visibilityStart) return;
        if (!visibilityEnd) return;

        track('interactableView', {
            instanceId: this.visibilityInstanceId,
            type: this.type,
            content: this.content,
            contentName: this.contentName,
            contentImage: this.contentImage,
            contentValue: this.contentValue,
            contentMeta: this.contentMeta,
            tags: this.tags ? this.tags : [],
            duration: visibilityEnd - visibilityStart,
            context: this.getContextInfo(),
            path: this.pathLastVisible || location.href.replace(location.origin, ''),
        });
        //TODO track scroll position
    }

    @bind()
    async reportClick(e: MouseEvent) {
        track('interactableClick', {
            instanceId: this.visibilityInstanceId,
            type: this.type,
            content: this.content,
            contentName: this.contentName,
            contentImage: this.contentImage,
            contentValue: this.contentValue,
            contentMeta: this.contentMeta,
            tags: this.tags ? this.tags : [],
            duration: this.visibilityEnd ? this.visibilityEnd - this.visibilityStart : undefined,
            context: this.getContextInfo(),
            clickInfo: {
                altKey: e.altKey ? 1 : 0,
                ctrlKey: e.ctrlKey ? 1 : 0,
                shiftKey: e.shiftKey ? 1 : 0,
                metaKey: e.metaKey ? 1 : 0,
                button: e.button,
                x: e.clientX,
                y: e.clientY,
                //TODO track click position on element
                //TODO track click position on window
            },
            path: this.pathLastVisible || location.href.replace(location.origin, ''),
        });
        //TODO track scroll position
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-tracking-interactable': ComponentTrackingInteractable;
    }
}