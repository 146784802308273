import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ComponentDevToolsEmulatorLog} from './component-dev-tools-emulator-log';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {storageBoundSessionStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-dev-tools-emulator-log-overlay')
class ComponentDevToolsEmulatorLogOverlay extends BunnyElement {

    @property({type: Boolean, reflect: true})
    open = false;

    @property({type: Boolean, reflect: true})
    updatingServer = false;

    @property({type: Number})
    lastUpdatedServer = 0;

    @property({type: Number})
    @computed('errors')
    get rawErrorsCount() {
        return (this.errors || []).filter(_ => _.level === 'error').length;
    };

    @property({type: Number, reflect: true})
    @computed('rawErrorsCount', 'lastOpenedErrorsCount')
    get errorsCount() {
        return this.rawErrorsCount - this.lastOpenedErrorsCount;
    };

    @property({type: Array})
    errors: any[] = [];

    @property({type: Number, notify: true})
    @storageBoundSessionStorage('component-dev-tools-emulator-log-overlay-lastOpenedErrorsCount', '0', _ => parseInt(_))
    lastOpenedErrorsCount = 0;

    private recentUpdatedIntervalId: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: fixed;
                bottom: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                box-shadow: 0 0 3px black;
                border-top-left-radius: 8px;
                z-index: 99999999;
            }

            #title {
                --padding: 8px;
                background-color: var(--primary-color);
                color: white;
                margin: 0;
                border-radius: 0;
                border-top-left-radius: 8px;
            }

            :host(:not([errors-count="0"])) #title {
                background-color: var(--attention-color);
            }

            @keyframes spin {
                0%, 100% {
                    transform: rotate(90deg) scale(1);
                    background: var(--attention-color);
                }
                50% {
                    transform: rotate(135deg) scale(1.25);
                    background: var(--primary-color);
                }
            }

            :host(:not([errors-count="0"])) #title:before {
                content: ':O';
                position: absolute;
                top: -50px;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                line-height: 30px;
                text-align: center;
                background: var(--attention-color);
                box-shadow: 0 0 3px black;
                animation: spin 1s linear infinite;
            }

            :host(:not([open])) component-dev-tools-emulator-log {
                display: none;
            }

            component-dev-tools-emulator-log {
                width: 1024px;
                max-width: 100vw;
                max-height: 50vh;
                background-color: white;
                font-size: 12px;
                line-height: 16px;
            }
        `,
    ];

    override render() {
        return html`
            ${this.updatingServer || this.lastUpdatedServer ? html`
                <component-loading .loading="${this.updatingServer}"
                                   style="--loading-margin: 0; position: absolute; bottom: 110%; right: 5px; width: 25px; height: 25px; background: rgba(255, 0, 0, .3); border-radius: 100%; border: solid red 2px; color: white; text-align: center; line-height: 25px">
                    ${this.lastUpdatedServer}
                </component-loading>
            ` : undefined}

            <component-button-toggle id="title" .active="${this.bind.open}">
                ${this.errorsCount} errors
            </component-button-toggle>

            <component-dev-tools-emulator-log id="log"
                                              .rawItems="${this.bind.errors}"
                                              @new-raw-items="${this.onRecentUpdated}"></component-dev-tools-emulator-log>
        `;
    }

    @observe('open')
    setFilterOnOpen(open: boolean) {
        if (open) {
            (this.shadowRoot?.querySelector<ComponentDevToolsEmulatorLog>('#log') as ComponentDevToolsEmulatorLog).search = 'level:error';
            this.lastOpenedErrorsCount = this.rawErrorsCount;
        }
    }


    onRecentUpdated(e: CustomEvent) {
        let newLogs = e.detail.newItems;

        for (let newLog of newLogs) {
            if (newLog.timestamp < Date.now() - 500) continue;

            if (newLog.level === 'info' && newLog.message.includes('Serving at port')) {
                clearInterval(this.recentUpdatedIntervalId);
                this.lastUpdatedServer = 0;
                this.updatingServer = true;
            }

            if (newLog.level === 'info' && newLog.message.includes('Loaded functions definitions from')) {
                clearInterval(this.recentUpdatedIntervalId);
                this.lastUpdatedServer = 1;
                this.updatingServer = false;

                this.recentUpdatedIntervalId = window.setInterval(() => {
                    this.lastUpdatedServer = Math.ceil((Date.now() - newLog.timestamp) / 1000);
                    if (this.lastUpdatedServer >= 30) {
                        this.lastUpdatedServer = 0;
                        clearInterval(this.recentUpdatedIntervalId);
                    }
                }, 1000);
            }
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-dev-tools-emulator-log-overlay': ComponentDevToolsEmulatorLogOverlay;
    }
}