import Package from '../../shared/Package';

export const REGISTERED_FUNCTIONS: { [key: string]: (e: any) => void } = {};

export const generateRegisteredFunctionId = (_package: Package, id: string) => {
    return {
        registeredFunctionId: `${_package.id}-${id}`,
    };
};

export const registerFunction = (id: { registeredFunctionId: string }, _function: (e: any) => void): void => {
    if (REGISTERED_FUNCTIONS[id.registeredFunctionId]) throw new Error(`Registered function already exists by the name of ${id.registeredFunctionId}`);


    REGISTERED_FUNCTIONS[id.registeredFunctionId] = _function;
};