import {ref as litRef} from 'lit-html/directives/ref.js';
import {BunnyElement} from '../../components/bunny-element.ts';

type RefBunnyElement = BunnyElement & { __refModeActive: boolean };

export const ref = (): any => {
    return (_proto: any, _property: string, _descriptor: PropertyDescriptor) => {
        if (_proto.render) {
            //hook the render to track if its in ref mode or not
            let oldRender = _proto.render as (...args: any[])=>any;
            _proto.render = function (this: RefBunnyElement, ...args: any[]) {
                try {
                    this.__refModeActive = true;
                    return oldRender.apply(this, args);

                } finally {
                    this.__refModeActive = false;
                }
            };
        }


        _descriptor ??= {};
        _descriptor.get = function (this: RefBunnyElement) {
            if (this.__refModeActive) {
                return litRef(function (this: any, ref) {
                    this[`__${_property}`] = ref;
                });

            } else {
                return (this as any)[`__${_property}`];
            }
        };

        return _descriptor;
    };
};