import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {registerEditorElement, registerEditorElementProperty} from '../../../routing/local/helpers/DecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-embed-youtube')
@registerEditorElement({
    key: 'component-embed-youtube',
    name: 'YouTube embed',
    defaultProperties: {videoId: 'M7lc1UVf-VE'},
})
export class ComponentEmbedYoutube extends BunnyElement {
    @property({type: String})
    @registerEditorElementProperty({
        properties: {
            label: 'Video ID',
        },
        component: 'component-input',
    })
    videoId: string;

    @property({type: Boolean})
    controls: boolean = false;

    @property({type: Boolean})
    relatedVideos: boolean = false;

    @property({type: Boolean})
    modestBranding: boolean = false;

    @property({type: Boolean})
    loop: boolean = false;

    @property({type: Boolean})
    allowFullscreen: boolean = true;

    @property({type: Boolean})
    autoplay: boolean = false;

    @property({type: Boolean})
    fs: boolean = false;

    @property({type: Number})
    ratio: number = 9 / 16;


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                padding-top: 50%;
                position: relative;
            }

            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        `,
    ];

    override render() {
        return html`
            <iframe type="text/html" width="640" height="360"
                    src="https://www.youtube.com/embed/${this.videoId}?controls=${this.controls ? 1 : 0}&rel=${this.relatedVideos ? 1 : 0}&modestbranding=${this.modestBranding ? 1 : 0}&loop=${this.loop ? 1 : 0}&fs=${this.fs ? 1 : 0}&autoplay=${this.autoplay ? 1 : 0}"
                    frameborder="0"></iframe>
        `;
    }

    @observe('ratio')
    updateRatio(ratio: number) {
        this.style.paddingTop = (ratio * 100) + '%';
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-embed-youtube': ComponentEmbedYoutube;
    }
}
