import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {html} from 'lit';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';


@customElement('component-firestore-collection-list-item-cell-prize-winning-thread-shipping-address')
class ComponentFirestoreCollectionListItemCellPrizeWinningThreadShippingAddress extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                font-size: 12px;
            }
        `,
    ];


    override render() {
        return html`
            ${['firstName', 'lastName', 'phone', 'emailAddress'].map(_ => this.item._data?.shippingAddress[_]).filter(_ => _).join(', ')}
            <br>
            ${['address1', 'address2', 'city', 'province', 'zip'].map(_ => this.item._data?.shippingAddress[_]).filter(_ => _).join(', ')}
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-item-cell-prize-winning-thread-shipping-address': ComponentFirestoreCollectionListItemCellPrizeWinningThreadShippingAddress;
    }
}