import LocalPackage from '../../__internal/local/LocalPackage';
import {AspireCompsPrizeClaimingPackage} from '../shared';
import './components/component-prize-claiming';
import './components/component-prize-claiming-sidebar';
import './components/component-prize-claiming-messages';
import './components/component-prize-claiming-sidebar-item';
import './components/component-prize-claiming-messages-message';
import './components/component-prize-claiming-messages-confirm-address-details-request';
import './components/component-prize-claiming-messages-confirm-address-details-response';
import './components/component-prize-claiming-messages-confirm-bank-details-request';
import './components/component-prize-claiming-messages-confirm-bank-details-response';
import './components/component-prize-claiming-messages-winner-pic-request';
import './components/component-prize-claiming-messages-winner-pic-response';
import './components/component-prize-claiming-messages-winner-quote-request';
import './components/component-prize-claiming-messages-winner-quote-response';
import './components/component-prize-claiming-messages-close';
import './components/component-prize-claiming-messages-delivery-sent';
import './components/component-prize-claiming-messages-bank-sent';
import './components/component-firestore-collection-list-item-cell-prize-winning-thread-status';
import './components/component-firestore-collection-list-item-cell-prize-winning-thread-shipping-address';

export class AspireCompsPrizeClaimingLocalPackage extends AspireCompsPrizeClaimingPackage implements LocalPackage {

    constructor() {
        super();

    }
}
