import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {deleteDoc} from 'firebase/firestore';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';


@customElement('component-shop-products-admin-actions')
class ComponentShopProductsAdminActions extends ComponentFirestoreCollectionListItemCell {

    @property({notify: true})
    auth = Auth.getInstance(this);

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            ${RenderingHelper._accountHasPermission(this.auth.user, 'app.admin.shop.deleteProduct') ? html`
                <component-button @click="${this.delete}">Delete</component-button>
            ` : undefined}

            <component-button @click="${this.duplicate}">Duplicate</component-button>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Duplicating',
        successMessage: 'Duplicated',
        failedMessage: 'Failed duplicating: {{e}}',
    })
    @confirmationDialog({
        title: 'Are you sure you want to duplicate this product?',
        body: function (this: ComponentShopProductsAdminActions) {
            return `Are you sure you want to duplicate "${this.item._data.name}"`;
        },
    })
    async duplicate() {
        let duplicateName = prompt(`Duplicate of ${this.item._data.name}'s name`, `${this.item._data.name} (Duplicate)`) as string;
        if (!duplicateName) throw new FriendlyMessage(`No name was supplied for the new duplicate`);
        if (duplicateName.includes('(Duplicate)')) throw new FriendlyMessage(`${duplicateName} still contains "(Duplicate)"`);


        let duplicatedProduct = await adminCall.shopDuplicateProduct(this.item.id, duplicateName);
        HistoryHelper.pushState(`/admin/shop/products/${duplicatedProduct.duplicatedProductId}`);
    }

    @toastProgressWrapper({progressMessage: 'Deleting product', successMessage: 'Deleted product'})
    @confirmationDialog({
        title: 'Are you sure you want to delete this?',
        failButtonText: 'Cancel! Nope I just like clicking buttons',
        successButtonText: 'Yes im sure sure i want to delete this',
        body: function (this: ComponentShopProductsAdminActions) {
            return `Are you sure you want to permanently completely and unrecoverably delete forever the following product - ${this.item._data._ref.path}: ${this.item._data.name}`;
        },
    })
    async delete() {
        await deleteDoc(this.item._data._ref);
        await delayPromise(1500);
        location.reload();
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-products-admin-actions': ComponentShopProductsAdminActions;
    }
}