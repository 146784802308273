import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {remapNumber} from '../../../__internal/shared/helpers/MathHelper';
import {shopCall} from '../helpers/ShopHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-shop-order-reserved-countdown')
export class ComponentShopOrderReservedCountdown extends BunnyElement {

    @property({type: Number})
    startTime: number;

    @property({type: Number})
    endTime: number;

    @property({type: Number})
    localTimeOffset: number = 0;

    @property({type: Boolean})
    localTimeSynced: boolean = false;

    @property({type: Number})
    serverTime: number;

    @property({type: Number, notify: true})
    @computed('endTime', 'serverTime')
    get timeRemaining() {
        if (!this.serverTime) return;
        if (!this.endTime) return;

        return Math.max(0, this.endTime - this.serverTime);
    }

    set timeRemaining(_unused: any){
        //TODO temp to make lit happy for setting a binding
    }

    @property({type: Number})
    @computed('endTime', 'serverTime')
    get percentage() {
        if (!this.serverTime) return;
        if (!this.startTime) return;
        if (!this.endTime) return;

        return Math.min(1, Math.max(0, remapNumber(this.serverTime, this.startTime, this.endTime, 0, 1)));
    }

    private __updateServerTimeIntervalId: any;

    @property({type: Boolean, reflect: true})
    @computed('percentage')
    get visible() {
        if (!this.percentage) return false;

        return this.percentage >= 0.33;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                background-color: var(--attention-color);
                padding: 10px 15px;
                color: #fff;
                position: relative;
                --percentage: 0;
                overflow: hidden;
                transition: opacity .5s;
            }

            :host(:not([visible])) {
                opacity: 0;
                pointer-events: none;
            }

            :host:before {
                content: '';
                position: absolute;
                bottom: 0;
                top: 0;
                right: 0;
                background-color: #911;
                width: 100%;
                transform: scaleX(var(--percentage));
                transform-origin: right;
                transition: 1s linear;
            }
        `
    ];

    override render(){
        return html`
            <div style="position:relative;">
                This order is reserved for
                <span style="font-weight: bold">${this._formatTimeRemaining(this.timeRemaining)}</span>
            </div>
        `;
    }

    async connectedCallback() {
        super.connectedCallback();

        this.serverTime = Date.now();
        this.__updateServerTimeIntervalId = setInterval(() => {
            this.serverTime = Date.now() + this.localTimeOffset;
        }, 1000);
        this.localTimeSynced = false;
        this.localTimeOffset = await shopCall.calculateLocalTimeOffset();
        this.localTimeSynced = true;
        this.serverTime = Date.now() + this.localTimeOffset;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        clearInterval(this.__updateServerTimeIntervalId);
    }

    _formatTimeRemaining(timeRemaining: number) {
        let seconds = Math.floor(timeRemaining / 1000);
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        return `${minutes}`.padStart(2, '0') + ':' + `${seconds}`.padStart(2, '0');
    }

    @observe('percentage')
    reflectPercentageToProperty(percentage: number) {
        this.style.setProperty('--percentage', `${percentage}`);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-order-reserved-countdown': ComponentShopOrderReservedCountdown;
    }
}