import {customElement} from 'lit/decorators.js';
import {ComponentAnalytics} from './component-analytics';
import {loadTrackingLibrary} from '../helpers/TrackingLibraryLoaderHelper';
import {EventType, TypeParams} from '../helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-trustpilot-analytics')
class ComponentTrustpilotAnalytics extends ComponentAnalytics {

    delayStartupUntilEvent = ['shopPurchase'];

    private tp: any;

    @property({type: String})
    integrationKey: string;

    protected async startup() {
        let me = this;
        let win = window as any;
        let tp: any = me.tp = function () {
            tp.q.push(arguments);
        };
        tp.q = [];
        tp('register', me.integrationKey);

        let tpGlobalName = win.TrustpilotObject = '__tp';
        Object.defineProperty(win, tpGlobalName, {
            get() {
                return me.tp;
            },
            set(val) {
                me.tp = val;
            },
            configurable: true,
        });


        await loadTrackingLibrary('//invitejs.trustpilot.com/tp.min.js');

        delete win[tpGlobalName];
        delete win.TrustpilotObject;
    }

    protected setUserId(_id: string, _options: any = {}) {
    }

    protected setCurrentScreen(_id: string, _options: any = {}) {
    }

    protected setUserProperties(_properties: object, _options: any = {}) {
    }

    protected generateEventHandlers(): { [key in EventType]: (params: TypeParams[key]) => void } {
        let me = this;

        return {
            appPerformance() {
            },
            appError() {
            },
            viewContent() {
            },
            shopAddToCart() {
            },
            pageView() {
            },
            accountRegister() {
            },
            accountLogin() {
            },
            shopPurchase(params) {
                let orderContact = params.order.contact;
                me.tp('createInvitation', {
                    recipientEmail: orderContact.emailAddress,
                    recipientName: `${orderContact.firstName} ${orderContact.lastName}`,
                    referenceId: params.orderId,
                    source: 'InvitationScript',
                });
            },
            shopStartCheckout() {
            },
            interactableClick() {
            },
            interactableView() {
            },
            pwaPrompt() {
            },
            toastShown() {
            },
        };
    }


}


declare global {
    interface HTMLElementTagNameMap {
        'component-trustpilot-analytics': ComponentTrustpilotAnalytics;
    }
}