import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-stock-remaining')
export default class ComponentShopStockRemaining extends BunnyElement {
    @property({type: String})
    label: string = 'Stock remaining';

    @property({type: Number})
    value: number | undefined;

    @property({type: Number})
    max: number | false = false;

    static override styles = [
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            ${this.label}: ${this.value}
            ${this.max ? html`
                of ${this.max}
            ` : undefined}
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-stock-remaining': ComponentShopStockRemaining;
    }
}