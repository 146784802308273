import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {NEW_COMPONENTS} from './component-page-editor-content-add';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';

export const CONTROL_MAPPING: { [key: string]: { [key: string]: any } } = {
    Node: {
        textContent: {
            component: 'component-input',
            properties: {
                label: 'Content',
            },
            _shouldRemove(value: any) {
                return !value;
            },
        },
    },

    Element: {
        innerHTML: {
            component: 'component-page-editor-content-multi-type',
            properties: {
                label: 'Content',
                elements: {
                    innerHTML: {
                        component: 'component-input-quill',
                        properties: {
                            label: 'HTML',
                        },
                    },
                    textContent: {
                        component: 'component-input',
                        properties: {
                            label: 'Text',
                        },
                    },
                },
            },
        },
        textContent: undefined,
    },
    ComponentSignup: {
        termsUrl: {
            component: 'component-input',
            properties: {
                label: 'Terms URL',
            },
        },
        loginUrl: {
            component: 'component-input',
            properties: {
                label: 'Login URL',
            },
        },
    },
};

interface Property {
    element: string;
    key: string;
}

interface FormElementProperty extends Property {
    formElement: {
        _shouldRemove?: (value: any) => boolean
    };
}


@customElement('component-page-editor-content-editor')
export class ComponentPageEditorContentEditor extends BunnyElement {
    @property({type: Object, notify: true})
    data: any;

    //TODO readd when reverting is added
    // private original: any;

    @property({type: String})
    dataPath: string;

    @property({type: String})
    @computed('data')
    get editingComponent() {
        return this.data?.component as string;
    };

    @property({type: Array})
    @computed('editingComponent')
    get controlProperties(): Property[] {
        if (!this.editingComponent) return [];

        let element = document.createElement(this.editingComponent);

        let properties: { [key: string]: Property } = {};
        let proto = (element as any).__proto__;
        while (proto) {
            let controlMappings = CONTROL_MAPPING[proto.constructor.name];
            if (controlMappings) {
                for (let i in controlMappings) {
                    //dont include ones thats come up before(allows removing further down the chain)
                    if (i in properties) continue;


                    properties[i] = {element: proto.constructor.name, key: i};
                }
            }

            proto = proto.__proto__;
        }


        return JSON.parse(JSON.stringify(Object.values(properties)));
    }

    @computed('controlProperties')
    @property({type: Array})
    get propertyControls(): FormElementProperty[] {
        return this.controlProperties.map(_ => ({
            ..._,
            formElement: CONTROL_MAPPING[_.element] && _.key !== 'constructor' ? CONTROL_MAPPING[_.element][_.key] : undefined,
        }));
    }

    @computed('propertyControls')
    @property({type: Array})
    get editableProperties(): FormElementProperty[] {
        return this.propertyControls.filter(_ => _.formElement);
    }

    @computed('editableProperties')
    @property({type: Array})
    get formElements(): any[] {
        return this.editableProperties.map(_ => ({
            key: _.key,
            name: _.key,
            ..._.formElement,
        }));
    }

    @computed('editableProperties', 'data')
    @property({type: Boolean})
    get opened() {
        return !!this.data;
    }

    @computed('data')
    @property({type: String})
    get internalComponentSpec() {
        if (!this.data) return undefined;

        return NEW_COMPONENTS.find(_ => _.key === this.data.component);
    }

    static override styles = [
        // language=SCSS
        scss`
            #dialog {
                position: fixed;
                height: 100vh;
                top: 0;
                right: 0;
                bottom: 0;
                width: 33vw;
                margin: 0;
                display: flex;
                flex-direction: column;
                z-index: 100;
                background: white;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
            }

            #dialog:not([opened]) {
                display: none;
            }

            component-forms-group {
                flex: 1;
                overflow: auto;
                margin: 0;
                padding: 0 10px;
            }
        `,
    ];


    override render() {
        return html`
            <div id="dialog" ?opened="${this.opened}">
                <div style="display: flex; background: #efefef; align-content: center; padding-left: 10px; overflow: hidden; ">
                    <h3 style="flex: 1; margin-top: auto; margin-bottom: auto">
                        Editing
                        ${this.internalComponentSpec?.name}
                    </h3>
                    <component-button @click="${this.save}" style="min-width: 0;">Save</component-button>
                </div>
                ${this.internalComponentSpec?.group ? html`
                    <component-input-select label="Type" .value="${this.bindDeep('data.component')}">
                        ${this.calculateGroupedComponentSpecs(this.internalComponentSpec.group).map(item => html`
                            <component-input-select-item .value="${item.key}" style="${item.previewStyle}">
                                ${item.name}
                            </component-input-select-item>
                        `)}
                    </component-input-select>
                ` : undefined}

                <component-forms-group .elements="${this.formElements}"
                                       .value="${this.bindDeep('data.properties')}"
                                       @value-changed="${() => {
                                           this.requestUpdate('data');
                                       }}"></component-forms-group>
            </div>
        `;
    }

    @observe('opened')
    toggleOpenedStyles(opened: boolean) {
        let htmlElement = document.body.parentElement;
        if (!htmlElement) return;

        htmlElement.style.paddingRight = opened ? '33vw' : '';
    }

    // @observe('opened')
    // captureOriginal(opened: boolean) {
    //     if (!opened) return;
    //
    //
    //     //TODO perciest the reinject params
    //     this.original = JSONParseLocal(JSONStringify(this.data));
    // }

    // revert(_e: Event) {
    //     //restore the original before detaching from it
    //     this.data = this.original;
    //
    //     setTimeout(() => {
    //         this.data = null;
    //     });
    // }

    async save(_e: Event) {
        this.data = null;
        await delayPromise();
        this.data = null;
    }

    calculateGroupedComponentSpecs(group: string) {
        return NEW_COMPONENTS.filter(_ => _.group === group);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-page-editor-content-editor': ComponentPageEditorContentEditor;
    }
}