import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {AnalyticsReportDocument, FIRESTORE_COLLECTION_ANALYTICS_REPORTS} from '../../shared/helpers/FirebaseHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';

@customElement('component-analytics-report')
class ComponentAnalyticsReport extends BunnyElement {

    @property({type: String})
    id: string;

    @property({type: Object})
    @computed('id')
    get analyticsReport() {
        if (!this.id) return undefined;

        return new FirestoreDocument<AnalyticsReportDocument>(
            this,
            `${FIRESTORE_COLLECTION_ANALYTICS_REPORTS}/${this.id}`,
            {method: FetchMethod.NETWORK_ONLY},
        );
    };

    @property({type: Object})
    supplierData: any = {};

    @property({type: Object})
    data: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <div class="gridContainer row" id="suppliers">
                ${Object.entries(this.analyticsReport?.data?.suppliers || {})
                        .map(([supplierKey, supplier]) => createComponent({
                            ...supplier as any,
                            events: [
                                {
                                    'data-changed': (e: CustomEvent) => {
                                        this.supplierData[supplierKey] = e.detail.value;
                                        this.requestUpdate('supplierData');
                                    },
                                },
                            ],
                        }, this.data))}
            </div>

            <h1>${this.analyticsReport?.data?.name}</h1>
            <div class="gridContainer row" id="visualizers">
                ${Object.values(this.analyticsReport?.data?.visualizers || {})
                        .map(visualizer => createComponent(
                                visualizer as any,
                                {...this.data, supplierData: this.supplierData},
                        ))}
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-report': ComponentAnalyticsReport;
    }
}