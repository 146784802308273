import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-entry-route-type')
class ComponentShopEntryRouteType extends BunnyElement {

    @property({type: Boolean, notify: true})
    showFreePostalRoute: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .controls {
                display: flex;
                justify-content: center;
                margin: auto;
                padding: 0;
                margin-bottom: 15px;
                text-transform: uppercase;
            }

            .controls > component-button-toggle {
                background-color: var(--secondary-text-color-lighter);
                font-size: 10px;
                --padding: 5px 10px;
                cursor: default;
                margin: 0;
            }

            .controls > component-button-toggle:not([active]) {
                background-color: var(--primary-color);
            }

            .controls > component-button-toggle + component-button-toggle {
                margin-left: 1px;
            }
        `,
    ];

    override render() {
        return html`
            <div class="controls">
                <component-button-toggle .active="${this.bind.showFreePostalRoute}" .raised="${false}">Online entry
                </component-button-toggle>
                <component-button-toggle .active="${!this.showFreePostalRoute}" @active-changed="${(e: CustomEvent) => {
                    this.showFreePostalRoute = !e.detail.value;
                }}" .raised="${false}">Free postal entry
                </component-button-toggle>
            </div>

            ${this.showFreePostalRoute ? html`
                <div class="contentContainer">
                    <p>
                        If you wish to enter any of our competitions for free then you must send an unenclosed hand
                        written postcard via the post with your personal information so we can identify you stating:
                        Which competition you would like to take part in (written exactly as it is on the product page
                        on the website), along with your personal details as follows;
                    </p>

                    <ul>
                        <li>Full Name</li>
                        <li>Date of Birth</li>
                        <li>Postal Address</li>
                        <li>Email address (must match account email address)</li>
                        <li>Contact telephone number</li>
                    </ul>

                    <p>
                        Please send postal entries on a postcard with a first class stamp to: Aspire competitions Ltd,
                        4-5 Seaton Mews, West Henford, Yeovil BA20 2AG
                    </p>

                    <p>
                        Any entries that fail to follow the criteria mentioned above will be void as per any paid entry
                        received.
                    </p>

                    <p>
                        Entrants MUST have an active account on our website at the time the free postal entries are
                        processed, all details on postcards MUST match the name and address on the related user account.
                    </p>
                </div>
            ` : undefined}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-entry-route-type': ComponentShopEntryRouteType;
    }
}