import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-price')
class ComponentShopPrice extends BunnyElement {

    @property({type: Number})
    value: number;

    @property({type: Number})
    originalValue: number;

    @property({type: Boolean, reflect: true})
    @computed('value', 'originalValue')
    get isOnSale() {
        return this.value < this.originalValue;
    }

    @property({type: Boolean, reflect: true})
    @computed('value')
    get loaded() {
        return (this.value as any) !== null;
    }

    @property({type: String, reflect: true})
    originalValueDisplay: string = 'default';

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                opacity: 0;
            }

            :host([loaded]) {
                opacity: 1;
            }

            .value {
                display: inline-block;
                position: relative;
            }

            .originalValue {
                font-size: 80%;
                color: var(--primary-text-color);
                font-weight: normal;
                line-height: 1;
                opacity: .8;
            }

            :host([original-value-display="default"]) .originalValue {
                position: absolute;
                bottom: 100%;
                right: 0;
                transform: translateX(-10%);
            }
        `,
    ];

    override render() {
        return html`
            <slot name="prefix"></slot>
            <span class="value">
                ${this.isOnSale ? html`
                    <del class="originalValue">${this.format(this.originalValue)}</del>
                ` : undefined}
                
                ${this.format(this.value)}
            </span>
            <slot name="suffix"></slot>
        `;
    }

    format(value: number | string) {
        if (typeof value === 'string') {
            value = parseInt(value, 10);
        }

        return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(value / 100);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-price': ComponentShopPrice;
    }
}