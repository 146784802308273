import {customElement} from 'lit/decorators.js';
import {PageLayoutDefault} from '../../../routing/local/pages/page-layout-default';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {UnshadowStyles} from '../../../__internal/local/controllers/UnshadowStyles';

@customElement('page-layout-default-shop-checkout')
class PageLayoutDefaultShopCheckout extends PageLayoutDefault {

    //@ts-ignore
    private _unshadowStyles = new UnshadowStyles(this);

    @property({type: String})
    step: string = 'login';

    @property({type: String})
    cartId: string;

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            page-layout-default-shop-checkout {
                --header-height: 50px;
                --header-spacing: 25px;
                padding-top: calc(var(--header-height) + var(--header-spacing));
                line-height: 1.5;
                
                header {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: var(--header-height);
                    box-shadow: var(--shadow-elevation-2dp-box-shadow);
                    background: #ffffff;
                    z-index: 100;
                    --col-margin-top: 3 !important;
                    --col-margin-scale: 2px !important;
                    max-width: 100% !important;
                }
    
                header > * {
                    margin-top: auto !important;
                    margin-bottom: auto !important;
                }
    
                header h1 {
                    font-size: 20px;
                }
            }
        `
    ];

    override render(){
        return html`
            <header class="gridContainer">
                <a href="/" style="--col: 4;">
                    <img src="/images/aspire-logo.svg" alt="Aspire comps" class="logo"
                         style="margin-top: auto; margin-bottom: auto; height: 40px;">
                </a>
                <h1 style="--col: 4; text-align: center">
                    Checkout
                </h1>
                <a href="/shop/cart" style="--col: 4; text-align: right">
                    <component-icon icon="icons:shopping-basket"></component-icon>
                </a>
            </header>
            <div class="gridContainer">
                <component-shop-checkout-steps step="${this.step}" .cartId="${this.cartId}"></component-shop-checkout-steps>
            </div>
            <div id="main" data-region class="gridContainer">
                ${this.renderRegion(this.content?.main)}
            </div>
        `;
    }

    createRenderRoot() {
        return this;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'page-layout-default-shop-checkout': PageLayoutDefaultShopCheckout;
    }
}