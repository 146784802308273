import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {User} from 'firebase/auth';
import {getRegisterMeta} from '../helpers/AccountHelper';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {AccountDocument, FIRESTORE_COLLECTION_ACCOUNTS} from '../../shared/helpers/FirebaseHelper';
import {Auth} from '../controllers/Auth';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';

@customElement('component-login-alt')
class ComponentLoginAlt extends BunnyElement {

    //@ts-ignore
    auth = Auth.getInstance(this);

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Object})
    user: User | null;

    @property({type: Object})
    get account() {
        if (!this.user?.uid) return undefined;

        return new FirestoreDocument<AccountDocument>(this, `${FIRESTORE_COLLECTION_ACCOUNTS}/${this.user?.uid}`);
    }

    private attempts = 0;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-button {
                background-color: white;
                margin: auto;
                color: var(--primary-text-color);

                component-icon {
                    margin-right: 5px;
                }

                @media screen and (max-width: 400px) {
                    & {
                        flex-direction: column;
                    }
                }
            }
        `,
    ];

    override render() {
        return html`
            <component-button @click="${(_: MouseEvent) => this.login('google')}">
                <component-icon icon="app:google"></component-icon>
                Google
            </component-button>
            <component-button @click="${(_: MouseEvent) => this.login('facebook')}">
                <component-icon icon="app:facebook"></component-icon>
                Facebook
            </component-button>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Logging in',
        successMessage: 'Logged in successfully',
        failedMessage: 'Failed logging in: {{e}}',
    })
    async login(altLoginMethod: 'google' | 'facebook') {
        this.attempts++;


        let result;
        try {
            result = await this.auth.signInWithPopup(altLoginMethod);

        } catch (e: any) {
            throw new FriendlyMessage(e.message);
        }


        this.user = result?.user;
        if (!this.user) throw new Error('Failed signing up');


        let metadata = this.user.metadata;
        //TODO check this against timezones;
        let accountAge = (new Date()).getTime() - (new Date(metadata.creationTime as string)).getTime();
        let newAccount = accountAge < 30000;

        if (newAccount) {
            let name = (this.user.displayName || '').split(' ');


            debugger;
            await delayPromise();
            await this.account?.save({
                firstName: name[0],
                lastName: name.slice(1).join(' '),
                meta: getRegisterMeta(),
            });

            track('accountRegister', {
                path: location.href.replace(location.origin, ''),
                method: altLoginMethod,
                attempts: this.attempts,
            });

        } else {
            track('accountLogin', {
                path: location.href.replace(location.origin, ''),
                method: altLoginMethod,
                attempts: this.attempts,
            });
        }
    }


}


declare global {
    interface HTMLElementTagNameMap {
        'component-login-alt': ComponentLoginAlt;
    }
}