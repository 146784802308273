import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';


@customElement('component-input-select-item')
export class ComponentInputSelectItem extends BunnyElement {

    @property()
    value: any;

    @property({type: Boolean, reflect: true})
    disabled = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
            }


            :host([disabled]) {
                pointer-events: none;
                filter: grayscale(1);
                opacity: .6;
            }
        `,
    ];

    override render() {
        return html`
            <slot>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-select-item': ComponentInputSelectItem;
    }
}