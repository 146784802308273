import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {MediaDocument, MediaReferenceField} from '../../shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {registerEditorElementProperty} from '../../../routing/local/helpers/DecoratorHelper.ts';

let sharedVisibilityObserver: IntersectionObserver;
const getSharedObserver = (): IntersectionObserver => {
    if (!sharedVisibilityObserver) {
        sharedVisibilityObserver = new IntersectionObserver((entries, observer) => {
            for (let entry of entries) {
                if (!entry.isIntersecting) continue;

                observer.unobserve(entry.target);
                (entry.target as ComponentMediaView).visible = true;
            }
        }, {
            root: null,
            rootMargin: '100px',
            threshold: 0,
        });
    }

    return sharedVisibilityObserver;
};

const TYPES: {
    [key: string]: string
} = {
    jpg: 'image/jpeg',
    webp: 'image/webp',
    avif: 'image/avif',
    mp4: 'video/mp4',
    webm: 'video/webm',
};
const TYPE_PRIORITIES: {
    [key: string]: number
} = {
    'image/avif': 2,
    'image/webp': 1,
    'video/webm': 1,
};

export const getTypePriority = (type: string, scaling: number) => {
    let priority = (TYPE_PRIORITIES[type] || 0) * 10;
    priority += scaling;

    return priority;
};

export const getInfoFromPath = (url: string) => {
    /** 1 = userId,  2 = originalFile,   3 = processedFile,  4 = scaling,    5 = fileExt,    6 = meta */
        // let info = decodeURIComponent(url).match(/\/o\/(?:(?P<userId>[a-zA-Z0-9]+)\/)?(?:(?P<originalFile>[a-zA-Z0-9\-._]+)\/)?(?P<processedFile>[a-zA-Z0-9\-._]+)(?:@(?P<scaling>\d)x)?\.(?P<fileExt>[a-z]+)[?#]?(?P<meta>.*)$/);
    let info = decodeURIComponent(url).match(/\/o\/(?:([a-zA-Z0-9]+)\/)?(?:([a-zA-Z0-9\-._]+)\/)?([a-zA-Z0-9\-._]+)(?:@(\d)x)?\.([a-z]+)[?#]?(.*)$/);

    if (!info) return {
        userId: undefined,
        originalFile: undefined,
        processedFile: undefined,
        fileExt: '',
        scaling: 1,
        width: 100,
        height: 75,
        type: '',
    };

    /** 1 = width,  2 = height */
        // let meta = info[6] ? info[6].match(/?.*(width=(?P<width>\d+)).*(height=(?P<height>\d+)).*$/) : undefined;
    let meta = info[6] ? info[6].match(/width=(\d+)&height=(\d+)/) : undefined;

    return {
        userId: info[1],
        originalFile: info[2],
        processedFile: info[3],
        scaling: parseInt(info[4]) || 1,
        fileExt: info[5],
        width: meta ? parseInt(meta[1]) : 100,
        height: meta ? parseInt(meta[2]) : 75,
        type: TYPES[info[5]],
    };
};

@customElement('component-media-view')
export class ComponentMediaView extends BunnyElement {

    @property({type: String})
    fetchMethod: string = 'live';

    @property({type: String})
    @registerEditorElementProperty({
        properties: {
            label: 'Type',
        },
        component: 'component-input',
    })
    type: string = 'original';

    @property({type: Object})
    media?: MediaReferenceField;

    @property({type: String})
    @registerEditorElementProperty({
        properties: {
            label: 'Src',
        },
        component: 'component-input',
    })
    src?: string = undefined;

    @property({type: Array})
    sources: {
        media: string,
        src: string,
        type: string
    }[] = [];

    @computed('sources')
    get defaultSource(): {
        media: string,
        src: string,
        type: string
    } | undefined {
        if (!this.sources.length) return undefined;

        return this.sources[this.sources.length - 1];
    };

    @property({type: Number})
    ratio: number | undefined = undefined;

    @property({type: Number})
    @registerEditorElementProperty({
        properties: {
            label: 'Width',
            type: 'number',
        },
        component: 'component-input-number',
    })
    width: number = 100;

    @property({type: Number})
    @registerEditorElementProperty({
        properties: {
            label: 'Height',
            type: 'number',
        },
        component: 'component-input-number',
    })
    height: number = 100;

    @property({type: String})
    loadingMethod: 'panel' | 'image' = 'panel';

    @property({type: Boolean})
    loading: boolean = true;

    private _autoRatio: boolean;

    private _fetchRequest: any;

    @property({type: String})
    miniPreloadImage: string | undefined;

    @property({type: Boolean})
    visible: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;

                background: no-repeat center center;
                background-image: var(--miniPreloadImage);
                background-size: 100% 100%;

                --ratio: 1;
                --innerContent-transition: none;
                display: block;
            }

            .full {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }

            .fadeIn {
                opacity: 0;
                transition: opacity 0.125s cubic-bezier(0.43, 0, 1, 1);
            }

            .fadeIn.loaded {
                opacity: 1;
            }

            #innerContent {
                padding-top: calc(100% * var(--ratio));
                transition: var(--innerContent-transition, none);
            }
        `];


    override render() {
        return html`
            <div id="innerContent">
                <slot></slot>
            </div>
        `;
    }

    @observe('sources')
    markNotLoadingWhenSourcesAvailable(sources: {
        media: string,
        src: string,
        type: string
    }[]) {
        if (!sources.length) return;

        this.loading = false;
    }

    @observe('ratio')
    loadRatio(ratio: number) {
        this.style.setProperty('--ratio', `${ratio}`);
    }

    @observe('width', 'height')
    calculateRatio(width: number, height: number) {
        if (this.ratio !== undefined && !this._autoRatio) return;

        this._autoRatio = true;
        this.ratio = height / width;
    }

    @(observe as any)('media', 'visible')
    async loadMedia(media: MediaReferenceField, visible: boolean) {
        if (!media?.media) return;

        this.src = undefined;

        this.loading = true;

        if (this._fetchRequest) {
            this._fetchRequest.disconnect();
            this._fetchRequest = null;
        }

        if (media.miniPreloadImage) {
            this.miniPreloadImage = media.miniPreloadImage;
        }

        //stop loading if its not visible yet
        if (!visible) return;

        this._fetchRequest = FirestoreDocument.hostlessRequest(media.media.path, {method: FetchMethod.NETWORK_ONLY}, (data: MediaDocument) => {
            if (!data) return;
            let items: {
                [key: string]: string
            } = data.items;


            let avaliableItems = Object.entries(items).filter(_ => _[0].startsWith(this.type));
            if (!avaliableItems.length) {
                avaliableItems = Object.entries(items).filter(_ => _[0] === 'original');
            }


            let avaliableMinis = avaliableItems.filter(_ => _[0].endsWith('@0x'));
            avaliableItems = avaliableItems.filter(_ => !_[0].endsWith('@0x'));


            this.miniPreloadImage = avaliableMinis.length ? avaliableMinis[0][1] : undefined;
            this.sources = avaliableItems.map(_ => {
                let info = getInfoFromPath(_[1]);
                return {
                    type: info.type as string,
                    media: 'all',
                    scaling: info.scaling,
                    width: info.width,
                    height: info.height,
                    src: _[1],
                    info: info,
                    sources: [
                        {
                            src: _[1],
                            scaling: info.scaling,
                            width: info.width,
                            height: info.height,
                            info: info,
                        },
                    ],
                };
            })
                .sort((a, b) => getTypePriority(a.type, a.scaling) - getTypePriority(b.type, b.scaling))
                .reduce((previousValue, currentValue) => {
                    let foundType = previousValue.find(_ => _.type === currentValue.type);
                    if (!foundType) {
                        foundType = currentValue;
                        previousValue.push(foundType);

                    } else {
                        foundType.sources.push(currentValue.sources[0]);
                    }


                    return previousValue;
                }, [] as {
                    type: string,
                    media: string,
                    scaling: number,
                    width: number,
                    height: number,
                    src: string,
                    info: any,
                    sources: {
                        src: string,
                        scaling: number,
                        width: number,
                        height: number,
                        info: any
                    }[]
                }[])
                .sort((a, b) => getTypePriority(b.type, b.scaling) - getTypePriority(a.type, a.scaling));

            if (this.src || this.sources.length) {
                this.loading = false;
            }
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();


        if (this._fetchRequest) {
            this._fetchRequest.disconnect();
            this._fetchRequest = null;
        }

        this.deinitLazyLoading();
    }

    @observe('miniPreloadImage')
    loadMiniPreloadImage(miniPreloadImage: string | undefined) {
        this.style.setProperty('--miniPreloadImage', `url("${miniPreloadImage}")`);
    }

    connectedCallback() {
        this.initLazyLoading();
        super.connectedCallback();
    }

    initLazyLoading() {
        if (!('IntersectionObserver' in window)) {
            this.visible = true;
            return;
        }

        getSharedObserver().observe(this);
    }

    deinitLazyLoading() {
        if (!('IntersectionObserver' in window)) return;

        getSharedObserver().unobserve(this);
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-media-view': ComponentMediaView;
    }
}