import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {createComponent} from '../helpers/DomHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';

export interface ComponentSpec {
    key: string;
    name: string;
    defaultProperties?: { [key: string]: any };
    group?: string;
    previewStyle?: string;
}

export const NEW_COMPONENTS: ComponentSpec[] = [
    {
        key: 'h1',
        name: 'Heading 1',
        defaultProperties: {textContent: 'Heading 1'},
        group: 'Heading',
        previewStyle: 'font-size: 24px',
    },
    {
        key: 'h2',
        name: 'Heading 2',
        defaultProperties: {textContent: 'Heading 2'},
        group: 'Heading',
        previewStyle: 'font-size: 22px',
    },
    {
        key: 'h3',
        name: 'Heading 3',
        defaultProperties: {textContent: 'Heading 3'},
        group: 'Heading',
        previewStyle: 'font-size: 20px',
    },
    {
        key: 'h4',
        name: 'Heading 4',
        defaultProperties: {textContent: 'Heading 3'},
        group: 'Heading',
        previewStyle: 'font-size: 18px',
    },
    {
        key: 'h5',
        name: 'Heading 5',
        defaultProperties: {textContent: 'Heading 3'},
        group: 'Heading',
        previewStyle: 'font-size: 16px',
    },
    {
        key: 'h6',
        name: 'Heading 6',
        defaultProperties: {textContent: 'Heading 3'},
        group: 'Heading',
        previewStyle: 'font-size: 14px',
    },
    {key: 'p', name: 'Paragraph', defaultProperties: {textContent: 'Some text about things and stuff'}},
    {key: 'div', name: 'HTML', defaultProperties: {innerHTML: 'Your <span style="color: red">Content</span>'}},
    {
        key: 'ul',
        name: 'Unordered list',
        defaultProperties: {innerHTML: '<li>Its a list item</li>', style: 'list-style: disc;'},
        group: 'List',
    },
    {key: 'ol', name: 'Ordered list', defaultProperties: {innerHTML: '<li>Its a list item</li>'}, group: 'List'},
];

let instances: number = 0;

export enum MODE {
    PREVIEW,
    ADD,
}

@customElement('component-page-editor-content-add')
export class ComponentPageEditorContentAdd extends BunnyElement {

    @property({type: String, notify: true})
    selectedComponent: string;

    @property({type: String})
    preSelectedComponent: string;

    @property({type: Array})
    components: ComponentSpec[] = NEW_COMPONENTS;

    @property({type: String})
    dataPath: string;

    @property({type: Number})
    insertIndex: number;

    @property({type: Number})
    mode: MODE;

    get selectedComponentSpec(): ComponentSpec | undefined {
        return this.components.find(_ => _.key === this.selectedComponent);
    }

    @computed('opened')
    get insertInstanceId(): number {
        if (!this.opened) return 0;

        return instances++;
    }

    @property({type: Boolean})
    opened: boolean = false;

    static override styles = [
        // language=SCSS
        scss`
            component-button {
                margin: 5px;
            }
        `,
    ];

    override render() {
        return html`
            <component-dialog id="dialog" .opened="${this.bind.opened}">
                <h3 slot="heading">Insert new Component</h3>
                <div style="display: flex">
                    ${this.flattenComponentGroups(this.components).map(item => html`
                        <component-button-toggle .active="${this.selectedComponent === item.key}"
                                                 @mouseover="${(_: MouseEvent) => this.preSelectElement(item.key)}"
                                                 @click="${(_: MouseEvent) => this.selectElement(item.key)}">
                            ${item.name}
                        </component-button-toggle>
                    `)}
                </div>
                <div slot="footer">
                    <h2>Preview</h2>
                    <div id="preview">
                        ${this.renderPreview()}
                    </div>
                    ${!this.selectedComponent ? html`
                        Please select a component
                    ` : undefined}
                </div>
                <div slot="footer" class="buttons">
                    <component-button @click="${this.close}">Cancel</component-button>
                </div>
            </component-dialog>
        `;
    }

    preSelectElement(element: string) {
        this.selectedComponent = element;
    }

    async selectElement(element: string) {
        this.mode = MODE.ADD;
        this.selectedComponent = '';
        await delayPromise();

        this.preSelectElement(element);
        await delayPromise();
        await delayPromise();

        this.opened = false;
        this.selectedComponent = '';
    }

    close() {
        this.selectedComponent = '';
        this.opened = false;
    }

    open() {
        this.mode = MODE.PREVIEW;
        this.opened = true;
    }

    createComponent() {
        if (!this.selectedComponent) return;

        return {
            component: this.selectedComponent,
            properties: this.selectedComponentSpec?.defaultProperties,
        };
    }

    createElement(): HTMLElement | undefined {
        if (!this.selectedComponent) return;


        let componentObj: HTMLElement = document.createElement(this.selectedComponent);
        componentObj.dataset.dynamicInsertId = '' + this.insertInstanceId;

        let componentSpec = this.selectedComponentSpec;
        if (componentSpec?.defaultProperties) {
            for (let property in componentSpec.defaultProperties) {
                (componentObj as any)[property] = componentSpec.defaultProperties[property];
            }
        }


        return componentObj;
    }

    renderPreview() {
        if (!this.selectedComponent) return undefined;

        return createComponent({
            component: this.selectedComponent,
            properties: {
                ...this.selectedComponentSpec?.defaultProperties,
            },
            attributes: {
                'data-dynamic-insert-id': this.insertInstanceId,
            },
        }, {});
    }

    flattenComponentGroups(components: ComponentSpec[]) {
        let seenGroups: string[] = [];

        return components.reduce((previousValue, currentValue) => {
            if (currentValue.group) {
                if (seenGroups.indexOf(currentValue.group) !== -1) return previousValue;

                seenGroups.push(currentValue.group);
                currentValue = {...currentValue, name: currentValue.group};
            }

            previousValue.push(currentValue);
            return previousValue;
        }, [] as ComponentSpec[]);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-page-editor-content-add': ComponentPageEditorContentAdd;
    }
}