import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {PageContentComponent} from '../../../routing/shared/helpers/FirestoreHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-input-array')
class ComponentInputArray extends BunnyElement {

    @property({type: String})
    label: string;

    @property({type: Array})
    value: any[];

    @property({type: String})
    component: PageContentComponent;

    @property({type: Object})
    data: any;

    @property({type: Object})
    newItemValue: object;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                margin-bottom: 25px;
            }

            :host([invalid]) label {
                color: var(--paper-input-container-invalid-color, var(--error-color));
            }

            #elements {
            }

            #elements > * {
                /*border-left: solid black 2px;*/
                padding-left: 20px;
                position: relative;
            }

            /*#elements > * + * {*/
            /*    margin-top: 5px;*/
            /*}*/

            #elements > *:not(:last-child):after {
                content: '';
                position: absolute;
                top: 0;
                left: 5px;
                bottom: 0;
                border-left: solid #999999 2px;
            }

            #elements > *:before {
                content: '';
                position: absolute;
                top: 0;
                left: 5px;
                height: 35px;
                max-height: 50%;
                /*height: 50%;*/
                width: 10px;
                border-left: solid #999999 2px;
                border-bottom: solid #999999 2px;
                border-bottom-left-radius: 7px;
            }

            /*#elements > *:before {*/
            /*    content: '';*/
            /*    position: absolute;*/
            /*    top: 0;*/
            /*    left: 5px;*/
            /*    height: 100%;*/
            /*    width: 10px;*/
            /*    border-left: solid #999999 2px;*/
            /*    border-bottom: solid #999999 2px;*/
            /*    border-bottom-left-radius: 7px;*/
            /*    background: rgba(0, 0, 0, .05);*/
            /*}*/
            /*#elements > *:before {*/
            /*    content: '';*/
            /*    position: absolute;*/
            /*    top: 0;*/
            /*    left: 5px;*/
            /*    height: 100%;*/
            /*    width: 100%;*/
            /*    border-left: solid #999999 2px;*/
            /*    border-bottom: solid rgba(153, 153, 153, 0.2) 2px;*/
            /*    border-bottom-left-radius: 7px;*/
            /*    z-index: -1;*/
            /*    pointer-events: none;*/
            /*}*/

            #elements .arrayItem {
                display: flex;
            }

            #elements .arrayItem component-button {
                margin-top: 18px;
                margin-left: auto;
                margin-bottom: auto;
            }
        `,
    ];

    override render() {
        return html`
            <label>
                ${this.label}
            </label>

            <div id="elements">
                ${this.value?.map((item) => html`
                    <div class="arrayItem">
                        ${createComponent(this.component, this.data, {
                            value: item,
                        })}
                        <component-button @click="${(_: MouseEvent) => this.removeArrayItem(item)}">
                            Remove
                        </component-button>
                    </div>
                `)}
                <div class="addArrayItem" style="padding-top: 10px">
                    <component-button @click="${this.addArrayItem}">Add ${this.label}</component-button>
                </div>
            </div>
        `;
    }

    // @observe('value.*')
    // applyArrayValues(value: any) {
    //     let children = this.$.elements.children;
    //
    //     if (value.path === 'value') {
    //         if (!value.value) return;
    //
    //         for (let i in value.value) {
    //             (children[parseInt(i)] as any).children[0].value = value.value[i];
    //         }
    //
    //     } else if (value.path === 'value.splices') {
    //         this.renderItems(value.value.indexSplices[0].object, this.component, this.data);
    //
    //         for (let i = value.value.indexSplices[0].index; i < value.value.indexSplices[0].index + value.value.indexSplices[0].addedCount; i++) {
    //             (children[i] as any).children[0].value = value.value.indexSplices[0].object[i];
    //         }
    //     }
    // }

    removeArrayItem(item: any) {
        let index = this.value.indexOf(item);

        if (index >= 0) {
            this.value.splice(index, 1);
            this.requestUpdate('value');
        }
    }

    addArrayItem() {
        this.value.push({...this.newItemValue});
        this.requestUpdate('value');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-array': ComponentInputArray;
    }
}