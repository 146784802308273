import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';

@customElement('component-shop-payment-redirect')
class ComponentShopPaymentRedirect extends BunnyElement {

    @property({type: String})
    src: string;

    @property({type: Boolean})
    loading: boolean = true;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .contentStep {
                margin-bottom: 0;
                text-align: center;
            }
        `
    ];

    override render(){
        return html`
            <div class="contentStep">
                Redirecting to payment provider
            </div>
        `;
    }

    @observe('src')
    redirect(src: string) {
        HistoryHelper.redirect(src);
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-payment-redirect': ComponentShopPaymentRedirect;
    }
}