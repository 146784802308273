import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {registerEditorElement} from '../../../routing/local/helpers/DecoratorHelper';
import {ShopProductDocument} from '../../shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

@customElement('component-shop-product-guaranteed-draw-date')
@registerEditorElement({
    key: 'component-shop-product-guaranteed-draw-date',
    name: 'Shop product guaranteed draw date',
    defaultProperties: {},
})
class ComponentShopProductGuaranteedDrawDate extends BunnyElement {

    @property({type: Object})
    __product: ShopProductDocument = null as any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <h3 style="margin-bottom: 0">
                Draw guaranteed for
                ${this.__product ? RenderingHelper._dateFormat(this.__product.drawDate as Date, 'dd/MM/yyyy') : '<PRODUCT DRAW>'}
            </h3>
            <h4 style="margin-top: 0">
                (Sales close
                ${this.__product ? RenderingHelper._dateFormat(this.__product.finished as Date, 'dd/MM/yyyy') : '<PRODUCT FINISH>'})
                regardless of ticket sales
            </h4>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-product-guaranteed-draw-date': ComponentShopProductGuaranteedDrawDate;
    }
}