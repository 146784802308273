import {css, html, LitElement} from 'lit';
import {UnshadowStyles} from '../../controllers/UnshadowStyles';
import {IMPORT_TEST_COMPONENTS} from '../../../../../config';

if (IMPORT_TEST_COMPONENTS) {
    class ComponentTesterUnshadowStyles extends LitElement {
        //@ts-ignore
        unshadowStyles = new UnshadowStyles(this);

        //Used to test quick baling of UnshadowStyles on if its attached to a none HTMLElement host
        //@ts-ignore
        unconnectedUnshadow = new UnshadowStyles({
            addController: function () {
            },
        });

        // language=CSS
        static styles = css`
            h1 {
                color: red;
            }

            :host {
                background-color: grey;
            }

            :host(:first-child) {
                background-color: blue;
            }
        `;

        render() {
            return html`
                <p>I am the unshadower!</p>
            `;
        }

        protected createRenderRoot() {
            return this;
        }

    }

    customElements.define('component-tester-unshadow-styles', ComponentTesterUnshadowStyles);
}
