import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {User} from 'firebase/auth';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';

@customElement('component-account-manage-auth')
class ComponentAccountManageAuth extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    title: string = 'Link with';

    @property({type: Object})
    @computed('auth')
    get user() {
        return this.auth.getInternalUser() as User;
    }

    @property({type: Number})
    forceRefreshAuthMethods = -1;

    @property({type: Array})
    @computed('user', 'forceRefreshAuthMethods')
    get authMethods() {
        return [
            {
                id: 'google.com',
                name: 'Google',
                icon: 'app:google',
                visible: true,
            },
            {
                id: 'facebook.com',
                name: 'Facebook',
                icon: 'app:facebook',
                visible: true,
            }].map(auth => ({
            ...auth,
            active: this.user && !!this.user.providerData.find(_ => _.providerId === auth.id),
        }));
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-button {
                overflow: hidden;
                margin-top: 15px;
                display: flex;
                align-items: center;
            }

            component-button[data-active="1"] {
                background-color: var(--primary-text-color);
            }

            component-button component-icon {
                background-color: white;
                padding: 15px;
                margin: -0.7em -0.57em;
                margin-right: 15px;
            }
        `,
    ];

    override render() {
        return html`
            <div class="contentStep">
                ${this.title ? html`
                    <h2 style="margin-bottom: 0">${this.title}</h2>
                ` : undefined}
                <p style="margin-bottom: 25px; margin-top: 0">
                    Link with external platforms to allow you to sign in with
                </p>


                ${this.authMethods?.map(item => html`
                    <component-button @click="${(_: MouseEvent) => this.toggleLink(item.id, item.active)}"
                                      data-active="${item.active ? 1 : 0}">
                        <component-icon icon="${item.icon}"></component-icon>
                        ${item.name}

                        ${item.active ? 'connected' : 'connect'}
                    </component-button>
                `)}
            </div>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Connecting auth',
        successMessage: 'Connected auth',
        failedMessage: 'Failed connecting auth: {{e}}',
    })
    async connectAuth(method: string) {
        await this.auth.linkWithPopup(method);
    }

    @confirmationDialog({
        title: 'Are you sure you want to disconnect',
        successButtonText: 'Disconnect',
        failButtonText: 'Cancel',
        body: function (method: string) {
            return `Disconnecting ${method} will mean you are no longer able to login with ${method}`;
        },
    })
    @toastProgressWrapper({
        progressMessage: 'Disconnecting auth',
        successMessage: 'Disconnected auth',
        failedMessage: 'Failed disconnecting auth: {{e}}',
    })
    async disconnectAuth(method: string) {
        let activeAuthMethods = this.authMethods.filter(_ => _.active).length;
        if (activeAuthMethods <= 1) throw new FriendlyMessage(`You cant disconnect ${method} yet as this is your only auth method, please connect one of the others first`);

        await this.auth.unlink(method);
    }

    async toggleLink(method: string, active: boolean) {
        debugger;
        if (active) {
            await this.disconnectAuth(method);

        } else {
            await this.connectAuth(method);
        }


        this.forceRefreshAuthMethods = Date.now();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-account-manage-auth': ComponentAccountManageAuth;
    }
}