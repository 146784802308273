import {customElement} from 'lit/decorators.js';
import {ComponentFirestoreCollectionListItemCell} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {seededRandomColor} from '../../../__internal/local/helpers/MathHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-firestore-collection-list-item-cell-draw-date')
class ComponentFirestoreCollectionListItemCellDrawDate extends ComponentFirestoreCollectionListItemCell {

    @observe('type', 'format', 'field', 'item', 'defaultValue')
    updateBackgroundColor(type: string, format: string, field: string, item: any, defaultValue: string) {
        let content = this.renderContent(type, format, field, item, defaultValue);

        this.style.backgroundColor = seededRandomColor(content);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-item-cell-draw-date': ComponentFirestoreCollectionListItemCellDrawDate;
    }
}