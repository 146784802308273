import {customElement} from 'lit/decorators.js';
import {
    ComponentNotificationsPreferenceChannelBase,
    CONFIGURATION,
} from '../component-notifications-preference-channel-base';
import {computed} from '../../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {html} from 'lit';

@customElement('component-notifications-preference-channel-whatsapp')
class ComponentNotificationsPreferenceChannelWhatsapp extends ComponentNotificationsPreferenceChannelBase {

    get name() {
        return 'Whatsapp';
    }

    renderSetupHtml() {
        return html`TODO whatsapp setup stuff(number??)??`;
    }

    renderInfoHtml() {
        return html`
            <div style="margin-bottom: 15px; font-size: 80%">
                Number &middot; ${this.item.meta.number}
            </div>
        `;
    }

    @computed('item')
    get configuration(): CONFIGURATION {
        return this.item?.meta?.number ?
            CONFIGURATION.AVAILABLE:
            CONFIGURATION.AWAITING_SETUP;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-preference-channel-whatsapp': ComponentNotificationsPreferenceChannelWhatsapp;
    }
}