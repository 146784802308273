import {customElement} from 'lit/decorators.js';
import {ComponentShopCustomerContactCard} from './component-shop-customer-contact-card';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-customer-contact-card-editable')
class ComponentShopCustomerContactCardEditable extends ComponentShopCustomerContactCard {

    @property({type: Boolean, reflect: true})
    hideAddress: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                max-width: 400px;
                min-width: 300px;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding: 0;
                display: inline-flex;
                margin: 15px;
                flex: 1;
                flex-direction: column;
            }

            :host > * {
                padding: 10px;
            }

            .contactName {
                background: var(--primary-color);
                color: white;
                --primary-text-color: color;
                font-weight: bold;
                font-size: 18px;
                font-style: normal;
                position: relative;
                display: flex;
                padding: 0 10px;
            }

            .contactName > * {
                flex: 1;
            }

            .contactName > * + * {
                margin-left: 10px;
            }

            .contactNotification {
                display: flex;
                border-bottom: solid rgba(32, 32, 32, .1) 1px;
            }

            .contactNotification > * {
                flex: 1;
            }

            .contactNotification > * + * {
                margin-left: 10px;
            }

            .contactNotification component-icon {
                vertical-align: bottom;
                margin-right: 5px;
            }

            :host([hide-address]) .contactAddress {
                display: none;
            }
        `,
    ];

    override render() {
        return html`
            <div class="contactName">
                <component-input .value="${this.bindDeep('contact.firstName')}"></component-input>
                <component-input .value="${this.bindDeep('contact.lastName')}"></component-input>
            </div>
            <div class="contactNotification">
                <component-input-phone .value="${this.bindDeep('contact.phone')}">
                    <component-icon slot="prefix" icon="communication:contact-phone"></component-icon>
                </component-input-phone>
                <component-input-email .value="${this.bindDeep('contact.emailAddress')}">
                    <component-icon slot="prefix" icon="communication:contact-mail"></component-icon>
                </component-input-email>
            </div>

            <address class="contactAddress">
                <component-input .value="${this.bindDeep('contact.name')}" label="Name"></component-input>
                <component-input .value="${this.bindDeep('contact.address1')}" label="Address 1"></component-input>
                <component-input .value="${this.bindDeep('contact.address2')}" label="Address 2"></component-input>
                <component-input .value="${this.bindDeep('contact.company')}" label="Company"></component-input>
                <component-input .value="${this.bindDeep('contact.city')}" label="City"></component-input>
                <component-input .value="${this.bindDeep('contact.province')}" label="Province"></component-input>
                <component-input .value="${this.bindDeep('contact.zip')}" label="Zip"></component-input>
                <component-input .value="${this.bindDeep('contact.country')}" label="Country"></component-input>
            </address>
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-customer-contact-card-editable': ComponentShopCustomerContactCardEditable;
    }
}