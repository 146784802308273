import {dotGet} from './DotHelper';

export const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
export const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;


export const stringValueInjector = (value: any, previousResolveData: object, nullResolveSkipped = false, safeHtml = false) => {
    let fieldMatches = [...value.matchAll(/:([a-zA-Z0-9_.\[\]']+):/g)];

    let isSingleExactReplace = fieldMatches.length === 1 && value[0] === ':' && value[value.length - 1] === ':';

    let skippedValues = 0;
    for (let fieldMatch of fieldMatches) {
        let matchValue = dotGet(fieldMatch[1], previousResolveData);

        if (matchValue === undefined || matchValue === null) {
            console.debug('skipping field match', fieldMatch[1]);
            skippedValues++;

            if (nullResolveSkipped) {
                //allow nulling out skipped values
                value = null;
            }

            continue;
        }


        if ((typeof matchValue === 'object' || typeof matchValue === 'boolean' || typeof matchValue === 'number') &&
            isSingleExactReplace) {
            value = matchValue;

        } else {
            if (!safeHtml && matchValue) {
                //html encode the value to prevent xss
                matchValue = matchValue.toString().replace(/[\u00A0-\u9999<>\&'"]/gim, function (i: string) {
                    return '&#' + i.charCodeAt(0) + ';';
                });
            }

            //merge in the value to the field replacer
            value = value.replace(':' + fieldMatch[1] + ':', matchValue);
        }
    }

    return {
        value: value,
        skippedValues: skippedValues,
    };
};

export const PROCESS_CALL_DATA_HANDLERS: { [key: string]: (args: any[]) => any } = {
    'array.map.single': (args: any[]) => {
        let array = args[0];
        let arrayObjectField = args[1];

        if (!array) return undefined;
        if (!Array.isArray(array)) return undefined;

        return array.map((_: any) => _[arrayObjectField]);
    },
    'array.map.intoObject': (args: any[]) => {
        let array = args[0];
        let objField = args[1];
        let obj = args[2];

        if (!array) return undefined;
        if (!Array.isArray(array)) return undefined;

        return array.map((_: any) => ({
            ...obj,
            [objField]: _,
        }));
    },
    'array.map.object.spread': (args: any[]) => {
        let array = args[0];

        if (!array) return undefined;
        if (!Array.isArray(array)) return undefined;

        return array.map((_: any) => Object.assign({}, _));
    },
    'dot.get': (args: any[]) => {
        if (args[0] && args[1]) {
            if (typeof args[0] !== 'string') {
                console.error('dot.get requires first argument to be a path:string');
                return undefined;

            } else if (typeof args[1] !== 'object') {
                console.error('dot.get requires second argument to be a object');
                return undefined;

            } else {
                // @ts-ignore
                return dotGet(...args);
            }

        } else {
            return undefined;
        }
    },
    'object.toArray': (args: any[]) => {
        let obj = args[0];
        if (!obj) return [];

        return Object.values(obj);
    },
    'object.spread': (args: any[]) => {
        return Object.assign({}, ...args);
    },
    'JSON.stringify': (args: any[]) => {
        return JSON.stringify(...(args as [any, any, any]));
    },
};

function isObjectObject(o: any) {
    return o && typeof o === 'object'
        && Object.prototype.toString.call(o) === '[object Object]';
}

export default function isPlainObject(o: any) {
    let ctor, prot;

    if (isObjectObject(o) === false) return false;

    // If has modified constructor
    ctor = o.constructor;
    if (typeof ctor !== 'function') return false;

    // If has modified prototype
    prot = ctor.prototype;
    if (isObjectObject(prot) === false) return false;

    // If constructor does not have an Object-specific method
    if (prot.hasOwnProperty('isPrototypeOf') === false) {
        return false;
    }

    // Most likely a plain Object
    return true;
};

export const mergeData = (raw: any, data: any, seenStack: any[] | undefined = undefined, safeHtml = false) => {
    seenStack = seenStack || [];

    for (let key in raw) {
        if (!(`_reinject_${key}` in raw)) {
            Object.defineProperty(raw, `_reinject_${key}`, {value: raw[key], configurable: true});
        }

        let value = raw[`_reinject_${key}`];
        if (!value) continue;

        if (typeof value === 'string') {
            let valueStringInjected = stringValueInjector(value, data, true, safeHtml);
            value = valueStringInjected.value;
            raw[key] = value;
            continue;
        }

        if (((isPlainObject(value) && !value._injectorSkip) || Array.isArray(value)) && seenStack.indexOf(seenStack) === -1) {
            seenStack.push(value);

            mergeData(value, data, seenStack, safeHtml);
        }
    }
};

export const processCallData = async (raw: any, seenStack: any[] = []) => {
    for (let key in raw) {
        let value = raw[key];
        if (!value) continue;

        if (typeof value === 'object' && typeof value.call === 'string' && Array.isArray(value.args)) {
            let call = value.call;
            let args: any[] = value.args;

            let handler = PROCESS_CALL_DATA_HANDLERS[call];
            if (handler) {
                value = await handler(args);
            }

            raw[key] = value;
            continue;
        }

        if ((isPlainObject(value) || Array.isArray(value)) && seenStack.indexOf(seenStack) === -1) {
            seenStack.push(value);

            await processCallData(value, seenStack);
        }
    }
};

export const JSONParse = (text: string, reviver?: (this: any, key: string, value: any) => any) => {
    let propergateUndefineds: [any, string][] = [];
    let ret = JSON.parse(text, function (key, value) {
        if (reviver) {
            value = reviver(key, value);
        }

        if (value && typeof value === 'object' && value.__internalType === 'undefined') {
            propergateUndefineds.push([this, key]);
            value = undefined;

        } else if (value && typeof value === 'object' && value.__internalType === 'function') {
            if (globalThis.localStorage && !localStorage['testMode']) throw new Error('Only for use in test mode');
            value = new Function('...args', `return (${value.value})(...args);`);

        } else if (value && typeof value === 'object' && value.__internalType === 'date') {
            let a = reISO.exec(value.value);
            if (a) {
                value = new Date(value.value);
            }

            a = reMsAjax.exec(value.value);
            if (a) {
                let b = a[1].split(/[-+,.]/);
                value = new Date(b[0] ? +b[0] : 0 - +b[1]);
            }
        }


        return value;
    });

    //propergate undefineds cus JSON.parse will delete the keys when returned
    for (let [obj, key] of propergateUndefineds) {
        obj[key] = undefined;
    }


    return ret;
};

export interface JSONStringifyOptions {
    storeFirestoreDocumentData?: boolean;
}

export const JSONStringify = (body: any, replacer?: ((this: any, key: string, value: any) => any) | null, space?: string | number, options: JSONStringifyOptions | undefined = undefined) => {
    return JSON.stringify(body, function (key, value) {
        if (replacer) {
            value = replacer(key, value);
        }
        let valueType = typeof value;

        if (value === undefined) {
            return {
                __internalType: 'undefined',
            };

        } else if (valueType === 'function') {
            return {
                __internalType: 'function',
                value: value.toString(),
            };
        }

        if (valueType === 'string' && typeof this[key] === 'object' && this[key] instanceof Date) {
            //handle dates being pre stringifyied by date.toJSON()
            value = this[key];
            valueType = typeof value;
        }

        if (value && valueType === 'object') {
            let docData = undefined;
            if (value.toDate && value.seconds && value.nanoseconds) {
                value = value.toDate();

            } else if (value._ref) {
                if (options?.storeFirestoreDocumentData) {
                    docData = {...value};
                }

                value = value._ref;
            }


            if (value.path && value.id) {
                if (value.path.replace(/[a-zA-Z0-9_\-/]/g, '').length === 0) {
                    value = {
                        path: value.path,
                        id: value.id,
                    };

                    if (docData) {
                        value.data = docData;
                    }
                }

            } else if (value.ref?.path && value.ref?.id) {
                if (value.ref.path.replace(/[a-zA-Z0-9_\-/]/g, '').length === 0) {
                    value = {
                        path: value.ref.path,
                        id: value.ref.id,
                    };

                    if (docData) {
                        value.data = docData;
                    }
                }

            } else if (value instanceof Date) {
                value = {
                    __internalType: 'date',
                    value: value.toISOString(),
                };
            }
        }


        return value;
    }, space);
};

export const arrayChunk = function <T = any>(arr: T[], size: number = 1): T[][] {
    if (arr.length === 0) return [];
    if (arr.length <= size) return [arr];

    let chunk = [];
    for (let i = 0, l = arr.length, group: any[] = []; i < l; i++) {
        if (i % size === 0) {
            group = [];
            chunk.push(group);
        }

        group.push(arr[i]);
    }

    return chunk;
};

export const hasChanged = (value1: any, value2: any) => {
    //TODO maybe change to something else that doesnt rely on JSON

    return JSONStringify(value1) !== JSONStringify(value2);
};

// let t= [
//     // {type: 'firestore', key: 'document_owner_notification_preferences', value: ':docAfter.owner.path:/private/notification-preferences'},
//     {type: 'firestore', key: 'document_route', value: '/pages/:route.routeId:'},
//     {type: 'variable', key: 'description', value: 'Routing admin edit :document_route.title:'},
//     {type: 'variable', key: 'title', value: 'Routing admin edit :document_route.title:'},
//     {type: 'processor', value: {call: 'rejectCondition', args: [':document_route:']}},
// ];
// export const processResolvers = (resolvers: any[], data: any)=>{
//
// };