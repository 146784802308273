import {injectBunnyHooks} from '../DecoratorsHelper';
import {Route} from '../../../../routing/local/controllers/Route';
import HistoryHelper from '../HistoryHelper';

type StorageBoundPostProcessor<T> = (value: T) => unknown;
type StorageBoundGetter<T> = () => T | null;
type StorageBoundSetter<T> = (value: T) => void;

function storageBoundBase<T>(getter: StorageBoundGetter<T>, setter: StorageBoundSetter<T>, _default: T | false, postProcessor: StorageBoundPostProcessor<T> = ((value) => value), startListeningForChanges?: (hasUpdate: () => void) => void, stopListeningForChanges?: () => void) {
    return (_proto: any, _property: string, _descriptor: PropertyDescriptor) => {
        let camelProperty = _property.replace(/([a-z])([A-Z])/g, (_m, m1, m2) => `${m1}-${m2.toLowerCase()}`);

        if (!_proto.constructor.hasOwnProperty('properties')) {
            Object.defineProperty(_proto.constructor, 'properties', {value: {}});
        }

        _proto.constructor.properties[_property] = Object.assign({}, _proto.constructor.properties[_property], {
            value: _default,
        });

        // let globalChangeListener: ((e: CustomEvent) => void) | null;
        let fieldChangeListener: ((e: CustomEvent) => void) | null;
        injectBunnyHooks(_proto, 'init', function (this: any) {
            let me = this;

            let value = getter() ?? _default;
            if (value === false) return;

            me[_property] = postProcessor(value);
        });
        injectBunnyHooks(_proto, 'connectedCallback', function (this: any) {
            // let app = (window as any).app;
            let me = this;

            // globalChangeListener = () => {
            //     let value = getter() ?? _default;
            //     if (value === false) return;
            //
            //     me[_property] = postProcessor(value);
            // };

            fieldChangeListener = (e: CustomEvent) => {
                setter(e.detail.value);
            };

            // app.addEventListener('global-changed', globalChangeListener);
            me.addEventListener(`${camelProperty}-changed`, fieldChangeListener);
            if (startListeningForChanges) {
                startListeningForChanges(() => {
                    let value = getter() ?? _default;
                    if (value === false) return;

                    me[_property] = postProcessor(value);
                });
            }

            let value = getter() ?? _default;
            if (value === false) return;

            me[_property] = postProcessor(value);
        });
        injectBunnyHooks(_proto, 'disconnectedCallback', function (this: any) {
            // let app = (window as any).app;
            let me = this;

            // app.removeEventListener('global-changed', globalChangeListener);
            me.removeEventListener(`${camelProperty}-changed`, fieldChangeListener);
            if (stopListeningForChanges) {
                stopListeningForChanges();
            }

            // globalChangeListener = null;
            fieldChangeListener = null;
        });
    };
}

type StorageListenerCallback = ((value: string) => void);

class StorageListener {
    //todo add support for being notififed if other tabs are updating it
    listeners: Record<string, Set<StorageListenerCallback>> = {};

    on(key: string, listener: StorageListenerCallback) {
        this.listeners[key] ??= new Set();

        this.listeners[key].add(listener);
    }

    off(key: string, listener: StorageListenerCallback) {
        if (!this.listeners[key]) return;

        this.listeners[key].delete(listener);

        if (!this.listeners[key].size) {
            delete this.listeners[key];
        }
    }

    notify(key: string, value: string, selfListener?: StorageListenerCallback) {
        let callbacks = this.listeners[key];
        if (!callbacks) return;

        for (let callback of callbacks) {
            if (callback === selfListener) continue;

            try {
                callback(value);

            } catch (e) {
                console.error(`Storage listener callback failed during key update: ${key}`, e);
            }
        }
    }
}

let localStorageStorageListener = new StorageListener();
let sessionStorageStorageListener = new StorageListener();

/**
 * if the _default is false then dont set it
 *
 * @param key
 * @param _default
 * @param postProcessor Function used to alter the value after checking it with _default
 */
export function storageBoundLocalStorage(key: string, _default: string | false = '', postProcessor?: StorageBoundPostProcessor<string>): any {
    let storageChangeListener: StorageListenerCallback;

    return storageBoundBase(
        () => {
            return localStorage[key];
        },
        (value) => {
            if (value as any === undefined) {
                delete localStorage[key];

            } else {
                localStorage[key] = value;
            }

            localStorageStorageListener.notify(key, value, storageChangeListener);
        },
        _default,
        postProcessor,
        (hasUpdate) => {
            storageChangeListener = () => {
                hasUpdate();
            };

            localStorageStorageListener.on(key, storageChangeListener);
        },
        () => {
            localStorageStorageListener.off(key, storageChangeListener);
        },
    );
}

/**
 * if the _default is false then dont set it
 *
 * @param key
 * @param _default
 * @param postProcessor Function used to alter the value after checking it with _default
 */
export function storageBoundSessionStorage(key: string, _default: string | false = '', postProcessor?: StorageBoundPostProcessor<string>): any {
    let storageChangeListener: StorageListenerCallback;

    return storageBoundBase(
        () => {
            return sessionStorage[key];
        },
        (value) => {
            if (value as any === undefined) {
                delete sessionStorage[key];

            } else {
                sessionStorage[key] = value;
            }

            sessionStorageStorageListener.notify(key, value, storageChangeListener);
        },
        _default,
        postProcessor,
        (hasUpdate) => {
            storageChangeListener = () => {
                hasUpdate();
            };

            sessionStorageStorageListener.on(key, storageChangeListener);
        },
        () => {
            sessionStorageStorageListener.off(key, storageChangeListener);
        },
    );
}

/**
 * if the _default is false then dont set it
 *
 * @param key
 * @param _default
 * @param postProcessor
 */
export function storageBoundQueryString(key: string, _default: string | false = '', postProcessor?: StorageBoundPostProcessor<string>): any {
    let route = Route.getInstance();
    return storageBoundBase(() => route.current.query[key], (value) => {
        let queryString = location.search;
        let oldQueryString = queryString;

        if (queryString.includes(`${key}=`)) {
            let regexKey = key.replace(/([^a-zA-Z0-9])/g, '\\$1');
            queryString = queryString.replace(
                new RegExp(`([?&]${regexKey}=)[^&]*`),
                value ? `$1${encodeURIComponent(value)}` : '',
            );

        } else if (value) {
            queryString += `${queryString ? '&' : '?'}${key}=${encodeURIComponent(value)}`;

        } else {
            return;
        }

        if (queryString === oldQueryString) return;


        HistoryHelper.replaceState(`${location.pathname}${queryString}`, document.title, history.state, false);
    }, _default, postProcessor);
}