import Packages from './packages/__internal/shared/Packages';
import Package from './packages/__internal/shared/Package';
import LocalPackage from './packages/__internal/local/LocalPackage';
import {__InternalLocalPackage} from './packages/__internal/local';
import {InstallLocalPackage} from './packages/install/local';
import {AuthLocalPackage} from './packages/auth/local';
import {MediaLocalPackage} from './packages/media/local';
import {AdminLocalPackage} from './packages/admin/local';
import {EmbedYoutubeLocalPackage} from './packages/embed-youtube/local';
import {FirebaseAnalyticsLocalPackage} from './packages/firebase-analytics/local';
import {FormLocalPackage} from './packages/form/local';
import {InputAddressLocalPackage} from './packages/input-address/local';
import {MenuLocalPackage} from './packages/menu/local';
import {RoutingLocalPackage} from './packages/routing/local';
import {ShopLocalPackage} from './packages/shop/local';
import {SlideshowLocalPackage} from './packages/slideshow/local';
import {AspireCompsLocalPackage} from './packages/aspire-comps/local';
import {AspireCompsCommentPickerLocalPackage} from './packages/aspire-comps-comment-picker/local';
import {FirestoreEventsLocalPackage} from './packages/firestoreEvents/local';
import {FirestoreEventsHandlerFetchLocalPackage} from './packages/firestoreEventsHandlerFetch/local';
import {InputJsonLocalPackage} from './packages/input-json/local';
import {NotificationsLocalPackage} from './packages/notifications/local';
import {InputElasticsearchSelectLocalPackage} from './packages/input-elasticsearch-select/local';
import {ElasticsearchLocalPackage} from './packages/elasticsearch/local';
import {InputsLocalPackage} from './packages/inputs/local';
import {DevToolsLocalPackage} from './packages/dev-tools/local';
import {AspireCompsCompetitionsLocalPackage} from './packages/aspire-comps-competitions/local';
import {GoogleLocalPackage} from './packages/google/local';
import {ShopPointsLocalPackage} from './packages/shop-points/local';
import {AnalyticsLocalPackage} from './packages/analytics/local';
import {FirebaseMessagingLocalPackage} from './packages/firebase-messaging/local';
import {BigqueryMirrorPackage} from './packages/bigquery-mirror/shared';
import {ENV} from './config';
import {AspireCompsPrizeClaimingLocalPackage} from './packages/aspire-comps-prize-claiming/local';


const packages = new Packages<Package & LocalPackage>();

packages.add(new __InternalLocalPackage());
packages.add(new InstallLocalPackage());
packages.add(new RoutingLocalPackage());
packages.add(new AdminLocalPackage());
packages.add(new FormLocalPackage());
packages.add(new MenuLocalPackage());
packages.add(new InputJsonLocalPackage());
packages.add(new FirestoreEventsLocalPackage());
packages.add(new ElasticsearchLocalPackage());
packages.add(new AuthLocalPackage());
packages.add(new MediaLocalPackage());
packages.add(new EmbedYoutubeLocalPackage());
packages.add(new FirebaseAnalyticsLocalPackage());
packages.add(new FirebaseMessagingLocalPackage());
packages.add(new InputAddressLocalPackage());
packages.add(new ShopLocalPackage());
packages.add(new ShopPointsLocalPackage());
packages.add(new SlideshowLocalPackage());
packages.add(new AspireCompsLocalPackage());
packages.add(new AspireCompsCommentPickerLocalPackage());
packages.add(new FirestoreEventsHandlerFetchLocalPackage());
packages.add(new InputElasticsearchSelectLocalPackage());
packages.add(new NotificationsLocalPackage());
packages.add(new InputsLocalPackage());
packages.add(new AspireCompsCompetitionsLocalPackage());
packages.add(new AspireCompsPrizeClaimingLocalPackage());
packages.add(new GoogleLocalPackage());
packages.add(new AnalyticsLocalPackage());
packages.add(new BigqueryMirrorPackage());

if (ENV === 'dev' || ENV === 'local') {
    packages.add(new DevToolsLocalPackage());
}

export default packages;