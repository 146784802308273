import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {prepareFunction} from '../../../__internal/local/helpers/FirebaseFunctionHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {ENV} from '../../../../config';
import {sharedStyles} from '../../../../shared-styles';

let resolvingLinks: { [key: string]: ComponentRoutingLink[] } = {};
let collectedLinks: { [key: string]: ComponentRoutingLink[] } = {};
let _resolveLinksTimeoutId: number;


@customElement('component-routing-link')
class ComponentRoutingLink extends BunnyElement {

    @property({type: String})
    href: string;

    @property({type: String})
    title: string;

    static collectLink(link: ComponentRoutingLink) {
        let collectedLink = collectedLinks[link.href] = collectedLinks[link.href] || [];
        collectedLink.push(link);

        ComponentRoutingLink.resolveLinks();
    }

    static uncollectLink(link: ComponentRoutingLink) {
        let collectedLink = collectedLinks[link.href] = collectedLinks[link.href] || [];
        collectedLink.splice(collectedLink.indexOf(link), 1);
    }

    private static async _resolveLinks() {
        let urlResolvers = [];
        for (let collectedLinkUrl in collectedLinks) {
            if (!resolvingLinks[collectedLinkUrl]) {
                urlResolvers.push(collectedLinkUrl);
            }

            let resolvingLink = resolvingLinks[collectedLinkUrl] = resolvingLinks[collectedLinkUrl] || [];

            resolvingLink.push(...collectedLinks[collectedLinkUrl]);
        }

        collectedLinks = {};

        if (!urlResolvers.length) return;


        let LinkResolver = await prepareFunction('routing-linkresolverv2', ENV === 'local' ? undefined : `${location.origin}/_`);
        let urlsResolved = await LinkResolver({urls: urlResolvers});
        ComponentRoutingLink.applyResolvedLinks((urlsResolved.data as any).urls);
    }

    private static applyResolvedLinks(urls: { [key: string]: string }) {
        for (let resolvingLinkUrl in resolvingLinks) {
            let resolvedUrl = urls[resolvingLinkUrl];
            if (!resolvedUrl) continue;

            for (let link of resolvingLinks[resolvingLinkUrl]) {
                link.href = resolvedUrl;
            }
        }

        resolvingLinks = {};
    }


    private static resolveLinks() {
        clearTimeout(_resolveLinksTimeoutId);
        _resolveLinksTimeoutId = window.setTimeout(ComponentRoutingLink._resolveLinks, 10);
    }

    static override styles = [
        sharedStyles,
    ];

    override render() {
        return html`
            <a href="${this.href}" title="${this.title}">
                <slot></slot>
            </a>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        ComponentRoutingLink.collectLink(this);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        ComponentRoutingLink.uncollectLink(this);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-routing-link': ComponentRoutingLink;
    }
}