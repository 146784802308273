import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-aspire-comps-entry-list-mine-item')
class ComponentAspireCompsEntryListMineItem extends BunnyElement {

    @property({type: Boolean})
    opened: boolean = false;

    @property({type: Object})
    competition: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                margin-bottom: 5px;
            }

            component-button-toggle {
                background: var(--primary-color);
                position: relative;

                color: white;
                min-width: 0;
                margin: 0;
                display: flex;
                justify-content: start;
                --padding: 0;
                overflow: hidden;
                line-height: 1.2;
                text-align: left;

                &[active] {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                span {
                    font-size: 25px;
                    padding: 10px;
                    flex: 1;

                    small {
                        vertical-align: middle;
                        background-color: var(--primary-text-color);
                        font-size: 50%;
                        border-radius: 3px;
                        padding: 2px 5px;
                        color: white;
                        text-transform: uppercase;
                    }
                }
            }


            component-button.innerButton {
                min-width: 0;
                margin: 0;
                background: var(--primary-text-color);
                border-radius: 0;
                margin-bottom: auto;
                margin-left: 5px;
                height: 50px;
            }
        `
    ];

    override render() {
        return html`
            <component-button-toggle .active="${this.bind.opened}">
                <span style="flex: 1; text-transform: none">
                    ${this.competition.name}
                    
                    <small style="background-color:var(--attention-color);">
                        ${this.competition.tickets.length} ticket${this.competition.tickets.length === 1 ? '' : 's'}
                    </small>
                </span>
                ${!this.opened ? html`
                    <component-button class="innerButton">
                        View
                    </component-button>
                ` : undefined}
            </component-button-toggle>

            ${this.opened ? html`
                <component-aspire-comps-tickets-renderer
                        .items="${this.competition.tickets}"
                        no-items-message="You do not have any tickets for this competition"
                        display-winner
                        style="padding: 15px 0"></component-aspire-comps-tickets-renderer>
            ` : undefined}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-entry-list-mine-item': ComponentAspireCompsEntryListMineItem;
    }
}