import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {html, PropertyValues} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {createComponent} from '../helpers/DomHelper';

@customElement('page-layout-default')
export class PageLayoutDefault extends BunnyElement {

    @property({type: Object, notify: true})
    content!: Record<string, any[]>;

    @property({type: Object})
    data: any;

    @property({type: Number})
    _forceRerender: number = 0;

    @property({type: Boolean, reflect: true})
    isRootFrame: boolean = parent === window;

    @property({type: Object})
    regions: { [key: string]: { elements: Element[], key: string, node: Element } } = {};

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                --region: {
                };
                --element: {
                };
            }

            :host([only-content]) {
                padding: 0;
            }

            :host([only-content]) .noneContent {
                display: none;
            }

            [data-region] {
                @apply --region;
            }

            [data-region] > * {
                @apply --element;
            }
        `,
    ];

    override render() {
        return html`
            <div class="gridContainer">
                <div id="main" data-region style="--col-md: 8">
                    ${this.renderRegion(this.content?.main)}
                </div>
                <div id="sidebar" data-region style="--col-md: 4">
                    ${this.renderRegion(this.content?.sidebar)}
                </div>
            </div>
        `;
    }

    renderRegion(regionContent?: any[]) {
        if (!regionContent) return [];

        return regionContent.map(value => createComponent(value, this.data, {data: this.data}));
    }

    override updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);

        this.initRegions();
    }

    private initRegions() {
        if (Object.keys(this.regions).length) return false;

        let regions: any = {};
        for (let region of [...(this.shadowRoot || this).querySelectorAll('[data-region]')]) {
            regions[region.id] = {elements: [...region.children] as any, key: region.id, node: region};
        }

        this.regions = regions;

        return true;
    }


}


declare global {
    interface HTMLElementTagNameMap {
        'page-layout-default': PageLayoutDefault;
    }
}