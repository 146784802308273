import {ReactiveControllerHost} from 'lit';
import {
    Auth as FirebaseAuth,
    browserPopupRedirectResolver,
    browserSessionPersistence,
    confirmPasswordReset,
    connectAuthEmulator,
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    FacebookAuthProvider,
    fetchSignInMethodsForEmail,
    getAuth,
    getIdTokenResult,
    GoogleAuthProvider,
    indexedDBLocalPersistence,
    linkWithPopup,
    OAuthProvider,
    onAuthStateChanged,
    reauthenticateWithCredential,
    setPersistence,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    signInWithPopup,
    signOut,
    unlink,
    updateCurrentUser,
    updateEmail,
    updatePassword,
    User,
} from 'firebase/auth';
import {config} from '../../../../config';
import {firebaseApp} from '../../../__internal/local/helpers/FirebaseHelper';
import {BunnyController} from '../../../__internal/local/controllers/BunnyController';
import {FriendlyMessage, remapExceptionCode} from '../../../__internal/shared/helpers/ExceptionHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {applyActionCode, AuthCredential} from '@firebase/auth';
import {ComponentReauthenticationPasswordDialog} from '../components/component-reauthentication-password-dialog.ts';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument.ts';
import {AccountPermissionsDocument, FIRESTORE_COLLECTION_ACCOUNTS} from '../../shared/helpers/FirebaseHelper.ts';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData.ts';
import {performanceNow} from '../../../__internal/local/helpers/PerformanceHelper.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';


const REMAP_ERROR_MESSAGES = {
    'auth/internal-error': {
        message: 'Please try again or contact us for help if the problem persists',
        errorClass: Error,
    },
    'auth/invalid-email': {message: 'Email address is invalid', errorClass: FriendlyMessage},
    'auth/email-already-in-use': {
        message: 'This email address is already in use by another account',
        errorClass: FriendlyMessage,
    },
    'auth/requires-recent-login': {message: 'To do this action please reauthenticate', errorClass: FriendlyMessage},
    'auth/weak-password': {
        message: 'Please enter a password that is at least 6 characters',
        errorClass: FriendlyMessage,
    },
    'auth/user-not-found': {message: 'Invalid email address', errorClass: FriendlyMessage},
    'auth/invalid-action-code': {
        message: 'This supplied action code has expired, please request again',
        errorClass: Error,
    },
    'auth/expired-action-code': {
        message: 'This supplied action code has expired, please request again',
        errorClass: FriendlyMessage,
    },
    'auth/wrong-password': {message: 'Invalid email address or password', errorClass: FriendlyMessage},

    'auth/credential-already-in-use': {
        message: 'This credential is already linked to another account',
        errorClass: FriendlyMessage,
    },
    'auth/account-exists-with-different-credential': {
        message: 'The email associated with your [Google/Facebook] account has already got an account with us, please log in with your password and link to your [Google/Facebook] account in your account settings',
        errorClass: FriendlyMessage,
    },

    'auth/popup-closed-by-user': {message: 'Auth popup closed', errorClass: FriendlyMessage},
};

let instance: Auth;

const _sessionStorage = window.sessionStorage;
const _localStorage = window.localStorage;
const SESSION_STORAGE_ACTIVE_AUTH_KEY = '__activeAuth';
const LOCAL_STORAGE_ACCOUNT_ID_KEY = '__accountId';

type StoredUser =
    {
        uid: string,
        permissions: string[]
    }
    | null
const generateFlatUser = (user: User | null) => {
    return user ? {
        uid: user.uid,
        permissions: (user as any)._claims.permissions,
    } : null;
};
let activeUser: StoredUser | null = null;

const storeActiveUser = (user: StoredUser) => {
    if (user) {
        _sessionStorage[SESSION_STORAGE_ACTIVE_AUTH_KEY] = JSON.stringify(activeUser);
        _localStorage[LOCAL_STORAGE_ACCOUNT_ID_KEY] = activeUser?.uid;

    } else {
        delete _sessionStorage[SESSION_STORAGE_ACTIVE_AUTH_KEY];
        delete _localStorage[LOCAL_STORAGE_ACCOUNT_ID_KEY];
    }
};

export class Auth extends BunnyController {

    private internalUser: User | null = null;

    getIdToken(forceRefresh?: boolean) {
        return this.internalUser?.getIdToken(forceRefresh);
    }

    getInternalUser() {
        return this.internalUser;
    }

    set user(value: User | null) {
        this.internalUser = value;
        activeUser = generateFlatUser(value);

        this._trackUserPermissionChanges();

        storeActiveUser(activeUser);
    };

    get user(): StoredUser {
        return activeUser;
    }

    private auth: FirebaseAuth;

    private permissionTracker: (Promise<AccountPermissionsDocument> & {
        disconnect: () => void
    }) | undefined;

    static getInstance(host?: ReactiveControllerHost) {
        if (!instance) {
            instance = new Auth();
        }

        if (host) {
            instance.addHost(host);
        }

        return instance;
    }

    private constructor() {
        super();

        this.auth = getAuth(firebaseApp);
        if (config.google.firebase.useEmulator) {
            connectAuthEmulator(this.auth, 'http://localhost:9099', {disableWarnings: true});
        }


        if (activeUser === null) {
            let sessionStorageActiveAuth = _sessionStorage[SESSION_STORAGE_ACTIVE_AUTH_KEY];
            activeUser = sessionStorageActiveAuth ? JSON.parse(sessionStorageActiveAuth) : null;
            console.log('Auth ready after', performanceNow());
        }

        onAuthStateChanged(this.auth, async (user) => {
            if (user) {
                let tokenResult = await getIdTokenResult(user);
                Object.defineProperty(user, '_claims', {
                    value: tokenResult.claims,
                });
            }

            this.user = user;
            this.notifyUpdated();
        });

        this.attemptSigninToken();
        this.attemptSigninState();
    }

    async attemptSigninToken() {
        let hash = location.hash;
        if (!hash || !hash.includes('signInWithToken')) return;

        let signInToken = hash.match(/[#&]signInWithToken=([a-zA-Z0-9]+)/);
        if (!signInToken) return;


        let [username, password] = atob((signInToken[1] as string)).split(':', 2);
        await this.signInWithEmailAndPassword(username, password);
    }

    async attemptSigninState() {
        let hash = location.hash;
        if (!hash || !hash.includes('signInWithState')) return;

        let signInState = hash.match(/[#&]signInWithState=([^&]+)/);
        if (!signInState) return;

        if (localStorage._authCompletedSignInWithState) {
            location.hash = '';
            return;
        }


        let newAuthState = JSON.parse(atob(signInState[1] as string));
        await this.importFirebaseUser(newAuthState);

        if (newAuthState._returnUrl) {
            HistoryHelper.replaceState(newAuthState._returnUrl, document.title, history.state);
        }
        localStorage._authCompletedSignInWithState = 1;
    }

    private _trackUserPermissionChanges() {
        if (this.permissionTracker) {
            this.permissionTracker.disconnect();
            this.permissionTracker = undefined;
        }

        if (!this.user) return;


        this.permissionTracker = FirestoreDocument.hostlessRequest(
            `${FIRESTORE_COLLECTION_ACCOUNTS}/${this.user.uid}/private/permissions`,
            {method: FetchMethod.LIVE},
            (data: AccountPermissionsDocument) => {
                if (!data) return;

                if (!this.internalUser || this.internalUser?.uid !== data._ref?.parent.parent?.id) return;//its not the current user so bail

                let currentInternalUserPermissions = (this.internalUser as any)._claims?.permissions;
                if (JSON.stringify(currentInternalUserPermissions) === JSON.stringify(data.permissions)) return;//its permissions havnt changed so bail

                (this.internalUser as any)._claims.permissions = data.permissions;
                activeUser = generateFlatUser(this.internalUser);
                storeActiveUser(activeUser);
                this.notifyUpdated();


                getIdTokenResult(this.internalUser, true);//dont await it as it can background
            },
        );
    }

    getSigninProvider(providerName: string) {
        switch (providerName) {
            case 'facebook':
            case 'facebook.com':
                return new FacebookAuthProvider();

            case 'google':
            case 'google.com':
                return new GoogleAuthProvider();

            case 'apple':
                let provider = new OAuthProvider('apple.com');
                provider.addScope('email');
                provider.addScope('name');

                return provider;
        }

        throw new Error(`Unknown signin provider ${providerName}`);
    }

    async signInWithEmailAndPassword(email: string, password: string) {
        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return signInWithEmailAndPassword(this.auth, email, password);
        });
    }

    async signUpWithEmailAndPassword(email: string, password: string) {
        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return createUserWithEmailAndPassword(this.auth, email, password);
        });
    }

    async signInWithCustomToken(token: string) {
        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return signInWithCustomToken(this.auth, token);
        });
    }


    _attemptProviderSignIn(provider: any, method: any) {
        if (!provider) return Promise.reject('Must supply a provider for popup sign in.');
        if (!this.auth) return Promise.reject('No app configured for firebase-auth!');

        return method(this.auth, provider, browserPopupRedirectResolver);
    }

    async signInWithPopup(providerName: 'google' | 'facebook') {
        let provider = this.getSigninProvider(providerName);
        if (!provider) throw new Error(`Unknown provider ${providerName}`);

        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return this._attemptProviderSignIn(provider, signInWithPopup);
        });
    }

    async createUserWithEmailAndPassword(email: string, password: string) {
        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            let ret = await createUserWithEmailAndPassword(this.auth, email, password);

            await delayPromise(3000);

            return ret;
        });
    }

    signOut() {
        return signOut(this.auth);
    }

    private async getReauthenticationCredential(): Promise<AuthCredential> {
        let user = (this.internalUser as User);
        let provider = user.providerData[0].providerId;

        if (provider === 'password') {
            return EmailAuthProvider.credential(
                user.email as string,
                await ComponentReauthenticationPasswordDialog.requestPassword(),
            );

        } else if (provider === 'google.com') {
            let provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            let result = await signInWithPopup(this.auth, provider, browserPopupRedirectResolver);

            return GoogleAuthProvider.credentialFromResult(result) as AuthCredential;

        } else if (provider === 'facebook.com') {
            let provider = new FacebookAuthProvider();
            provider.addScope('user_birthday');
            let result = await signInWithPopup(this.auth, provider, browserPopupRedirectResolver);

            return FacebookAuthProvider.credentialFromResult(result) as AuthCredential;
        }

        throw new Error('Unknown authentation provider: ' + provider);
    }

    async reauthenticate() {
        while (1) {
            try {
                let credential = await this.getReauthenticationCredential();

                await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
                    await reauthenticateWithCredential(this.internalUser as User, credential);
                });

                return;

            } catch (e: any) {
                if (e.message === REMAP_ERROR_MESSAGES['auth/wrong-password'].message) {
                    await showToast('Reauthenticate failed, try again');
                    continue;
                }

                throw e;
            }
        }
    }

    async updateEmail(email: string) {
        while (1) {
            try {
                return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
                    return await updateEmail(this.internalUser as User, email);
                });

            } catch (e: any) {
                if (e.message === REMAP_ERROR_MESSAGES['auth/requires-recent-login'].message) {
                    await this.reauthenticate();

                    continue;
                }

                throw e;
            }
        }
    }


    async updatePassword(password: string) {
        while (1) {
            try {
                return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
                    await updatePassword(this.internalUser as User, password);
                });

            } catch (e: any) {
                if (e.message === REMAP_ERROR_MESSAGES['auth/requires-recent-login'].message) {
                    await this.reauthenticate();

                    continue;
                }

                throw e;
            }
        }
    }

    async linkWithPopup(providerName: string) {
        let provider = this.getSigninProvider(providerName);
        if (!provider) throw new Error(`Unknown provider ${providerName}`);

        await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            await linkWithPopup(this.internalUser as User, provider, browserPopupRedirectResolver);
        });
    }

    async unlink(providerName: string) {
        await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return unlink(this.internalUser as User, providerName);
        });
    }

    async confirmPasswordReset(code: string, newPassword: string) {
        await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return confirmPasswordReset(this.auth, code, newPassword);
        });
    }

    applyActionCode(code: string) {
        return applyActionCode(this.auth, code);
    }

    reauthenticateWithEmailLink(email: string, url: string) {
        return reauthenticateWithCredential(
            this.internalUser as User,
            EmailAuthProvider.credentialWithLink(email, url),
        );
    }

    async signInWithEmailLink(email: string, url: string) {
        return signInWithEmailLink(this.auth, email, url);
    }

    fetchSignInMethodsForEmail(email: string) {
        return fetchSignInMethodsForEmail(this.auth, email);
    }

    async setStatePersistence(state: 'local' | 'session') {
        await setPersistence(
            this.auth,
            {
                local: indexedDBLocalPersistence,
                session: browserSessionPersistence,
            }[state],
        );
    }

    exportFirebaseUser() {
        return this.internalUser?.toJSON();
    }

    async importFirebaseUser(user?: object) {
        let internalUser: User | null = null;

        if (user) {
            let persistenceManager;
            while (!(persistenceManager = (this.auth as any).persistenceManager)) {
                //wait until persistenceManager is avaliable, its normally about 4 frames
                await delayPromise();
            }

            //HACKS to convert JSON user to Firebase User obj via persistenceManager.getCurrentUser that exists under Auth
            internalUser = await persistenceManager.getCurrentUser.apply({
                auth: this.auth,
                persistence: {
                    _get() {
                        return user;
                    },
                },
            }, []);
        }

        return updateCurrentUser(this.auth, internalUser);
    }


}


if (localStorage['testMode']) {
    (window as any).__Auth = Auth;
}