import {compress, obfuscate} from '../../shared/helpers/TrackingSocketHelper.ts';
import {ENV} from '../../../../config.ts';

const FLUSH_ON_MAX_QUEUE = 100;
const FLUSH_ON_INTERVAL = 1000;


export class TrackingSocket {
    url: string;
    queue: string[] = [];
    private pendingFlushQueueTimeoutId?: number;

    constructor(url: string) {
        this.url = url;
    }

    private async processData(data: string) {
        data = obfuscate(data);

        try {
            // throw new Error('ddd');
            if ('CompressionStream' in window) {
                return await compress(data);
            }

        } catch (e) {
            console.error('Failed processing data', e);
        }


        return new Blob([data], {type: 'text/plain'});
    };

    private async internalFlushQueue() {
        let items = this.queue;
        this.queue = [];


        try {
            if (ENV === 'local') {
                console.log('Items in queue', items);
            }

            let sendData = await this.processData(JSON.stringify(items));
            await fetch(this.url, {
                method: 'POST',
                headers: {
                    ...(sendData.type === 'application/octet-stream' ? {
                        'Content-encoding': 'gzip',
                    } : undefined),
                    'Content-type': sendData.type,
                },
                body: sendData as any,
            });

        } catch (e) {
            this.queue.unshift(...items);
            console.error('Failed flushing queue', e);
        }
    }

    flushQueue() {
        if (this.queue.length > FLUSH_ON_MAX_QUEUE) {
            clearTimeout(this.pendingFlushQueueTimeoutId);
            delete this.pendingFlushQueueTimeoutId;
            this.internalFlushQueue();
            return;
        }


        if (this.pendingFlushQueueTimeoutId) return;

        this.pendingFlushQueueTimeoutId = window.setTimeout(() => {
            delete this.pendingFlushQueueTimeoutId;
            this.internalFlushQueue();
        }, FLUSH_ON_INTERVAL);
    }

    send(data: string) {
        this.queue.push(data);

        this.flushQueue();
    }
}