import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';
import {FIRESTORE_EVENTS_PACKAGE_ID} from '../../firestoreEvents/shared';


export const BIGQUERY_MIRROR_PACKAGE_ID = 'bigquery-mirror';

export class BigqueryMirrorPackage extends Package {
    id: string = BIGQUERY_MIRROR_PACKAGE_ID;
    name: string = 'Bigquery mirror';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, FIRESTORE_EVENTS_PACKAGE_ID];
}