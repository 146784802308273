import {customElement} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../helpers/decorators/BindDecoratorHelper';
import {delayPromise} from '../helpers/PromiseHelper';

@customElement('component-draggable-grid')
class ComponentDraggableGrid extends BunnyElement {

    @property({type: Boolean, reflect: true})
    enabled: boolean = true;

    @property({type: Array, notify: true})
    managedItems: any[];

    private container: HTMLSlotElement;

    private dragEl: HTMLElement;
    private nextEl: HTMLElement;
    private startDragIndex: number;
    // private newPos: DOMRect[];
    // private dragGhost;
    // private oldPos: DOMRect[];

    private originalChildrenOrder: HTMLElement[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            slot {
                display: flex;
                flex-wrap: wrap;
            }


            :host([enabled]) slot {
                margin: 0;
                padding: 0;
                list-style: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                user-select: none;
                display: grid;
                grid-gap: 15px;
                grid-template-columns: repeat(auto-fit, minmax(200px, .25fr));;
                grid-template-rows: auto;
                grid-auto-flow: row dense;
            }

            :host([enabled]) ::slotted(*) {
                width: 100% !important;
            }

            :host([enabled]) ::slotted(.inside) {
                background-color: #fff;
            }

            :host([enabled]) ::slotted(.ghost) {
                transform: scale(.95);
                transition: .125s;
                opacity: .6;
                background-color: #fff;
            }
        `,
    ];

    override render() {
        return html`
            <slot id="list" @dragstart="${this.onDragStart}"></slot>
        `;
    }

    private getChildren() {
        return [...this.container.assignedElements()] as HTMLElement[];
    }

    async connectedCallback() {
        super.connectedCallback();

        await delayPromise();
        this.container = this.shadowRoot?.querySelector<HTMLSlotElement>('#list') as HTMLSlotElement;
        this.getChildren().forEach(item => {
            item.draggable = true;
        });
    }

    @bind()
    private onDragStart(e: DragEvent) {
        this.dragEl = e.target as HTMLElement;
        this.nextEl = this.dragEl.nextSibling as HTMLElement;
        this.startDragIndex = this.getChildren().indexOf(this.dragEl);

        if (e.dataTransfer) {
            e.dataTransfer.setData('Text', this.dragEl.textContent as string);
            e.dataTransfer.effectAllowed = 'move';
        }

        this.container.addEventListener('dragover', this.onDragOver, false);
        this.container.addEventListener('dragend', this.onDragEnd, false);


        this.originalChildrenOrder = [...this.children] as HTMLElement[];

        setTimeout(() => {
            this.dragEl.classList.add('ghost');
        }, 0);
    }

    @bind()
    private onDragOver(e: DragEvent) {
        e.preventDefault();
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'move';
        }

        let target = e.target as HTMLElement;
        let children = this.getChildren();
        if (target && target !== this.dragEl) {
            if (target.classList.contains('inside')) {
                e.stopPropagation();

            } else {
                //getBoundinClientRect contains location-info about the element (relative to the viewport)
                // let targetPos = target.getBoundingClientRect();
                //checking that dragEl is dragged over half the target y-axis or x-axis. (therefor the .5)
                let next = false;//(e.clientY - targetPos.top) / (targetPos.bottom - targetPos.top) > .5 || (e.clientX - targetPos.left) / (targetPos.right - targetPos.left) > .5;
                let parent = this.dragEl.parentElement as HTMLElement;
                let beforeChild = next && target.nextSibling || target;

                let currentPosition = children.indexOf(this.dragEl);
                let toPosition = children.indexOf(target);
                if (currentPosition === toPosition) return;

                if (beforeChild.parentNode === parent) {
                    // parent.insertBefore(this.dragEl, beforeChild);
                    if (toPosition > currentPosition) {
                        if (target.nextSibling) {
                            parent.insertBefore(this.dragEl, target.nextSibling);

                        } else {
                            parent.appendChild(this.dragEl);
                        }

                    } else {
                        parent.insertBefore(this.dragEl, target);
                    }
                }

                /*  console.log("oldPos:" + JSON.stringify(oldPos));
                 console.log("newPos:" + JSON.stringify(newPos)); */
                /* console.log(newPos.top === oldPos.top ? 'They are the same' : 'Not the same'); */
                // console.log(this.oldPos);
            }
        }
    }

    @bind()
    private onDragEnd(e: DragEvent) {
        e.preventDefault();
        // this.newPos = this.getChildren().map(_ => _.getBoundingClientRect());
        // console.log(this.newPos);
        this.dragEl.classList.remove('ghost');
        this.container.removeEventListener('dragover', this.onDragOver, false);
        this.container.removeEventListener('dragend', this.onDragEnd, false);

        let endDragIndex = this.getChildren().indexOf(this.dragEl);

        this.nextEl !== this.dragEl.nextSibling ? this.onUpdate(this.dragEl, this.startDragIndex, endDragIndex) : false;
    }

    private arrayMove(arr: any[], oldIndex: number, newIndex: number) {
        while (oldIndex < 0) {
            oldIndex += arr.length;
        }

        while (newIndex < 0) {
            newIndex += arr.length;
        }

        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;

            while (k--) {
                arr.push(undefined);
            }
        }

        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    };


    protected onUpdate(_draggedElement: HTMLElement, startDragIndex: number, endDragIndex: number) {
        if (!this.managedItems) return;

        //do the move of the underlying data
        this.arrayMove(this.managedItems, startDragIndex, endDragIndex);


        //move the node back to where it was so that lit doesnt freak out
        this.append(...this.originalChildrenOrder)


        //request a update so the above renderer moves it properly
        this.requestUpdate('managedItems');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-draggable-grid': ComponentDraggableGrid;
    }
}