import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

let highPromise: Promise<any>;

const getHigh = () => {
    if (!highPromise) {
        highPromise = new Promise<any>(async (s) => {
            // let highcharts = await import('highcharts/highcharts.src');
            await loadTrackingLibrary('https://cdnjs.cloudflare.com/ajax/libs/highstock/6.0.3/highstock.js');
            // let highcharts = await import('highcharts/highstock.src');

            s((window as any).Highcharts);
        });
    }


    return highPromise;
};

@customElement('component-highcharts')
class ComponentHighcharts extends BunnyElement {

    @property({type: Object})
    highcharts: any;

    @property({type: Object})
    chart: any;

    @property({type: Object})
    data: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            <div id="container"></div>
        `;
    }

    async connectedCallback() {
        super.connectedCallback();

        this.highcharts = await getHigh();
    }

    @observe('highcharts')
    doRender(highcharts: any) {
        this.chart = highcharts.stockChart(this.shadowRoot?.querySelector('#container'), {
            tooltip: {
                formatter() {
                    return `<div>
                        <strong>${RenderingHelper._dateFormat(new Date((this as any).x), 'dd/MM/yyyy HH:mm')}</strong><br>
                        ${(this as any).points.map((point: any) => `<span style="color: ${point.series.color};">■</span> ${point.series.name} · ${point.series.options.labelPrefix || ''}${point.y}`).join('<br>')}
                    </div>
`;
                },

                positioner: function (_labelWidth: any, _labelHeight: any, point: any) {
                    return {x: Math.max(0, point.plotX - _labelWidth), y: 35};
                },
                style: {
                    fontSize: 16,
                },
            },


            boost: false,
            turboThreshold: 0,

            rangeSelector: false,

            title: false,

            series: [],

            scrollbar: {
                enabled: false,
            },

            plotOptions: {
                series: {
                    animation: false,
                },
            },

            navigator: {
                enabled: false
            },


            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b',
                },
            },

            yAxis: {
                title: {
                    text: ' ',
                },
                min: 0,
            },
        });
    }

    @observe('chart', 'data')
    loadData(chart: any, data: any) {
        if (!chart) return;
        if (!data?.series) return;

        for (let series of chart.series) {
            series.remove();
        }


        for (let i in data.series) {
            chart.addSeries(data.series[i]);
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-highcharts': ComponentHighcharts;
    }
}