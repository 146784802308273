import {customElement, query} from 'lit/decorators.js';
import {html} from 'lit';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {UnshadowStyles} from '../../../__internal/local/controllers/UnshadowStyles';
import {sharedStyles} from '../../../../shared-styles';
import {Auth} from '../../../auth/local/controllers/Auth';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {PageLayoutDefault} from '../../../routing/local/pages/page-layout-default';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {config} from '../../../../config';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('page-layout-aspire-comps')
export class PageLayoutAspireComps extends PageLayoutDefault {

    auth = Auth.getInstance(this);

    //@ts-ignore
    private _unshadowStyles = new UnshadowStyles(this);

    @property({notify: true})
    route = Route.getInstance(this);

    private _lastMiniNavPercentage: number;

    @property({type: Boolean, reflect: true})
    @computed('route')
    get mobileNavOpen() {
        return !!this.route.current.data?.mobileNavOpen;
    };

    set mobileNavOpen(_value: boolean) {
        //ignore, just to have a setter for the attribute
    };

    @property({type: Boolean})
    renderFooter: boolean = true;

    @query('#headerContainer')
    headerContainer: HTMLElement;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                background: transparent;
                display: block;
                --header-spacing: 15px;
                padding-top: 122px;
                //padding-top: calc(60px + var(--header-spacing));

                #headerContainer {
                    --mini-nav-percentage: 0;
                    --header-height: calc(122px - (22px * var(--mini-nav-percentage)));
                    --header-bottom: calc(var(--header-height) + (-37px * var(--mini-nav-percentage)));
                    --header-spacing: 25px;

                    header {
                        text-align: center;
                        position: fixed;
                        top: calc(-37px * var(--mini-nav-percentage));
                        left: 0;
                        right: 0;
                        height: 100px;
                        height: var(--header-height);
                        box-shadow: 0 0 3px rgba(32, 32, 32, .2);
                        box-shadow: 0 0 1px white, 0 0 3px rgba(32, 32, 32, .2);
                        border-bottom: solid #e6e6e6 1px;
                        background: #f9f9f9;
                        z-index: 100;
                        will-change: auto;

                        .logo {
                            margin: auto 0;
                        }

                        .announcementMessage {
                            background: #8dc033;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            margin: 0;
                            color: #fff;
                            text-align: center;
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 5px;
                            height: 39px;
                        }
                    }
                }

                &:not([is-root-frame]) {
                    padding: 0;

                    #headerContainer, footer {
                        display: none;
                    }
                }

                @media screen and (max-height: 500px) {
                    #headerContainer {
                        --mini-nav-percentage: 1 !important;
                        padding-top: 60px;
                    }
                }

                component-shop-cart-overlay {
                    top: var(--header-bottom);
                }

                #banner, main, footer {
                    line-height: 1.5;
                }


                @media screen and (min-width: 1025px) {
                    .mobileOnly {
                        display: none !important;
                    }
                }

                //@media screen and (max-width: 1024px) {
                //    header component-menu {
                //        transform-origin: top;
                //        transform: scaleY(0);
                //        transition: .125s;
                //        position: fixed;
                //        top: var(--header-bottom);
                //        left: 0;
                //        right: 0;
                //        bottom: 0;
                //        padding: 25px;
                //        background: white;
                //        opacity: 0;
                //        --menus-group-items-flex-direction: column;
                //        box-shadow: 0 0 1px rgba(32, 32, 32, .3);
                //        overflow: auto;
                //    }
                //
                //    :host([mobile-nav-open]) header component-menu {
                //        transform: scaleX(1);
                //        opacity: 1;
                //    }
                //
                //}

                @media screen and (max-width: 1024px) {
                    .mobileOnly {
                        display: block;
                    }
                    header component-menu {
                        position: fixed;
                        top: var(--header-bottom);
                        right: 0;
                        bottom: 0;
                        transform: translateX(100px);
                        width: 300px;
                        max-width: 100%;
                        background: white;
                        z-index: 1;
                        transition: transform .25s, opacity .25s;
                        transform-origin: top right;
                        box-shadow: 0 1px 3px rgba(32, 32, 32, 0.2);
                        opacity: 0;
                        --menus-group-items-flex-direction: column;
                        pointer-events: none;
                        padding: 15px;
                        box-sizing: border-box;

                        //> * {
                        //    display: block;
                        //    padding: 8px 15px;
                        //    transform: translateX(10%);
                        //    opacity: 0;
                        //    transition: .125s;
                        //    transition-delay: 0s;
                        //}
                    }

                    header .desktopOnly {
                        display: none !important;
                    }

                    header:after {
                        content: '';
                        inset: 0;
                        top: var(--header-bottom);
                        position: fixed;
                        background: rgba(32, 32, 32, 0.2);
                        opacity: 0;
                        pointer-events: none;
                        transition: opacity .0625s;
                    }
                    &[mobile-nav-open] header:after {
                        opacity: 1;
                        pointer-events: all;
                    }
                    &[mobile-nav-open] header component-menu {
                        transform: translateX(0);
                        opacity: 1;
                        pointer-events: all;


                        //> * {
                        //    transform: none;
                        //    opacity: 1;
                        //    transition-delay: calc(.125s + (0.0625s * var(--index)));
                        //}
                    }

                    .toggleMobileNav {
                        position: relative;
                        font-size: 25px;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        display: inline-block;
                        text-align: center;
                        overflow: hidden;
                        transition: .125s linear;
                    }
                }

                footer {
                    padding-top: 50px;
                    padding-bottom: 25px;
                    background-color: #f5f5f5;
                    box-shadow: -25vw 0 0 #f5f5f5, 25vw 0 0 #f5f5f5;
                    --col-margin-bottom: 10 !important;

                    a {
                        color: #8dc033;
                    }

                    ul {
                        margin: 0;
                        padding: 0;
                    }

                    .socialLinks li + li {
                        margin-top: 10px;
                    }

                    component-media-view-image {
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        vertical-align: sub;
                    }

                    .quickLinks li:before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        background: url('/images/aspire-star.svg') no-repeat center center;
                        background-size: cover;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 5px;
                    }

                    h3 {
                        margin-bottom: 25px;
                    }

                    component-trustpilot-rating {
                        --link-padding: 7px 15px;
                        color: var(--primary-text-color);
                    }
                }
            }
        `,
    ];

    override render() {
        return html`
            <div id="headerContainer">
                ${this.doRenderHeader()}
                <component-shop-cart-overlay you-may-also-like-heading="Feeling lucky?"></component-shop-cart-overlay>
            </div>


            <div id="banner" data-region>
                ${this.renderRegion(this.content.banner)}
            </div>
            <main data-region class="gridContainer" style="--col-margin-top: 3; --mini-nav-percentage: 0">
                ${this.renderRegion(this.content.main)}
            </main>


            ${this.doRenderFooter()}
        `;
    }

    doRenderHeader() {
        return html`
            <header id="header" class="gridContainer noneContent" style="max-width: 100%;">
                <p class="announcementMessage" style="margin: 0">
                    Better odds <span class="desktopOnly">than other competition sites</span>! Win Big Spend Little
                </p>
                <div style="display: flex; margin-top: 5px; margin-bottom: 5px; font-weight: bold">
                    <a href="/" style="display: flex; min-width: 15vw">
                        <img src="/images/aspire-logo.svg" alt="Aspire comps"
                             style="margin: auto; height: calc(var(--header-height) - 55px); max-width: 300px; max-width: 33vw; ">
                    </a>

                    <div class="mobileOnly" style="margin: auto 15px auto auto">
                        <component-menus-item-shop-basket href="/shop/cart"
                                                          icon="icons:shopping-basket"></component-menus-item-shop-basket>
                        <component-menus-item-notifications
                                href="/account/notifications"></component-menus-item-notifications>
                    </div>

                    <a .extendHistoryData="${{mobileNavOpen: 1}}"
                       class="toggleMobileNav mobileOnly"
                       style="margin-top: auto; margin-bottom: auto; line-height: 0"
                       @click="${(e: MouseEvent) => {
                           if (this.mobileNavOpen) {
                               history.back();
                               e.preventDefault();
                           }
                       }}">
                        <component-button .raised="${false}"
                                          style="min-width: 0; --padding: 8px; background: transparent; color: var(--primary-text-color);">
                            <component-icon icon="icons:${this.mobileNavOpen ? 'close' : 'menu'}"
                                            style="vertical-align: middle"
                                            title="${this.mobileNavOpen ? 'Close' : 'Open'} navigation"></component-icon>
                        </component-button>
                    </a>

                    <component-menu id="menu"
                                    style="margin-top: auto; margin-bottom: auto; flex: 1; --menu-margin: 3px 0 0 0;"
                                    menuId="${config.site.menus.mainMenuId}"></component-menu>
                </div>
            </header>

        `;
        // <header>
        //     <div style="display: inline-block">
        //         LOGO
        //     </div>
        //     <nav>
        //         <a href="/" style="--index: 0">Home</a>
        //         <a href="/competitions" style="--index: 0">Competitions</a>
        //         <a href="/winners" style="--index: 1">Winners</a>
        //         <a href="/charities" style="--index: 2">Charities</a>
        //         <a href="/contact-us" style="--index: 3">Contact us</a>
        //         <hr style="--index: 4" class="mobileOnly">
        //         <a href="/get-the-app" style="--index: 5" class="mobileOnly">Get the app</a>
        //         ${this.auth.user ? html`
        //             <a href="/logout" style="--index: 6">logout</a>
        //         ` : html`
        //             <a href="/login" .historyData="${{something: 1}}" style="--index: 6">login</a>
        //         `}
        //     </nav>
        // </header>
    }

    doRenderFooter() {
        if (!this.renderFooter) return;
        let socialConfig = config.site.social;

        return html`
            <footer class="gridContainer noneContent" style="transform: translateZ(0)">
                <div style="text-align: center">
                    <div>
                        <component-button
                                style="text-transform: none; --padding: 0; border: solid #00b67a 1px; background-color: white"
                                .raised="${false}">
                            <component-trustpilot-rating .enableStars="${false}" landing="evaluate"
                                                         prefix="Review us on"></component-trustpilot-rating>
                        </component-button>
                    </div>
                </div>
                <div style="--col-md: 4">
                    <h3>Quick links</h3>
                    <ul class="quickLinks">
                        <li><a href="/about">About Aspire Competitions</a></li>
                        <li><a href="/how-to-enter">How to enter</a></li>
                        <li><a href="/faq">FAQ</a></li>
                        <li><a href="/terms-conditions">Terms & conditions</a></li>
                        <li><a href="/previous-winners">Previous winners</a></li>
                        <li><a href="/charities-we-support">Charities we support</a></li>
                        <li><a href="/contact-us">Contact us</a></li>
                        <li><a href="/account">My account</a></li>
                        <li><a href="/get-the-app">Get the app</a></li>
                    </ul>
                </div>
                <div style="--col-md: 4">
                    <h3>The UK’s best competition website.</h3>
                    <p>
                        Win top prizes from as little as 25p per ticket. Win that camper, bike or car you’ve always
                        dreamed of!
                    </p>
                    <ul class="socialLinks">
                        <li>
                            <a href="${socialConfig.facebook}" target="_blank">
                                <component-media-view-image width="30" height="30"
                                                            src="/images/social/facebook.svg"></component-media-view-image>
                                ${this.formatSocialUrl(socialConfig.facebook)}
                            </a>
                        </li>
                        <li>
                            <a href="${socialConfig.youtube}/videos" target="_blank">
                                <component-media-view-image width="254" height="179"
                                                            src="/images/social/youtube.svg"></component-media-view-image>
                                ${this.formatSocialUrl(socialConfig.youtube)}
                            </a>
                        </li>
                        <li>
                            <a href="${socialConfig.instagram}" target="_blank">
                                <component-media-view-image width="30" height="30"
                                                            src="/images/social/instergram.svg"></component-media-view-image>
                                ${this.formatSocialUrl(socialConfig.instagram)}
                            </a>
                        </li>
                        <li>
                            <a href="${socialConfig.twitter}" target="_blank">
                                <component-media-view-image width="30" height="30"
                                                            src="/images/social/twitter.svg"></component-media-view-image>
                                ${this.formatSocialUrl(socialConfig.twitter)}
                            </a>
                        </li>
                        <li>
                            <a href="${socialConfig.tiktok}" target="_blank">
                                <component-media-view-image width="30" height="30"
                                                            src="/images/social/tiktok.svg"></component-media-view-image>
                                ${this.formatSocialUrl(socialConfig.tiktok)}
                            </a>
                        </li>
                        <li>
                            <a href="${socialConfig.trustpilot}" target="_blank">
                                <component-media-view-image width="30" height="30"
                                                            src="/images/trustpilot.svg"></component-media-view-image>
                                Trustpilot
                            </a>
                        </li>
                    </ul>
                </div>
                <div style="--col-md: 4">
                    <h3>Want to get notified?</h3>
                    <p>
                        Getting notified by e-mail, sms and push is easy! Manage your notification preferences
                        about draws, early badgers, and extra goodies.
                        <small style="line-height: 1.2; display: block; margin-top: 5px">
                            Psst, and if that badgering you gets too much,
                            then you can turn them off at any point
                        </small>
                    </p>
                    <a href="/account/notifications/preferences">
                        <component-button style="display: block;">
                            My notification preferences
                        </component-button>
                    </a>

                    <h3>We accept</h3>
                    <div style="text-align: center">
                        <img width="63" height="40" src="/images/payment-logo--visa.svg" alt="Visa">
                        <img width="63" height="40" src="/images/payment-logo--mastercard.svg" alt="Mastercard">
                        <img width="63" height="40" src="/images/payment-logo--maestro.svg" alt="Maestro">
                        <img width="63" height="40" src="/images/payment-logo--amex.svg" alt="Amex"
                             style="background-color: #0079c1">
                        <img width="63" height="40" src="/images/payment-logo--apple-pay.svg" alt="Apple Pay">
                        <!--                            <img src="/images/payment-logo&#45;&#45;google-pay.svg" alt="Google Pay">-->
                    </div>
                </div>

                <div style="text-align: center; font-size: 14px; margin-top: 15px; padding-top: 15px; border-top: solid rgba(32, 32, 32, .1) 1px; color: gray">
                    © Aspire Competitions ${RenderingHelper._dateFormat(new Date(), 'yyyy')} · Aspire Competitions Ltd ·
                    Company No. 12067448 <br>
                    4-5 Seaton Mews, West Henford, Yeovil, BA20 2AG <br>
                    <a href="/privacy-policy">Privacy Policy</a>
                </div>
            </footer>

            <component-aspire-comps-live-banner></component-aspire-comps-live-banner>
        `;
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();

        window.addEventListener('scroll', this.onScroll);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        window.removeEventListener('scroll', this.onScroll);
    }

    formatSocialUrl(url: string) {
        url = url.replace('https://', '').replace('www.', '');

        return url[0].toUpperCase() + url.substring(1);
    }

    @bind()
    onScroll() {
        let miniNavPercentage = Math.min(window.scrollY / 60, 1);
        if (miniNavPercentage === this._lastMiniNavPercentage) return;
        this._lastMiniNavPercentage = miniNavPercentage;

        this.headerContainer.style.setProperty('--mini-nav-percentage', `${miniNavPercentage}`);
        (this.regions['']?.node as HTMLElement)?.style.setProperty('--mini-nav-percentage', `${miniNavPercentage}`);
    }

    @observe('mobileNavOpen')
    disableScrollOnMobileNavOpen(mobileNavOpen: boolean) {
        (document.body.parentElement as HTMLElement).classList.toggle('disableScroll', mobileNavOpen);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'page-layout-aspire-comps': PageLayoutAspireComps;
    }
}