import {customElement, query} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {delayPromise} from '../helpers/PromiseHelper';
import {PageContentComponent} from '../../../routing/shared/helpers/FirestoreHelper';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../helpers/decorators/BindDecoratorHelper';
import {listen} from '../helpers/decorators/ListenDecoratorHelper';

@customElement('component-tab-panels')
class ComponentTabPanels extends BunnyElement {

    @property({type: Array})
    panels: { tab: { title: string }, content: PageContentComponent }[] = [];

    @property({type: Object})
    data: any = {};

    @property({type: String})
    tabsClass: string = 'angledContainer forceMobileAngledContainer';

    @property({type: String})
    tabsStyle: string = '--angled-indent: 0px';

    @property({type: String})
    panelsClass: string = '';

    @property({type: String})
    panelsStyle: string = '';

    @property({type: String})
    selectedPanel: string;

    @property({type: Number})
    tabsScrollOffset: number = 0;

    @query('#tabs')
    tabsElement: HTMLElement;

    @query('#tabContents')
    tabContentsElement: HTMLElement;

    get tabsScrollMax() {
        return Math.max(0, (this.tabsElement.firstElementChild as HTMLElement).clientWidth - this.tabsElement.clientWidth);
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                --panel-background: transparent;
                --tab-background: var(--primary-text-color);
                --tab-active-background: var(--attention-color);
            }

            #tabs {
                position: relative;
                overflow: hidden;
                white-space: nowrap;
                background-color: var(--primary-text-color);
                box-shadow: 25vw 0 0 var(--primary-text-color), -25vw 0 0 var(--primary-text-color--lighter);
                font-size: 1.17em;
                font-weight: bold;
                --slide-x: 0px;
            }

            #tabs:before, #tabs:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 20px;
                background: linear-gradient(90deg, var(--primary-text-color), rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 75%, var(--primary-text-color)) no-repeat left center;
                background-size: 80px;
                z-index: 1;
                transition: .375s;
            }

            #tabs:before {
                left: -100vw;
                border-left: solid var(--primary-text-color) 100vw;
            }

            #tabs:after {
                right: -100vw;
                background-position-x: -60px;
                border-right: solid var(--primary-text-color) 100vw;
            }

            #tabs:not(.leftThing):before, #tabs:not(.rightThing):after {
                background-position-x: -40px;
                border-color: transparent;
            }

            #tabs .slideContainer {
                display: inline-flex;
                flex-direction: row;
            }

            #tabs .slideContainer > * {
                transform: translateX(var(--slide-x));
            }

            #tabsContainer {
                position: relative;
            }

            #tabsContainer:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: var(--tab-active-background);
                height: 3px;
                box-shadow: -25vw 0 0 var(--tab-active-background), 25vw 0 0 var(--tab-active-background);
            }

            #tabs component-button-toggle {
                background-color: var(--tab-background);
                color: white;
                transition: background-color .125s;
                border-radius: 0;
                flex-shrink: 0;
                margin: 0;
                --padding: calc(10px + .5vw) calc(15px + .5vw);
                border-right: solid var(--primary-text-color--lighter) 3px;
            }

            #tabs component-button-toggle[active] {
                background-color: var(--tab-active-background);
                pointer-events: none;
            }

            #panels {
                padding-top: 15px;
                background-color: var(--panel-background);
                box-shadow: -25vw 0 0 var(--panel-background), 25vw 0 0 var(--panel-background);
            }

            #panels > * {
                display: none;
            }

            #panels > .active {
                display: block;
            }
        `,
    ];

    override render() {
        return html`
            <div id="tabsContainer">
                <div id="tabs" class="${this.tabsClass}" style="${this.tabsStyle}">
                    <div id="tabContents" class="slideContainer continueAngled" style="overflow:hidden;">
                        ${this.panels.map(item => html`
                            <component-button-toggle
                                    @click="${(_: MouseEvent) => this.selectedPanel = this.calculatePanelId(item)}"
                                    .active="${this.isTabActive(item, this.selectedPanel)}"
                                    class="continueAngled">
                                <span class="unangled">
                                    ${item.tab.title}
                                </span>
                            </component-button-toggle>
                        `)}
                    </div>
                </div>
            </div>

            <div id="panels" class="${this.panelsClass}" style="${this.panelsStyle}">
                ${this.panels.map(panel => createComponent(panel.content, this.data, {
                    classList: this.calculatePanelId(panel) === this.selectedPanel ? 'active' : '',
                }))}
            </div>
        `;
    }

    isTabActive(panel: { tab: { title: string }, content: PageContentComponent }, selectedPanel: string) {
        return this.calculatePanelId(panel) === selectedPanel;
    }

    calculatePanelId(panel: { tab: { title: string }, content: PageContentComponent }) {
        return panel.tab.title.toLowerCase().replace(/[^0-9a-zA-Z-_]/, '-');
    }


    //mobile touch events of tab sliding
    //TODO cache the width of the tabs container, and clear on resize
    //TODO recenter tabs on selectedPanel changed


    @observe('selectedPanel')
    centerTabsOnSelectedPanelChange(selectedPanel: string) {
        let panelIndex = this.panels.findIndex(_ => this.calculatePanelId(_) === selectedPanel);

        let tab = this.tabContentsElement.children[panelIndex] as Element;
        let tabBounds = tab.getBoundingClientRect();
        this.tabsScrollOffset += tabBounds.x - (tabBounds.width / 2);
    }

    @observe('panels')
    async selectInitialTab(panels: { tab: { title: string }, content: PageContentComponent }[]) {
        if (!panels.length) return;

        this.selectedPanel = this.calculatePanelId(panels[0]);

        await delayPromise();
        this.tabsScrollOffset = 0;
    }

    private updateTabScrollPositionTimeoutId: number;

    @observe('tabsScrollOffset')
    updateTabsScrollOffset(tabsScrollOffset: number) {
        let tabsScrollMax = this.tabsScrollMax;

        if (tabsScrollOffset > tabsScrollMax) {
            this.tabsScrollOffset = tabsScrollMax;
            return;

        } else if (tabsScrollOffset < 0) {
            this.tabsScrollOffset = 0;
            return;
        }

        ((window.cancelAnimationFrame || clearTimeout) as any)(this.updateTabScrollPositionTimeoutId);
        this.updateTabScrollPositionTimeoutId = ((window.requestAnimationFrame || setTimeout) as any)(this.updateTabScrollPosition, 16);
    }

    @bind()
    updateTabScrollPosition() {
        let classList = this.tabsElement.classList;
        classList.toggle('leftThing', !(this.tabsScrollOffset === 0));
        classList.toggle('rightThing', !(this.tabsScrollOffset === this.tabsScrollMax));


        (this.tabsElement as HTMLElement).style.setProperty('--slide-x', `${-this.tabsScrollOffset}px`);
    }


    private tabsDragStartX: number = Number.MIN_VALUE;

    @listen('mousedown', 'tabs')
    @listen('touchstart', 'tabs')
    tabsDragStart(e: Event) {
        if (!this.tabsScrollMax) return;


        if (e instanceof MouseEvent) {
            this.tabsDragStartX = e.clientX + this.tabsScrollOffset;

        } else if (e instanceof TouchEvent) {
            this.tabsDragStartX = e.touches[0].clientX + this.tabsScrollOffset;
        }
    }

    @listen('mousemove', window)
    @listen('touchmove', window)
    tabsDrag(e: Event) {
        if (this.tabsDragStartX === Number.MIN_VALUE) return;


        if (e instanceof MouseEvent) {
            this.tabsScrollOffset = this.tabsDragStartX - e.clientX;

        } else if (e instanceof TouchEvent) {
            this.tabsScrollOffset = this.tabsDragStartX - e.touches[0].clientX;
        }
    }

    @listen('mouseup', window)
    @listen('touchend', window)
    tabsDragEnd(e: Event) {
        if (this.tabsDragStartX === Number.MIN_VALUE) return;


        if (e instanceof MouseEvent) {
            this.tabsScrollOffset = this.tabsDragStartX - e.clientX;

        } else if (e instanceof TouchEvent) {
            this.tabsScrollOffset = this.tabsDragStartX - e.changedTouches[0].clientX;
        }

        this.tabsDragStartX = Number.MIN_VALUE;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-tab-panels': ComponentTabPanels;
    }
}