import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {html} from 'lit';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {PrizeClaimingThreadStatus} from '../../shared/helpers/FirestoreHelper.ts';


@customElement('component-firestore-collection-list-item-cell-prize-winning-thread-status')
class ComponentFirestoreCollectionListItemCellPrizeWinningThreadStatus extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .statusContainer {
                border-radius: 10px;
                background: var(--primary-text-color);
                color: white;
                padding: 6px;
                display: inline-block;
                line-height: 1;


                &.statusIndicator-pendingAspire {
                    background: var(--attention-color);
                }

                &.statusIndicator-pendingCustomer {
                    background: var(--primary-color);
                }
            }
        `,
    ];


    override render() {
        return html`
            ${Object.entries((this.item._data?.status || {}) as Record<string, PrizeClaimingThreadStatus>).map(([key, value]) => html`
                <span class="statusContainer statusIndicator-${value.icon}" title="${value.icon} - ${value.updated}"
                      style="font-size: 14px">
                    ${key} <br> <small>${value.textContent}</small>
                </span>
            `)}
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-item-cell-prize-winning-thread-status': ComponentFirestoreCollectionListItemCellPrizeWinningThreadStatus;
    }
}