import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {internalAdminCall} from '../../../admin/local/helpers/AdminHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper.ts';


@customElement('component-prize-claiming-messages-winner-quote-response')
class ComponentPrizeClaimingMessagesWinnerQuoteResponse extends ComponentPrizeClaimingMessagesMessage {

    override render() {
        return html`
            ${super.render()}

            ${this.isAdmin ? html`
                <div class="footer">
                    <component-button style="background: var(--attention-color); display: block"
                                      @click="${this.doApprove}">
                        Approve
                    </component-button>
                </div>
            ` : undefined}
        `;
    }

    @confirmationDialog({title: 'Confirm winner quote?', body: 'Are you sure you want to approve this winner quote?'})
    @toastProgressWrapper({
        progressMessage: 'Approving quote',
        successMessage: 'Quote approved',
        failedMessage: 'Failed approving quote: {{e}}',
    })
    async doApprove() {
        let awardPoints = parseInt(prompt('How many points?', '1000') as any);
        if (isNaN(awardPoints)) throw new FriendlyMessage('Please enter a valid number of points to award or "0"');

        await internalAdminCall(`PrizeClaimingApproveQuote`, {
            threadId: this.prizeClaimingThread._ref?.id,
            quote: this.item.body,
            awardPoints: awardPoints,
        });

        this.dispatchEvent(new CustomEvent('scrollMessagesRequest'));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-winner-quote-response': ComponentPrizeClaimingMessagesWinnerQuoteResponse;
    }
}