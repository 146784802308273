import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {html} from 'lit';
import {ComponentShopProduct} from '../../../shop/local/components/component-shop-product';
import {
    ComponentAspireCompsWinner,
} from '../../../aspire-comps-competitions/local/components/component-aspire-comps-winner';
import {
    ComponentAspireCompsCharity,
} from '../../../aspire-comps-competitions/local/components/component-aspire-comps-charity';
import {RoutingPage} from '../../../routing/local/pages/routing-page';

@customElement('component-admin-toggle')
class ComponentAdminToggle extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Boolean})
    @computed('auth')
    get enabled() {
        return RenderingHelper._accountHasPermission(this.auth.user, 'app.admin');
    }

    override render() {
        return html`
        `;
    }

    @observe('enabled')
    attach(enabled: boolean) {
        if (!enabled) return;

        window.addEventListener('keypress', this.handleKeyPress, {passive: true});
    }

    getShadowActiveElement() {
        let activeElement = document.activeElement;
        while (activeElement?.shadowRoot) {
            activeElement = activeElement.shadowRoot.activeElement;
        }

        return activeElement;
    }

    @bind()
    handleKeyPress(e: KeyboardEvent) {
        if (e.code !== 'BracketRight') return;

        let activeElement = this.getShadowActiveElement();
        if (activeElement && ['TEXTAREA', 'INPUT'].includes(activeElement.tagName)) return;


        let path = window.location.pathname;
        let adminShopProducts = '/admin/shop/products';
        let shopProducts = '/shop/products';
        let adminWinners = '/admin/competitions/winners';
        let winners = '/previous-winners';
        let adminCharities = '/admin/competitions/charities';
        let charities = '/charities-we-support';
        let routingPage = document.querySelector('aspire-app routing-page') as RoutingPage;
        let routingPageFirstChild = routingPage.querySelector('[is-root-frame]');
        if (path.startsWith(`${adminShopProducts}/`)) {
            HistoryHelper.pushState(path.replace(`${adminShopProducts}/`, `${shopProducts}/`));

        } else if (path.startsWith(`${shopProducts}/`)) {
            HistoryHelper.pushState(`${adminShopProducts}/${routingPageFirstChild?.querySelector<ComponentShopProduct>('component-shop-product')?.product?.data?._ref?.id}`);

        } else if (path.startsWith(adminShopProducts)) {
            HistoryHelper.pushState(shopProducts);

        } else if (path.startsWith(shopProducts)) {
            HistoryHelper.pushState(adminShopProducts);

        } else if (path.startsWith(`${adminWinners}/`)) {
            HistoryHelper.pushState(path.replace(`${adminWinners}/`, `${winners}/`));

        } else if (path.startsWith(`${adminWinners}`)) {
            HistoryHelper.pushState(winners);

        } else if (path.startsWith(`${winners}/`)) {
            HistoryHelper.pushState(`${adminWinners}/${routingPageFirstChild?.querySelector<ComponentAspireCompsWinner>('component-aspire-comps-winner')?.winner?.data?._ref?.id}`);

        } else if (path.startsWith(winners)) {
            HistoryHelper.pushState(adminWinners);

        } else if (path.startsWith(`${adminCharities}/`)) {
            HistoryHelper.pushState(path.replace(`${adminCharities}/`, `${charities}/`));

        } else if (path.startsWith(`${adminCharities}`)) {
            HistoryHelper.pushState(charities);

        } else if (path.startsWith(`${charities}/`)) {
            HistoryHelper.pushState(`${adminCharities}/${routingPageFirstChild?.querySelector<ComponentAspireCompsCharity>('component-aspire-comps-charity')?.charity?.data?._ref?.id}`);

        } else if (path.startsWith(charities)) {
            HistoryHelper.pushState(adminCharities);

        } else {
            HistoryHelper.pushState(`/admin/routing/${routingPage.page?._ref?.id}`);
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-toggle': ComponentAdminToggle;
    }
}