import {customElement} from 'lit/decorators.js';
import '../../../../shared-styles';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {
    FIRESTORE_COLLECTION_MENU,
    FIRESTORE_COLLECTION_MENU_SUB_ITEMS,
    MenuDocument,
    MenuItemDocument,
} from '../../shared/helpers/FirestoreHelper';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {FirestoreCollection} from '../../../__internal/local/controllers/FirestoreCollection';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {config} from '../../../../config';

@customElement('component-menu')
class ComponentMenu extends BunnyElement {

    @property({type: Object, notify: true})
    @computed('menuId')
    get menu() {
        if (!this.menuId) return undefined;

        let staticMenu = config.site.staticMenus[this.menuId];

        return new FirestoreDocument<MenuDocument>(
            this,
            `${FIRESTORE_COLLECTION_MENU}/${this.menuId}`,
            {
                method: staticMenu ? FetchMethod.STATIC : FetchMethod.CACHE_FIRST,
                staticContent: staticMenu ? {
                    ref: {},
                    metadata: {},
                    data() {
                        return staticMenu.menu;
                    },
                } : undefined,
            } as any,
        );
    }

    @property({type: Object, notify: true})
    @computed('menuId')
    get menuItems() {
        let staticMenu = config.site.staticMenus[this.menuId];

        return new FirestoreCollection<MenuItemDocument>(
            this,
            `${FIRESTORE_COLLECTION_MENU}/${this.menuId}/${FIRESTORE_COLLECTION_MENU_SUB_ITEMS}`,
            {
                method: staticMenu ? FetchMethod.STATIC : FetchMethod.CACHE_FIRST,
                staticContent: staticMenu ? {
                    ref: {},
                    metadata: {},
                    docs: staticMenu.items.map((item: any) => {
                        return {
                            ref: {},
                            metadata: {},
                            data() {
                                return item;
                            },
                        };
                    }),
                } : undefined,
            } as any,
        );
    }

    @property({type: String})
    menuId: string;

    @property({type: Object})
    data: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            ${this.menu?.data && this.menuItems?.data?.length ? createComponent(
                    this.menu.data,
                    this.data,
                    {
                        menu: this.menu.data,
                        data: this.data,
                        rawItems: this.menuItems.data,
                    },
            ) : undefined}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-menu': ComponentMenu;
    }
}