import {customElement} from 'lit/decorators.js';
import {ComponentFormsGroup} from './component-forms-group';
import {html} from 'lit';

@customElement('component-forms-group-2-col')
export class ComponentFormsGroup2Col extends ComponentFormsGroup {

    override render(){
        let elementTemplates = this.generateElementTemplates(this.elements, this.value, this.data);

        return html`
            ${this.baseRender()}

            <div class="gridContainer row" style="${this.elementsContainerStyle}">
                <div>
                    <div id="topElements" class="elements ${this.elementsContainerClassList}">
                        ${elementTemplates.topElements}
                    </div>
                </div>

                <div style="--col: 8">
                    <div id="elements" class="elements ${this.elementsContainerClassList}">
                        ${elementTemplates.elements}
                    </div>
                </div>
                <div style="--col: 4">
                    <div id="sideElements" class="elements ${this.elementsContainerClassList}">
                        ${elementTemplates.sideElements}
                    </div>
                </div>
            </div>
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-forms-group-2-col': ComponentFormsGroup2Col;
    }
}