export const downloadFile = (data: string, filename: string, type: string) => {
    let file = new Blob([data], {type: type});
    let a = document.createElement('a');
    let url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.onclick = (e) => {
        e.stopPropagation();
    };
    a.click();

    setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
};