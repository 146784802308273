import {customElement} from 'lit/decorators.js';
import {ComponentFirestoreCollectionList} from './component-firestore-collection-list';
import {quickDotGet} from '../../shared/helpers/DotHelper';
// import '../../../../components/resolve-firestore-document-name';//TODO rework this component
import {BunnyElement} from './bunny-element';
import './component-dialogable-link';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../helpers/RenderingHelper';

const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;

/**
 * @todo add fixed datetime format if ago is being used and set it to the title
 */
@customElement('component-firestore-collection-list-item-cell')
export class ComponentFirestoreCollectionListItemCell extends BunnyElement {

    @property({type: String})
    type: string = 'text';

    @property({type: String})
    format: string = null as any;

    @property({type: Object})
    list: ComponentFirestoreCollectionList;

    @property({type: String})
    field: string;

    @property({type: Object})
    item: any;

    @property({type: String})
    defaultValue: string = '';

    @property({type: String})
    href: string = '';

    @property({type: Boolean})
    dialogable: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            a {
                display: block;
            }
        `,
    ];

    override render() {
        let itemId = this.item?.id || this.item?._ref?.id;

        let innerContent = html`${this.type === 'reference' ? html`
            <resolve-firestore-document-name .path="${this.getReferencePath(this.field, this.item)}"
                                             .format="${this.format}"></resolve-firestore-document-name>
        ` : html`
            ${this.renderContent(this.type, this.format, this.field, this.item, this.defaultValue)}
        `}`;

        return html`
            ${this.href ? html`
                ${this.dialogable ? html`
                    <component-dialogable-link title="Dialog" href="${this.href}${itemId}">
                        <component-button raised>${innerContent}</component-button>
                    </component-dialogable-link>
                ` : html`
                    <a href="${this.href}${itemId}">
                        <component-button raised> ${innerContent}</component-button>
                    </a>
                `}
            ` : innerContent}
        `;
    }


    getReferencePath(field: string, item: any) {
        return this.getContent(field, item)?.path;
    }

    getContent(field: string, item: any) {
        if (!field) return '';
        if (!item) return '';

        return quickDotGet(field, item._data ? item._data : item);
    }

    renderContent(type: string, format: string, field: string, item: any, defaultValue: string) {
        let value = this.getContent(field, item);
        if (!value && value !== 0) return defaultValue;


        switch (type) {
            case 'reference':
                return value;

            case 'text':
                return value;

            case 'datetime':
                if (format === 'ago') {
                    return RenderingHelper._dateFromNow(value);

                } else {
                    format ??= DEFAULT_DATETIME_FORMAT;
                    return RenderingHelper._dateFormat(value, format);
                }

            case 'time':
                return RenderingHelper._dateFormat(value, format || DEFAULT_TIME_FORMAT);

            case 'date':
                return RenderingHelper._dateFormat(value, format || DEFAULT_DATE_FORMAT);

            case 'currency':
                return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(value / 100);

            default:
                console.error(`Unknown type ${type} for field render ${field} on item`, item, this);
                debugger;
                return defaultValue;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-item-cell': ComponentFirestoreCollectionListItemCell;
    }
}