import {AdminPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './pages/page-layout-admin';
import './components/component-admin-toggle';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';

export class AdminLocalPackage extends AdminPackage implements LocalPackage {

    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            e.registerPostComponent({
                component: 'component-admin-toggle',
            }, undefined, true);
        },
    };

}