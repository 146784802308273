import {customElement} from 'lit/decorators.js';
import {PageLayoutDefault} from '../../../routing/local/pages/page-layout-default';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {config} from '../../../../config';

@customElement('page-layout-admin')
class PageLayoutAdmin extends PageLayoutDefault {

    @property({type: Boolean, reflect: true})
    mobileNavOpen: boolean = false;

    @property({notify: true})
    route = Route.getInstance(this);

    static override styles = [
        sharedStyles,
        // language=SCSS
        ...super.styles,
        scss`
            :host {
                --header-height: 41px;
                --header-spacing: 15px;
                padding-top: calc(var(--header-height) + var(--header-spacing));
            }

            header {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                height: var(--header-height);
                box-shadow: 0 0 2px rgba(32, 32, 32, .2);
                background: #f9f9f9;
                z-index: 100;
            }

            header .mobileOnly {
                display: none;
            }

            @media screen and (max-width: 1024px) {
                header component-menu {
                    transform-origin: top;
                    transform: scaleY(0);
                    transition: .125s;
                    position: fixed;
                    top: var(--header-height);
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: 25px;
                    background: white;
                    opacity: 0;
                    --menus-group-items-flex-direction: column;
                    box-shadow: 0 0 1px rgba(32, 32, 32, .3);
                    overflow: auto;
                }

                :host([mobile-nav-open]) header component-menu {
                    transform: scaleX(1);
                    opacity: 1;
                }

                header .mobileOnly {
                    display: flex;
                }
            }
        `];


    override render() {
        return html`
            ${this.isRootFrame ? html`
                <header id="header" class="gridContainer" style="--col-margin-top: 3; max-width: 100%;">
                    <a href="/" style="--col: 10; align-items: center; margin: 0;" class="mobileOnly">
                        Site
                    </a>
                    <component-button-toggle .active="${this.bind.mobileNavOpen}"
                                             style="--col: 2; text-align: center; margin: 0; min-width: 0;"
                                             class="mobileOnly">
                        <component-icon icon="icons:${this.mobileNavOpen ? 'close' : 'menu'}"></component-icon>
                    </component-button-toggle>
                    <component-menu style="margin-top: auto; margin-bottom: auto"
                                    .menuId="${config.site.menus.adminMenuId}"></component-menu>
                </header>
            ` : undefined}

            <main class="gridContainer" id="main" data-region>
                ${this.renderRegion(this.content.main)}
            </main>
        `;
    }


    @observe('route')
    closeNavOnRouteChange(_route: Route) {
        this.mobileNavOpen = false;
    }

    @observe('mobileNavOpen')
    disableScrollOnMobileNavOpen(mobileNavOpen: boolean) {
        let classList = (document.body.parentElement as HTMLElement).classList;

        classList.toggle('disableScroll', mobileNavOpen);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'page-layout-admin': PageLayoutAdmin;
    }
}