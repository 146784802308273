import {customElement} from 'lit/decorators.js';
import {ComponentInputMediaFileInfoCollection} from '../../../media/local/components/component-input-media-file-info-collection';
import {PageContentComponent} from '../../../routing/shared/helpers/FirestoreHelper';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';

@customElement('component-input-shop-product-media-file-info-collection')
class ComponentInputShopProductMediaFileInfoCollection extends ComponentInputMediaFileInfoCollection {

    @property({type: Boolean, reflect: true})
    fullscreen: boolean = false;

    @property({type: Array})
    usages: string[] = ['thumbnail', 'featured'];

    @property({type: Array})
    mediaSuppliers: PageContentComponent[] = [];

    @property({type: Number, reflect: true})
    @computed('value')
    get itemCount(){
        return (this.value || []).length;
    }

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            .popoutable {
                position: relative;
            }

            .popoutable .popoutableUnpopped {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
                background: rgba(32, 32, 32, .3);
                color: white;
                line-height: 40px;
                cursor: pointer;
                opacity: 0;
                transition: .125s;
            }

            .popoutable .popoutableUnpopped:hover,
            :host([item-count="0"]) .popoutable .popoutableUnpopped{
                opacity: 1;
            }

            :host([fullscreen]) .popoutable {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 15px;
                background: white;
                z-index: 100;
                display: flex;
                flex-direction: column;
                box-shadow: 0 0 0 100vw rgba(32, 32, 32, .6), var(--shadow-elevation-2dp-box-shadow);
            }

            :host([fullscreen]) .popoutable .popoutableUnpopped {
                display: none;
            }

            .popoutableTitle {
                display: none;
                background: var(--primary-color);
                color: white;
            }

            .popoutableTitle, .popoutableControls {
                display: none;
            }

            :host([fullscreen]) .popoutableTitle {
                display: flex;
                align-items: center;
                padding-left: 15px;
            }

            :host([fullscreen]) .popoutableControls {
                display: flex;
                margin: 8px 15px;
            }

            :host([fullscreen]) .popoutableControls > * {
                flex: 1;
                max-width: 50%;
            }

            :host([fullscreen]) .popoutableContents {
                flex: 1;
                overflow: auto;
                margin: 8px 15px;
            }

            component-draggable-grid{
                min-height: 100px;
            }
        `
    ];

    override render(){
        return html`
            ${this.label ? html`
                <div class="label">
                    ${this.label}
                </div>
            ` : undefined}

            <div class="popoutable">
                <div class="popoutableTitle">
                    Manage

                    <component-button-toggle .active="${this.bind.fullscreen}" style="margin-left: auto; min-width: 0">
                        X
                    </component-button-toggle>
                </div>

                <div id="popoutableControls" class="popoutableControls">
                    ${this.mediaSuppliers.map(mediaSupplierSpec=>createComponent({
                        ...mediaSupplierSpec,
                        events:[{
                            'value-changed': (e:CustomEvent)=>{
                                this.value = e.detail.value;
                                this.requestUpdate('value');
                            }                            
                        }]
                    }, {}, {
                        value:this.value,
                    }))}
                </div>


                <component-draggable-grid class="popoutableContents" .enabled="${this.fullscreen}" .managedItems="${this.bind.value}" @managed-items-changed="${(_e:CustomEvent)=>{this.requestUpdate('value')}}">
                    ${this.value?.map((item:any)=>html`
                        <component-input-media-file-info .value="${item}" style="width: 33%; padding: 0">
                            ${this.fullscreen ? html`
                                <component-input-select style="padding: 0; position:absolute; top: 0; left: 0; background: var(--attention-color); border-bottom-right-radius: 4px">
                                    ${this.usages.map(usage=>html`
                                        <component-input-select-item @click="${this.toggleUsage}" .item="${item}" data-usage="${usage}">
                                            <component-input-checkbox .checked="${item.usages?.includes(usage)}">
                                                ${usage}
                                            </component-input-checkbox>
                                        </component-input-select-item>
                                    `)}
                                    <hr>
                                    <component-input-select-item @click="${this.delete}" .item="${item}">
                                        <component-icon icon="icons:delete-forever"></component-icon>
                                        Delete
                                    </component-input-select-item>
                                </component-input-select>
                            ` : undefined}

                            ${item.usages && item.usages.length ? html`
                                <div style="position:absolute; bottom: 0; left: 0; right: 0; background: rgba(32, 32, 32, .5); padding: 3px;  text-align: center; font-size: 10px; color: white">
                                    ${item.usages.join(' · ')}
                                </div>
                            ` : undefined}
                        </component-input-media-file-info>
                    `)}
                </component-draggable-grid>
                <component-button-toggle .active="${this.bind.fullscreen}" class="popoutableUnpopped">Manage</component-button-toggle>
            </div>
        `;
    }

    @observe('value')
    loadDefaultValue(value: any[]){
        if(typeof value === 'undefined'){
            this.value = [];
            return;
        }


        // observeArrayChange(this.value, () => {
        //     this.notifySplices('value', this.value);
        // });
        //
        //
        // for(let mediaSupplierNode of this.$.popoutableControls.children){
        //     (mediaSupplierNode as any).value = this.value;
        // }
    }

    toggleUsage(e: MouseEvent) {
        let target = e.currentTarget as any | HTMLElement;
        let item = target.item;
        let usage = target.dataset.usage;


        item.usages??=[];
        let usageActiveIndex = item.usages.indexOf(usage);
        if (usageActiveIndex !== -1) {
            item.usages.splice(usageActiveIndex, 1);

        } else {
            item.usages.push(usage);
        }


        this.requestUpdate('value');
    }

    delete(e: MouseEvent) {
        let target = e.currentTarget as any | HTMLElement;
        let item = target.item;
        let itemIndex = this.value.indexOf(item);

        this.doDelete(itemIndex);
    }

    @confirmationDialog({body: 'You really wanna remove this media?'})
    doDelete(itemIndex: number) {
        this.value.splice(itemIndex, 1);
        this.requestUpdate('value');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-shop-product-media-file-info-collection': ComponentInputShopProductMediaFileInfoCollection;
    }
}