import {customElement, query} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html, PropertyValues} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {cache} from 'lit/directives/cache.js';

let libraryLoader: Promise<any>;
let loadLibrary = () => {
    if (!libraryLoader) {
        libraryLoader = loadTrackingLibrary('https://cdn.quilljs.com/1.3.6/quill.js');
    }

    return libraryLoader;
};

@customElement('component-input-quill')
class ComponentInputQuill extends BunnyElement {

    @property({type: Object})
    editor: any;

    @property({type: String, notify: true})
    value: string;

    @query('#editor')
    editorElement: HTMLElement;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .ql-toolbar {
                position: sticky;
                top: 0;
            }
        `,
    ];

    override render() {
        return html`
            <link href="https://cdn.quilljs.com/1.0.0/quill.snow.css" rel="stylesheet">
            <div id="toolbar"></div>
            ${cache(html`
                <div id="editor">
                    <p>Hello World!</p>
                </div>
            `)}
        `;
    }

    async connectedCallback() {
        super.connectedCallback();

        this.initEditor();
    }

    patchEditor() {
        this.editor.selection.getNativeRange = () => {
            const dom = this.editor.root.getRootNode();
            const selection = dom.getSelection();
            if (selection == null || selection.rangeCount <= 0) return null;
            let nativeRange = selection.getRangeAt(0);
            if (nativeRange == null) return null;
            return this.editor.selection.normalizeNative(nativeRange);
        };

        document.addEventListener('selectionchange', () => {
            this.editor.selection.update();
        });
    }

    async initEditor() {
        if (!this.editor) {
            await loadLibrary();
            this.editor = new (window as any).Quill(
                this.editorElement,
                {
                    theme: 'snow',
                },
            );
            this.editor.on('text-change', this.saveContent);
            this.patchEditor();
        }
    }

    private skipLoadingContent: boolean;

    protected shouldUpdate(_changedProperties: PropertyValues): boolean {
        if (this.editor && _changedProperties.size === 1 && _changedProperties.has('value')) return false;//if the editor has inited then prevent updating for value changing

        return super.shouldUpdate(_changedProperties);
    }

    @observe('value')
    loadContent(value: string) {
        if (this.skipLoadingContent) return;

        this.editorElement.innerHTML = value;
    }

    @bind()
    saveContent() {
        this.skipLoadingContent = true;
        this.value = this.editor.root.innerHTML;
        this.dispatchEvent(new CustomEvent('value-changed', {detail: {value: this.value}}));
        this.skipLoadingContent = false;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-quill': ComponentInputQuill;
    }
}