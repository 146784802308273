import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {internalAdminCall} from '../../../admin/local/helpers/AdminHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';


@customElement('component-prize-claiming-messages-winner-pic-response')
class ComponentPrizeClaimingMessagesWinnerPicResponse extends ComponentPrizeClaimingMessagesMessage {

    static override styles = [
        ...ComponentPrizeClaimingMessagesMessage.styles,
        // language=SCSS
        scss`
            component-media-view-image {
                width: 300px;
            }
        `,
    ];

    override render() {
        return html`
            ${super.render()}

            <div class="footer" style="padding: 0; line-height: 0">
                ${this.item.meta?.attachments?.map(item => html`
                    <component-media-view-image .media="${item}"
                                                type="default-thumbnail"
                                                fetch-method="live"
                                                .width="${400}"
                                                .height="${300}"
                                                style="background-color: #f2f2f2; flex-shrink: 0"></component-media-view-image>
                `)}

                ${this.isAdmin ? html`
                    <small style="position:absolute; bottom: 15px; right: 15px">
                        <component-button @click="${this.doApprove}"
                                          style="background: var(--attention-color); min-width: 0">
                            Approve
                        </component-button>
                    </small>
                ` : undefined}
            </div>
        `;
    }

    @confirmationDialog({title: 'Confirm winner pic?', body: 'Are you sure you want to approve this winner pic?'})
    @toastProgressWrapper({
        progressMessage: 'Approving pic',
        successMessage: 'Pic approved',
        failedMessage: 'Failed approving pic: {{e}}',
    })
    async doApprove() {
        let attachment = (this.item.meta?.attachments || [])[0];
        if (!attachment) throw new FriendlyMessage('Missing attachment');

        let awardPoints = parseInt(prompt('How many points?', '1000') as any);
        if (isNaN(awardPoints)) throw new FriendlyMessage('Please enter a valid number of points to award or "0"');


        await internalAdminCall(`PrizeClaimingApprovePic`, {
            threadId: this.prizeClaimingThread._ref?.id,
            mediaId: attachment.media.id,
            awardPoints: awardPoints,
        });

        this.dispatchEvent(new CustomEvent('scrollMessagesRequest'));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-winner-pic-response': ComponentPrizeClaimingMessagesWinnerPicResponse;
    }
}