import {customElement} from 'lit/decorators.js';
import {ComponentInput} from './component-input';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';

@customElement('component-input-number')
export class ComponentInputNumber extends ComponentInput {

    @property({type: Number, notify: true})
    value: any = 0;
    
    @property()
    type = 'number';

    @property({type: String})
    valueAttribute = 'valueAsNumber';

    @property({type: Number})
    step: number = 1;

    @property({type: Number})
    min!: number;

    @property({type: Number})
    max!: number;

    @property({type: Object})
    @computed('min', 'max', 'step')
    get extraAttributes() {
        return {
            min: this.min,
            max: this.max,
            step: this.step,
        } as any;
    }


    @listen('blur')
    clampMinMaxValueOnBlur() {
        if (!(typeof this.min === 'number' && typeof this.max === 'number')) return;

        this.value = Math.max(this.min, Math.min(this.value, this.max));
    }

    @observe('value')
    updateHasValue(_value: any) {
        this.hasValue = true;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'component-input-number': ComponentInputNumber;
    }
}
