import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-admin-auth-tracking')
class ComponentAdminAuthTracking extends BunnyElement {

    @property({type: String})
    accountId: string;

    @property({type: Array})
    devices: any[] = [];

    @property({type: Array})
    sessions: any[] = [];


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            h4 + div {
                overflow: auto;
                max-height: 400px;
            }
        `,
    ];

    override render() {
        return html`
            <h4>Account</h4>
            <p>
                <a href="/admin/firebase-analytics/activity-stream?q=${encodeURIComponent(`accountId="${this.accountId}"`)}">Open
                    account activity feed</a>
            </p>

            <h4>Devices</h4>
            <div>${this.devices}</div>

            <h4>Sessions</h4>
            <div>${this.sessions}</div>
        `;
    }

    @observe('accountId')
    async loadAccountAuthMethods(accountId: string) {
        if (!accountId) return;

        let data = await adminCall.authGetAccountTrackingInfo(accountId);

        this.devices = data.devices
            .map((device) => html`
                <div>
                    <a title="Open device activity feed"
                       href="/admin/firebase-analytics/activity-stream?q=${encodeURIComponent(`deviceId="${device.id}"`)}">${device.id}</a>:
                    Created: ${new Date(device.created).toISOString()} - Last active:
                    ${new Date(device.lastActive as string).toISOString()} <br>
                    <div style="margin-left: 50px">${JSON.stringify(device.userAgent)}</div>
                </div>`);

        this.sessions = data.sessions
            .map((session) => {
                let created = session.created;
                let lastActive = session.lastActive as string;
                let id = session.id as string;
                delete session.created;
                delete session.lastActive;
                delete session.id;

                return html`
                    <div>
                        <a title="Open session activity feed"
                           href="/admin/firebase-analytics/activity-stream?q=${encodeURIComponent(`sessionId="${id}"`)}">${id}</a>:
                        Created: ${new Date(created).toISOString()} - Last active: ${new Date(lastActive).toISOString()}
                        <br>
                        <div style="margin-left: 50px">${JSON.stringify(session)}</div>
                    </div>`;
            });
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-auth-tracking': ComponentAdminAuthTracking;
    }
}