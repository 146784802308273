import {customElement} from 'lit/decorators.js';
import {html, PropertyValues} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper.ts';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper.ts';
import {adminCall} from '../../../admin/local/helpers/AdminHelper.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';


const RENDERERS: Record<string, (item: any) => any> = {
    _DEFAULT: (item: any) => {
        return html`
            <div>
                ${JSON.stringify(item)}
            </div>
        `;
    },
    'timeline-mark': (item: any) => {
        return html`
            ${item.created}
        `;
    },
    'app-performance': (item: any) => {
        let performanceEntries = item.data.performanceEntries?.map((_: any) => `${_.entryType.padStart(10, ' ').substring(0, 10)}(${_.duration.toFixed(0).padStart(3, ' ')}ms): ${_.name} - ${JSON.stringify(_)}`)
            .join('\n');

        return html`
            <h3>Performance sample</h3>
            <pre style="max-height: 300px; overflow: auto">${performanceEntries}</pre>
            <p>
                <small>
                    ${item.data.url}
                </small>
            </p>
        `;
    },
    'app-error': (item: any) => {
        let stack = (item.data.error.stack || []).map((_: any) => _.func === '?' ?
            `    at ${_.url}:${_.line}:${_.column}` :
            `    at ${_.func} (${_.url}:${_.line}:${_.column})`)
            .join('\n');

        let performanceEntries = item.data.performanceEntries?.map((_: any) => `${_.entryType.padStart(10, ' ').substring(0, 10)}(${_.duration.toFixed(0).padStart(3, ' ')}ms): ${_.name} - ${JSON.stringify(_)}`)
            .join('\n');

        return html`
            <h3>${item.data.error.name}: ${item.data.error.message}</h3>
            <pre>${stack}</pre>
            ${performanceEntries ? html`
                <hr style="margin-top: 15px; margin-bottom: 15px">
                <h4>Performance</h4>
                <pre style="max-height: 300px; overflow: auto">${performanceEntries}</pre>
            ` : undefined}
            <p>
                <small>
                    ${item.data.url}
                </small>
            </p>
        `;
    },
    'interaction-click': (item: any) => {
        return html`
            <h3>
                Interaction click: ${item.data.context.parentTextContent}
            </h3>
            <p>
                ${item.data.context.parentSelector?.join(', ')}
            </p>
            <p>
                <small>
                    ${item.data.url}
                </small>
            </p>
        `;
    },
    'interaction-view': (item: any) => {
        return html`
            <h3>
                Interaction view: ${item.data.context.parentTextContent}
            </h3>
            <p>
                Duration: ${Math.round(item.data.duration / 1000)}s<br>
                ${item.data.context.parentSelector?.join(', ')}
            </p>
            <p>
                <small>
                    ${item.data.url}
                </small>
            </p>
        `;
    },
    'device-active': (item: any) => {
        return html`
            <h3>
                Device: ${item.deviceId}
            </h3>
            <p>
                ${item.data.device.userAgent.screen[0]}
                x ${item.data.device.userAgent.screen[1]}
                    @${item.data.device.userAgent.resolution} <br>
                ${item.accountId ? html`
                    Account: ${item.accountId}
                ` : undefined}
            </p>
            <p>
                <small>
                    ${item.data.device.userAgent?.name}
                </small>
            </p>
        `;
    },
    'session-active': (item: any) => {
        return html`
            <h3>
                Session: ${item.sessionId}
            </h3>
            <p>
                ${item.ip} <br>
                ${item.accountId ? html`
                    Account: ${item.accountId}
                ` : undefined}
            </p>
            <p>
                <small>
                    Tab: ${item.tabId}
                </small>
            </p>
        `;
    },
    'page-view': (item: any) => {
        return html`
            <h3>
                Page view: ${item.data.title}
            </h3>
            <p>
                Depth: ${item.data.navigationDepth} <br>
                History depth: ${item.data.historyDepth}
            </p>
            <p>
                <small>
                    ${item.data.url}
                </small>
            </p>
        `;
    },
    'view-content': (item: any) => {
        return html`
            <h3>
                View content: ${item.data.contentName}
            </h3>
            <p>
                ${item.data.contentValue} <br>
                ${JSON.stringify(item.data.contentMeta)}
            </p>
            <p>
                <small>
                    ${item.data.url}
                </small>
            </p>
        `;
    },
    'add-to-cart': (item: any) => {
        return html`
            <h3>
                Add to cart: ${item.data.contentName}
            </h3>
            <p>
                Quantity: ${item.data.contentValue} * ${item.data.quantity} = ${item.data.value}
            </p>
            <p>
                <small>
                    ${item.data.url}
                </small>
            </p>
        `;
    },
    'start-checkout': (item: any) => {
        return html`
            <h3>
                Start checkout: ${item.data.value}
            </h3>
            <ul>
                ${item.data.orderItems.map((orderItem: any) => html`
                    <li>${orderItem.contentName} &middot; ${orderItem.contentValue} * ${orderItem.quantity} =
                        ${orderItem.value}
                    </li>
                `)}
            </ul>
        `;
    },
    'purchase': (item: any) => {
        return html`
            <h3>
                Purchase: ${item.data.value}
            </h3>
            <ul>
                ${item.data.orderItems.map((orderItem: any) => html`
                    <li>${orderItem.contentName} &middot; ${orderItem.contentValue} * ${orderItem.quantity} =
                        ${orderItem.value}
                    </li>
                `)}
            </ul>
        `;
    },
    'toast-shown': (item: any) => {
        return html`
            <h3>
                Toast: ${item.data.content}
            </h3>
            <p>
                <small>
                    ${item.data.url}
                </small>
            </p>
        `;
    },
    'auth-login': (item: any) => {
        return html`
            <h3>
                Login: ${item.accountId}
            </h3>
            <p>
                Method: ${item.data.authMethod} <br>
                Attempts: ${item.data.authAttempts}
            </p>
        `;
    },
    'auth-register': (item: any) => {
        return html`
            <h3>
                Register: ${item.accountId}
            </h3>
            <p>
                Method: ${item.data.authMethod} <br>
                Attempts: ${item.data.authAttempts}
            </p>
        `;
    },
};

@customElement('component-firebase-analytics-activity-stream')
class ComponentFirebaseAnalyticsActivityStream extends BunnyElement {

    @property({type: String, notify: true})
    @storageBoundQueryString('q', '')
    activityQuery: string;

    @property({type: Number, notify: true})
    @storageBoundQueryString('limit', '1000', value => parseInt(value))
    activityLimit: number;

    @property({type: Array})
    items: any[];

    @property({type: Object})
    stats: any = {};

    @property({type: Object, notify: true})
    types: Record<string, {
        active: boolean,
        count: number
    }> = {};

    @property({type: Object})
    markedTime = new Date();

    @property({type: Array})
    @computed('items', 'types', 'markedTime')
    get itemsFiltered() {
        let activeTypes = Object.entries(this.types)
            .filter(([_type, typeObj]) => typeObj.active)
            .map(([type, _typeObj]) => type);

        let filtered = (this.items || []).filter(_ => activeTypes.includes(_.type));


        let markIndex = filtered.findIndex(_ => new Date(_.created) < this.markedTime);
        filtered.splice(markIndex, 0, {type: 'timeline-mark', created: this.markedTime.toISOString()});


        return filtered;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                padding-top: 150px;
            }

            .itemType- {
                &timeline-mark {
                    background: red;
                    text-align: center;
                    color: white;
                }

                &app-error {
                    background: #911;
                    color: white;
                }

                &app-performance {
                    background: #191;
                    color: white;
                }

                &interaction-click {
                    background: rgba(64, 0, 0, .3);
                }

                &interaction-view {
                    background: rgba(64, 0, 0, .5);
                }

                &device-active {
                    background: rgba(64, 64, 0, .3);
                }

                &session-active {
                    background: rgba(64, 64, 0, .5);
                }

                &page-view {
                    background: rgba(64, 64, 0, .7);
                    color: white;
                }

                &view-content {
                    background: rgba(0, 0, 64, .3);
                }

                &add-to-cart {
                    background: rgba(0, 0, 64, .5);
                    color: white;
                }

                &start-checkout {
                    background: rgba(0, 0, 64, .7);
                    color: white;
                }

                &purchase {
                    background: rgba(0, 0, 64, .9);
                    color: white;
                }

                &toast-shown {
                    background: rgba(64, 0, 64, .3);
                }

                &auth-login {
                    background: rgba(0, 64, 64, .3);
                }

                &auth-register {
                    background: rgba(0, 64, 64, .5);
                    color: white;
                }
            }


            .header {
                position: fixed;
                top: 41px;
                left: 0;
                right: 0;
                background: white;
                z-index: 1;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);

                .search {
                    display: flex;
                }

                .filter, .stats {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 200px);
                    padding: 5px;
                    gap: 5px;
                }
            }

            .items > * {
                padding: 15px;
                margin-bottom: 5px;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                border-radius: 15px;
                overflow: hidden;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);

                * {
                    margin: 0;
                }

                h3 {
                    margin-top: -15px;
                    margin-left: -15px;
                    margin-right: -15px;
                    margin-bottom: 15px;
                    padding: 5px 15px;
                    background: rgba(50, 50, 50, .3);
                }
            }
        `,
    ];

    override render() {
        let stats = this.stats;

        return html`
            <div class="header">
                <div class="search">
                    <component-input .value="${this.bind.activityQuery}" label="Query"
                                     style="flex: 1; margin-right: 5px" @keydown="${(e: KeyboardEvent) => {
                        if (e.key === 'Enter') {
                            this.loadActivity();
                        }
                    }}"></component-input>
                    <component-input-number .value="${this.bind.activityLimit}" label="Limit"
                                            style="margin-right: 5px; width: 100px"></component-input-number>
                    <component-input-datetime .value="${this.bind.markedTime}"
                                              label="Jump to" .step="${0.001}"></component-input-datetime>
                    <component-button @click="${this.loadActivity}" style="margin-top: auto">View</component-button>
                </div>
                <div class="filter">
                    ${Object.entries(this.types)
                            .sort((a, b) => a[0].localeCompare(b[0]))
                            .map(([type, typeObj]) => html`
                                <component-input-checkbox class="itemType-${type}"
                                                          .checked="${this.bindDeep(`types.${type}.active`)}"
                                                          style="border-radius: 3px"
                                                          @checked-changed="${(_: CustomEvent) => this.requestUpdate('types')}">
                                    ${type} &middot; ${typeObj.count}
                                </component-input-checkbox>
                            `)}
                </div>
                <div class="stats">
                    <div title="${`Newest: ${stats.newest}\nOldest: ${stats.oldest}`}">
                        Count &middot; ${stats.count} <br>
                    </div>
                    <div title="${this.formatStatTop(stats.sessionTop)}">
                        Sessions &middot; ${stats.sessionCount}
                    </div>
                    <div title="${this.formatStatTop(stats.deviceTop)}">
                        Devices &middot; ${stats.deviceCount}
                    </div>
                    <div title="${this.formatStatTop(stats.userAgentTop)}">
                        User agents &middot; ${stats.userAgentCount}
                    </div>
                    <div title="${this.formatStatTop(stats.ipTop)}">
                        Ips &middot; ${stats.ipCount}
                    </div>
                    <div title="${this.formatStatTop(stats.urlTop)}">
                        Urls &middot; ${stats.urlCount}
                    </div>
                </div>
            </div>

            <div class="items">
                ${(this.itemsFiltered || []).map((item, i) => {
                    let renderer = RENDERERS[item.type] || RENDERERS._DEFAULT;
                    let lastItem = this.itemsFiltered[i + 1];
                    let msSinceLast = lastItem ? new Date(item.created).getTime() - new Date(lastItem.created).getTime() : Number.MAX_VALUE;
                    let secondsSinceLast = Math.round(msSinceLast / 1000);
                    let minutesSinceLast = Math.round(secondsSinceLast / 60);
                    let display = '';
                    if (!secondsSinceLast) {
                        display = '';

                    } else if (secondsSinceLast < 120) {
                        display = `+${secondsSinceLast}s`;

                    } else if (minutesSinceLast < 5) {
                        display = `+${minutesSinceLast}m`;

                    } else {
                        display = item.created;
                    }

                    return html`
                        <div class="itemType-${item.type}"
                             title="${JSON.stringify(item)}">
                            ${renderer(item)}
                        </div>

                        ${display ? html`
                            <div title="${item.created}" style="text-align: center; opacity: .6; box-shadow: none">
                                ${display}
                            </div>
                        ` : undefined}
                    `;
                })}
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.loadActivity();
    }

    private formatStatTop(top?: {
        value: string,
        count: number
    }[]) {
        return (top || []).filter(_ => _.value)
            .map(_ => `${_.value}: ${_.count}`)
            .join('\n');
    }

    @bind()
    @toastProgressWrapper({
        progressMessage: 'Loading activity',
        successMessage: 'Activity loaded',
        failedMessage: 'Failed loading activity: {{e}}',
    })
    async loadActivity() {
        let activityStream = await adminCall.firebaseAnalyticsGetActivityStream(this.activityQuery, this.activityLimit);
        this.items = activityStream.activity;
        this.stats = activityStream.stats;

        let types: Record<string, {
            active: boolean,
            count: number
        }> = {};
        for (let item of this.items) {
            types[item.type] ??= {active: true, count: 0};
            types[item.type].count++;
        }
        this.types = types;
    }

    override updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);

        this.shadowRoot?.querySelector('.itemType-timeline-mark')?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        });
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-firebase-analytics-activity-stream': ComponentFirebaseAnalyticsActivityStream;
    }
}