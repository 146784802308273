import {customElement} from 'lit/decorators.js';
import {ComponentDataCollectionList} from './component-data-collection-list';
import {limit as queryLimit, orderBy, startAfter, where} from 'firebase/firestore';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper';
import {FirestoreCollection} from '../controllers/FirestoreCollection';
import {FetchMethod, FirestoreData} from '../controllers/FirestoreData';
import {html} from 'lit';
import {computed} from '../helpers/decorators/ComputedDecotratorHelper';
import {FirestoreDocument} from '../controllers/FirestoreDocument';

@customElement('component-firestore-collection-list')
export class ComponentFirestoreCollectionList extends ComponentDataCollectionList {

    @property({type: Object})
    @computed('path', 'filters', 'order', 'defaultOrder', 'limit', 'startAfterSnapshot')
    get _internalItems() {
        if (!this.path) return;
        let me = this;

        return new FirestoreCollection(
            this,
            this.path,
            {
                method: FirestoreData.resolveFetchMethod(this.fetchMethod),
                queryExtendFunction() {
                    return me.queryExtend(me.filters, me.order, me.defaultOrder, me.limit, me.startAfterSnapshot as any);
                },
            },
        );
    }

    @property({type: String})
    path: string;

    @property({type: String})
    fetchMethod: string = 'networkFirst';

    @property({type: Object})
    queryDb: any | null;

    @property({type: String})
    startAfter: string = '';

    @property({type: Object})
    startAfterSnapshot: object | null | false = null;

    // language=HTML
    static get dataProvider() {
        return html`
            <!--            <firestore-document db="{{queryDb}}"></firestore-document>-->
            <!--            <firestore-document path="{{path}}" data="{{items}}"-->
            <!--                                query-extend-function="{{queryExtendFiltered}}"-->
            <!--                                fetch-method="{{fetchMethod}}"-->
            <!--                                loading="{{loadingItems}}"></firestore-document>-->
        `;
    }

    @observe('startAfter', 'queryDb')
    async populateStartAfterSnapshot(startAfter: string, queryDb: any) {
        if (startAfter) {
            if (!queryDb) return;

            this.startAfterSnapshot = null;
            await FirestoreDocument.hostlessRequest(`${this.path}/${startAfter}`, {
                method: FetchMethod.CACHE_FIRST,
            }, (snapshot: any) => {
                this.startAfterSnapshot = snapshot;
            });

        } else {
            this.startAfterSnapshot = false;
        }
    }

    queryExtend(filters: any, order: any, defaultOrder: string[][] | string[], limit: number, startAfterSnapshot: object | null) {
        let ret = [];

        for (let key in filters) {
            if (Array.isArray(filters[key])) {
                ret.push(where(key, filters[key][0], filters[key][1]));

            } else {
                ret.push(where(key, '==', filters[key]));
            }
        }

        for (let key in order) {
            ret.push(orderBy(order[key][0], order[key][1]));
        }

        if (!order.length && defaultOrder) {
            if (defaultOrder.length && typeof defaultOrder[0][0] === 'string') {
                defaultOrder = [defaultOrder as any];
            }

            for (let key in defaultOrder) {
                ret.push(orderBy(defaultOrder[key][0], defaultOrder[key][1] as 'asc' | 'desc' | undefined));
            }
        }

        if (startAfterSnapshot) {
            ret.push(startAfter(startAfterSnapshot));
        }

        ret.push(queryLimit(limit));


        return ret;
    }

    @observe('_internalItems')
    reflect_internalItemsIntoItems(_internalItems: FirestoreCollection) {
        if (_internalItems.data) {
            this.items = _internalItems.data;
        }

        this.loadingItems = _internalItems.loading;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list': ComponentFirestoreCollectionList;
    }
}