import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';
import {ELASTICSEARCH_PACKAGE_ID} from '../../elasticsearch/shared';
import {SHOP_PACKAGE_ID} from '../../shop/shared';

export const ASPIRE_COMPS_COMPETITIONS_PACKAGE_ID = 'aspire-comps-competitions';


export class AspireCompsCompetitionsPackage extends Package {
    id: string = ASPIRE_COMPS_COMPETITIONS_PACKAGE_ID;
    name: string = 'Aspire comps competitions';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, SHOP_PACKAGE_ID, ELASTICSEARCH_PACKAGE_ID];

}