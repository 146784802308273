import {customElement} from 'lit/decorators.js';
import {seededRandom} from '../../../__internal/local/helpers/MathHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

const DEFAULT_BACKGROUND_COLORS = [
    '#85F8E5', '#85F8E9', '#85C0F8', '#8B85F8', '#D585F8', '#F885AA', '#F88585', '#F89885', '#F8BC85',
];

@customElement('component-account-profile-picture')
class ComponentAccountProfilePicture extends BunnyElement {

    @property({type: String})
    name: string;

    @property({type: String})
    accountId: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: inline-block;
                --size: 100px;
                background: url('/images/defaultProfilePicture.svg') var(--background-color) no-repeat center center;
                background-size: 100%;
                position: relative;
                width: var(--size);
                vertical-align: middle;
                border-radius: 3px;
            }

            .container {
                padding-top: 100%;
            }

            .container .overlayText {
                font-size: 40px;
                font-size: calc(var(--size) / 3.33);
                color: white;
                font-weight: bold;
                text-transform: uppercase;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-shadow: 0 0 3px #444444;
            }
        `
    ];

    override render(){
        return html`
            <div class="container">
                <div class="overlayText">
                    <slot></slot>
                </div>
            </div>
        `;
    }

    @observe('accountId')
    accountIdChanged(accountId: string) {
        this.name = accountId;

        let random = seededRandom(parseInt(accountId, 36));
        this.style.backgroundColor = DEFAULT_BACKGROUND_COLORS[Math.floor(random * DEFAULT_BACKGROUND_COLORS.length)];
        this.name = accountId;
    }

    @observe('name')
    nameChanged(name: string) {
        this.textContent = name[0];
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-furiffic-account-picture': ComponentAccountProfilePicture;
    }
}