import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {FIRESTORE_COLLECTION_PAGES, PageDocument} from '../../shared/helpers/FirestoreHelper';
import {where} from 'firebase/firestore';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {html} from 'lit';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {FirestoreCollection} from '../../../__internal/local/controllers/FirestoreCollection';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {ComponentInput} from '../../../inputs/local/components/component-input';
import {sharedStyles} from "../../../../shared-styles.ts";
import {scss} from "../../../__internal/local/helpers/StyleHelper";

@customElement('component-routing-aliases')
class ComponentRoutingAliases extends BunnyElement {

    @property({type: Object})
    value: any;


    @property({type: Array})
    @computed('value')
    get unsortedPages() {
        let ref = this.value?._ref;
        if (!ref) return;

        return new FirestoreCollection<PageDocument>(
            this,
            FIRESTORE_COLLECTION_PAGES,
            {
                method: FetchMethod.LIVE,
                queryExtendFunction() {
                    return [where('_managedRoutingAlias', '==', ref)];
                },
            },
        );
    }

    @property({type: Array})
    @computed('unsortedPages')
    get pages(): PageDocument[] {
        if (!this.unsortedPages?.data) return [];

        let sortValue = (page: PageDocument) => {
            if (page.type === 'internalRedirect') return 100;
            if (!(page as any)._managedRoutingLegacy) return 50;
            return 0;
        };

        return this.unsortedPages.data.sort((a, b) => sortValue(b) - sortValue(a));
    }

    @property({type: Object})
    @computed('pages')
    get primaryPage() {
        return this.pages[0];
    }

    @property({type: Boolean})
    showAll: boolean = false;

    @property({type: String})
    get currentHost() {
        return `${location.protocol}//${location.host}`;
    }


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render() {
        return html`
            Url &middot;
            <a href="${this.primaryPage?.routeFieldMatcher}">${this.primaryPage?.routeFieldMatcher}</a>
            <component-button-toggle style="border-radius: 100%; min-width: 0; width: 15px; height: 15px; --padding: 0;"
                                     .active="${this.bind.showAll}">
                ^
            </component-button-toggle>

            ${this.showAll ? html`
                <component-dialog opened="${this.bind.showAll}">
                    <h2 slot="heading">Routing</h2>
                    ${this.pages.map(item => html`
                        <component-input label="${(item as any)._managedRoutingId}"
                                         readonly
                                         .value="${this.currentHost}${item.routeFieldMatcher}"
                                         style="width: 600px"
                                         @focus="${this.copyText}">
                            ${(item as any)._managedRoutingLegacy ? html`
                                <small slot="prefix">(Legacy)</small>
                            ` : undefined}
                            <a slot="suffix" href="${item.routeFieldMatcher}">Open</a>
                        </component-input>
                    `)}
                </component-dialog>
            ` : undefined}
        `;
    }

    copyText(e: FocusEvent) {
        (e.currentTarget as ComponentInput).copyText();
        showToast('Coppied');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-routing-aliases': ComponentRoutingAliases;
    }
}