import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {shopCall} from '../helpers/ShopHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-payment-apple-pay')
class ComponentShopPaymentApplePay extends BunnyElement {

    @property({type: Number})
    orderId: string;

    @property({type: Number})
    transactionId: string;

    @property({type: String})
    gateway: string = '';

    @property({type: String})
    gatewayMerchantId: string = '';

    @property({type: String})
    merchantName: string;

    @property({type: String})
    merchantId: string;

    @property({type: String})
    currency: string = 'GBP';

    @property({type: String})
    country: string = 'GB';

    @property({type: Number})
    totalPrice: number;

    @property({type: Number})
    environment: 'TEST' | 'PRODUCTION' = 'TEST';

    @property({type: String})
    validateMerchantCustomCallbackSignature: string;

    @property({type: String})
    validateMerchantCustomCallback: string;

    @property({type: Boolean, reflect: true})
    disabled: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host([disabled]) {
                filter: contrast(0.5) grayscale(1);
                pointer-events: none;
            }

            component-button {
                display: block;
                max-width: 340px;
                height: 40px;
                border-radius: 4px;
                background-color: black;
                color: white;
                margin: 0;
                text-transform: none;
                font-weight: bold;
                --padding: 0;
            }
        `
    ];

    override render(){
        return html`
            <component-button @click="${this.doPay}">
                Pay with <img src="/images/payment-logo--apple-pay-plain.svg" style="height: 40px; width: 62px; vertical-align: middle"
                              alt="Apple pay">
            </component-button>
        `;
    }

    doPay() {
        this.disabled = true;
        try {
            let ApplePaySession = (window as any).ApplePaySession;


            let session = new ApplePaySession(6, {
                countryCode: this.country,
                currencyCode: this.currency,
                merchantCapabilities: [
                    'supports3DS',
                    'supportsDebit',
                    'supportsCredit',
                ],
                supportedNetworks: [
                    'visa',
                    'masterCard',
                    'amex',
                    'discover',
                ],
                total: {
                    label: 'Total',
                    amount: (this.totalPrice / 100).toFixed(2),
                    type: 'final',
                },
            });

            session.onvalidatemerchant = async (e: any) => {
                let response = await shopCall.paymentHandlerCustomCallback(
                    this.orderId,
                    this.transactionId,
                    this.validateMerchantCustomCallbackSignature,
                    this.validateMerchantCustomCallback,
                    {
                        appleUrl: e.validationURL,
                    },
                );
                session.completeMerchantValidation(response.merchantSession);
            };
            session.onpaymentauthorized = (_e: any) => {
                let me = this;
                this.dispatchEvent(new CustomEvent('tokenized', {
                    detail: {
                        token: _e.payment.token,
                        completePayment(success: boolean) {
                            console.log('completePayment success', success);
                            session.completePayment(success ? ApplePaySession.STATUS_SUCCESS : ApplePaySession.STATUS_FAILURE);
                            me.disabled = false;
                        },
                    },
                }));
            };

            session.oncancel = () => {
                this.disabled = false;
            };


            session.begin();

        } catch (e) {
            this.disabled = false;

            throw e;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-payment-apple-pay': ComponentShopPaymentApplePay;
    }
}