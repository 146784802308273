import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ElasticsearchQueryRequest} from '../../../elasticsearch/shared/helpers/ElasticsearchHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-points-admin-user-heading')
class ComponentShopPointsAdminUserHeading extends BunnyElement {

    @property({type: String})
    accountId: string;

    @property({type: Object})
    @computed('accountId')
    get pointsQuery(): ElasticsearchQueryRequest {
        return {
            id: 'searchShopPointsBackend',
            params: {
                searchMust: {term: {'owner.id': this.accountId}},
            },
        };
    }

    @property({type: Object})
    pointsResponse: any;

    @property({type: Object})
    @computed('pointsResponse')
    get pointsResult() {
        let hits = this.pointsResponse?.hits?.hits;
        if (!hits.length) return null;

        return hits[0]?._source || null;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            <component-elasticsearch-query .query="${this.pointsQuery}"
                                           .response="${this.bind.pointsResponse}"></component-elasticsearch-query>

            ${this.pointsResult ? html`
                <h1>
                    ${this.pointsResult.ownerInfo.firstName} ${this.pointsResult.ownerInfo.lastName}'s points &middot;
                    ${this.pointsResult.points}
                </h1>
            ` : html`
                <h1>${this.accountId} doesnt have any points yet</h1>
            `}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-points-admin-user-heading': ComponentShopPointsAdminUserHeading;
    }
}