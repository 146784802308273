import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import '../../../firebase-analytics/local/components/component-firebase-remote-config';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

let CACHE_POINTS_CONFIG:any;

@customElement('component-aspire-comps-product-points')
class ComponentAspireCompsProductPoints extends BunnyElement {

    @property({type: Object})
    pointsConfig: any = CACHE_POINTS_CONFIG;

    @property({type: Number})
    @computed('productPrice', 'quantity', 'pointsConfig')
    get points(){
        if(!this.quantity) return -1;
        if(!this.productPrice) return -1;
        if(!this.pointsConfig?.earn?.spend100) return -1;


        CACHE_POINTS_CONFIG = this.pointsConfig;


        return Math.floor(((this.productPrice * this.quantity) / 100) * this.pointsConfig.earn.spend100)
    };

    @property({type: Number})
    productPrice: number;

    @property({type: Number})
    quantity: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                text-align: center;
            }

            .points {
                font-weight: bold;
            }
        `
    ];

    override render(){
        return html`
            <component-firebase-remote-config key="shopPoints" .value="${this.bind.pointsConfig}"></component-firebase-remote-config>
            
            Purchase and receive <span class="points">${this.points}</span> Aspire Points!
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-product-points': ComponentAspireCompsProductPoints;
    }
}