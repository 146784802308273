import {customElement} from 'lit/decorators.js';
import {ComponentButton} from './component-button';
import {property} from '../packages/__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../packages/__internal/local/helpers/StyleHelper';
import {bind} from '../packages/__internal/local/helpers/decorators/BindDecoratorHelper';


@customElement('component-button-toggle')
class ComponentButtonToggle extends ComponentButton {

    @property({type: Boolean, notify: true, reflect: true})
    active = false;

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            :host{
                transition: .175s linear;
            }

            :host([raised]) {
                box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.4);
            }
            
            :host([active]){
                box-shadow: none;
                background-color: var(--primary-text-color);
            }
        `,
    ];

    constructor() {
        super();

        this.addEventListener('click', this.onClick);
    }

    @bind()
    onClick(){
        this.active = !this.active;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-button-toggle': ComponentButtonToggle;
    }
}