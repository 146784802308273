import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {
    ComponentElasticsearchCollectionList,
} from '../../../elasticsearch/local/components/component-elasticsearch-collection-list';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';

@customElement('component-winners-collection-header')
class ComponentWinnersCollectionHeader extends BunnyElement {

    @property({type: Object})
    list: ComponentElasticsearchCollectionList;

    @property({type: Number})
    totalResults: number;

    @property({type: Number})
    @computed('totalResults')
    get prizeValue() {
        return this.totalResults * 5788;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <h1 style="margin-top: 0">
                Our ${this.totalResults || ''} Aspire winners to date, totaling
                &pound;${this.prizeValue ? this._formatPrizeValue(this.prizeValue) : ''}M
            </h1>

            <slot></slot>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.list.addEventListener('response-changed', this.reflectTotalResults);
        this.totalResults = this.list.response?.aggregations?.all?.doc_count;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.list.removeEventListener('response-changed', this.reflectTotalResults);
    }

    _formatPrizeValue(value: number) {
        return (value / 1000000).toFixed(1).replace('.0', '');
    }

    @bind()
    reflectTotalResults() {
        this.totalResults = this.list.response?.aggregations?.all?.doc_count;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-winners-collection-header': ComponentWinnersCollectionHeader;
    }
}