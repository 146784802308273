import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {JSONParseLocal} from '../../../__internal/local/helpers/DataHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-aspire-comps-entry-list-mine-quick-view')
class ComponentAspireCompsEntryListMineQuickView extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    shopProductVariant: string;

    @property({type: Boolean, reflect: true})
    @computed('tickets')
    get visible() {
        return this.tickets > 0;
    }

    @property({type: Number})
    tickets: number = 0;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                background-color: var(--attention-color);
                color: white;
                padding: 5px 15px;
                display: none;
            }

            :host([visible]) {
                display: flex;
            }

            @media screen and (min-width: 1024px) {
                div {
                    text-align: center;
                    padding-left: 94px;
                }
            }
        `,
    ];

    override render() {
        return html`
            <div style="flex: 1; margin-top: auto; margin-bottom: auto">
                You have ${this.tickets} ticket${this.tickets === 1 ? '' : 's'} for this competition
            </div>

            <a href="/entry-list/my-competitions" style="margin: auto; margin-right: 0">
                <component-button style="background-color: var(--primary-text-color); --padding: 3px 6px; margin: 0">
                    My comps
                </component-button>
            </a>
        `;
    }

    @observe('auth', 'shopProductVariant')
    async loadAccountTickets(auth: Auth, shopProductVariant: any) {
        if (!auth.user) return;
        if (!shopProductVariant) return;

        let myTicketsUrl = `/_/accountTickets?accountId=${auth.user.uid}&fetchIncludePending=1&fetchShopProductVariant=${encodeURIComponent(shopProductVariant)}`;
        let response = await fetch(myTicketsUrl);
        let data = JSONParseLocal(JSON.stringify(await response.json()));

        let competitionTickets = Object.values(data.competitions)[0] as any;
        if (!competitionTickets) return;

        this.tickets = competitionTickets.length;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-entry-list-mine-quick-view': ComponentAspireCompsEntryListMineQuickView;
    }
}