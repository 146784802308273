import {customElement} from 'lit/decorators.js';
import {property} from '../../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../../auth/local/controllers/Auth';
import {html} from 'lit';
import {
    ComponentNotificationsPreferenceChannelBase,
    CONFIGURATION,
} from '../component-notifications-preference-channel-base';
import {NOTIFICATION_CHANNELS_INFO} from '../../../shared/helpers/NotificationsHelper.ts';

@customElement('component-notifications-preference-channel-email')
class ComponentNotificationsPreferenceChannelEmail extends ComponentNotificationsPreferenceChannelBase {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Object})
    types = NOTIFICATION_CHANNELS_INFO.email;

    @property({type: Boolean})
    allowsRemoval = false;

    @property({type: String})
    get configuration(): CONFIGURATION {
        return CONFIGURATION.AVAILABLE;
    }

    get name() {
        return 'Email';
    }

    renderInfoHtml() {
        return html`
            <div class="contentContainer" style="font-size: 80%">
                ${this.auth.getInternalUser()?.email}
            </div>
        `;
    }

    renderFullInfoHtml() {
        return html`
            <div class="contentContainer" style="font-size: 80%">
                Tailor the communications you receive to your email address.        
                <br>
                Your email address is linked to your account, you can change it in your <a href="/account">account settings.</a>
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-preference-channel-email': ComponentNotificationsPreferenceChannelEmail;
    }
}
