import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ComponentElasticsearchAdvancedSearchGroup} from './component-elasticsearch-advanced-search-group';
import {ComponentElasticsearchCollectionList} from './component-elasticsearch-collection-list';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {customElement, query} from 'lit/decorators.js';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';


export type EitherOr<A, B> =
    { [K in keyof A]: A[K] } & { [K in keyof B]?: never }
    | { [K in keyof A]?: never } & { [K in keyof B]: B[K] };

export interface ESRangeGTE {
    gte: any,
}

export interface ESRangeLTE {
    lte: any,
}

export type ESRange = EitherOr<ESRangeGTE, ESRangeLTE>;

export interface FilterMatch {
    match: Record<string, any>,
}

export interface FilterRange {
    range: Record<string, ESRange>,
}

export interface FilterBool {
    bool: {
        must_not: [{
            match: Record<string, any>
        }]
    },
}

// Gross nested EitherOr
export type FilterQuery = EitherOr<FilterMatch, EitherOr<FilterRange, FilterBool>>;

export interface BoolMust {
    must: (FilterQuery | GroupQuery)[],
}

export interface BoolShould {
    should: (FilterQuery | GroupQuery)[],
}

export type QueryBool = EitherOr<BoolMust, BoolShould>;

export interface GroupQuery {
    bool: QueryBool;
}

@customElement('component-elasticsearch-advanced-search')
export class ComponentElasticsearchAdvancedSearch extends BunnyElement {

    @property({type: Object})
    list: ComponentElasticsearchCollectionList;

    @property({type: String})
    searchId: string;

    @property({type: Object})
    properties: Record<string, {
        inputComponent: string,
        label: string
    }> = {};

    @property({type: Object})
    @computed('properties')
    get propertySelectOptions() {
        return Object.entries(this.properties)
            .map(([k, v]) => ({value: k, label: v.label}))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    @query('#mainInput')
    mainInput: HTMLElement;

    @query('component-elasticsearch-advanced-search-group')
    mainGroup: ComponentElasticsearchAdvancedSearchGroup;

    @property({type: Object, notify: true})
    searchParams: any = {};

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                margin-top: 10px;
                margin-bottom: 10px;
                line-height: 1.25;
                border-bottom: 1px solid #eee;
            }

            #mainInput {
                padding: 0 10px;
                position: relative;
                min-height: 49px;
            }
        `];

    override render() {
        return html`
            <div id="mainInput">
                <component-elasticsearch-advanced-search-group .searchComponent="${this}"
                                                               main></component-elasticsearch-advanced-search-group>
            </div>
        `;
    }

    updateUrl() {
        const urlObject = this.mainGroup.buildUrl();

        let url = new URL(window.location.href);
        url.searchParams.set('query', urlObject ? JSON.stringify(urlObject) : '');
        history.replaceState({}, '', url.toString());
    }

    updateQuery() {
        console.log('updating query');

        const searchMust = this.mainGroup.buildQuery();

        this.searchParams = searchMust ? {
            searchMust: searchMust,
        } : {};

        this.updateUrl();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-elasticsearch-advanced-search': ComponentElasticsearchAdvancedSearch;
    }
}