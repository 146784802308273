import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import '../../../media/local/components/component-media-view-image';
import {MediaReferenceField} from '../../../media/shared/helpers/FirebaseHelper';
import {ShopProductDocument} from '../../../shop/shared/helpers/FirebaseHelper';
import {ShopProductPrice} from '../../../shop/shared/helpers/ShopProductPriceHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';
import {Auth} from '../../../auth/local/controllers/Auth';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

@customElement('component-aspire-comps-product-list-item')
class ComponentAspireCompsProductListItem extends BunnyElement {

    @property({type: String, reflect: true})
    display: 'condensed' | 'full' = 'full';

    //@ts-ignore
    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Boolean, reflect: true})
    @computed('auth')
    get loggedIn() {
        return !!this.auth.user;
    }

    @property({type: Object})
    item: any;

    @property({type: Object})
    @computed('item')
    get flatStock() {
        let variants = this.item?.variants as {
            [key: string]: { stock: number, stockLock: number, maxStock: number; price: number; saleMatrix: any }
        } | undefined;
        if (!variants) return null;


        let ret = {
            stock: 0,
            maxStock: 0,
            stockLock: 0,
            price: Number.MAX_SAFE_INTEGER,
            originalPrice: Number.MAX_SAFE_INTEGER,
            hasSales: false,
        };
        for (let variant of Object.values(variants).filter(_ => _)) {
            ret.stock += variant.stock;
            ret.maxStock += variant.maxStock;
            ret.stockLock += variant.stockLock;

            let saleMatrix = new ShopProductPrice(
                this.item,
                {
                    price: variant.price,
                    saleMatrix: variant.saleMatrix,
                    maxStock: variant.maxStock,
                } as Partial<ShopProductDocument> as any,
                {stock: variant.stock, stockLock: variant.stockLock},
            );
            ret.price = Math.min(ret.price, saleMatrix.getPrice());
            ret.originalPrice = Math.min(ret.originalPrice, saleMatrix.getOriginalPrice());
            if (saleMatrix.isSale()) {
                ret.hasSales = true;
            }
        }


        let realStock = ret.stock - ret.stockLock;
        if ((ret.stock / ret.maxStock < .1 || realStock < 50)) {
            //when at 10% real stock show the real stock level
            ret.stock = realStock;
        }


        return ret;
    }

    @property({type: String})
    @computed('item')
    get promotedCategory() {
        return this.item.category?.find((_: any) => ['Auto draw'].includes(_));
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;
                text-align: center;
                overflow: hidden;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            component-button {
                display: block;
                border-radius: 0;
                margin-top: auto;
            }

            component-button[disabled] {
                background-color: var(--primary-text-color);
            }

            h3 {
                color: #8dc033;
                margin-left: 5px;
                margin-right: 5px;
                line-height: 1.2;
            }

            .closesOverlay, .promotedCategoryOverlay {
                position: absolute;
                top: 10px;
                padding: 5px 10px;
                background-color: var(--attention-color);
                color: white;
                border-radius: 0 3px 3px 0;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
                font-size: 16px;
                line-height: 1.1;
            }

            .promotedCategoryOverlay {
                top: 50px;
                background-color: var(--primary-text-color);
            }

            component-shop-price {
                display: none;
                text-align: center;
                margin-bottom: 15px;
            }

            .saleBanner {
                display: none;
                position: absolute;
                right: 0;
                top: 10px;
                padding: 5px 10px;
                background-color: var(--primary-text-color);
                color: white;
                border-radius: 3px 0 0 3px;
                box-shadow: -30px 0 0 var(--primary-text-color), 30px 0 0 var(--primary-text-color), -25px 2px 5px rgba(0, 0, 0, .4), 25px 2px 5px rgba(0, 0, 0, .4);
                font-size: 16px;
                line-height: 1.1;
                transform: rotate(45deg);
            }

            :host([logged-in]) component-shop-price, :host([logged-in]) .saleBanner {
                display: block;
            }

            component-shop-stock-remaining-bar {
                position: relative;
                --progress-bar-height: 20px;
                --progress-bar-border-radius: 0;
            }

            :host([display="condensed"]) > :not(component-media-view-image) {
                display: none;
            }
        `,
    ];

    override render() {
        return html`
            <component-media-view-image .media="${this._findThumbnail(this.item.productImages)}"
                                        type="default-thumbnail"
                                        width="400" height="300">
            </component-media-view-image>
            <component-shop-stock-remaining-bar label="::value:: tickets sold" .value="${this.flatStock?.stock}"
                                                .max="${this.flatStock?.maxStock}"
                                                use-percentage
                                                class="labelOverTop"></component-shop-stock-remaining-bar>

            <h3>${this.item.name}</h3>
            <component-shop-price .value="${this.flatStock?.price}" .originalValue="${this.flatStock?.originalPrice}"
                                  original-value-display="before">
                <span slot="suffix">per ticket</span>
            </component-shop-price>


            ${this.flatStock?.stock && this.flatStock?.stock > 0 ? html`
                <component-button>
                    Enter
                </component-button>
            ` : html`
                <component-button disabled>
                    Sold out
                </component-button>
            `}

            ${this.item.finished ? html`
                <div class="closesOverlay">
                    Closes ${RenderingHelper._dateFromNow(this.item.finished)}
                </div>
            ` : undefined}

            ${this.promotedCategory ? html`
                <div class="promotedCategoryOverlay">
                    ${this.promotedCategory}
                </div>
            ` : undefined}

            ${this.flatStock?.hasSales ? html`
                <div class="saleBanner">Sale</div>
            ` : undefined}


            <component-tracking-interactable .type="${'shop-product'}"
                                             .content="${this.item._ref?.id}"
                                             .contentName="${this.item.name}"
                                             .contentImage="${this._findThumbnail(this.item.productImages)?.media?.id}"
                                             .contentValue="${(this.flatStock?.price || 0) / 100}"
                                             .contentMeta="${{
                                                 stock: this.flatStock?.stock,
                                                 maxStock: this.flatStock?.maxStock,
                                                 onSale: this.flatStock?.hasSales ? 1 : 0,
                                                 inStock: (this.flatStock?.stock || 0) > 0 ? 1 : 0,
                                             }}"
                                             .tags="${this.item.category?.map((_: any) => `category-${_}`)}"></component-tracking-interactable>
        `;
    }

    _findThumbnail(productImages: MediaReferenceField[]) {
        return productImages.find(_ => _.usages?.includes('thumbnail'));
    }

    hasStock(stock: number) {
        return stock > 0;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-product-list-item': ComponentAspireCompsProductListItem;
    }
}
