import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {
    CompetitionCharityDocument,
    CompetitionWinnerDocument,
    FIRESTORE_COLLECTION_COMPETITION_WINNERS,
} from '../../shared/helpers/FirestoreHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {config} from '../../../../config';

@customElement('component-aspire-comps-winner')
export class ComponentAspireCompsWinner extends BunnyElement {

    @property({type: String})
    winnerId: string;

    @property({type: Object})
    @computed('winnerId')
    get winner() {
        if (!this.winnerId) return undefined;

        return new FirestoreDocument<CompetitionWinnerDocument>(this, `${FIRESTORE_COLLECTION_COMPETITION_WINNERS}/${this.winnerId}`, {method: FetchMethod.CACHE_FIRST});
    }

    @property({type: Boolean})
    winnerLoading = true;

    @property({type: String})
    @computed('winner')
    get cleanedProductName() {
        return this.winner?.data?.productName?.replace(/ ?-? ?\d{1,2}(st|nd|rd|th) [a-z]+/i, '') || '';
    }

    @property({type: String})
    @computed('cleanedProductName')
    get condensedProductName() {
        let productNameParts = this.cleanedProductName.split(' - ')
            .sort((a, b) => b.length - a.length);

        return productNameParts[0];
    }

    @property({type: Object})
    charitiesResponse: any;

    @property({type: Boolean})
    charitiesLoading = true;

    @property({type: Object})
    @computed('charitiesResponse')
    get charity() {
        let hits = this.charitiesResponse?.hits?.hits;
        if (!hits?.length) return undefined;

        return hits[0]._source as CompetitionCharityDocument;
    }

    @property({type: Number})
    @computed('charitiesResponse')
    get charityTotalDonated() {
        return this.charitiesResponse?.aggregations?.all?.amountDonated?.value;
    }

    @property({type: Number})
    @computed('charitiesResponse')
    get charityTotalCount() {
        return this.charitiesResponse?.aggregations?.all?.doc_count;
    }

    @property({type: Array})
    upsaleProductFields = [
        {
            cellProperties: {
                style: '--col-sm: 6; --col-lg: 4;',
                field: 'name',
                type: 'text',
            },
            header: false,
            cell: {
                component: 'component-shop-collection-list-item',
                properties: {
                    href: '/shop/products/',
                },
            },
        },
    ];

    @property({type: Object})
    @computed('winner')
    get upsaleProductParams() {
        if (!this.winner?.data?.productCategory) return undefined;

        return {
            searchFilter: [
                {
                    terms: {
                        category: this.winner.data.productCategory.map(_ => _.content)
                            .filter(_ => _ !== 'Early badger'),
                    },
                },
            ],
        };
    }

    @property({type: Object})
    @computed('winner')
    get charityElasticsearchQuery() {
        let winner = this.winner?.data;
        if (!winner) return undefined;

        //and within 2 weeks of winner.drawn
        return {
            id: 'searchCompetitionCharities',
            params: {
                from: 0,
                size: 1,
                searchFilter: [
                    {
                        query_string: {
                            query: `suggestedBy: "${winner.winnerName}"`,
                            default_operator: 'AND',
                        },
                    },

                    //TODO maybe add this at some point to filter it down, but for now the winner name should be enough
                    // {
                    //     range: {
                    //         donationDate: {
                    //             gte: `${((winner.drawn as any).toDate() as Date).toISOString()}`,
                    //             lt: `${((winner.drawn as any).toDate() as Date).toISOString()}/d+2w`,
                    //         },
                    //     },
                    // },
                ],
            },
        };
    }

    visibilityObserver: IntersectionObserver;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            h1 {
                background-color: var(--primary-color);
                box-shadow: 25vw 0 0 var(--primary-color), -25vw 0 0 var(--primary-color);
                color: white;
                margin-top: -15px !important;
                line-height: 1.1;
                font-size: 24px;
                font-size: calc(16px + 1vw);
                padding: 10px 35px;
                padding: 10px calc(5px + 2vw);
            }

            component-media-view-image {
                background-color: #f2f2f2;
                box-shadow: 0 1px 0 0 rgba(142, 146, 135, .3);
                border-radius: 15px;
                overflow: hidden;
            }

            blockquote {
                background: var(--primary-text-color);
                box-shadow: 0 100px 0 var(--primary-text-color);
                margin: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                padding: 15px 10px !important;
                color: white;
                padding-left: 50px !important;
                padding-bottom: 35px !important;
                position: relative;
                line-height: 1.2;
                margin-bottom: -15px !important;
            }

            blockquote:before {
                color: #ccc;
                content: open-quote;
                font-size: 400%;
                line-height: 1;
                position: absolute;
                top: 15px;
                left: 15px;
            }

            blockquote:after {
                content: '';
                z-index: 1;
                position: absolute;
                top: 100%;
                right: 15%;
                width: 0;
                border-top: 40px solid var(--primary-text-color);
                border-right: 30px solid transparent;
                margin-top: -15px;
            }

            .focusContent.left {
                padding-right: 5%;
                text-align: right;
            }

            .focusContent.right {
                padding-left: 5%;
            }

            .focusContent {
                font-size: 125%;
            }

            .focusContent span {
                font-size: 150%;
                background-color: var(--attention-color);
                color: white;
                border-radius: 8px;
                padding: 2px 20px;
                text-transform: capitalize;
                line-height: 1.4;

                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
            }

            .focusContent span.secondary {
                background-color: var(--primary-color);
            }

            :host(.allowAnimations) .animateIn {
                transition: .375s;
            }

            :host(.allowAnimations) .animateIn.animateInFromLeft:not(.visible) {
                opacity: 0;
                transform: translateX(-25%);
            }

            :host(.allowAnimations) .animateIn.animateInFromRight:not(.visible) {
                opacity: 0;
                transform: translateX(25%);
            }

            :host(.allowAnimations) .animateIn.animateInFromBottom:not(.visible) {
                opacity: 0;
                transform: translateY(25%);
            }
        `,
    ];

    override render() {
        if (!this.winner) return;

        return html`
            <component-elasticsearch-query .response="${this.bind.charitiesResponse}"
                                           .query="${this.charityElasticsearchQuery}"
                                           .loading="${this.bind.charitiesLoading}"></component-elasticsearch-query>


            <component-loading .loading="${this.winner?.loading}">
                ${!this.winner?.loading ? html`
                    <div class="gridContainer contentContainer">
                        <h1 style="margin-bottom: 0">${this.winner?.data.winnerName} won the
                            ${this.cleanedProductName}</h1>
                        <ul class="breadcrumbs angledContainer forceMobileAngledContainer">
                            <li class="prev">
                                <a href="/previous-winners">
                                    Winners
                                </a>
                            </li>
                            <li class="active">
                                <a href="/previous-winners/${this.winner?.ref?.id}">
                                    ${this.winner?.data.winnerName}
                                </a>
                            </li>
                        </ul>

                        <div style="display: flex; flex-wrap: wrap; background-color: rgb(249, 249, 249); box-shadow: rgb(249, 249, 249) -50vw 0 0, rgb(249, 249, 249) 50vw 0 0; padding-top: 50px; padding-bottom: 50px;">
                            <p style="font-size: 125%; margin-right: 50px; min-width: 300px; flex: 1">
                                Congratulations <strong>${this.winner?.data.winnerName}</strong>, <br>
                                the winner of the ${this.condensedProductName} <br>
                                which was drawn on
                                <strong>${RenderingHelper._dateFormat(this.winner?.data.drawn, 'dd/MM/yyyy')}</strong>.
                                <br>
                                And all it took was 1 lucky winning ticket.
                                <br><br>

                                ${this.winner?.data.watchLiveLink ? html`
                                    <a href="${this.winner?.data.watchLiveLink}">
                                        Watch the draw when ${this.winner?.data.winnerName} won their prize, and
                                        hopefully they picked up!
                                    </a>
                                ` : undefined}
                            </p>
                            <component-aspire-comps-tickets-renderer display-winner
                                                                     .items="${this.generateWinningTickets(this.winner?.data)}"
                                                                     style="margin: auto"></component-aspire-comps-tickets-renderer>
                        </div>

                        <div style="margin-top: 100px">
                        </div>

                        <div style="--col-md: 6; display: flex; align-items: center" class="focusContent left">
                            <p class="animateIn animateInFromRight">
                                The awesome <br>
                                <span>${this.cleanedProductName}</span> <br>
                                that was won by <br>
                                <span class="secondary">${this.winner?.data.winnerName}</span><br>
                            </p>
                        </div>
                        <component-media-view-image type="default-thumbnail" width="400" height="300"
                                                    .media="${this.winner?.data.productImage}"
                                                    style="--col-md: 6; margin-top: auto; margin-bottom: auto"></component-media-view-image>

                        <div style="margin-top: 100px">

                        </div>

                        ${this.winner?.data.winnerImage ? html`
                            ${this.winner?.data.winnerQuote ? html`
                                <blockquote style="--col-md: 6;">
                                    <p style="font-style: italic; white-space: break-spaces;"
                                       class="animateIn animateInFromBottom">${this.winner?.data.winnerQuote}</p>
                                    <div class="animateIn animateInFromBottom">
                                        - <strong>${this.winner?.data.winnerName} &middot;
                                        ${this.winner?.data?.location?.city},
                                        ${this.winner?.data?.location?.county}</strong>
                                    </div>
                                </blockquote>
                                <div style="--col-md: 6;">

                                </div>
                            ` : undefined}
                            <component-media-view-image type="default-thumbnail" width="400" height="300"
                                                        .media="${this.winner?.data.winnerImage}"
                                                        style="--col-md: 6; margin-top: auto; margin-bottom: auto;">
                            </component-media-view-image>
                            <div style="--col-md: 6; display: flex; align-items: center" class="focusContent right">
                                <p class="animateIn animateInFromLeft">
                                    All the best to <br>
                                    <span>${this.winner?.data.winnerName}</span> <br>
                                    with their new <br>
                                    <span class="secondary">${this.cleanedProductName}</span>
                                </p>
                            </div>
                        ` : undefined}


                        <div style="margin-top: 100px">

                        </div>


                        <div style="text-align: center; background-color: rgb(249, 249, 249); box-shadow: rgb(249, 249, 249) -50vw 0px 0px, rgb(249, 249, 249) 50vw 0px 0px; padding-top: 50px; padding-bottom: 50px;">
                            <h3>
                                This could be you next week!
                            </h3>
                            <p>
                                Browse our selection of competitions and try your luck for a win.
                            </p>
                            <a href="/shop/products?utm_source=aspire&utm_medium=winnerExploreProducts&utm_content=Check out our latest competitions being drawn soon">
                                <component-button>
                                    Check out our latest competitions being drawn soon
                                </component-button>
                            </a> <br>

                            <component-trustpilot-rating
                                    style="text-align:center;margin-top:15px"></component-trustpilot-rating>
                        </div>


                        <component-loading .loading="${this.charitiesLoading}">
                            ${this.charity ? html`
                                <div>
                                    <div class="gridContainer row">
                                        <div style="margin-top: 100px">

                                        </div>

                                        <div style="--col-md: 6; display: flex; align-items: center"
                                             class="focusContent left">
                                            <p class="animateIn animateInFromRight">
                                                And on top of all that ${this.winner?.data.winnerName}} nominated the
                                                charity
                                                <a href="/charities-we-support/${this.charity._ref?.id}">
                                                    <span>${this.charity.charityName}}</span>
                                                </a>
                                                to receive
                                                <span class="secondary">&pound;${this.charity.amountDonated}</span>
                                                as an extra thank you from us.
                                            </p>
                                        </div>

                                        <a href="/charities-we-support/${this.charity._ref?.id}"
                                           style="--col-md: 6; margin-top: auto; margin-bottom: auto; padding: 0">
                                            <component-media-view-image type="default-thumbnail" width="400"
                                                                        height="300"
                                                                        .media="${this.charity.charityImage}"></component-media-view-image>
                                        </a>


                                        <div style="--col-md: 6">

                                        </div>
                                        <p style="--col-md: 6; margin-top: 15px" class="animateIn animateInFromBottom">
                                            ${this.charity.description}

                                            ${this.charity.charityWebsite ? html`
                                                <br>

                                                <a href="${this.charity.charityWebsite}" class="colorPrimary"
                                                   style="word-break: break-all"
                                                   target="_blank">
                                                    ${this.charity.charityWebsite}
                                                </a>
                                            ` : undefined}
                                        </p>
                                    </div>
                                </div>
                            ` : undefined}
                        </component-loading>


                        <div style="margin-top: 100px">

                        </div>


                        <p style="text-align: center" class="focusContent">
                            Because of amazing people like this, <br>
                            its allowed us at Aspire Competitions to donate a total of
                            &pound;${this.charityTotalDonated} to
                            ${this.charityTotalCount} charities <br> <br>
                            <a href="/charities-we-support?utm_source=aspire&utm_medium=winnerViewAllCharities&utm_content=All charities we have supported">
                                <small>
                                    <component-button>
                                        All charities we have supported
                                    </component-button>
                                </small>
                            </a>
                        </p>

                        <div style="margin-top: 100px">

                        </div>

                        <component-aspire-comps-items-section title="YOU MAY LIKE"
                                                              more-link="View all"
                                                              more-link-href="/shop/products?utm_source=aspire&utm_medium=winnerUpsaleYouMayLike&utm_content=View all">
                            ${this.upsaleProductParams ? html`
                                <component-elasticsearch-collection-list .searchId="${'searchProductsFrontend'}"
                                                                         .fields="${this.upsaleProductFields}" limit="3"
                                                                         .params="${this.upsaleProductParams}"></component-elasticsearch-collection-list>
                            ` : undefined}

                            <div style="text-align: center; margin-top: 25px">
                                <a href="/shop/products?utm_source=aspire&utm_medium=winnerUpsaleYouMayLike&utm_content=Looking for something else? Explore the rest">
                                    <small>
                                        <component-button>
                                            Looking for something else? Explore the rest
                                        </component-button>
                                    </small>
                                </a>
                            </div>
                        </component-aspire-comps-items-section>
                    </div>
                ` : undefined}
            </component-loading>


            <div style="margin-top: 100px">

            </div>
        `;
    }

    @observe('charitiesLoading', 'winnerLoading')
    async activateAnimations() {
        if (!('IntersectionObserver' in window)) return;
        await delayPromise();

        if (!this.visibilityObserver) {
            this.classList.add('allowAnimations');
            this.visibilityObserver = new IntersectionObserver((entries) => {
                for (let entry of entries) {
                    if (!entry.intersectionRatio) continue;

                    entry.target.classList.add('visible');
                    this.visibilityObserver.unobserve(entry.target);
                }
            }, {
                root: document.querySelector('#scrollArea'),
                rootMargin: '0px',
                threshold: 1.0,
            });

        } else {
            this.visibilityObserver.disconnect();
        }


        this.shadowRoot?.querySelectorAll('.animateIn:not(.visible)').forEach(_ => {
            this.visibilityObserver.observe(_);
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        if (this.visibilityObserver) {
            this.visibilityObserver.disconnect();
        }
    }

    generateWinningTickets(winner: CompetitionWinnerDocument) {
        if (!winner) return [];

        return [
            {number: winner.ticketNumber, name: winner.winnerName},
        ];
    }

    //TODO remove this and place with proper firestore routing resolvers/preresolvers
    @observe('winner')
    populatePageTitle(winner?: FirestoreDocument<CompetitionWinnerDocument>) {
        if (!winner?.data) return;

        document.title = [
            `${winner.data.winnerName || ''} the winner of ${winner.data.productName || ''} from ${winner.data.location?.county || ''}`,
            'Winners',
            config.title,
        ].filter(_ => _).join(' · ');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-winner': ComponentAspireCompsWinner;
    }
}