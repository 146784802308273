import {customElement} from 'lit/decorators.js';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {nodeParentSelectorSearch} from '../../../__internal/local/helpers/DomHelper';
import {FORM_ACTION_BUTTON_ACTION_PROP, FORM_ACTION_BUTTON_ACTIONS, FORM_CLASS_ACTION_BUTTON} from '../../shared';
import {DateableDocument, RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {FIRESTORE_COLLECTION_FORM, FormDocument, SAVE_HANDLER} from '../../shared/helpers/FirestoreHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper';
import {prepareFunction} from '../../../__internal/local/helpers/FirebaseFunctionHelper';
import {ComponentFormsGroup} from './component-forms-group';
import {firestoreTimestamp} from '../../../__internal/local/helpers/FirestoreHelper';
import {addDoc, collection, deleteDoc, updateDoc} from 'firebase/firestore';
import {JSONParseLocal} from '../../../__internal/local/helpers/DataHelper';
import {internalAdminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {html} from 'lit';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {dotPut} from '../../../__internal/shared/helpers/DotHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';


@customElement('component-form')
export class ComponentForm extends BunnyElement {
    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    formId: string;

    @property({type: Object})
    @computed('formId')
    get form() {
        if (!this.formId) return;

        return new FirestoreDocument<FormDocument>(this, `${FIRESTORE_COLLECTION_FORM}/${this.formId}`, {method: FetchMethod.FASTEST_THEN_CLEAN});
    }

    @property({type: Object})
    defaultValues: object;

    @property({type: Object})
    data: any;

    @property({type: String})
    formDataPath: string;

    @property({type: Object, notify: true})
    @computed('formDataPath')
    get formDataInternal() {
        if (!this.formDataPath) return undefined;

        return new FirestoreDocument<RefferableDocument>(
            this,
            this.calculateFormDataPath(this.formDataPath, 0),
            {
                method: FetchMethod.NETWORK_FIRST,
            },
        );
    }

    @property({type: Object, notify: true})
    formData: RefferableDocument & DateableDocument;

    @property({type: String})
    duplicateFormDataPath: string;

    @property({type: Object})
    duplicateFormData: string;

    @property({type: String})
    saveHandler: SAVE_HANDLER = SAVE_HANDLER.FIRESTORE;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];


    override render() {
        if (!this.form?.data) return;
        this.form.data.events ??= [];
        if (!this.form.data.events.find(_ => _[`value-changed`] === this.onValueChanged)) {
            this.form.data.events.push({['value-changed']: this.onValueChanged});
        }

        return html`
            <!--            <firestore-document path="{{duplicateFormDataPath}}" data="{{duplicateFormData}}"-->
            <!--                                fetch-method="networkFirst"></firestore-document>-->

            <div id="form">
                ${createComponent(
                        this.form.data,
                        this.data,
                        {form: this.form.data, value: this.formData, data: this.data},
                        false,
                )}
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        if (this.route.current.query.defaultFormValues) {
            let defaultValues = JSONParseLocal(this.route.current.query.defaultFormValues);
            this.defaultValues ??= {};
            for (let i in defaultValues) {
                (this.defaultValues as any)[i] = defaultValues[i];
            }
        }

        this.duplicateFormDataPath = this.route.current.query.duplicateDataPath || null as any;
        if (!this.duplicateFormDataPath) {
            this.formData = {
                ...this.defaultValues,
                created: firestoreTimestamp(),
                updated: firestoreTimestamp(),
            };
        }
    }

    @observe('formDataInternal')
    reflectFormDataInternalToFormData() {
        if (!this.formDataInternal?.data) return; // Avoid overriding default values with nothing
        this.formData = this.formDataInternal.data as RefferableDocument;
    }

    @observe('duplicateFormData')
    loadDuplicateFormData(duplicateFormData: any) {
        this.formData = {
            ...this.defaultValues,
            ...duplicateFormData,
            created: firestoreTimestamp(),
            updated: firestoreTimestamp(),
        };
    }

    calculateFormDataPath(formDataPath: string, isCollection: number) {
        if (this.saveHandler !== SAVE_HANDLER.FIRESTORE) return '';

        return (formDataPath || '').split('/').length % 2 === isCollection ? formDataPath : '';
    }

    rerender() {
        this.performUpdate();
    }

    @listen('click')
    async handleSubmitButton(e: Event) {
        let path = e.composedPath();
        let currentTarget = path[0] as Element;
        if (!currentTarget) return;

        let buttonSearch: any = nodeParentSelectorSearch(currentTarget, '.' + FORM_CLASS_ACTION_BUTTON);
        if (!buttonSearch) return;


        let action: FORM_ACTION_BUTTON_ACTIONS = buttonSearch[FORM_ACTION_BUTTON_ACTION_PROP];
        if (!action) return;

        await this.actions[action]();
    }

    get actions() {
        return {
            [FORM_ACTION_BUTTON_ACTIONS.SAVE]: this.saveAction.bind(this),
            [FORM_ACTION_BUTTON_ACTIONS.DELETE]: this.deleteAction.bind(this),
        };
    }


    @toastProgressWrapper({progressMessage: 'Deleting document', successMessage: 'Deleted document'})
    @confirmationDialog({
        title: 'Are you sure you want to delete this?',
        failButtonText: 'Cancel! Nope I just like clicking buttons',
        successButtonText: 'Yes im sure sure i want to delete this',
        body: function (this: ComponentForm) {
            let name = (this.formData as any).name || (this.formData as any).firstName || (this.formData as any).lastName;
            return `Are you sure you want to permanently completely and unrecoverably delete forever the following document - ${this.formData._ref?.path}${name ? `: (${name})` : ''}`;
        },
    })
    async deleteAction() {
        switch (this.saveHandler) {
            case SAVE_HANDLER.FIRESTORE:
                if (this.formData?._ref) {
                    await deleteDoc(this.formData._ref as any);
                }

                break;

            default:
                throw new Error(`Deleting form data documents is only supported for the saveHandler of ${SAVE_HANDLER.FIRESTORE}`);
        }
    }

    @toastProgressWrapper()
    async saveAction() {
        if (!this.validate()) throw new FriendlyMessage('Please check invalid form fields');


        this.dispatchEvent(new CustomEvent('before-save', {
            bubbles: true,
            detail: {
                formData: this.formData,
                updateFormData: (key: string, value: any) => {
                    dotPut(key, value, this.formData);
                    this.requestUpdate('formData');
                },
            },
        }));

        switch (this.saveHandler) {
            case SAVE_HANDLER.FIRESTORE:
                if (this.formData._ref) {
                    this.formData.updated = firestoreTimestamp();
                    await updateDoc(this.formData._ref as any, this.formData);


                    this.dispatchEvent(new CustomEvent('after-save', {
                        bubbles: true,
                        detail: {formData: this.formData},
                    }));

                } else {
                    let formDataCollection = collection(FirestoreDocument.db, this.calculateFormDataPath(this.formDataPath, 1));
                    Object.defineProperty(this.formData, '_ref', {
                        value: await addDoc(formDataCollection, this.formData),
                    });


                    let afterSaveEvent = new CustomEvent('after-save', {
                        bubbles: true,
                        detail: {formData: this.formData},
                        cancelable: true,
                    });
                    this.dispatchEvent(afterSaveEvent);
                    if (afterSaveEvent.defaultPrevented) return;

                    HistoryHelper.replaceState(window.location.href.replace('/create', `/${(this.formData._ref as unknown as FirestoreDocumentReference)?.id}`));
                }
                break;

            case SAVE_HANDLER.FIREBASE_FUNCTION:
            case SAVE_HANDLER.ADMIN_CALL:
            case SAVE_HANDLER.FETCH:
                let data = {...this.formData};

                delete data.created;
                delete data.updated;

                let responseData;

                if (this.saveHandler === SAVE_HANDLER.FETCH) {
                    let response = await fetch(this.formDataPath, {
                        body: JSONStringify(data),
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                    });
                    responseData = await response.json();

                } else if (this.saveHandler === SAVE_HANDLER.FIREBASE_FUNCTION) {
                    let firebaseFunction = await prepareFunction(this.formDataPath);

                    responseData = (await firebaseFunction(data)).data;

                } else if (this.saveHandler === SAVE_HANDLER.ADMIN_CALL) {
                    responseData = await internalAdminCall(this.formDataPath, {data: data});
                }


                if (responseData && responseData.status === 'error') {
                    throw new Error(responseData._messages[0]);
                }

                if (responseData && responseData.status === 'ok' && responseData.redirect) {
                    HistoryHelper.replaceState(responseData.redirect);
                }

                break;

            default:
                throw new Error(`Unknown form save handler: ${this.saveHandler}`);
        }
    }

    // @listen('change')
    @bind()
    onValueChanged(e: Event) {
        let target: any = e.target;

        this.formData = target.value;
        this.requestUpdate('formData');
    }

    getFormComponent(): ComponentFormsGroup {
        return this.shadowRoot?.querySelector('#form>*') as ComponentFormsGroup;
    }

    validate() {
        return this.getFormComponent().validate();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-form': ComponentForm;
    }
}