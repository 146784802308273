import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-payment-google-pay')
class ComponentShopPaymentGooglePay extends BunnyElement {

    @property({type: String})
    gateway: string = '';

    @property({type: String})
    gatewayMerchantId: string = '';

    @property({type: String})
    merchantName: string;

    @property({type: String})
    merchantId: string;

    @property({type: String})
    currency: string = 'GBP';

    @property({type: String})
    country: string = 'GB';

    @property({type: Number})
    totalPrice: number;

    @property({type: Number})
    environment: 'TEST' | 'PRODUCTION' = 'TEST';

    @property({type: Boolean, reflect: true})
    disabled: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                max-width: 340px;
                height: 40px;
                border-radius: 4px;
                background-color: black;
                position: relative;
            }
            
            :host([disabled]) {
                filter: contrast(0.5) grayscale(1);
                pointer-events: none;
            }
            
            button {
                display: block;
                width: 100%;
            }
            
            .gpay-card-info-container.black.hover, .gpay-card-info-animation-container.black.hover{
                background-color: black!important;
            }
        `
    ];

    override render(){
        return html`
            <component-ripple style="color: white!important;"></component-ripple>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.initGooglePay().then((inited)=>{
            if(!inited){
                this.style.display = 'none';
            }
        });
    }

    async initGooglePay() {
        await loadTrackingLibrary('https://pay.google.com/gp/p/js/pay.js');
        let PaymentsClient = (window as any).google.payments.api.PaymentsClient;
        let paymentsClient = new PaymentsClient({environment: this.environment});


        let baseRequest = {
            apiVersion: 2,
            apiVersionMinor: 0,
        };

        let baseCardPaymentMethod = {
            type: 'CARD',
            parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
            },
        };
        let cardPaymentMethod = {
            ...baseCardPaymentMethod,
            tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                    gateway: this.gateway,
                    gatewayMerchantId: this.gatewayMerchantId,
                },
            },
        };

        try {
            let response = await paymentsClient.isReadyToPay({
                ...baseRequest,
                allowedPaymentMethods: [baseCardPaymentMethod],
            });
            console.log('response', response);

            if (!response.result) return false;//google pay not supported


        } catch (e) {
            //TODO see what it looks like when this isnt the case
            debugger;
            console.error('failed initing google pay', e);
            return false;
        }


        let paymentDataRequest = {
            ...baseRequest,
            allowedPaymentMethods: [cardPaymentMethod],
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPrice: (this.totalPrice / 100).toFixed(2),
                currencyCode: this.currency,
                countryCode: this.country,
            },
            merchantInfo: {
                merchantName: this.merchantName,
                merchantId: this.merchantId,
            },
        };
        paymentsClient.prefetchPaymentData(paymentDataRequest);


        let button = paymentsClient.createButton({
            onClick: () => {
                this.disabled = true;
                paymentsClient.loadPaymentData(paymentDataRequest)
                    .then((paymentData: any) => {
                        this.disabled = false;
                        this.dispatchEvent(new CustomEvent('tokenized', {detail: paymentData.paymentMethodData.tokenizationData}));
                    })
                    .catch((err: any) => {
                        this.disabled = false;

                        if(err?.message !== 'User closed the Payment Request UI.'){
                            // show error in developer console for debugging
                            console.error(err);
                        }
                    });
            },
            allowedPaymentMethods: [baseCardPaymentMethod],
            buttonRootNode: this.shadowRoot,
        });

        (this.shadowRoot as ShadowRoot).prepend(button);

        return true;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-payment-google-pay': ComponentShopPaymentGooglePay;
    }
}