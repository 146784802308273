import {customElement} from 'lit/decorators.js';
import {ComponentShopPurchaseQuantity} from '../../../shop/local/components/component-shop-purchase-quantity';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';

@customElement('component-aspire-comps-shop-purchase-quantity')
class ComponentAspireCompsShopPurchaseQuantity extends ComponentShopPurchaseQuantity {

    @property({type: Object})
    @computed('value')
    get simplifiedOdds() {
        let quantity = this.value;

        return {
            less: quantity === 1 ? 0 : (this.maxStock / (quantity - 1)),
            current: this.maxStock / quantity,
            more: this.maxStock / (quantity + 1),
        };
    }

    @property({type: Number})
    maxStock: number;

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            .oddsSimplifier {
                margin-top: 15px;
                margin-bottom: 15px;
            }

            .oddsSimplifier p {
                margin: 0;
            }

            .oddsSimplifier #lessTickets,
            .oddsSimplifier #moreTickets {
                font-size: 80%;
            }

            .oddsSimplifier #lessTickets[data-min-reached],
            .oddsSimplifier #moreTickets[data-max-reached] {
                opacity: .5;
            }

            .oddsSimplifier strong small {
                font-weight: normal;
            }
        `,
    ];

    override render() {
        return html`
            ${super.render()}

            <div class="oddsSimplifier">
                <p id="lessTickets" data-max-reached="${this.value === 1}">
                    ${this.simplifiedOdds?.less ? html`
                        Odds with 1 less ticket are
                        <strong>1 <small>in</small> ${this._formatNumber(this.simplifiedOdds.less)}</strong>
                    ` : html`
                        Got to be in it to win it
                    `}
                </p>

                <p>
                    Your current odds with
                    <strong style="color: var(--primary-color)">
                        ${this.value} ticket${this.value === 1 ? '' : 's'}
                    </strong>
                    are
                    <strong style="color: var(--primary-color)">1 <small>in</small>
                        ${this._formatNumber(this.simplifiedOdds?.current || 0)}</strong>
                </p>

                <p id="moreTickets" data-max-reached="${this.value === this.max}">
                    Better odds with 1 more ticket will be
                    <strong>1 <span style="font-weight: normal">in</span>
                        ${this._formatNumber(this.simplifiedOdds?.more || 0)}</strong>
                </p>
            </div>
        `;
    }

    _formatNumber(value: number) {
        return value.toFixed(2).replace('.00', '');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-shop-purchase-quantity': ComponentAspireCompsShopPurchaseQuantity;
    }
}