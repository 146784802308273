import {customElement} from 'lit/decorators.js';
import {prepareFunction} from '../../../__internal/local/helpers/FirebaseFunctionHelper';
import {ElasticsearchQueryRequest} from '../../shared/helpers/ElasticsearchHelper';
import {timeoutPromise} from '../../../__internal/shared/helpers/PrimiseHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {reISO, reMsAjax} from '../../../__internal/shared/helpers/DataHelper';
import {doc} from 'firebase/firestore';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {ENV} from '../../../../config';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {html} from 'lit';

@customElement('component-elasticsearch-query')
export class ComponentElasticsearchQuery extends BunnyElement {
    @property({type: Array})
    queries: ElasticsearchQueryRequest[];

    @property({type: Object})
    query: ElasticsearchQueryRequest;

    querySignature(query: object): string {
        return JSON.stringify(query);
    }

    private currentlyQueryingForSignature: string;

    @property({type: Array, notify: true})
    responses: object[];

    @computed('responses')
    @property({type: Array, notify: true})
    get response() {
        return (this.responses || [])[0];
    }

    set response(_ignore: any) {
    }

    @property({type: Boolean, notify: true})
    loading: boolean;

    @property({type: Number})
    debounce: number = 250;

    private _debouncePromise: Promise<any>;

    @observe('query')
    _populateQueriesFromQuery(query: ElasticsearchQueryRequest) {
        this.queries = [query].filter(_ => _);
    }

    override render() {
        return html``;
    }

    private hydrateResponse(parent: any = null, key: string) {
        let data = parent[key];

        if (typeof data === 'object' && data) {
            if (data.path && data.id) {
                Object.defineProperty(parent, key, {
                    value: doc(FirestoreDocument.db, data.path),
                    enumerable: key[0] !== '_',
                });
                return;
            }

            for (let i in data) {
                this.hydrateResponse(data, i);
            }

        } else if (data && typeof data === 'string') {
            let a = reISO.exec(data);
            if (a) {
                data = new Date(data);
            }

            a = reMsAjax.exec(data);
            if (a) {
                let b = a[1].split(/[-+,.]/);
                data = new Date(b[0] ? +b[0] : 0 - +b[1]);
            }

            parent[key] = data;
        }
    }

    @observe('queries')
    async sendQuery(queries: ElasticsearchQueryRequest[]) {
        this.loading = true;
        let thisQuerySignature = this.querySignature(queries);
        this.currentlyQueryingForSignature = thisQuerySignature;


        if (this.debounce > 0) {
            await this._debouncePromise;
            if (this.currentlyQueryingForSignature !== thisQuerySignature) {
                console.info('[ES query]: Throwing away old query request :3');
                return;
            }


            this._debouncePromise = timeoutPromise(this.debounce);
        }

        if (queries.length) {
            let queryFunction = await prepareFunction('elasticsearchqueryv2', ENV === 'local' ? undefined : `${location.origin}/_`);
            let response = await queryFunction({queries: queries});

            if (this.currentlyQueryingForSignature !== thisQuerySignature) {
                console.info('[ES query]: Throwing away old query response');
                return;
            }


            this.hydrateResponse(response.data, 'responses');
            this.responses = (response.data as any).responses;

        } else {
            this.responses = [];
        }


        this.loading = false;
        this.currentlyQueryingForSignature = '';
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-elasticsearch-query': ComponentElasticsearchQuery;
    }
}