import {ComponentConfirmationDialog, ConfirmationDialogOptions} from '../../components/component-confirmation-dialog';
import {BunnyElement} from '../../components/bunny-element';

export function confirmationDialog(options: ConfirmationDialogOptions = {} as any) {
    return (_proto: any, _propName: string, descriptor: PropertyDescriptor) => {
        let oldFunction = descriptor.value;

        descriptor.value = async function (...args: any[]) {
            options.title ??= 'Are you sure?';
            options.body ??= 'Are you sure?';

            let me = this as unknown as BunnyElement;

            await ComponentConfirmationDialog.showConfirmationDialog(options as ConfirmationDialogOptions, me, args);
            return oldFunction.apply(me, args);
        };
    };
}

