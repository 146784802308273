import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';

export const ANALYTICS_PACKAGE_ID = 'analytics';

export class AnalyticsPackage extends Package {
    id: string = ANALYTICS_PACKAGE_ID;
    name: string = 'analytics';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];
}
