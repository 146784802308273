import Package from '../../__internal/shared/Package';
import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import {ADMIN_PACKAGE_ID} from '../../admin/shared';
import {AUTH_PACKAGE_ID} from '../../auth/shared';
import {ELASTICSEARCH_PACKAGE_ID} from '../../elasticsearch/shared';

export const NOTIFICATIONS_PACKAGE_ID = 'notifications';

export class NotificationsPackage extends Package {
    id: string = NOTIFICATIONS_PACKAGE_ID;
    name: string = 'Notifications';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, ADMIN_PACKAGE_ID, AUTH_PACKAGE_ID, ELASTICSEARCH_PACKAGE_ID];
}