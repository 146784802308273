import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {scss} from '../helpers/StyleHelper';
import {BunnyElement} from './bunny-element';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';


@customElement('component-progress')
class ComponentProgress extends BunnyElement {

    @property({type: Number})
    value!: number;

    @property({type: Number})
    min?: number;

    @property({type: Number})
    max?: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                min-height: 16px;
                border-radius: 3px;
                overflow: hidden;
                position: relative;
                text-align: center;
            }

            progress {
                display: block;
                width: 100%;
                height: 100%;
                background-color: transparent;
                position: absolute;
                inset: 0;
                z-index: -1;
                appearance: none;
                -webkit-appearance: none;
            }

            progress::-webkit-progress-bar {
                background-color: rgba(32, 32, 32, .1);
            }

            progress::-webkit-progress-value {
                background-color: var(--primary-color);
            }
        `,
    ];

    override render() {
        return html`
            <progress value="${this.value - (this.min || 0)}"
                      max="${this.min || this.max ? (this.max || 0) - (this.min || 0) : undefined}"></progress>
            <slot style="position:relative; z-index: 1"></slot>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-progress': ComponentProgress;
    }
}