import {customElement} from 'lit/decorators.js';
import {ComponentInput} from "./component-input.ts";

@customElement('component-input-phone')
export class ComponentInputPhone extends ComponentInput {

    protected onInput() {
        let value = (this.nativeInput as any)[this.valueAttribute];
        let oldValue = value;

        value = value.replace(/\++/g, '+')
            .replace(/[^+0-9]/g, '');

        if (value !== oldValue) {
            (this.nativeInput as any)[this.valueAttribute] = value;
        }


        super.onInput();
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'component-input-phone': ComponentInputPhone;
    }
}
