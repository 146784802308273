import {customElement} from 'lit/decorators.js';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';


@customElement('component-prize-claiming-messages-confirm-address-details-request')
class ComponentPrizeClaimingMessagesConfirmAddressDetailsRequest extends ComponentPrizeClaimingMessagesMessage {

    override render() {
        return html`
            ${super.render()}


            <component-native-form @submit="${this.sendCallback}" class="footer"
                                   style="--loading-inset: -500px; padding-bottom: 0; padding-top: 0">
                <component-input
                        style="--col: 6"
                        name="firstName"
                        label="First name"
                        required
                        autocomplete="given-name"
                        .value="${this.bindDeep('item.meta.addressDetails.firstName')}"
                ></component-input>
                <component-input
                        style="--col: 6"
                        name="lastName"
                        label="Last name"
                        required
                        autocomplete="family-name"
                        .value="${this.bindDeep('item.meta.addressDetails.lastName')}"
                ></component-input>
                <component-input-phone
                        name="phoneNumber"
                        type="tel"
                        label="Phone"
                        required
                        minlength="8"
                        maxlength="15"
                        autocomplete="tel"
                        .value="${this.bindDeep('item.meta.addressDetails.phone')}"
                ></component-input-phone>
                <component-input
                        name="addressLine1"
                        label="Street address"
                        required
                        placeholder="House number and street name"
                        autocomplete="address-line1"
                        .value="${this.bindDeep('item.meta.addressDetails.address1')}"
                ></component-input>
                <component-input
                        name="addressLine2"
                        label="Street address 2 (optional)"
                        placeholder="Apartment, suite, unit, etc"
                        autocomplete="address-line2"
                        .value="${this.bindDeep('item.meta.addressDetails.address2')}"
                ></component-input>
                <component-input
                        name="city"
                        label="Town / City"
                        required
                        autocomplete="address-level2"
                        .value="${this.bindDeep('item.meta.addressDetails.city')}"
                ></component-input>
                <component-input
                        name="county"
                        label="County (optional)"
                        autocomplete="address-level1"
                        .value="${this.bindDeep('item.meta.addressDetails.province')}"
                ></component-input>
                <component-input
                        name="postcode"
                        label="Postcode"
                        required
                        autocomplete="postal-code"
                        .value="${this.bindDeep('item.meta.addressDetails.zip')}"
                ></component-input>
                <component-input
                        name="country"
                        label="Country"
                        required
                        disabled
                        autocomplete="country"
                        .value="${this.bindDeep('item.meta.addressDetails.country')}"
                ></component-input>

                <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                    Submit
                </button>

                <div slot="submitButton">
                    <component-button raised
                                      style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                        Confirm my delivery address
                    </component-button>
                </div>
            </component-native-form>
        `;
    }

    @bind()
    async sendCallback(_e: FormSubmitEvent) {
        this.dispatchEvent(new CustomEvent('sendMessageRequest', {
            detail: {
                newMessage: {
                    type: 'confirmAddressDetailsResponse',
                    body: 'My address details are the following...',
                    meta: {
                        addressDetails: this.item.meta?.addressDetails,
                    },
                },
            },
        }));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-confirm-address-details-request': ComponentPrizeClaimingMessagesConfirmAddressDetailsRequest;
    }
}