import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import './component-input-media-file-info';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-input-media-file-info-collection')
export class ComponentInputMediaFileInfoCollection extends BunnyElement {

    @property({type: String})
    label: string;

    @property({type: Object, notify: true})
    value: any = [];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .label {
                font-family: var(--paper-font-caption_-_font-family);
                -webkit-font-smoothing: var(--paper-font-caption_-_-webkit-font-smoothing);
                white-space: var(--paper-font-caption_-_white-space);
                overflow: var(--paper-font-caption_-_overflow);
                text-overflow: var(--paper-font-caption_-_text-overflow);
                font-size: var(--paper-font-caption_-_font-size);
                font-weight: var(--paper-font-caption_-_font-weight);
                letter-spacing: var(--paper-font-caption_-_letter-spacing);
                line-height: var(--paper-font-caption_-_line-height);
                margin-bottom: 5px;
            }
        `
    ];

    override render(){
        return html`
            ${this.label ? html`
                <div class="label">
                    ${this.label}
                </div>
            ` : undefined}

            ${this.value.map((item: any)=>html`
                <component-input-media-file-info .value="${item}"></component-input-media-file-info>
            `)}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-media-file-info-collection': ComponentInputMediaFileInfoCollection;
    }
}