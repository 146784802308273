import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {html} from 'lit';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {Auth} from '../controllers/Auth';

@customElement('component-logout')
class ComponentLogout extends BunnyElement {

    auth = Auth.getInstance(this);


    override render() {
        return html``;
    }

    connectedCallback(): void {
        super.connectedCallback();

        this.logout();
    }

    async logout() {
        await this.auth.signOut();
        showToast('Logged out successfully');
        HistoryHelper.replaceState('/');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-logout': ComponentLogout;
    }
}