import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';

@customElement('component-shop-payment-iframe')
class ComponentShopPaymentIframe extends BunnyElement {

    @property({type: String})
    src: string;

    @property({type: Boolean})
    loading: boolean = true;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            iframe {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                height: 85vh;
                height: calc(100vh - 149px);
                border: none;
                margin: 0;
                padding: 0;
            }

            .contentStep {
                margin-bottom: 0;
                padding: 0;
            }
        `
    ];

    override render(){
        return html`
            <component-loading .loading="${this.loading}">
                <iframe src="${this.src}" frameborder="0" id="frame"></iframe>
            </component-loading>
        `;
    }

    @listen('load', 'frame')
    frameLoaded(e: any) {
        try {
            //wont normally get past due to cross site restrictions
            //used to detect when the iframe returns back to this domain;
            let frameLocation = e.currentTarget.contentWindow.location;
            let frameUrl = frameLocation.href;
            let frameHost = frameLocation.host;

            let frameSearchParams = (new URL(frameLocation.href)).searchParams;
            let frameSearchParamReturnUrl = 'return-url'
            if (frameSearchParams.has(frameSearchParamReturnUrl)) {
                frameUrl = frameSearchParams.get(frameSearchParamReturnUrl);
            }
            console.log('frameUrl', frameUrl);

            let location = window.location;
            if (frameHost === location.host && frameUrl !== location.href) {
                //pass them onto the next success page for the page rather than just in the frame

                HistoryHelper.replaceState(frameUrl);
            }

        } catch (e) {
        }

        this.loading = false;
    }

    @listen('error', 'frame')
    frameErrored(_e: any) {
        debugger;
        this.loading = false;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-payment-iframe': ComponentShopPaymentIframe;
    }
}