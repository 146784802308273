import {customElement} from 'lit/decorators.js';
import {ComponentAnalyticsDataVisualizerList} from './component-analytics-data-visualizer-list';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-analytics-data-visualizer-list-shop-customers')
class ComponentAnalyticsDataVisualizerListShopCustomers extends ComponentAnalyticsDataVisualizerList {

    @property({type: Array})
    fields: { name: string, style?: string, title: string }[] = [
        {name: '', style: '--col-md: 5', title: 'Name'},
        {name: 'doc_count', title: 'Orders'},
        {name: 'subTotal.sum', title: 'Value'},
        {name: 'subTotal.avg', style: '--col-md: 4', title: 'Value per order'},
    ];

    // language=HTML
    renderRow(item: any) {
        return html`
            <div style="--col-md: 5">
                <a href="/admin/shop/orders?filters[*]=owner.id%3A${item.key}">
                    ${item.firstName.buckets[0].key}
                    ${item.lastName.buckets[0].key}
                </a>
            </div>
            <div style="--col-md: 1">
                ${item.doc_count}
            </div>
            <div style="--col-md: 1">
                &pound;${RenderingHelper._currencyFormat(item.value.sum)}
            </div>
            <div style="--col-md: 4">
                &pound;${RenderingHelper._currencyFormat(item.value.avg)} avg <br>
                <small>(&pound;${RenderingHelper._currencyFormat(item.value.min)} -
                    &pound;${RenderingHelper._currencyFormat(item.value.max)})</small>
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-data-visualizer-list-shop-customers': ComponentAnalyticsDataVisualizerListShopCustomers;
    }
}