import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';
import {generateRegisteredFunctionId} from '../../__internal/shared/helpers/RegisteredFunctionsHelper';

export const ASPIRE_COMPS_PACKAGE_ID = 'aspire-comps';

export let REGISTERED_FUNCTION_ID_pwaInstallPrompt: { registeredFunctionId: string };

export class AspireCompsPackage extends Package {
    id: string = ASPIRE_COMPS_PACKAGE_ID;
    name: string = 'Aspire comps';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];

    constructor() {
        super();

        REGISTERED_FUNCTION_ID_pwaInstallPrompt = generateRegisteredFunctionId(this, 'aspireCompsPwaInstallPrompt');
    }
}