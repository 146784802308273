import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {Search} from './component-input-elasticsearch-select-2';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-input-elasticsearch-select-2-item')
class ComponentInputElasticsearchSelect2Item extends BunnyElement {

    @property({type: Object})
    value: any;

    @property({type: Object})
    search: Search;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: inline-block;
                padding: 4px 8px;
                border-radius: 10px;
                background: var(--primary-text-color);
                color: white;
            }
        `
    ];

    override render(){
        return html`
            ${this.search.name}:

            ${this.isFirestoreReference(this.value) ? html`
                <resolve-firestore-document-name path="${this.value.path}"
                                                 format="${this.getFormatter(this.search)}"></resolve-firestore-document-name>
                
            ` : html`
                Search results in ${this.value.id} matching ${this.value.params.q}
            `}

            <slot></slot>
        `;
    }

    getFormatter(search: Search) {
        return (search.renderer as any).properties.innerHTML.replace(/highlightedResult\./g, '').replace(/~~/g, '::');
    }

    isFirestoreReference(value: any) {
        return !!(value?.id && value?.path);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-elasticsearch-select-2-item': ComponentInputElasticsearchSelect2Item;
    }
}