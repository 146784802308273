import {ElementPrototype} from '../DecoratorsHelper';

export interface ElementPropertyObserver {
    method: (...args: any[]) => any;
    targets: string[];
}

export const observe = <P extends string, El extends ElementPrototype & {
    [K in P]: {} | null | undefined;
}>(...targets: P[]) => {
    return (prototype: El, propName: string) => {
        //DONT replace with ??= as it doesnt account for parent class observers
        let prototypeConstructor = prototype.constructor;
        let observers = prototypeConstructor.hasOwnProperty('observers') ? prototypeConstructor.observers as ElementPropertyObserver[] : (prototypeConstructor.observers = []);

        observers.push({
            method: (prototype as any)[propName],
            targets: targets,
        });
    };
};