import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ShopProductDocument} from '../../shared/helpers/FirebaseHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';

@customElement('component-shop-admin-wait-for-product')
class ComponentShopAdminWaitForProduct extends BunnyElement {

    @property({type: Object})
    @computed('productPath')
    get product() {
        if (!this.productPath) return undefined;

        return new FirestoreDocument<ShopProductDocument>(this, this.productPath, {method: FetchMethod.LIVE});
    };

    @property({type: String})
    productPath: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
        `;
    }

    @observe('product')
    redirectOnProductReady(product?: FirestoreDocument<ShopProductDocument>) {
        if (!product?.data) return;
        if (product.data.created?.getTime() === product.data.updated?.getTime()) return;


        HistoryHelper.replaceState(location.href.replace(/wait-for-product\//, ''));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-wait-for-product': ComponentShopAdminWaitForProduct;
    }
}