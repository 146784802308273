import {customElement} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-firestore-collection-list-header-cell')
class ComponentFirestoreCollectionListHeaderCell extends BunnyElement {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                font-weight: bold;
            }
        `
    ];

    override render(){
        return html`
            <slot></slot>
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-header-cell': ComponentFirestoreCollectionListHeaderCell;
    }
}