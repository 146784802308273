import {ReactiveController, ReactiveControllerHost} from 'lit';

let GLOBAL_STYLESHEETS_COUNT = 0;

export class UnshadowStyles implements ReactiveController {
    host: ReactiveControllerHost;
    globalStyleSheetId: string;

    constructor(host: ReactiveControllerHost) {
        (this.host = host).addController(this);
        this.globalStyleSheetId = `global-style-sheet-${GLOBAL_STYLESHEETS_COUNT++}`;
    }

    hostConnected() {
        if (!(this.host instanceof HTMLElement)) return;

        let nodeName = this.host.tagName.toLowerCase();
        let styles = (this.host.constructor as any).styles;
        if (!Array.isArray(styles)) {
            styles = [styles];
        }

        let stylesheet = document.createElement('style');
        stylesheet.id = this.globalStyleSheetId;
        stylesheet.dataset.forNode = nodeName;

        for (let style of styles) {
            let css = (style.cssText as string)
                .replace(/:host\(([^{]+)\)/g, (_, m1) => `${nodeName}${m1}`)
                .replace(/:host/g, nodeName);
            stylesheet.textContent += css + '\n\n';
        }

        document.head.appendChild(stylesheet);
    }

    hostDisconnected() {
        let stylesheet = document.head.querySelector(`style#${this.globalStyleSheetId}`);
        stylesheet?.parentElement?.removeChild(stylesheet);
    }
}
