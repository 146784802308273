import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';

@customElement('component-recover-account-reset-email-address')
class ComponentRecoverAccountResetEmailAddress extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    loginUrl: string = '/login';

    @property({type: String})
    signupUrl: string = '/signup';

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding: 0 15px 0 15px;
                overflow: hidden;
            }

            .formHeader {
                text-align: center;
                margin-bottom: 25px;
                background-color: var(--primary-color);
                color: white;
                padding: 10px 15px;
                margin-left: -15px;
                margin-right: -15px;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
            }

            .formHeader > * {
                margin: 0;
            }

            .formFooter {
                position: relative;
                margin-top: 35px;
                margin-left: -15px;
                margin-right: -15px;
                padding: 35px 5px 15px 5px;
                border-top: solid rgba(64, 64, 64, .3) 1px;
                background-color: var(--primary-text-color);
                text-align: center;
                display: flex;
                justify-content: center;
            }

            .formFooter:after {
                content: 'Still having issues';
                display: inline-block;
                padding: 3px 15px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: white;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
            }
        `,
    ];

    override render() {
        return html`
            <div class="formHeader">
                <h1>
                    Restore email address
                </h1>
                <p>Existing customers</p>
            </div>

            <component-native-form @submit="${this.sendCallback}">
                <input required type="hidden" name="recoveryCode" readonly .value="${this.route.current.query.oobCode}">

                <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                    Submit
                </button>

                <p>Would you like to restore your email address back to ${this.route.current.query.email}</p>

                <div slot="submitButton" style="margin-top: 50px">
                    <component-button
                            style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                        Restore email address
                    </component-button>

                    <div style="text-align: right; margin-bottom: 10px; margin-top: 10px">
                        <a href="${this.loginUrl}" style="color: var(--primary-color);">Login</a>
                        ·
                        <a href="${this.signupUrl}" style="color: var(--primary-color);">Signup</a>
                    </div>
                </div>
            </component-native-form>

            <div class="formFooter" style="text-align: center">
                <a href="/contact-us">
                    <component-button raised style="background-color: white; color: var(--primary-text-color)">Get
                        support
                    </component-button>
                </a>
            </div>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Resetting account email address',
        successMessage: 'Successfully reset account email address',
        failedMessage: 'Failed resetting account email address: {{e}}',
    })
    @bind()
    async sendCallback(e: FormSubmitEvent) {
        debugger;
        let finalResponse = await e.detail.setResponse(async () => {
            let formData = e.detail.data;
            await this.auth.applyActionCode(formData.recoveryCode);

            HistoryHelper.replaceState('/account');

            return 'Successfully reset account email address';
        });

        if (finalResponse instanceof Error) {
            throw finalResponse;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-recover-account-reset-email-address': ComponentRecoverAccountResetEmailAddress;
    }
}