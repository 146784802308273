import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {
    FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS,
    ShopOrderDocument,
    ShopOrderItem,
} from '../../shared/helpers/FirebaseHelper';
import {firestoreIncrement, firestoreTimestamp} from '../../../__internal/local/helpers/FirestoreHelper';
import {collection, doc, runTransaction} from 'firebase/firestore';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';

@customElement('component-shop-admin-order-products')
class ComponentShopAdminOrderProducts extends BunnyElement {

    @property({type: Object})
    value: any;

    @property({type: Array})
    get fields() {
        return [
            {
                cellProperties: {
                    style: 'border-bottom: solid rgba(32, 32, 32, .5) 1px',
                },
                header: false,
                cell: {
                    component: 'div',
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 7; --col: 8',
                    field: 'name',
                },
                header: {
                    properties: {
                        textContent: 'Product',
                    },
                },
                cell: {
                    component: 'component-data-collection-list-item-cell-order-item-name',
                    properties: {
                        renderFormSubmissionKeys: ['answer'],
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 1; --col: 4',
                    field: 'quantity',
                    type: 'text',
                },
                header: {
                    properties: {
                        textContent: 'Qty',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 1; --col: 4',
                    field: 'productPrice',
                    type: 'currency',
                },
                header: {
                    properties: {
                        textContent: 'Each',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 3; --col: 4',
                    field: 'subTotal',
                    type: 'currency',
                },
                header: {
                    properties: {
                        textContent: 'Total',
                    },
                },
            },
        ];
    }


    newProduct = {
        quantity: '1',
        product: null as any,
    };

    selectProductRenderer = {
        component: 'component-button',
        properties: {
            innerHTML: ':highlightedResult.name:',
            style: 'white-space: pre;',
        },
    };

    selectProductValueFormat = {
        document: ':result._ref:',
        product: ':result:',
        content: ':result.name:',
    };

    @property({type: Object})
    @computed('value')
    get order() {
        let path = this.value?._ref?.path;
        if (!path) return undefined;

        return new FirestoreDocument<ShopOrderDocument>(this, path, {method: FetchMethod.LIVE});
    };

    @property({type: Object})
    @computed('order')
    get orderItems() {
        return Object.values(this.order?.data?.items || {});
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-shop-price {
                display: inline-block;
            }
        `,
    ];

    renderPriceSummary(label: string, value?: number, hideEmpty = true) {
        if (!value && hideEmpty) return;

        return html`
            ${label} &middot;
            <component-shop-price .value="${value}"></component-shop-price>
            <br>
        `;
    }

    override render() {
        let orderPrice = this.value?.price;

        return html`
            <component-firestore-collection-list .fields="${this.fields}" .items="${this.orderItems || []}">
            </component-firestore-collection-list>
            <div class="gridContainer">
                <div style="--col-md: 7; --col: 8"></div>
                <div style="--col-md: 1; --col: 4">${this.value?.itemsTotal?.quantity}</div>
                <div style="--col-md: 1; --col: 4; text-align: right">
                </div>
                <div style="--col-md: 3; --col: 4">
                    ${this.renderPriceSummary('Sub total', orderPrice?.subTotal, false)}
                    ${this.renderPriceSummary('Paid', orderPrice?.paid, false)}
                    ${this.renderPriceSummary('Discount', orderPrice?.discount)}
                    ${this.renderPriceSummary('Refunded', orderPrice?.refunded)}
                    ${this.renderPriceSummary('Total', orderPrice?.total, false)}
                </div>

                ${['draft', 'pending'].includes(this.value?.state) ? html`
                    <div style="--col-md: 7; background-color: rgba(32, 32, 32, .1);">
                        <component-input-elasticsearch-select .value="${this.bindDeep('newProduct.product')}"
                                                              .renderer="${this.selectProductRenderer}"
                                                              .valueFormat="${this.selectProductValueFormat}"
                                                              label="Product"
                                                              .searchId="${'searchShopProductsBackend'}"></component-input-elasticsearch-select>
                    </div>
                    <div style="--col-md: 2; background-color: rgba(32, 32, 32, .1);">
                        <component-input-number label="Quantity"
                                                .value="${this.bindDeep('newProduct.quantity')}"></component-input-number>
                    </div>
                    <div style="--col-md: 3; background-color: rgba(32, 32, 32, .1); color: white">
                        <component-button style="min-width: 0; width: 100%; margin: 10px"
                                          @click="${this.addProduct}">
                            Add
                        </component-button>
                    </div>
                ` : undefined}
            </div>
        `;
    }

    @toastProgressWrapper({progressMessage: 'Adding product', successMessage: 'Added product'})
    @confirmationDialog({
        body: function (this: ComponentShopAdminOrderProducts) {
            return `Are you sure you want to add ${this.newProduct.quantity} of the product ${this.newProduct.product.content}`;
        },
    })
    async addProduct() {
        let orderRef = (this as any).value._ref;
        let product = this.newProduct.product.product;
        let variant = Object.keys(product.variants)[0];
        let productPrice = product.variants[variant].price;
        let newQuantity = parseInt(this.newProduct.quantity as string);
        let orderItemId = `${product._ref.id}-${variant}`;

        let db = orderRef.firestore;
        await runTransaction(db, async (transaction) => {
            let currentOrder = await transaction.get(orderRef);
            let currentOrderData = currentOrder.data() as ShopOrderDocument;


            let update: {
                [key: string]: any
            } = {};

            let priceIncrease = 0;
            let itemsIncrease = 0;
            currentOrderData.items ??= {};
            if (currentOrderData.items[orderItemId]) {
                let currentOrderItemObj = currentOrderData.items[orderItemId] as ShopOrderItem;
                let newQuantityValue = currentOrderItemObj.quantity + newQuantity;
                let newSubTotalValue = newQuantityValue * productPrice;
                priceIncrease += newSubTotalValue - currentOrderItemObj.subTotal;

                update[`items.${orderItemId}.updated`] = firestoreTimestamp();
                update[`items.${orderItemId}.quantity`] = newQuantityValue;
                update[`items.${orderItemId}.subTotal`] = newSubTotalValue;

            } else {
                itemsIncrease++;
                priceIncrease += newQuantity * productPrice;

                update[`items.${orderItemId}`] = {
                    updated: firestoreTimestamp(),
                    created: firestoreTimestamp(),
                    product: product._ref,
                    productVariant: doc(collection(product._ref, FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS), variant) as any,
                    quantity: newQuantity,
                    type: product.type,
                    productCategory: product.category || undefined,
                    name: product.name,
                    productPrice: productPrice,
                    productOriginalPrice: productPrice,
                    wasOnSale: false,
                    productData: {},
                    subTotal: newQuantity * productPrice,
                    state: 'completed',
                } as ShopOrderItem;
            }


            update['updated'] = firestoreTimestamp();
            update['price.subTotal'] = firestoreIncrement(priceIncrease);
            update['price.total'] = firestoreIncrement(0);
            update['itemsTotal.total'] = firestoreIncrement(itemsIncrease);
            update['itemsTotal.quantity'] = firestoreIncrement(newQuantity);
            transaction.update(orderRef, update);
        });

        //TODO trigger a recalc of product quantity and prices
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-order-products': ComponentShopAdminOrderProducts;
    }
}