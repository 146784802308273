import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {createComponent} from '../../../routing/local/helpers/DomHelper';

@customElement('component-aspire-comps-items-section')
class ComponentAspireCompsItemsSection extends BunnyElement {

    @property({type: String})
    title: string;

    @property({type: String})
    moreLink: string;

    @property({type: String})
    moreLinkHref: string;

    @property({type: Array})
    components: any[] = [];

    @property({type: Object})
    data: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                --title-padding: 25px 5px;
            }

            h3 {
                background: var(--primary-text-color);
                color: white;
                padding: var(--title-padding);
                box-shadow: 25vw 0 0 var(--primary-text-color), -25vw 0 0 var(--primary-text-color--lighter);
                --angled-indent: 0px;
                margin-top: 0;
                display: flex;
                font-size: calc(12px + 1vw);
                font-size: min(calc(12px + 1vw), 1.17em);

                span {
                    text-indent: 15px;
                    text-indent: min(calc(5px + 1vw), 15px);
                }
            }


            @media screen and (max-width: 1024px) {
                :host {
                    overflow-x: hidden;
                }
            }
        `,
    ];

    override render() {
        return html`
            <h3 class="angledContainer forceMobileAngledContainer">
                <span style="margin-right: auto">${this.title}</span>

                ${this.moreLink ? html`
                    <a href="${this.moreLinkHref}">
                        <component-button
                                style="vertical-align: middle; font-weight: normal; font-size: 80%; margin-top: -50px; margin-bottom: -50px; background: none"
                                .raised="${false}">
                            ${this.moreLink}
                        </component-button>
                    </a>
                ` : undefined}
            </h3>

            <slot></slot>
            <div id="components">
                ${this.components.map(component => createComponent(component, this.data))}
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-items-section': ComponentAspireCompsItemsSection;
    }
}