import {FirebaseMessagingPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-firebase-messaging';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';

export class FirebaseMessagingLocalPackage extends FirebaseMessagingPackage implements LocalPackage {

    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            e.registerPostComponent({
                component: 'component-firebase-messaging',
            }, 'messaging');
        },
    };
}