import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';
import {NotificationChannel, NotificationChannelType} from '../../shared/helpers/NotificationsHelper.ts';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper.ts';
import {ComponentInputCheckbox} from '../../../inputs/local/components/component-input-checkbox.ts';

export enum CONFIGURATION {
    UNAVAILABLE = 'unavailable',
    AVAILABLE = 'available',
    AWAITING_SETUP = 'awaiting_setup',
}

const AUTO_OPEN_AWAITING_SETUP_DIALOG_DURATION = 5 * 60 * 1000;

export abstract class ComponentNotificationsPreferenceChannelBase extends BunnyElement {

    @property({type: Boolean})
    opened = false;

    @property({type: Object})
    item: any;

    @property({type: Object})
    types: NotificationChannel = {} as any;

    @property({type: Boolean})
    allowsRemoval = true;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    // language=HTML
    renderSetupHtml() {
        return html``;
    }

    // language=HTML
    renderInfoHtml() {
        return html``;
    }

    // language=HTML
    renderFullInfoHtml() {
        return html``;
    }

    // language=HTML
    renderFullManageHtml() {
        return html``;
    }

    override render() {
        return html`
            <component-button-toggle
                    style="display: block; margin: 0 -15px; --padding: 5px 15px; background: var(--primary-color); color: white; border-radius: 0; text-align: left"
                    .active="${this.bind.opened}">
                <div style="display: flex">
                    <span style="margin-top: auto; margin-bottom: auto">
                        <div>
                            ${this.name}
                        </div>
                        
                        <div style="text-transform: none;">
                            ${this.configuration !== CONFIGURATION.AWAITING_SETUP ? this.renderInfoHtml() : html`
                                <div class="contentContainer" style="font-size: 80%">
                                    &nbsp;
                                </div>
                            `}
                        </div>
                    </span>

                    <component-button
                            style="margin-left: auto; background: var(--primary-text-color); --padding: 14px; min-width: 0; flex-shrink: 0; margin-top: -5px; margin-bottom: -5px; margin-right: -15px; border-radius: 0; box-shadow: none;">
                        <component-icon icon="icons:settings"></component-icon>
                    </component-button>
                </div>

                <div style="text-transform: none; font-size: 80%; margin-top: 5px; border-top: solid rgba(255, 255, 255, .2) 1px; margin-left: -15px; margin-right: -15px; padding: 5px 15px; padding-bottom: 0; height: 23px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    ${this.configuration === CONFIGURATION.AVAILABLE ? html`
                        <component-icon icon="social:notifications-active"></component-icon>
                        ${Object.entries(this.types || {})
                                .filter(([key, value]) => this.isChecked(this.item.subscriptions, key, value))
                                .map(([_key, value]) => html`
                                    <span style="background: var(--primary-text-color); padding: 0 5px; border-radius: 5px; margin-bottom: 3px; display: inline-block">${value.name}</span>
                                `)}
                    ` : undefined}
                    ${this.configuration === CONFIGURATION.AWAITING_SETUP ? html`
                        <span style="color: white; background: var(--attention-color); padding: 0 5px; border-radius: 5px;">Requires setup</span>
                    ` : undefined}
                </div>
            </component-button-toggle>


            <component-dialog .opened="${this.bind.opened}">
                <h3 slot="heading">
                    Manage ${this.name} preferences
                </h3>
                <div style="max-width: 500px;">
                    ${this.renderFullInfoHtml()}
                    ${this.configuration === CONFIGURATION.AWAITING_SETUP ? html`
                        ${this.renderSetupHtml()}
                    ` : html`
                        <h4>Get notifications for</h4>
                        ${Object.entries(this.types || {})
                                .map(([key, value]) => html`
                                    <div style="margin-bottom: 15px">
                                        <component-input-checkbox
                                                style="margin-bottom: 5px"
                                                ?disabled="${!value.configurable}"
                                                ?checked="${this.isChecked(this.item.subscriptions, key, value)}"
                                                @click="${(e: MouseEvent) => this.toggleChecked(e, key, value)}">
                                            ${value.name}
                                        </component-input-checkbox>
                                        <p style="margin-top: 0; margin-left: 30px; line-height: 1">
                                            <small>
                                                ${value.description}
                                            </small>
                                        </p>
                                    </div>
                                `)}

                        ${this.renderFullManageHtml()}
                    `}
                </div>

                ${this.renderRemoveChannelHtml()}
                <component-button-toggle slot="footer" .active="${this.bind.opened}"
                                         style="background: var(--primary-color); margin-left: auto">
                    Save
                </component-button-toggle>
            </component-dialog>
        `;
    }

    renderRemoveChannelHtml() {
        if (this.configuration === CONFIGURATION.UNAVAILABLE) return;
        if (!this.allowsRemoval) return;


        return html`
            <component-button slot="footer" style="background: var(--attention-color);"
                              @click="${this.removeChannel}">
                Remove ${this.name}
            </component-button>
        `;
    }

    abstract get name(): string;

    isChecked(subscriptions: any, type: string, typeInfo: NotificationChannelType) {
        if (!subscriptions || !subscriptions[type]) return typeInfo.default;

        return subscriptions[type].enabled;
    }

    toggleChecked(e: MouseEvent, type: string, typeInfo: NotificationChannelType) {
        if ((e.currentTarget as ComponentInputCheckbox).disabled) return;
        let checked = this.isChecked(this.item.subscriptions, type, typeInfo);
        let subscriptions = this.item.subscriptions ??= {};


        if (!subscriptions[type]) {
            subscriptions[type] = {
                enabled: !checked,
            };

        } else {
            subscriptions[type].enabled = !checked;
        }

        this.saveChannel();
    }

    @property({type: String})
    get configuration(): CONFIGURATION {
        return CONFIGURATION.UNAVAILABLE;
    }

    @confirmationDialog({body: 'Are you sure you want to remove this channel?'})
    removeChannel() {
        this.dispatchEvent(new CustomEvent('remove-channel', {
            composed: true,
            bubbles: true,
            detail: {
                channel: this.item,
            },
        }));

        this.opened = false;
    }

    @observe('configuration')
    defaultOpenedOnRequiringSetup(configuration: CONFIGURATION) {
        if (Date.now() - this.item.created > AUTO_OPEN_AWAITING_SETUP_DIALOG_DURATION) return;//dont do auto opening dialog if its been more than 5 minutes

        if (configuration === CONFIGURATION.AWAITING_SETUP) {
            this.opened = true;
        }
    }

    protected saveChannel() {
        this.requestUpdate('item');

        this.dispatchEvent(new CustomEvent('update-channel', {
            composed: true,
            bubbles: true,
            detail: {
                channel: this.item,
            },
        }));
    }
}