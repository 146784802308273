import {delayPromise} from './PromiseHelper.ts';

export interface InternalLocationChangedEvent {
    path: string,
    query: string,
    hash: string,
}

export default class HistoryHelper {
    public static async refresh(notify: boolean = true) {
        let currentUrl = location.href;
        let currentState = history.state;
        let currentTitle = document.title;

        this.replaceState('/__noneExistingUrl', currentTitle, undefined, notify);
        await delayPromise();
        this.replaceState(currentUrl, currentTitle, currentState);
    }

    public static pushState(url: string, title: string = '', data: any = {}, notify: boolean = true) {
        window.history.pushState(data, title, url);

        if (notify) {
            window.dispatchEvent(new CustomEvent('location-changed'));
        }
    }

    public static replaceState(url: string, title: string = '', data: any = {}, notify: boolean = true) {
        window.history.replaceState(data, title, url);

        if (notify) {
            window.dispatchEvent(new CustomEvent('location-changed'));
        }
    }

    public static internalLocationChange(path: string, query: string = '', hash: string = '') {
        window.dispatchEvent(new CustomEvent<InternalLocationChangedEvent>('internal-location-changed', {
            detail: {
                path: path,
                query: query,
                hash: hash,
            },
        }));
    }

    public static redirect(url: string) {
        let locationOrigin = location.origin;
        let urlObj = new URL(url, locationOrigin);

        if (urlObj.origin === locationOrigin) {
            HistoryHelper.pushState(url);

        } else {
            location.href = url;
        }
    }

}