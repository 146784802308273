import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {Route} from '../../../routing/local/controllers/Route.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';

@customElement('component-process-draw-claim')
class ComponentProcessDrawClaim extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    connectedCallback() {
        super.connectedCallback();

        this.doProcess();
    }

    async doProcess() {
        try {
            let params = new URLSearchParams(this.route.current.hash?.substring(1));
            let accessToken = params.get('access_token');
            let documentId = params.get('state');

            localStorage.setItem(`process-comment-draw-claim-access-token:${documentId}`, accessToken ?? '');
            window.opener?.postMessage(accessToken); // opener is `any`, but may be `null`

            window.close(); // Will not close in facebook browser
            HistoryHelper.redirect(`/promotional-draws?accessToken=${accessToken}&documentId=${documentId}`);

        } catch (e) {
            console.error(e);

            window.close(); // Will not close in facebook browser
            HistoryHelper.redirect(`/promotional-draws`);
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-process-draw-claim': ComponentProcessDrawClaim;
    }
}