import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {isWednesday} from 'date-fns';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-aspire-comps-live-banner')
class ComponentAspireCompsLiveBanner extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Boolean, reflect: true})
    @computed('route')
    get visible() {
        return isWednesday(new Date) && this.route.current.path !== '/shop/cart';
    };

    set visible(_value: boolean) {
        //ignore, just to have a setter for the attribute
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 98;
                background-color: var(--primary-text-color);
                /*background-color: var(--attention-color);*/
                display: none;
                text-align: center;
                color: white;
                align-items: center;
                flex-wrap: wrap;
                box-shadow: rgba(200, 200, 200, .5) -2px 0 2px, rgba(200, 200, 200, .5) -4px 0 10px;
            }

            @media screen and (min-height: 500px) {
                :host([visible]) {
                    display: flex;
                }
            }

            p {
                font-size: 25px;
                font-size: calc(15px + .5vw);
                margin: 5px;
            }

            a + a {
                margin-left: 5px;
            }

            img {
                height: 50px;
            }

            .links {
                display: flex;
                justify-content: center;
                margin-right: 5px;
            }

            @media screen and (max-width: 768px) {
                .links {
                    align-items: center;
                    width: 100%;
                    margin: 0
                }

                .links a {
                    margin: 0;
                    width: 50%;
                    display: inline-block;
                }

                .links component-button {
                    border-radius: 0;
                    width: 100%;
                }
            }
        `,
    ];

    override render() {
        return html`
            <div style="min-width: 80px; padding: 5px; font-size: 0">
                <img src="/images/social/youtube.svg" alt="YouTube"
                     style="clip-path: polygon(0 0,70% 0,50% 100%,0 100%)">
                <img src="/images/social/facebook.svg" alt="Facebook"
                     style="clip-path: polygon(50% 0,100% 0,100% 100%,12% 100%); margin-left: -53px">
            </div>

            <p style="flex: 1; min-width: 200px">
                We're live Wednesday at 7:30pm!
            </p>

            <div class="links">
                <a href="/live">
                    <component-button>Watch live</component-button>
                </a>
                <a href="/entry-list">
                    <component-button style="background-color: var(--attention-color)">View tickets</component-button>
                </a>
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-live-banner': ComponentAspireCompsLiveBanner;
    }
}