import {JSONStringify} from '../../shared/helpers/DataHelper';
import {getApp} from 'firebase/app';
import {connectFunctionsEmulator, getFunctions, httpsCallable} from 'firebase/functions';
import {FriendlyMessage} from '../../shared/helpers/ExceptionHelper';
import {config, ENV} from '../../../../config';

const FUNCTION_REGION = 'europe-west1';

let functions: { [key: string]: any } = {};
export const prepareFunction = async (name: string, host: undefined | string = undefined) => {
    let namespace = host || FUNCTION_REGION;


    if (!functions[namespace]) {
        let firebaseApp = getApp();
        functions[namespace] = getFunctions(firebaseApp, host || FUNCTION_REGION);
        if (host) {
            functions[namespace].customDomain = host;
        }


        if (ENV === 'local') {
            connectFunctionsEmulator(functions[namespace], 'localhost', 5001);
        }
    }

    let callable = httpsCallable(functions[namespace], name);

    return async (...args: any[]) => {
        args = JSON.parse(JSONStringify(args));

        let response = await callable(...args);
        let responseData = response.data as any;

        if (responseData.status === 'error') {
            console.error(`Logs: https://console.cloud.google.com/logs/query;query=resource.labels.service_name%3D%22${name}%22;timeRange=PT1H?project=${config.google.firebase.projectId}&authuser=1&hl=en`);
            if (responseData._messages) {
                let friendlyError = new FriendlyMessage(responseData._messages[0]);
                (friendlyError as any)._response = responseData;
                throw friendlyError;
            }
        }

        return response;
    };
};