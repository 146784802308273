import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {DEFAULT_PAGE_LAYOUT} from '../../shared';
import {createComponent} from '../helpers/DomHelper';
import {PageLayoutDefault} from '../pages/page-layout-default';
import './component-page-editor-content-control';
import {AttachmentTypes} from './component-page-editor-content-control';
import {ComponentPageEditorContentEditor} from './component-page-editor-content-editor';
import './component-page-editor-content-editor';
import './component-page-editor-content-add';
import {ComponentPageEditorContentAdd, MODE} from './component-page-editor-content-add';
import {PageContent} from '../../shared/helpers/FirestoreHelper';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper';
import {JSONParseLocal} from '../../../__internal/local/helpers/DataHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html, PropertyValues, TemplateResult} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {ComponentDialog} from '../../../__internal/local/components/component-dialog';
import {dotGet, dotPut} from '../../../__internal/shared/helpers/DotHelper';
import {ref} from '../../../__internal/local/helpers/decorators/RefDecoratorHelper';


@customElement('component-page-editor-content')
class ComponentPageEditorContent extends BunnyElement {

    @ref()
    optionsDialogElement: ComponentDialog;

    @ref()
    newContentElement: ComponentPageEditorContentAdd;

    @ref()
    editorElement: ComponentPageEditorContentEditor;

    @property({type: String})
    label: string;

    @property({type: Object, notify: true})
    value: PageContent;

    @property({type: Object})
    data: any;

    __ignoreFormEditedEvent: boolean;

    @property({type: Object})
    contentNode: PageLayoutDefault;

    @property({type: Boolean})
    validLayout: boolean;

    @property({type: Boolean})
    allowLayoutChange: boolean = true;

    @property({type: Array})
    controls: TemplateResult[];

    static override styles = [
        // language=SCSS
        scss`
            :host {
                position: revert;
            }

            #pageContent {
                background: #eeeeee;
            }

            #pageContent > * {
                --element: {
                    min-height: 25px;
                };
                --region: {
                    padding-top: 25px;
                    padding-bottom: 25px;
                };
            }

            :host(:hover) #pageContent > * {
                --region: {
                    padding-top: 25px;
                    padding-bottom: 25px;
                    outline: solid rgba(255, 0, 0, .3) 2px;
                };
            }

            #controls {
                opacity: 0;
                transition: .125s;
                transition-delay: .125s;
            }

            #controls:hover {
                opacity: 1;
            }
        `];


    override render() {
        let content = this.value;

        return html`
            <h2>${this.label}</h2>

            ${this.allowLayoutChange ? html`
                <component-input label="Layout" .value="${this.bind.value._layout}"></component-input>

                <h3>Page</h3>
            ` : undefined}
            <div id="pageContent">
                ${createComponent({
                    component: content?._layout || DEFAULT_PAGE_LAYOUT,
                    properties: {
                        ...(content?._layoutProperties || {}),
                        content: content,
                        data: this.data,
                    },
                }, this.data)}
            </div>
            <div id="controls">
                ${this.controls}
            </div>

            ${!this.validLayout ? html`
                !!Invalid layout
            ` : undefined}
            <component-page-editor-content-editor ${this.editorElement}
                                                  id="editor"></component-page-editor-content-editor>
            <component-page-editor-content-add ${this.newContentElement}
                                               id="newContent"></component-page-editor-content-add>


            <component-dialog ${this.optionsDialogElement} id="optionsDialog"
                              style="--header-display: none; --footer-display: none">
                <div style="padding-top: 5px">
                    <div style="text-align: center; margin-bottom: 5px">Insert</div>
                    <component-button @click="${(_: MouseEvent) => this.optionsDialogActions('insertBefore')}">Before
                    </component-button>
                    <component-button @click="${(_: MouseEvent) => this.optionsDialogActions('insertAfter')}">After
                    </component-button>
                </div>
                <hr>
                <component-button @click="${(_: MouseEvent) => this.optionsDialogActions('remove')}"
                                  style="display: block">Remove
                </component-button>
            </component-dialog>
        `;
    }

    override updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);

        let currentContentNode = this.shadowRoot?.querySelector<PageLayoutDefault>('#pageContent > *');
        if (this.contentNode !== currentContentNode && currentContentNode) {
            this.contentNode = currentContentNode;
            this.contentNode.setAttribute('only-content', '');
            this.validLayout = !!this.contentNode.regions;
        }
    }

    @observe('value')
    createDefaultValue(value: PageContent) {
        if (value) return;

        this.value = {};
    }

    _rerenderPage() {
        this.performUpdate();
    }

    @observe('contentNode')
    async attachControls() {
        await delayPromise();
        await delayPromise();
        await delayPromise();
        await delayPromise();
        await delayPromise();
        let regions = this.contentNode.regions || {};


        let controls = [];
        for (let region of Object.values(regions)) {
            controls.push(createComponent({
                component: 'component-page-editor-content-control',
                properties: {
                    attachment: region.node,
                    attachmentType: AttachmentTypes.REGION,
                    dataPath: `value.${region.key}`,
                },
            }, {}));

            for (let i in region.elements) {
                controls.push(createComponent({
                    component: 'component-page-editor-content-control',
                    properties: {
                        attachment: region.elements[i],
                        attachmentType: AttachmentTypes.ELEMENT,
                        dataPath: `value.${region.key}.${i}`,
                    },
                }, {}));
            }
        }

        this.controls = controls;
    }

    @listen('edit', 'controls')
    async activateEditor(e: Event) {
        let dataPath = (e as CustomEvent).detail.dataPath;
        let attachmentType = (e as CustomEvent).detail.attachmentType;

        switch (attachmentType) {
            case AttachmentTypes.REGION:
                let newContent = this.newContentElement;
                newContent.dataPath = dataPath;
                newContent.insertIndex = (e as CustomEvent).detail.index;
                newContent.open();
                break;

            case AttachmentTypes.ELEMENT:
                let editor = this.editorElement;
                this.__ignoreFormEditedEvent = true;
                editor.data = null;
                this.performUpdate();
                await new Promise<void>(_ => queueMicrotask(_));

                editor.dataPath = dataPath;
                editor.data = JSONParseLocal(JSONStringify(dotGet(dataPath, this)));

                this.performUpdate();
                await delayPromise();
                this.__ignoreFormEditedEvent = false;
                break;
        }
    }

    @listen('select', 'controls')
    showOptions(e: Event) {
        let parentDataPath = (e as CustomEvent).detail.dataPath.replace(/\.[a-zA-Z0-9]+$/, '') as string;
        let rawPreDataPath = (e as CustomEvent).detail.dataPath.replace('value.', '') as string;

        let preDataPath = rawPreDataPath.replace('value.', '').replace(/([a-zA-Z0-9]+)\.([0-9]+)/, (_m, m1, m2) => {
            return `${m1}.elements.${m2}`;
        });


        // let element = this.get(`contentNode.regions.${preDataPath}`);
        // console.log('element', element);

        let optionsDialog = this.optionsDialogElement;
        optionsDialog.positionTarget = (e as CustomEvent).detail.controlElement;
        optionsDialog.opened = true;
        optionsDialog.dataset.dataPath = preDataPath;
        optionsDialog.dataset.parentDataPath = parentDataPath;
    }

    optionsDialogActions(action: 'insertAfter' | 'insertBefore' | 'remove') {
        let optionsDialog = this.optionsDialogElement;
        let dataPath = optionsDialog.dataset.dataPath as string;
        let index = parseInt((dataPath.match(/\.(\d+)$/) as any)[1], 10);
        let parentDataPath = optionsDialog.dataset.parentDataPath as string;

        switch (action) {
            // @ts-ignore (allow the fall through)
            case 'insertAfter':
                index++;

            case 'insertBefore':
                let newContent = this.newContentElement;
                newContent.dataPath = parentDataPath;
                newContent.insertIndex = index;
                newContent.open();
                break;

            case 'remove':
                let regionName = parentDataPath.replace('value.', '');
                let regions = this.contentNode.regions;
                let region = regions[regionName];
                region.node.removeChild(region.node.children[index]);

                let valueContainer = {
                    value: this.value,
                };

                dotGet(`value.${dataPath}`, valueContainer).splice(index, 1);
                this.requestUpdate('value');
                this.contentNode.regions = {};//clear the regions cache and reinit
                this.attachControls();
                break;

            default:
        }

        optionsDialog.opened = false;
    }

    @listen('selected-component-changed', 'newContent')
    async insertNewElement() {
        let addComponent = this.newContentElement;
        if (!addComponent.opened) return;


        let dataPath = addComponent.dataPath.replace('value.', '');
        let insertInstanceId = addComponent.insertInstanceId;

        let regions = this.contentNode.regions;
        let region = regions[dataPath];
        let previousInsertComponentObj = region.node.querySelector(`[data-dynamic-insert-id="${insertInstanceId}"]`);
        let componentObj: HTMLElement | undefined = addComponent.createElement();


        if (!componentObj) {
            if (previousInsertComponentObj) {
                region.node.removeChild(previousInsertComponentObj);
            }

            return;
        }


        let index = addComponent.insertIndex;

        if (addComponent.mode === MODE.ADD) {
            let valueContainer = {
                value: this.value,
            };

            if (!dotGet(`value.${dataPath}`, valueContainer)) {
                dotPut(`value.${dataPath}`, [], valueContainer);
            }

            await delayPromise();
            dotGet(`value.${dataPath}`, valueContainer).splice(index, 0, addComponent.createComponent());
            this.requestUpdate('value');
            this.contentNode.regions = {};//clear the regions cache and reinit
            this.attachControls();
        }

        if (previousInsertComponentObj) {
            previousInsertComponentObj.replaceWith(componentObj);

        } else {
            region.node.insertBefore(componentObj, region.node.children[index]);
        }
    }

    @listen('data-changed', 'editor')
    formEdited(e: Event) {
        if (this.__ignoreFormEditedEvent) return;
        if (!(e as CustomEvent).detail.value) return;


        let rawPreDataPath = (e.currentTarget as ComponentPageEditorContentEditor).dataPath;
        let preDataPath = rawPreDataPath.replace('value.', '').replace(/([a-zA-Z0-9]+)\.([0-9]+)/, (_m, m1, m2) => {
            return `${m1}.elements.${m2}`;
        });
        let value = (e as CustomEvent).detail.value;


        let element = dotGet(`contentNode.regions.${preDataPath}`, this);
        let tempValueContainer = {
            value: this.value,
        };

        if (typeof value === 'object' && value.component && value.properties) {
            dotPut(rawPreDataPath, value, tempValueContainer);

            for (let subProperty in value.properties) {
                element[subProperty] = value.properties[subProperty];
            }
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-page-editor-content': ComponentPageEditorContent;
    }
}
