import {ReactiveController, ReactiveControllerHost} from 'lit';
import {BunnyElement} from '../components/bunny-element';


export abstract class BunnyController implements ReactiveController {
    private pendingHostResolves = 0;

    hosts: {
        hostKey?: string,
        host: ReactiveControllerHost,
        proxyController: ReactiveController,
    }[] = [];

    constructor(host?: ReactiveControllerHost) {
        if (host) {
            this.addHost(host);
        }
    }

    protected addHost(host: ReactiveControllerHost) {
        let hostContainer = {
            host: host,
            proxyController: {} as ReactiveController,
        };
        this.hosts.push(hostContainer);

        if (this.hostConnected) {
            hostContainer.proxyController.hostConnected = () => {
                this.hostConnected(host);
            }
        }
        if (this.hostDisconnected) {
            hostContainer.proxyController.hostDisconnected = () => {
                this.hostDisconnected(host);
            }
        }
        //@ts-ignore
        if (this.hostUpdate) {
            hostContainer.proxyController.hostUpdate = () => {
                //@ts-ignore
                this.hostUpdate(host);
            }
        }
        //@ts-ignore
        if (this.hostUpdated) {
            hostContainer.proxyController.hostUpdated = () => {
                //@ts-ignore
                this.hostUpdated(host);
            }
        }

        host.addController(hostContainer.proxyController);
        this.pendingHostResolves++;
    }

    protected removeHost(host: ReactiveControllerHost) {
        let removedHostIndex = this.hosts.findIndex(_ => _.host === host);
        let hostItem = this.hosts[removedHostIndex];
        if (!hostItem) return;


        this.hosts.splice(removedHostIndex, 1);
        queueMicrotask(() => {
            host.removeController(hostItem.proxyController);
        });

        if (!hostItem.hostKey) {
            this.pendingHostResolves--;
        }
    }

    //@ts-ignore
    hostConnected(host: ReactiveControllerHost) {
        queueMicrotask(() => {
            this.resolveHostKeys();
        });
    }

    //@ts-ignore
    hostDisconnected(host: ReactiveControllerHost) {
        this.removeHost(host);
    }

    private resolveHostKeys() {
        if (!this.pendingHostResolves) return;

        for (let host of this.hosts) {
            if (host.hostKey) continue;

            let hostElement = host.host as BunnyElement;
            let __proto__ = (hostElement as any);
            while ((__proto__ = __proto__.__proto__) && __proto__ instanceof BunnyElement) {
                for (let i of Object.getOwnPropertyNames(__proto__)) {
                    if ((hostElement as any)[i] !== this) continue;

                    host.hostKey = i;
                    this.pendingHostResolves--;
                }
            }
        }
    }

    protected notifyUpdated() {
        this.resolveHostKeys();

        for (let host of this.hosts) {
            //@ts-ignore
            host.host.requestUpdate(host.hostKey, undefined);
        }
    }
}
