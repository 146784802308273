import {JSONParseLocal} from '../../../__internal/local/helpers/DataHelper';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper';
import {quickDotPut} from '../../../__internal/shared/helpers/DotHelper';

const SESSIONSTORAGE_KEY = '_shopOrderMeta';

export const getOrderMeta = () => {
    try {
        let data = sessionStorage[SESSIONSTORAGE_KEY] || '{}';

        return JSONParseLocal(data);

    } catch (e) {
        console.error('Failed loading shop order meta');
    }

    return {};
};

export const saveOrderMeta = (data: any) => {
    try {
        if (!data) {
            delete sessionStorage[SESSIONSTORAGE_KEY];
            return;
        }

        sessionStorage[SESSIONSTORAGE_KEY] = JSONStringify(data);

    } catch (e) {
        console.error('Failed saving shop order meta');
    }
};


export const addOrderMeta = (key: string, value: any) => {
    let data = getOrderMeta();

    quickDotPut(key, value, data);

    saveOrderMeta(data);
};