import {CACHE_DOCUMENT_DELAYS, FetchMethod, FirestoreData, hostlessRequest, LOADING_STATE} from './FirestoreData';
import {
    doc,
    DocumentData,
    DocumentReference,
    DocumentSnapshot,
    getDoc,
    getDocFromCache,
    getDocFromServer,
    onSnapshot,
    setDoc,
} from 'firebase/firestore';
import {convertFirestoreTimestamps} from '../helpers/FirebaseHelper';


export class FirestoreDocument<T extends DocumentData = any> extends FirestoreData<DocumentReference<T>, T> {

    data!: T;

    static hostlessRequest(path: string, options?: {
        method?: FetchMethod,
        suppressLoadingError?: boolean,
        measurePerformance?: boolean,
    }, streamUpdates?: (data: any) => void) {
        return hostlessRequest(this as any, path, options, streamUpdates);
    }

    async* fetchData(): AsyncGenerator<[LOADING_STATE, any | undefined]> {
        let ref = this.ref;
        if (!ref) return yield [LOADING_STATE.ABORTED, undefined];

        yield [LOADING_STATE.LOADING, undefined] as any;


        let snapshot;
        switch (this.method) {
            case FetchMethod.NETWORK_ONLY:
                snapshot = await getDocFromServer(ref);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.NETWORK_FIRST:
                snapshot = await getDocFromServer(ref);

                if (!snapshot.exists()) {
                    snapshot = await getDocFromCache(ref);
                }

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.CACHE_ONLY:
                snapshot = await getDocFromCache(ref);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.CACHE_FIRST:
                let itemFromCache;

                if (CACHE_DOCUMENT_DELAYS.size) {
                    itemFromCache = await getDocFromCache(ref).catch(_ => undefined);
                    if (itemFromCache) {
                        return yield [LOADING_STATE.STREAMING, itemFromCache];
                    }

                    console.time(`waiting for doc: ${ref.path}`);
                    await Promise.all(CACHE_DOCUMENT_DELAYS);
                    console.timeEnd(`waiting for doc: ${ref.path}`);
                }


                let data = await (getDocFromCache(ref).catch(_ => getDoc(ref as any)));
                let itemFromCacheDocument = (itemFromCache as any)?._document;
                let dataDocument = (data as any)?._document;
                if (itemFromCacheDocument && dataDocument && itemFromCacheDocument.version.toString() === dataDocument.version.toString()) {
                    return yield [LOADING_STATE.LOADED, itemFromCache];
                }


                return yield [LOADING_STATE.LOADED, data];

            case FetchMethod.FASTEST:
                snapshot = await Promise.any([
                    getDocFromCache(ref),
                    getDocFromServer(ref),
                ]);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.FASTEST_THEN_CLEAN:
                let cleanSnapshot = getDocFromServer(ref);
                snapshot = await Promise.any([
                    getDocFromCache(ref),
                    cleanSnapshot,
                ]);


                if (snapshot.metadata.fromCache) {
                    yield [LOADING_STATE.LOADED, snapshot];
                    return yield [LOADING_STATE.LOADED, await cleanSnapshot];

                } else {
                    return yield [LOADING_STATE.LOADED, snapshot];
                }

            case FetchMethod.LIVE:
                let nextData: Promise<DocumentSnapshot<T>>;
                let nextDataCallback: (snapshot: DocumentSnapshot<T>) => void;

                let unsubscribe = onSnapshot(ref, {includeMetadataChanges: true}, (snapshot) => {
                    nextDataCallback(snapshot);
                }, (e) => {
                    console.error('Firestore document error', e);
                });

                let abortListener = () => {
                    unsubscribe();
                    this.abortController?.signal.removeEventListener('abort', abortListener);
                };
                this.abortController?.signal.addEventListener('abort', abortListener);


                while (1) {
                    nextData = new Promise((s) => {
                        nextDataCallback = s;
                    });

                    let snapshot = await nextData;
                    yield [LOADING_STATE.STREAMING, snapshot];
                }

                return yield [LOADING_STATE.ABORTED, undefined];

            case FetchMethod.STATIC:
                return yield [LOADING_STATE.LOADED, (this.options as any).staticContent];
        }
    }

    async receiveData(loadingState: LOADING_STATE, data: DocumentSnapshot) {
        if (loadingState === LOADING_STATE.LOADING) {
            this.loading = true;
            this.data = undefined as any;
            return;
        }

        this.loading = false;
        if (loadingState === LOADING_STATE.ABORTED) return;


        let doc = data.data({serverTimestamps: 'estimate'}) as T;
        if (doc) {
            convertFirestoreTimestamps(doc);

            Object.defineProperties(doc, {
                _ref: {
                    value: data.ref,
                    configurable: true,
                },
                _metadata: {
                    value: data.metadata,
                    configurable: true,
                },
            });
        }

        this.data = doc;
    }

    generateRef(path: string): DocumentReference<T> {
        return doc(FirestoreDocument.db, path) as DocumentReference<T>;
    }


    async save(_data?: any, _path: string | null = null) {
        let db = FirestoreDocument.db;
        let path = _path || this.path;
        let data = _data || this.data;

        if (!db) {
            //dont run if the db isnt ready
            return;
        }
        if (!path) return;

        if (path.endsWith('/') || path.startsWith('/') || path.indexOf('//') !== -1) {
            //dont run if theres unloaded variables
            return;
        }


        this.loading = true;
        try {
            let parts = path.split('/');
            let docId = parts.pop();
            if (docId) {
                this.ref = this.generateRef(`${parts.join('/')}/${docId}`);
            }

            if (data && !data.__ignore) {
                await setDoc(this.ref as any, data, {merge: true});
            }

        } finally {
            this.loading = false;
        }
    }

}
