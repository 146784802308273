import {html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../packages/__internal/local/components/bunny-element';
import {scss} from '../packages/__internal/local/helpers/StyleHelper';
import {bind} from '../packages/__internal/local/helpers/decorators/BindDecoratorHelper';

//TODO fix positining of ripple relative to the attached parent and not the clicked node
@customElement('component-ripple')
export class ComponentRipple extends BunnyElement {
    rippleContainers: Set<any> = new Set();

    // language=SCSS
    static override styles = scss`
        :host {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;
            pointer-events: none;

            --ripple-color: rgba(255, 255, 255, .25);
        }

        div {
            border-radius: 100%;
            background: var(--ripple-color);
            width: var(--size);
            height: var(--size);
            opacity: 0;
            transform: translateX(-50%) translateY(-50%) scale(0);
            transform-origin: center center;
            position: absolute;
            top: var(--top, 50%);
            left: var(--left, 50%);
            pointer-events: none;

            &.animate {
                transition: transform .375s linear, opacity .375s linear;
                transform: translateX(-50%) translateY(-50%) scale(1);
                opacity: 1;

                //&.slow {
                //    transition: transform 3s linear,  opacity 3s linear;
                //}
                &.destroy {
                    transition: transform .375s linear, opacity .25s linear;
                    opacity: 0;
                    //    
                    //    &.slow {
                    //        transition: transform 3s linear, opacity .25s linear;
                    //    }
                }
            }
        }
    `;

    override render() {
        return html`
            <!--            <div style="&#45;&#45;size: 100px;"></div>-->
        `;
    }

    connectedCallback() {
        super.connectedCallback();


        this.parentNode?.addEventListener('mousedown', this.onMouseDown as any);
        this.parentNode?.addEventListener('touchstart', this.onTouchStart as any);
        this.parentNode?.addEventListener('click', this.onClick as any);
    }

    disconnectedCallback() {
        super.disconnectedCallback();


        this.parentNode?.removeEventListener('mousedown', this.onMouseDown as any);
        this.parentNode?.removeEventListener('touchstart', this.onTouchStart as any);
        this.parentNode?.removeEventListener('click', this.onClick as any);
    }

    activateRipple(positionX: number = 0.5, positionY: number = 0.5, mouseX = 0, mouseY = 0) {
        let size = Math.max(
            this.offsetWidth || 0,
            this.offsetHeight || 0,
        ) * 2;

        let ripple = document.createElement('div');
        ripple.style.setProperty('--size', `${size}px`);
        ripple.style.setProperty('--left', `${positionX * 100}%`);
        ripple.style.setProperty('--top', `${positionY * 100}%`);

        this.shadowRoot?.append(ripple);

        requestAnimationFrame(() => {
            ripple.classList.add('slow');
            ripple.classList.add('animate');
        });


        let rippleContainer = {
            ripple: ripple,
            activateMouseX: mouseX,
            activateMouseY: mouseY,
            activateScrollY: window.scrollY,
            destroyTimeoutId: -1,
            destroy: async (timeout: number = 0) => {
                rippleContainer.undestroy();

                if (timeout) {
                    rippleContainer.destroyTimeoutId = window.setTimeout(() => {
                        rippleContainer.destroy();
                    }, timeout);

                } else {
                    this.rippleContainers.delete(rippleContainer);

                    ripple.classList.add('destroy');
                    await new Promise(_ => setTimeout(_, 250));
                    ripple.parentNode?.removeChild(ripple);
                }
            },
            undestroy() {
                clearTimeout(rippleContainer.destroyTimeoutId);
                rippleContainer.destroyTimeoutId = -1;
            },
        };

        this.rippleContainers.add(rippleContainer);
        return rippleContainer;
    }

    deactivateRipple(timeout: number = 0) {
        for (let rippleContainer of this.rippleContainers) {
            (rippleContainer as any).destroy(timeout);
        }
    }

    undeactivateRipple() {
        for (let rippleContainer of this.rippleContainers) {
            (rippleContainer as any).undestroy();
        }
    }

    deactivateRippleMove(mouseX: number, mouseY: number) {
        for (let rippleContainer of this.rippleContainers) {
            let distance = Math.sqrt((mouseX - rippleContainer.activateMouseX) ** 2 + (mouseY - rippleContainer.activateMouseY) ** 2);
            if (distance < 15) continue;

            (rippleContainer as any).destroy();
        }
    }

    deactivateRippleScroll(scrollY: number) {
        for (let rippleContainer of this.rippleContainers) {
            if (Math.abs(rippleContainer.activateScrollY - scrollY) < 15) continue;

            (rippleContainer as any).destroy();
        }
    }

    @bind()
    onMouseDown(e: MouseEvent) {
        //destroy any current ones
        this.deactivateRipple();


        let x = e.offsetX / this.offsetWidth;
        let y = e.offsetY / this.offsetHeight;
        this.activateRipple(x, y, e.pageX, e.pageY);

        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('mousemove', this.onMouseMove);
    }

    @bind()
    onTouchStart(e: TouchEvent) {
        //destroy any current ones
        this.deactivateRipple();

        let touch = e.touches[0];

        let rect = this.getBoundingClientRect();
        let x = (touch.clientX - rect.x) / this.offsetWidth;
        let y = (touch.clientY - rect.y) / this.offsetHeight;
        this.activateRipple(x, y, touch.pageX, touch.pageY);

        window.addEventListener('touchend', this.onTouchEnd);
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('touchmove', this.onTouchMove);
    }

    @bind()
    onMouseUp(_e: MouseEvent) {
        for (let rippleContainer of this.rippleContainers) {
            rippleContainer.ripple.classList.remove('slow');
        }


        window.removeEventListener('mouseup', this.onMouseUp);
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('mousemove', this.onMouseMove);


        this.deactivateRipple(50);
    }

    @bind()
    onTouchEnd(_e: TouchEvent) {
        for (let rippleContainer of this.rippleContainers) {
            rippleContainer.ripple.classList.remove('slow');
        }


        window.removeEventListener('touchend', this.onTouchEnd);
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('mousemove', this.onMouseMove);


        this.deactivateRipple(50);
    }

    @bind()
    onClick(_e: MouseEvent) {
        this.undeactivateRipple();
        this.deactivateRipple(375);
        //complete ripples
    }

    @bind()
    onScroll() {
        this.deactivateRippleScroll(window.scrollY);
    }

    @bind()
    onMouseMove(e: MouseEvent) {
        this.deactivateRippleMove(e.pageX, e.pageY);
    }

    @bind()
    onTouchMove(e: TouchEvent) {
        let rect = this.getBoundingClientRect();
        this.deactivateRippleMove(
            e.touches[0].pageX - rect.x,
            e.touches[0].pageY - rect.y,
        );
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'component-ripple': ComponentRipple;
    }
}
