import {customElement} from 'lit/decorators.js';
import {ComponentAnalytics} from './component-analytics';
import {loadTrackingLibrary} from '../helpers/TrackingLibraryLoaderHelper';
import {EventType, TypeParams} from '../helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-facebook-analytics')
class ComponentFacebookAnalytics extends ComponentAnalytics {

    private fbq: any;

    @property({type: String})
    pixelId: string;

    protected async startup() {
        let fbq: any = this.fbq = function () {
            fbq.callMethod ? fbq.callMethod.apply(fbq, arguments) : fbq.queue.push(arguments);
        };
        fbq.queue = [];
        fbq.push = fbq;
        (window as any).fbq = fbq;


        await loadTrackingLibrary('//connect.facebook.net/en_US/fbevents.js');
        // this.fbq = (window as any).fbq;
        this.fbq('init', this.pixelId);
    }

    protected setUserId(_id: string, _options: any = {}) {
    }

    protected setCurrentScreen(_id: string, _options: any = {}) {
    }

    protected setUserProperties(_properties: object, _options: any = {}) {
    }

    protected generateEventHandlers(): { [key in EventType]: (params: TypeParams[key]) => void } {
        let track = (...args: any[]) => {
            this.fbq('track', ...args);
        };

        return {
            appPerformance() {
            },
            appError() {
            },
            viewContent(params) {
                track('ViewContent', {
                    content_ids: [params.id],
                    content_category: params.categories ? params.categories[0] : undefined,
                    content_name: params.name,
                    content_type: params.type,
                    contents: [{id: params.id, quantity: 1}],
                    currency: params.currency,
                    value: params.value,
                });
            },
            shopAddToCart(params) {
                track('AddToCart', {
                    content_ids: [params.id],
                    content_name: params.name,
                    content_type: 'product',
                    contents: [{id: params.id, quantity: params.quantity}],
                    currency: params.currency,
                    value: params.value,
                });
            },
            pageView() {
                track('PageView');
            },
            accountRegister() {
                track('CompleteRegistration');
            },
            accountLogin() {
            },
            shopPurchase(params) {
                track('Purchase', {
                    content_ids: params.items.map(_ => _.id),
                    content_name: params.items.map(_ => _.name).join(', '),
                    content_type: 'product',
                    contents: params.items.map(_ => ({id: _.id, quantity: _.quantity})),
                    currency: params.currency,
                    num_items: params.items.length,
                    value: params.value,
                });
            },
            shopStartCheckout(params) {
                track('InitiateCheckout', {
                    content_category: [...new Set(([] as string[]).concat(...params.items.map(_ => _.categories as string[])))],
                    content_ids: params.items.map(_ => _.id),
                    contents: params.items.map(_ => ({id: _.id, quantity: _.quantity})),
                    currency: params.currency,
                    num_items: params.items.length,
                    value: params.value,
                });
            },
            interactableView() {
            },
            interactableClick() {
            },
            pwaPrompt() {
            },
            toastShown() {
            },
        };
    }


}


declare global {
    interface HTMLElementTagNameMap {
        'component-facebook-analytics': ComponentFacebookAnalytics;
    }
}