import LocalPackage from '../../__internal/local/LocalPackage.ts';
import {AspireCompsCommentPickerPackage} from '../shared';

import './components/component-promotional-draws';
import './components/component-process-draw-claim';

export class AspireCompsCommentPickerLocalPackage extends AspireCompsCommentPickerPackage implements LocalPackage {
    constructor() {
        super();
    }
}