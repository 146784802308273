import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-admin-order-transactions')
class ComponentShopAdminOrderTransactions extends BunnyElement {

    @property({type: Object})
    value: any;

    @property({type: Array})
    get fields() {
        return [
            {
                cellProperties: {
                    style: '--col-md: 2',
                    field: 'provider',
                },
                header: {
                    properties: {
                        textContent: 'Provider',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 1',
                    field: 'value',
                    type: 'currency',
                },
                header: {
                    properties: {
                        textContent: 'Value',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 1',
                    field: 'type',
                },
                header: {
                    properties: {
                        textContent: 'Type',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 2',
                    field: 'state',
                },
                header: {
                    properties: {
                        textContent: 'State',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 2',
                    field: 'completed',
                    type: 'datetime',
                },
                header: {
                    properties: {
                        textContent: 'Completed',
                    },
                },
                cell: {
                    properties:{
                        format: 'dd/MM/yyyy HH;mm;ss.SSS',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 2',
                    field: 'created',
                    type: 'datetime',
                },
                header: {
                    properties: {
                        textContent: 'Created',
                    },
                },
                cell: {
                    properties:{
                        format: 'dd/MM/yyyy HH;mm;ss.SSS',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 2',
                },
                header: {
                    properties: {
                        textContent: '',
                    },
                },
                cell: {
                    component: 'component-data-collection-list-item-cell-admin-order-transaction-actions',
                },
            },
            {
                cellProperties: {
                    field: '_ref.id',
                    style: 'font-size: 60%'
                },
                header: false,
            },
        ];
    }

    listOrder = [['created', 'asc']];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            <component-firestore-collection-list path="${this.value?._ref?.path}/transactions"
                                                 .fields="${this.fields}"
                                                 .fetchMethod="${'live'}"
                                                 .order="${this.listOrder}"
            >
            </component-firestore-collection-list>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-order-transactions': ComponentShopAdminOrderTransactions;
    }
}
