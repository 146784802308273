import {customElement} from 'lit/decorators.js';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {MediaDocumentAccessField} from '../../shared/helpers/FirebaseHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FirebaseStorageFile} from '../../../__internal/local/components/firebase-storage-file';


@customElement('component-input-media-file')
class ComponentInputMediaFile extends BunnyElement {

    @property({type: String})
    label: string;

    @property({type: Object, notify: true})
    value: any;

    @property({type: Boolean})
    multiple: boolean = false;

    @property({type: Array, notify: true})
    values: any[] = [];

    @property({type: String})
    type: string = 'default';

    @property({type: Object})
    meta: any;

    @property({type: String})
    accept: string = 'image/*';

    @property({type: String})
    access: MediaDocumentAccessField = 'private';

    @property({type: Array})
    uploads: any | any[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                padding: 8px 0;
            }

            .label {
                font-family: var(--paper-font-caption_-_font-family);
                -webkit-font-smoothing: var(--paper-font-caption_-_-webkit-font-smoothing);
                white-space: var(--paper-font-caption_-_white-space);
                overflow: var(--paper-font-caption_-_overflow);
                text-overflow: var(--paper-font-caption_-_text-overflow);
                font-size: var(--paper-font-caption_-_font-size);
                font-weight: var(--paper-font-caption_-_font-weight);
                letter-spacing: var(--paper-font-caption_-_letter-spacing);
                line-height: var(--paper-font-caption_-_line-height);
                margin-bottom: 5px;
            }

            bunny-file-supplier {
                margin-bottom: 10px;
            }

            component-progress {
                margin-top: 3px;
                padding: 5px;
            }

            #uploads {
                max-height: 165px;
                overflow-y: scroll;
            }
        `,
    ];

    calculateUploadSummary(uploads: File[]) {
        let size = 0;
        let loaded = 0;
        let completedCount = 0;

        for (let upload of uploads) {
            let uploadLoaded = (upload as any).loaded || 0;
            size += upload.size;
            loaded += uploadLoaded;

            if (upload.size === uploadLoaded) {
                completedCount += 1;
            }
        }

        return {
            size: size,
            loaded: loaded,
            count: uploads.length,
            completedCount: completedCount,
        };
    }

    override render() {
        let uploads = (Array.isArray(this.uploads) ? this.uploads : [this.uploads])
            .filter(_ => _);
        let uploadSummary = this.calculateUploadSummary(uploads);


        return html`
            ${this.label ? html`
                <div class="label">
                    ${this.label}
                </div>
            ` : undefined}

            ${this.currentlyProcessingUpload?.__processing ? html`
                <firebase-storage-file .type="${this.type}"
                                       .meta="${this.meta}"
                                       .access="${this.access}"
                                       .uploadReference="${this.currentlyProcessingUpload.__processing}"></firebase-storage-file>
            ` : undefined}

            <bunny-file-supplier .accept="${this.accept}"
                                 .value="${this.bind.uploads}"
                                 @value-changed="${() => {
                                     this.requestUpdate('uploads');
                                 }}"
                                 .multiple="${this.multiple}">

            </bunny-file-supplier>
            ${uploadSummary.count > 2 ? html`
                <component-progress .value="${uploadSummary.loaded}"
                                    .min="${0}"
                                    .max="${uploadSummary.size}">
                    ${uploadSummary.completedCount}/${uploadSummary.count}
                </component-progress>
            ` : undefined}


            <div id="uploads">
                ${uploads?.map(item => html`
                    <component-progress .value="${item.loaded ?? 0}"
                                        .min="${0}"
                                        .max="${item.size}">
                        <p style="overflow: hidden; word-break: break-word; white-space: nowrap; text-overflow: ellipsis">
                            ${item.name} <br>
                        </p>
                        <small>
                            ${item.status ? html`
                                ${item.status} &middot;
                            ` : undefined}

                            ${item.size}
                        </small>
                    </component-progress>
                `)}
            </div>
        `;
    }

    getCurrentlyProcessingUpload(uploads: any[]) {
        let lastCompletedIndex = uploads.findLastIndex((_: any) => _.complete);
        let currentlyProcessing = uploads[lastCompletedIndex + 1];

        return currentlyProcessing;
    }

    @property({type: Object})
    currentlyProcessingUpload: any;

    @observe('uploads')
    async processNextUpload(uploads: any | any[]) {
        uploads = (Array.isArray(this.uploads) ? this.uploads : [this.uploads])
            .filter(_ => _);

        let currentlyProcessing = this.currentlyProcessingUpload = this.getCurrentlyProcessingUpload(uploads);
        if (!this.currentlyProcessingUpload) return;

        if (!currentlyProcessing.__processing) {
            currentlyProcessing.__processing = true;
            this.processFile(currentlyProcessing);

            await delayPromise();
            await delayPromise();
            await delayPromise();
            await delayPromise();
            await delayPromise();
            await delayPromise();
            currentlyProcessing.__processing = this.values[this.values.length - 1];
            this.requestUpdate('currentlyProcessingUpload');
            await delayPromise();

            this.shadowRoot?.querySelector<FirebaseStorageFile>('firebase-storage-file')?.upload(currentlyProcessing);
        }
    }

    async processFile(file: File, preserveMediaReference = false): Promise<any> {
        if (!this.multiple) {
            this.values = [];
        }

        let mediaPromise = new Promise<any>(async (res, rej) => {
            await delayPromise();
            let uploadObject = {
                type: this.type,
                media: null,
            };

            if (preserveMediaReference && this.values[0] && this.values[0].media) {
                uploadObject.media = this.values[0].media;
            }

            Object.defineProperty(uploadObject, '_uploadFile', {value: file});
            Object.defineProperty(uploadObject, '_waitForMedia', {
                get(): any {
                    return mediaPromise;
                },
            });
            Object.defineProperty(uploadObject, '_notifyFileChanges', {
                value: () => {
                    this.requestUpdate('uploads');
                    this.requestUpdate('values');
                    this.requestUpdate('value');

                    let uploadFile = (uploadObject as any)._uploadFile;
                    console.log('uploadFile', uploadFile);

                    if (uploadFile.complete) {
                        res(uploadObject);

                    } else if (uploadFile.error) {
                        rej(uploadObject);
                    }
                },
            });
            this.values.push(uploadObject);
            this.requestUpdate('values');
            this.requestUpdate('value');
        });

        return await mediaPromise;
    }

    async uploadRawFileBlob(blob: any, type: string, filename: string, preserveMediaReference = false) {
        let file = new File([blob], filename, {type: type});

        return await this.processFile(file, preserveMediaReference);
    }

    async uploadRawFileContents(contents: any, type: string, filename: string, preserveMediaReference = false) {
        let blob = new Blob([contents], {type: type});

        return await this.uploadRawFileBlob(blob, type, filename, preserveMediaReference);
    }

    @observe('value')
    mapValueToValues(value: any) {
        if (Array.isArray(value)) {
            this.values = value;

        } else if (value && typeof value === 'object') {
            this.values = [value];

        } else {
            this.values = [];
        }
    }

    @observe('values')
    mapValuesToValue(values: any) {
        let newValue = undefined;
        if (this.multiple) {
            newValue = values;

        } else {
            newValue = values[0];
        }

        if (newValue !== this.value) {
            this.value = newValue;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-media-file': ComponentInputMediaFile;
    }
}