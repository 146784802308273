import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';


@customElement('component-prize-claiming-messages-winner-quote-request')
class ComponentPrizeClaimingMessagesWinnerQuoteRequest extends ComponentPrizeClaimingMessagesMessage {

    override render() {
        return html`
            ${super.render()}

            <component-native-form @submit="${this.sendCallback}" class="footer"
                                   style="--loading-inset: -500px; padding-bottom: 0; padding-top: 0">
                <component-input-textarea
                        name="winnerQuote"
                        label="Your winning quote"
                        required
                ></component-input-textarea>

                <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                    Submit
                </button>

                <div slot="submitButton">
                    <component-button raised
                                      style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                        Submit a quote about winning with Aspire Competitions!
                    </component-button>
                </div>
            </component-native-form>
        `;
    }

    @bind()
    async sendCallback(e: FormSubmitEvent) {
        this.dispatchEvent(new CustomEvent('sendMessageRequest', {
            detail: {
                newMessage: {
                    type: 'winnerQuoteResponse',
                    body: e.detail.data.winnerQuote,
                },
            },
        }));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-winner-quote-request': ComponentPrizeClaimingMessagesWinnerQuoteRequest;
    }
}