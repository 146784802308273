import {customElement} from 'lit/decorators.js';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {BunnyElement} from './bunny-element';
import {html} from 'lit';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';

@customElement('component-grid')
export class ComponentGrid extends BunnyElement {
    @property({type: Array})
    components: any[] = [];

    @property({type: Object})
    data: any = {};

    @property({type: Object})
    componentExtendedProperties: any = {};

    @property({type: String})
    itemsClass: string = 'gridContainer';

    @property({type: String})
    itemsStyle: string = '';


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            <div id="items" class="${this.itemsClass}" style="${this.itemsStyle}">
                ${this.components.map(component=>createComponent(component, this.data, this.componentExtendedProperties))}
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-grid': ComponentGrid;
    }
}