import {customElement} from 'lit/decorators.js';
import {ComponentInputSelect} from './component-input-select.ts';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper.ts';

@customElement('component-input-select-multiple')
export class ComponentInputSelectMultiple extends ComponentInputSelect {

    selectSelectingIndex() {
        if (!Array.isArray(this.value)) {
            this.value = [];
        }


        let newValue = this.selectingItem?.value;
        let newValueIndex = this.value.indexOf(newValue);
        if (newValueIndex === -1) {
            this.value.push(newValue);

        } else {
            this.value.splice(newValueIndex, 1);
        }

        this.value = [...this.value];//nuke the reinjects
    }


    @observe('value')
    updateHasValue(value: any) {
        this.hasValue = !!value?.length;
    }

    @bind()
    onKeyDown(e: KeyboardEvent) {
        if (e.key === 'ArrowDown') {
            this.selectingIndex++;

            if (this.selectingIndex >= this.items.length) {
                this.selectingIndex = 0;
            }

        } else if (e.key === 'ArrowUp') {
            this.selectingIndex--;

            if (this.selectingIndex < 0) {
                this.selectingIndex = this.items.length - 1;
            }

        } else if (e.key === ' ') {
            requestAnimationFrame(() => {
                this.selectSelectingIndex();
            });

        } else if (e.key === 'Enter') {
            this.dropDownOpen = !this.dropDownOpen;

        } else if (e.key === 'Escape') {
            this.dropDownOpen = false;
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-select-multiple': ComponentInputSelectMultiple;
    }
}