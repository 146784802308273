import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-analytics-data-visualizer-graph')
class ComponentAnalyticsDataVisualizerGraph extends BunnyElement {

    @property({type: Object})
    data: any;

    @property({type: String})
    @storageBoundQueryString('chartSeries')
    chartSeries: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            <component-highcharts .data="${this.convertData(this.data, this.chartSeries)}"></component-highcharts>
        `;
    }

    convertData(data: any, chartSeries: string) {
        if (!data) return;

        let availableSeries = [
            {
                name: 'Orders',
                turboThreshold: 1,
                boost: true,
                get data() {
                    return data.buckets.map((_: any) => ([
                        new Date(_.key).getTime(),
                        _.doc_count,
                    ]));
                },
            },
            {
                name: 'Items',
                turboThreshold: 1,
                boost: true,
                get data() {
                    return data.buckets.map((_: any) => ([
                        new Date(_.key).getTime(),
                        _.item.quantity.sum,
                    ]));
                },
            },
            {
                name: 'Value',
                turboThreshold: 1,
                boost: true,
                get data() {
                    return data.buckets.map((_: any) => ([
                        new Date(_.key).getTime(),
                        (_.item.subTotal.sum) / 100,
                    ]));
                },
                labelPrefix: '£',
            },
        ];

        return {
            series: [availableSeries.find(_ => chartSeries ? _.name === chartSeries : true)],
        };
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-data-visualizer-graph': ComponentAnalyticsDataVisualizerGraph;
    }
}