import {CACHE_DOCUMENT_DELAYS, FetchMethod, FirestoreData, hostlessRequest, LOADING_STATE} from './FirestoreData';
import {
    collection,
    CollectionReference,
    DocumentData,
    getDocsFromCache,
    getDocsFromServer,
    onSnapshot,
    query,
    QuerySnapshot,
} from 'firebase/firestore';
import {convertFirestoreTimestamps} from '../helpers/FirebaseHelper';
import {ReactiveControllerHost} from 'lit';
import {FirestoreDocument} from './FirestoreDocument';
import {QueryConstraint} from '@firebase/firestore';


export class FirestoreCollection<T extends DocumentData = any> extends FirestoreData<CollectionReference<T>, T> {

    data!: T[] & {
        _ref: CollectionReference<T>,
        _metadata: {
            hasPendingWrites: boolean,
            fromCache: boolean
        }
    };

    queryExtendFunction: ((ref: CollectionReference<T>) => QueryConstraint[]) | undefined;

    static hostlessRequest<TT extends DocumentData = any>(path: string, options?: {
        method?: FetchMethod,
        suppressLoadingError?: boolean,
        measurePerformance?: boolean,
        queryExtendFunction?: (ref: CollectionReference<TT>) => QueryConstraint[],
    }, streamUpdates?: (data: any) => void) {
        return hostlessRequest(this as any, path, options, streamUpdates);
    }

    constructor(host: ReactiveControllerHost, path: string, options?: {
        method?: FetchMethod,
        suppressLoadingError?: boolean,
        measurePerformance?: boolean,
        queryExtendFunction?: (ref: CollectionReference<T>) => QueryConstraint[],
    }) {
        super(host, path, options);

        this.queryExtendFunction = options?.queryExtendFunction;
    }

    async* fetchData(): AsyncGenerator<[LOADING_STATE, any | undefined]> {
        let ref = this.ref as CollectionReference<T>;
        if (!ref) return yield [LOADING_STATE.ABORTED, undefined];

        yield [LOADING_STATE.LOADING, undefined] as any;

        if (this.queryExtendFunction) {
            ref = query(ref, ...this.queryExtendFunction(ref)) as any; //maybe do typing properly when finished
        }


        let cleanSnapshot;
        let snapshot;
        switch (this.method) {
            case FetchMethod.NETWORK_ONLY:
                snapshot = await getDocsFromServer(ref);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.NETWORK_FIRST:
                snapshot = await getDocsFromServer(ref);

                if (snapshot.empty) {
                    snapshot = await getDocsFromCache(ref);
                }

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.CACHE_ONLY:
                snapshot = await getDocsFromCache(ref);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.CACHE_FIRST:
                let itemsFromCache;

                if (CACHE_DOCUMENT_DELAYS.size) {
                    itemsFromCache = await getDocsFromCache(ref).catch(_ => undefined);
                    if (itemsFromCache?.docs?.length) {
                        return yield [LOADING_STATE.STREAMING, itemsFromCache];
                    }

                    console.time(`waiting for collection`);
                    await Promise.all(CACHE_DOCUMENT_DELAYS);
                    console.timeEnd(`waiting for collection`);
                }


                let data = await getDocsFromCache(ref);

                if (data.empty) {
                    data = await getDocsFromServer(ref);
                }
                if (itemsFromCache && data && itemsFromCache.docs.map(_ => (_ as any)._document.version.toString()).join(',') === data.docs.map(_ => (_ as any)._document.version.toString()).join(',')) {
                    return yield [LOADING_STATE.LOADED, itemsFromCache];
                }


                return yield [LOADING_STATE.LOADED, data];

            case FetchMethod.FASTEST:
                cleanSnapshot = getDocsFromServer(ref);
                snapshot = await Promise.any([
                    getDocsFromCache(ref),
                    cleanSnapshot,
                ]);

                if (snapshot.metadata.fromCache && snapshot.docs.length) {
                    return yield [LOADING_STATE.LOADED, snapshot];

                } else {
                    return yield [LOADING_STATE.LOADED, await cleanSnapshot];
                }

            case FetchMethod.FASTEST_THEN_CLEAN:
                cleanSnapshot = getDocsFromServer(ref);
                snapshot = await Promise.any([
                    getDocsFromCache(ref),
                    cleanSnapshot,
                ]);


                if (snapshot.metadata.fromCache) {
                    yield [LOADING_STATE.LOADED, snapshot];
                    return yield [LOADING_STATE.LOADED, await cleanSnapshot];

                } else {
                    return yield [LOADING_STATE.LOADED, snapshot];
                }

            case FetchMethod.LIVE:
                let nextData: Promise<QuerySnapshot<T>>;
                let nextDataCallback: (snapshot: QuerySnapshot<T>) => void;

                let unsubscribe = onSnapshot(ref, {includeMetadataChanges: true}, (snapshot) => {
                    nextDataCallback(snapshot);
                }, (e) => {
                    console.error('Firestore collection error', e);
                });

                let abortListener = () => {
                    unsubscribe();
                    this.abortController?.signal.removeEventListener('abort', abortListener);
                };
                this.abortController?.signal.addEventListener('abort', abortListener);


                while (1) {
                    nextData = new Promise((s) => {
                        nextDataCallback = s;
                    });

                    let snapshot = await nextData;
                    yield [LOADING_STATE.STREAMING, snapshot];
                }

                return yield [LOADING_STATE.ABORTED, undefined];

            case FetchMethod.STATIC:
                return yield [LOADING_STATE.LOADED, (this.options as any).staticContent];
        }
    }

    async receiveData(loadingState: LOADING_STATE, data: QuerySnapshot) {
        if (loadingState === LOADING_STATE.LOADING) {
            this.loading = true;
            let docs: T[] = [];
            Object.defineProperties(docs, {
                _ref: {
                    value: this.ref,
                },
                _metadata: {
                    value: {
                        fromCache: true,
                        hasPendingWrites: false,
                    },
                },
            });
            this.data = docs as any;
            return;
        }

        this.loading = false;
        if (loadingState === LOADING_STATE.ABORTED) return;


        let docs = data.docs.map(_ => {
            let doc = _.data({serverTimestamps: 'estimate'}) as T;

            convertFirestoreTimestamps(doc);

            Object.defineProperties(doc, {
                _ref: {
                    value: _.ref,
                    configurable: true,
                },
                _metadata: {
                    value: _.metadata,
                    configurable: true,
                },
            });

            return doc;
        });
        Object.defineProperties(docs, {
            _ref: {
                value: this.ref,
            },
            _metadata: {
                value: data.metadata,
            },
        });
        this.data = docs as any;
    }

    generateRef(path: string): CollectionReference<T> {
        return collection(FirestoreDocument.db, path) as CollectionReference<T>;
    }

}
