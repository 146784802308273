import {customElement} from 'lit/decorators.js';
import {ComponentFirestoreCollectionListItemCell} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-data-collection-list-item-cell-order-item-name')
class ComponentDataCollectionListItemCellOrderItemName extends ComponentFirestoreCollectionListItemCell {

    @property({type: Array})
    renderFormSubmissionKeys: string[] = [];

    @property({type: Array})
    @computed('item', 'renderFormSubmissionKeys')
    get additionalDetails() {
        let formSubmission = this.item.productData?.formSubmission;
        if (!formSubmission) return [];


        let ret = [];
        for (let key in formSubmission) {
            if (!this.renderFormSubmissionKeys.includes(key)) continue;

            ret.push(`${key} - ${formSubmission[key]}`);
        }


        return ret;
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            a {
                display: block;
                height: 100%;
            }

            ul {
                font-size: 85%;
                margin-top: 5px;
                margin-bottom: 5px;
                padding-left: 25px;
                list-style: disc;
                text-transform: capitalize;
            }
        `,
    ];


    override render() {
        return html`
            ${this.item.name ? html`
                <span>${this.item.name}</span>
            ` : html`
                <resolve-firestore-document-name .path="${this.item.productVariant.path}"
                                                 format="::name::"></resolve-firestore-document-name>
            `}
            ${this.additionalDetails?.length ? html`
                <ul id="additionalDetails">
                    ${this.additionalDetails.map(item => html`
                        <li>${item}</li>
                    `)}
                </ul>
            ` : undefined}
        `;
    }


}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-order-item-name': ComponentDataCollectionListItemCellOrderItemName;
    }
}