import {InstallPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './pages/page-install';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';
import './components/component-app-state';
import './components/component-app-state-component-restrictions';

export class InstallLocalPackage extends InstallPackage implements LocalPackage {

    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            if(localStorage['testMode']) return;

            e.registerPostComponent({
                component: 'component-app-state',
            });
            e.registerPostComponent({
                component: 'component-app-state-component-restrictions',
            });
        },
    };

}