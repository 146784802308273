import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';
import {INPUT_JSON_PACKAGE_ID} from '../../input-json/shared';
import {MENU_PACKAGE_ID} from '../../menu/shared';
import {CACHING_PACKAGE_ID} from '../../caching/shared';

export const FIRESTORE_EVENTS_PACKAGE_ID = 'firestore-events';

export class FirestoreEventsPackage extends Package {
    id: string = FIRESTORE_EVENTS_PACKAGE_ID;
    name: string = 'Firestore events';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, CACHING_PACKAGE_ID, INPUT_JSON_PACKAGE_ID, MENU_PACKAGE_ID];
}