export const injectData = (uri: string, routingPage: any, previousResolveData: any) => {
    if (uri.length > 1) {
        uri = uri.replace(/\/$/, '');
    }

    let routeFieldMatcher = routingPage.routeFieldMatcher;
    if (routeFieldMatcher) {
        let uriMatches = uri.match(new RegExp(routeFieldMatcher));
        if (uriMatches) {
            let routeFields = routingPage.resolvers?.routeFields || {};
            for (let i in routeFields) {
                previousResolveData[routeFields[i]] = uriMatches[parseInt(i, 10) + 1];
            }
        }
    }


    // if (routingPage.resolvers.metadata) {
    //     let metadataConfig = JSON.stringify(routingPage.resolvers.metadata);
    //     let metadata = JSON.parse(metadataConfig, (key, value) => {
    //         if (typeof value === 'string') {
    //             let valueStringInjected = this.stringValueInjector(value, previousResolveData, true);
    //             value = valueStringInjected.value;
    //         }
    //
    //         return value;
    //     });
    //     console.log('resolved metadata', metadata);
    //
    //     previousResolveData = merge.all([previousResolveData, metadata]);
    //     console.log('previousResolveData after metadata', previousResolveData);
    // }

    return previousResolveData;
};