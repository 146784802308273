import {customElement} from 'lit/decorators.js';
import {ComponentFirestoreCollectionListItemCell} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-data-collection-list-item-cell-order-actions')
class ComponentDataCollectionListItemCellOrderActions extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let itemId = this.item._ref.id;
        let orderHistoryUrl = `/account/shop/order-history/${itemId}`;
        let state = this.item.state;

        return html`
            ${['draft', 'pending'].includes(state) ? html`
                <a href="/shop/checkout/${this.item.cart.id}/your-details?orderId=${itemId}">
                    <component-button>
                        ${state === 'draft' ? 'Continue' : 'Pay'}
                    </component-button>
                </a>

                <a href="${orderHistoryUrl}?action=cancel">
                    <component-button>Cancel</component-button>
                </a>
            ` : html`
                <a href="${orderHistoryUrl}">
                    <component-button>View</component-button>
                </a>
            `}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-order-actions': ComponentDataCollectionListItemCellOrderActions;
    }
}