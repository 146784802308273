import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-aspire-comps-homepage-intro')
class ComponentAspireCompsHomepageIntro extends BunnyElement {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host{
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
            }

            component-button{
                background-color: var(--primary-text-color);
                color: white;
                border-radius: 15px;
                font-weight: bold;
                flex-direction: column;
                align-items: flex-start;
                font-size: 23px;
                min-width: 45%;
                margin: 0;
                flex: 1;
            }
            component-button span{
                color: var(--attention-color);
            }
            component-button .secondary{
                font-size: 80%;
                margin-top: 15px;
            }
            component-button small{
                font-weight: normal;
                font-size: 70%;
            }
        `
    ];

    override render(){
        return html`
            <component-button style="background: url(https://1.cdn.aspirecomps.co.uk/v0/b/aspire-comps-live-userfiles/o/AG1Kkj6mPuRRfWYE6ItG8XTXDUd2%2F1676362759340-1e121b62581e65ab6dbfe6b8bed1c9e720f84956.jpg%2Fdefault-preview_avif%402x.avif?alt=media&width=2048&height=1365) center; padding-top: 45%; background-size: cover; overflow:hidden; min-width: 100%">
                <div style="background: linear-gradient(0deg, rgba(32, 32, 32, .5), transparent); position: absolute; bottom: 0; left: 0; right: 0; padding: 15px; padding-top: 50px; text-shadow: 0 0 10px rgba(32, 32, 32, .3);">2019 PORSCHE MACAN TURBO SUV</div>
            </component-button>
            <component-button style="min-width: 100%;">
                <div>
                    <span>123</span> Competitions
                </div>
                <div class="secondary">
                    50H
                    <small>until sales end</small>
                </div>
            </component-button>
            <component-button style="min-width: 100%;">
                <div>
                    <span>Next</span> draw
                </div>
                <div class="secondary">
                    00:00:00 &middot; <small>Wednesday at 7:30PM</small>
                </div>
            </component-button>
            <component-button>
                <div>
                    <span>432</span> Winners
                </div>
                <div class="secondary">
                    12.5M
                    <small>in prizes</small>
                </div>
            </component-button>
            <component-button>
                <div>
                    <span>432</span> Charities
                </div>
                <div class="secondary">
                    123K
                    <small>given</small>
                </div>
            </component-button>
            <component-button>
                <div>
                    <span>10.5K</span> Reviews
                </div>
                <div class="secondary">
                    4.8 <small>on trust pilot</small>
                </div>
            </component-button>
            <component-button>
                <div>
                    <span>140K</span> Social
                </div>
                <div class="secondary">
                    110K <small>Fb</small> &middot;
                    11K <small>YouTube</small> &middot;
                    5K <small>Insta</small>
                </div>
            </component-button>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-homepage-intro': ComponentAspireCompsHomepageIntro;
    }
}