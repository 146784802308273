import {DevToolsPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';
import './components/component-dev-tools-emulator-log';
import './components/component-dev-tools-emulator-log-overlay';

export class DevToolsLocalPackage extends DevToolsPackage implements LocalPackage {

    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            if (localStorage.hideDevTools) {
                console.log('Dev tools hidden because of (localStorage.hideDevTools)');
                return;
            }
            if (localStorage['testMode']) return;

            e.registerPostComponent({
                component: 'component-dev-tools-emulator-log-overlay',
            }, undefined, true);
        },
    };
}