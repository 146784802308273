import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from './bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {delayPromise} from '../helpers/PromiseHelper';


@customElement('bunny-file-supplier')
class BunnyFileSupplier extends BunnyElement {

    @property({type: Boolean})
    multiple = false;

    @property({type: Object, notify: true})
    value: any | any[];

    @property({type: Boolean})
    renderInput = true;

    @property({type: String})
    accept = '';

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                height: 75px;
            }


            component-button {
                position: relative;
                display: block;
                height: 100%;
            }

            input {
                position: absolute;
                inset: 0;
                opacity: 0;
                zoom: 10;
                cursor: pointer;
            }
        `,
    ];

    override render() {
        return html`
            <component-button>
                <strong>Drag and Drop file to upload</strong> <br>
                <hr style="border: none; border-top: solid rgba(32, 32, 32, .1) 1px">
                <small>
                    Select a file from your device
                </small>

                ${this.renderInput ? html`
                    <input type="file"
                           @change="${this.receiveFile}"
                           ?multiple="${this.multiple}"
                           accept="${this.accept}">
                ` : undefined}
            </component-button>
        `;
    }

    async receiveFile(e: Event) {
        let files = (e.target as HTMLInputElement).files as FileList;
        for (let file of files) {
            if (this.multiple) {
                this.value ??= [];
                this.value.push(file);

            } else {
                this.value = file;
            }
        }

        if (this.multiple) {
            this.requestUpdate('value');
        }


        //fully redraw the input to allow the same file to be selected
        this.renderInput = false;
        await delayPromise();
        this.renderInput = true;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'bunny-file-supplier': BunnyFileSupplier;
    }
}