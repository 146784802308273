import {customElement} from 'lit/decorators.js';
import {
    ComponentNotificationsPreferenceChannelBase,
    CONFIGURATION,
} from '../component-notifications-preference-channel-base';
import {property} from '../../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {
    toastProgressWrapper,
} from '../../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FriendlyMessage} from '../../../../__internal/shared/helpers/ExceptionHelper';
import {observe} from '../../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {computed} from '../../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {
    ComponentFirebaseMessaging,
} from '../../../../firebase-messaging/local/components/component-firebase-messaging.ts';
import {ComponentInput} from '../../../../inputs/local/components/component-input.ts';
import {NOTIFICATION_CHANNELS_INFO} from '../../../shared/helpers/NotificationsHelper.ts';
import {showToast} from '../../../../__internal/local/helpers/ToastHelper.ts';

const AUTO_ENABLE_ATTEMPT_DURATION = 5 * 60 * 1000;

@customElement('component-notifications-preference-channel-push')
class ComponentNotificationsPreferenceChannelPush extends ComponentNotificationsPreferenceChannelBase {

    @property({type: Object})
    messaging = ComponentFirebaseMessaging.getInstance();

    @property({type: Object})
    types = NOTIFICATION_CHANNELS_INFO.push;

    @property({type: Boolean})
    loading: boolean = false;

    @property({type: String})
    errorMessage?: string;

    get currentUseragent() {
        let navigator = window.navigator;

        let userAgentData = (navigator as any).userAgentData;
        if (userAgentData) {
            try {
                return `${userAgentData.mobile ? 'Mobile' : 'Desktop'} ${userAgentData.brands.find((_: any) => !_.brand.toLowerCase().includes('chromium') && !_.brand.toLowerCase().includes('brand'))?.brand} - ${userAgentData.platform}`;

            } catch (e) {
                console.error('Failed fetching userAgentData', e);
            }
        }


        let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
                (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
            !(window as any).MSStream;
        if (isIOS) return 'Mobile - Apple';

        let isApple = navigator.platform === 'MacIntel' && !(window as any).MSStream;
        if (isApple) return 'Desktop - Apple';


        return navigator.userAgent;
    }

    get name() {
        return 'Push';
    }

    renderSetupHtml() {
        return html`
            <p>
                Device &middot; ${this.currentUseragent}
            </p>
            <p>
                <component-loading .loading="${this.loading}">
                    <component-button @click="${this.enable}"
                                      style="background-color: var(--primary-color); color: white;">
                        Enable notifications
                    </component-button>
                </component-loading>
            </p>

            <div class="contentContainer">
                ${this.errorMessage ? html`
                    <p style="color: red; font-weight: bold">${this.errorMessage}</p>
                ` : undefined}
                <p>
                    Please enable and allow notifications for this browser, if you are having problems dont hesitate to
                    <a href="/contact-us/#section-support">contact us here</a>
                </p>
            </div>
        `;
    }


    renderInfoHtml() {
        return html`
            <div class="contentContainer" style="font-size: 80%">
                Device &middot; ${this.item.meta?.useragent}
            </div>
        `;
    }

    renderFullInfoHtml() {
        return html`
            <p>
                Tailor the communications you receive to your smartphone via push notifications.
            </p>
        `;
    }

    renderFullManageHtml() {
        return html`
            <hr style="margin: 25px -15px">
            <p>
                <component-input label="Device name" .value="${this.item.meta?.useragent}" @focusout="${this.updateDeviceName}">
                </component-input>
                <small style="display: block; line-height: 1.1; margin-top: 5px">Rename to make it easy to organise your
                    push devices</small>
                <component-button style="display: block; margin-top: 5px">
                    Apply
                </component-button>
            </p>
        `;
    }

    @computed('item')
    get configuration(): CONFIGURATION {
        return this.item?.meta?.token ?
            CONFIGURATION.AVAILABLE :
            CONFIGURATION.AWAITING_SETUP;
    }

    connectedCallback() {
        super.connectedCallback();

        if (this.item.meta?.errorMessage) {
            this.errorMessage = this.item.meta.errorMessage;
        }
    }

    @toastProgressWrapper({
        progressMessage: 'Enabling notifications',
        successMessage: 'Notifications enabled',
        failedMessage: 'Failed enabling notifications: {{e}}',
    })
    async enable() {
        try {
            this.errorMessage = undefined;
            this.loading = true;
            let token = await this.messaging.getToken();

            this.item.meta ??= {};
            this.item.meta.token = token;
            this.item.meta.useragent = this.currentUseragent;
            delete this.item.meta.errorMessage;

            this.item.enabled = true;
            this.item.subscriptions = {};

            this.saveChannel();

        } catch (e: any) {
            if (e?.code === 'messaging/permission-blocked') {
                this.errorMessage = 'Cannot enable notifications! You\'ll have to unblock notifications from this site in your browser to continue';
                throw new FriendlyMessage(this.errorMessage);

            } else {
                this.errorMessage = e.message;
                throw e;
            }

        } finally {
            this.loading = false;
        }
    }

    @observe('configuration', 'opened')
    attemptAutoEnable(configuration: CONFIGURATION, opened: boolean) {
        if (!opened) return;
        if (configuration !== CONFIGURATION.AWAITING_SETUP) return;
        if (Date.now() - this.item.created > AUTO_ENABLE_ATTEMPT_DURATION) return; // don't do auto enabling if it's been more than 5 minutes

        this.enable();
    }
    
    updateDeviceName(e: FocusEvent) {
        this.item.meta ??= {};
        this.item.meta.useragent = (e.currentTarget as ComponentInput).value;

        this.saveChannel();
        showToast('Device name updated', {autoDismiss: 2000});
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-preference-channel-push': ComponentNotificationsPreferenceChannelPush;
    }
}
