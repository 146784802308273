import Package from '../../__internal/shared/Package';
import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';


export const EMBED_YOUTUBE_PACKAGE_ID = 'embed-youtube';

export class EmbedYoutubePackage extends Package {
    id: string = EMBED_YOUTUBE_PACKAGE_ID;
    name: string = 'Embed youtube';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];
}
