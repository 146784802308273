import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';

export const CACHING_PACKAGE_ID = 'caching';


export class CachingPackage extends Package {
    id: string = CACHING_PACKAGE_ID;
    name: string = 'Caching';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];

}