import {DateableDocument, RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {PageContentComponent} from '../../../routing/shared/helpers/FirestoreHelper';
import {MediaReferenceField} from '../../../media/shared/helpers/FirebaseHelper';

export const FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_CONTACT_DETAILS = 'shopContactDetails';
export const FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_SAVED_CARDS = 'shopSavedCards';
export const FIRESTORE_COLLECTION_SHOP_PRODUCTS = 'shopProducts';
export const FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS = 'variants';
export const FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS_SUB_STOCK_LOCK = 'stockLock';
export const FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS_SUB_STOCK_LOCK_STATE = 'state';
export const FIRESTORE_COLLECTION_SHOP_CARTS = 'shopCarts';
export const FIRESTORE_COLLECTION_SHOP_CARTS_SUB_DISCOUNTS = 'discounts';
export const FIRESTORE_COLLECTION_SHOP_ORDERS = 'shopOrders';
export const FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_TRANSACTIONS = 'transactions';
export const FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_NOTES = 'notes';


declare global {
    interface Collections {
        shopProducts: FirebaseFirestore.CollectionReference<ShopProductDocument>;
        shopCarts: FirebaseFirestore.CollectionReference<ShopCartDocument>;
        shopOrders: FirebaseFirestore.CollectionReference<ShopOrderDocument>;
    }
}

export interface ShopContactDetailDocument extends RefferableDocument, ShopOrderAddress, DateableDocument {

}

export interface ShopProductDocument extends RefferableDocument, DateableDocument {
    published?: Date;
    finished?: Date;
    type: string;
    owner: FirestoreDocumentReference,
    name: string;
    description?: PageContentComponent[];
    category?: { content: string }[] | null;
    crossSale?: { content: string, id?: string }[] | null;
    productImages?: MediaReferenceField[];
    campaign?: string;
    drawDate?: Date;
}

export interface ShopProductVariantSaleMatrixRule {
    rule: 'now' | 'publishedAgo' | 'finishedTill' | 'soldQuantity' | 'soldPercentage';
    condition: 'equalTo' | 'lessThan' | 'lessThanEqualTo' | 'moreThan' | 'moreThanEqualTo';
    value: number | Date;
}

export interface ShopProductVariantSaleMatrix {
    rules: ShopProductVariantSaleMatrixRule[];
    modifier: 'value' | 'reduceValue' | 'percentage' | 'reducePercentage';
    value: number;
    final?: boolean;
}

export interface ShopProductVariantDocument extends RefferableDocument, DateableDocument {
    type: string;
    owner: FirestoreDocumentReference;
    name: string;
    sku: string;
    price: number;
    maxPerPerson: number;
    maxStock: number;
    form?: FirestoreDocumentReference;
    saleMatrix: ShopProductVariantSaleMatrix[];
}

export interface ShopCartItem {
    created: any,
    updated: any,
    product: FirestoreDocumentReference;
    productVariant: FirestoreDocumentReference;
    quantity: number;
    formSubmission?: object;
    subTotal: number;
    price: number;
    productAvailableTill?: Date;
}

export interface ShopCartDocument extends RefferableDocument, DateableDocument {
    owner: FirestoreDocumentReference | null;
    itemTotals?: {
        count: number;
        quantity: number;
    };
    price?: {
        subTotal: number;
        discount: number;
        total: number;
    };
    discounts?: {
        discount?: FirestoreDocumentReference;
        name: string;
        value: number;
        handler: string;
        meta?: any;
    }[];
    messages?: string[];
    items?: {
        [key: string]: ShopCartItem
    };
}

export interface ShopCartDiscountDocument extends RefferableDocument, DateableDocument {
    meta: any;
    handler: string;
    name: string;
}

export interface ShopOrderAddress extends RefferableDocument {
    address1?: string;
    address2?: string;
    city?: string;
    company?: string;
    country?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    emailAddress?: string;
    province?: string;
    zip?: string;
    name?: string;
    provinceCode?: string;
    countryCode?: string;
    latitude?: number;
    longitude?: number;
}

export interface ShopOrderItem {
    created: any;
    updated: any;
    product: FirestoreDocumentReference;
    productVariant: FirestoreDocumentReference;
    productData: object;
    name: string;
    quantity: number;
    productPrice: number;
    productOriginalPrice: number;
    wasOnSale: boolean;
    productCategory?: string[];
    subTotal: number;
    discount?: number;
    state: 'pending' | 'completed' | 'refunded';
    type: string;
}

export interface ShopOrderDocument extends RefferableDocument, DateableDocument {
    canceled?: Date;
    paid?: Date;
    completed?: Date;
    refunded?: Date;
    cancelReason?: string;
    state: 'draft' | 'pending' | 'completed' | 'canceled' | 'refunded';
    fulfillmentStatus?: 'fulfilled' | 'unfulfilled' | 'partiallyFulfilled';
    owner: FirestoreDocumentReference;
    billingAddress: ShopOrderAddress;
    shippingAddress?: ShopOrderAddress;
    contact: {
        firstName: string;
        lastName: string;
        emailAddress: string;
        phone: string;
    },
    provider?: string;
    currency: string;
    discounts?: {
        discount?: FirestoreDocumentReference;
        name: string;
        value: number;
        handler: string;
        meta?: any;
    }[];
    requiresShipping: boolean;
    price: {
        subTotal: number;
        tax: number;
        taxPercentage: number;
        discount: number;
        total: number;
        shipping: number;
        paid: number;
        refunded: number;
    };
    itemsTotal: {
        total: number;
        quantity: number;
    };
    items?: {
        [key: string]: ShopOrderItem
    };
    meta: object;
    orderNumber: number;
    cart?: FirestoreDocumentReference;
    paymentHandler?: string;
}

export interface ShopOrderTransactionDocument extends RefferableDocument, DateableDocument {
    value: number;
    provider: string;
    providerTransactionId?: string;
    type: 'payment' | 'refund';
    state: 'pending' | 'canceled' | 'completed' | 'errored';
    meta?: any;
    canceled?: any;
}

export interface ShopOrderNoteDocument extends RefferableDocument, DateableDocument {
    transaction?: FirestoreDocumentReference;
    body: string;
    causer: FirestoreDocumentReference;
    type: 'private' | 'customer';
}