import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-input-address')
class ComponentInputAddress extends BunnyElement {

    @property({type: String})
    label: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            ${this.label ? html`
                <h2>${this.label}</h2>
            ` : undefined}

            <component-input label="Postcode"></component-input>
            <component-input label="Property name/number"></component-input>
            <component-input label="Street name"></component-input>
            <component-input label="Area"></component-input>
            <component-input label="Town"></component-input>
            <component-input label="County"></component-input>
            <component-input label="Country"></component-input>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-address': ComponentInputAddress;
    }
}