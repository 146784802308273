export const measurePerformance = 'performance' in window && performance.measure ? (measureName: string, measureOptions: PerformanceMeasureOptions) => {
    try {
        performance.measure(measureName, measureOptions);

    } catch (e) {
        console.error('Failed capturing performance', e);
    }
} : () => {

};

export const performanceNow = 'performance' in window && performance.now ? () => {
    return performance.now();
} : () => {
    return Date.now();
};

export const performanceTimeOrigin = 'performance' in window ? () => {
    return window.performance?.timeOrigin || window.performance?.timing?.navigationStart || 0;
} : () => {
    return 0;
};