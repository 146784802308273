import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper.ts';


@customElement('component-prize-claiming-messages-confirm-address-details-response')
class ComponentPrizeClaimingMessagesConfirmAddressDetailsResponse extends ComponentPrizeClaimingMessagesMessage {

    @property({type: Boolean})
    deliveryDialogOpen = false;

    override render() {
        return html`
            ${super.render()}

            <div class="footer">
                ${this.item.meta?.addressDetails?.firstName ? html`
                    ${this.item.meta.addressDetails.firstName} ${this.item.meta.addressDetails.lastName} <br>
                ` : undefined}
                ${this.item.meta?.addressDetails?.address1 ? html`
                    ${this.item.meta.addressDetails.address1} <br>
                ` : undefined}
                ${this.item.meta?.addressDetails?.address2 ? html`
                    ${this.item.meta.addressDetails.address2} <br>
                ` : undefined}
                ${this.item.meta?.addressDetails?.city ? html`
                    ${this.item.meta.addressDetails.city} <br>
                ` : undefined}
                ${this.item.meta?.addressDetails?.province ? html`
                    ${this.item.meta.addressDetails.province} <br>
                ` : undefined}
                ${this.item.meta?.addressDetails?.zip ? html`
                    ${this.item.meta.addressDetails.zip} <br>
                ` : undefined}
                ${this.item.meta?.addressDetails?.country ? html`
                    ${this.item.meta.addressDetails.country} <br>
                ` : undefined}
                ${this.item.meta?.addressDetails?.phone ? html`
                    ${this.item.meta.addressDetails.phone} <br>
                ` : undefined}

                ${this.isAdmin ? html`
                    <div style="margin-top: 15px">
                        <component-button-toggle style="background: var(--attention-color); display: block"
                                                 .active="${this.bind.deliveryDialogOpen}">
                            Deliver
                        </component-button-toggle>
                    </div>
                ` : undefined}
            </div>


            <component-dialog .opened="${this.bind.deliveryDialogOpen}" .closeOnClickBackdrop="${false}"
                              style="color: var(--primary-text-color)">
                <h3 slot="heading">
                    Complete delivery
                </h3>
                <div>
                    <component-native-form @submit="${this.sendCallback}"
                                           style="--loading-inset: -500px; padding-bottom: 0; padding-top: 0">

                        <component-input-textarea name="body" label="Message to customer">

                        </component-input-textarea>

                        <hr style="border-top: solid var(--primary-text-color) 1px">

                        <component-input name="deliveryVia" label="Delivery via"
                                         placeholder="Who is is being delivered by"></component-input>

                        <component-input-date name="deliveryBy"
                                              label="Expected delivery by"
                                              .min="${new Date()}"></component-input-date>

                        <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                            Submit
                        </button>

                        <div slot="submitButton">
                            <component-button raised
                                              style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                                Complete delivery
                            </component-button>
                        </div>
                    </component-native-form>
                </div>
            </component-dialog>
        `;
    }

    @bind()
    @confirmationDialog({
        title: 'Confirm delivery?',
        body: 'Are you sure you want to complete delivery to this address?',
    })
    async sendCallback(e: FormSubmitEvent) {
        if (e.detail.data.deliveryBy) {
            e.detail.data.deliveryBy = new Date(e.detail.data.deliveryBy);
        }

        this.dispatchEvent(new CustomEvent('sendMessageRequest', {
            detail: {
                newMessage: {
                    type: 'deliverySent',
                    body: `Your prize is being delivered via ${e.detail.data.deliveryVia} and should arrive ${e.detail.data.deliveryBy ? `by ${RenderingHelper._dateFormat(e.detail.data.deliveryBy, 'dd/MM/yyyy')}` : 'soon'}.${e.detail.data.body ? `\n---\n${e.detail.data.body}` : ''}`,
                    meta: {
                        delivery: {
                            via: e.detail.data.deliveryVia,
                            by: e.detail.data.deliveryBy,
                        },
                    },
                },
            },
        }));

        this.dispatchEvent(new CustomEvent('sendMessageRequest', {
            detail: {
                newMessage: {
                    type: 'winnerPicRequest',
                    body: `We would absolutely LOVE for you to upload a picture of you and your win when it arrives for the website. It’s fabulous seeing our happy winning badgers. As a thank you, we will add £10 website credit to your account as points.`,
                },
            },
        }));


        this.deliveryDialogOpen = false;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-confirm-address-details-response': ComponentPrizeClaimingMessagesConfirmAddressDetailsResponse;
    }
}