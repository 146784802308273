import {mergeDeep} from './helpers/ObjectHelper';
import {MenuDocument, MenuItemDocument} from './packages/menu/shared/helpers/FirestoreHelper';

let _localStorage = window.localStorage;
let _location = window.location;
type ENV = 'local' | 'dev' | 'staging' | 'live';
const HOST_ENVS: { [key: string]: ENV } = {
    '127.0.0.1': 'local',
    'localhost': 'local',
    'aspire-comps.web.app': 'dev',
    'dev.aspirecomps.co.uk': 'dev',
    'aspire-test-host': 'dev',
    'aspire-comps-staging.web.app': 'staging',
    'staging.aspirecomps.co.uk': 'staging',
    'www.aspirecomps.co.uk': 'live',
    'aspirecomps.co.uk': 'live',
};
const getEnv = (): ENV => {
    let locationSearch = _location.search;
    let forceEnvKey = 'force-env';
    let urlForceEnv = locationSearch.includes(forceEnvKey) && new RegExp(`${forceEnvKey}=([^&]+)`, 'g').exec(locationSearch);
    if (urlForceEnv) {
        _localStorage[forceEnvKey] = urlForceEnv[1];
    }

    if (_localStorage[forceEnvKey]) return _localStorage[forceEnvKey];


    let hostname = _location.hostname;
    if (hostname.startsWith('staging')) {
        hostname = 'staging.aspirecomps.co.uk'; //match anything ending with staging... as staging because of the hidden staging domains
    }

    return HOST_ENVS[hostname] || 'live';
};


export const ENV = getEnv();

export const version = '::VERSION::';
const configs: { [key: string]: any } = {
    'local': {
        google: {
            firebase: {
                apiKey: 'AIzaSyB1pPm9i15RWtYFlmqxJ18Ci3Sd4do3q1w',
                projectId: 'aspire-comps',
                messagingSenderId: '887742877630',
                appId: '1:887742877630:web:1b11e3af70d23fb19bb3ef',
                measurementId: 'G-XGC3YV9RVM',
                vapidKey: 'BIG33wUi92o92JPXCmApitpdkXQoP5s8uhwpiV67QtaCL3eqhge8jYO039LNjyqOqzOSFyyaFF2dBKM177qqDxw',
                databaseUrl: 'https://aspire-comps-28dab.europe-west1.firebasedatabase.app',
            },
        },
        commentPicker: {
            youtube: {
                oauth: {
                    clientId: '244662333133-986neongujm0rueo8sfivu5v0npr7rqk.apps.googleusercontent.com',
                },
            },
        },
    },
    'staging': {
        google: {
            firebase: {
                apiKey: 'AIzaSyDyfFrzDIU4U0HNEHgjTh6DxBhPJXtDB1k',
                projectId: 'aspire-comps-staging',
                messagingSenderId: '244662333133',
                appId: '1:244662333133:web:8d899fdd435c6a6f664384',
                measurementId: 'G-660JFH244V',
                vapidKey: 'BJqVexgV231-Y5gSyVSy6KNSEl9M1t-vWq5fEVNNoZibTUYeGcssytVuog0FTEtYWDCOfyxTGvDirb2vaTXFesM',
                databaseUrl: 'https://aspire-comps-staging-default-rtdb.europe-west1.firebasedatabase.app',
            },
        },
        commentPicker: {
            youtube: {
                oauth: {
                    clientId: '244662333133-986neongujm0rueo8sfivu5v0npr7rqk.apps.googleusercontent.com',
                },
            },
        },
    },
};
configs.dev = configs.local;

declare const __IMPORT_TEST_COMPONENTS: boolean;
export const IMPORT_TEST_COMPONENTS = __IMPORT_TEST_COMPONENTS;

export const config = mergeDeep({
    title: 'Aspire comps',
    google: {
        maps: {
            get key() {
                if (ENV === 'dev' || ENV === 'local') {
                    return 'AIzaSyBRR48p00nDOrXN9Gu0qUQtNmm7EwaT9fA';

                } else if (ENV === 'staging') {
                    return 'AIzaSyBRR48p00nDOrXN9Gu0qUQtNmm7EwaT9fA';

                } else {
                    return 'AIzaSyDjkIJkg4Ffe6kXAyWWdKGfo73jMEt8GfQ';
                }
            },
        },
        firebase: {
            authDomain: _location.hostname,
            databaseUrl: `https://aspire-comps-live.firebaseio.com`,
            functionUrl: '',
            apiKey: 'AIzaSyAVIR4sIRsce8aTmYDy7v6-wKiSUbk2DXw',
            projectId: 'aspire-comps-live',
            get storage() {
                return `${this.projectId}.appspot.com`;
            },
            get userStorage() {
                return `${this.projectId}-userfiles`;
            },
            messagingSenderId: '229070908301',
            appId: '1:229070908301:web:7418c3c0d62a9ad15a6bc4',
            measurementId: 'G-ZMHG1CN012',
            useEmulator: ENV === 'local',
            vapidKey: 'BAcoRA6sMDMr19AyVI4x5Dr2EoHB99YHx5viO2m5eMCFUzlZLgUsQINMNgM2OQF2ednowyIZcV_8ttfyAz6AWY0',
        },
    },
    site: {
        social: {
            facebook: 'https://www.facebook.com/aspirecompetitions',
            youtube: 'https://www.youtube.com/c/AspireCompetitions',
            trustpilot: 'https://uk.trustpilot.com/review/aspirecomps.co.uk',
            instagram: 'https://www.instagram.com/aspirecompsltd',
            twitter: 'https://twitter.com/aspirecomps1',
            tiktok: 'https://tikTok.com/@aspirecomps',
        },
        menus: {
            mainMenuId: '000000000aspirecomps',
            accountMenuId: '000000000accountmenu',
            adminMenuId: '000000000000000admin',
        },
        staticMenus: {
            '000000000aspirecomps': {
                menu: {
                    component: 'component-menus-group',
                    name: 'aspire-comps',
                },
                items: [{
                    parent: null,
                    component: 'component-menus-item',
                    name: 'aspire-comps-entry-list',
                    properties: {textContent: 'Entry list', href: '/entry-list'},
                    order: 1690795833145,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    'permissions': ['app.loggedin'],
                    name: 'aspire-comps-my-account',
                    properties: {icon: 'social:person', textContent: 'My account', href: '/account'},
                    order: 1690795833161,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'aspire-comps-how-to-enter',
                    properties: {textContent: 'How to enter', href: '/how-to-enter'},
                    order: 1690795833120,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'aspire-comps-winners',
                    properties: {textContent: 'Winners', href: '/previous-winners'},
                    order: 1690795833128,
                }, {
                    parent: null,
                    component: 'component-menus-item-shop-basket',
                    name: 'aspire-comps-basket',
                    properties: {icon: 'icons:shopping-basket', textContent: 'Basket', href: '/shop/cart'},
                    order: 1690795833180,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'aspire-comps-competitions',
                    properties: {textContent: 'Competitions', href: '/shop/products'},
                    order: 1690795833108,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'aspire-comps-charities',
                    properties: {textContent: 'Charities', href: '/charities-we-support'},
                    order: 1690795833136,
                }, {
                    parent: null,
                    component: 'component-menus-item-notifications',
                    name: 'aspire-comps-notifications',
                    properties: {
                        icon: 'social:notifications-none',
                        textContent: 'Notifications',
                        href: '/account/notifications',
                    },
                    order: 1690795833189,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'aspire-comps-about',
                    properties: {style: 'margin-right: auto', textContent: 'About', href: '/about'},
                    order: 1690795833152,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    'permissions': ['app.loggedout'],
                    name: 'aspire-comps-login',
                    properties: {textContent: 'Login', href: '/login'},
                    order: 1690795833169,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    'permissions': ['app.admin'],
                    name: 'aspire-comps-admin',
                    properties: {icon: 'icons:settings', textContent: 'Admin', href: '/admin'},
                    order: 1690795833197,
                }],
            },
            '000000000accountmenu': {
                menu: {
                    component: 'component-menus-group',
                    name: 'account-menu',
                },
                items: [{
                    parent: null,
                    component: 'component-menus-item',
                    name: 'notifications',
                    properties: {textContent: 'Notifications', href: '/account/notifications'},
                    order: 1689682639905,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'account-menu-logout',
                    properties: {textContent: 'Logout', href: '/logout'},
                    order: 1.7976931348623157e+308,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'shop-addresses',
                    properties: {textContent: 'Saved addresses', href: '/account/shop/addresses'},
                    order: 1692189258252,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'shop-order-history',
                    properties: {textContent: 'Order history', href: '/account/shop/order-history'},
                    order: 1692189258244,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'shop-cards',
                    properties: {textContent: 'Saved cards', href: '/account/shop/cards'},
                    order: 1692189258259,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'account-menu-delete-account',
                    properties: {textContent: 'Delete account', href: '/account/delete-account'},
                    order: 1689682638669,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'account-menu-my-account',
                    properties: {textContent: 'My account', href: '/account'},
                    order: 1689682638648,
                }, {
                    parent: null,
                    component: 'component-menus-item',
                    name: 'shop-points',
                    properties: {textContent: 'My points', href: '/account/shop/points'},
                    order: 1689682641465,
                }],
            },
        } as Record<string, { menu: MenuDocument, items: MenuItemDocument[] }>,
    },
    commentPicker: {
        oauthRedirectURI: `https://${window.location.host}/promotional-draws/process-claim`,
        youtube: {
            oauth: {
                clientId: '229070908301-0dkj70qtda6t0rkgakneqkqiurrnnefj.apps.googleusercontent.com',
            },
        },
    },
}, configs[ENV]);