import {showToast} from '../ToastHelper';
import {FriendlyMessage} from '../../../shared/helpers/ExceptionHelper';

export interface ToastProgressWrapperOptions {
    progressMessage?: string | false;
    successMessage?: string | false;
    failedMessage?: string | false;
}


export function toastProgressWrapper(options: ToastProgressWrapperOptions | null = null) {
    let progressMessage = options?.progressMessage ?? 'Saving';
    let successMessage = options?.successMessage ?? 'Saved successfully';
    let failedMessage = options?.failedMessage ?? 'Failed saving: {{e}}';


    return (_proto: any, _propName: string, descriptor: PropertyDescriptor) => {
        let oldFunction = descriptor.value;

        descriptor.value = async function (...args: any[]) {
            showToast();

            try {
                if (progressMessage) {
                    showToast(progressMessage, {autoDismiss: 0, dismissable: false});
                }

                if (await oldFunction.apply(this, args)) {
                    showToast();
                    return;
                }

                if (successMessage) {
                    showToast(successMessage, {autoDismiss: 2000});
                }

            } catch (e: any) {
                if (failedMessage) {
                    showToast(failedMessage.replace('{{e}}', e.message), {autoDismiss: 6000, dismissable: true});
                }

                if (!(e instanceof FriendlyMessage)) {
                    throw e;
                }
            }
        };
    };
}