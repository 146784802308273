import {customElement, query} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper';

let google: any;

@customElement('component-input-google-place')
class ComponentInputGooglePlace extends BunnyElement {

    @property({type: String, notify: true})
    apiKey: string;

    @property({type: Boolean, notify: true})
    apiLoaded = false;

    @property({type: Boolean})
    hideError = false;

    @property({type: Boolean})
    hideIcon = false;

    @property({type: Boolean, notify: true})
    disabled = false;

    @property({type: String})
    searchCountryCode = '';

    @property({type: String})
    searchType = '';

    @property({type: String, notify: true})
    errorMessage: string = 'Invalid - please select a place';

    @property({type: Boolean, notify: true})
    invalid = false;

    @property({type: Boolean})
    _invalid = false;

    @property({type: String, notify: true})
    label = '';

    @property({type: String, notify: true})
    placeholder = '';

    @property({type: Object, notify: true})
    latLng = {
        lat: 0,
        lng: 0,
    };

    @property({type: Object, notify: true})
    place:any = {};

    @property({type: Boolean, notify: true})
    required = false;

    @property({type: String, notify: true})
    language = '';

    @property({type: Boolean})
    minimizeApi = false;

    @property({type: Object, notify: true})
    value: any;

    @property({type: String, notify: true})
    _value = '';

    @property({type: String, notify: true})
    @computed('apiKey', 'minimizeApi', 'language')
    get _gmapApiUrl() {
        return this.apiKey ? ('https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=' +
            (this.minimizeApi ? 'places' : 'drawing,geometry,places,visualization') +
            '&key=' + this.apiKey + (this.language ? '&language=' + this.language : '')) : '';
    };

    private _geocoder: any;

    searchBounds: any;

    private _places: any;

    @query('component-input input')
    nativeInputElement: HTMLInputElement;

    static override styles = [
        // language=SCSS
        scss`
            :host {
                display: block;
            }
        `
    ];

    override render() {
        return html`
            <component-input .value="${this.bind._value}"
                             .label="${this.label}"
                             .placeholder="${this.placeholder}"
                             ?disabled="${this.disabled}"
                             @change="${this._onChange}"
            style="--placeholder-label-indent: 32px;">
                ${!this.hideIcon ? html`
                    <component-icon slot="prefix" icon="papinpplc:place"></component-icon>
                ` : undefined}
                <component-icon slot="suffix" icon="papinpplc:clear" @click="${this._clearLocation}"></component-icon>
            </component-input>

            ${this.errorMessage && this.invalid ? html`
                <p aria-live="assertive">${this.errorMessage}</p>
            ` : undefined}
        `;
    }

    @observe('_gmapApiUrl')
    async loadGoogleMapsApi(_gmapApiUrl: string) {
        await loadTrackingLibrary(_gmapApiUrl);

        google = (window as any).google;
        this._mapsApiLoaded();
    }

    _mapsApiLoaded() {
        if (!this._geocoder && !this._places && this.nativeInputElement) {
            this._geocoder = new google.maps.Geocoder();
            this._places = new google.maps.places.Autocomplete(this.nativeInputElement, {});
            google.maps.event.addListener(this._places, 'place_changed', this._onChangePlace.bind(this));
            this.apiLoaded = true;
            this._searchBiasChanged(this.searchCountryCode, this.searchBounds, this.searchType);
            this.dispatchEvent(new CustomEvent('api-loaded', {
                detail: {
                    text: 'Google api is ready',
                },
            }));
        }
    }

    @observe('searchCountryCode', 'searchBounds', 'searchType')
    _searchBiasChanged(searchCountryCode: string, searchBounds: any, searchType: string) {
        if (this.apiLoaded) {
            if (searchBounds &&
                searchBounds.hasOwnProperty('east') &&
                searchBounds.hasOwnProperty('west') &&
                searchBounds.hasOwnProperty('north') &&
                searchBounds.hasOwnProperty('south')
            ) {
                this._places.setBounds(searchBounds);
            } else {
                this._places.setBounds();
            }
            if (searchCountryCode && searchCountryCode.length == 2) {
                this._places.setComponentRestrictions({
                    country: searchCountryCode.toString(),
                });
            } else {
                this._places.setComponentRestrictions();
            }
            if (searchType && ['address', 'geocode', 'establishment', '(regions)', '(cities)'].includes(searchType)) {
                this._places.setTypes([searchType.toString()]);
            } else {
                this._places.setTypes([]);
            }
        }
    }

    @observe('value')
    _valueChanged(newValue: any, oldValue?: any) {
        // update the search term and the invalid flag if the value is being set for the first time,
        // or if the value has changed and is not the same as the search term
        if (!oldValue || (newValue.search !== oldValue.search || newValue.search !== this._value)) {
            this._value = newValue && newValue.search ? newValue.search : '';
            this._invalid = !newValue || !(newValue.place_id && newValue.latLng && newValue.latLng.lat && newValue.latLng
                .lng);
            if (!this.hideError) {
                this.invalid = this.required ? this._invalid : this._invalid && (newValue && newValue.search);
            }
        }
    }

    @observe('_value')
    _svalChanged(newValue: string, _oldValue?: string) {
        // reset the invalid property if the user has typed in the input field

        // if the newValue matches the selected place, which could happen if
        // the user types after selecting a place, then deletes the typing
        if (newValue && this.place && this.place.search && newValue == this.place.search) {
            this.value = {
                place_id: this.place.place_id,
                search: newValue,
                latLng: {
                    lat: this.place.latLng.lat,
                    lng: this.place.latLng.lng,
                },
            };
            this._invalid = false;
            this.invalid = false;
            return;
        }
        // if blank and not a required input
        if (!newValue && !this.required) {
            this.value = {
                place_id: '',
                search: '',
                latLng: {
                    lat: 0,
                    lng: 0,
                },
            };
            this.place = {};
            this._invalid = true;
            if (!this.hideError) {
                this.invalid = false;
            }
            return;
        }
        // if the new _value matches the value.search, which could happen if
        // the value is changed externally (possibly through data binding) which
        // causes _value to be changed triggering this function _svalChanged()
        if (newValue && this.value && this.value.search && newValue == this.value.search) {
            // nothing has really changed, just return
            return;
        }
        // if the existing value is blank, and the new value is not
        if ((!this.value || !this.value.search) && newValue) {
            this.value = {
                place_id: '',
                search: newValue,
                latLng: {
                    lat: 0,
                    lng: 0,
                },
            };
            this.place = {};
            this._invalid = true;
            if (!this.hideError) {
                this.invalid = true;
            }
            return;
        }
        // otherwise, the value is invalid
        this.value = {
            place_id: '',
            search: newValue,
            latLng: {
                lat: 0,
                lng: 0,
            },
        };
        this.place = {};
        this._invalid = true;
        if (!this.hideError) {
            this.invalid = true;
        }
        return;

    }

    _clearLocation(_e:CustomEvent) {
        this._value = '';
    }

    /**
     * Geocodes an address
     * @param  {string} address address to be geocoded
     * @param  {object} options Optional - Geocoder Request options
     * @return {Promise
            <place>} A promise for a place object or a status on failure
     */
    geocode(address: any, options: any) {
        return new Promise((resolve, reject) => {
            if (!this._geocoder) {
                reject('Geocoder not ready.');
            } else {
                let opts = options ? options : {};
                opts.address = address ? address : '';
                this._geocoder.geocode(opts, (results: any, status: any) => {
                    if (status == google.maps.GeocoderStatus.OK && results && results[0]) {
                        let p = this._extractPlaceInfo(results[0], opts.address);
                        resolve(p);
                    } else {
                        reject(status);
                    }
                });
            }
        });
    }

    /**
     * Reverse Geocodes a latLng
     * @param {object} latlng latitude/Longitude {lat: , lng: } to be reverse geocoded
     * @param {object} options Optional - Geocoder Request options
     * @return {Promise
                <place>} A promise for a place object or a status on failure
     */
    reverseGeocode(latlng: any, options: any) {
        return new Promise((resolve, reject) => {
            if (!this._geocoder) {
                reject('Geocoder not ready.');
            } else {
                let opts = options ? options : {};
                if (latlng) {
                    opts.location = latlng;
                }
                this._geocoder.geocode(opts, (results: any, status: any) => {
                    if (status == google.maps.GeocoderStatus.OK && results && results[0]) {
                        let p = this._extractPlaceInfo(results[0], '');
                        resolve(p);
                    } else {
                        reject(status);
                    }
                });
            }
        });
    }

    _onChangePlace(_e: CustomEvent) {
        let pl = this._places.getPlace();
        if (pl.geometry) {
            let p = this._extractPlaceInfo(pl, this.nativeInputElement.value);
            this.place = p;
            this._invalid = false;
            this.invalid = false;
            this.latLng = {
                lat: p.latLng.lat,
                lng: p.latLng.lng,
            };
            this._value = this.nativeInputElement.value;
            this.value = {
                search: this.nativeInputElement.value,
                place_id: p.place_id,
                latLng: {
                    lat: p.latLng.lat,
                    lng: p.latLng.lng,
                },
            };
        }
    }

    /**
     * extracts and simplifies a google place result
     * @param PlaceResult pl google place result
     * @return place
     */
    _extractPlaceInfo(pl: any, searchTerm: any) {
        let p = {
            place_id: pl.place_id,
            formatted_address: pl.formatted_address,
            search: searchTerm ? searchTerm : pl.formatted_address,
            latLng: {
                lat: pl.geometry.location.lat(),
                lng: pl.geometry.location.lng(),
            },
            basic: {
                name: pl.name || '',
                address: '',
                city: '',
                state: '',
                stateCode: '',
                postalCode: '',
                country: '',
                countryCode: '',
                phone: pl.formatted_phone_number || '',
                streetNumber: '',
                route: '',
            },
            placeDetails: {
                address_components: [] as any[],
                icon: pl.icon,
                international_phone_number: pl.international_phone_number || '',
                permanently_closed: pl.permanently_closed || false,
                types: pl.types ? JSON.parse(JSON.stringify(pl.types)) : [],
                website: pl.website || '',
                url: pl.url || '',
                utc_offset: pl.utc_offset,
            },
        };
        // extract address components
        let address = {
            street_number: '',
            route: '',
        };
        for (let i = 0; i < pl.address_components.length; i++) {
            let addressComponent = pl.address_components[i];
            p.placeDetails.address_components.push(JSON.parse(JSON.stringify(addressComponent)));
            switch (addressComponent.types[0]) {
                case 'locality':
                    p.basic['city'] = addressComponent.long_name;
                    break;
                case 'administrative_area_level_1':
                    p.basic['stateCode'] = addressComponent.short_name;
                    p.basic['state'] = addressComponent.long_name;
                    break;
                case 'country':
                    p.basic['country'] = addressComponent.long_name;
                    p.basic['countryCode'] = addressComponent.short_name;
                    break;
                case 'postal_code':
                    p.basic['postalCode'] = addressComponent.long_name;
                    break;
                case 'street_number':
                    address.street_number = addressComponent.short_name;
                    p.basic.address = address.street_number + ' ' + address.route;
                    p.basic.streetNumber = address.street_number;
                    break;
                case 'route':
                    address.route = addressComponent.long_name;
                    p.basic.address = address.street_number + ' ' + address.route;
                    p.basic.route = address.route;
                    break;
                default:
                    (address as any)[addressComponent.types[0] as any] = addressComponent.long_name;
            }
        }

        return p;

    }

    /**
     * Updates the current place, value and latLng with the place provided
     * @param IpipPlace newPlace the new place
     */
    putPlace(newPlace: any) {
        if (newPlace && newPlace.place_id && newPlace.latLng) {
            this.place = JSON.parse(JSON.stringify(newPlace));
            this.latLng = {
                lat: newPlace.latLng.lat,
                lng: newPlace.latLng.lng,
            };
            this.value = {
                place_id: newPlace.place_id,
                search: newPlace.search,
                latLng: {
                    lat: newPlace.latLng.lat,
                    lng: newPlace.latLng.lng,
                },
            };
            this._value = newPlace.search;
        }
    }

    /**
     * sets the focus to the input field
     */
    focus() {
        this.nativeInputElement.focus();
    }

    _onChange(event:CustomEvent) {
        if (this.shadowRoot) {
            this.dispatchEvent(new CustomEvent('input-change', {
                bubbles: true,
                cancelable: event.cancelable,
                detail: {
                    text: this.nativeInputElement.value
                }
            }));
        }
    };
}



declare global {
    interface HTMLElementTagNameMap {
        'component-input-google-place': ComponentInputGooglePlace;
    }
}