import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {FirestoreCollection} from '../../../__internal/local/controllers/FirestoreCollection';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {where} from 'firebase/firestore';
import {GLOBAL_GRAPPYFILL} from '../../../../aspire-app';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {
    FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS,
    PrizeClaimingThreadDocument,
} from '../../shared/helpers/FirestoreHelper';


@customElement('component-prize-claiming-sidebar')
class ComponentPrizeClaimingSidebar extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Array})
    @computed('auth')
    get prizeClaimingThreads() {
        return new FirestoreCollection<PrizeClaimingThreadDocument>(
            this,
            `${FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS}`,
            {
                method: FetchMethod.LIVE,
                queryExtendFunction() {
                    return [
                        where('owner', '==', GLOBAL_GRAPPYFILL.account?._ref as any),
                    ];
                },
            },
        );
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
            }

            component-prize-claiming-sidebar-item {
                margin: 10px;
            }
        `,
    ];

    override render() {
        return html`
            <h1 style="text-align: center; background: var(--primary-text-color); color: white">Prizes</h1>

            <component-loading .loading="${this.prizeClaimingThreads?.loading ?? true}">
                ${this.prizeClaimingThreads?.data?.map(item => html`
                    <component-prize-claiming-sidebar-item .item="${item}"></component-prize-claiming-sidebar-item>
                `)}
            </component-loading>

            <div style="height: 1px"></div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-sidebar': ComponentPrizeClaimingSidebar;
    }
}