import {customElement} from 'lit/decorators.js';
import {ComponentFirestoreCollectionListItemCell} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-data-collection-list-item-cell-order-total')
class ComponentDataCollectionListItemCellOrderTotal extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let totalItems = this.item.itemsTotal.total;

        return html`
            <component-shop-price value="${this.item.price.total}"></component-shop-price>
            for ${totalItems}
            item${totalItems === 1 ? '' : 's'}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-order-total': ComponentDataCollectionListItemCellOrderTotal;
    }
}