import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {AccountDocument, FIRESTORE_COLLECTION_ACCOUNTS} from '../../shared/helpers/FirebaseHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';

@customElement('component-admin-auth-account-details')
class ComponentAdminAuthAccountDetails extends BunnyElement {

    @property({type: String})
    accountId: string;

    @property({type: Object})
    @computed('accountId')
    get account() {
        if (!this.accountId) return undefined;

        return new FirestoreDocument<AccountDocument>(this, `${FIRESTORE_COLLECTION_ACCOUNTS}/${this.accountId}`, {method: FetchMethod.LIVE});
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <component-loading .loading="${this.account?.loading ?? true}">
                <component-input label="First name" .value="${this.account?.data?.firstName}"></component-input>
                <component-input label="Last name" .value="${this.account?.data?.lastName}"></component-input>
                <component-button @click="${this.updateAccount}">
                    Update
                </component-button>
            </component-loading>
        `;
    }

    @toastProgressWrapper()
    async updateAccount() {
        debugger;
        await adminCall.authUpdateAccount(this.accountId, {...this.account?.data});
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-auth-account-details': ComponentAdminAuthAccountDetails;
    }
}