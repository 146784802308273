import {customElement, query} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';


@customElement('component-prize-claiming')
class ComponentPrizeClaiming extends BunnyElement {

    prizeClaimingId: string = '';

    @property({type: Object})
    @query('#sideBarContainer')
    sidebarContainer: HTMLElement;

    @property({type: Object})
    @query('#sideBarContainer component-prize-claiming-sidebar')
    sidebar: HTMLElement;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
            }

            .gridContainer {
                min-height: 600px;
                min-height: 75vh;
            }

            component-prize-claiming-sidebar {
                background: rgb(249, 249, 249);
                box-shadow: rgba(32, 32, 32, 0.2) 0 0 7px;
                padding: 0 !important;
                position: fixed;
                top: calc(137px - (58px * var(--mini-nav-percentage)));
            }

            component-prize-claiming-messages, .noPrizeSelectedMessages {
                padding: 0 !important;
            }

            @media screen and (max-width: 576px) {
                component-prize-claiming-sidebar {
                    display: none;
                }
            }
        `,
    ];

    override render() {
        return html`
            <div class="gridContainer">
                ${this.prizeClaimingId ? html`
                    <component-prize-claiming-messages
                            .prizeClaimingId="${this.prizeClaimingId}"></component-prize-claiming-messages>
                ` : html`
                    <div class="noPrizeSelectedMessages"
                         style="--col-md: 8; --col-sm: 6; display: flex; align-items: center; justify-content: center; flex: 1;">
                        Select a prize to claim first
                    </div>
                `}
            </div>
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming': ComponentPrizeClaiming;
    }
}