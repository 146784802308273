import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {JSONParse} from '../../../__internal/shared/helpers/DataHelper';
import {requestIdleCallback} from '../../../__internal/local/helpers/TaskHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {loadTrackingLibrary} from "../helpers/TrackingLibraryLoaderHelper.ts";


let remoteConfigVersionChangeTracker: any;
let attachRemoteConfigVersionWatcher = async (callback: (version: number) => void) => {
    if (remoteConfigVersionChangeTracker) return;
    remoteConfigVersionChangeTracker = {};

    await delayPromise(2000);
    await new Promise(_ => requestIdleCallback(_, {timeout: 5000}));

    while (!FirestoreDocument.db) await delayPromise();


    await FirestoreDocument.hostlessRequest('global/version', {method: FetchMethod.LIVE}, (data) => {
        if (!data) return;

        let version = data.remoteConfig as number;
        callback(version);
    })
};


let config: any = null;
const configRecievers: ((config: any) => void)[] = [];
const unrecieveConfig = (callback: (config: any) => void) => {
    configRecievers.splice(configRecievers.indexOf(callback), 1);
};

const recieveConfig = (callback: (config: any) => void) => {
    configRecievers.push(callback);

    if (config) {
        callback(config);
    }
};

const loadConfig = (version: number) => {
    if (parseInt(config?.version) >= version) return;

    let loaderParent = document.head;
    let currentLoader = document.getElementById('remoteConfigLoader');
    if (currentLoader) {
        loaderParent.removeChild(currentLoader);
    }

    let loader = loadTrackingLibrary(`/_/config?callback=populateRemoteConfig&version=${version}`);
    (loader as any).script.id = 'remoteConfigLoader'
};

(window as any).populateRemoteConfig((_config: any) => {
    config = _config;

    for (let configReciever of configRecievers) {
        configReciever(config);
    }
});

@customElement('component-firebase-remote-config')
class ComponentFirebaseRemoteConfig extends BunnyElement {

    @property({type: Object, notify: true})
    config: any;

    @property({type: String})
    key: string;

    @property({type: Object, notify: true})
    value: any;


    static override styles = [
        // language=SCSS
        scss`
        `
    ];

    override render() {
        return html`
        `;
    }

    @bind()
    private configReciever(config: any) {
        this.config = config;
    }

    connectedCallback(): void {
        super.connectedCallback();

        attachRemoteConfigVersionWatcher(async (version) => {
            loadConfig(version);
        });
        recieveConfig(this.configReciever);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        unrecieveConfig(this.configReciever);
    }

    @observe('key', 'config')
    async fetch(key: string, config: any) {
        if (!config) return;
        let remoteConfigValue = config.parameters[key]; // TODO populate this from config

        if (remoteConfigValue) {
            this.value = JSONParse(remoteConfigValue.defaultValue.value);

        } else {
            this.value = null;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-firebase-remote-config': ComponentFirebaseRemoteConfig;
    }
}