import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../packages/__internal/local/components/bunny-element';
import {scss} from '../packages/__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {property} from '../packages/__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../packages/__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {FirestoreDocument} from '../packages/__internal/local/controllers/FirestoreDocument';

@customElement('resolve-firestore-document-name')
class ResolveFirestoreDocumentName extends BunnyElement {

    @property({type: String})
    name: string = 'Item';

    @property({type: String})
    format: string;

    @property({type: Object, notify: true})
    @computed('path', 'fetchMethod')
    get internalData() {
        if (!this.path) return undefined;

        return new FirestoreDocument(this, this.path, {method: FirestoreDocument.resolveFetchMethod(this.fetchMethod)});
    }

    @property({type: Boolean})
    @computed('internalData')
    get loading() {
        return this.internalData?.loading;
    }

    @property({type: Object, notify: true})
    @computed('internalData')
    get data() {
        return this.internalData?.data;
    }

    @property({type: String})
    path: string;

    @property({type: String})
    fetchMethod = 'fastest';


    static override styles = [
        // language=SCSS
        scss`
            :host {
                display: inline-block;
                --content-vertical-align: initial;
            }

            .content {
                vertical-align: var(--content-vertical-align);
            }
        `,
    ];

    override render() {
        return html`
            ${this.loading ? html`
                <component-loading style="width: 15px; height: 15px; " active class="thin"></component-loading> &nbsp;
                Loading ${this.name}...
            ` : html`
                ${this.data ? html`
                    <slot name="before"></slot>
                    <span class="content">
                        ${this.formatData(this.data, this.format)}
                    </span>
                    <slot name="after"></slot>
                ` : html`
                    [${this.name} deleted]
                `}
            `}
        `;
    }


    formatData(data: any, format: string | ((data: any) => any)) {
        if (typeof format === 'function') {
            return format(data);

        } else if (typeof format === 'string') {
            format = format.replace(/~~/g, '::');
        }


        if (format === 'currency') {
            //TODO rework this to allow any field
            return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(data.price / 100);
        }


        let match;
        while ((match = (format as string).match(/::([a-zA-Z_0-9]+)::/))) {
            format = (format as string).replace(match[0], data[match[1]]);
        }

        return format;
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'resolve-firestore-document-name': ResolveFirestoreDocumentName;
    }
}