import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {ComponentListView} from '../../../../components/component-list-view.ts';
import {adminCall} from '../../../admin/local/helpers/AdminHelper.ts';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper.ts';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper.ts';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';

let loadConfetti = async () => {
    let confettiPromise = loadTrackingLibrary('https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.2/dist/confetti.browser.min.js');
    loadConfetti = () => confettiPromise;
    await confettiPromise;
};

const fireConfetti = async (intensity: number, origin: { x: number, y: number }) => {
    await loadConfetti();

    let confetti = (window as any).confetti;

    let drift = ((origin.x - 0.5) * 8) + (2 - (Math.random() * 4));
    let count = intensity * 30;
    let defaults = {
        angle: 90 - (drift * 1.5),
        spread: 80,
        drift: drift,
        origin: origin,
        zIndex: 99999999999,
        startVelocity: 35,
    };

    const fire = function (particleRatio: number, opts: any) {
        confetti({
            ...defaults,
            ...opts,
            particleCount: Math.floor(count * particleRatio),
        });
    };

    fire(0.25, {
        spread: 26,
        startVelocity: defaults.startVelocity * 1.25,
    });
    fire(0.2, {
        spread: 60,
    });
    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: defaults.startVelocity * .5,
        decay: 0.92,
        scalar: 1.2,
    });
    fire(0.1, {
        spread: 120,
    });


    let canvas = document.body.querySelector('canvas') as any;
    if (!canvas.popovered) {
        canvas.popovered = true;
        canvas.popover = 'auto';
        canvas.showPopover();
        canvas.style.background = 'transparent';
    }
};

// language=SCSS
const TICKET_STYLES = scss`
    .ticketContainer {
        width: 182px;
        min-width: 182px !important;
        height: 158px;
        display: inline-block;
    }

    .ticketContainer[hidden] {
        display: none;
    }


    .ticket {
        background-color: #8dc033;
        box-shadow: #0a0a0a7a 2px 2px 5px 0;
        margin: 8px;
        border-radius: 5px;
        text-transform: capitalize;
        color: white;
        position: relative;
        text-align: center;
        font-size: 16px;
        line-height: 1.5;
    }

    .ticketNum {
        font-weight: bolder;
        font-size: 30px;
        background: url('/images/ticket-blank-white-01.png') no-repeat center center;
        image-rendering: optimizeSpeed;
        image-rendering: pixelated;
        image-rendering: crisp-edges;
        image-rendering: -webkit-crisp-edges;
        padding: 25px 10px;
        background-size: contain;
        width: 120px;
        margin: 0 auto;
        display: block;
    }

    .ticketOwner {
        margin: 0;
        height: 30px;
        line-height: 1;
    }

    .ticketOrder {
        opacity: .8;
        color: #111111;
        font-size: 12px;
    }

    [data-mine="1"] {
        background-color: #e56f04;
    }

    :host([display-winner]) .ticket[data-winner="1"] {
        background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #af842a 30%, #af8b3c 40%, rgba(255, 255, 255, 0) 80%),
        radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #a97f22 62.5%, #947631 100%);
    }

    :host([display-winner]) .ticket[data-winner="1"][data-mine="1"] {
        outline: solid #e56f04 5px;
        position: relative;
    }

    :host([display-winner]) .ticket[data-winner="1"][data-mine="1"]:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('/images/winner-background-yours.svg') no-repeat center center;
        background-size: cover;
        pointer-events: none;
    }

    .ticketContainer {
        &.rippleAnimation {
            animation: showWinnerRippleContainer .75s 1;
            animation-fill-mode: forwards;
            animation-delay: calc((var(--animationIndex) * .25s));
        }

        &.rippleAnimation.loadingRipple {
            animation: showWinnerRippleContainer .75s infinite;
            animation-delay: none;
        }

        &.rippleAnimation .ticket {
            overflow: hidden;
        }

        &.rippleAnimation .ticket:after {
            content: attr(data-ripple-text-content);
            position: absolute;
            top: 27px;
            left: -37px;
            transform: rotate(-45deg) scale(0);
            padding: 3px 25px;
            background: #c1c1c1;
            border-radius: 3px;
            line-height: 1;
            color: white;
            animation: showWinnerRipple 1.5s 1;
            animation-fill-mode: forwards;
            animation-delay: calc((var(--animationIndex) * .25s));
            white-space: pre;
            width: 100px;
            display: block;
            text-align: center;
            font-weight: bold;
            box-shadow: #0a0a0a7a 2px 2px 5px 0;
        }

        &.loadingRipple.rippleAnimation .ticket:after, &.primaryWinner.rippleAnimation .ticket:after {
            display: none;
        }

        &.primaryWinner .ticket, &.rippleWinner .ticket {
            background-image: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #af842a 30%, #af8b3c 40%, rgba(255, 255, 255, 0) 80%),
            radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #a97f22 62.5%, #947631 100%);
            background-repeat: no-repeat;
            background-position-y: -200px;

            animation: primaryWinnerBackground .125s 1;
            animation-fill-mode: forwards;
            animation-delay: calc((var(--animationIndex) * .25s));
        }

        &.rippleWinner .ticket {
            background-image: radial-gradient(ellipse farthest-corner at right bottom, #D3D3D3 0%, #BEBEBE 8%, #878787 30%, #878787 40%, rgba(255, 255, 255, 0) 80%),
            radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #F5F5F5 8%, #B3B3B3 25%, #818181 62.5%, #777777 100%);
            background-position-y: -200px;
        }
    }

    :host([disable-animation]) {
        .ticketContainer {
            &.rippleAnimation, &.primaryWinner .ticket, &.rippleWinner .ticket, &.rippleAnimation .ticket:after {
                animation-duration: 0s;
                animation-delay: 0s;
            }
        }
    }

    @keyframes showWinnerRippleContainer {
        0% {
            transform: translate(-1px, -1px);
        }
        10% {
            transform: translate(2px, 1px);
        }
        20% {
            transform: translate(1px, -2px);
        }
        30% {
            transform: translate(-1px, 3px);
        }
        40% {
            transform: translate(2px, -3px);
        }
        50% {
            transform: translate(1px, -2px);
        }
        60% {
            transform: translate(-3px, 4px);
        }
        70% {
            transform: translate(5px, -5px);
        }
        80% {
            transform: translate(1px, 1px);
        }
        95% {
            transform: translate(-1px, -1px);
        }
        100% {
            transform: translate(0);
        }
    }

    @keyframes showWinnerRipple {
        0% {
            transform: rotate(-45deg) scale(0);
        }
        50% {
            transform: rotate(-45deg) scale(.25);
        }
        55% {
            transform: rotate(-45deg) scale(.15);
        }
        60% {
            transform: rotate(-45deg) scale(.35);
        }
        65% {
            transform: rotate(-45deg) scale(.5);
        }
        70% {
            transform: rotate(-45deg) scale(.2);
        }
        75% {
            transform: rotate(-45deg) scale(.1);
        }
        80% {
            transform: rotate(-45deg) scale(.8);
        }
        90% {
            transform: rotate(-45deg) scale(.1);
        }
        97% {
            transform: rotate(-45deg) scale(1.25);
        }
        100% {
            transform: rotate(-45deg) scale(1);
        }
    }

    @keyframes primaryWinnerBackground {
        0% {
            background-position-y: -200px;
        }
        100% {
            background-position-y: 0;
        }
    }
`;

@customElement('component-aspire-comps-tickets-renderer')
class ComponentAspireCompsTicketsRenderer extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Array})
    items: any[] = [];

    @property({type: Number})
    limit: number = Number.MAX_SAFE_INTEGER;

    @property({type: Boolean, reflect: true})
    displayWinner: boolean;

    @property({type: Boolean})
    performanceMode: boolean = false;

    @property({type: String})
    competitionId: string;

    @property({type: Object})
    rippleWinners: Record<string, {
        textContent: string;
        class: string;
        style: string;
    }> | undefined = undefined;

    @property({type: Boolean})
    @computed('performanceMode', 'auth')
    get canRippleWinners() {
        if (!this.performanceMode) return false;
        if (!RenderingHelper._accountHasPermission(this.auth.user, 'app.admin.competition.applyRippleWinner')) return false;

        return true;
    }

    @property({type: Array})
    @computed('items', 'limit')
    get renderedItems() {
        if (this.limit === Number.MAX_SAFE_INTEGER) return this.items;

        return this.items.slice(0, this.limit);
    }

    @property({type: String})
    noItemsMessage: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .scrollContainer {
                overflow: auto;
                text-align: center;
                font-size: 0;
            }

            component-list-view {
                height: 75vh;
                max-height: 75vh;
                max-height: calc(85vh - 92px);
            }
        `,
        TICKET_STYLES,
    ];

    renderTicket(item: any, column?: number, row?: number) {
        let winningRipple = this.rippleWinners ? this.rippleWinners[item?.number] : undefined;

        return html`
            <div class="ticketContainer ${winningRipple?.class || ''}"
                 style="--x: ${column}; --y: ${row}; ${winningRipple?.style || ''}"
                 @click="${item && this.canRippleWinners ? () => this.doRippleWinner(item?.number) : undefined}"
                 @animationstart="${item && this.canRippleWinners ? this.doRippleConfetti : undefined}">
                <div class="ticket" data-winner="${item?.winner ? 1 : 0}"
                     data-ripple-text-content="${winningRipple?.textContent}"
                     data-mine="${this.isMine(item) ? 1 : 0}">
                    <p class="ticketNum">${item?.number || '?????'}</p>
                    <p class="ticketOwner">${item?.name}</p>
                    ${item?.orderId ? html`
                        <div class="ticketOrder">
                            Order #${item.orderId}
                        </div>
                    ` : undefined}
                </div>
            </div>
        `;
    }

    override render() {
        return html`
            ${!this.renderedItems?.length ? html`
                <p style="padding: 10px; text-align: center">${this.noItemsMessage}</p>
            ` : undefined}

            ${this.performanceMode ? html`
                <component-list-view
                        .items="${this.renderedItems}"
                        .injectedStyles="${TICKET_STYLES}"
                        .renderItem="${(item: any, _index: number, column: number, row: number) => html`
                            ${item?.number ? html`
                                ${this.renderTicket(item, column, row)}
                            ` : undefined}
                        `}"></component-list-view>
            ` : html`
                <div class="scrollContainer">
                    ${this.renderedItems?.map(item => html`
                        ${this.renderTicket(item)}
                    `)}
                </div>
            `}
        `;
    }

    isMine(item: any) {
        if (!this.auth) return false;

        return item.owner === this.auth.user?.uid;
    }

    @toastProgressWrapper({progressMessage: false, successMessage: false, failedMessage: `Failed rippling: {{e}}`})
    async doRippleWinner(ticketNumber?: number) {
        if (!ticketNumber) return;
        if (this.rippleWinners) return;//prevent double rippling on this render


        let rippleMinDelay = delayPromise(1000);
        let oldRippleWinners = this.rippleWinners;
        this.rippleWinners = {
            [ticketNumber]: {
                textContent: '',
                class: 'rippleAnimation loadingRipple',
                style: ``,
            },
        };


        try {
            let items = this.items;
            let tickerNumberIndex = items.findIndex(_ => _.number === ticketNumber);
            let lowBoundsTicketNumber = (items[tickerNumberIndex - 25] || items[0]).number;
            let highBoundsTicketNumber = (items[tickerNumberIndex + 25] || items[items.length - 1]).number;
            let response = await adminCall.competitionsApplyRippleWinner(
                this.competitionId,
                ticketNumber,
                [lowBoundsTicketNumber, highBoundsTicketNumber],
            );
            await rippleMinDelay;
            this.rippleWinners = response.rippleWinners;

        } catch (e) {
            await rippleMinDelay;

            //reset the ripple for multiple attempts
            this.rippleWinners = {};
            await delayPromise();
            this.rippleWinners = oldRippleWinners;

            throw e;
        }
    }

    @observe('rippleWinners')
    forceReRenderListViewOnRippleWinnersChange(rippleWinners: any) {
        if (!rippleWinners) return;

        let componentListView = this.shadowRoot?.querySelector<ComponentListView>('component-list-view');
        componentListView?.requestUpdate();
    }

    doRippleConfetti(e: AnimationEvent) {
        let ticketContainer = e.currentTarget as HTMLElement;

        if (ticketContainer.classList.contains('loadingRipple')) return;

        let animationDelay = parseFloat(getComputedStyle(ticketContainer).animationDelay) * 1000;
        let animationIndex = parseInt(ticketContainer.style.getPropertyValue('--animationIndex') || '0');
        let confettiIntensity = (5 - animationIndex) + 1;


        setTimeout(() => {
            let bounds = ticketContainer.getBoundingClientRect();
            fireConfetti(confettiIntensity, {
                x: (bounds.x + (bounds.width / 2)) / window.innerWidth,
                y: (bounds.y + bounds.height) / window.innerHeight,
            });
        }, animationDelay + (Math.random() * 250));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-tickets-renderer': ComponentAspireCompsTicketsRenderer;
    }
}