import {customElement} from 'lit/decorators.js';
import Packages from '../../../__internal/shared/Packages';
import Package from '../../../__internal/shared/Package';
import LocalPackage from '../../../__internal/local/LocalPackage';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {internalAdminCall, internalAdminCallStream} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

@customElement('page-install')
class PageInstall extends BunnyElement {
    @property({type: Array})
    packages: Packages<Package & LocalPackage>;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <div class="gridContainer">
                <h1>Admin installer</h1>

                <component-dev-tools-emulator-log></component-dev-tools-emulator-log>

                <div>
                    <h2>Functions</h2>
                    <component-button @click="${this.updateElasticsearchIndexes}">
                        Update elasticsearch indexes
                    </component-button>
                    <component-button @click="${this.refreshIndex}">
                        Refresh elasticsearch index
                    </component-button>
                    <component-button @click="${this.installFirestoreSecurityRules}">
                        Install firestore security rules
                    </component-button>
                    <component-button @click="${this.cacheInfo}">
                        List caches
                    </component-button>
                    <component-button @click="${this.cacheInfoBasic}">
                        Cache info
                    </component-button>
                    <component-button @click="${this.cacheInfoFull}">
                        Full cache info
                    </component-button>
                    <component-button @click="${this.cacheInvalidate}">
                        Invalidate cache
                    </component-button>
                </div>
                <hr>

                <div>
                    <h2 style="display: inline-block">Packages</h2>
                    <component-button @click="${(_: MouseEvent) => this.install()}">
                        Install
                    </component-button>
                </div>
                ${this.packages.map(item => html`
                    <div style="border-left: solid ${RenderingHelper._colorFromString(item.id)} 5px; background: #f8f8f8; display: flex; padding-right: 0">
                        <div style="flex: 1; padding-top: 15px; padding-bottom: 15px">
                            <h2 style="margin-bottom: 5px">
                                    [${item.id}]
                                ${item.name}
                            </h2>
                            <p style="margin: 0">
                                Dependencies &middot; ${item.dependencies}
                            </p>
                        </div>
                        <component-button @click="${(_: MouseEvent) => this.install(item.id)}" style="margin: 0">
                            Install
                        </component-button>
                    </div>
                `)}
            </div>
        `;
    }

    async install(installPackage?: string) {
        installPackage ??= '';

        await this.streamAdminCallLogs('InstallRun', {installPackage: installPackage});
    }

    @toastProgressWrapper({
        progressMessage: 'Running command: check console!!',
        failedMessage: 'Failed running command: {{e}}',
        successMessage: 'Ran command: check console!!',
    })
    private async streamAdminCallLogs(action: string, args: any) {
        await new Promise<void>(async (s, f) => {
            let source = await internalAdminCallStream(action, args);

            let receivedMessages = false;
            source.onmessage = (e) => {
                let prefix = e.data.split('\t')[0];
                if (prefix in console) {
                    (console as any)[prefix](e.data);

                } else {
                    console.error('unknown console prefix', prefix, e.data);
                }

                receivedMessages = true;
            };

            source.onerror = () => {
                source.close();

                if (receivedMessages) {
                    s();

                } else {
                    f();
                }
            };
        });
    }

    async updateElasticsearchIndexes() {
        await this.streamAdminCallLogs('ElasticsearchUpdateIndexes', {});
    }

    async refreshIndex() {
        let index = prompt('Index to refresh');

        if (!index) return;

        await internalAdminCall('ElasticsearchForceIndexRefresh', {
            index: index,
        });
    }

    @toastProgressWrapper()
    async installFirestoreSecurityRules() {
        await internalAdminCall('InstallFirestoreSecurityRules', {});
    }

    @toastProgressWrapper({
        progressMessage: 'Fetching cache info',
        successMessage: 'Cache info in console',
        failedMessage: 'Failed loading cache info: {{e}}',
    })
    async cacheInfo() {
        let response = await internalAdminCall('CachingCacheInfo', {});

        console.table(response.caches);
    }

    @toastProgressWrapper({
        progressMessage: 'Fetching cache info',
        successMessage: 'Cache info in console',
        failedMessage: 'Failed loading cache info: {{e}}',
    })
    async cacheInfoBasic() {
        let cacheId = prompt('cacheId');
        if (!cacheId) throw new FriendlyMessage('Cache id not supplied');

        let response = await internalAdminCall('CachingCacheInfo', {key: cacheId, listKeys: true});

        let cacheInfo = response.caches[0];
        console.log(`Id: ${cacheInfo.id}\nVersion: ${cacheInfo.version}`);
        console.log('Keys', cacheInfo.keys);
    }

    @toastProgressWrapper({
        progressMessage: 'Fetching cache info',
        successMessage: 'Cache info in console',
        failedMessage: 'Failed loading cache info: {{e}}',
    })
    async cacheInfoFull() {
        let cacheId = prompt('cacheId');
        if (!cacheId) throw new FriendlyMessage('Cache id not supplied');

        let response = await internalAdminCall('CachingCacheInfo', {key: cacheId, listKeys: true, listValues: true});

        let cacheInfo = response.caches[0];
        console.log(`Id: ${cacheInfo.id}\nVersion: ${cacheInfo.version}`);
        console.log('Keys', cacheInfo.keys);
    }

    @toastProgressWrapper({
        progressMessage: 'Clearing cache',
        successMessage: 'Cleared cache',
        failedMessage: 'failed clearing cache: {{e}}',
    })
    async cacheInvalidate() {
        let cacheId = prompt('cacheId, [empty for all]');

        let response = await internalAdminCall('CachingCacheInvalidate', {key: cacheId});
        console.table(response.caches.map((_: any) => ({id: _.id, ..._.cacheInvalidated})));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'page-install': PageInstall;
    }
}
