import {PropertyDeclaration as _PropertyDeclaration} from '@lit/reactive-element';
import {property as _property} from 'lit/decorators.js';
import {ClassElement} from 'typescript';

export type PropertyDeclaration = _PropertyDeclaration & { notify?: boolean };
export const property = (options?: PropertyDeclaration & { notify?: boolean }): ((protoOrDescriptor: (Object | ClassElement), name?: PropertyKey) => any) => {
    if (options?.reflect && !options.attribute) {
        return ((target: any, key: string) => {
            (options as any).attribute = key.replace(/([a-z])([A-Z])/g, (_, m1, m2) => `${m1}-${m2.toLowerCase()}`).toLowerCase();

            return _property(options)(target, key);
        }) as any;
    }

    return _property(options);
};
