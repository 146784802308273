import {ReactiveController, ReactiveControllerHost} from 'lit';
import {
    CollectionReference,
    connectFirestoreEmulator,
    DocumentData,
    DocumentReference,
    Firestore,
    initializeFirestore,
} from 'firebase/firestore';
import {BunnyController} from './BunnyController';
import {firebaseApp} from '../helpers/FirebaseHelper';
import {config} from '../../../../config';
import {measurePerformance, performanceNow, performanceTimeOrigin} from '../helpers/PerformanceHelper.ts';

export enum FetchMethod {
    NETWORK_ONLY,
    NETWORK_FIRST,
    CACHE_ONLY,
    CACHE_FIRST,
    FASTEST,
    FASTEST_THEN_CLEAN,
    LIVE,
    STATIC
}

export enum LOADING_STATE {
    ABORTED,
    LOADING,
    LOADED,
    STREAMING,
}

export const CACHE_DOCUMENT_DELAYS = new Set<Promise<void>>();

export const hostlessRequest = (Class: (host: ReactiveControllerHost, path: string, options?: {
    method?: FetchMethod,
    suppressLoadingError?: boolean,
    measurePerformance?: boolean,
}) => void, path: string, options?: {
    method?: FetchMethod,
    suppressLoadingError?: boolean,
    measurePerformance?: boolean,
}, streamUpdates?: (data: any) => void) => {
    let dataLoader = undefined as any;
    let ret = new Promise((s) => {
        let tempHost = {
            dataLoader: undefined as any,
            addController(controller: ReactiveController) {
                if (controller.hostConnected) {
                    controller.hostConnected();
                }
            },
            requestUpdate() {
                if (streamUpdates) {
                    streamUpdates(tempHost.dataLoader.data);
                }

                if (!tempHost.dataLoader.loading) {
                    s(tempHost.dataLoader.data);

                    if (!streamUpdates) {
                        tempHost.dataLoader.hostDisconnected();
                    }
                }
            },
            removeController(_controller: ReactiveController) {
            },
            updateComplete: Promise.resolve(true),
        };
        dataLoader = tempHost.dataLoader = (new (Class as any)(tempHost, path, options)) as any;
    });
    (ret as any).disconnect = () => {
        dataLoader.hostDisconnected();
    };

    return ret as (Promise<any> & {
        disconnect: () => void
    });
};


export abstract class FirestoreData<FirestoreReference extends (CollectionReference<T> | DocumentReference<T>) = any, T extends DocumentData = any> extends BunnyController {
    ref?: FirestoreReference;

    latency = -1;

    static resolveFetchMethod(fetchMethod?: string): FetchMethod | undefined {
        console.warn(`Returning resolved fetch method, replace this with enums for ${fetchMethod}`);
        if (fetchMethod === undefined) return fetchMethod;

        let ret = {
            'networkOnly': FetchMethod.NETWORK_ONLY,
            'networkFirst': FetchMethod.NETWORK_FIRST,
            'cacheOnly': FetchMethod.CACHE_ONLY,
            'cacheFirst': FetchMethod.CACHE_FIRST,
            'fastest': FetchMethod.FASTEST,
            'fastestThenClean': FetchMethod.FASTEST_THEN_CLEAN,
            'live': FetchMethod.LIVE,
        }[fetchMethod];

        if (!Number.isFinite(ret)) throw new Error(`Failed to look up resolved fetch method of ${fetchMethod}`);

        return ret;
    }

    static async delayCacheDocuments(waitFor: Promise<void>) {
        CACHE_DOCUMENT_DELAYS.add(waitFor);
        await waitFor;
        CACHE_DOCUMENT_DELAYS.delete(waitFor);
    }

    set path(value: string | undefined) {
        this.ref = value ? this.generateRef(value) : undefined;
    };

    get path() {
        return this.ref?.path;
    }

    loading: boolean = true;

    loadingError?: Error;

    method: FetchMethod;

    suppressLoadingError: boolean = false;

    measurePerformance: boolean = false;

    options?: {
        method?: FetchMethod,
        suppressLoadingError?: boolean,
        measurePerformance?: boolean,
    };

    protected abortController?: AbortController;

    static get db(): Firestore {
        let db = initializeFirestore(
            firebaseApp,
            {
                localCache: undefined,
                ignoreUndefinedProperties: true,
            },
        );

        if (config.google.firebase.useEmulator) {
            connectFirestoreEmulator(db, 'localhost', 8080);
        }


        Object.defineProperty(this, 'db', {value: db});
        return db;
    };

    constructor(host: ReactiveControllerHost, path: string, options?: {
        method?: FetchMethod,
        suppressLoadingError?: boolean,
        measurePerformance?: boolean,
    }) {
        super(host);


        if (!path || typeof path !== 'string' || path.includes('null') || path.includes('undefined') || path.includes('//')) throw new Error(`Invalid firestore path of ${path}`);


        this.path = path;
        this.method = options?.method ?? FetchMethod.CACHE_FIRST;
        this.suppressLoadingError = options?.suppressLoadingError ?? false;
        this.measurePerformance = options?.measurePerformance ?? false;
        this.options = options;
    }

    abstract generateRef(path: string): FirestoreReference;

    abstract receiveData(loadingState: LOADING_STATE, data: any): Promise<void>;

    abstract fetchData(): AsyncGenerator<[LOADING_STATE, any | undefined]>;

    private async load() {
        this.loading = true;
        this.loadingError = undefined;
        this.abortController = new AbortController();
        let hasMeasuredPerformance = false;

        try {
            let startTs = Date.now();
            this.latency = -1;

            for await (let [loadingState, data] of this.fetchData()) {
                await this.receiveData(loadingState, data);

                if (loadingState === LOADING_STATE.LOADED || loadingState === LOADING_STATE.STREAMING) {
                    this.latency = Date.now() - startTs;

                    if (this.measurePerformance && !hasMeasuredPerformance) {
                        hasMeasuredPerformance = true;
                        measurePerformance(`FD:${this.path}`, {
                            start: startTs - performanceTimeOrigin(),
                            end: performanceNow(),
                        });
                    }
                }

                console.debug('loadingState', loadingState, this.path);
                this.notifyUpdated();
            }

        } catch (e) {
            this.loadingError = e as any;
            this.notifyUpdated();

            if (!this.suppressLoadingError) throw e;

        } finally {
            this.loading = false;
        }
    }

    private stop() {
        this.abortController?.abort();
    }

    private queuedDisconnectTimeoutId?: number;

    hostConnected(host: ReactiveControllerHost) {
        super.hostConnected(host);


        if (this.queuedDisconnectTimeoutId) {
            clearTimeout(this.queuedDisconnectTimeoutId);
            this.queuedDisconnectTimeoutId = undefined;

            return; //if its reconnecting right after a disconnect its probably cus dom node moving so ignore the reload
        }


        queueMicrotask(() => {
            this.load();
        });
    }

    hostDisconnected(host: ReactiveControllerHost) {
        super.hostDisconnected(host);

        clearTimeout(this.queuedDisconnectTimeoutId);
        this.queuedDisconnectTimeoutId = window.setTimeout(() => {
            this.stop();
            this.queuedDisconnectTimeoutId = undefined;
        }, 0);
    }
}
