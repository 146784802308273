import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html, unsafeStatic} from 'lit/static-html.js';

const RULE_EDITOR_COMPONENTS: { [key: string]: string } = {
    now: 'component-input-datetime',
    publishedAgo: 'component-input-number',
    finishedTill: 'component-input-number',
    soldQuantity: 'component-input-number',
    soldPercentage: 'component-input-percentage',
};

@customElement('component-input-shop-sale-matrix-rule')
class ComponentInputShopSaleMatrixRule extends BunnyElement {

    @property({type: Object})
    value: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: flex;
            }

            :host .field + .field {
                margin-left: 5px;
            }
        `,
    ];

    override render() {
        let tag = this.value.rule ? unsafeStatic(RULE_EDITOR_COMPONENTS[this.value.rule]) : undefined;

        return html`
            <component-input-select label="Rule"
                                    class="field"
                                    .value="${this.bindDeep('value.rule')}"
                                    .options="${['now', 'publishedAgo', 'finishedTill', 'soldQuantity', 'soldPercentage']}">
            </component-input-select>

            <component-input-select label="Condition"
                                    class="field"
                                    .value="${this.bindDeep('value.condition')}"
                                    .options="${['equalTo', 'lessThan', 'lessThanEqualTo', 'moreThan', 'moreThanEqualTo']}">
            </component-input-select>

            ${tag ? html`
                <${tag} label="Value"
                        .value="${this.bindDeep('value.value')}"
                        class="field"></${tag}>
            ` : undefined}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-shop-sale-matrix-rule': ComponentInputShopSaleMatrixRule;
    }
}