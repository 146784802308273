import {customElement} from 'lit/decorators.js';
import {ComponentDataCollectionList} from '../../../__internal/local/components/component-data-collection-list';
import './component-elasticsearch-query';
import {ElasticsearchQueryRequest} from '../../shared/helpers/ElasticsearchHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {html} from 'lit';
import {REGISTERED_SCROLL_POSITIONS} from '../../../routing/local/pages/routing-page.ts';

@customElement('component-elasticsearch-collection-list')
export class ComponentElasticsearchCollectionList extends ComponentDataCollectionList {

    @property({notify: true})
    route = Route.getInstance(this);

    validQueryFiltersRoute: string;

    @property({type: Object, notify: true})
    queryFilters: Record<string, any>;

    @property({type: Object, notify: true})
    @computed('route')
    get _queryFilters() {
        if (!this.route.current?.query) return {} as Record<string, any>;

        return Object.entries(this.route.current.query)
            .map(_ => {
                let match = _[0].match(/([a-z0-9-_.]+)\[([a-z0-9-_.*]+)\]/i);
                if (!match) return null;

                return {value: _[1], group: match[1], key: match[2]};
            })
            .filter(_ => _ && _.group === 'filters')
            .reduce((accumulator, value: any) => {
                (accumulator[value.key] ??= []).push(value.value);
                return accumulator;
            }, {} as Record<string, any>);
    };

    @computed('response')
    @property({type: Array})
    get results(): any[] {
        return this.response.hits?.hits?.map((_: any) => {
            let ret = _._source;

            Object.defineProperty(ret, '_hit', {value: _});

            return ret;
        });
    }

    @property({type: String})
    searchId: string;

    @property({type: Object, notify: true})
    response: any;

    @property({type: Number, notify: true})
    @storageBoundQueryString('from', '0', _ => parseInt(_))
    from: number = 0;

    @property({type: Object})
    params: Record<string, any> = {};

    @property({type: String, notify: true})
    @computed('response')
    //@ts-ignore
    get error() {
        let rootCause = this.response.error?.root_cause;
        if (!rootCause) return null;
        if (!rootCause.length) return null;

        return rootCause[0]?.reason || null;
    }

    @observe('_queryFilters')
    loadQueryFilters(_queryFilters: any) {
        if (!this.validQueryFiltersRoute) {
            this.validQueryFiltersRoute = this.route.current.path;
        }
        if (this.validQueryFiltersRoute !== this.route.current.path) return;

        if (JSON.stringify(_queryFilters) === JSON.stringify(this.queryFilters)) return;

        this.queryFilters = _queryFilters;
    }

    @property({type: Object})
    @computed('searchId', 'filters', 'limit', 'from', 'params', 'queryFilters', 'controlFilters')
    get query(): ElasticsearchQueryRequest {
        if (!this.queryFilters) return undefined as any;

        let ret = {
            id: this.searchId,
            params: {
                size: this.limit,
                from: this.from,
                ...this.controlFilters,
                ...this.params,
            },
        };

        let searchFilter: any[] = ret.params.searchFilter || [];

        for (let filters of [this.filters, this.queryFilters]) {
            if (filters && Object.keys(filters).length) {
                for (let filterKey in filters) {
                    if (filters[filterKey] === '*' || (Array.isArray(filters[filterKey]) && filters[filterKey][0] === '*')) {
                        searchFilter.push({
                            exists: {field: filterKey},
                        });

                    } else {
                        if (!Array.isArray(filters[filterKey])) {
                            filters[filterKey] = [filters[filterKey]];
                        }

                        searchFilter.push({
                            terms: {[filterKey]: filters[filterKey]},
                        });
                    }
                }
            }
        }

        if (searchFilter.length) {
            ret.params.searchFilter = searchFilter;
        }

        return ret;
    }

    @property({type: Number, notify: true})
    @computed('response')
    get totalResults(): number {
        return this.response.hits?.total?.value || 0;
    }

    // language=HTML
    get dataProvider() {
        return html`
            <component-elasticsearch-query .loading="${this.bind.loadingItems}" .query="${this.query}"
                                           .response="${this.bind.response}"></component-elasticsearch-query>
        `;
    }

    @observe('results')
    populateItems(results: any[]) {
        results ??= [];

        this.items = results.map(_ => ({_data: _, id: _._hit._id}));
    }

    connectedCallback() {
        super.connectedCallback();

        REGISTERED_SCROLL_POSITIONS['collection-list'] = this;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        delete REGISTERED_SCROLL_POSITIONS['collection-list'];
    }

    @observe('searchId', 'filters', 'limit', 'queryFilters', 'controlFilters')
    resetFromOnQueryChanged() {
        if (this.ignoreFromReset || !this.response) return;

        this.from = 0;
    }

    @observe('from')
    scrollToTopOnPageChange() {
        if (!this.response) return; // if not set then its initial load rather than page change

        let position = this.content.getBoundingClientRect();
        window.scrollTo(0, window.scrollY + position.y - 75);
    }

    private ignoreFromReset: boolean;

    @observe('route')
    populateOptionsFromUrl(route: Route) {
        if (parseInt(route?.current?.query?.from || '0', 10)) {
            this.ignoreFromReset = true;
            this.from = parseInt(route.current?.query.from, 10);
            this.ignoreFromReset = false;
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-elasticsearch-collection-list': ComponentElasticsearchCollectionList;
    }
}