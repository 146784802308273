import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

@customElement('component-analytics-data-visualizer-info')
class ComponentAnalyticsDataVisualizerInfo extends BunnyElement {

    @property({type: Object})
    data: any;

    @property({type: Array})
    panels: { title: string, format: 'number' | 'currency', value: number }[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            h3 {
                padding: 15px;
                background-color: var(--primary-text-color);
                color: white;
                margin-left: -15px;
                margin-right: -15px;
                margin-top: -25px !important;
            }
        `
    ];

    override render(){
        return html`
            <div class="gridContainer row">
                ${this.getPanels(this.panels).map(item=>html`
                    <div style="--col: 4">
                        <div class="contentStep">
                            <h3>${item.title}</h3>
                            ${item.format === 'number' ? html`
                                ${item.value}
                            ` : undefined}
                            ${item.format === 'currency' ? html`
                                &pound;${RenderingHelper._currencyFormat(item.value)}
                            ` : undefined}
                        </div>
                    </div>
                `)}
            </div>
        `;
    }

    getPanels(panels: { title: string, format: 'number' | 'currency', value: number }[]) {
        return panels.map(_ => ({..._}));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-data-visualizer-info': ComponentAnalyticsDataVisualizerInfo;
    }
}