import {customElement} from 'lit/decorators.js';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';


@customElement('component-reauthentication-password-dialog')
export class ComponentReauthenticationPasswordDialog extends BunnyElement {

    @property({type: Object})
    response: Promise<string>;

    @property({type: Object})
    success: (value: string) => void;

    @property({type: Object})
    fail: (e: Error) => void;

    static async requestPassword() {
        let dialogNode = document.createElement('component-reauthentication-password-dialog') as ComponentReauthenticationPasswordDialog;

        let parent = document.body;
        parent.append(dialogNode);
        try {
            return await dialogNode.response;

        } finally {
            parent.removeChild(dialogNode);
        }
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];


    override render() {
        return html`
            <component-dialog opened
                              style="--footer-display: none"
                              @opened-changed="${(e: CustomEvent) => {
                                  if (!e.detail.value) {
                                      this.doFail();
                                  }
                              }}">
                <h3 slot="heading">
                    Please re-login
                </h3>

                <h3>Its been a while since you have logged in</h3>
                <p>
                    For security we require you to log in again! <br>
                    To continue with this request, please enter you current password.
                </p>

                <component-native-form @submit="${this.sendCallback}">
                    <component-input-password label="Current password"
                                              name="password"></component-input-password>

                    <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                        Submit
                    </button>

                    <component-button style="display: block;">
                        Continue
                    </component-button>
                </component-native-form>
            </component-dialog>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.response = new Promise<string>((s, f) => {
            this.success = s;
            this.fail = f;
        }) as any;
    }


    @bind()
    async sendCallback(e: FormSubmitEvent) {
        await e.detail.setResponse(async () => {
            let formData = e.detail.data;

            if (formData.password) {
                this.success(formData.password);

            } else {
                this.doFail();
            }

            return 'Reauthentication attempted';
        });
    }

    doFail() {
        this.fail(new FriendlyMessage('Request reauthentication failed'));
    }
}


if (localStorage['testMode']) {
    (window as any).__ComponentReauthenticationPasswordDialog = ComponentReauthenticationPasswordDialog;
}

declare global {
    interface HTMLElementTagNameMap {
        'component-reauthentication-password-dialog': ComponentReauthenticationPasswordDialog;
    }
}