import {BunnyElement} from '../../../../__internal/local/components/bunny-element';
import {IMPORT_TEST_COMPONENTS} from '../../../../../config';
import {Auth} from '../../controllers/Auth';
import {property} from '../../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {FacebookAuthProvider, GoogleAuthProvider, OAuthProvider} from 'firebase/auth';

if (IMPORT_TEST_COMPONENTS) {
    class ComponentTesterAuth extends BunnyElement {
        @property()
        auth = Auth.getInstance();

        testGetSigninProvider() {
            const assert = (providerName: string, _class: any) => {
                let provider = this.auth.getSigninProvider(providerName);
                if (!(provider instanceof _class)) {
                    throw new Error(`getSigninProvider incorrect provider for name: ${providerName}`);
                }
            };

            assert('facebook', FacebookAuthProvider);
            assert('facebook.com', FacebookAuthProvider);
            assert('google', GoogleAuthProvider);
            assert('google.com', GoogleAuthProvider);
            assert('apple', OAuthProvider);
        }
    }

    customElements.define('component-tester-auth', ComponentTesterAuth);
}
