import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';

export const ADMIN_PACKAGE_ID = 'admin';

export class AdminPackage extends Package {
    id: string = ADMIN_PACKAGE_ID;
    name: string = 'Admin';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];
}
