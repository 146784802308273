import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {scss} from '../helpers/StyleHelper';
import {BunnyElement} from './bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';


@customElement('component-loading')
class ComponentLoading extends BunnyElement {

    @property({type: Boolean})
    loading = true;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                --loading-spinner-size: 25px;
                --loading-margin: 50px 0;
            }

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(3600deg);
                }
            }

            .loadingContainer {
                text-align: center;
                margin: var(--loading-margin);
                line-height: 1;

                .loadingSpinner {
                    display: inline-block;
                    width: var(--loading-spinner-size);
                    height: var(--loading-spinner-size);
                    text-align: center;
                    animation: spin 5s infinite linear;
                    border-radius: 100%;
                    background: rgba(200, 200, 200, .7);
                    box-shadow: 0 0 10px rgba(200, 200, 200, .7);
                }
            }

        `,
    ];

    override render() {
        return html`
            ${this.loading ? html`
                <div class="loadingContainer">
                    <component-icon icon="app:loading" class="loadingSpinner"></component-icon>
                </div>
            ` : html`
                <slot></slot>
            `}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-loading': ComponentLoading;
    }
}