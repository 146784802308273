import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element.ts';
import {sharedStyles} from '../../../../shared-styles.ts';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';


@customElement('component-routing-loading')
class ComponentRoutingLoading extends BunnyElement {

    @property({type: String})
    message: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-loading {
                position: fixed;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);

                &:after {
                    content: attr(data-message);
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 100%;
                    max-width: 200px;
                    text-align: center;
                    opacity: .6;
                }
            }
        `,
    ];

    override render() {
        return html`
            <component-loading data-message="${this.message}"></component-loading>
        `;
    }

    async connectedCallback() {
        super.connectedCallback();

        await delayPromise(2000);
        this.message = 'Still loading, the badgers might still be warming up';
        await delayPromise(2000);
        this.message = 'Taking longer than normal, huh maybe the badgers fell asleep';
        await delayPromise(6000);
        this.message = 'This is weird, plugging the badgers in to charge... support@aspirecomps.co.uk if help is required';
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-routing-loading': ComponentRoutingLoading;
    }
}