import {customElement} from 'lit/decorators.js';
import {html} from 'lit/static-html.js';
import {ComponentInputNumber} from './component-input-number';
import {ComponentInput} from './component-input';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-input-currency')
export class ComponentInputCurrency extends ComponentInputNumber {

    // language=SCSS
    static override styles = [
        ...ComponentInput.styles,
        scss`
            :host {
                --placeholder-label-indent: 52px;
            }
        `];

    @property({type: Number})
    decimal: number = 2;

    @property({type: Number})
    step: number = 1;

    @property()
    currency: string = 'GBP';

    protected onInput() {
        this.value = Math.round(this.nativeInput.valueAsNumber * (10 ** this.decimal));
    }

    @observe('decimal')
    updateStep() {
        this.step = 1 / (10 ** this.decimal);
    }

    convertValueToNative(value: any): any {
        return value / (10 ** this.decimal);
    }

    renderInput(): any {
        return html`
            <small style="font-size: 80%; display: inline-block; background-color: var(--primary-text-color); color: white; line-height: 43px; padding: 0 10px">
                ${this.currency}
            </small>
            ${super.renderInput()}
        `;
    }


    // nativeInput.valueAsNumber = value / (10 ** this.decimal);
}

declare global {
    interface HTMLElementTagNameMap {
        'component-input-currency': ComponentInputCurrency;
    }
}
