import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';

const DELETE_ACCOUNT_CONFIRMATION_MESSAGE = 'Are you sure you want to permanently delete this account';

@customElement('component-admin-auth-account-delete')
class ComponentAdminAuthAccountDelete extends BunnyElement {

    @property({type: String})
    accountId: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <component-button @click="${this.deleteAccount}" style="background-color: var(--attention-color);">
                Delete account
            </component-button>
        `;
    }

    @toastProgressWrapper()
    @confirmationDialog({body: DELETE_ACCOUNT_CONFIRMATION_MESSAGE, successButtonText: 'Yes', failButtonText: 'No'})
    @confirmationDialog({
        body: `Really?? ${DELETE_ACCOUNT_CONFIRMATION_MESSAGE}`,
        successButtonText: 'Totally',
        failButtonText: 'Not really',
    })
    @confirmationDialog({
        body: `Really!! Really????? Last chance!!! ${DELETE_ACCOUNT_CONFIRMATION_MESSAGE}`,
        successButtonText: 'Yep 100%',
        failButtonText: 'Nope, aaa im scared',
    })
    async deleteAccount() {
        let currentHref = location.href;

        await adminCall.authDeleteAccount(this.accountId);

        if (location.href === currentHref) { // Don't redirect if user has already left the page
            HistoryHelper.replaceState('/admin/auth/accounts');
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-auth-account-delete': ComponentAdminAuthAccountDelete;
    }
}