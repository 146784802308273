import Package, {EventCallbackFunction} from './Package';

class EventEmitter {
    callbacks: any = {};

    on(event: string, cb: any) {
        if (!this.callbacks[event]) this.callbacks[event] = [];
        this.callbacks[event].push(cb);
    }

    emit(event: string, ...data: any[]) {
        let cbs = this.callbacks[event];
        if (cbs) {
            cbs.forEach((cb: any) => cb(...data));
        }
    }
}

export default class Packages<T extends Package> extends Array<T> {
    events = new EventEmitter();

    validateDependencies(_package: T) {
        for (let dependency of _package.dependencies) {
            if (!this.exists(dependency)) {
                throw new Error(`Package [${_package.id}] ${_package.name} is missing the dependency of ${dependency}`);
            }
        }
    }

    exists(packageId: string): boolean {
        return !!(this as any)[packageId];
    }

    add(_package: T): void {
        if (this.exists(_package.id)) {
            throw new Error(`Package [${_package.id}] ${_package.name} already exists`);
        }


        this.push(_package);
        (this as any)[_package.id] = _package;


        this.attachListeners(_package);
    }

    private attachListeners(_package: T) {
        let listeners = _package.listen;
        if (!listeners) return;


        for (let i in listeners) {
            this.events.on(i, listeners[i] as EventCallbackFunction<any>);
        }
    }

    trigger(name: string, ...args: any[]) {
        this.events.emit(name, ...args);
    }

    enforceDependencies(): void {
        for (let _package of this) {
            this.validateDependencies(_package);
        }
    }

    init(): void {
        this.enforceDependencies();

        for (let _package of this) {
            if ((_package as any)?.init) {
                (_package as any).init(this);
            }
        }
    }
}
