import Package from '../../__internal/shared/Package';
import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import {ANALYTICS_PACKAGE_ID} from '../../analytics/shared';
import {generateRegisteredFunctionId} from '../../__internal/shared/helpers/RegisteredFunctionsHelper';


export const SHOP_PACKAGE_ID = 'shop';
export const SHOP_STALE_DRAFT_ORDER_ROLLBACK = 5;
export const SHOP_STALE_DRAFT_ORDER_ROLLBACK_BUFFER = 5;

export let REGISTERED_FUNCTION_ID_shopProductFormAfterSave: { registeredFunctionId: string };

export class ShopPackage extends Package {
    id: string = SHOP_PACKAGE_ID;
    name: string = 'Shop';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, ANALYTICS_PACKAGE_ID];

    constructor() {
        super();

        REGISTERED_FUNCTION_ID_shopProductFormAfterSave = generateRegisteredFunctionId(this, 'shopProductFormAfterSave');
    }


}