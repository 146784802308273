import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {userCall} from '../../../auth/local/helpers/UserCallHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';


@customElement('component-prize-claiming-messages-winner-pic-request')
class ComponentPrizeClaimingMessagesWinnerPicRequest extends ComponentPrizeClaimingMessagesMessage {

    @property({type: Object})
    fileUpload: any;

    @property({type: Boolean})
    isUploading = false;

    override render() {
        return html`
            ${super.render()}

            <div class="footer">
                <div style="width: 200px; height: 200px; background: rgb(128, 128, 128);  border-radius: 10px; color: white; position:relative;">
                    <component-loading .loading="${this.isUploading}"
                                       style="position: absolute; display: flex; inset: 0; align-items: center; justify-content: center;">
                        Winner pic upload
                    </component-loading>

                    <bunny-file-supplier .accept="${'image/*'}"
                                         .value="${this.bind.fileUpload}"
                                         style="opacity: 0; position:absolute; inset: 0; height: auto">
                    </bunny-file-supplier>
                </div>
            </div>
        `;
    }

    @observe('fileUpload')
    @toastProgressWrapper({
        progressMessage: 'Uploading winner pic',
        failedMessage: 'Failed uploading winner pic: {{e}}',
        successMessage: 'Uploaded winner pic',
    })
    async processUpload(fileUpload: any) {
        try {
            this.isUploading = true;

            await userCall(
                'PrizeClaimingSubmitWinnerPic',
                {
                    threadId: this.prizeClaimingThread._ref?.id,
                    winnerPic: fileUpload,
                },
                'multipart/form-data',
            );

            this.dispatchEvent(new CustomEvent('scrollMessagesRequest'));

        } finally {
            this.isUploading = false;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-winner-pic-request': ComponentPrizeClaimingMessagesWinnerPicRequest;
    }
}