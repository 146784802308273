import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';
import './helpers/FirestoreHelper';

export const INSTALL_PACKAGE_ID = 'install';

export class InstallPackage extends Package {
    id: string = INSTALL_PACKAGE_ID;
    name: string = 'Install';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];
}