import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {ShopContactDetailDocument} from '../../../shop/shared/helpers/FirebaseHelper';

@customElement('component-admin-auth-shop-contact-details-item')
class ComponentAdminAuthShopContactDetails extends BunnyElement {

    @property({type: String})
    accountId: string;

    @property({type: Array})
    item: { _id: string, data: ShopContactDetailDocument };

    @property({type: Boolean})
    applyToRecentOrders = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .gridContainer {
                --grid-columns: 5;
            }

            .gridContainer > * {
                --col: 1;
                padding-left: 5px;
                padding-right: 5px;
                margin-top: auto;
            }
        `,
    ];

    override render() {
        return html`
            <div class="gridContainer">
                <component-input label="First name" .value="${this.bindDeep('item.data.firstName')}"></component-input>
                <component-input label="Last name" .value="${this.bindDeep('item.data.lastName')}"></component-input>
                <component-input-email label="Email address"
                                       .value="${this.bindDeep('item.data.emailAddress')}"></component-input-email>
                <component-input label="Phone" .value="${this.bindDeep('item.data.phone')}"></component-input>
                <div></div>

                <component-input label="Address1" .value="${this.bindDeep('item.data.address1')}"></component-input>
                <component-input label="Address2" .value="${this.bindDeep('item.data.address2')}"></component-input>
                <component-input label="City" .value="${this.bindDeep('item.data.city')}"></component-input>
                <component-input label="Province" .value="${this.bindDeep('item.data.province')}"></component-input>
                <component-input label="Zip" .value="${this.bindDeep('item.data.zip')}"></component-input>
                <component-input-checkbox .checked="${this.bind.applyToRecentOrders}">
                    Apply to Recent Orders
                </component-input-checkbox>
                <component-button @click="${this.updateContactDetail}">
                    Update
                </component-button>
                <div style="--col: 3"></div>
            </div>
        `;
    }

    @toastProgressWrapper()
    async updateContactDetail() {
        await adminCall.shopUpdateAccountContactDetail(
            this.accountId,
            this.item._id,
            this.item.data,
            this.applyToRecentOrders,
        );
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-auth-shop-contact-details-item': ComponentAdminAuthShopContactDetails;
    }
}