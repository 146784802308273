import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {FIRESTORE_COLLECTION_SHOP_PRODUCTS} from '../../shared/helpers/FirebaseHelper';
import {MediaReferenceField} from '../../../media/shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-slideshow')
class ComponentShopSlideshow extends BunnyElement {

    @property({type: String})
    path: string = FIRESTORE_COLLECTION_SHOP_PRODUCTS;

    @property({type: Array})
    items: any[];

    @property({type: Number})
    ratio: number = 1;

    @property({type: Number})
    duration: number = 3;

    @property({type: Array})
    @computed('items')
    get slides() {
        return this.items.map(_ => {
            let variant = Object.values((_._data as any).variants)[0] as any;

            return {
                navigation: {
                    component: 'component-slideshow-navigation-item',
                    properties: {
                        textContent: _._data.name.replace(/ - [a-zA-Z]* ?[0-9]{1,2}[stndrh]{2} ?[a-zA-Z]*$/, ''),
                    },
                },
                components: [
                    {
                        component: 'component-media-view-image',
                        properties: {
                            media: _._data.productImages?.find((_:MediaReferenceField)=>_.usages?.includes('featured')),
                            type: 'default-featured',
                            style: 'overflow: hidden;',
                            width: 1024,
                            height: 410,
                        },
                    },
                    {
                        component: 'div',
                        properties: {
                            innerHTML: `<div style="transform: skewX(15deg)">${_._data.name}</div>`,
                            classList: 'hidden-md-down',
                            style: 'overflow: hidden; transform: skewX(-15deg); font-size: 30px; font-size: calc(0.5vw + 18px); padding: 15px; padding-left: 25px; padding-right: 25px; max-width: 60%; background: #e56f04; color: #efefef; bottom: auto; left: -15px; top: 25px; right: auto;',
                        },
                    },
                    {
                        component: 'div',
                        properties: {
                            innerHTML: `£${(variant.price/100).toFixed(2)} each<br/>${variant.stock} Tickets`,
                            style: 'top: auto; left: auto; text-transform: uppercase; font-weight: bold; bottom: 65px; right: 15px; color: #ffffff; text-shadow: rgba(32, 32, 32, .9); text-align: right; font-size: 20px; line-height: 1.3; text-shadow: 0 0 1px black, 0 0 2px black, 0 0 3px black',
                        },
                    },
                    {
                        component: 'a',
                        properties: {
                            href: `/shop/products/${_.id}`,
                            innerHTML: '<component-button style="background-color: #e56f04; color: #efefef; margin: 0;">View details</component-button>',
                            style: 'top: auto; left: auto; bottom: 15px; right: 15px;',
                        },
                    },
                ],
            };
        });
    }

    @property({type: Object})
    get searchParams() {
        return {
            searchFilter: [
                {term: {category: 'Featured'}},
            ],
        };
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-slideshow {
                overflow: hidden;
            }
        `
    ];

    override render(){
        return html`
            <component-elasticsearch-collection-list .path="${this.path}"
                                                     .searchId="${'searchProductsFrontend'}"
                                                     .params="${this.searchParams}"
                                                     .items="${this.bind.items}" style="position: absolute; opacity: 0; visibility: hidden"></component-elasticsearch-collection-list>

            <component-slideshow .ratio="${this.ratio}"
                                 .duration="${this.duration}" 
                                 .slides="${this.slides}"></component-slideshow>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-slideshow': ComponentShopSlideshow;
    }
}