import {AspireCompsPackage, REGISTERED_FUNCTION_ID_pwaInstallPrompt} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './pages/page-layout-aspire-comps';
import './pages/page-layout-account-aspire-comps';
import './components/component-aspire-comps-guaranteed-draw';
import './components/component-aspire-comps-homepage-intro';
import './components/component-aspire-comps-info-step';
import './components/component-aspire-comps-items-section';
import './components/component-aspire-comps-live';
import './components/component-aspire-comps-live-banner';
import './components/component-aspire-comps-product-countdown-timer';
import './components/component-aspire-comps-product-display';
import './components/component-aspire-comps-product-info';
import './components/component-aspire-comps-product-list-item';
import './components/component-aspire-comps-product-points';
import './components/component-aspire-comps-product-tab-competition-details';
import './components/component-aspire-comps-product-tab-our-process';
import './components/component-aspire-comps-review-item';
import './components/component-aspire-comps-review-slider';
import './components/component-aspire-comps-shop-purchase-quantity';
import './components/component-aspire-comps-stats';
import './components/component-countdown-timer';
import './components/component-homepage-competitions-available';
import './components/component-slider';
import './components/component-trustpilot-rating';
import {registerFunction} from '../../__internal/shared/helpers/RegisteredFunctionsHelper';
import {ComponentPwaInstallPrompt} from '../../__internal/local/components/component-pwa-install-prompt';

export class AspireCompsLocalPackage extends AspireCompsPackage implements LocalPackage {

    constructor() {
        super();

        registerFunction(REGISTERED_FUNCTION_ID_pwaInstallPrompt, function () {
            ComponentPwaInstallPrompt.getInstance().pwaPrompt(true);
        });
    }
}