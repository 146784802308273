import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {storageBoundLocalStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';

// language=SCSS
const LOG_STYLES = scss`
    .logRow {
        min-height: 0 !important;
        min-width: 100% !important;
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
        display: flex !important;
        column-gap: 5px;
        flex-wrap: wrap;
    }

    .logRow span {
        padding: 3px;
        border-radius: 3px;
        background-color: rgba(32, 32, 32, .2);
        text-align: center;
        min-width: 25px;
    }

    .logRow span[data-log-level="info"] {
        background-color: rgba(0, 33, 122, 0.37);
    }

    .logRow span[data-log-level="warn"] {
        background-color: rgba(122, 106, 0, 0.37);
    }

    .logRow span[data-log-level="error"] {
        background-color: rgba(122, 33, 0, 0.37);
    }

    .logRow pre {
        display: inline-block;
        height: 18px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        vertical-align: text-bottom;
        margin-left: 5px;
        padding-left: 5px;
        border-left: solid rgba(0, 0, 0, 0.1) 1px;
        flex: 1;
        white-space: break-spaces;
    }
`;

@customElement('component-dev-tools-emulator-log')
export class ComponentDevToolsEmulatorLog extends BunnyElement {

    @property({type: Array, notify: true})
    rawItems: any[] = [];

    @property({type: String, notify: true})
    search: string = '';

    @property({type: Array})
    @computed('search')
    get regexSearches() {
        let search = this.search;
        if (!search) return [];

        let searches = search.split(' && ');
        return searches.map(_ => {
            if (_[0] === '!') {
                let regex = new RegExp(_.substr(1), 'i');
                (regex as any).invert = true;

                return regex;

            } else {
                return new RegExp(_, 'i');
            }
        });
    }


    @property({type: Array})
    @computed('rawItems', 'regexSearches', 'lastCleared')
    get items() {
        let regexSearches = this.regexSearches;
        if (!regexSearches?.length) {
            return this.rawItems.filter(_ => {
                return _.timestamp >= this.lastCleared;
            });
        }


        return this.rawItems.filter(_ => {
            if (_.timestamp < this.lastCleared) return false;


            for (let regexSearch of regexSearches) {
                let matches = _._search.match(regexSearch);
                if ((regexSearch as any).invert) {
                    matches = !matches;
                }


                if (!matches) return false;
            }

            return true;
        });
    }

    private tempNewItems: any[] = [];

    private socket: WebSocket;

    @property({type: Object})
    viewFullLog: any;

    @property({type: Number, notify: true})
    @storageBoundLocalStorage('dev-tools-emulator-log-last-cleared', '0', _ => parseInt(_))
    lastCleared = 0;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: flex;
                flex-direction: column;
                height: 400px;
            }

            .fullLog {
                overflow: auto;
                flex: 1;
                height: auto;
                max-height: none;
            }
        `,
    ];

    renderLogLabel(title: string, field: string, value: string) {
        if (!value) return undefined;

        return html`
            <span title="${title}"
                  @click="${(_: MouseEvent) => this.search = `${field}:${value}`}">${value}</span>
        `;
    }

    override render() {
        return html`
            <component-input .value="${this.bind.search}" placeholder="Search">
                <component-button slot="suffix" style="min-width: 0; padding: 3px 5px;" @click="${this.clearLogs}">
                    Clear
                </component-button>
            </component-input>

            ${this.items?.length ? html`
                <component-list-view
                        class="fullLog"
                        .items="${this.items}"
                        .injectedStyles="${LOG_STYLES}"
                        .renderItem="${(item: any, _index: number, column: number, row: number) => html`
                            <div class="logRow" style="--x: ${column}; --y: ${row};">
                            <span data-log-level="${item.level}"
                                  style="text-transform: uppercase"
                                  title="${item.level}"
                                  @click="${(_: MouseEvent) => this.search = `level:${item.level}`}">${item.level[0]}</span>

                                ${this._formatTimestamp(item.timestamp)}


                                ${this.renderLogLabel(`Function: ${this._formatFunctionName(item.data?.metadata?.function?.name)}`, 'function', this._formatShortFunctionName(item.data?.metadata?.function?.name))}
                                ${this.renderLogLabel('Package', 'package', item.data?.user?.package)}
                                ${this.renderLogLabel('Logger', 'logger', item.data?.user?.logger)}


                                <pre @click="${(_: MouseEvent) => this.viewFullLog = item}">${this._formatMessage(item.data?.user?.message || item.message)}</pre>
                            </div>
                        `}">
                </component-list-view>
            ` : html`
                <p>No results for ${this.search}</p>
            `}


            ${this.viewFullLog ? html`
                <component-dialog opened @opened-changed="${(e: CustomEvent) => {
                    if (!e.detail.value) this.viewFullLog = undefined;
                }}">
                    <pre style="overflow: auto; max-height: 80vh">${this._formatMessage(this.viewFullLog.data?.user?.message || this.viewFullLog.message)}</pre>
                </component-dialog>
            ` : undefined}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.socket = new WebSocket('ws://127.0.0.1:4500/');
        this.socket.onmessage = this.handleMessage;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.socket.close();
    }

    clearLogs() {
        this.lastCleared = Date.now();
    }

    @listen('keydown', window)
    onCtrlL(e: Event) {
        if (!(e instanceof KeyboardEvent)) return;

        if ((e.ctrlKey || e.metaKey) && e.key === 'l') {
            this.clearLogs();
            e.preventDefault();
        }
    }

    _formatMessage(value: string) {
        return value.replace(/^> /, '');
    }

    _formatFunctionName(value: string) {
        return value?.replace('europe-west1-', '');
    }

    _formatShortFunctionName(value: string) {
        return this._formatFunctionName(value)?.split('-')?.reverse()[0];
    }

    _formatTimestamp(value: string) {
        return RenderingHelper._dateFormat(new Date(value), 'HH:mm');
    }

    generateLogMessageSearchValue(logMessage: any) {
        let search = `level:${logMessage.level}`;
        if (logMessage.data?.metadata?.function?.name) {
            search += `; function:${this._formatShortFunctionName(logMessage.data.metadata.function.name)}`;
        }
        if (logMessage.data?.user?.package) {
            search += `; package:${logMessage.data.user.package}`;
        }
        if (logMessage.data?.user?.logger) {
            search += `; logger:${logMessage.data.user.logger}`;
        }

        search += '; ' + this._formatMessage(logMessage.data?.user?.message || logMessage.message);

        return search;
    }

    @bind()
    handleMessage(e: MessageEvent) {
        let logMessage = JSON.parse(e.data);
        if (logMessage?.data?.user?.severity === 'ERROR') {
            logMessage.level = logMessage?.data?.user?.severity.toLowerCase();
        }

        Object.defineProperty(logMessage, '_search', {
            value: this.generateLogMessageSearchValue(logMessage),
        });


        if (!this.tempNewItems.length) {
            setTimeout(() => {
                this.rawItems.unshift(...this.tempNewItems);
                this.dispatchEvent(new CustomEvent('new-raw-items', {detail: {newItems: this.tempNewItems}}));
                this.requestUpdate('rawItems');
                this.tempNewItems = [];
            }, 100);
        }


        this.tempNewItems.unshift(logMessage);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-dev-tools-emulator-log': ComponentDevToolsEmulatorLog;
    }
}
