import {customElement} from 'lit/decorators.js';
import {PageLayoutDefault} from './page-layout-default';
import {html} from 'lit';

@customElement('page-layout-none')
export class PageLayoutNone extends PageLayoutDefault {

    override render() {
        return html`
            <div id="none" data-region>
                ${this.renderRegion(this.content?.none)}
            </div>
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'page-layout-none': PageLayoutNone;
    }
}