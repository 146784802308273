import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {CommentPickerPrizeDocument, FIRESTORE_COLLECTION_PROMOTIONAL_DRAWS} from '../../shared/helpers/FirebaseHelper';
import {FirestoreCollection} from '../../../__internal/local/controllers/FirestoreCollection';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {config} from '../../../../config.ts';
import {choose} from 'lit/directives/choose.js';
import {userCall} from '../../../auth/local/helpers/UserCallHelper.ts';
import {Auth} from '../../../auth/local/controllers/Auth.ts';
import {showToast} from '../../../__internal/local/helpers/ToastHelper.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';
import {Route} from '../../../routing/local/controllers/Route.ts';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';

@customElement('component-promotional-draws')
class ComponentPromotionalDraws extends BunnyElement {
    @property({type: Object, notify: true})
    unclaimedPrizes = new FirestoreCollection<CommentPickerPrizeDocument>(this, `${FIRESTORE_COLLECTION_PROMOTIONAL_DRAWS}`, {
        method: FetchMethod.FASTEST_THEN_CLEAN,
    });

    @property({type: Array})
    claim: { winnerDocId: string, prizeDocument: CommentPickerPrizeDocument };

    @property({type: Boolean})
    showMessage: boolean = false;

    @property({type: Boolean})
    successfulClaim: boolean = false;

    @property({type: Object, notify: true})
    auth = Auth.getInstance(this);

    @property({type: Object, notify: true})
    route = Route.getInstance(this);

    static override styles = [
        sharedStyles,
        //language=SCSS
        scss`
            :host {
                --heading-style: {
                };
            }

            .itemContainer {
                --col-sm: 6;
                --col-md: 4;

                > div {
                    box-shadow: var(--shadow-elevation-2dp-box-shadow);
                    padding-bottom: 15px;
                }

                p {
                    padding: 0 15px;
                    margin-bottom: 0;
                }
            }

            h4 {
                background: var(--primary-color);
                color: white;
                padding: 10px 15px;
                margin: 0;
                @apply --heading-style;
            }

            .itemContainer > div > component-media-view-image {
                background-color: #f2f2f2;
                position: relative;
                box-shadow: 0 1px 0 0 rgba(142, 146, 135, .3);
                overflow: hidden;
            }

            component-media-view-image > component-media-view-image {
                position: absolute;
                bottom: 0;
                right: 0;
                max-width: 33%;
                max-height: 33%;
                background-color: #f2f2f2;
                box-shadow: -1px -1px 3px 0 rgba(142, 146, 135, .6), 0 0 10px 0 rgba(32, 32, 32, .4);
                width: 100%;
                overflow: hidden;
            }

            .loginWithButton {
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                -webkit-appearance: none;
                background-color: WHITE;
                background-image: none;
                border: 1px solid #747775;
                -webkit-border-radius: 5px;
                border-radius: 5px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                color: #1f1f1f;
                cursor: pointer;
                font-family: 'Roboto', arial, sans-serif;
                font-size: 14px;
                height: 40px;
                letter-spacing: 0.25px;
                outline: none;
                overflow: hidden;
                padding: 0 12px;
                position: relative;
                text-align: center;
                -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
                transition: background-color .218s, border-color .218s, box-shadow .218s;
                vertical-align: middle;
                white-space: nowrap;
                width: auto;
                max-width: 400px;
                min-width: min-content;

                &.facebook {
                    border: 1px solid #1877F2;
                    color: #1877F2;
                }

                .loginWithButton-icon {
                    height: 20px;
                    margin-right: 12px;
                    min-width: 20px;
                    width: 20px;
                }

                .loginWithButton-content-wrapper {
                    -webkit-align-items: center;
                    align-items: center;
                    display: flex;
                    -webkit-flex-direction: row;
                    flex-direction: row;
                    -webkit-flex-wrap: nowrap;
                    flex-wrap: nowrap;
                    height: 100%;
                    justify-content: space-between;
                    position: relative;
                    width: 100%;
                }

                .loginWithButton-contents {
                    -webkit-flex-grow: 1;
                    flex-grow: 1;
                    font-family: 'Roboto', arial, sans-serif;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: top;
                }

                .loginWithButton-state {
                    -webkit-transition: opacity .218s;
                    transition: opacity .218s;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }

            .facebookButton > a > div {
                border: 1px solid #1877F2;
                padding: 5px;
                border-radius: 5px;
                color: #1877F2;
                font-weight: bold;
                display: inline-block;

                > img {
                    height: 20px;
                    padding: 5px;
                }

                > p {
                    margin: 0;
                    padding: 0;
                }

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            #loginDialog {
                component-login {
                    max-width: 500px;
                    width: 90vw;
                    display: block;
                }
            }
        `,
    ];

    connectedCallback() {
        super.connectedCallback();
    }

    render() {
        //language=HTML
        return html`
            <div class="gridContainer">
                ${this.unclaimedPrizes.data?.map(_ => this.renderPrize(_))}
            </div>
        `;
    }

    renderLoginButton(commentPickerPrize: CommentPickerPrizeDocument) {
        return html`
            ${choose(commentPickerPrize.authMethod, [
                ['youtube', () => html`
                    <div style="margin-top: 10px">
                        <a href="${this.generateOAuthURL(commentPickerPrize)}"
                           @click="${this.getAccountLinkHandler(commentPickerPrize)}">
                            <button class="loginWithButton">
                                <div class="loginWithButton-state"></div>
                                <div class="loginWithButton-content-wrapper">
                                    <div class="loginWithButton-icon">
                                        <img alt="Google" src="/images/social/google.svg">
                                    </div>
                                    <span class="loginWithButton-contents">Sign in with Google</span>
                                </div>
                            </button>
                        </a>
                    </div>
                `],
                ['facebook', () => html`
                    <div style="margin-top: 10px">
                        <a href="${this.generateOAuthURL(commentPickerPrize)}"
                           @click="${this.getAccountLinkHandler(commentPickerPrize)}">
                            <button class="loginWithButton facebook">
                                <div class="loginWithButton-state"></div>
                                <div class="loginWithButton-content-wrapper">
                                    <div class="loginWithButton-icon">
                                        <img src="/images/social/facebook.svg">
                                    </div>
                                    <span class="loginWithButton-contents">Continue with Facebook</span>
                                </div>
                            </button>
                        </a>
                    </div>
                `],
            ])}
        `;
    }

    renderPrize(commentPickerPrize: CommentPickerPrizeDocument) {
        return html`
            <div class="itemContainer">
                <div>
                    <h4>${commentPickerPrize.productName}</h4>
                    <component-media-view-image .media="${commentPickerPrize.winnerImage}"
                                                type="default-thumbnail"
                                                fetch-method="cacheFirst"
                                                width="400"
                                                height="300">
                        <component-media-view-image .media="${commentPickerPrize.productImage}"
                                                    type="default-thumbnail"
                                                    fetch-method="cacheFirst"
                                                    width="400"
                                                    height="300"></component-media-view-image>
                    </component-media-view-image>

                    <p>
                        <strong>Winner</strong> &middot; ${commentPickerPrize.winnerUsername}
                        <small>on</small> <span
                            style="text-transform: capitalize;">${commentPickerPrize.authMethod}</span>
                    </p>

                    <p>
                        ${this.renderLoginButton(commentPickerPrize)}
                    </p>
                </div>
            </div>
        `;
    }

    getAccountLinkHandler(commentPickerPrize: CommentPickerPrizeDocument) {
        return (e: MouseEvent) => {
            let permissions = Auth.getInstance().user?.permissions ?? [];

            if (!permissions.includes('app.loggedin')) {
                HistoryHelper.pushState('/login?returnUrl=/promotional-draws');
                e.preventDefault();
                return;
            }

            let anchor: HTMLAnchorElement = e.composedPath().find((_: any) => _.tagName === 'A') as any;
            if (!anchor) return;

            let url = anchor.href;
            let popup = window.open(url, '', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=540,height=600`);
            if (!popup) throw new Error('Failed to open oauth popup');

            this.doClaim(popup, commentPickerPrize);

            e.preventDefault();
        };
    }

    generateOAuthURL(commentPickerPrize: CommentPickerPrizeDocument) {
        switch (commentPickerPrize.authMethod) {
            case 'facebook':
                return this.generateFacebookOauth(commentPickerPrize);
            case 'youtube':
                return this.generateYoutubeOauth(commentPickerPrize);
            case 'instagram':
                return this.generateInstagramOauth(commentPickerPrize);
            case 'tiktok':
                return this.generateTikTokOauth(commentPickerPrize);
            default:
                throw new Error(`Unknown oauth method ${commentPickerPrize.authMethod}`);
        }
    }

    generateYoutubeOauth(commentPickerPrize: CommentPickerPrizeDocument) {
        return `https://accounts.google.com/o/oauth2/v2/auth`
            + `?client_id=${encodeURIComponent(config.commentPicker.youtube.oauth.clientId)}`
            + `&redirect_uri=${encodeURIComponent(config.commentPicker.oauthRedirectURI)}`
            + `&response_type=token`
            + `&scope=https://www.googleapis.com/auth/youtube.readonly`
            + `&state=${commentPickerPrize._ref?.id}`;
    }

    generateFacebookOauth(commentPickerPrize: CommentPickerPrizeDocument) {
        return 'https://www.facebook.com/v18.0/dialog/oauth'
            + `?client_id=231908571605772`
            + `&response_type=token`
            + `&redirect_uri=${encodeURIComponent(config.commentPicker.oauthRedirectURI)}`
            + `&scope=public_profile`
            + `&state=${commentPickerPrize._ref?.id}`;
    }

    generateTikTokOauth(_commentPickerPrize: CommentPickerPrizeDocument) {
        return 'Not implemented';
    }

    generateInstagramOauth(_commentPickerPrize: CommentPickerPrizeDocument) {
        return 'Not implemented';
    }

    async doClaim(popup: Window, commentPickerPrize: CommentPickerPrizeDocument) {
        let documentId = commentPickerPrize._ref?.id;
        if (!documentId) return;

        let commentDrawClaimStorageKey = `process-comment-draw-claim-access-token:${documentId}`;

        let accessToken = await new Promise<string>((res, _rej) => {
            let interval: any;

            // Adding the event listener for postMessage
            let callback = (e: MessageEvent) => {
                if (e.source !== popup) return;
                window.removeEventListener('message', callback);
                clearInterval(interval);
                res(e.data);
            };

            window.addEventListener('message', callback);

            // listening to local storage for key change, and also for cross-origin popup closure
            localStorage.removeItem(commentDrawClaimStorageKey);
            interval = setInterval(() => {
                let token = localStorage.getItem(commentDrawClaimStorageKey);
                if (token) {
                    localStorage.removeItem(commentDrawClaimStorageKey);
                    window.removeEventListener('message', callback);
                    clearInterval(interval);
                    res(token);
                }
            }, 1000);
        });

        await this.processClaim(accessToken, documentId);
    }

    @observe('route')
    async processBySearchParams(route: Route) {
        let accessToken = route.current.query.accessToken;
        let documentId = route.current.query.documentId;

        if (!accessToken || !documentId) {
            return;
        }

        HistoryHelper.replaceState('/promotional-draws');
        await this.processClaim(accessToken, documentId);
    }

    async processClaim(accessToken: string, documentId: string) {
        showToast(html`
            Processing claim
        `, {
            dismissable: false,
        });

        let userCallResponse = await userCall(`claimPromotionalDraw`, {
            accessToken: accessToken,
            documentId: documentId,
        });

        if (userCallResponse.claim === null) {
            showToast(html`
                Sorry! It doesn't look like your account matches that draw. If you think this is incorrect,
                <a href="/contact-us" style="color: var(--primary-color)">contact support</a>
            `, {
                autoDismiss: 10000,
            });
            return;
        }

        let prizeIndex = this.unclaimedPrizes.data.findIndex(p => p._ref?.id === userCallResponse.claim.commentPickerPrizeDocId);
        let prizeDocument = this.unclaimedPrizes.data[prizeIndex];
        this.unclaimedPrizes.data.splice(prizeIndex, 1);
        this.claim = {
            winnerDocId: userCallResponse.claim.winnerDocId,
            prizeDocument: prizeDocument,
        };

        showToast(html`
            Congrats! Successfully claimed.
            <a href="/previous-winners/${userCallResponse.claim.winnerDocId}?utm_source=aspire&utm_medium=commentDrawClaim&utm_content=View%20your%20winners%20page"
               style="color: var(--primary-color)">
                View your winners page
            </a>
        `, {
            autoDismiss: 10000,
        });
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'component-promotional-draws': ComponentPromotionalDraws;
    }
}