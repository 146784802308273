import {AnalyticsPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-highcharts';
import './components/component-analytics-cookie-banner';
import './components/component-analytics-report';
import './components/component-analytics-data-supplier-elasticsearch';
import './components/component-analytics-data-visualizer-graph';
import './components/component-analytics-data-visualizer-list';
import './components/component-analytics-data-visualizer-info';
import './components/component-analytics-data-visualizer-list-shop-products';
import './components/component-analytics-data-visualizer-list-shop-customers';
import './components/component-analytics-data-visualizer-list-shop-orders';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from "../../__internal/local/helpers/SetupAppReadyEvent";

export class AnalyticsLocalPackage extends AnalyticsPackage implements LocalPackage {
    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            e.registerPostComponent({
                component: 'component-analytics-cookie-banner',
            });
        },
    };
}