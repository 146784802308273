import {quickDotGet, quickDotPut} from '../../../__internal/shared/helpers/DotHelper';

export const obfuscate = (data: string) => {
    //add a char shift character so shift all the characters by that amount
    let shiftCharacter = Math.floor(Math.random() * 10);
    if (shiftCharacter === 5) {
        shiftCharacter = 6;
    }
    data = data.replace(/./g, (m) => String.fromCharCode(m.charCodeAt(0) + shiftCharacter));

    //return the data with the appended shift chracter
    return `${data}${shiftCharacter}`;
};

export const deobfuscate = (data: string) => {
    //load the shift character from the raw data at the last character
    let shiftCharacter = parseInt(data[data.length - 1]);
    //shift the char codes by the shift character( excluding the last 2 characters as they arnt shifted to start with)
    return `${data.substring(0, data.length - 1)
        .replace(/./g, (m) => String.fromCharCode(m.charCodeAt(0) - shiftCharacter))}`;
};


export const compress = async (string: string) => {
    // Convert the string to a byte stream.
    let stream = new Blob([string], {type: 'application/octet-stream'}).stream();

    // @ts-ignore
    let compressedStream = stream.pipeThrough(new CompressionStream('gzip'));
    return await new Response(compressedStream, {headers: {'Content-Type': 'application/octet-stream'}}).blob();
};

export const decompress = async (compressedBytes: Blob) => {
    // Convert the bytes to a stream.
    let stream = compressedBytes.stream();

    // @ts-ignore
    let decompressedStream = stream.pipeThrough(new DecompressionStream('gzip'));
    return await new Response(decompressedStream).text();
};


interface Hydrator {
    type: string;
    keys: string[];
}

const HYDRATORS: Hydrator[] = [
    {
        type: 'app-error',
        keys: ['accountId', 'deviceId', 'error.name', 'error.message', 'error.stack', 'errorTs', 'extendedInfo', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'performanceEntries', 'queryParams', 'sessionId', 'tabId', 'title', 'url', 'landingReferrer'],
    },
    {
        type: 'app-performance',
        keys: ['accountId', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'performanceEntries', 'queryParams', 'sessionId', 'tabId', 'title', 'url', 'landingReferrer'],
    },
    {
        type: 'page-view',
        keys: ['accountId', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'queryParams', 'sessionId', 'tabId', 'title', 'url', 'landingReferrer'],
    },
    {
        type: 'view-content',
        keys: ['accountId', 'content', 'contentImage', 'contentMeta', 'contentName', 'contentValue', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'queryParams', 'sessionId', 'tabId', 'tags', 'url', 'viewTs', 'viewType', 'landingReferrer'],
    },
    {
        type: 'interaction-view',
        keys: ['accountId', 'content', 'contentImage', 'contentMeta', 'contentName', 'contentValue', 'context.parentTextContent', 'context.parentSelector', 'context.parent.width', 'context.parent.height', 'context.parent.top', 'context.parent.left', 'context.screen.width', 'context.screen.height', 'context.screen.portrait', 'deviceId', 'duration', 'instanceId', 'interactionTs', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'queryParams', 'sessionId', 'tabId', 'tags', 'type', 'url', 'landingReferrer'],
    },
    {
        type: 'interaction-click',
        keys: ['accountId', 'clickInfo.altKey', 'clickInfo.button', 'clickInfo.ctrlKey', 'clickInfo.metaKey', 'clickInfo.shiftKey', 'clickInfo.x', 'clickInfo.y', 'content', 'contentImage', 'contentMeta', 'contentName', 'contentValue', 'context.parentTextContent', 'context.parentSelector', 'context.parent.width', 'context.parent.height', 'context.parent.top', 'context.parent.left', 'context.screen.width', 'context.screen.height', 'context.screen.portrait', 'deviceId', 'duration', 'instanceId', 'interactionTs', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'queryParams', 'sessionId', 'tabId', 'tags', 'type', 'url', 'landingReferrer'],
    },
    {
        type: 'add-to-cart',
        keys: ['accountId', 'addToCartTs', 'content', 'contentImage', 'contentMeta', 'contentName', 'contentValue', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'quantity', 'queryParams', 'sessionId', 'tabId', 'tags', 'url', 'value', 'landingReferrer'],
    },
    {
        type: 'auth-register',
        keys: ['accountId', 'authAttempts', 'authMethod', 'authTs', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'queryParams', 'sessionId', 'tabId', 'url', 'landingReferrer'],
    },
    {
        type: 'auth-login',
        keys: ['accountId', 'authAttempts', 'authMethod', 'authTs', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'queryParams', 'sessionId', 'tabId', 'url', 'landingReferrer'],
    },
    {
        type: 'start-checkout',
        keys: ['accountId', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'orderId', 'orderItems', 'pageTs', 'queryParams', 'sessionId', 'startCheckoutTs', 'tabId', 'url', 'value', 'landingReferrer'],
    },
    {
        type: 'purchase',
        keys: ['accountId', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'orderId', 'orderItems', 'pageTs', 'queryParams', 'sessionId', 'purchaseTs', 'tabId', 'url', 'value', 'landingReferrer'],
    },
    {
        type: 'pwa-prompt',
        keys: ['accountId', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'promptTs', 'pwaPlatform', 'pwaState', 'queryParams', 'sessionId', 'tabId', 'url', 'landingReferrer'],
    },
    {
        type: 'toast-shown',
        keys: ['accountId', 'content', 'deviceId', 'landingQueryParams', 'landingTs', 'landingUrl', 'navigationDepth', 'historyDepth', 'pageTs', 'queryParams', 'sessionId', 'tabId', 'url', 'landingReferrer'],
    },
    //TODO add array keys -> convert, for example with {'cake': ['subThing', 'subThing2']}, convert {cake:[{'subThing': 'aa', 'subThing2': 'baa'}, {'subThing': 'aa', 'subThing2': 'baa'}]} into [['aa', 'baa'],['aa', 'baa']]
];

export const hydrate = (rawData: string) => {
    let data = JSON.parse(rawData);


    let hydrator = HYDRATORS[data[0] as number];
    let ret: Record<string, string> & {
        _type: string
    } = {} as any;
    Object.defineProperty(ret, '_type', {value: hydrator.type});

    //convert the data array back to hydrated object keys based on the hydrator
    for (let i in hydrator.keys) {
        let key = hydrator.keys[i];
        let value = data[parseInt(i) + 1];

        quickDotPut(key, value, ret);
    }

    return ret;
};

export const dehydrate = (type: string, data: any) => {
    //get the hydrator from the type
    let hydratorIndex = HYDRATORS.findIndex(_ => _.type === type);
    let hydrator = HYDRATORS[hydratorIndex];
    if (!hydrator) throw new Error(`Unknown hydrator: ${type}`);
    let ret = [
        hydratorIndex,
    ];

    //convert the object to a flat object, and merge in dupped session hydration values with hydration nulls
    for (let key of hydrator.keys) {
        let value = quickDotGet(key, data);

        ret.push(value);
    }

    return JSON.stringify(ret);
};