import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {html as litHTML, render} from 'lit-html';
import {ComponentInputSelectItem} from './component-input-select-item';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';

//TODO render the actual item in place rather than the value
//TODO handle opening upwards if theres not enough room
//TODO max height on the options and scroll
@customElement('component-input-select')
export class ComponentInputSelect extends BunnyElement {

    @property({type: Boolean, reflect: true})
    hasValue = false;

    @property()
    label?: string;

    @property({type: Boolean})
    required: boolean = false;

    @property({notify: true})
    value: any;

    @property({type: Number, notify: true})
    selectingIndex = 0;

    @property({type: Boolean, notify: true, reflect: true})
    dropDownOpen = false;

    @property({type: Array})
    options: object[] = [];

    @property({type: String, reflect: true})
    dropDownDirection: 'down' | 'up' = 'down';

    //TODO cache items
    @property()
    get items() {
        return [...this.querySelectorAll<ComponentInputSelectItem>('component-input-select-item:not([disabled])')];
    }

    @property()
    get selectingItem() {
        return this.querySelector<ComponentInputSelectItem>('component-input-select-item.selecting');
    }

    @property({type: String})
    @computed('value')
    get selectedItemLabel() {
        let values = Array.isArray(this.value) ? this.value : [this.value];
        let selectedItems = this.items.filter(_ => values.includes(_.value));

        return selectedItems.map(_ => _.textContent).join(', ');
    }


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                --placeholder-label-indent: 11px;
                position: relative;
            }


            .label {
                font-size: 13px;
                transform-origin: top left;
                transform: scale(1.1) translateY(25px) translateX(var(--placeholder-label-indent));
                transition: .125s;
                margin-bottom: 3px;
                margin-left: 5px;
                //position: relative;
                //z-index: 1;
                pointer-events: none;
            }

            :host([has-value]) .label {
                transform: scale(1) translateY(0px);
                pointer-events: all;
            }

            :host(:not([hasvalue])) label:focus-within .label {
                opacity: .5;
            }

            .required {
                color: red;
            }

            :host([has-value]) {
                .required {
                    display: none;
                }
            }

            .inputContainer {
                border: solid var(--primary-text-color) 1px;
                border-radius: 3px;
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                padding: 0 15px;
                cursor: pointer;
                min-height: 32px;
                box-sizing: border-box;


                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 2px;
                    left: 0;
                    right: 0;
                    transform: scale(0);
                    transform-origin: 15px bottom;
                    opacity: 0;
                    width: 100%;
                    transition: .125s;
                    background: var(--primary-color);
                }

                &:focus-within:after {
                    transform: scale(1);
                    opacity: 1;
                }

                &:focus-within {
                    border-bottom: solid var(--primary-color) 1px;
                }

                ::slotted(input) {
                    padding: 8px 15px;
                    border: none;
                    display: block;
                    width: 100%;
                    background: none;
                    box-sizing: border-box;
                    flex: 1;
                }

                ::slotted(input:focus) {
                    outline: none;
                }

                slot {
                    display: inline-block;
                }
            }


            :host(:focus) .inputContainer {
                outline: solid black 2px;
            }

            #dropDown {
                position: absolute;
                z-index: 1;
                background: white;
                left: 0;
                right: 0;
                transform: scaleY(0);
                transition: 62ms;
                overflow: hidden;
                min-width: 175px;

                ::slotted(component-input-select-item) {
                    background: white;
                    color: var(--primary-text-color);
                    padding: 8px 15px;
                }

                ::slotted(hr) {
                    margin: 0;
                }
            }

            :host([drop-down-direction="down"]) #dropDown {
                top: 100%;
                transform-origin: top;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            :host([drop-down-direction="up"]) #dropDown {
                bottom: 100%;
                transform-origin: bottom;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
            }

            :host([drop-down-open]) {
                #dropDown {
                    transform: scaleY(1);
                    transition: .125s;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);

                    ::slotted(component-input-select-item.selected) {
                        border-left: solid var(--attention-color) 5px;
                    }

                    ::slotted(component-input-select-item.selecting) {
                        background: var(--primary-text-color);
                        color: white;
                    }
                }
            }
        `,
    ];

    override render() {
        return html`
            ${this.label ? html`
                <div class="label">
                    ${this.label}
                    ${this.required ? html`<span class="required">*</span>` : ''}
                </div>
            ` : undefined}

            <div class="inputContainer" style="display: flex">
                <slot name="prefix"></slot>
                <div id="selectedItem" style="flex: 1">
                    ${this.selectedItemLabel}
                </div>
                <span style="line-height: 1">
                    ▼
                </span>
                <slot name="suffix"></slot>
            </div>


            <div id="dropDown">
                <slot></slot>
            </div>
        `;
    }

    constructor() {
        super();

        this.tabIndex = 0;

        this.addEventListener('keydown', this.onKeyDown);
        this.addEventListener('mousemove', this.onMouseMove);
        this.addEventListener('click', this.onClick);
        this.addEventListener('blur', this.onBlur);
    }

    connectedCallback() {
        super.connectedCallback();

        this.selectingIndex = this.items.findIndex(_ => _.value === this.value);
    }

    @observe('selectingIndex')
    markSelecting(selectingIndex: number) {
        this.selectingItem?.classList?.remove('selecting');
        this.items[selectingIndex]?.classList?.add('selecting');
    }

    @observe('value')
    markSelected(value: any | any[]) {
        let values = Array.isArray(value) ? value : [value];

        for (let item of this.items) {
            let isSelected = values.includes(item.value);

            item.classList?.toggle('selected', isSelected);
        }
    }

    @observe('dropDownOpen')
    remarkSelectedOnDropdownOpen(dropDownOpen: boolean) {
        if (!dropDownOpen) return;

        this.markSelected(this.value);
    }

    @bind()
    onKeyDown(e: KeyboardEvent) {
        if (e.key === 'ArrowDown') {
            this.selectingIndex++;

            if (this.selectingIndex >= this.items.length) {
                this.selectingIndex = 0;
            }

            requestAnimationFrame(() => {
                this.selectSelectingIndex();
            });

        } else if (e.key === 'ArrowUp') {
            this.selectingIndex--;

            if (this.selectingIndex < 0) {
                this.selectingIndex = this.items.length - 1;
            }

            requestAnimationFrame(() => {
                this.selectSelectingIndex();
            });

        } else if (e.key === 'Enter') {
            this.dropDownOpen = !this.dropDownOpen;

        } else if (e.key === 'Escape') {
            this.dropDownOpen = false;
        }
    }

    @bind()
    onMouseMove(e: MouseEvent) {
        if (e.target === this) return;

        let selectedIndex = this.items.findIndex(_ => _ === e.target);
        if (selectedIndex >= 0) {
            this.selectingIndex = selectedIndex;
        }
    }

    @bind()
    onClick(e: MouseEvent) {
        if (e.target === this) {
            this.dropDownOpen = !this.dropDownOpen;

            return;
        }

        let selectedIndex = this.items.findIndex(_ => _ === e.target);
        if (selectedIndex >= 0) {
            this.selectingIndex = selectedIndex;
            this.selectSelectingIndex();
            this.dropDownOpen = false;
        }
    }

    @bind()
    onBlur() {
        this.dropDownOpen = false;
    }


    selectSelectingIndex() {
        this.value = this.selectingItem?.value;
    }

    @observe('value')
    updateHasValue(value: any) {
        this.hasValue = !!value;
    }

    normalizeOptions(options: any[]) {
        return options.map(option => {
            if (typeof option === 'string' || typeof option === 'number') {
                return {value: option, label: option};

            } else if (typeof option === 'object') {
                option.value = option.value || option.label;

                return option;
            }

            return {value: '', label: '__UNKNOWN_OPTION__'};
        });
    }

    @observe('options')
    injectOptions(options: object[]) {
        let optionsTemplate = litHTML`
            ${this.normalizeOptions(options).map(item => litHTML`
                <component-input-select-item .value="${item.value}" class="${item.value === this.value ? 'selecting' : ''}">${item.label}</component-input-select-item>
            `)}
        `;

        render(optionsTemplate, this);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-select': ComponentInputSelect;
    }
}