import {LitElement, PropertyValues} from 'lit';
import {ElementConstructor, HookName, runBunnyHooks} from '../helpers/DecoratorsHelper';
import {directive, Directive} from 'lit/directive.js';
import {PropertyDeclaration} from '../helpers/decorators/PropertyDecoratorHelper';
import {ElementPropertyObserver} from '../helpers/decorators/ObserveDecoratorHelper';
import {ElementPropertyComputed} from '../helpers/decorators/ComputedDecotratorHelper';
import {dotGet, dotPut} from '../../shared/helpers/DotHelper';

const propertyToChangeEvent = (propertyName: string) => `${propertyName.replace(/([a-z])([A-Z])/g, (_m, m1, m2) => `${m1}-${m2.toLowerCase()}`)}-changed`;

class BindDirective extends Directive {

    private boundProperty: string;
    private isDeeplyBoundProperty: string;
    private parentBindings: any;

    constructor(partInfo: any) {
        super(partInfo);

        this.boundProperty = partInfo.boundProperty;
        this.isDeeplyBoundProperty = partInfo.isDeeplyBoundProperty;
        this.parentBindings = partInfo.options.host;

        partInfo.element.addEventListener(propertyToChangeEvent(partInfo.name), (e: CustomEvent) => {
            let value = e.detail.value;

            if (this.isDeeplyBoundProperty) {
                let oldValue = this.render();
                if (oldValue !== value) {
                    //hack to allow dotPut to work properly with lit properties as they arnt (hasOwnProperty)
                    let topLevelPath = this.boundProperty.split('.')[0];
                    let topLevelProperty = this.parentBindings[topLevelPath];
                    if (!topLevelProperty) {
                        topLevelProperty = this.parentBindings[topLevelPath] = {};
                    }

                    dotPut(this.boundProperty, value, {
                        [topLevelPath]: topLevelProperty,
                    });

                    //TODO notify the root object that a child changed
                    this.parentBindings.requestUpdate(this.boundProperty, oldValue);
                }

            } else {
                this.parentBindings[this.boundProperty] = value;
            }
        });
    }

    render() {
        if (this.isDeeplyBoundProperty) return dotGet(this.boundProperty, this.parentBindings);


        return this.parentBindings[this.boundProperty];
    }
}

const getBoundDirective = (boundProperty: string, isDeep = false) => {
    return directive(class extends BindDirective {
        constructor(partInfo: any) {
            partInfo.boundProperty = boundProperty;
            partInfo.isDeeplyBoundProperty = isDeep;
            super(partInfo);
        }
    });
};

const getCachedParentProperties = (name: string, cacheName: string, constructor: ElementConstructor) => {
    if (!constructor.hasOwnProperty(cacheName)) {
        (constructor as any)[cacheName] = [];
        let capturedMethodNames = new Set<string>();
        let parentConstructor = (constructor as any);
        do {
            if (parentConstructor === HTMLElement) break;

            if (parentConstructor.hasOwnProperty(name)) {
                let parentConstructorMethods = parentConstructor[name];
                for (let parentConstructorMethod of parentConstructorMethods) {
                    let methodName = parentConstructorMethod.method.name.replace('get ', '');
                    if (capturedMethodNames.has(methodName)) continue;

                    capturedMethodNames.add(methodName);
                    (constructor as any)[cacheName].push(parentConstructorMethod);
                }
            }

        } while ((parentConstructor = (parentConstructor as any).__proto__));
    }

    return (constructor as any)[cacheName];
};
const getObservers = getCachedParentProperties.bind(undefined, 'observers', '__cachedObservers');
const getComputeds = getCachedParentProperties.bind(undefined, 'computeds', '__cachedComputeds');

export class BunnyElement extends LitElement {

    private __runBunnyHooks: (hookName: HookName) => any;

    constructor() {
        super();

        this.__runBunnyHooks = runBunnyHooks.bind(this);
        this.__runBunnyHooks('init');
    }

    get bind(): this {
        let cachedBindings: any = {};

        let bindingProperties: PropertyDescriptorMap = {};
        for (let property of (this.constructor as any).elementProperties.keys()) {
            bindingProperties[property] = {
                get(): any {
                    let cachedBinding = cachedBindings[`__${property}`] = getBoundDirective(property)();

                    Object.defineProperty(cachedBindings, property, {value: cachedBinding});
                    return cachedBinding;
                },
                configurable: true,
            };
        }

        Object.defineProperties(cachedBindings, bindingProperties);


        Object.defineProperty(this, 'bind', {value: cachedBindings});
        return cachedBindings;
    }

    bindDeep(property: string) {
        let bind = this.bind as any;

        bind[property] ??= getBoundDirective(property, true)();

        return bind[property];
    }

    private triggerEventsForUpdatedProperties(changedProperties: PropertyValues) {
        let propertiesInfo: Map<PropertyKey, PropertyDeclaration> = (this.constructor as any).elementProperties;
        for (let [propertyName, oldValue] of changedProperties) {
            let propertyInfo = propertiesInfo.get(propertyName) as PropertyDeclaration;
            if (!propertyInfo?.notify) continue;

            this.dispatchEvent(new CustomEvent(
                propertyToChangeEvent(propertyName as string),
                {
                    detail: {
                        oldValue: oldValue,
                        value: (this as any)[propertyName],
                    },
                }));
        }
    }

    private triggerObserversForUpdatedProperties(changedProperties: PropertyValues) {
        if (!changedProperties.size) return;

        let observers = getObservers(this.constructor) as ElementPropertyObserver[];
        if (!observers) return;

        let changedKeys = [...changedProperties.keys()];
        if (!changedKeys.length) return;

        let updatedObservers = observers.filter(_ => _.targets.some(_ => changedKeys.includes(_)));
        if (!updatedObservers.length) return;


        for (let updatedObserver of updatedObservers) {
            let methodArgs = updatedObserver.targets.map(_ => (this as any)[_]);
            updatedObserver.method.apply(this, methodArgs);
        }
    }

    private triggerComputedsForUpdatedProperties(changedProperties: PropertyValues) {
        if (!changedProperties.size) return;

        let computeds = getComputeds(this.constructor) as ElementPropertyComputed[];
        if (!computeds) return;

        let changedKeys = [...changedProperties.keys()];
        if (!changedKeys.length) return;

        let updatedComputeds = computeds.filter(_ => _.targets.some(_ => changedKeys.includes(_)));
        if (!updatedComputeds.length) return;

        for (let updatedComputed of updatedComputeds) {
            let computedValue = updatedComputed.method.apply(this);
            let oldValue = updatedComputed.getValue.apply(this);

            updatedComputed.setValue.apply(this, [computedValue]);
            this.requestUpdate(updatedComputed.property, oldValue);
        }
    }

    override updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);

        this.triggerEventsForUpdatedProperties(changedProperties);
        this.triggerObserversForUpdatedProperties(changedProperties);
        this.triggerComputedsForUpdatedProperties(changedProperties);
    }

    connectedCallback() {
        super.connectedCallback();

        this.__runBunnyHooks('connectedCallback');
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.__runBunnyHooks('disconnectedCallback');
    }

}