import {customElement} from 'lit/decorators.js';
import {registerEditorElement, registerEditorElementProperty} from '../../../routing/local/helpers/DecoratorHelper';
import {MediaReferenceField} from '../../shared/helpers/FirebaseHelper';
import {ComponentMediaView} from './component-media-view';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';

@customElement('component-media-view-image')
@registerEditorElement({
    key: 'component-media-view-image',
    name: 'Media image',
    defaultProperties: {
        width: 620,
        height: 600,
        type: 'original',
    },
})
export class ComponentMediaViewImage extends ComponentMediaView {

    @property({type: String})
    mode: 'image' | 'background-image' = 'image';

    @property({type: Object})
    @registerEditorElementProperty({
        properties: {
            label: 'Media',
            type: 'Image',
        },
        component: 'component-input-media-file',
    })
    media?: MediaReferenceField;

    @property({type: String})
    alt: string;

    @property({type: Array})
    @computed('sources')
    get generatedSources() {
        return this.sources.map(source => createComponent({
            component: 'source',
            properties: {
                src: this._convertCdnProxy(source.src),
                ...((source as any).sources ? {
                    srcset: this._generateSourceSet((source as any).sources),
                } : undefined),
                media: source.media,
                type: source.type,
            },
        }, {}));
    }


    override render() {
        return html`
            <picture id="picture">
                ${this.generatedSources}
                <img @load="${this.fadeIn}"
                     class="fadeIn full"
                     src="${this.defaultSource ? this._convertCdnProxy(this.defaultSource.src) : undefined}"
                     alt="${this.alt}">
            </picture>

            ${super.render()}
        `;
    }

    _generateSourceSet(sources: { src: string, scaling: number }[]) {
        if (!sources) return sources;

        return sources.map(_ => `${this._convertCdnProxy(_.src)} ${this.width * _.scaling}w`)
            .join(', ');
    }

    _convertCdnProxy(src: string) {
        if (!src) return src;

        return src.replace(/^https:\/\/firebasestorage\.googleapis\.com\//, 'https:\/\/1.cdn.aspirecomps.co.uk\/');
    }

    @(observe as any)('src', 'visible')
    populateSourcesFromSrc(src: string, visible: boolean) {
        if (!src) return;
        //stop loading if its not visible yet
        if (!visible) return;

        this.sources = [
            {src: src, media: 'all', type: 'image/jpeg'},
        ];
        this.loading = false;
    }

    @bind()
    fadeIn(e: Event) {
        (e.currentTarget as Element).classList.add('loaded');

        let currentTarget = e.currentTarget as HTMLImageElement;
        this.width = currentTarget.naturalWidth;
        this.height = currentTarget.naturalHeight;
    }


    @observe('defaultSource')
    loadDimensions(_defaultSource: any) {
        if (!_defaultSource) return;

        this.width = _defaultSource.width;
        this.height = _defaultSource.height;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-media-view-image': ComponentMediaViewImage;
    }
}