export const remapNumber = (val: number, inMin: number, inMax: number, outMin: number, outMax: number) => {
    return (val - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
};

export const numberToOrdinal = (number: number) => {
    let tens = number % 100;
    let ones = number % 10;

    if ([11, 12, 13].includes(tens)) return 'th';

    if (ones === 1) return 'st';
    if (ones === 2) return 'nd';
    if (ones === 3) return 'rd';

    return 'th';
};