import {html} from 'lit';
import {customElement} from 'lit/decorators.js';
import './component-ripple';
import {BunnyElement} from '../packages/__internal/local/components/bunny-element';
import {property} from '../packages/__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../packages/__internal/local/helpers/StyleHelper';


@customElement('component-button')
export class ComponentButton extends BunnyElement {
    @property({type: Boolean, reflect: true})
    raised: boolean = true;

    @property({type: Boolean, reflect: true})
    disabled = false;

    // language=SCSS
    static override styles = [
        scss`
            :host {
                display: inline-block;
                position: relative;
                min-width: 100px;
                border-radius: 3px;
                overflow: hidden;
                --padding: 0.7em 0.57em;
                background-color: var(--primary-color);
                color: white;
                cursor: pointer;
                text-align: center;
                vertical-align: middle;
                box-sizing: border-box;
                text-transform: uppercase;
                height: fit-content;
            }

            button {
                display: inherit;
                padding: var(--padding);
                border: none;
                width: 100%;
                color: inherit;
                background: none;
                text-transform: inherit;
                cursor: inherit;
                height: 100%;
                font-weight: inherit;
                text-align: inherit;
                font-size: inherit;
                line-height: inherit;
                font-family: inherit;
                align-items: inherit;
            }

            :host([raised]) {
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
            }


            :host([disabled]) {
                pointer-events: none;
                background: #eaeaea;

                > * {
                    filter: grayscale(1);
                    opacity: .6;
                }
            }

            :host([disabled][raised]) {
                box-shadow: none;
            }

            component-ripple {
                --ripple-color: rgba(128, 128, 128, .25);
            }
        `,
    ];

    override render() {
        return html`
            <button @click="${this.handleFormSubmit}">
                <slot></slot>
            </button>

            <component-ripple></component-ripple>
            <component-tracking-interactable .trackVisibility="${false}"></component-tracking-interactable>
        `;
    }

    handleFormSubmit() {
        let form = this.parentElement as HTMLFormElement;
        if (!(form instanceof HTMLFormElement)) return;
        if (!form.reportValidity()) return;

        form.dispatchEvent(new CustomEvent('submit', {bubbles: true, composed: true}));
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'component-button': ComponentButton;
    }
}
