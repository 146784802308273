import {customElement, query, queryAll} from 'lit/decorators.js';
import {ElasticsearchQueryRequest} from '../../../elasticsearch/shared/helpers/ElasticsearchHelper';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper';
import {nodeParentSelectorSearch} from '../../../__internal/local/helpers/DomHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html, PropertyValues} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {ComponentInput} from '../../../inputs/local/components/component-input';

interface Search {
    name: string;
    searchId: string;
    allowSelectionOfSearchQuery?: boolean;
    valueFormat?: any;
    renderer?: object;
}

@customElement('component-input-elasticsearch-select')
class ComponentInputElasticsearchSelect extends BunnyElement {

    @property({type: Boolean, reflect: true})
    hasValue = false;

    @property({type: String})
    label: string;

    @property({type: Object, notify: true})
    value: any;

    @property({type: Array})
    internalValue: any[] = [];

    @property({type: Boolean, reflect: true})
    multiple: boolean = false;

    @property({type: Boolean})
    readonly: boolean = false;

    @property({type: String})
    placeholder: string;

    @property({type: String, notify: true})
    searchValue: string = '';

    @property({type: String})
    searchId: string;

    @property({type: Object})
    selectedSearch: Search;

    @property({type: Object})
    response: any;

    @property({type: String})
    stringCreationField: string | undefined = undefined;

    @property({type: String})
    searches: {
        [key: string]: Search
    };

    @computed('response')
    @property({type: Array})
    get results(): any[] {
        return this.response.hits.hits.map((_: any) => {
            let ret = _._source;

            Object.defineProperty(ret, '_hit', {value: _});

            return ret;
        });
    }

    @computed('response')
    @property({type: Array})
    get aggregations(): any[] {
        let ret = [];
        let aggs = this.response.aggregations;

        for (let aggregationName in aggs) {
            let buckets = aggs[aggregationName].buckets;

            for (let bucket of buckets) {
                let aggResult = {
                    [aggregationName]: bucket.key,
                    key: bucket.key,
                    aggregation: aggregationName,
                };

                Object.defineProperty(aggResult, '_bucket', {value: bucket});
                Object.defineProperty(aggResult, '_aggregation', {value: aggs[aggregationName]});

                ret.push(aggResult);
            }
        }

        return ret;
    }

    @computed('response', 'aggregations')
    @property({type: Array})
    get totalResults(): any[] {
        return this.response.hits.total.value + this.aggregations.length;
    }

    @property({type: Object})
    @computed('searchId', 'searchValue')
    get query(): ElasticsearchQueryRequest | undefined {
        if (!this.searchId) return undefined;

        return {
            id: this.searchId,
            params: {
                q: this.searchValue.trim() + '*',
            },
        };
    };

    renderer: object = {
        component: 'component-button',
        properties: {
            innerHTML: ':highlightedResult.firstName: :highlightedResult.lastName:',
        },
    };

    @property({type: Boolean})
    allowSelectionOfSearchQuery: boolean = false;

    @property({type: Object})
    valueFormat: any = {
        document: ':result._ref:',
        content: ':result.firstName: :result.lastName:',
    };

    @computed('query', 'totalResults', 'searchValue')
    get queryAsValue() {
        return {
            query: this.query,
            content: `${this.totalResults} search result(s) for ${this.searchValue}`,
        };
    }

    @property({type: Boolean, reflect: true})
    focused: boolean = false;

    @queryAll('#searchResults>*, #searchResults #searchResultsSelectQuery')
    searchResultsElements: NodeListOf<HTMLElement>;

    @property({type: Boolean})
    loading = false;

    @query('#input')
    inputElement: ComponentInput;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;
            }

            :host[hidden] {
                display: none !important;
            }

            .chipItem {
                display: inline-block;
                margin: 2px;
                cursor: pointer;
                max-width: 100%;
                overflow: hidden;
                vertical-align: top;
                background: var(--primary-text-color);
                color: white;
                padding: 4px 8px;
                border-radius: 10px;
            }

            component-icon {
                --iron-icon-height: 20px;
                --iron-icon-width: 20px;
                color: var(--disabled-text-color);
            }

            #searchResultsContainer .resultHighlight {
                font-weight: bold;
                @apply(--input-elasticsearch-select-result-highlight);
            }

            #searchResultsContainer .resultHighlight + .resultHighlight {
                margin-left: 3px;
            }

            #searchResultsContainer .itemSelected {
                display: none;
            }

            #searchResultsOuter {
                box-shadow: 0 1px 3px rgba(32, 32, 32, .2);
                position: absolute;
                top: 100%;
                left: 0;
                margin-top: 2px;
                max-width: 100%;
                background-color: white;
                z-index: 1;
                transition: .125s;
                transform-origin: top;
                transform: scaleY(0);
                width: 400px;
            }

            #searchResultsOuter h2 {
                background-color: var(--primary-text-color);
                color: white;
                padding: 3px 10px;
                margin-bottom: 0;
                line-height: 40px;
            }

            #searchResultsOuter {
                display: flex;
                overflow: hidden;

                #searchesSelection {
                    component-button {
                        width: 100%;

                        + component-button {
                            margin-top: 5px;
                        }
                    }
                }
            }

            #searchResultsOuter > * {
                width: 100%;
                flex-shrink: 0;
            }

            #searchResultsOuter [data-has-search-selected="1"] {
                display: none;
            }

            :host([focused]) #searchResultsOuter {
                transform: scaleY(1);
            }

            #searchResultsOuter #searchResults component-button {
                display: block;
                text-align: left;
                background: white;
                color: var(--primary-text-color);
                border-radius: 0;
                margin-bottom: 1px;
            }

            :host(:not([focused])) #searchResultsContainer > * {
                display: none;
            }

            :host([multiple]) component-input {
                --input-container-flex-direction: column;
                --input-container-align-items: normal;
            }
        `,
    ];

    override render() {
        return html`
            <component-elasticsearch-query .loading="${this.bind.loading}"
                                           .query="${this.query}"
                                           .response="${this.bind.response}"></component-elasticsearch-query>


            <!--                         on-keydown="_onInputKeydown"-->
            <component-input id="input"
                             style="--input-container-overflow: visible; --label-overflow: visible;"
                             .label="${this.label}"
                             .placeholder="${this.placeholder}"
                             @blur="${this._blur}"
                             ?readonly="${this.readonly}"
                             .value="${this.bind.searchValue}"
                             @focus="${this._focus}"
                             .hasValue="${this.hasValue}">
                <div slot="prefix">
                    ${this.internalValue.map(item => html`
                        <div selectable="" class="chipItem">
                            ${item.content}
                            ${!this.readonly ? html`
                                <component-icon icon="icons:cancel"
                                                @click="${(_: MouseEvent) => this.removeValue(item)}"></component-icon>
                            ` : undefined}
                        </div>
                    `)}
                </div>
                <div slot="suffix">
                    <component-loading .loading="${this.loading}"></component-loading>
                    <div id="searchResultsOuter">
                        ${this.searches ? html`
                            <div id="searchesSelection" data-has-search-selected="${this.selectedSearch ? 1 : 0}">
                                <h2>Searches</h2>
                                ${Object.entries(this.searches).map(([key, item]) => html`
                                    <component-button @click="${this.selectSearch}" .search="${key}">
                                        ${item.name}
                                    </component-button>
                                `)}
                            </div>
                        ` : undefined}

                        <div id="searchResultsContainer">
                            ${this.searches ? html`
                                <h2>
                                    <component-button @click="${this.selectSearch}"
                                                      style="margin-top: -3px; margin-left: -10px; margin-bottom: -3px; --padding: 3px; min-width: 46px; vertical-align: bottom; border-radius: 0">
                                        <component-icon icon="icons:chevron-left"></component-icon>
                                    </component-button>
                                    ${this.selectedSearch?.name}
                                </h2>
                            ` : undefined}

                            <div id="searchResults">
                                ${this.dodoRenderResults(this.results, this.aggregations, this.renderer, this.valueFormat)}
                            </div>

                            ${this.stringCreationField ? html`
                                ${this.searchValue ? html`
                                    <component-button @click="${this.createStringValue}">
                                        Create "${this.searchValue}"
                                    </component-button>
                                ` : undefined}
                            ` : undefined}

                            ${this.searchValue ? html`
                                ${!this.loading ? html`
                                    ${this.totalResults ? html`
                                        <component-button id="searchResultsSelectQuery"
                                                          ?disabled="${!this.allowSelectionOfSearchQuery}"
                                                          .value="${this.queryAsValue}"
                                                          @click="${this.selectItem}">
                                            <span class="resultHighlight">${this.totalResults}</span>
                                            &nbsp;search result(s) for&nbsp;
                                            <span class="resultHighlight">${this.searchValue}</span>
                                        </component-button>
                                    ` : html`
                                        <component-button disabled>
                                            <span class="resultHighlight">No</span>
                                            &nbsp;search results for&nbsp;
                                            <span class="resultHighlight">${this.searchValue}</span>
                                        </component-button>
                                    `}
                                ` : undefined}
                            ` : undefined}
                        </div>
                    </div>
                </div>
            </component-input>
        `;
    }

    constructor() {
        super();

        this.tabIndex = -1;
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);


        this._allowValuePopulating = false;
        if (this.searches) {
            for (let search of Object.values(this.searches)) {
                if (!('allowSelectionOfSearchQuery' in search)) {
                    search.allowSelectionOfSearchQuery = this.allowSelectionOfSearchQuery;
                }
                if (!('valueFormat' in search)) {
                    search.valueFormat = this.valueFormat;
                }
                if (!('renderer' in search)) {
                    search.renderer = this.renderer;
                }
                // if(!('searchId' in search)){
                //     search.searchId = this.searchId;
                // }
            }
        }

        queueMicrotask(() => {
            this._allowValuePopulating = true;
        });
    }

    selectSearch(e: CustomEvent) {
        let search = (e.currentTarget as any).search;
        this.selectedSearch = this.searches[search];
    }

    @observe('selectedSearch')
    updateSearchSelection(selectedSearch: Search) {
        if (!selectedSearch) return;

        this.searchId = selectedSearch.searchId;
        this.allowSelectionOfSearchQuery = selectedSearch.allowSelectionOfSearchQuery as boolean;
        this.valueFormat = selectedSearch.valueFormat;
        this.renderer = selectedSearch.renderer as object;
    }

    @bind()
    selectItem(e: CustomEvent) {
        let value = (e.currentTarget as any).value;

        if (!this.multiple) {
            this.internalValue = [];
        }

        this.internalValue.push({...value});
        this.requestUpdate('internalValue');
    }

    dodoRenderResults(results: any, aggregations: any, renderer: any, valueFormat: any) {
        results = results || [];
        aggregations = aggregations || [];

        let ret = [];
        let stringCopyOfRenderer = JSON.stringify(renderer).replace(/~~/g, ':')
            .replace(/paper-item/g, 'component-button');//TODO remove temp work around to replace paper-items
        let stringCopyOfValueFormat = JSON.stringify(valueFormat).replace(/~~/g, ':')
            .replace(/paper-item/g, 'component-button');//TODO remove temp work around to replace paper-items

        for (let result of [...results, ...aggregations]) {
            let highlightedResult = {...result};
            let highlight = result._hit?.highlight;

            if (highlight) {
                for (let field in highlight) {
                    highlightedResult[field] = highlight[field][0];//todo look into this when there is multiple results in the highlighter??
                    //probably have to check each word
                }
            }

            ret.push(createComponent(
                {
                    ...JSON.parse(stringCopyOfRenderer),
                    events: [{click: this.selectItem}],
                },
                {result: result, highlightedResult: highlightedResult},
                {
                    // onclick: this.selectItem,
                    value: JSON.parse(stringCopyOfValueFormat),
                },
                true,
            ));
        }

        queueMicrotask(() => {
            this.updateResultsVisibility(this.internalValue);
        });

        return ret;
    }


    @observe('internalValue')
    private updateResultsVisibility(internalValue: any[]) {
        let results: any[] = [...this.searchResultsElements];


        let internalValueFlat = internalValue.map(_ => JSONStringify(_));

        for (let result of results) {
            if (!result) continue;

            if (result.value !== result._flatValueFrom) {
                Object.defineProperties(result, {
                    _flatValueFrom: {
                        value: result.value,
                        configurable: true,
                    },
                    _flatValue: {
                        value: JSONStringify(result.value),
                        configurable: true,
                    },
                });
            }


            let exists = internalValueFlat.includes(result._flatValue);
            result.classList.toggle('itemSelected', exists);
        }
    }

    _blur(e: FocusEvent) {
        let lostFocusTo = e.relatedTarget as Element;
        if (lostFocusTo) {
            let parent = nodeParentSelectorSearch(lostFocusTo, 'component-input-elasticsearch-select');
            if (parent) {
                (e.currentTarget as ComponentInput).nativeInput.focus();
                return;
            }
        }


        this.focused = false;
        this.searchValue = '';
    }

    _focus(_e: FocusEvent) {
        this.focused = true;
    }

    removeValue(value: string) {
        if (this.value === null) return;


        let valueIndex = this.internalValue.indexOf(value);
        if (valueIndex > -1) {
            this.internalValue.splice(valueIndex, 1);
            this.requestUpdate('internalValue');
        }

        this.focused = true;
    }

    private _allowInternalValuePopulating: boolean = true;
    private _allowValuePopulating: boolean = true;

    @observe('internalValue', 'multiple')
    updateValue(internalValue: any[], multiple: boolean) {
        if (!this._allowValuePopulating) return;


        this._allowInternalValuePopulating = false;
        if (multiple) {
            this.value = internalValue;

        } else {
            this.value = internalValue[0] || null;
        }
        this.performUpdate();
        this._allowInternalValuePopulating = true;
    }

    @observe('value', 'multiple')
    updateInternalValue(value: any, multiple: boolean) {
        if (!this._allowInternalValuePopulating) return;


        if (!value) {
            value = [];

        } else if (!multiple) {
            value = [value];

        } else if (multiple && !Array.isArray(value)) {
            value = [value];
        }

        this._allowValuePopulating = false;
        this.internalValue = value || [];
        this.performUpdate();
        this._allowValuePopulating = true;
    }

    createStringValue() {
        if (!this.stringCreationField) return;


        if (!this.multiple) {
            this.internalValue = [];
        }

        let foundMatchingValue = this.internalValue.find(_ => _[this.stringCreationField as string] === this.searchValue);
        if (!foundMatchingValue) {
            this.internalValue.push({
                [this.stringCreationField]: this.searchValue,
            });
            this.requestUpdate('internalValue');
        }


        this.searchValue = '';
    }

    @observe('internalValue', 'searchValue')
    updateHasValue(internalValue: any[], searchValue: string) {
        this.hasValue = (!!searchValue) || (!!internalValue.length);

        //hack to force the input to actually have the corrent hasValue
        queueMicrotask(() => {
            this.inputElement.hasValue = this.hasValue;
        });
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-elasticsearch-select': ComponentInputElasticsearchSelect;
    }
}