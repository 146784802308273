export const loadTrackingLibrary = (url: string) => {
    try {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        // script.defer = true;

        let success: () => void;
        let fail: () => void;
        let ret = new Promise<void>((s, f) => {
            success = s;
            fail = f;
        });
        (ret as any).script = script;


        script.onload = () => success();
        script.onerror = () => fail();

        document.head.append(script);

        return ret;

    } catch (e) {
        console.warn('Failed initing tracking library', url, e);

        return Promise.reject();
    }
};