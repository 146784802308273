import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import {ADMIN_PACKAGE_ID} from '../../admin/shared';
import Package from '../../__internal/shared/Package';
import {FORM_PACKAGE_ID} from '../../form/shared';

export const MENU_PACKAGE_ID = 'menu';

export class MenuPackage extends Package {
    id: string = MENU_PACKAGE_ID;
    name: string = 'Menu';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, FORM_PACKAGE_ID, ADMIN_PACKAGE_ID];
}