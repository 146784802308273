import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {scss} from '../helpers/StyleHelper';
import {BunnyElement} from './bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper';


@customElement('component-toast')
class ComponentToast extends BunnyElement {

    @property()
    heading?: string;

    @property({type: Boolean, reflect: true})
    shown = false;

    @property({type: Boolean, reflect: true})
    dismissable = true;

    @property({type: Number})
    autoDismiss = 50000;

    private _autoDismissTimeoutId: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                --translateX: -50%;
                display: flex;
                background: var(--primary-text-color);
                color: white;
                padding: 15px 23px;
                position: fixed;
                bottom: 25px;
                left: 50%;
                transform: translateX(var(--translateX)) translateY(200%);
                opacity: 0;
                transition: .25s;
                z-index: 2147483647;
                border-radius: 25px;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 0 8px 2px rgba(255, 255, 255, .6);
                --auto-dismiss: 0;
                align-items: center;
                max-width: min(80vw, 400px);
                line-height: 1.1;
                min-width: 200px;

                @media screen and (max-width: 765px) {
                    left: 15px;
                    bottom: 15px;
                    max-width: calc(100vw - 30px);
                    --translateX: 0;
                }
            }

            h3 {
                margin-bottom: 0;
            }

            :host([shown]) {
                opacity: 1;
                transform: translateX(var(--translateX));
            }

            :host(.dismiss) {
                transform: translateX(var(--translateX)) scale(0);
            }

            @keyframes circleDismiss {
                from {
                    stroke-dashoffset: 0;
                }
                to {
                    stroke-dashoffset: -252;
                }
            }

            component-button {
                min-width: 0;
                width: 32px;
                height: 32px;
                border-radius: 100px;
                vertical-align: middle;
                margin: -8px -14px -5px 15px;
                text-align: center;
                --padding: 0;
                font-weight: bold;
                position: relative;
                background: var(--primary-color) none;
                color: white;
                overflow: visible;
                display: none;
                flex-shrink: 0;

                svg {
                    position: absolute;
                    inset: -4.5px;
                    border-radius: 100%;
                    overflow: hidden;
                    width: 41px;
                    height: 41px;

                    circle {
                        stroke: var(--attention-color);
                        rotate: -90deg;
                        transform-origin: center center;
                        stroke-width: 10px;
                        stroke-dasharray: 252 252;
                        stroke-linecap: round;
                        animation: circleDismiss calc(var(--auto-dismiss) * 1ms) linear forwards;
                    }
                }
            }

            :host([dismissable]) component-button {
                display: inline-block;
            }

            slot[name="actions"] {
                display: block;

                &::slotted(:first-child) {
                    margin-top: 10px;
                }
            }
        `,
    ];

    override render() {
        return html`
            <div style="flex: 1">
                ${this.heading ? html`
                    <h3>${this.heading}</h3>
                ` : ''}
                <slot></slot>
                <slot name="actions"></slot>
            </div>
            <component-button @click="${this.dismiss}">
                X

                <svg viewBox="0 0 100 100" reserveAspectRatio="xMidYMid slice" role="img">
                    <circle r="40" cx="50" cy="50" fill="transparent"></circle>
                </svg>
            </component-button>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        requestAnimationFrame(() => {
            this.shown = true;
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        clearTimeout(this._autoDismissTimeoutId);
    }

    dismiss() {
        this.classList.add('dismiss');
        this.shown = false;
    }

    @observe('autoDismiss')
    reflectAutoDismissToCssProperty(autoDismiss: number) {
        this.style.setProperty('--auto-dismiss', `${autoDismiss}`);
    }

    @observe('autoDismiss')
    startAutoDismiss(autoDismiss: number) {
        if (!autoDismiss || !this.dismissable) return;

        this._autoDismissTimeoutId = setTimeout(() => {
            this.dismiss();
        }, autoDismiss);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-toast': ComponentToast;
    }
}