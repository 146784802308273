import {customElement, query} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {MediaReferenceField} from '../../../media/shared/helpers/FirebaseHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {ShopProductDocument} from '../../shared/helpers/FirebaseHelper';
import {ComponentInputNumber} from '../../../inputs/local/components/component-input-number';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-data-collection-list-item-cell-cart-item')
class ComponentDataCollectionListItemCellCartItem extends ComponentFirestoreCollectionListItemCell {

    @property({type: Number})
    @computed('item')
    get initialValue() {
        return this.item.quantity;
    }

    @property({type: Boolean})
    updateButtonVisible: boolean = false;

    @property({type: Array})
    renderFormSubmissionKeys: string[] = [];

    @property({type: Object})
    @computed('item')
    get product() {
        if (!this.item.product.path) return undefined;

        return new FirestoreDocument<ShopProductDocument>(this, this.item.product.path, {method: FetchMethod.CACHE_FIRST});
    };


    private initialQuantity: number;

    @property({type: Array})
    @computed('item', 'renderFormSubmissionKeys')
    get additionalDetails() {
        let formSubmission = this.item.formSubmission;
        if (!formSubmission) return [];


        let ret = [];
        for (let key in formSubmission) {
            if (!this.renderFormSubmissionKeys.includes(key)) continue;

            ret.push(`${key}: ${formSubmission[key]}`);
        }


        return ret;
    };

    private incrementQuantityUpdateTimeoutId: number;

    @query('#quantity')
    quantityElement: ComponentInputNumber;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: flex;
            }

            .product {
                display: flex;
                flex: 1;
            }

            .product component-media-view-image {
                max-width: 150px;
                width: 30%;
                margin-right: 15px;
                margin-bottom: auto;
                flex-shrink: 0;
                background-color: #f2f2f2;
                position: relative;
                box-shadow: 0 1px 0 0 rgba(142, 146, 135, .3);
            }

            @media screen and (max-width: 600px) {
                :host {
                    flex-direction: column;
                }

                .itemControls {
                    margin-left: 0 !important;
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top: solid rgba(32, 32, 32, .05) 1px;
                }
            }

            ul {
                font-size: 85%;
                margin-top: 5px;
                margin-bottom: 5px;
                padding-left: 25px;
                list-style: disc;
                text-transform: capitalize;
            }


            component-button {
                --padding: 3px;
                font-size: 14px;
                margin: 0;
                min-width: 0;
            }

            component-input-number component-button {
                vertical-align: middle;
            }


            .productPrice:after {
                content: 'Each';
            }

            .subTotal:after {
                content: 'Total';
            }

            component-input-number {
                --input-container-border-radius: 0;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input {
                    text-align: center;
                    padding: 8px 0;
                    -moz-appearance: textfield;
                }
            }
        `,
    ];

    override render() {
        return html`
            <a href="/shop/products/${this.item.product.id}" class="product">
                <component-media-view-image .media="${this._findThumbnail(this.product?.data?.productImages)}"
                                            type="default-thumbnail"
                                            fetch-method="cacheFirst"
                                            .width="${400}"
                                            .height="${300}"></component-media-view-image>

                <div>
                    <resolve-firestore-document-name path="${this.item.productVariant.path}"
                                                     format="::name::"
                                                     fetch-method="cacheFirst"
                                                     style="line-height: 1.2"
                    ></resolve-firestore-document-name>
                    ${(this.additionalDetails || []).length ? html`
                        <ul id="additionalDetails">
                            ${this.additionalDetails.map(item => html`
                                <li>${item}</li>
                            `)}
                        </ul>
                    ` : undefined}
                </div>
            </a>


            <div style="flex-shrink: 0; text-align: right; margin-left: 15px" class="itemControls">
                <div>
                    <component-firestore-collection-list-item-cell class="productPrice"
                                                                   .item="${this.item}"
                                                                   field="price"
                                                                   type="currency"
                    ></component-firestore-collection-list-item-cell>
                    <component-firestore-collection-list-item-cell class="subTotal"
                                                                   .item="${this.item}"
                                                                   field="subTotal"
                                                                   type="currency"
                                                                   style="font-weight: bold"
                    ></component-firestore-collection-list-item-cell>
                </div>

                <div>
                    <component-input-number .value="${this.bindDeep('item.quantity')}"
                                            label="Quantity"
                                            @change="${this.showUpdateButton}"
                                            @keydown="${this.onQuantityKeyDown}"
                                            @blur="${this.updateQuantity}"
                                            @focus="${this.focusQuantity}"
                                            class="borderBottomOnly"
                                            style="width: 95px; text-align: center; margin-top: 10px; margin-bottom: 5px;"
                                            .min="${0}"
                                            id="quantity">
                        <component-button
                                style="padding: 0; width: 30px; height: 30px; background: white; color: var(--primary-text-color)"
                                @mousedown="${(e: MouseEvent) => this.incrementQuantity(e, -1)}"
                                slot="prefix"
                                .raised="${false}">
                            <component-icon icon="icons:chevron-left">
                            </component-icon>
                        </component-button>
                        <component-button
                                style="padding: 0; width: 30px; height: 30px; background: white; color: var(--primary-text-color)"
                                @mousedown="${(e: MouseEvent) => this.incrementQuantity(e, 1)}"
                                slot="suffix"
                                .raised="${false}">
                            <component-icon icon="icons:chevron-right">
                        </component-button>
                    </component-input-number>

                    ${this.updateButtonVisible ? html`
                        <component-button @click="${this.updateQuantity}"
                                          style="background: white; color: var(--attention-color)"
                                          .raised="${false}">
                            Update
                        </component-button>
                    ` : html`
                        <component-button @click="${this.removeShopProduct}"
                                          style="background: white; color: var(--attention-color)"
                                          .raised="${false}">
                            Remove
                        </component-button>
                    `}
                </div>
            </div>
        `;
    }

    _formatCurrency(value: number) {
        return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(value / 100);
    }

    onQuantityKeyDown(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            this.updateQuantity();
            return;
        }

        this.showUpdateButton();
    }

    showUpdateButton() {
        this.updateButtonVisible = true;
    }

    @observe('item')
    loadInitialQuantity(item: any) {
        this.initialQuantity = item.quantity;
    }

    updateQuantity() {
        this.updateButtonVisible = false;

        if (this.initialQuantity === this.item.quantity) return;


        let oldValue = this.initialQuantity;
        let me = this;
        this.dispatchEvent(new CustomEvent('shop-product-set-quantity', {
            bubbles: true,
            detail: {
                variant: {_ref: {ref: this.item.productVariant}},
                formSubmission: this.item.formSubmission,
                quantity: this.item.quantity,
                callback(success: boolean) {
                    if (!success) {
                        me.quantityElement.value = oldValue;
                    }
                },
            },
            composed: true,
        }));
    }

    focusQuantity() {
        let nativeInput = this.quantityElement.nativeInput as HTMLInputElement;

        nativeInput.type = 'text';
        nativeInput.setSelectionRange(0, nativeInput.value.length);
        nativeInput.type = 'number';
    }

    incrementQuantity(_e: MouseEvent, incrementValueBy: number) {
        let input = this.quantityElement;

        input.value += incrementValueBy;
        input.dispatchEvent(new Event('change'));


        clearTimeout(this.incrementQuantityUpdateTimeoutId);
        this.incrementQuantityUpdateTimeoutId = window.setTimeout(this.updateQuantity.bind(this), 1000);
    }

    removeShopProduct() {
        let item = this.item;
        this.dispatchEvent(new CustomEvent('shop-product-remove', {
            bubbles: true,
            detail: {
                variant: {_ref: {ref: item.productVariant}},
                formSubmission: item.formSubmission,
                currentQuantity: item.quantity,
            },
            composed: true,
        }));
    }

    _findThumbnail(productImages?: MediaReferenceField[]) {
        return (productImages || []).find(_ => _.usages?.includes('thumbnail'));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-cart-item': ComponentDataCollectionListItemCellCartItem;
    }
}
