import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-aspire-comps-product-countdown-timer')
class ComponentAspireCompsProductCountdownTimer extends BunnyElement {

    @property({type: Object})
    endDate:Date;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host{
                color: white;
                text-align: center;
                text-transform: uppercase;
                overflow: hidden;
                padding: 0!important;
            }
        `
    ];

    override render(){
        return html`
            <div class="angledContainer" style="--angled-indent: 30px">
                <h2 class="continueAngled" style="background-color: #191919; margin: 0; padding: 5px; box-shadow: 25vw 0 0 0 #191919;">
                    <span class="unangled">Competition ends in</span>
                </h2>
                <div class="continueAngled" style="background-color: #484848; box-shadow: 25vw 0 0 0 #484848;">
                    <component-countdown-timer style="margin-left: -55px" class="unangled resetCentering" .target="${this.endDate}"></component-countdown-timer>
                </div>
            </div>
            <div style="font-size: 12px; margin-top: 3px; color: var(--primary-text-color); text-transform: none">
                Competition will close early if the maximum entry count is reached
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-product-countdown-timer': ComponentAspireCompsProductCountdownTimer;
    }
}