import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ComponentElasticsearchCollectionList} from './component-elasticsearch-collection-list';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-elasticsearch-collection-list-control-search')
class ComponentElasticsearchCollectionListControlSearch extends BunnyElement {

    @property({type: String})
    label: string = 'Search';

    @property({type: Object})
    list: ComponentElasticsearchCollectionList;

    @property({type: String, notify: true})
    @storageBoundQueryString('q')
    search: string = '';

    @property({type: Object, notify: true})
    searchParams: any = {};

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <component-input .value="${this.bind.search}" label="${this.label}"></component-input>
        `;
    }

    @observe('search')
    forceUpdateSearchIntoList(search: string) {
        this.searchParams = {
            ...this.searchParams,
            searchFilter: {
                query_string: {
                    query: search.replace(/([a-z0-9])$/gi, '$1*') || '*',
                    default_operator: 'AND',
                },
            },
        };
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-elasticsearch-collection-list-control-search': ComponentElasticsearchCollectionListControlSearch;
    }
}