import {format, formatDistanceToNow} from 'date-fns';

const formatTwoDigits = (_: number) => {
    return _.toString().padStart(2, '0');
};

export const RenderingHelper = {
    _currencyFormat(value: number) {
        return (value / 100).toFixed(2);
    },

    _timestampToDate(value?: null | { toDate: () => Date }) {
        if (!value) return '';

        let date = value.toDate();
        return `${formatTwoDigits(date.getDate())}/${formatTwoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
    },

    _timestampToTime(value?: null | { toDate: () => Date }) {
        if (!value) return '';

        let date = value.toDate();
        return `${formatTwoDigits(date.getHours())}:${formatTwoDigits(date.getMinutes())}`;
    },

    _timestampToTimeAgo(value?: null | { toDate: () => Date }) {
        console.warn('_timestampToTimeAgo is deprecated, use _dateFromNow instead');
        return RenderingHelper._dateFromNow(value);
    },

    _dateFromNow(value?: null | { toDate: () => Date } | Date) {
        if (!value) return 'Just now';

        let date = value instanceof Date ? value : value.toDate();
        return formatDistanceToNow(date, {addSuffix: true, includeSeconds: true}).replace('about', '').trim();
    },

    _dateFormat(value: null | undefined | { toDate: () => Date } | Date, formatString: string) {
        if (!value) return 'Just now';

        let date = value instanceof Date ? value : value.toDate();

        return format(date, formatString);
    },

    _ucFirst(val?: string) {
        if (!val) return val;

        return val[0].toUpperCase() + val.substr(1);
    },

    __getAccountPermissions(account: any) {
        if (!account) {
            account = {};

            Object.defineProperty(account, 'permissions', {
                value: [
                    'app.loggedout',
                ],
            });
        }

        return account.permissions || [];
    },

    _accountHasPermission(account: any, permission: string) {
        let accountPermissions = RenderingHelper.__getAccountPermissions(account);

        return accountPermissions.includes(permission);
    },

    _accountHasPermissions(account: any, permissions: string[]) {
        let accountPermissions = RenderingHelper.__getAccountPermissions(account);

        return RenderingHelper._accountPermissionsHasPermissions(accountPermissions, permissions);
    },

    _accountPermissionsHasPermissions(accountPermissions: string[], permissions: string[]) {
        for (let permission of permissions) {
            if (!accountPermissions.includes(permission)) return false;
        }

        return true;
    },

    _colorFromString(val: string) {
        val = val.toLowerCase().replace(/[^a-z0-9]/, '');
        let seed = parseInt(val, 36);

        return '#' + Math.floor((Math.abs(Math.sin(seed) * 16777215)) % 16777215).toString(16);
    },
};