import {customElement} from 'lit/decorators.js';
import {html} from 'lit/static-html.js';
import {spread} from '@open-wc/lit-helpers';
import {ComponentInput} from './component-input';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-input-textarea')
export class ComponentInputTextarea extends ComponentInput {


    // language=SCSS
    static override styles = [
        ...super.styles,
        scss`
            ::slotted(textarea) {
                resize: vertical;
            }
        `,
    ];

    get nativeInput(): any {
        return this.querySelector('textarea') as HTMLTextAreaElement as any;
    };

    renderInput() {
        return html`
            <textarea id="nativeInput"
                      ?autofocus="${this.autofocus}"
                      autocomplete="${this.autocomplete}"
                      ${spread(this.extraAttributes)}
                      @input="${this.onInput}"
                      @blur="${(e: FocusEvent) => this.forwardEvent('blur', e)}"
                      @focus="${(e: FocusEvent) => this.forwardEvent('focus', e)}"
                      @change="${this.onInput}"
                      ?required="${this.required}"
                      name="${this.name}"
                      ?disabled="${this.disabled}"
                      placeholder="${this.placeholder}"
                      .value="${this.convertValueToNative(this.value)}"
            ></textarea>
        `;
    }

    @observe('value')
    doAutoResize(_value: string) {
        let nativeInput = this.nativeInput;

        if (nativeInput) {
            nativeInput.style.height = 0;
            nativeInput.style.height = `${nativeInput.scrollHeight}px`;
        }
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'component-input': ComponentInput;
    }
}
