import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {config} from '../../../../config';

@customElement('component-shop-order-thank-you')
class ComponentShopOrderThankYou extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Boolean, reflect: true})
    @computed('route')
    get shown() {
        return this.route?.current.query?.paymentReturn === 'success';
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: none;
            }

            :host([shown]) {
                display: block;
            }


            .contentStep h2 {
                background-color: var(--primary-text-color);
                color: white;
                margin-left: -15px;
                margin-right: -15px;
                margin-top: -25px !important;
                padding: 15px;
                text-align: center;
            }

            .whatToExpectNext {
                padding-left: 0;
                margin-left: -14px;
                list-style: none !important;
            }

            .whatToExpectNext > li h4 {
                border-left: solid var(--primary-color) 5px;
                padding-left: 15px;
            }

            .whatToExpectNext > li p {
                margin-left: 20px;
                margin-bottom: 5px;
                line-height: 1.3;
            }

            .whatToExpectNext > li p:last-child {
                margin-bottom: 25px;
            }

            component-button {
                background-color: var(--primary-color);
                color: white;
                margin: 0;
            }
        `
    ];

    override render() {
        let social = config.site.social;

        return html`
            <div class="contentStep contentContainer" style="max-width: 700px; margin: auto; padding-bottom: 1px">
                <h2>
                    Thank you. Your order has been received.
                </h2>

                <h3>What to expect next?</h3>

                <ul class="whatToExpectNext">
                    <li>
                        <h4>When do I get my ticket numbers?</h4>
                        <p>
                            Your ticket numbers will be randomly allocated within a hour of your order, you will be
                            notified of this along with the ability to view your numbers on your
                            <a href="/entry-list/my-competitions">personal entry list</a>. Good luck!
                        </p>
                    </li>
                    <li>
                        <h4>How will I get notified of competition updates?</h4>
                        <p>
                            By default we send you notifications by email, but you can also enable other methods like
                            SMS.
                        </p>
                        <p>
                            <a href="/account/notifications/preferences"><small>
                                <component-button>Manage notification preferences</component-button>
                            </small></a>
                        </p>
                    </li>
                    <li>
                        <h4>When are the draws?</h4>
                        <p>
                            Live Draw: The live draw is available to watch and comment along over on Facebook or YouTube
                            at 7:30pm on Wednesdays. Like us on Facebook/Subscribe on YouTube to be notified when we
                            go live. You can always check if you were a winner any time after the draw by checking the
                            entry list.
                        </p>
                        <p>
                            Auto Draw: Auto draws are automatically drawn 1 hour after all tickets are closed or the
                            draw date ends, participants will be updated with the draw results, and the winners will be
                            posted on our winners page.
                        </p>
                        <p>
                            <a href="${social.facebook}">
                                <small>
                                    <component-button>Aspire Facebook</component-button>
                                </small>
                            </a>
                            <a href="${social.youtube}">
                                <small>
                                    <component-button>Aspire Youtube</component-button>
                                </small>
                            </a>
                            <a href="/entry-list">
                                <small>
                                    <component-button>Entry list</component-button>
                                </small>
                            </a>
                        </p>
                    </li>
                    <li>
                        <h4>How will I know who wins?</h4>
                        <p>
                            Live Draw: The winners will be drawn during the live stream on Facebook and YouTube at
                            7:30pm on
                            Wednesdays, be sure to like us on Facebook/Subscribe on YouTube to be notified when we go
                            live. We will always call the winner during the live but if you are busy will get in contact
                            with you after.
                        </p>
                        <p>
                            Auto Draw: Participants will be updated with the draw results via email, and the winners
                            will be posted on our winners page and contacted to arrange delivery of their prize.
                        </p>
                        <p>
                            <a href="${social.facebook}">
                                <small>
                                    <component-button>Facebook live</component-button>
                                </small>
                            </a>
                            <a href="${social.youtube}">
                                <small>
                                    <component-button>YouTube</component-button>
                                </small>
                            </a>
                            <a href="/entry-list">
                                <small>
                                    <component-button>Entry list</component-button>
                                </small>
                            </a>
                        </p>
                    </li>
                    <li>
                        <h4>Want to leave a review?</h4>
                        <p>
                            Your reviews really help. Please take time to
                            <a href="${social.trustpilot}">leave us a review</a> as this
                            really helps us build confidence and allows us to continue bringing you better competitions
                            every week.
                        </p>
                        <p>
                            <a href="${social.trustpilot}">
                                <small>
                                    <component-button>Trustpilot</component-button>
                                </small>
                            </a>
                        </p>
                    </li>
                </ul>
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-order-thank-you': ComponentShopOrderThankYou;
    }
}