import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element.ts';
import {sharedStyles} from '../../../../shared-styles.ts';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {NOTIFICATION_CHANNELS_INFO} from '../../shared/helpers/NotificationsHelper.ts';
import {adminCall} from '../../../admin/local/helpers/AdminHelper.ts';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';


@customElement('component-notifications-create-campaign-from-template')
class ComponentNotificationsCreateCampaignFromTemplate extends BunnyElement {

    @property({type: Boolean})
    opened = false;

    @property({type: String})
    campaignType = 'site-marketing';

    @property({type: Object})
    prePopulateFlags: { autoDraws?: boolean, newIn?: boolean, finishingThisWeek?: boolean, earlyBadger?: boolean } = {};

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: inline-block;
            }

            component-input-checkbox + component-input-checkbox {
                margin-top: 5px;
            }
        `,
    ];

    override render() {
        return html`
            <component-button-toggle .active="${this.bind.opened}">
                <slot></slot>
            </component-button-toggle>

            <component-dialog .opened="${this.bind.opened}">
                <h1 slot="heading">Template</h1>

                <div style="width: 400px; height: 300px">
                    <component-input-select
                            label="Template"
                            .value="${this.bind.campaignType}"
                            .options="${Object.entries(NOTIFICATION_CHANNELS_INFO.email)
                                    .filter(([_key, value]) => value.campaignable)
                                    .map(([key, value]) => ({
                                        value: key,
                                        label: value.name,
                                    }))}">
                    </component-input-select>

                    <h2>Pre population</h2>
                    <component-input-checkbox .checked="${this.bindDeep('prePopulateFlags.autoDraws')}">
                        Auto draws
                    </component-input-checkbox>
                    <component-input-checkbox .checked="${this.bindDeep('prePopulateFlags.newIn')}">
                        New in
                    </component-input-checkbox>
                    <component-input-checkbox .checked="${this.bindDeep('prePopulateFlags.finishingThisWeek')}">
                        Finishing this week
                    </component-input-checkbox>
                    <component-input-checkbox .checked="${this.bindDeep('prePopulateFlags.earlyBadger')}">
                        Early badger
                    </component-input-checkbox>
                </div>

                <component-button slot="footer" @click="${this.doClick}">
                    Create
                </component-button>
            </component-dialog>
        `;
    }

    @toastProgressWrapper()
    async doClick() {
        let campaign = await adminCall.notificationsCreateCampaign(this.campaignType, this.prePopulateFlags);
        HistoryHelper.pushState(`/admin/notifications/campaigns/${campaign.campaignId}`);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-create-campaign-from-template': ComponentNotificationsCreateCampaignFromTemplate;
    }
}