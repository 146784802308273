import {customElement} from 'lit/decorators.js';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {PageContentComponent} from '../../../routing/shared/helpers/FirestoreHelper';

@customElement('component-slideshow')
class ComponentSlideshow extends BunnyElement {

    @property({type: Number})
    ratio: number = 1;

    @property({type: Array})
    slides: any[] = [];

    activeSlide: number = 0;
    nextSlide: number;
    nextSlideProgress: number = 0;
    private _nextSlideIntervalId: number;
    paused: boolean = false;


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                --ratio: 1;
            }

            #slides {
                position: relative;
                padding-top: 40%;
                padding-top: calc(100% * var(--ratio));
            }

            #slides .slide {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: none;
            }

            #slides .slide.active {
                display: block;
            }

            #slides .slide > * {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                overflow: auto;
            }

            #navigation {
                display: flex;
                --loading: 0;
            }

            #navigation > * {
                width: 25%;
                min-width: 150px;
                max-width: 250px;
                padding: 10px;
                position: relative;
                transition: .25s;
                transition-property: box-shadow;
                cursor: pointer;
                line-height: 1.1;
            }

            #navigation > *:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 0;
                background-color: var(--primary-color);
                width: calc(100% * var(--loading));
                transition: .25s;
                transition-property: width, height;
                transition-timing-function: linear, ease-in-out;
            }

            #navigation > *.loading:after {
                height: 2px;
                opacity: .5;
            }

            #navigation > *:hover:after {
                height: 4px;
                opacity: 1;
            }

            #navigation > *:hover:after {
                width: 100%;
            }

            #navigation > *.active:after {
                width: 100%;
                height: 2px;
                background-color: var(--attention-color);
            }
        `,
    ];

    override render() {
        return html`
            <div id="slides">
                ${this.slides.map((slide: any) => html`
                    <div class="slide">
                        ${slide.components.map((slideComponent: PageContentComponent) => createComponent(slideComponent, (this as any).data))}
                    </div>
                `)}
            </div>

            <div id="navigation">
                ${this.slides.map((slide: any, slideIndex) => createComponent({
                    ...slide.navigation,
                    events: [
                        {'click': this.selectSlide.bind(this, slideIndex)},
                    ],
                }, (this as any).data))}
            </div>
        `;
    }

    @observe('ratio')
    applyRatio(ratio: number) {
        this.style.setProperty('--ratio', ratio as any as string);
    }

    @observe('activeSlide')
    onActiveSlideChanged(activeSlide: number) {
        let navigation = this.shadowRoot?.querySelector('#navigation') as HTMLElement;
        let slides = this.shadowRoot?.querySelector('#slides') as HTMLElement;

        slides.querySelector('.active')?.classList.remove('active');
        navigation.querySelector('.active')?.classList.remove('active');

        slides.children[activeSlide].classList.add('active');
        navigation.children[activeSlide].classList.add('active');


        if (activeSlide == this.slides.length - 1) {
            this.nextSlide = 0;

        } else {
            this.nextSlide = activeSlide + 1;
        }
    }

    selectSlide(slide: number) {
        this.activeSlide = slide;
    }

    @observe('nextSlideProgress')
    onNextSlideProgressChanged(nextSlideProgress: number) {
        this.shadowRoot?.querySelector<HTMLElement>('#navigation')?.style?.setProperty('--loading', nextSlideProgress as any as string);
    }

    @observe('nextSlide')
    onNextSlideChanged(nextSlide: number) {
        let navigation = this.shadowRoot?.querySelector('#navigation') as HTMLElement;
        navigation.querySelector('.loading')?.classList.remove('loading');
        navigation.children[nextSlide].classList.add('loading');
        this.nextSlideProgress = 0;

        clearInterval(this._nextSlideIntervalId);
        this._nextSlideIntervalId = setInterval(() => {
            if (this.paused) return;


            this.nextSlideProgress += 0.05;

            if (this.nextSlideProgress > 1) {
                clearInterval(this._nextSlideIntervalId);
                this.activeSlide = nextSlide;
            }
        }, 250) as any as number;
    }

    @listen('mouseover', 'slides')
    @listen('mouseover', 'navigation')
    onMouseOver(_e: Event) {
        this.paused = true;


        this.nextSlideProgress = Math.max(0, this.nextSlideProgress - 0.05);
    }

    @listen('mouseout', 'slides')
    @listen('mouseout', 'navigation')
    onMouseLeave(_e: Event) {
        this.paused = false;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-slideshow': ComponentSlideshow;
    }
}