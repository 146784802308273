export const parseQueryString = (paramString: string) => {
    let params: { [key: string]: string } = {};
    // Work around a bug in decodeURIComponent where + is not
    // converted to spaces:
    paramString = (paramString || '').replace(/\+/g, '%20');
    let paramList = paramString.split('&');

    for (let i = 0; i < paramList.length; i++) {
        let param = paramList[i].split('=');

        if (param[0]) {
            params[decodeURIComponent(param[0])] = decodeURIComponent(param[1] || '');
        }
    }

    return params;
};