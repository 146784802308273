import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-analytics-data-visualizer-list')
export class ComponentAnalyticsDataVisualizerList extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Array})
    fields: { name: string, style?: string, title: string }[];

    @property({type: Object})
    data: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            a.selected.selectedAsc:after {
                content: '▲';
                color: orange;
            }

            a.selected.selectedDesc:after {
                content: '▼';
                color: red;
            }

            .header > * {
                --col: 1;
                font-weight: bold;
            }
        `
    ];

    renderRow(item: any) {
        return html`Empty row:${JSON.stringify(item)}`;
    }

    override render(){
        return html`
            <div class="gridContainer row header">
                ${this.fields.map(item=>html`
                    <div style="${item.style}">
                        ${item.name ? html`
                            <a href="${this._generateAggOrderLink(this.route, 'params[itemsAggOrder][0]', item.name)}&chartSeries=Orders"
                               class="${this._generateAggOrderClass(this.route, 'params[itemsAggOrder][0]', item.name)}">
                                ${item.title}
                            </a>
                        ` : html`
                            ${item.title}
                        `}
                    </div>
                `)}
            </div>

            <div class="gridContainer row">
                ${this.data?.map((item: any)=>html`
                    ${this.renderRow(item)}
                `)}
            </div>
        `;
    }

    _generateAggOrderLink(route: Route, prefix: string, key: string) {
        key = `[${key}]`;
        let fullKey = `${prefix}${key}`;

        let currentQueryParams = {...route.current.query};
        let current: string[] = [];
        for (let i in currentQueryParams) {
            if (!i.startsWith(prefix)) continue;

            if (i.startsWith(fullKey)) {
                current = [i, currentQueryParams[i]];
            }

            delete currentQueryParams[i];
            break;
        }


        if (current.length) {
            if (current[1] === 'desc') {
                current[1] = 'asc';

            } else {
                current = [];
            }

        } else {
            current = [fullKey, 'desc'];
        }


        if (current.length) {
            currentQueryParams[current[0]] = current[1];
        }

        return '?' + Object.entries(currentQueryParams).map(_ => `${encodeURIComponent(_[0])}=${encodeURIComponent(_[1] as string)}`).join('&');
    }

    _generateAggOrderClass(route: Route, prefix: string, key: string) {
        key = `[${key}]`;
        let fullKey = `${prefix}${key}`;
        let currentDirection = route.current.query[fullKey];

        if (currentDirection) {
            return `selected selected${currentDirection === 'asc' ? 'Asc' : 'Desc'}`;
        }

        return '';
    }


    _round(val: number) {
        return parseFloat(val.toFixed(2));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-data-visualizer-list': ComponentAnalyticsDataVisualizerList;
    }
}