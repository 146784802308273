import {customElement} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';

@customElement('component-dialogable-link')
class ComponentDialogableLink extends BunnyElement {

    @property({type: String})
    href: string;

    @property({type: String})
    title: string;

    @property({type: Boolean})
    opened: boolean = false;

    @property({type: String})
    target: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-dialog {
                --content-padding: 0;
                --footer-padding: 0;
            }

            iframe {
                width: 90vw;
                height: 90vh;
            }
        `,
    ];


    override render() {
        return html`
            <a href="${this.href}" title="${this.title}" target="${this.target}" @click="${this.toggleOpen}">
                <slot></slot>
            </a>

            ${this.opened ? html`
                <component-dialog .opened="${this.bind.opened}">
                    <h3 slot="heading">${this.title}</h3>
                    <iframe src="${this.href}" @load="${this.frameLoaded}" frameborder="0"></iframe>
                </component-dialog>
            ` : undefined}
        `;
    }

    toggleOpen(e: MouseEvent) {
        e.preventDefault();

        this.opened = !this.opened;
    }

    frameLoaded(e: any) {
        let frameDocument = e.currentTarget.contentDocument;
        this.title = frameDocument.title;

        setTimeout(() => {
            this.title = frameDocument.title;
        }, 500);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-dialogable-link': ComponentDialogableLink;
    }
}