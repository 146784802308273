import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';


export const GOOGLE_PACKAGE_ID = 'google';

export class GooglePackage extends Package {
    id: string = GOOGLE_PACKAGE_ID;
    name: string = 'Google';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];
}