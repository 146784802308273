import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {createComponent} from '../helpers/DomHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FormElementDocument} from '../../../form/shared/helpers/FirestoreHelper';

@customElement('component-page-editor-content-multi-type')
class ComponentPageEditorContentMultiType extends BunnyElement {

    @property({type: String})
    label: string;

    @property({type: Object, notify: true})
    value: any;

    @property({type: Object})
    elements: Record<string, FormElementDocument>;

    @property({type: String})
    selectedElementKey: string;

    @property({type: Object})
    data: any;

    @property({type: String})
    @computed('selectedElementKey')
    get selectedElement() {
        return this.elements[this.selectedElementKey];
    }

    static override styles = [
        // language=SCSS
        scss`
        `];


    override render() {
        return html`
            <h3 style="display: flex; margin-bottom: 0">
                <span style="margin-top: auto; margin-bottom: auto; flex: 1">
                    ${this.label}
                </span>


                <component-input-select .value="${this.bind.selectedElementKey}"
                                        style="margin-left: auto; width: 175px">
                    ${Object.entries(this.elements).map(([key, value]) => html`
                        <component-input-select-item .value="${key}">
                            ${value.properties?.label}
                        </component-input-select-item>
                    `)}
                </component-input-select>
            </h3>

            <div id="element">
                ${this.selectedElement ? createComponent({
                    ...this.selectedElement as any,
                    events: [{
                        'value-changed': (e: CustomEvent) => {
                            this.value = e.detail.value;
                        },
                    }],
                }, this.data, {value: this.value}) : undefined}
            </div>
        `;
    }

    _ignoreSelfApplyFormFieldNameChange: boolean;

    @observe('elements')
    setInitialSelectedElementKey(elements: any) {
        for (let key in elements) {
            let outsideValue = (this as any).__formGroup.value[key];
            if (!outsideValue) continue;


            this._ignoreSelfApplyFormFieldNameChange = true;
            (this as any).__formElement.name = key;
            this.value = outsideValue;
            this.selectedElementKey = key;

            // this.value = outsideValue;
            this._ignoreSelfApplyFormFieldNameChange = false;
        }
    }

    @observe('selectedElementKey')
    selfApplyFormFieldNameChange(selectedElementKey: string) {
        if (this._ignoreSelfApplyFormFieldNameChange) return;


        //todo dont like this, need a better way to tell the parent to clean up its unused children
        for (let key in this.elements) {
            if (key === selectedElementKey) continue;

            delete (this as any).__formGroup.value[key];
            (this as any).__formGroup.requestUpdate('value');
        }


        (this as any).__formElement.name = selectedElementKey;

        //todo dont really like the double set to change just to notify
        // let value = this.value;
        this.requestUpdate('value');
        // this.notifyPath('value', '');
        // this.notifyPath('value', value);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-page-editor-content-multi-type': ComponentPageEditorContentMultiType;
    }
}