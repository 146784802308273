import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {doc, setDoc} from 'firebase/firestore';
import {FIRESTORE_COLLECTION_ACCOUNTS} from '../../shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';

@customElement('component-account-email-address')
class ComponentAccountEmailAddress extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    title: string = 'Change email address';

    @property({type: String})
    currentEmail: string;

    @property({type: String})
    email: string;

    @property({type: String})
    confirmEmail: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <div class="contentStep">
                ${this.title ? html`
                    <h2 style="margin-bottom: 0">${this.title}</h2>
                ` : undefined}

                <component-native-form @submit="${this.save}">
                    <p style="margin: 0">Current email address &middot; ${this.currentEmail}</p>

                    <component-input-email
                            label="Email address"
                            name="email"
                            required
                    ></component-input-email>
                    <component-input-email
                            label="Confirm email address"
                            name="confirmEmail"
                            required
                    ></component-input-email>

                    <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                        Submit
                    </button>

                    <div slot="submitButton" style="margin: 25px -15px -25px -15px;">
                        <component-button style="display: block; border-radius: 0">
                            Update email address
                        </component-button>
                    </div>
                </component-native-form>
            </div>
        `;
    }


    connectedCallback() {
        super.connectedCallback();

        this.loadCurrentEmail();
    }

    private loadCurrentEmail() {
        let user = this.auth.getInternalUser();
        if (!user) return;

        this.currentEmail = user.email as string;
    }

    @toastProgressWrapper({
        progressMessage: 'Updating email address',
        successMessage: 'Email address updated successfully',
        failedMessage: 'Failed updating email address: {{e}}',
    })
    @bind()
    async save(e: FormSubmitEvent) {
        let finalResponse = await e.detail.setResponse(async () => {
            let formData = e.detail.data;
            if (formData.email !== formData.confirmEmail) throw new FriendlyMessage('Email and confirm email do not match');

            debugger;
            await this.auth.updateEmail(formData.email);
            await setDoc(doc(FirestoreDocument.db, `${FIRESTORE_COLLECTION_ACCOUNTS}/${this.auth.user?.uid}/private/account`), {
                emailAddress: formData.email,
            });
            this.loadCurrentEmail();

            return 'Email address updated successfully';
        });

        if (finalResponse instanceof Error) {
            throw finalResponse;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-account-email-address': ComponentAccountEmailAddress;
    }
}