import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {ComponentButton} from '../../../../components/component-button';

const DEFAULT_SUCCESS_MESSAGE = 'Submitted successfully';

type FormSubmitEventData = {
    data: Record<string, any>;
    setResponse(_response: Promise<any> | any): Promise<any>;
};

export class FormSubmitEvent extends CustomEvent<FormSubmitEventData> {
}

@customElement('component-native-form')
class ComponentNativeForm extends BunnyElement {

    @property()
    message?: string;

    @property({reflect: true})
    state: 'success' | 'error' | 'loading' | 'user' = 'user';

    private form!: HTMLFormElement;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                position: relative;
            }

            #loadingOverlay {
                position: absolute;
                inset: var(--loading-inset, 0);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, .8);
                z-index: 2;
                text-shadow: 0 0 15px white;
            }
        `,
    ];

    override render() {
        return html`
            ${this.message && this.state !== 'loading' ? html`
                <p id="message">
                    ${this.message}
                </p>
            ` : ''}

            ${this.state === 'loading' ? html`
                <div id="loadingOverlay">
                    <component-loading style="--size: 75px"></component-loading>
                    <p>
                        ${this.message}
                    </p>
                </div>
            ` : ''}

            <slot></slot>
        `;
    }

    constructor() {
        super();


        queueMicrotask(() => {
            //wrap my children in a form
            let form = this.form = document.createElement('form');
            form.addEventListener('submit', this.onInternalFormSubmit as any);
            form.method = 'post';
            form.append(...this.children);
            this.appendChild(form);

            this.querySelectorAll<ComponentButton>('component-button').forEach(submitButton => {
                submitButton.addEventListener('click', (e) => {
                    if (this.form.reportValidity()) {
                        this.onInternalFormSubmit(e);
                    }
                });
            });
        });
    }

    @bind()
    async onInternalFormSubmit(e: FormSubmitEvent | MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        this.message = 'Loading';
        this.state = 'loading';
        let data = Object.fromEntries(new FormData(this.form).entries());


        let response: Promise<any> | any;
        let finalResponseCallback: ((value: any) => void) = undefined as any;
        let finalResponse = new Promise((s) => {
            finalResponseCallback = s;
        });
        this.dispatchEvent(new FormSubmitEvent(
            'submit',
            {
                composed: true,
                detail: {
                    data: data,
                    setResponse(_response: Promise<any> | any) {
                        response = _response;

                        return finalResponse;
                    },
                },
            },
        ));


        if (typeof response === 'function') {
            response = response();
        }

        try {
            response = (await response) || DEFAULT_SUCCESS_MESSAGE;

        } catch (e) {
            response = e;
        }

        if (response instanceof Error) {
            this.message = response.message;
            this.state = 'error';

        } else {
            this.message = response;
            this.state = 'success';
        }

        if (finalResponseCallback) {
            finalResponseCallback(response);
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-native-form': ComponentNativeForm;
    }
}