import {initializeApp} from 'firebase/app';
import {config} from '../../../../config';


let firebaseConfig = config.google.firebase;
export const firebaseApp = initializeApp(firebaseConfig);


export const convertFirestoreTimestamps = (data: any) => {
    for (let key in data) {
        let value = data[key];
        if (!value || typeof value !== 'object') continue;

        if(typeof value.seconds === 'number' && typeof value.nanoseconds === 'number' && value.toDate){
            //convert firestore timestamps
            data[key] = value.toDate();
            continue;
        }
        if(value.id && value.path){
            //skip firestore references
            continue;
        }

        convertFirestoreTimestamps(value);
    }
};