import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ComponentElasticsearchCollectionList} from '../../../elasticsearch/local/components/component-elasticsearch-collection-list';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';

@customElement('component-charities-collection-header')
class ComponentCharitiesCollectionHeader extends BunnyElement {

    @property({type: Object})
    list: ComponentElasticsearchCollectionList;

    @property({type: Number})
    totalResults: number;

    @property({type: Number})
    amountDonated: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            `,
        ];


    override render() {
        return html`
            <h1 style="margin-top: 0">Our ${this.totalResults} Aspire charities to date, totaling &pound;${this.amountDonated}</h1>

            <slot></slot>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.list.addEventListener('response-changed', this.reflectTotalResults);
        this.totalResults = this.list.response?.aggregations?.all?.doc_count;
        this.amountDonated = this.list.response?.aggregations?.all?.amountDonated?.value;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.list.removeEventListener('response-changed', this.reflectTotalResults);
    }

    @bind()
    reflectTotalResults() {
        this.totalResults = this.list.response?.aggregations?.all?.doc_count;
        this.amountDonated = this.list.response?.aggregations?.all?.amountDonated?.value;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-charites-collection-header': ComponentCharitiesCollectionHeader;
    }
}