import {customElement, query} from 'lit/decorators.js';
import {
    ComponentNotificationsPreferenceChannelBase,
    CONFIGURATION,
} from '../component-notifications-preference-channel-base';
import {property} from '../../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../../routing/local/controllers/Route';
import {html} from 'lit';
import {observe} from '../../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {
    toastProgressWrapper,
} from '../../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {ComponentInput} from '../../../../inputs/local/components/component-input';
import {computed} from '../../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {FriendlyMessage} from '../../../../__internal/shared/helpers/ExceptionHelper.ts';
import {NOTIFICATION_CHANNELS_INFO} from '../../../shared/helpers/NotificationsHelper.ts';
import {delayPromise} from '../../../../__internal/local/helpers/PromiseHelper.ts';

@customElement('component-notifications-preference-channel-sms')
class ComponentNotificationsPreferenceChannelSms extends ComponentNotificationsPreferenceChannelBase {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Object})
    types = NOTIFICATION_CHANNELS_INFO.sms;

    @property({type: String})
    phoneNumber: string;

    @query('component-input')
    phoneNumberInput: ComponentInput;

    get name() {
        return 'Text message';
    }

    renderSetupHtml() {
        return html`
            <div class="contentContainer">
                <component-input .value="${this.bind.phoneNumber}"
                                 label="Mobile number"
                                 required
                                 auto-validate
                                 pattern="^\\+?(07\\d{8,12}|447\\d{7,11})$"></component-input>
                <component-button @click="${this.save}" style="display: block; margin-top: 5px">Apply</component-button>

                <p>
                    Please enter a valid uk mobile number, if you are having problems dont hesitate to <a
                        href="/contact-us/#section-support">contact us here</a>
                </p>
            </div>
        `;
    }

    renderInfoHtml() {
        return html`
            <div class="contentContainer" style="font-size: 80%">
                ${this.item?.meta?.number}
            </div>
        `;
    }

    renderFullInfoHtml() {
        return html`
            <div class="contentContainer" style="font-size: 80%">
                At any time, if you wish to no longer receive any SMS from us please click "Remove Text Message" button below to unsubscribe from SMS.
            </div>
        `;
    }

    @computed('item')
    get configuration(): CONFIGURATION {
        return this.item?.meta?.number ?
            CONFIGURATION.AVAILABLE :
            CONFIGURATION.AWAITING_SETUP;
    }

    @observe('item')
    populatePhoneNumber(item: any) {
        this.phoneNumber = item?.meta?.number || '';
    }

    @toastProgressWrapper({
        progressMessage: 'Enabling SMS notifications',
        successMessage: 'SMS notifications enabled',
        failedMessage: 'Failed enabling SMS notifications: {{e}}',
    })
    save() {
        if (!this.phoneNumberInput.validate()) throw new FriendlyMessage('Phone number is not a valid UK mobile number');

        this.item.meta ??= {};
        this.item.meta.number = this.phoneNumber;
        this.item.enabled = true;
        this.item.subscriptions = {};

        this.saveChannel();
    }


    @observe('configuration', 'opened', 'item')
    prepopulatePhoneNumber(configuration: CONFIGURATION, opened: boolean, _item: any) {
        if (!opened) return;
        if (configuration !== CONFIGURATION.AWAITING_SETUP) return;

        let addChannelMeta = this.route.current.query.addChannelMeta;
        if (!addChannelMeta) return;

        if (this.phoneNumber) return;

        this.phoneNumber = addChannelMeta;
    }

    @observe('configuration', 'opened')
    async attemptAutoEnable(configuration: CONFIGURATION, opened: boolean) {
        if (!opened) return;
        if (configuration !== CONFIGURATION.AWAITING_SETUP) return;

        let addChannelMeta = this.route.current.query.addChannelMeta;
        if (!addChannelMeta) return;


        //wait for the proper update of the phone number
        for (let i = 0; i < 10; i++) {
            if (this.phoneNumberInput?.nativeInput?.value) break;//found the number input
            if (i === 9) return;//give up

            await delayPromise(i * 25);
        }

        this.save();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-preference-channel-sms': ComponentNotificationsPreferenceChannelSms;
    }
}
