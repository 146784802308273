import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import {AUTH_PACKAGE_ID} from '../../auth/shared';

import Package from '../../__internal/shared/Package';


export const MEDIA_PACKAGE_ID = 'media';

export class MediaPackage extends Package {
    id: string = MEDIA_PACKAGE_ID;
    name: string = 'Media';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, AUTH_PACKAGE_ID];
}