import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';


export const FIREBASE_ANALYTICS_PACKAGE_ID = 'firebase-analytics';

export class FirebaseAnalyticsPackage extends Package {
    id: string = FIREBASE_ANALYTICS_PACKAGE_ID;
    name: string = 'Firebase analytics';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];
}