import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ComponentElasticsearchAdvancedSearch} from './component-elasticsearch-advanced-search';
import {ComponentElasticsearchAdvancedSearchGroup} from './component-elasticsearch-advanced-search-group';
import {property} from 'lit/decorators.js';


export class ComponentElasticsearchAdvancedSearchItem extends BunnyElement {
    @property({type: Object})
    searchComponent: ComponentElasticsearchAdvancedSearch;

    @property({type: String})
    type: string;

    @property({type: Object})
    parentGroup: ComponentElasticsearchAdvancedSearchGroup | undefined;

    /**
     * Builds the elasticsearch query
     */
    buildQuery(): any | undefined {
        return {};
    }

    /**
     * Builds a shorter object that represents the advanced search
     */
    buildUrl(): any | undefined {
        return {};
    }
}