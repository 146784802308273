import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import Package from '../../__internal/shared/Package';

export const DEFAULT_PAGE_LAYOUT = 'page-layout-default';

export const ROUTING_PACKAGE_ID = 'routing';

export class RoutingPackage extends Package {
    id: string = ROUTING_PACKAGE_ID;
    name: string = 'Routing';
    dependencies: string[] = [INTERNAL_PACKAGE_ID];
}