const debouncedPromises = new Map<string, Promise<any>>();

export const debouncePromise = async <T = any>(id: string, promiseGenerator: () => Promise<T>): Promise<T> => {
    if (debouncedPromises.has(id)) return debouncedPromises.get(id);


    try {
        debouncedPromises.set(id, promiseGenerator());

        return await debouncedPromises.get(id);

    } finally {
        debouncedPromises.delete(id);
    }
};
