import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {config} from '../../../../config';

@customElement('component-aspire-comps-live')
class ComponentAspireCompsLive extends BunnyElement {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .platforms {
                text-align: center;
                color: white;
                font-size: 20px;
                font-weight: bold;
            }

            .platforms a {
                display: inline-block;
                margin: 15px;
            }

            .platforms a img {
                height: 50px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;
            }

            .platforms a p {
                display: inline-block;
                margin: 0;
            }
            
            component-icon{
                width: 50px;
                height: 50px;
            }
        `
    ];

    override render(){
        return html`
            <div class="platforms">
                <a href="${config.site.social.youtube}" target="_blank">
                    <component-button style="background-color: #b02a25;">
                        <img src="/images/social/youtube.svg" alt="YouTube">
                        <p>Watch and comment on YouTube</p>
                    </component-button>
                </a>

                <a href="${config.site.social.facebook}/live" target="_blank">
                    <component-button style="background-color: #2e487e;">
                        <img src="/images/social/facebook.svg" alt="Facebook">
                        <p>Watch and comment on Facebook</p>
                    </component-button>
                </a>
            </div>

            <div id="timingCarousel" class="gridContainer" style="text-align: center; margin-top: 50px">
                <div style="--col-md: 4">
                    <component-aspire-comps-info-step .title="${'Live Draw'}">
                        <component-icon icon="notification:live-tv"></component-icon>
                        <p>Every Wednesday · <small>7.30pm</small></p>
                        <hr>
                        <p>When our main prizes are drawn!</p>
                    </component-aspire-comps-info-step>
                </div>

                <div style="--col-md: 4">
                    <component-aspire-comps-info-step .title="${'Comments Draw'}">
                        <component-icon icon="communication:comment"></component-icon>
                        <p>Every Wednesday · <small>Mid-day</small></p>
                        <hr>
                        <p>People that comment on the live draw get a free entry for some website credit!</p>
                    </component-aspire-comps-info-step>
                </div>

                <div style="--col-md: 4">
                    <component-aspire-comps-info-step .title="${'Early Badger'}">
                        <component-icon icon="social:notifications-none"></component-icon>
                        <p>Every Wednesday · <small>Mid-day</small></p>
                        <hr>
                        <p>Those who purchased early badger tickets get a chance to win extra tickets for the main
                            draw!</p>
                    </component-aspire-comps-info-step>
                </div>
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-live': ComponentAspireCompsLive;
    }
}