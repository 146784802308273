import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';

@customElement('component-recover-account')
class ComponentRecoverAccount extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    @storageBoundQueryString('email')
    emailAddress: string;

    @property({type: String})
    loginUrl: string = '/login';

    @property({type: String})
    signupUrl: string = '/signup';

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding: 0 15px 0 15px;
                overflow: hidden;
            }

            .formHeader {
                text-align: center;
                margin-bottom: 25px;
                background-color: var(--primary-color);
                color: white;
                padding: 10px 15px;
                margin-left: -15px;
                margin-right: -15px;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
            }

            .formHeader > * {
                margin: 0;
            }

            .formFooter {
                position: relative;
                margin-top: 35px;
                margin-left: -15px;
                margin-right: -15px;
                padding: 35px 5px 15px 5px;
                border-top: solid rgba(64, 64, 64, .3) 1px;
                background-color: var(--primary-text-color);
                text-align: center;
                display: flex;
                justify-content: center;
            }

            .formFooter:after {
                content: 'Still having issues';
                display: inline-block;
                padding: 3px 15px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: white;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
            }
        `,
    ];

    override render() {
        let urlEmail = this.renderUrlEmail('email', this.emailAddress);

        return html`
            <div class="formHeader">
                <h1>
                    Recover account
                </h1>
                <p>Existing customers</p>
            </div>

            <component-native-form @submit="${this.sendCallback}" style="--loading-inset: -500px">
                <component-input-email
                        required
                        name="emailAddress"
                        label="Email address"
                        .value="${this.emailAddress}"></component-input-email>

                <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                    Submit
                </button>

                <p>
                    Check your email to find a link to reset your password (be sure to check your spam folder).
                </p>

                <div slot="submitButton">
                    <component-button
                            style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                        Request recovery code
                    </component-button>

                    <div style="text-align: right; margin-bottom: 10px; margin-top: 10px">
                        <a href="${this.loginUrl}?${urlEmail}"
                           style="color: var(--primary-color);">Login</a>
                        ·
                        <a href="${this.signupUrl}?${urlEmail}"
                           style="color: var(--primary-color);">Signup</a>
                    </div>
                </div>
            </component-native-form>

            <div class="formFooter" style="text-align: center">
                <a href="/contact-us">
                    <component-button style="background-color: white; color: var(--primary-text-color)">
                        Get support
                    </component-button>
                </a>
            </div>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Recovering account',
        successMessage: 'Successfully sent recovery request, please check your email',
        failedMessage: 'Failed sending recovery code',
    })
    @bind()
    async sendCallback(e: FormSubmitEvent) {
        let finalResponse = await e.detail.setResponse(async () => {
            let formData = e.detail.data;
            let response = await fetch(`/_/authresetpasswordv2`, {
                body: JSON.stringify({
                    emailAddress: formData.emailAddress,
                }),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status !== 200) {
                let data = await response.json();

                throw new Error(data._messages[0]);
            }

            return 'Successfully sent recovery request, please check your email';
        });

        if (finalResponse instanceof Error) {
            throw finalResponse;
        }
    }

    renderUrlEmail(field: string, emailAddress: string) {
        return emailAddress ? `${field}=${encodeURIComponent(emailAddress)}` : '';
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-recover-account': ComponentRecoverAccount;
    }
}
