import {ElementPrototype} from '../DecoratorsHelper';
import {BunnyElement} from '../../components/bunny-element';

export interface ElementPropertyComputed {
    method: () => any;
    targets: string[];
    property: string;
    setValue: (value: any) => void;
    getValue: () => any;
}

export const computed = <P extends string, El extends ElementPrototype & {
    [K in P]: {} | null | undefined;
}>(...targets: P[]) => {
    return (prototype: El, propName: string, descriptor: PropertyDescriptor) => {
        //DONT replace with ??= as it doesnt account for parent class observers
        let prototypeConstructor = prototype.constructor;
        let computeds = prototypeConstructor.hasOwnProperty('computeds') ? prototypeConstructor.computeds as ElementPropertyComputed[] : (prototypeConstructor.computeds = []);

        computeds.push({
            method: descriptor.get as () => any,
            setValue: function (this: BunnyElement, value: any) {
                (this as any)[`__${propName}`] = value;
            },
            getValue: function (this: BunnyElement) {
                return (this as any)[`__${propName}`];
            },
            targets: targets,
            property: propName,
        });

        descriptor.get = function (this: BunnyElement) {
            return (this as any)[`__${propName}`];
        };
    };
};