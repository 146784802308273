import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-slider')
class ComponentSlider extends BunnyElement {

    @property({type: Array})
    components: any[] = [];

    @property({type: Object})
    data: any = {};

    @property({type: String})
    selectedItem: string = '';

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                overflow: hidden;

                --item-width: 100%;
                --selected-index: 0;
            }

            #items {
                display: flex;
                transition: .375s;
                transform: translateX(calc(var(--item-width) * var(--selected-index) * -1));
            }

            #items > * {
                flex-shrink: 0;
                width: var(--item-width);
            }

            component-input-radio-item {
                width: 16px;
            }

            @media screen and (min-width: 768px) {
                :host {
                    --item-width: 70%;
                }

                #items > *:first-child {
                    margin-left: 15%;
                }

                #items > *:last-child {
                    margin-right: 15%;
                }

                :host {
                    position: relative;
                }

                :host:after {
                    content: '';
                    background: linear-gradient(90deg, #f9f9f9 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, #f9f9f9 100%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    pointer-events: none;
                }
            }
        `,
    ];

    override render() {
        return html`
            <div id="items">
                ${this.components?.map(_ => createComponent(_, this.data))}
            </div>

            <component-input-radio-group .value="${this.bind.selectedItem}">
                ${this.components?.map((_, index) => html`
                    <component-input-radio-item name="${index}"></component-input-radio-item>
                `)}
            </component-input-radio-group>
        `;
    }

    @observe('components')
    onComponentsChanged(components: any[]) {
        this.selectedItem = `${Math.floor(components.length / 2)}`;
    }

    @observe('selectedItem')
    loadSelectedIndex(selectedItem: string) {
        this.style.setProperty('--selected-index', selectedItem);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-slider': ComponentSlider;
    }
}