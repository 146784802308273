import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';

import Package from '../../__internal/shared/Package';
import {ROUTING_PACKAGE_ID} from '../../routing/shared';
import {ELASTICSEARCH_PACKAGE_ID} from '../../elasticsearch/shared';


export const AUTH_PACKAGE_ID = 'auth';

export class AuthPackage extends Package {
    id: string = AUTH_PACKAGE_ID;
    name: string = 'Auth';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, ROUTING_PACKAGE_ID, ELASTICSEARCH_PACKAGE_ID];
}