import {BunnyElement} from "../../components/bunny-element";
import {BunnyController} from "../../controllers/BunnyController";
import {ReactiveController, ReactiveControllerHost} from 'lit';
import {IMPORT_TEST_COMPONENTS} from "../../../../../config";

if (IMPORT_TEST_COMPONENTS) {
    class TestController extends BunnyController {
        connectedCalls = 0;
        disconnectedCalls = 0;

        override hostConnected(host: ReactiveControllerHost) {
            this.connectedCalls++;
            super.hostConnected(host);
        }

        override hostDisconnected(host: ReactiveControllerHost) {
            this.disconnectedCalls++;
            super.hostDisconnected(host);
        }
    }

    class TestReactiveControllerHost implements ReactiveControllerHost {
        readonly updateComplete: Promise<boolean>;

        controllers: ReactiveController[] = [];
        updatesRequested = 0;

        addController(controller: ReactiveController): void {
            this.controllers.push(controller);
        }

        removeController(controller: ReactiveController): void {
            this.controllers.splice(this.controllers.findIndex(_ => _ === controller), 1);
        }

        requestUpdate(): void {
            this.updatesRequested++;
        }

        hostConnected(controllerIndex: number) {
            let connectedFunc = this.controllers[controllerIndex]?.hostConnected;
            if (connectedFunc) {
                connectedFunc();
            }
        }

        hostDisconnected(controllerIndex: number) {
            let disconnectedFunc = this.controllers[controllerIndex]?.hostDisconnected;
            if (disconnectedFunc) {
                disconnectedFunc();
            }
        }
    }

    class ComponentTesterBunnyController extends BunnyElement {
        async constructsWithoutHost() {
            let controller = new TestController();

            return {
                controller: controller,
            };
        }

        async constructsWithHost() {
            let host = new TestReactiveControllerHost();
            let controller = new TestController(host);

            return {
                host: host,
                controller: controller,
            };
        }

        async hostConnected() {
            let host = new TestReactiveControllerHost();
            let controller = new TestController(host);

            host.hostConnected(0);

            await new Promise(requestAnimationFrame);

            return {
                host: host,
                controller: controller,
            };
        }

        async hostDisconnected() {
            let host = new TestReactiveControllerHost();
            let controller = new TestController(host);

            host.hostDisconnected(0);

            return {
                host: host,
                controller: controller,
            };
        }
    }

    customElements.define('component-tester-bunny-controller', ComponentTesterBunnyController);
}
