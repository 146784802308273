import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper.ts';

@customElement('component-input-checkbox')
export class ComponentInputCheckbox extends BunnyElement {

    @property({type: String})
    name?: string;

    @property({type: Boolean, notify: true, reflect: true})
    checked = false;

    @property({type: Boolean, notify: true, reflect: true})
    disabled = false;

    @property({type: Boolean, notify: true, reflect: true})
    required = false;

    get nativeInput(): HTMLInputElement {
        return this.querySelector('input') as HTMLInputElement;
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
            }

            label {
                display: flex;
                align-items: center;

                .label {
                    flex: 1;
                }
            }

            ::slotted(input) {
                border-radius: 3px;
                border: solid var(--primary-text-color) 2px;
                transition: .125s;
                background: none;
                width: 24px;
                height: 24px;
                appearance: none;
                margin: 0;
                cursor: pointer;
            }

            #checkContainer {
                position: relative;
                line-height: 0;
                margin-right: 5px;

                &:before {
                    content: '✔';
                    position: absolute;
                    inset: 0;
                    z-index: 1;
                    line-height: 24px;
                    text-align: center;
                    pointer-events: none;
                    color: var(--primary-color)
                }

                &:after {
                    content: '';
                    position: absolute;
                    inset: 2px;
                    z-index: 1;
                    background: white;
                    pointer-events: none;
                    transition: .125s linear;
                    transform: scale(1);
                    transform-origin: top right;
                }
            }

            :host([checked]) {
                ::slotted(input) {
                    border-color: var(--primary-color);
                }

                #checkContainer:after {
                    transform: scale(0);
                }
            }

            :host([disabled]) {
                #checkContainer {
                    filter: grayscale(1);
                    opacity: .6;
                }

                ::slotted(input) {
                    pointer-events: none;
                }
            }
        `,
    ];

    override render() {
        return html`
            <label>
                <span id="checkContainer">
                    <slot name="input"></slot>
                </span>
                <input type="checkbox"
                       ?disabled="${this.disabled}"
                       ?checked="${this.checked}"
                       ?required="${this.required}"
                       @input="${this.onInput}"
                       @change="${this.onInput}"
                       name="${this.name}">

                <div id="label" class="label">
                    <slot></slot>
                    ${this.required ? html`<span class="required">*</span>` : ''}
                </div>
            </label>
        `;
    }

    constructor() {
        super();

        requestAnimationFrame(() => {
            let input = this.shadowRoot?.querySelector('input') as HTMLInputElement;
            input.slot = 'input';

            this.appendChild(input);
        });
    }

    protected onInput() {
        this.checked = this.nativeInput.checked;
    }

    @listen('click', 'label')
    onLabelClick() {
        if (this.disabled) return;

        this.checked = !this.checked;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-checkbox': ComponentInputCheckbox;
    }
}