import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import './component-login-alt';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';

@customElement('component-login-one-time')
class ComponentLoginOneTime extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Boolean})
    showSetupAccountForm: boolean = false;

    @property({type: String})
    returnUrl: string = '/';

    @property({type: String})
    email: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding: 0 15px 0 15px;
                overflow: hidden;
            }

            .formHeader {
                text-align: center;
                margin-bottom: 25px;
                background-color: var(--primary-color);
                color: white;
                padding: 10px 15px;
                margin-left: -15px;
                margin-right: -15px;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
            }

            .formHeader > * {
                margin: 0;
            }
        `,
    ];

    override render() {
        return html`
            <div class="formHeader">
                <h1>
                    Login
                </h1>
                <p>Existing customers</p>
            </div>

            ${this.showSetupAccountForm ? html`
                <component-native-form @submit="${this.sendCallback}">
                    <component-input-email id="authEmail" required name="email" inputmode="email"
                                           autocomplete="email"
                                           label="Email address" autofocus readonly
                                           .value="${this.email}"></component-input-email>
                    <component-input-password id="authPassword" required name="password"
                                              autocomplete="current-password"
                                              label="Password"></component-input-password>

                    <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                        Submit
                    </button>

                    <div slot="submitButton">
                        <component-button
                                style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                            Set password
                        </component-button>
                    </div>
                </component-native-form>
            ` : undefined}
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Completing account',
        successMessage: 'Account completed',
        failedMessage: 'Failed completing account: {{e}}',
    })
    @bind()
    async sendCallback(e: FormSubmitEvent) {
        let finalResponse = await e.detail.setResponse(async () => {
            let formData = e.detail.data;
            if (this.auth.user) {
                try {
                    await this.auth.reauthenticateWithEmailLink(formData.email, location.href);

                } catch (e) {
                    console.warn('Email link might of been used up', e);
                }

            } else {
                await this.auth.signInWithEmailLink(formData.email, location.href);
            }


            await this.auth.updatePassword(formData.password);


            HistoryHelper.pushState(this.returnUrl);

            return 'Account completed';
        });

        if (finalResponse instanceof Error) {
            throw finalResponse;
        }
    }

    @observe('route')
    loadReturnUrlFromUrl(route: Route) {
        let returnUrl = route.current.query.returnUrl;
        if (!returnUrl) return;

        //validate that the url can only start with /(base) and not //(off origin)
        if (!(returnUrl[0] === '/' && returnUrl[1] !== '/')) return;

        this.returnUrl = returnUrl;
    }

    @observe('route')
    loadEmailUrlFromUrl(route: Route) {
        let account = route.current.query.account;
        if (!account) return;

        function base64AddPadding(str: string) {
            return str + Array((4 - str.length % 4) % 4 + 1).join('=');
        }


        let text = base64AddPadding(account.split('').reverse().join(''));
        this.email = atob(text);
    }

    @observe('email')
    async checkLoginState(email: string) {
        if (!email) return;

        //TODO show doing something
        let signInMethods = await this.auth.fetchSignInMethodsForEmail(email);

        if (signInMethods.includes('password')) {
            if (!this.auth.user) {
                await this.auth.signInWithEmailLink(email, location.href);
            }

            HistoryHelper.pushState(this.returnUrl);

        } else {
            this.showSetupAccountForm = true;
        }

        //TODO show done doing something
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-login-one-time': ComponentLoginOneTime;
    }
}