import {customElement, query} from 'lit/decorators.js';
import ComponentShopStockRemaining from './component-shop-stock-remaining';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-shop-stock-remaining-bar')
class ComponentShopStockRemainingBar extends ComponentShopStockRemaining {

    @property({type: Number})
    internalValue: number;

    @property({type: String, reflect: true})
    updateMode: 'normal' | 'animated' = 'normal';

    @property({type: Boolean, reflect: true})
    usePercentage: boolean;

    @property({type: Boolean, reflect: true})
    @computed('internalValue')
    get internalValueLoaded() {
        return typeof this.internalValue !== 'undefined';
    }

    @query('#progress')
    progressElement: HTMLElement;

    @query('#label')
    labelElement: HTMLElement;

    static override styles = [
        // language=SCSS
        ...super.styles,
        scss`
            :host {
                position: relative;
                --progress-bar-height: 50px;
                --progress-bar-border-radius: 3px;
            }

            :host([update-mode="animated"]) {
                background-color: #f9f9f9;
                transition: .125s;
            }

            :host([update-mode="animated"]) > * {
                opacity: 0;
                transition: .125s;
            }

            :host([update-mode="animated"][internal-value-loaded]) {
                background-color: transparent;
            }

            :host([update-mode="animated"][internal-value-loaded]) > * {
                opacity: 1;
            }


            @keyframes spin {
                0% {
                    /*transform: skewX(0deg);*/
                    transform: rotateX(0deg) skewX(0deg);
                    text-shadow: 0 0 0 transparent;
                }
                50% {
                    /*transform: skewX(180deg);*/
                    text-shadow: 0 15px 1px rgba(32, 32, 32, .5);
                    transform: rotateX(90deg) skewX(70deg);
                }
                50.1% {
                    /*transform: skewX(180deg);*/
                    text-shadow: 0 15px 1px rgba(32, 32, 32, .5);
                    transform: rotateX(90deg) skewX(140deg);
                }
                100% {
                    /*transform: skewX(180deg);*/
                    transform: rotateX(0deg) skewX(180deg);
                    text-shadow: 0 0 0 transparent;
                }
            }

            component-progress {
                width: 100%;
                height: var(--progress-bar-height);
                border-radius: var(--progress-bar-border-radius);
            }

            .label {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                font-weight: bold;
            }

            .label.triggerAnimation {
                animation: spin .125s linear;
                animation-fill-mode: forwards;
            }

            :host(.labelOverTop) {
                component-progress {
                    overflow: visible;
                }

                .label {
                    position: absolute;
                    bottom: 100%;
                    background-color: #8dc033;
                    border-radius: 20px 20px 0 0;
                    padding: 2px 11px;
                    font-size: 16px;
                    color: white;
                    text-align: center;
                    height: 25px;
                    top: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    right: auto;
                    width: 75%;

                    &:before {
                        content: '';
                        background-color: transparent;
                        position: absolute;
                        width: 50px;
                        height: 13px;
                        bottom: 0;
                        right: 100%;
                        box-shadow: 20px 0 0 0 #8dc033;
                        border-bottom-right-radius: 66px;
                    }

                    &:after {
                        content: '';
                        background-color: transparent;
                        position: absolute;
                        width: 50px;
                        height: 13px;
                        bottom: 0;
                        left: 100%;
                        box-shadow: -20px 0 0 0 #8dc033;
                        border-bottom-left-radius: 66px;
                    }
                }
            }
        `];


    override render() {
        return html`
            <component-progress id="progress" .value="${this.max as number - this.internalValue}" .max="${this.max}">
                <div class="label" id="label">
                    ${this.generateLabel(this.label, this.internalValue, this.max as number, this.usePercentage)}
                </div>
            </component-progress>
        `;
    }

    generateLabel(label: string, value: number, max: number, usePercentage: boolean) {
        value = value || 0;

        let stringValue = `${usePercentage ? `${Math.round(((max - value) / max) * 100)}%` : value}`;
        label = label.replace(/~~/g, '::')
            .replace('::value::', stringValue);

        if (value === 1) {
            //removes the s
            //TODO replace this with someting thats not this
            label = label.replace(/([0-9]+ [a-zA-Z]+)s/, (_m: string, m1: string) => m1);
        }

        return label;
    }

    @observe('value', 'updateMode')
    async triggerAnimation(value: number, updateMode: 'normal' | 'animated') {
        if (typeof value !== 'number') return;
        if (value === -1) return;


        if (updateMode === 'animated' && typeof this.internalValue === 'number') {
            //only run if its animated and the internal value has been set before

            this.progressElement.classList.add('transiting');
            let classList = this.labelElement.classList;
            classList.remove('triggerAnimation');
            await delayPromise();
            classList.add('triggerAnimation');
            await delayPromise(125 / 2);
        }

        this.internalValue = value;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-stock-remaining-bar': ComponentShopStockRemainingBar;
    }
}
