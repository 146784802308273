import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ElasticsearchQueryRequest} from '../../../elasticsearch/shared/helpers/ElasticsearchHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-homepage-competitions-available')
class ComponentHomepageCompetitionsAvailable extends BunnyElement {

    @property({type: Object})
    get nextDrawAt() {
        let now = new Date();
        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + ((7 - now.getDay()) % 7 + 3) % 7,
            19,
            30,
            0,
        );
    }

    @property({type: Object})
    query: ElasticsearchQueryRequest = {
        id: 'searchProductsFrontend',
        params: {
            size: 0,
        },
    };

    @property({type: Object})
    response: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                text-align: center;
                text-transform: uppercase;
                color: #fefefe;
            }

            p {
                font-size: 25px;
                font-size: calc(1vw + 14px);
                font-weight: bold;
                background-color: #222222;
                padding: 10px;
                box-shadow: 25vw 0 #222222, -25vw 0 #222222;
                margin: 0;
            }

            component-countdown-timer {
                background-color: #484848;
                box-shadow: 25vw 0 #484848, -25vw 0 #484848;
            }

            component-button {
                display: block;
                box-shadow: 25vw 0 var(--primary-color), -25vw 0 var(--primary-color);
            }
        `,
    ];

    override render() {
        return html`
            <component-elasticsearch-query .query="${this.query}"
                                           .response="${this.bind.response}"></component-elasticsearch-query>

            <p>
                ${this.response?.hits?.total?.value} competitions available, next draw in
            </p>

            <component-countdown-timer .target="${this.nextDrawAt}"></component-countdown-timer>

            <a href="/shop/products">
                <component-button>
                    View all
                </component-button>
            </a>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-homepage-competitions-available': ComponentHomepageCompetitionsAvailable;
    }
}