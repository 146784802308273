import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {Auth} from '../../../auth/local/controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

interface Restriction {
    group?: string;
    message: string;
    urlMatch?: string;
    urlRegexMatch?: string;
    cssQuery: string[];
    permissionToSkip?: string;
    attachmentPoint?: 'overlayBefore' | 'overlayAfter' | 'before' | 'after';
    style?: string;
    overlayStyle?: string;
}

interface AppliedRestriction {
    id: number;
    restriction: Restriction;
    element?: HTMLElement;
    mutationObservers: Set<MutationObserver>;
}

let restrictionsCount = 0;

@customElement('component-app-state-component-restrictions')
class ComponentAppStateComponentRestrictions extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Array})
    restrictions: Restriction[];

    @property({type: Array})
    @computed('restrictions', 'route', 'auth')
    get activeRestrictions() {
        if (!this.restrictions) return [];

        let routePath: string = this.route.current.path;
        let restrictions = this.restrictions.filter(_ => {
            if (_.urlMatch) return routePath.startsWith(_.urlMatch);
            if (_.urlRegexMatch) return routePath.match(new RegExp(_.urlRegexMatch));

            return true;
        });

        if (this.auth?.user) {
            restrictions = restrictions.filter(_ => !_.permissionToSkip || !RenderingHelper._accountHasPermission(this.auth.user, _.permissionToSkip));
        }


        return restrictions;
    }

    @property({type: Array})
    enabledRestrictions: Restriction[] = [];

    @property({type: Array})
    appliedRestrictions: { [key: string]: AppliedRestriction } = {};

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <component-firebase-remote-config key="componentRestrictions"
                                              .value="${this.bind.restrictions}"></component-firebase-remote-config>
        `;
    }

    private waitForElement(root: Document | ShadowRoot, cssQuery: string, appliedRestriction: AppliedRestriction, timeout: number) {
        return new Promise<HTMLElement>(async (s, f) => {
            let failTimeoutId = setTimeout(() => {
                f(new Error('Timed out waiting for element'));
            }, timeout);

            let element: HTMLElement | null = null;
            while (!element) {
                element = root.querySelector(cssQuery);
                if (element) break;


                // If it doesn't exist then child listener for the element existing, falling back to setTimeout
                if ('MutationObserver' in window) {
                    element = await new Promise<HTMLElement>((s) => {
                        let observer = new MutationObserver(function (mutations) {
                            for (let mutation of mutations) {
                                for (let addedNode of mutation.addedNodes) {
                                    if (!(addedNode instanceof Element)) continue;

                                    if (addedNode.matches(cssQuery)) {
                                        s(addedNode as HTMLElement);

                                        appliedRestriction.mutationObservers.delete(observer);
                                        observer.disconnect();
                                    }

                                    let subMatchElement = addedNode.querySelector(cssQuery);
                                    if (subMatchElement) {
                                        s(subMatchElement as HTMLElement);

                                        appliedRestriction.mutationObservers.delete(observer);
                                        observer.disconnect();
                                    }
                                }
                            }
                        });

                        appliedRestriction.mutationObservers.add(observer);
                        observer.observe(root, {childList: true, subtree: true});
                    });

                } else {
                    await new Promise(_ => (window.requestAnimationFrame as any || setTimeout)(_, 16));
                }
            }

            clearTimeout(failTimeoutId);
            s(element);
        });
    }

    private async fetchRestrictionElement(restriction: Restriction, appliedRestriction: AppliedRestriction, timeout = 10000) {
        let root: Document | ShadowRoot | null = document;
        let element: HTMLElement | null = null;
        for (let cssQuery of restriction.cssQuery) {
            if (!root) throw new Error('Previously located root doesnt have a shadow');

            element = await this.waitForElement(root, cssQuery, appliedRestriction, timeout);
            if (!element) throw new Error(`Unable to locate ${cssQuery}`);

            root = element.shadowRoot;
        }

        if (!element) throw new Error('Unable to locate element');


        return element;
    }

    private async disableRestriction(appliedRestriction: AppliedRestriction) {
        let element = appliedRestriction.element as HTMLElement;
        if (element) {
            delete element.dataset[`restricted-${appliedRestriction.id}`];
            let restrictionStyle = element.parentNode?.querySelector(`style#component-restriction-${appliedRestriction.id}`);
            restrictionStyle?.parentNode?.removeChild(restrictionStyle);
        }

        for (let mutationObserver of appliedRestriction.mutationObservers) {
            mutationObserver.disconnect();
            appliedRestriction.mutationObservers.delete(mutationObserver);
        }

        delete this.appliedRestrictions[appliedRestriction.id];
    }

    private async enableRestriction(restriction: Restriction) {
        restriction.attachmentPoint ??= 'overlayAfter';
        let restrictionId = restrictionsCount++;
        let appliedRestriction = this.appliedRestrictions[restrictionId] = {
            id: restrictionId,
            restriction: restriction,
            mutationObservers: new Set(),
        } as AppliedRestriction;


        try {
            appliedRestriction.element = await this.fetchRestrictionElement(restriction, appliedRestriction);

        } catch (e) {
            await this.disableRestriction(appliedRestriction);

            return;
        }

        let element = appliedRestriction.element;
        if (!element) return;


        let restrictionElementDisplay = getComputedStyle(appliedRestriction.element).display;
        let extendedRestrictionStyle = '';
        if (restrictionElementDisplay.includes('flex')) {
            extendedRestrictionStyle += 'display: flex;';

        } else if (!restrictionElementDisplay || restrictionElementDisplay.includes('inline')) {
            extendedRestrictionStyle += 'display: block;';
        }


        let style = document.createElement('style');
        style.id = `component-restriction-${restrictionId}`;
        style.textContent = `
[data-restricted-${restrictionId}]{
    ${restriction.attachmentPoint.startsWith('overlay') ? `
    position:relative;
    pointer-events: none;
    ` : `
    `}
    ${extendedRestrictionStyle}
    ${restriction.style || ''}
}
[data-restricted-${restrictionId}]:${restriction.attachmentPoint.replace('overlay', '').toLowerCase()}{
    content: attr(data-restricted-${restrictionId});
    ${restriction.attachmentPoint.startsWith('overlay') ? `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 199999;
    background: rgba(255, 255, 255, .9);
    color: var(--primary-text-color);
    text-shadow: 0 0 3px rgb(255, 255, 255), 0 0 6px rgba(255, 255, 255, .5), 0 0 9px rgba(255, 255, 255, .25);
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    ` : `
    `}
    ${restriction.overlayStyle || ''}
}
        `;

        element.dataset[`restricted-${restrictionId}`] = restriction.message;
        element.parentNode?.appendChild(style);
    }

    @observe('activeRestrictions')
    async applyRestrictions(activeRestrictions: Restriction[]) {
        await Promise.all(Object.values(this.appliedRestrictions).map(_ => this.disableRestriction(_)));
        await Promise.all(activeRestrictions.map(_ => this.enableRestriction(_)));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-app-state-component-restrictions': ComponentAppStateComponentRestrictions;
    }
}
