import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {registerEditorElement} from '../../../routing/local/helpers/DecoratorHelper';
import {ShopProductDocument} from '../../shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-product-name')
@registerEditorElement({
    key: 'component-shop-product-name',
    name: 'Shop product name',
    defaultProperties: {},
})
class ComponentShopProductName extends BunnyElement {

    @property({type: Object})
    __product: ShopProductDocument = null as any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            <h2 style="margin-bottom: 0">
                ${this.__product ? this.__product.name : '<PRODUCT NAME>'}
            </h2>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-product-name': ComponentShopProductName;
    }
}