import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import './component-media-view-image';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-data-collection-list-item-cell-media')
class ComponentDataCollectionListItemCellMedia extends ComponentFirestoreCollectionListItemCell {


    @property({type: Object})
    @computed('field', 'item')
    get fieldValue(): any {
        return this.getContent(this.field, this.item);
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-media-view-image {
                max-width: 150px;
                margin: auto;
            }
        `,
    ];

    override render() {
        return html`
            ${this.fieldValue ? html`
                <component-media-view-image .media="${this.fieldValue}"
                                            type="default-thumbnail"
                                            .width="${400}"
                                            .height="${300}"></component-media-view-image>
            ` : undefined}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-media': ComponentDataCollectionListItemCellMedia;
    }
}