import {customElement} from 'lit/decorators.js';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {config} from '../../../../config';
import {PageLayoutAspireComps} from './page-layout-aspire-comps';
import {computed} from "../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts";

@customElement('page-layout-account-aspire-comps')
class PageLayoutAccountAspireComps extends PageLayoutAspireComps {

    @property({type: Boolean, reflect: true})
    @computed('route')
    get mobileSecondaryNavOpen() {
        return !!this.route.current.data?.mobileSecondaryNavOpen;
    };

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            :host {
                --header-spacing: 0px;

                nav.subNav {
                    position: fixed;
                    top: var(--header-bottom);
                    left: 0;
                    right: 0;
                    box-shadow: 0 0 3px rgba(32, 32, 32, .2);
                    box-shadow: 0 0 1px white, 0 0 3px rgba(32, 32, 32, .2);
                    border-bottom: solid #e6e6e6 1px;
                    color: #f9f9f9;
                    background: var(--primary-text-color);
                    z-index: 2;
                }

                @media screen and (min-width: 1025px) {
                    nav.subNav .mobileOnly {
                        display: none;
                    }
                }

                .subNavPadding {
                    height: 50px;
                    margin-bottom: 25px;
                }

                @media screen and (max-width: 1024px) {
                    nav.subNav component-menu {
                        transform-origin: top;
                        transform: scaleY(0);
                        transition: .125s;
                        position: absolute;
                        top: 48px;
                        left: 0;
                        right: 0;
                        padding: 25px;
                        background: var(--primary-text-color);
                        opacity: 0;
                        --menus-group-items-flex-direction: column;
                        box-shadow: 0 0 1px rgba(32, 32, 32, .3);
                        overflow: auto;
                        max-height: calc(100vh - 172px);
                    }

                    &[mobile-secondary-nav-open] nav.subNav component-menu {
                        transform: scaleX(1);
                        opacity: 1;
                    }
                }
            }
        `
    ];

    doRenderHeader() {
        return html`
            ${super.doRenderHeader()}
            <nav class="subNav">
                <div class="gridContainer">
                    <component-menu id="menu"
                                    style="margin-top: auto; margin-bottom: auto; flex: 1"
                                    .menuId="${config.site.menus.accountMenuId}"></component-menu>

                    <a href="/account" style="--col: 10; margin: auto; font-weight: bold" class="mobileOnly">
                        My Account
                    </a>


                    <a .extendHistoryData="${{mobileSecondaryNavOpen: 1}}"
                       class="mobileOnly"
                       style="--col: 2; margin: auto 0 auto 0; line-height: 0; text-align: right; "
                       @click="${(e: MouseEvent) => {
                           if (this.mobileSecondaryNavOpen) {
                               history.back();
                               e.preventDefault();
                           }
                       }}">
                        <component-button
                                style="min-width: 0; background: none; padding: 0; --padding: 0.7em 15px;"
                                .raised="${false}">
                            <component-icon
                                    icon="icons:${this.mobileSecondaryNavOpen ? 'close' : 'menu'}"></component-icon>
                        </component-button>
                    </a>
                </div>
            </nav>
            <div class="subNavPadding"></div>
        `;
    }


    override render() {
        return super.render();
    }


    // closeNavOnRouteChange(_path: string) {
    //     super.closeNavOnRouteChange(_path);
    //
    //     this.mobileSecondaryNavOpen = false;
    // }

}


declare global {
    interface HTMLElementTagNameMap {
        'page-layout-account-aspire-comps': PageLayoutAccountAspireComps;
    }
}