import {customElement} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {loadBundle} from 'firebase/firestore';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../helpers/decorators/ComputedDecotratorHelper';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper';
import {FirestoreDocument} from '../controllers/FirestoreDocument';
import {html} from 'lit';

@customElement('firestore-preloader')
class FirestorePreloader extends BunnyElement {

    @property({type: String})
    handler: string;

    @property({type: String})
    resourceId: string;

    @property({type: String})
    version: string;

    @property({type: Object})
    @computed('handler', 'resourceId', 'version')
    get waitFor() {
        if (!this.resourceId) return;

        return new Promise<void>(async (s) => {
            let db = FirestoreDocument.db;

            let resp = await fetch(`/_/data?handler=${this.handler}&id=${this.resourceId}&version=${this.version}`);
            await db;

            // Load the bundle contents into the Firestore SDK
            console.time(`loading bundle /_/data?handler=${this.handler}&id=${this.resourceId}&version=${this.version}`);
            await loadBundle(await db, resp.body ?? await resp.arrayBuffer());
            console.timeEnd(`loading bundle /_/data?handler=${this.handler}&id=${this.resourceId}&version=${this.version}`);
            s();
        });
    };

    override render() {
        return html`

        `;
    }

    @observe('waitFor')
    delayCacheDocuments(waitFor: Promise<void>) {
        FirestoreDocument.delayCacheDocuments(waitFor);
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'firestore-preloader': FirestorePreloader;
    }
}