import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-purchase-quantity')
export class ComponentShopPurchaseQuantity extends BunnyElement {

    @property({type: Number, notify: true})
    value: number;

    @property({type: Number})
    min: number = 1;

    @property({type: Number})
    max: number = Number.MAX_SAFE_INTEGER;

    @property({type: Array})
    options: number[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                text-align: center;
            }

            .options {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            .options > * {
                flex: 1
            }

            component-button, component-button-toggle {
                background-color: var(--secondary-text-color-lighter);
                color: white;
                font-weight: bold;
                font-size: 25px;
                --padding: 15px 5px;
                min-width: 0;
                margin: 2px;
                max-width: 80px;
            }


            component-button-toggle[active] {
                background-color: var(--attention-color);
            }

            component-button-toggle[disabled]:not([active]) {
                opacity: 0.35;
            }

            component-input-number {
                margin: auto;
                max-width: 200px;
                font-size: 20px;
                margin-bottom: 20px;
                --input-container-border-radius: 0;


                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input {
                    text-align: center;
                    padding: 8px 0;
                    -moz-appearance: textfield;
                }
            }

            component-input-number component-button {
                width: 50px;
                height: 50px;
                vertical-align: middle;
                --padding: 0 !important;
                background: none;
                color: var(--primary-text-color);

                component-icon {
                    --icon-width: 35px;
                    --icon-height: 35px;
                }
            }
        `,
    ];

    override render() {
        return html`
            <div class="options">
                ${this.options.map(item => html`
                    <component-button-toggle @click="${(_: MouseEvent) => this.setValue(item)}"
                                             .active="${item === this.value}"
                                             ?disabled="${this.disableQuantityButton(item, this.value, this.max)}"
                                             .raised="${false}">
                        ${item}
                    </component-button-toggle>
                `)}
                <component-button-toggle @click="${(_: MouseEvent) => this.setValue(this.max)}"
                                         .active="${this.max === this.value}"
                                         ?disabled="${this.max === this.value}"
                                         .raised="${false}">
                    Max
                </component-button-toggle>
            </div>
            <component-input-number id="quantityInput"
                                    @blur="${this.clampValue}"
                                    .value="${this.bind.value}"
                                    .min="${this.min}"
                                    .max="${this.max}"
                                    max-length="10"
                                    class="borderBottomOnly">
                <component-button slot="prefix"
                                  @click="${(_: MouseEvent) => this.increaseValue(-1)}"
                                  ?disabled="${this.value === 1}"
                                  style="font-size: 30px"
                                  alt="Less quantity"
                                  .raised="${false}">
                    <component-icon icon="icons:chevron-left" title="Less quantity"></component-icon>
                </component-button>
                <component-button slot="suffix"
                                  @click="${(_: MouseEvent) => this.increaseValue(1)}"
                                  ?disabled="${this.value === this.max}"
                                  alt="More quantity"
                                  .raised="${false}">
                    <component-icon icon="icons:chevron-right" title="More quantity"></component-icon>
                </component-button>
            </component-input-number>
            ${this.value === this.max ? html`
                <small style="width: 100%">
                    You have reached the maximum that is allowed to be purchased
                </small>
            ` : undefined}
        `;
    }

    increaseValue(increment: number) {
        this.setValue(this.value + increment);
    }

    setValue(value: number) {
        this.value = value;

        this.clampValue();
    }

    clampValue() {
        if (this.value < this.min) {
            this.value = this.min;

        } else if (this.value > this.max) {
            this.value = this.max;
        }
    }

    disableQuantityButton(buttonValue: number, value: number, max: number) {
        return value === buttonValue || buttonValue > max;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-purchase-quantity': ComponentShopPurchaseQuantity;
    }
}
