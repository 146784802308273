import Package from '../../__internal/shared/Package';
import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import {ELASTICSEARCH_PACKAGE_ID} from '../../elasticsearch/shared';

export const INPUT_ELASTICSEARCH_SELECT_PACKAGE_ID = 'input-elasticsearch-select';

export class InputElasticsearchSelectPackage extends Package {
    id: string = INPUT_ELASTICSEARCH_SELECT_PACKAGE_ID;
    name: string = 'Input elasticsearch select';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, ELASTICSEARCH_PACKAGE_ID];
}