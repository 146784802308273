import {INTERNAL_PACKAGE_ID} from '../../__internal/shared';
import {ADMIN_PACKAGE_ID} from '../../admin/shared';
import Package from '../../__internal/shared/Package';

export const FORM_CLASS_ACTION_BUTTON = '__formActionButton';

export const FORM_ACTION_BUTTON_ACTION_PROP = 'action';

export const FORM_ELEMENT_DEFAULT_VALUE_PROPERTY = 'value';

export enum FORM_ELEMENT_VALUE_TREATMENTS {
    RAW = 'raw',
    INT = 'int',
    FLOAT = 'float',
    BOOL = 'bool',
    INTERNAL = 'internal',
}

export const FORM_ELEMENT_DEFAULT_VALUE_TREATMENT: FORM_ELEMENT_VALUE_TREATMENTS = FORM_ELEMENT_VALUE_TREATMENTS.RAW;

export enum FORM_ACTION_BUTTON_ACTIONS {
    SAVE = 'save',
    DELETE = 'delete',
}


export const FORM_PACKAGE_ID = 'form';

export class FormPackage extends Package {
    id: string = FORM_PACKAGE_ID;
    name: string = 'Form';
    dependencies: string[] = [INTERNAL_PACKAGE_ID, ADMIN_PACKAGE_ID];
}