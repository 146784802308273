import {customElement, query} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {registerEditorElement, registerEditorElementProperty} from '../../../routing/local/helpers/DecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

let sharedVisibilityObserver: IntersectionObserver;
const getSharedObserver = (): IntersectionObserver => {
    if (!sharedVisibilityObserver) {
        sharedVisibilityObserver = new IntersectionObserver((entries, observer) => {
            for (let entry of entries) {
                if (!entry.isIntersecting) continue;

                observer.unobserve(entry.target);
                entry.target.classList.add('visible');
            }
        }, {
            root: null,
            rootMargin: '-100px',
            threshold: 0,
        });
    }

    return sharedVisibilityObserver;
};

@customElement('component-shop-product-features')
@registerEditorElement({
    key: 'component-shop-product-features',
    name: 'Shop product features',
    defaultProperties: {innerHTML: '<p>Some</p><p>Features</p>', heading: 'Features'},
})
class ComponentShopProductFeatures extends BunnyElement {

    @property({type: String})
    @registerEditorElementProperty({
        properties: {
            label: 'Heading',
        },
        component: 'component-input',
    })
    heading: string = 'Features';

    @query('slot')
    innerSlot: HTMLSlotElement;

    set innerHTML(value: string) {
        if (this.innerSlot) {
            this.innerSlot.assignedElements().forEach((_: Element) => getSharedObserver().unobserve(_));
        }


        super.innerHTML = value;


        if (this.innerSlot) {
            this.innerSlot.assignedElements().forEach((_: Element) => getSharedObserver().observe(_));
        }
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: flex;
                flex-wrap: wrap;
                padding: 0 !important;
                justify-content: center;
                --transition: .125s;
            }

            h3 {
                width: 100%;
                margin-left: 15px;
                margin-right: 15px;
            }

            ::slotted(p) {
                padding: 15px;
                padding-left: 12px;
                margin: 5px;
                background-color: var(--primary-text-color);
                color: white;
                flex: 1;
                width: 25%;
                flex-shrink: 0;
                min-width: 200px;
                max-width: 400px;
                border-radius: 3px;
                line-height: 1.2;
            }

            ::slotted(p) {
                border-left: solid var(--attention-color) 3px;
                --index: 0;
                opacity: 0;
                transform: scale(0);
                transition: var(--transition);
            }

            ::slotted(p.visible) {
                opacity: 1;
                transform: scale(1);
            }
        `,
    ];

    override render() {
        return html`
            ${this.heading ? html`
                <h3>${this.heading}</h3>
            ` : undefined}

            <slot id="slot"></slot>
        `;
    }

    async connectedCallback() {
        super.connectedCallback();

        await delayPromise();
        this.innerSlot.assignedElements().forEach(_ => getSharedObserver().observe(_));
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-product-features': ComponentShopProductFeatures;
    }
}