import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

@customElement('component-aspire-comps-winner-item')
class ComponentAspireCompsWinnerItem extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .itemContainer {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding-bottom: 15px;
            }

            h4 {
                background: var(--primary-color);
                color: white;
                padding: 10px 15px;
                margin: 0;
            }

            p {
                padding: 0 15px;
                margin-bottom: 0;
            }

            .itemContainer a > component-media-view-image {
                background-color: #f2f2f2;
                position: relative;
                box-shadow: 0 1px 0 0 rgba(142, 146, 135, .3);
                overflow: hidden;
            }

            a component-media-view-image > component-media-view-image {
                position: absolute;
                bottom: 0;
                right: 0;
                max-width: 33%;
                max-height: 33%;
                background-color: #f2f2f2;
                box-shadow: -1px -1px 3px 0 rgba(142, 146, 135, .6), 0 0 10px 0 rgba(32, 32, 32, .4);
                width: 100%;
                overflow: hidden;
            }
        `,
    ];


    override render() {
        let productImage = html`
            <component-media-view-image .media="${this.item._data.productImage}"
                                        type="default-thumbnail"
                                        fetch-method="cacheFirst"
                                        width="400"
                                        height="300"></component-media-view-image>`;

        return html`
            <div class="itemContainer">
                <a href="/previous-winners/${this.item.id}?utm_source=aspire&utm_medium=winnerInfo&utm_content=${encodeURIComponent(this.item._data.productName)}">
                    <h4>${this.item._data.productName}</h4>
                    ${this.item._data.winnerImage ? html`
                        <component-media-view-image .media="${this.item._data.winnerImage}"
                                                    type="default-thumbnail"
                                                    fetch-method="cacheFirst"
                                                    width="400"
                                                    height="300">
                            ${productImage}
                        </component-media-view-image>
                    ` : productImage}
                </a>

                <p>
                    <strong>Winner</strong> · ${this.item._data.winnerName}
                    ${this.item._data.location.county ? html`
                        <small>in</small> ${this.item._data.location.county}
                    ` : undefined}
                </p>
                ${this.item._data.ticketNumber ? html`
                    <p>
                        <strong>Winning ticket</strong> · ${this.item._data.ticketNumber}
                    </p>
                ` : ''}
                ${this.item._data.watchLiveLink ? html`
                    <p>
                        <a href="${this.item._data.watchLiveLink}" class="colorPrimary" target="_blank">
                            Watch live draw on FB ·
                            ${RenderingHelper._dateFormat(this.item._data.drawn, 'EEEE do MMM')}
                        </a>
                    </p>
                ` : undefined}
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-winner-item': ComponentAspireCompsWinnerItem;
    }
}