import {PageContentComponent} from '../../shared/helpers/FirestoreHelper';
import {spread, spreadEvents, spreadProps} from '@open-wc/lit-helpers';
import {html, unsafeStatic} from 'lit/static-html.js';
import {TemplateResult} from 'lit';
import {mergeData} from '../../../__internal/shared/helpers/DataHelper';
import {REGISTERED_FUNCTIONS} from '../../../__internal/shared/helpers/RegisteredFunctionsHelper';


const mergeEvents = (events: any) => {
    for (let _event of events) {
        for (let eventName in _event) {
            let registeredFunctionId = _event[eventName].registeredFunctionId;
            if (!registeredFunctionId) continue;

            let registeredFunction = REGISTERED_FUNCTIONS[registeredFunctionId];
            if (!registeredFunction) {
                console.error(`Registered function by id ${registeredFunctionId} missing`);
                continue;
            }

            _event[eventName] = registeredFunction;
        }
    }
};

export const createComponent = (componentConfig: PageContentComponent, data: any, extendedProperties: object | null = null, safeHtml = false): TemplateResult => {
    mergeData(componentConfig, data, undefined, safeHtml);
    if (extendedProperties) {
        mergeData(extendedProperties, data, undefined, safeHtml);
    }


    let tag = unsafeStatic(componentConfig.component);

    let events = componentConfig.events;
    if (events) {
        if (!Array.isArray(events)) {
            debugger;
            console.error('Component events should be a array of objects {event: handler}');
            events = [events];
        }

        mergeEvents(events);
    }


    if (events) {
        //TODO better way to do mutli level events cus this is soooo crap
        return html`
            <${tag}
                    ${componentConfig.properties ? spreadProps(componentConfig.properties) : undefined}
                    ${componentConfig.attributes ? spread(componentConfig.attributes) : undefined}
                    ${extendedProperties ? spreadProps(extendedProperties as any) : undefined}
                    ${events[0] ? spreadEvents(events[0]) : undefined}
                    ${events[1] ? spreadEvents(events[1]) : undefined}
                    ${events[2] ? spreadEvents(events[2]) : undefined}
                    ${events[3] ? spreadEvents(events[3]) : undefined}
                    ${events[4] ? spreadEvents(events[4]) : undefined}
            ></${tag}>
        `;

    } else {
        return html`
            <${tag}
                    ${componentConfig.properties ? spreadProps(componentConfig.properties) : undefined}
                    ${componentConfig.attributes ? spread(componentConfig.attributes) : undefined}
                    ${extendedProperties ? spreadProps(extendedProperties as any) : undefined}
            ></${tag}>
        `;
    }
};
// export const createComponent = (componentConfig: PageContentComponent, data: any, extendedProperties: object | null = null, safeHtml = false, existingComponent: HTMLElement|undefined = undefined): HTMLElement => {
//     mergeData(componentConfig, data, undefined, safeHtml);
//     if (extendedProperties) {
//         mergeData(extendedProperties, data, undefined, safeHtml);
//     }
//
//     let componentName = componentConfig.component;
//     if (!componentName) {
//         console.error('Component name is not set', componentConfig);
//         throw new Error('Component name is not set');
//     }
//
//     let component = existingComponent || document.createElement(componentName);
//     setProperties(component, {_pageData: data});
//
//     if (componentConfig.properties) {
//         setProperties(component, componentConfig.properties);
//     }
//
//     if (extendedProperties) {
//         setProperties(component, extendedProperties);
//     }
//
//
//     if (componentConfig.events) {
//         attachEvents(component, componentConfig.events);
//     }
//
//     return component;
// };