import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';


interface ShopProductType {
    listItemComponents?: {
        component: string;
        properties?: {
            [type: string]: any
        };
    }[];
    productPageComponents?: {
        component: string;
        properties?: {
            [type: string]: any
        };
    }[];
}

export const SHOP_PRODUCT_TYPES: {
    [key: string]: ShopProductType
} = {
    competition: {
        listItemComponents: [
            {
                component: 'component-aspire-comps-product-list-item',
                properties: {
                    item: ':item:',
                },
            },
        ],
        productPageComponents: [
            {
                component: 'component-aspire-comps-entry-list-mine-quick-view',
                properties: {
                    shopProductVariant: ':selectedVariant._ref.path:',
                    style: 'margin-bottom: 0',
                },
            },
            {
                component: 'component-aspire-comps-product-display',
                properties: {
                    product: ':product:',
                    productVariants: ':productVariants:',
                    selectedVariant: ':selectedVariant:',
                    selectedProductVariantStockLockState: ':selectedProductVariantStockLockState:',
                    formData: ':formData:',
                },
            },
        ],
    },
    autoDrawCompetition: {
        listItemComponents: [
            {
                component: 'component-aspire-comps-product-list-item',
                properties: {
                    item: ':item:',
                },
            },
        ],
        productPageComponents: [
            {
                component: 'component-aspire-comps-entry-list-mine-quick-view',
                properties: {
                    shopProductVariant: ':selectedVariant._ref.path:',
                    style: 'margin-bottom: 0',
                },
            },
            {
                component: 'component-aspire-comps-product-display',
                properties: {
                    product: ':product:',
                    productVariants: ':productVariants:',
                    selectedVariant: ':selectedVariant:',
                    selectedProductVariantStockLockState: ':selectedProductVariantStockLockState:',
                    formData: ':formData:',
                },
            },
        ],
    },
};


@customElement('component-shop-collection-list-item')
class ComponentShopCollectionListItem extends ComponentFirestoreCollectionListItemCell {

    @property({type: String})
    display: string = 'full';

    @computed('item')
    get _componentData(): any {
        return {
            item: (this.item as any)._data,
        };
    }

    @property({type: Object})
    @computed('item')
    get components() {
        if (!this.item) return [];

        return SHOP_PRODUCT_TYPES[this._componentData.item.type].listItemComponents;
    }


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let component = (this.components || [])[0];

        return html`
            <component-routing-link href="${this.href}${this.item.id}" id="content" style="height: 100%">
                ${component ? createComponent(component, this._componentData, {
                    display: this.display,
                }) : undefined}
            </component-routing-link>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-collection-list-item': ComponentShopCollectionListItem;
    }
}