import {customElement} from 'lit/decorators.js';
import {ComponentFirestoreCollectionList} from './component-firestore-collection-list';
import {BunnyElement} from './bunny-element';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper';
import {ComponentElasticsearchCollectionList} from '../../../elasticsearch/local/components/component-elasticsearch-collection-list';
import {bind} from '../helpers/decorators/BindDecoratorHelper';

@customElement('component-firestore-collection-list-control-pagination-limit')
class ComponentFirestoreCollectionListControlPaginationLimit extends BunnyElement {

    @property({type: String})
    label: string = 'Per page';

    @property({type: Object})
    list: ComponentFirestoreCollectionList;

    @property({type: Array})
    limits: number[] = [10, 20, 30, 45, 60, 90];

    @property({type: Number})
    limit: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    override render(){
        return html`
            <component-input-select .value="${this.bind.limit}" .options="${this.limits}">
            </component-input-select>
        `;
    }


    @observe('list')
    listUpdated(list: ComponentElasticsearchCollectionList){
        list.addEventListener('limit-changed', this.updateLimit);

        this.updateLimit();
    }

    @bind()
    updateLimit() {
        this.limit = this.list.limit;
    }

    @observe('limit')
    reflectLimitIntoListLimit(limit: number) {
        this.list.limit = limit;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-control-pagination-limit': ComponentFirestoreCollectionListControlPaginationLimit;
    }
}