import {customElement} from 'lit/decorators.js';
import {unsafeSVG} from 'lit/directives/unsafe-svg.js';
import {html} from 'lit';
import {BunnyElement} from './bunny-element';
import {scss} from '../helpers/StyleHelper';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';

interface Settings {
    size?: number;
}

const REGISTERED_ICONS: Record<string, Record<string, { settings: Settings, contents: string }>> = {};

export const registerIcons = (group: string, icons: Record<string, string>, settings?: Settings) => {
    settings ??= {};
    settings.size ??= 24;

    REGISTERED_ICONS[group] ??= {};

    for (let [name, icon] of Object.entries(icons)) {
        REGISTERED_ICONS[group][name] = {
            settings: settings,
            contents: icon,
        };
    }
};

const getIcon = (id: string) => {
    if (!id.includes(':')) {
        id = `icons:${id}`;
    }

    let [group, name] = id.split(':');

    let icon = REGISTERED_ICONS[group][name];
    if (!icon) throw new Error(`Unable to locate icon(${id})`);

    return icon;
};

@customElement('component-icon')
class ComponentIcon extends BunnyElement {

    @property({type: String})
    icon: string;

    static override styles = [
        // language=SCSS
        scss`
            :host {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: relative;
                vertical-align: middle;
                fill: var(--icon-fill-color, currentcolor);
                stroke: var(--icon-stroke-color, none);
                width: var(--icon-width, 24px);
                height: var(--icon-height, 24px);;
            }

            :host([hidden]) {
                display: none
            }
        `,
    ];

    override render() {
        let icon = getIcon(this.icon);
        return html`
            <svg viewBox="0 0 ${icon.settings.size} ${icon.settings.size}" preserveAspectRatio="xMidYMid meet"
                 focusable="false"
                 style="display: block; width: 100%; height: 100%;">
                <title>${this.generateIconTitle(this.title, this.icon)}</title>
                ${unsafeSVG(icon.contents)}
            </svg>
        `;
    }

    private generateIconTitle(elementTitle: string, icon: string) {
        if (elementTitle) return elementTitle;


        let title = icon.replace(/[a-z-]+:/, '')
            .replace(/-/g, ' ');

        return `${title[0].toUpperCase()}${title.substring(1)}`;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-icon': ComponentIcon;
    }
}