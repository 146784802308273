import {customElement} from 'lit/decorators.js';
import {ComponentShopCheckout} from './component-shop-checkout';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';

@customElement('component-shop-checkout-payment')
class ComponentShopCheckoutPayment extends ComponentShopCheckout {

    @property({type: Object})
    state: any;

    private _stateTrackingCurrentPath: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let paymentComponent = this.state?.paymentComponent;

        return html`
            <div id="payment">
                ${paymentComponent ? createComponent(paymentComponent, {}) : undefined}
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.loadState();

        this._stateTrackingCurrentPath = location.pathname;
    }

    @bind()
    @listen('popstate', window)
    onPopstate() {
        if (this._stateTrackingCurrentPath !== location.pathname) return;
        this.loadState();
    }

    @bind()
    @listen('location-changed', window)
    onLocationChanged() {
        if (this._stateTrackingCurrentPath !== location.pathname) return;
        this.loadState();
    }

    loadState() {
        this.state = history?.state?.placeOrderState ||
            (window as any).__shopPlaceOrderState || //TODO dont like this one, maybe remove soon???
            null;
    }

    @observe('state')
    redirectToCheckoutOnUnknownState(state: any) {
        if (!state) {
            HistoryHelper.replaceState(`/shop/checkout/${this.cartId}`);
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-checkout-payment': ComponentShopCheckoutPayment;
    }
}