import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {nextSunday} from 'date-fns';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-shop-admin-product-competition-time-defaults')
class ComponentShopAdminProductCompetitionTimeDefaults extends BunnyElement {

    @property({type: Object, notify: true})
    value: any;

    @property({type: Object})
    @computed('value') //TODO check if sub computed works
    get published() {
        let published = this.value?.published;
        if (!published) return false;

        return published <= Date.now();
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-button {
                min-width: 0;
                border-radius: 0;
            }

            .publish {
                background-color: var(--primary-color);
                color: white;

                &[active] {
                    background-color: var(--attention-color);
                }
            }
        `,
    ];

    override render() {
        return html`
            Draws

            <div style="display: flex; margin-top: 5px">
                <component-button @click="${(_: MouseEvent) => this.updateFinishAndDraw(604800)}">
                    1wk wed
                </component-button>
                <component-button @click="${(_: MouseEvent) => this.updateFinishAndDraw(1209600)}">
                    2wk wed
                </component-button>

                <component-button-toggle class="publish"
                                         .active="${this.published}"
                                         @click="${this.togglePublish}"
                                         style="margin-left: auto">
                    ${this.published ? 'Unpublish' : 'Publish'}
                </component-button-toggle>
            </div>
        `;
    }

    updateFinishAndDraw(interval: number) {
        let finished = nextSunday(new Date());
        finished.setHours(21, 0, 0, 0);

        finished.setTime(finished.getTime() + (interval * 1000));


        this.value.finished = finished;
        this.value.drawDate = new Date(finished.getFullYear(), finished.getMonth(), finished.getDate() + 3, 19, 30, 0, 0);
        this.requestUpdate('value');
    }

    togglePublish() {
        let newPublished = new Date();
        if (this.published) {
            newPublished.setTime(newPublished.getTime() + (86400 * 365 * 2 * 1000));
            newPublished.setMonth(0, 1);
            newPublished.setHours(0, 0, 0, 0);
        }


        this.value.published = newPublished;
        this.requestUpdate('value');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-product-competition-time-defaults': ComponentShopAdminProductCompetitionTimeDefaults;
    }
}