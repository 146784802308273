import {customElement} from 'lit/decorators.js';
import {getApp} from 'firebase/app';
import {
    connectStorageEmulator,
    FirebaseStorage,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytesResumable,
    UploadTaskSnapshot,
} from 'firebase/storage';
import {addDoc, collection, deleteDoc, getDoc, updateDoc} from 'firebase/firestore';
import {BunnyElement} from './bunny-element';
import {firestoreTimestamp} from '../helpers/FirestoreHelper';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {computed} from '../helpers/decorators/ComputedDecotratorHelper';
import {html} from 'lit';
import {config, ENV} from '../../../../config';
import {Auth} from '../../../auth/local/controllers/Auth';
import {GLOBAL_GRAPPYFILL} from '../../../../aspire-app';
import {FIRESTORE_COLLECTION_MEDIA} from '../../../media/shared/helpers/FirebaseHelper';
import {FirestoreDocument} from '../controllers/FirestoreDocument';

let storage: FirebaseStorage;

@customElement('firebase-storage-file')
export class FirebaseStorageFile extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Object, notify: true})
    uploadReference: any;

    @property({type: String})
    type: string;

    @property({type: Object})
    meta: any;

    @property({type: String})
    access: 'public' | 'private' = 'private';

    @property({type: Object})
    @computed('uploadReference')
    get _uploadFile() {
        return this.uploadReference._uploadFile;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
        `;
    }


    async createMediaReference(file: File) {
        let type = file.name.split('.').reverse()[0];

        if (this.uploadReference.media) {
            await updateDoc(this.uploadReference.media, {
                updated: firestoreTimestamp(),
                state: 'pre-upload',
                originalFile: file.name,
                meta: this.meta,
                access: this.access,
                items: {
                    original: null,
                },
            });
            this.requestUpdate('uploadReference');

            return ((await getDoc(this.uploadReference.media)).data() as any).key;

        } else {
            let storagePath = this.auth.user?.uid;
            let key = `${storagePath}/${Date.now()}-${this.generateRandomId(40)}.${type}`;


            let reference = await addDoc(collection(FirestoreDocument.db, FIRESTORE_COLLECTION_MEDIA), {
                owner: GLOBAL_GRAPPYFILL.account?._ref,
                updated: firestoreTimestamp(),
                created: firestoreTimestamp(),
                state: 'pre-upload',
                type: this.type,
                meta: this.meta,
                access: this.access,
                originalFile: file.name,
                key: key,
                items: {
                    original: null,
                },
            });

            this.uploadReference.media = reference;
            this.requestUpdate('uploadReference');
            return key;
        }
    }

    generateRandomId(length = 40) {
        // dec2hex :: Integer -> String
        function dec2hex(dec: number) {
            return ('0' + dec.toString(16)).substr(-2);
        }

        // generateId :: Integer -> String
        let randomData = new Uint8Array(length / 2);
        window.crypto.getRandomValues(randomData);
        return Array.from(randomData, dec2hex).join('');
    }


    async upload(file: File) {
        try {
            await this._upload(file);

        } catch (e: any) {
            this.uploadReference._uploadFile.uploading = false;
            this.uploadReference._uploadFile.error = true;
            this.uploadReference._uploadFile.status = 'Error: ' + e.message + (ENV === 'local' ? e.stack : '');
            this.uploadReference._notifyFileChanges();
        }
    }

    async waitForFirebaseStorage() {
        let firebaseApp = getApp();
        storage = getStorage(firebaseApp, `gs://${config.google.firebase.userStorage}`);
        if (ENV === 'local') {
            connectStorageEmulator(storage, 'localhost', 9199);
        }
    }

    async _upload(file: File) {
        await this.waitForFirebaseStorage();


        let key = await this.createMediaReference(file);
        this.uploadReference._uploadFile.status = 'Connected';
        this.uploadReference._notifyFileChanges();


        let location = ref(storage, key);
        let uploadTask = uploadBytesResumable(location, file);
        let markedUploading = false;
        uploadTask.on(
            'state_changed',
            async (snapshot: UploadTaskSnapshot) => {
                if (!markedUploading) {
                    markedUploading = true;
                    await updateDoc(this.uploadReference.media, {state: 'uploading'});
                }

                this.uploadReference._uploadFile.status = 'Uploading';
                this.uploadReference._uploadFile.loaded = snapshot.bytesTransferred;
                this.uploadReference._notifyFileChanges();
            }, async (error: any) => {
                console.error('Failed uploading storage file', error, this.uploadReference);

                if (this.uploadReference && this.uploadReference.media) {
                    await deleteDoc(this.uploadReference.media);
                }

                this.uploadReference._uploadFile.status = 'Error: ' + error.message;
                this.uploadReference._uploadFile.uploading = false;
                this.uploadReference._uploadFile.error = true;
                this.uploadReference._notifyFileChanges();
            }, async () => {
                // Upload completed successfully, now we can get the download URL
                let downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                await updateDoc(this.uploadReference.media, {
                    state: 'unprocessed',
                    key: key,
                    'items.original': downloadURL,
                });
                this.requestUpdate('uploadReference');

                this.uploadReference._uploadFile.status = 'Uploaded';
                this.uploadReference._uploadFile.uploading = false;
                this.uploadReference._uploadFile.complete = true;
                this.uploadReference._notifyFileChanges();
            },
        );
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'firebase-storage-file': FirebaseStorageFile;
    }
}