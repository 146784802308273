import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

export enum AttachmentTypes {
    REGION = 'region',
    ELEMENT = 'element',
}

@customElement('component-page-editor-content-control')
export class ComponentPageEditorContentControl extends BunnyElement {

    @property({type: Object})
    attachment: Element;

    @property({type: String})
    dataPath: string;

    @property({type: String, reflect: true})
    attachmentType: AttachmentTypes;

    __updatePositionIntervalId: number;

    static override styles = [
        // language=SCSS
        scss`
            :host {
                position: fixed;
            }

            #overlay {
                position: absolute;
                background: rgba(32, 32, 32, .2);
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            #control {
                position: absolute;
                top: 0;
                left: -25px;
                width: 25px;
                height: 25px;
                background: grey;
                transform: scale(0);
                transition: .125s;
                transition-delay: .25s;
            }

            :host([attachment-type="element"]) #control {
                left: 0;
            }


            :host(:hover) #control {
                transition-delay: .05s;
                transform: scale(1);
            }
        `
    ];

    override render() {
        return html`
            <div id="overlay"></div>
            <div id="control"></div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.updatePosition();
        //TODO do a better method that works on data being dirty
        this.__updatePositionIntervalId = window.setInterval(() => {
            this.updatePosition();
        }, 20);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        clearInterval(this.__updatePositionIntervalId);
    }

    updatePosition() {
        let attachmentPosition = this.attachment.getBoundingClientRect();

        this.style.top = `${attachmentPosition.top}px`;
        this.style.left = `${attachmentPosition.left}px`;
        this.style.width = `${attachmentPosition.width}px`;
        this.style.height = `${attachmentPosition.height}px`;
    }

    private calculateIndex(y: number) {
        let parentRect = this.attachment.getBoundingClientRect();

        let children = this.attachment.children;
        for (let i = 0; i < children.length; i++) {
            let childRect = children[i].getBoundingClientRect();

            let childDepthInsideParentRect = childRect.y - parentRect.y;
            if (childDepthInsideParentRect < y) continue;

            return i;
        }


        return children.length;
    }

    @listen('click', 'overlay')
    edit(e: Event) {
        let index = 0;
        if (e instanceof MouseEvent && this.attachment) {
            index = this.calculateIndex(e.offsetY);
        }


        this.dispatchEvent(new CustomEvent('edit', {
            detail: {
                attachmentType: this.attachmentType,
                dataPath: this.dataPath,
                index: index,
            },
            bubbles: true,
        }));
    }

    @listen('click', 'control')
    select(e: Event) {
        this.dispatchEvent(new CustomEvent('select', {
            detail: {
                controlElement: e.currentTarget,
                attachmentType: this.attachmentType,
                dataPath: this.dataPath,
            },
            bubbles: true,
        }));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-page-editor-content-control': ComponentPageEditorContentControl;
    }
}