import {customElement} from 'lit/decorators.js';
import {ComponentAnalyticsDataVisualizerList} from './component-analytics-data-visualizer-list';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-analytics-data-visualizer-list-shop-orders')
class ComponentAnalyticsDataVisualizerListShopOrders extends ComponentAnalyticsDataVisualizerList {

    @property({type: Array})
    fields: { name: string, style?: string, title: string }[] = [
        {name: '', style: '--col-md: 5', title: 'Order number'},
        {name: 'item.items.doc_count', title: 'Items'},
        {name: 'item.value.sum', style: '--col-md: 6', title: 'Value'},
    ];

    // language=HTML
    renderRow(item: any) {
        return html`
            <div style="--col-md: 5">
                <a href="/admin/shop/products/${item.key}">${item.key}</a>
            </div>
            <div style="--col-md: 1">
                ${item.items.doc_count}
            </div>
            <div style="--col-md: 6">
                &pound;${RenderingHelper._currencyFormat(item.value.sum)}
            </div>
        `;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-data-visualizer-list-shop-orders': ComponentAnalyticsDataVisualizerListShopOrders;
    }
}