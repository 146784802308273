import {customElement, queryAssignedElements} from 'lit/decorators.js';
import {html} from 'lit';
import {ComponentInputRadioItem} from './component-input-radio-item';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';


@customElement('component-input-radio-group')
class ComponentInputRadioGroup extends BunnyElement {

    private mutationObserver: MutationObserver;

    @property({notify: true})
    value: any;

    @queryAssignedElements()
    itemElements: NodeListOf<ComponentInputRadioItem>;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
            }
        `,
    ];

    override render() {
        return html`
            <slot>

            </slot>
        `;
    }

    async connectedCallback() {
        super.connectedCallback();

        await delayPromise();
        this.mutationObserver = new MutationObserver((mutations) => {
            for (let mutation of mutations) {
                for (let addedNode of mutation.addedNodes) {
                    if (!(addedNode instanceof ComponentInputRadioItem)) continue;

                    this.doAttachChild(addedNode);
                }
                for (let removedNode of mutation.removedNodes) {
                    if (!(removedNode instanceof ComponentInputRadioItem)) continue;

                    this.doDetachChild(removedNode);
                }
            }
        });

        this.mutationObserver.observe(this, {
            childList: true,
        });

        this.itemElements.forEach(radioItem => {
            this.doAttachChild(radioItem);
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.mutationObserver.disconnect();
        this.mutationObserver = undefined as any;

        this.itemElements.forEach(radioItem => {
            this.doDetachChild(radioItem);
        });
    }

    private doAttachChild(item: ComponentInputRadioItem) {
        item.active = this.value === item.name;

        item.addEventListener('active-changed', this.onRadioItemActiveChanged as any);
    }

    private doDetachChild(item: ComponentInputRadioItem) {
        item.removeEventListener('active-changed', this.onRadioItemActiveChanged as any);
    }

    @bind()
    onRadioItemActiveChanged(e: CustomEvent) {
        let radioItem = e.currentTarget as ComponentInputRadioItem;

        if (radioItem.active) {
            this.value = radioItem.name;
        }
    }

    @observe('value')
    updateActiveValue(value: any) {
        for (let child of [...this.children] as ComponentInputRadioItem[]) {
            child.active = value === child.name;
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-radio-group': ComponentInputRadioGroup;
    }
}