import {customElement} from 'lit/decorators.js';
import {ComponentInput} from './component-input';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper.ts';

@customElement('component-input-datetime')
export class ComponentInputDatetime extends ComponentInput {

    @property()
    type = 'datetime-local';

    @property()
    valueAttribute = 'valueAsNumber';

    @property({type: Number})
    step = 60;

    @property({type: Object})
    get extraAttributes() {
        return {
            ...(this.step ? {
                step: this.step,
            } : {}),
        } as any;
    }

    @observe('value')
    updateHasValue(value: any) {
        this.hasValue = value && value instanceof Date && !isNaN(value as any); // "Value is not assignable to number", but invalid date is NaN
    }

    override convertValueToNative(value: any): any {
        if (typeof value === 'string') {
            this.value = new Date(value);
            value = this.value;
        }

        if (value instanceof Date) {
            value.setSeconds(0);
            value.setMilliseconds(0);
            value = value.getTime() - (new Date().getTimezoneOffset() * 60000);
        }

        return super.convertValueToNative(value);
    }

    override convertNativeToValue(native: any): any {
        if (native instanceof Date) return native;

        return new Date(native + (new Date().getTimezoneOffset() * 60000));
    }

    @listen('paste')
    onPaste(e: Event) {
        if (!(e instanceof ClipboardEvent)) return;

        let value = e.clipboardData?.getData('text');
        if (value) {
            this.value = new Date(value);
        }
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'component-input-datetime': ComponentInputDatetime;
    }
}
