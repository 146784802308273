import {FirestoreCollection} from '../../controllers/FirestoreCollection';
import {FirestoreDocument} from '../../controllers/FirestoreDocument';
import {FetchMethod, FirestoreData, LOADING_STATE} from '../../controllers/FirestoreData';
import {CollectionReference, DocumentData} from 'firebase/firestore';
import {QueryConstraint} from '@firebase/firestore';
import {property} from '../../helpers/decorators/PropertyDecoratorHelper';
import {BunnyElement} from '../../components/bunny-element';
import {IMPORT_TEST_COMPONENTS} from '../../../../../config';

if (IMPORT_TEST_COMPONENTS) {
    class FirestoreDataTester<T extends DocumentData = any> extends FirestoreData<any, T> {
        data!: T;

        async* fetchData(): AsyncGenerator<[LOADING_STATE, any | undefined]> {
            yield [LOADING_STATE.LOADED, this.path];
        }

        generateRef(path: string): any {
            return {
                path: path,
            };
        }

        async receiveData(_loadingState: LOADING_STATE, data: any): Promise<void> {
            this.data = data;
        }
    }

    class ComponentTesterFirestoreCollection extends BunnyElement {
        @property({type: Object})
        collection: FirestoreCollection;

        @property({type: Object})
        document: FirestoreDocument;

        @property({type: Object})
        data: FirestoreDataTester;

        createCollection(path: string, options: {
            method?: FetchMethod,
            queryExtendFunction?: (ref: CollectionReference<any>) => QueryConstraint[]
        }) {
            this.collection = new FirestoreCollection(
                this,
                path,
                options,
            );
        }

        createDocument(path: string, options: {
            method?: FetchMethod
        }) {
            this.document = new FirestoreDocument(
                this,
                path,
                options,
            );
        }

        createData(path: string, options: {
            method?: FetchMethod
        }) {
            this.data = new FirestoreDataTester(
                this,
                path,
                options,
            );
        }

        async waitForCollectionUpdate(waitTime = 1000) {
            await new Promise<void>(res => {
                let timeout: any;
                let eventListener = () => {
                    this.removeEventListener('collection-changed', eventListener);
                    clearTimeout(timeout);
                    res();
                };

                this.addEventListener('collection-changed', eventListener);
                timeout = setTimeout(eventListener, waitTime);
            });
        }

        async waitForDocumentUpdate(waitTime = 1000) {
            await new Promise<void>(res => {
                let timeout: any;
                let eventListener = () => {
                    this.removeEventListener('document-changed', eventListener);
                    clearTimeout(timeout);
                    res();
                };

                this.addEventListener('document-changed', eventListener);
                timeout = setTimeout(eventListener, waitTime);
            });
        }

        async waitForDataUpdate(waitTime = 1000) {
            await new Promise<void>(res => {
                let timeout: any;
                let eventListener = () => {
                    this.removeEventListener('data-changed', eventListener);
                    clearTimeout(timeout);
                    res();
                };

                this.addEventListener('document-changed', eventListener);
                timeout = setTimeout(eventListener, waitTime);
            });
        }

        saveDocument() {
            return this.document.save();
        }

        resolveFetchMethod(fetchMethod?: string) {
            return FirestoreData.resolveFetchMethod(fetchMethod);
        }
    }

    customElements.define('component-tester-firestore', ComponentTesterFirestoreCollection);
}