import {JSONParseLocal} from '../../../__internal/local/helpers/DataHelper';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper';
import {quickDotPut} from '../../../__internal/shared/helpers/DotHelper';

const SESSIONSTORAGE_KEY = '_authRegisterMeta';

export const getRegisterMeta = () => {
    try {
        let data = sessionStorage[SESSIONSTORAGE_KEY] || '{}';

        return JSONParseLocal(data);

    } catch (e) {
        console.error('Failed loading auth register meta');
    }

    return {};
};

export const saveRegisterMeta = (data: any) => {
    try {
        if (!data) {
            delete sessionStorage[SESSIONSTORAGE_KEY];
            return;
        }

        sessionStorage[SESSIONSTORAGE_KEY] = JSONStringify(data);

    } catch (e) {
        console.error('Failed saving auth register meta');
    }
};


export const addRegisterMeta = (key: string, value: any) => {
    let data = getRegisterMeta();

    quickDotPut(key, value, data);

    saveRegisterMeta(data);
};