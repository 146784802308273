import {customElement} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {storageBoundLocalStorage} from '../helpers/decorators/StorageBoundDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {showToast} from '../helpers/ToastHelper';
import {listen} from '../helpers/decorators/ListenDecoratorHelper';
import {requestIdleCallback} from '../helpers/TaskHelper.ts';

const PWA_INSTALL_PROMPT_INTERVAL = 14 * 86400 * 1000;

let instance: ComponentPwaInstallPrompt;

@customElement('component-pwa-install-prompt')
export class ComponentPwaInstallPrompt extends BunnyElement {

    static getInstance() {
        return instance;
    }

    @property({type: Boolean, reflect: true})
    showSideBar: boolean = false;

    @property({type: Object})
    pwaInstallPrompt: object;

    @property({type: Object, notify: true})
    @storageBoundLocalStorage('app-pwa-install-prompted', '0', parseInt)
    lastPwaInstallPrompted: number;

    get platform(): 'standard' | 'ios' | 'none' {
        if ('BeforeInstallPromptEvent' in window) return 'standard';
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) return 'ios';

        return 'none';
    }

    get isPwa() {
        if (this.platform === 'standard') return window.matchMedia('(display-mode: standalone)').matches;
        if (this.platform === 'ios') return 'standalone' in window.navigator && !!window.navigator.standalone;

        return false;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-button#sideBar {
                position: fixed;
                bottom: 160px;
                left: 0;
                background: var(--attention-color);
                color: #fff;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                padding-right: 45px;
                z-index: 1;
                transition: .3s;
                transform: translateX(-110%);
                opacity: 0;
                margin: 0;
                height: 40px;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                min-width: 0;
                --padding: 0 0 0 11px;
            }

            @keyframes showSideBar {
                0% {
                    transform: translateX(-110%);
                    opacity: 0;
                }
                20%, 100% {
                    transform: translateX(0);
                    opacity: 1;
                }
            }

            @media screen and (min-height: 500px) {
                :host([show-side-bar]) component-button#sideBar {
                    animation: showSideBar 4s;
                    animation-fill-mode: forwards;
                }
            }

            component-button#sideBar component-icon {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
        `,
    ];

    override render() {
        return html`
            <component-button id="sideBar" @click="${this.pwaPrompt}">
                Get the app!
                <component-icon icon="icons:file-download"></component-icon>
            </component-button>
        `;
    }

    constructor() {
        super();

        //if the pwa is running then bail early
        if (this.isPwa) return;

        if (this.platform === 'standard') {
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                // Stash the event so it can be triggered later.
                this.pwaInstallPrompt = e;
                this.doTrackPwaPrompt('available');

                if (!('prompt' in this.pwaInstallPrompt)) {
                    //no way to delay prompt it yet so just assume instant prompt
                    this.doTrackPwaPrompt('prompted');

                } else {
                    e.preventDefault();
                }


                if ((this.pwaInstallPrompt as any).userChoice) {
                    (this.pwaInstallPrompt as any).userChoice.then((choiceResult: any) => {
                        this.doTrackPwaPrompt(choiceResult.outcome);
                    });
                }
            });

            //TODO decide if want to do this to track and debounce double track of manual vs prompted installs
            // window.addEventListener('appinstalled', () => {
            //     track('pwaPrompt', {
            //         state: 'accepted',
            //         platform: platform,
            //     });
            //
            //     this.pwaInstallPrompt = undefined as any;
            // });

        } else if (this.platform === 'ios') {
            requestIdleCallback(() => {
                this.doTrackPwaPrompt('available');
            });
        }
    }

    connectedCallback() {
        super.connectedCallback();

        instance = this;
    }

    private doTrackPwaPrompt(state: 'available' | 'prompted' | 'accepted' | 'dismissed') {
        if (this.platform === 'none') return;

        track('pwaPrompt', {
            platform: this.platform,
            state: state,
        });
    }

    async pwaPrompt(force = false) {
        switch (this.platform) {
            case 'none':
                return false;

            case 'standard':
                if (!(this.pwaInstallPrompt as any)?.prompt) {
                    if (force) {
                        await showToast('Sorry but the app is not currently available, this could be because its already installed or not available on your device.');
                    }

                    return false;
                }

                this.doTrackPwaPrompt('prompted');

                await (this.pwaInstallPrompt as any)?.prompt();
                break;

            case 'ios':
                //prompt is triggered from inside the ios-install-pwa.html page
                location.href = `/ios-install-pwa.html?returnUrl=${encodeURIComponent(location.pathname)}`;
        }

        return true;
    }

    @listen('track-event', window)
    attemptPwaPromptOnShopPurchase(e: Event) {
        if (!(e instanceof CustomEvent)) return;
        if (e.detail.event !== 'shopPurchase') return;
        if (this.platform === 'none') return;
        if (this.isPwa) return;

        if (this.platform === 'standard') {
            if (!(this.pwaInstallPrompt as any)?.prompt) return;
        }


        if (this.lastPwaInstallPrompted + PWA_INSTALL_PROMPT_INTERVAL > Date.now()) return;

        this.lastPwaInstallPrompted = Date.now();
        this.showSideBar = true;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-pwa-install-prompt': ComponentPwaInstallPrompt;
    }
}