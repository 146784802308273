import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {prepareFunction} from '../../../__internal/local/helpers/FirebaseFunctionHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {ENV} from '../../../../config';
import {html} from 'lit';

@customElement('component-notifications-auto-dismiss-all')
class ComponentNotificationsAutoDismissAll extends BunnyElement {

    override render(){
        return html`
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.dismissAll();
    }

    async dismissAll() {
        let dismissAll = await prepareFunction('notifications-dismissallv2', ENV === 'local' ? undefined : `${location.origin}/_`);

        await delayPromise(250);
        await dismissAll();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-auto-dismiss-all': ComponentNotificationsAutoDismissAll;
    }
}