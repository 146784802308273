import {JSONParse, JSONStringify} from '../../shared/helpers/DataHelper';
import {doc} from 'firebase/firestore';
import {FirestoreDocument} from '../controllers/FirestoreDocument';

export const JSONParseLocal = (text: string, reviver?: (this: any, key: string, value: any) => any) => {
    return JSONParse(text, (key, value) => {
        if (reviver) {
            value = reviver(key, value);
        }



        if (value && typeof value === 'object') {
            if (value.path && value.id) {
                let docRef = doc(FirestoreDocument.db, value.path);

                if (value.data) {
                    value = value.data;
                    Object.defineProperty(value, '_ref', {value: docRef});

                } else {
                    value = docRef;
                }
            }
        }


        return value;
    });
};

if(localStorage['testMode']){
    (window as any).__JSONParseLocal = JSONParseLocal;
    (window as any).__JSONStringify = JSONStringify;
}
