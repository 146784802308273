import {customElement, queryAll} from 'lit/decorators.js';
import {ElasticsearchQueryRequest} from '../../../elasticsearch/shared/helpers/ElasticsearchHelper';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper';
import {nodeParentSelectorSearch} from '../../../__internal/local/helpers/DomHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html, PropertyValues} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {ComponentInput} from '../../../inputs/local/components/component-input';


export interface Search {
    name: string;
    searchId: string;
    allowSelectionOfSearchQuery?: boolean;
    renderer?: object;
}

@customElement('component-input-elasticsearch-select-2')
class ComponentInputElasticsearchSelect2 extends BunnyElement {
    @property({type: String})
    label: string;

    @property({type: Object, notify: true})
    value: any;

    @property({type: Array})
    internalValue: any[] = [];

    @property({type: Boolean})
    multiple: boolean = false;

    @property({type: Boolean})
    readonly: boolean = false;

    @property({type: String})
    placeholder: string;

    @property({type: String, notify: true})
    searchValue: string = '';

    @property({type: Object})
    selectedSearch: Search;

    @property({type: Object})
    response: any;

    @property({type: String})
    searches: { [key: string]: Search };

    @computed('response')
    @property({type: Array})
    get results(): any[] {
        return this.response.hits?.hits?.map((_: any) => {
            let ret = _._source;

            Object.defineProperty(ret, '_hit', {value: _});

            return ret;
        }) || [];
    }

    @computed('response')
    @property({type: Array})
    get aggregations(): any[] {
        let ret = [];
        let aggs = this.response.aggregations;

        for (let aggregationName in aggs) {
            let buckets = aggs[aggregationName].buckets;

            for (let bucket of buckets) {
                let aggResult = {
                    [aggregationName]: bucket.key,
                    key: bucket.key,
                    aggregation: aggregationName,
                };

                Object.defineProperty(aggResult, '_bucket', {value: bucket});
                Object.defineProperty(aggResult, '_aggregation', {value: aggs[aggregationName]});

                ret.push(aggResult);
            }
        }

        return ret;
    }

    @computed('response', 'aggregations')
    @property({type: Array})
    get totalResults(): any[] {
        return this.response.hits.total.value + this.aggregations.length;
    }

    @property({type: Object})
    @computed('selectedSearch', 'searchValue')
    get query(): ElasticsearchQueryRequest | undefined {
        if (!this.selectedSearch) return undefined;

        return {
            id: this.selectedSearch.searchId,
            params: {
                q: this.searchValue.trim() + '*',
            },
        };
    };

    @property({type: Boolean, reflect: true})
    focused: boolean = false;

    @queryAll('#searchResults>*, #searchResults #searchResultsSelectQuery')
    searchResultsElements: NodeListOf<HTMLElement>;

    @property({type: Boolean})
    loading = true;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;
            }

            :host[hidden] {
                display: none !important;
            }

            component-icon {
                --iron-icon-height: 20px;
                --iron-icon-width: 20px;
                color: var(--disabled-text-color);
            }

            #searchResultsContainer .resultHighlight {
                font-weight: bold;
                @apply(--input-elasticsearch-select-result-highlight);
            }

            #searchResultsContainer .resultHighlight + .resultHighlight {
                margin-left: 3px;
            }

            #searchResultsContainer .itemSelected {
                display: none;
            }

            #searchResultsOuter {
                box-shadow: 0 1px 3px rgba(32, 32, 32, .2);
                position: absolute;
                top: 100%;
                left: 0;
                margin-top: 2px;
                max-width: 100%;
                background-color: white;
                z-index: 1;
                transition: .125s;
                transform-origin: top;
                transform: scaleY(0);
                width: 400px;
            }

            #searchResultsOuter h2 {
                background-color: var(--primary-text-color);
                color: white;
                padding: 3px 10px;
                margin-bottom: 0;
                line-height: 40px;
            }

            #searchResultsOuter {
                display: flex;
                overflow: hidden;
            }

            #searchResultsOuter > * {
                width: 100%;
                flex-shrink: 0;
            }

            #searchResultsOuter [data-has-search-selected="1"] {
                display: none;
            }

            :host([focused]) #searchResultsOuter {
                transform: scaleY(1);
            }

            #searchResultsOuter #searchesSelection, #searchResultsOuter #searchResults {
                component-button {
                    display: block;
                    text-align: left;
                    background: white;
                    color: var(--primary-text-color);
                    border-radius: 0;
                    margin-bottom: 1px;
                }
            }

            :host(:not([focused])) #searchResultsContainer > * {
                display: none;
            }
        `,
    ];

    override render() {
        return html`
            <component-elasticsearch-query .loading="${this.bind.loading}"
                                           .query="${this.query}"
                                           .response="${this.bind.response}"></component-elasticsearch-query>

            <component-input id="input"
                             style="--input-container-overflow: visible; --label-overflow: visible;"
                             .label="${this.label}"
                             .placeholder="${this.placeholder}"
                             @blur="${this._blur}"
                             ?readonly="${this.readonly}"
                             .value="${this.bind.searchValue}"
                             @focus="${this._focus}">
                <div slot="prefix" style="margin-right: 6px">
                    ${this.internalValue.map(item => html`
                        <component-input-elasticsearch-select-2-item .value="${item}"
                                                                     .search="${this.findSearchForValue(item)}">
                            ${!this.readonly ? html`
                                <component-icon icon="icons:cancel"
                                                @click="${(_: MouseEvent) => this.removeValue(item)}"></component-icon>
                            ` : undefined}
                        </component-input-elasticsearch-select-2-item>
                    `)}
                </div>
                <div slot="suffix">
                    <component-loading .loading="${this.loading}"></component-loading>
                    <div id="searchResultsOuter">
                        ${this.searches ? html`
                            <div id="searchesSelection" data-has-search-selected="${this.selectedSearch ? 1 : 0}">
                                <h2>Searches</h2>
                                ${Object.entries(this.searches).map(([key, item]) => html`
                                    <component-button @click="${this.selectSearch}" .search="${key}">
                                        ${item.name}
                                    </component-button>
                                `)}
                            </div>
                        ` : undefined}

                        <div id="searchResultsContainer">
                            ${this.selectedSearch ? html`
                                <h2>
                                    <component-button @click="${this.selectSearch}"
                                                      style="vertical-align: middle; min-width: 0; --padding: 0 5px;">
                                        <component-icon icon="icons:chevron-left"></component-icon>
                                    </component-button>
                                    ${this.selectedSearch?.name}
                                </h2>
                            ` : undefined}

                            <div id="searchResults">
                                ${this.dodoRenderResults(this.results, this.aggregations)}
                            </div>

                            ${this.searchValue ? html`
                                ${!this.loading ? html`
                                    ${this.totalResults ? html`
                                        <component-button id="searchResultsSelectQuery"
                                                          ?disabled="${!this.selectedSearch.allowSelectionOfSearchQuery}"
                                                          .value="${this.query}"
                                                          @mousedown="${this.selectItem}">
                                            <span class="resultHighlight">${this.totalResults}</span>
                                            &nbsp;search result(s) for&nbsp;
                                            <span class="resultHighlight">${this.searchValue}</span>
                                        </component-button>
                                    ` : html`
                                        <component-button disabled>
                                            <span class="resultHighlight">No</span>
                                            &nbsp;search results for&nbsp;
                                            <span class="resultHighlight">${this.searchValue}</span>
                                        </component-button>
                                    `}
                                ` : undefined}
                            ` : undefined}
                        </div>
                    </div>
                </div>
            </component-input>
        `;
    }

    constructor() {
        super();

        this.tabIndex = -1;
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);

        this._allowValuePopulating = false;

        queueMicrotask(() => {
            this._allowValuePopulating = true;
        });
    }

    selectSearch(e: CustomEvent) {
        let search = (e.currentTarget as any).search;
        this.selectedSearch = this.searches[search];
    }

    @bind()
    selectItem(e: CustomEvent) {
        let value = (e.currentTarget as any).value;

        if (!this.multiple) {//if its not multiple then clear it before each set
            this.internalValue = [];
            this.searchValue = '';
        }

        this.internalValue.push(value);
        this.requestUpdate('internalValue');
    }

    findSearchForValue(value: any) {
        if (this.isFirestoreReference(value)) {
            return this.searches[value.parent.id];

        } else {
            return Object.values(this.searches).find(_ => _.searchId === value.id);
        }
    }

    isFirestoreReference(value: any) {
        return !!(value?.id && value?.path);
    }


    dodoRenderResults(results: any, aggregations: any) {
        results = results || [];
        aggregations = aggregations || [];

        let ret = [];
        let stringCopyOfRenderer = JSON.stringify(this.selectedSearch?.renderer || '')
            .replace(/~~/g, ':')
            .replace(/paper-item/g, 'component-button');//TODO remove temp work around to replace paper-items

        for (let result of [...results, ...aggregations]) {
            let highlightedResult = {...result};
            let highlight = result._hit?.highlight;

            if (highlight) {
                for (let field in highlight) {
                    highlightedResult[field] = highlight[field][0];//todo look into this when there is multiple results in the highlighter??
                    //probably have to check each word
                }
            }

            try {
                ret.push(createComponent(
                    {
                        ...JSON.parse(stringCopyOfRenderer),
                        events: [{mousedown: this.selectItem}],
                    },
                    {result: result, highlightedResult: highlightedResult},
                    {
                        // onclick: this.selectItem,
                        value: result._ref,
                    },
                    true,
                ));

            } catch (e) {
                console.error('Failed rendering result', result, e);
            }
        }

        queueMicrotask(() => {
            this.updateResultsVisibility(this.internalValue);
        });

        return ret;
    }

    // @observe('results', 'aggregations')
    // renderResults(results: any, aggregations: any) {
    //     results = results || [];
    //     aggregations = aggregations || [];
    //     this.$.searchResults.innerHTML = '';
    //
    //
    //     let stringCopyOfRenderer = JSON.stringify(this.selectedSearch.renderer).replace(/~~/g, ':');
    //
    //     for (let result of [...results, ...aggregations]) {
    //         let highlightedResult = {...result};
    //         let highlight = result._hit?.highlight;
    //
    //         if (highlight) {
    //             for (let field in highlight) {
    //                 highlightedResult[field] = highlight[field][0];//todo look into this when there is multiple results in the highlighter??
    //                 //probably have to check each word
    //             }
    //         }
    //
    //         let row = createComponent(
    //             JSON.parse(stringCopyOfRenderer),
    //             {result: result, highlightedResult: highlightedResult},
    //             {
    //                 onmousedown: this.selectItem,
    //                 value: result._ref,
    //             },
    //             true,
    //         );
    //
    //         this.$.searchResults.appendChild(row);
    //     }
    //
    //
    //     // let selectAllItem = this.$.searchResultsContainer.querySelector('#searchResultsSelectQuery');
    //     // Object.defineProperty({})
    //     this.updateResultsVisibility(this.internalValue);
    // }

    @observe('internalValue')
    private updateResultsVisibility(internalValue: any[]) {
        let results: any[] = [...this.searchResultsElements];


        let internalValueFlat = internalValue.map(_ => JSONStringify(_));

        for (let result of results) {
            if (!result) continue;

            if (result.value !== result._flatValueFrom) {
                Object.defineProperties(result, {
                    _flatValueFrom: {
                        value: result.value,
                        configurable: true,
                    },
                    _flatValue: {
                        value: JSONStringify(result.value),
                        configurable: true,
                    },
                });
            }


            let exists = internalValueFlat.indexOf(result._flatValue) !== -1;
            result.classList.toggle('itemSelected', exists);
        }
    }

    _blur(e: FocusEvent) {
        let lostFocusTo = e.relatedTarget as Element;
        if (lostFocusTo) {
            let parent = nodeParentSelectorSearch(lostFocusTo, 'component-input-elasticsearch-select-2');
            if (parent) {
                (e.currentTarget as ComponentInput).nativeInput.focus();
                return;
            }
        }


        this.focused = false;
        this.searchValue = '';
    }

    _focus(_e: FocusEvent) {
        this.focused = true;
    }

    removeValue(value: string) {
        if (this.value === null) return;


        let valueIndex = this.internalValue.indexOf(value);
        if (valueIndex > -1) {
            this.internalValue.splice(valueIndex, 1);
            this.requestUpdate('internalValue');
        }

        this.focused = true;
    }


    private _allowInternalValuePopulating: boolean = true;
    private _allowValuePopulating: boolean = true;

    @observe('internalValue', 'multiple')
    updateValue(internalValue: any[], multiple: boolean) {
        if (!this._allowValuePopulating) return;

        this._allowInternalValuePopulating = false;
        if (multiple) {
            this.value = internalValue;

        } else {
            this.value = internalValue[0] || null;
        }
        this._allowInternalValuePopulating = true;
    }

    @observe('value', 'multiple')
    updateInternalValue(value: any, multiple: boolean) {
        if (!this._allowInternalValuePopulating) return;


        if (!value) {
            value = [];

        } else if (!multiple) {
            value = [value];

        } else if (multiple && !Array.isArray(value)) {
            value = [value];
        }

        this._allowValuePopulating = false;
        this.internalValue = value || [];
        this.performUpdate();
        this._allowValuePopulating = true;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-elasticsearch-select-2': ComponentInputElasticsearchSelect2;
    }
}