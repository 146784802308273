import {customElement} from 'lit/decorators.js';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FetchMethod} from '../../../__internal/local/controllers/FirestoreData';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {FirestoreDocument} from '../../../__internal/local/controllers/FirestoreDocument';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';

@customElement('component-shop-product-form-product-price')
class ComponentShopProductFormProductPrice extends BunnyElement {

    @property({type: String})
    label: string;

    @property({type: Number})
    internalValue: number;

    @property({type: Object, notify: true})
    value: RefferableDocument;

    @property({type: Object, notify: true})
    @computed('value')
    get privateData() {
        let ref = this.value?._ref;
        if (!ref) return undefined;

        return new FirestoreDocument<{
            price: number
        }>(this, `${ref.path}/private/item`, {method: FetchMethod.NETWORK_ONLY});
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <component-input-currency label="${this.label}"
                                      .value="${this.bind.internalValue}"
                                      @blur="${this.save}">
            </component-input-currency>
        `;
    }

    @observe('privateData')
    loadInternalValue(privateData?: FirestoreDocument<{ price: number }>) {
        this.internalValue = privateData?.data?.price || 0;
    }

    @toastProgressWrapper()
    @bind()
    async save() {
        await this.privateData?.save({
            price: this.internalValue,
        });
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-product-form-product-price': ComponentShopProductFormProductPrice;
    }
}