import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper.ts';


//TODO needs onclick of the label to trigger the input, maybe wrap the child in a label and for the value in
@customElement('component-input-radio-item')
export class ComponentInputRadioItem extends BunnyElement {

    @property()
    name!: string;

    @property({type: Boolean, notify: true, reflect: true})
    active: boolean = false;

    @property({type: Boolean, reflect: true})
    disabled = false;

    @property({type: Boolean, reflect: true})
    required = false;

    get nativeInput(): HTMLInputElement {
        return this.querySelector('input') as HTMLInputElement;
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                --size: 24px;
                --padding: 6px;
                display: block;
            }


            label {
                display: flex;
                align-items: center;
            }

            ::slotted(input) {
                width: calc(var(--size) - var(--padding));
                height: calc(var(--size) - var(--padding));
                border: solid var(--primary-text-color) 2px;
                border-radius: 100%;
                display: inline-block;
                position: relative;
                cursor: pointer;
                vertical-align: middle;
                transition: .125s;
                appearance: none;
                margin: var(--padding);
            }

            #radioContainer {
                position: relative;
                line-height: 0;
                margin-right: 5px;

                &:before {
                    content: '';
                    position: absolute;
                    inset: calc(var(--padding) + 3px);
                    border-radius: 100%;
                    background: var(--primary-color);
                    transition: .125s;
                    transform: scale(0);
                }
            }

            :host([active]) {
                ::slotted(input) {
                    border-color: var(--primary-color);
                }

                #radioContainer:before {
                    transform: scale(1);
                }
            }

            :host([disabled]) {
                pointer-events: none;

                #radioContainer {
                    filter: grayscale(1);
                    opacity: .6;
                }
            }
        `,
    ];

    override render() {
        return html`
            <label>
                <span id="radioContainer">
                    <slot name="input"></slot>
                </span>
                <input type="radio"
                       .disabled="${this.disabled}"
                       .checked="${this.active}"
                       required="${this.required}"
                       @input="${this.onInput}"
                       @change="${this.onInput}"
                       name="${this.name}">

                <div class="label">
                    <slot></slot>
                    ${this.required ? html`<span class="required">*</span>` : ''}
                </div>
            </label>

            <!--            <span id="radio"></span>-->
            <!--            <slot></slot>-->
        `;
    }

    constructor() {
        super();

        requestAnimationFrame(() => {
            let input = this.shadowRoot?.querySelector('input') as HTMLInputElement;
            input.slot = 'input';

            this.appendChild(input);
        });
    }

    protected onInput() {
        this.active = this.nativeInput.checked;
    }

    @listen('click')
    onClick(e: Event) {
        if(e.target === this.nativeInput) return;

        this.nativeInput.click();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-radio-item': ComponentInputRadioItem;
    }
}