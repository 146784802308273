import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-countdown-timer')
class ComponentCountdownTimer extends BunnyElement {

    @property({type: Object})
    target: Date;

    @property({type: Object})
    units = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    private _updateTimerIntervalId: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                line-height: 1.6;
                font-weight: bold;
                font-size: 24px;
                transform: translateZ(0);
            }

            :host > div {
                margin: 10px;
                display: inline-block;
            }

            :host > div > div {
                font-weight: normal;
                font-size: 16px;
            }
        `,
    ];

    override render() {
        return html`
            ${this.renderChunk(this.units.days, 'Days')}
            ${this.renderChunk(this.units.hours, 'Hours')}
            ${this.renderChunk(this.units.minutes, 'Mins')}
            ${this.renderChunk(this.units.seconds, 'Secs')}
        `;
    }

    renderChunk(number: number, label: string) {
        let numberString = number.toString().padStart(2, '0');

        return html`
            <div>
                <span class="display">${numberString[0]}</span>
                <span class="display">${numberString[1]}</span>
                <div>${label}</div>
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.updateTimer();
        this._updateTimerIntervalId = window.setInterval(this.updateTimer, 1000);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        clearInterval(this._updateTimerIntervalId);
    }

    @bind()
    @observe('target')
    updateTimer() {
        if (!this.target) return;

        let now = new Date();

        let saleEndsRemaining = Math.max(0, (this.target.getTime()) - now.getTime());
        let secondsRemaining = Math.ceil(saleEndsRemaining / 1000);

        let units = {days: 0, hours: 0, minutes: 0, seconds: 0};
        units.days = Math.floor(secondsRemaining / 86400);
        secondsRemaining -= units.days * 86400;
        units.hours = Math.floor(secondsRemaining / 3600);
        secondsRemaining -= units.hours * 3600;
        units.minutes = Math.floor(secondsRemaining / 60);
        secondsRemaining -= units.minutes * 60;
        units.seconds = secondsRemaining;
        this.units = units;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-countdown-timer': ComponentCountdownTimer;
    }
}
