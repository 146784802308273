import {customElement, queryAll} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {MediaReferenceField} from '../../shared/helpers/FirebaseHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {ComponentMediaViewImage} from './component-media-view-image';
import {requestIdleCallback} from '../../../__internal/local/helpers/TaskHelper';
import {getTime} from '../../../__internal/shared/helpers/DateHelper';
import {html} from 'lit';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';

@customElement('component-media-view-collection')
class ComponentMediaViewCollection extends BunnyElement {

    @property({type: String})
    fetchMethod: string = 'live';

    @property({type: Array})
    items: MediaReferenceField[];

    @property({type: Number})
    selectedIndex: number = -1;

    @property({type: Number})
    thumbnailWidth: number = 100;

    @property({type: Number})
    thumbnailSpacing: number = 5;

    @property({type: Boolean, reflect: true})
    @computed('_fullItemsMouseTracking', '_miniItemsMouseTracking')
    get slideAnimationEnabled() {
        return !(this._fullItemsMouseTracking || this._miniItemsMouseTracking);
    };

    set slideAnimationEnabled(_value: boolean) {
        //ignore, just to have a setter for the attribute
    };

    @property({type: Boolean})
    _fullItemsMouseTracking: boolean = false;

    @property({type: Boolean})
    _miniItemsMouseTracking: boolean = false;

    private _mouseTrackingStartTs: number = 0;
    private _mouseTrackingStartX: number = 0;
    private _mouseTrackingStartY: number = 0;
    private _mouseTrackingStartSelectedIndex: number = 0;
    private _mouseTrackingLastEvent: MouseEvent | TouchEvent;

    @property({type: Boolean})
    disablePageScroll: boolean = false;

    @property({type: Boolean})
    fullscreen: boolean = false;

    @property({type: Boolean, reflect: true})
    hiddenFullscreenControls: boolean = false;

    private _recentDragEnd: number;

    @property({type: Number})
    thumbnailTypeWidth: number = 400;

    @property({type: Number})
    thumbnailTypeHeight: number = 300;

    @property({type: String})
    thumbnailType: string = 'default-small-thumbnail';

    @property({type: Number})
    previewTypeWidth: number = 1024;

    @property({type: Number})
    previewTypeHeight: number = 768;

    @property({type: String})
    previewType: string = 'default-preview';

    private _preventInnerContentTransition = true;

    @property({type: Boolean, reflect: true})
    itemsLoading: boolean = true;

    @queryAll('#fullItems component-media-view-image')
    fullItemsElements: ComponentMediaViewImage[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                --thumbnail-width: 100px;
                --thumbnail-spacing: 5px;
                --thumbnail-area: calc(var(--thumbnail-width) + var(--thumbnail-spacing));
                position: relative;
            }

            :host([fullscreen]) {
                position: fixed !important;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 9999999;
                background: black;
                display: flex;
                flex-direction: column;
            }


            :host([fullscreen]) .mainImages {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            :host([fullscreen]) .mainImages .fullImageContainer {
                padding: 0;
            }

            :host([fullscreen]) .mainImages .fullImageContainer component-media-view-image {
                transform: scale(var(--fullscreen-scale));
            }

            :host([fullscreen]) .miniSliderContainer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, .8);
                padding: 5px;
                transition: .375s transform;
            }

            :host([fullscreen]) .miniSliderContainer:after {
                content: '^';
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-bottom: 15px;
                /*filter: drop-shadow(0 0 2px black);*/
                color: var(--primary-color);
                transform: translateX(-50%) scaleX(4) scaleY(0);
                pointer-events: none;
                transition: .25s transform;
                font-size: 30px;
            }

            :host([fullscreen][hidden-fullscreen-controls]) .toggleFullscreen {
                transform: scale(0);
            }

            :host([fullscreen][hidden-fullscreen-controls]) .miniSliderContainer {
                transform: translateY(100%);
                overflow: visible;
            }

            :host([fullscreen][hidden-fullscreen-controls]) .miniSliderContainer:after {
                transition-delay: .75s;
                transform: translateX(-50%) scaleX(4) scaleY(1);
            }

            :host([fullscreen]) .viewAllItems {
                display: none !important;
            }

            .sliderContainer {
                overflow: hidden;
            }

            .sliderContainer > * {
                position: relative;
                font-size: 0;
            }

            .sliderContainer > * > * {
                display: inline-block;
            }

            .sliderContainer > * > .fullImageContainer {
                box-sizing: border-box;
                vertical-align: middle;
            }

            :host([slide-animation-enabled]) .sliderContainer > * {
                transition: transform .375s;
            }

            /*component-media-view-image {*/
            /*    display: inline-block;*/
            /*}*/

            :host(:not([fullscreen])) .fullImageContainer component-media-view-image {
                --innerContent-transition: .125s;
            }

            :host(:not([fullscreen])) .fullImageContainer component-media-view-image:not([data-selected="1"]) {
                --ratio: var(--forced-full-ratio) !important;
            }

            :host([items-loading]) .fullItems {
                width: 100% !important;
            }

            :host([items-loading]) .miniItems {
                --items: 4 !important;
            }

            .miniItems component-media-view-image {
                width: var(--thumbnail-width);
                cursor: pointer;
                opacity: .75;
                transition: opacity .375s;
                margin-left: var(--thumbnail-spacing);
            }

            .miniItems component-media-view-image[data-selected="1"] {
                opacity: 1;
            }

            .toggleFullscreen {
                position: absolute;
                top: 15px;
                right: 15px;
                color: white;
                background-color: var(--primary-color);
                --padding: 5px;
                min-width: 0;
                margin: 0;
                width: 35px;
                height: 35px;
                transition: 0.375s;
            }

            component-media-view-image {
                background-color: #f2f2f2;
            }
        `,
    ];


    override render() {
        return html`
            <div class="mainImages sliderContainer">
                <div class="fullItems" id="fullItems"
                     style="width: ${this.itemsLoading ? 100 : this.items.length * 100}%; transform: translateX(${this.calculateScrollOffset(this.selectedIndex, this.items.length)}%)">
                    ${this.items.map((item, index) => html`
                        <div class="fullImageContainer" style="width: ${100 / this.items.length}%">
                            <component-media-view-image .media="${item}"
                                                        data-selected="${this.isSelected(index, this.selectedIndex)}"
                                                        type="${this.previewType}"
                                                        .width="${this.previewTypeWidth}"
                                                        .height="${this.previewTypeHeight}"
                                                        fetch-method="${this.fetchMethod}"></component-media-view-image>
                        </div>
                    `)}
                    ${this.itemsLoading ? html`
                        <component-media-view-image class="loadingImage"
                                                    style="width: 100%"
                                                    data-selected="1"
                                                    type="${this.previewType}"
                                                    .width="${this.previewTypeWidth}"
                                                    .height="${this.previewTypeHeight}"
                                                    .loading="${false}"></component-media-view-image>
                    ` : undefined}
                </div>
            </div>

            <div class="sliderContainer miniSliderContainer" style="margin-top: 5px">
                <div class="miniItems" id="miniItems"
                     style="--items: ${this.itemsLoading ? 4 : this.items.length}; width: calc(var(--thumbnail-area) * var(--items)); transform: translateX(${this.calculateThumbnailScrollOffset(this.selectedIndex)}px)">
                    ${this.items.map((item, index) => html`
                        <component-media-view-image data-index="${index}"
                                                    data-selected="${this.isSelected(index, this.selectedIndex)}"
                                                    .media="${item}"
                                                    type="${this.thumbnailType}"
                                                    .width="${this.thumbnailTypeWidth}"
                                                    .height="${this.thumbnailTypeHeight}"
                                                    @click="${this.selectMedia}"
                                                    fetch-method="${this.fetchMethod}"></component-media-view-image>
                    `)}
                    ${this.itemsLoading ? html`
                        ${new Array(4).fill(html`
                            <component-media-view-image style="width: 100px"
                                                        class="loadingImage"
                                                        type="${this.previewType}"
                                                        .width="${this.previewTypeWidth}"
                                                        .height="${this.previewTypeHeight}"
                                                        .loading="${false}"></component-media-view-image>
                        `)}
                    ` : undefined}
                </div>
            </div>

            <component-button-toggle class="toggleFullscreen" .active="${this.bind.fullscreen}">
                <component-icon icon="icons:fullscreen${this.fullscreen ? '-exit' : ''}"></component-icon>
            </component-button-toggle>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.selectedIndex = 0;
    }

    isSelected(index: number, selectedIndex: number) {
        return Math.round(selectedIndex) === index ? '1' : '0';
    }

    calculateScrollOffset(selectedIndex: number, items: number) {
        return (100 / items) * -selectedIndex;
    }

    calculateThumbnailScrollOffset(selectedIndex: number) {
        let offset = ((this.thumbnailWidth + this.thumbnailSpacing) * -selectedIndex);

        offset += this.clientWidth / 2;
        offset -= (this.thumbnailWidth + this.thumbnailSpacing) / 2;


        return offset;
    }

    selectMedia(e: MouseEvent) {
        if (this._mouseTrackingHasMetMinDragDistance === 'yes') return;
        if (getTime() - this._recentDragEnd < 10) return;

        this.selectedIndex = parseInt((e.currentTarget as HTMLElement).dataset.index as string, 10);
    }

    selectIndex(index: number, isLongDistance: boolean = false, direction: 'left' | 'right' = 'left') {
        if (isLongDistance) {
            let floorIndex = Math.floor(index);
            if (direction === 'left') {
                //and is going right
                index = floorIndex + 1;

            } else if (direction === 'right') {
                //and is going left
                index = floorIndex;
            }
        }

        this.selectedIndex = Math.max(Math.min(Math.round(index), this.items.length - 1), 0);
    }

    private handleDragStart(e: MouseEvent | TouchEvent) {
        if (e instanceof MouseEvent) {
            e.preventDefault();
        }

        let screenX = e instanceof MouseEvent ? e.screenX : e.touches[0].screenX;
        let screenY = e instanceof MouseEvent ? e.screenY : e.touches[0].screenY;
        this._mouseTrackingStartTs = getTime();
        this._mouseTrackingStartX = screenX;
        this._mouseTrackingStartY = screenY;
        this._mouseTrackingStartSelectedIndex = this.selectedIndex;
    }

    private _mouseTrackingHasMetMinDragDistance: 'undecided' | 'invalid' | 'yes' = 'undecided';

    private handleDrag(e: MouseEvent | TouchEvent, itemWidth: number) {
        let screenX = e instanceof MouseEvent ? e.screenX : e.touches[0].screenX;
        let screenY = e instanceof MouseEvent ? e.screenY : e.touches[0].screenY;
        if (e instanceof MouseEvent) {
            this._mouseTrackingHasMetMinDragDistance = 'yes';

        } else if (e instanceof TouchEvent && this._mouseTrackingHasMetMinDragDistance === 'undecided') {
            if (Math.abs(screenY - this._mouseTrackingStartY) > 50) {
                this._mouseTrackingHasMetMinDragDistance = 'invalid';

            } else if (Math.abs(screenX - this._mouseTrackingStartX) > 25) {
                this._mouseTrackingHasMetMinDragDistance = 'yes';
                this.disablePageScroll = true;
            }
        }

        if (this._mouseTrackingHasMetMinDragDistance !== 'yes') {
            return;
        }

        let offset = screenX - this._mouseTrackingStartX;
        let indexOffset = offset / itemWidth;

        this.selectedIndex = this._mouseTrackingStartSelectedIndex - indexOffset;
        this._mouseTrackingLastEvent = e;
    }

    private async handleDragEnd(e: MouseEvent | TouchEvent) {
        if (this._mouseTrackingHasMetMinDragDistance !== 'yes') {
            this._mouseTrackingHasMetMinDragDistance = 'undecided';
            return;
        }

        let cancelingDragId = this._mouseTrackingStartTs;
        let screenX = e instanceof MouseEvent ? e.screenX : e.touches[0].screenX;

        let direction: 'left' | 'right' = screenX < this._mouseTrackingStartX ? 'left' : 'right';
        let isLongDistance = Math.abs(screenX - this._mouseTrackingStartX) > 75;

        if (!isLongDistance) {
            await delayPromise(300);
        }

        if (cancelingDragId !== this._mouseTrackingStartTs) return;

        this.selectIndex(this.selectedIndex, isLongDistance, direction);


        this._mouseTrackingHasMetMinDragDistance = 'undecided';
        this.disablePageScroll = false;
        this._recentDragEnd = getTime();
    }

    @listen('mousedown', 'fullItems')
    @listen('touchstart', 'fullItems')
    fullItemsDragStart(e: Event) {
        if (!(e instanceof MouseEvent || e instanceof TouchEvent)) return;

        this.handleDragStart(e);
        this._fullItemsMouseTracking = true;
    }

    @listen('mousemove', window)
    @listen('touchmove', window)
    fullItemsDrag(e: Event) {
        if (!(e instanceof MouseEvent || e instanceof TouchEvent)) return;
        if (!this._fullItemsMouseTracking) return;


        this.handleDrag(e, this.clientWidth);
    }

    @listen('mouseup', window)
    @listen('touchend', window)
    fullItemsDragEnd(e: Event) {
        if (!(e instanceof MouseEvent || e instanceof TouchEvent)) return;
        if (!this._fullItemsMouseTracking) return;

        this._fullItemsMouseTracking = false;

        this.handleDragEnd(this._mouseTrackingLastEvent);
    }

    @listen('mousedown', 'miniItems')
    @listen('touchstart', 'miniItems')
    miniItemsDragStart(e: Event) {
        if (!(e instanceof MouseEvent || e instanceof TouchEvent)) return;

        this.handleDragStart(e);
        this._miniItemsMouseTracking = true;
    }

    @listen('mousemove', window)
    @listen('touchmove', window)
    miniItemsDrag(e: Event) {
        if (!(e instanceof MouseEvent || e instanceof TouchEvent)) return;
        if (!this._miniItemsMouseTracking) return;

        this.handleDrag(e, this.thumbnailWidth);
    }

    @listen('mouseup', window)
    @listen('touchend', window)
    miniItemsDragEnd(e: Event) {
        if (!(e instanceof MouseEvent || e instanceof TouchEvent)) return;
        if (!this._miniItemsMouseTracking) return;


        this._miniItemsMouseTracking = false;
        this.handleDragEnd(this._mouseTrackingLastEvent);
    }

    @listen('keydown', window)
    handleKeyboardArrows(e: Event) {
        if (!(e instanceof KeyboardEvent)) return;

        let keys: {
            [key: string]: number
        } = {
            ArrowLeft: -1,
            ArrowRight: 1,
        };

        let offset = keys[e.key];
        if (!offset) return;

        this.selectIndex(this.selectedIndex + offset);
    }

    @listen('keydown', window)
    handleKeyboardEscCloseFullscreen(e: Event) {
        if (!(e instanceof KeyboardEvent)) return;

        if (!this.fullscreen) return;
        if (e.key !== 'Escape') return;

        this.fullscreen = false;
    }

    @listen('fullscreenchange', window)
    handleCloseFullscreenOnUnfullscreening(_e: Event) {
        if (document.fullscreenElement) return;

        this.fullscreen = false;
    }

    @observe('disablePageScroll', 'fullscreen')
    modifyPageScroll(disablePageScroll: boolean, fullscreen: boolean) {
        if (fullscreen) {
            disablePageScroll = true;
        }

        document.body.style.overflow = disablePageScroll ? 'hidden' : '';
        if (document.body.parentElement) {
            document.body.parentElement.style.overflow = disablePageScroll ? 'hidden' : '';
        }
    }

    @observe('fullscreen')
    @listen('resize', window)
    updateFullscreenScales() {
        if (!this.fullscreen) return;

        let screenRatio = window.innerWidth / window.innerHeight;
        this.fullItemsElements.forEach(_ => {
            let mediaRatio = _.clientWidth / _.clientHeight;

            (_ as HTMLElement).style.setProperty('--fullscreen-scale', '' + (mediaRatio < screenRatio ? mediaRatio / screenRatio : 1));
        });
    }

    @listen('resize', window)
    forceRecenteringOfMiniItems() {
        let selectedIndex = this.selectedIndex;
        this.selectedIndex += 0.01;
        this.selectedIndex = selectedIndex;
    }

    @listen('click', 'fullItems')
    toggleFullscreenControls() {
        if (!this.fullscreen) return;
        if (getTime() - this._recentDragEnd < 10) return;

        this.hiddenFullscreenControls = !this.hiddenFullscreenControls;
    }


    @observe('fullscreen')
    async modifyElementFullscreen(fullscreen: boolean) {
        if (fullscreen) {
            this.setAttribute('fullscreen', '');

            if (document.body.requestFullscreen) {
                await document.body.requestFullscreen();
            }

        } else {
            if (document.exitFullscreen && document.fullscreenElement) {
                await document.exitFullscreen();
            }


            this.removeAttribute('fullscreen');
        }
    }

    @observe('items')
    markItemsLoaded(items: MediaReferenceField[]) {
        this.itemsLoading = !items.length;
    }

    @observe('selectedIndex', 'itemsLoading')
    idleLazyLoad(selectedIndex: number, itemsLoading: boolean) {
        if (itemsLoading) return;
        selectedIndex = Math.ceil(selectedIndex);

        let lazyLoadItems: ComponentMediaViewImage[] = [];
        let fullItems = this.fullItemsElements;

        for (let i = selectedIndex - 1; i <= selectedIndex + 1; i++) {
            if (fullItems[i] && !fullItems[i].visible && !(fullItems[i] as any).__mediaViewCollectionLazyVisibiling) {
                lazyLoadItems.push(fullItems[i]);
                (fullItems[i] as any).__mediaViewCollectionLazyVisibiling = true;
            }
        }


        if (!lazyLoadItems.length) return;

        let doLazyLoad = () => {
            for (let lazyLoadItem of lazyLoadItems) {
                lazyLoadItem.visible = true;
            }
        };

        let timeoutDelay = 'performance' in window && performance.now ? performance.now() < 7500 ? 5000 : 250 : 5000;
        if ('requestIdleCallback' in window) {
            requestIdleCallback(doLazyLoad, {timeout: timeoutDelay});

        } else {
            setTimeout(doLazyLoad, timeoutDelay);
        }
    }

    @observe('selectedIndex', 'itemsLoading')
    async applyCurrentRatioToInactiveChildren(selectedIndex: number, itemsLoading: boolean) {
        if (itemsLoading) return;
        if (selectedIndex < 0) return;
        await delayPromise();

        let fullItems = this.fullItemsElements;
        let currentFullItem = fullItems[selectedIndex];
        if (!currentFullItem) return;

        while (currentFullItem.loading) {
            await delayPromise();
        }


        if (this._preventInnerContentTransition) {
            currentFullItem.style.setProperty('--innerContent-transition', 'none');
        }

        this.style.setProperty('--forced-full-ratio', `${currentFullItem.ratio}`);

        if (this._preventInnerContentTransition) {
            await delayPromise();
            currentFullItem.style.removeProperty('--innerContent-transition');
            this._preventInnerContentTransition = false;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-media-view-collection': ComponentMediaViewCollection;
    }
}