import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';


@customElement('component-prize-claiming-messages-confirm-bank-details-response')
class ComponentPrizeClaimingMessagesConfirmBankDetailsResponse extends ComponentPrizeClaimingMessagesMessage {

    @property({type: String})
    winnerPicSrc = '/images/winnerPrizeClaiming/winner-cash-alternative.jpg';

    override render() {
        return html`
            ${super.render()}

            <div class="footer">
                ${this.item.meta?.bankDetails?.accountName ? html`
                    Account number: ${this.item.meta.bankDetails.accountName} <br>
                ` : undefined}
                ${this.item.meta?.bankDetails?.accountNumber ? html`
                    Account number: ${this.item.meta.bankDetails.accountNumber} <br>
                ` : undefined}
                ${this.item.meta?.bankDetails?.sortCode ? html`
                    Sort code: ${this.item.meta.bankDetails.sortCode} <br>
                ` : undefined}

                ${this.isAdmin ? html`
                    <div style="margin-top: 35px;">
                        <component-input-select
                                label="Winner pic src"
                                .dropDownDirection="${'up'}"
                                .value="${this.bind.winnerPicSrc}">
                            <component-input-select-item
                                    .value="${'/images/winnerPrizeClaiming/winner-cash-alternative.jpg'}">
                                Cash alternative
                            </component-input-select-item>
                            <component-input-select-item
                                    .value="${'/images/winnerPrizeClaiming/winner-cash-cash.jpg'}">
                                Cash
                            </component-input-select-item>
                            <component-input-select-item
                                    .value="${'/images/winnerPrizeClaiming/winner-cash-credit.jpg'}">
                                Website credit
                            </component-input-select-item>
                        </component-input-select>
                        <component-button style="background: var(--attention-color); display: block"
                                          @click="${this.doSendMoney}">
                            Mark money sent
                        </component-button>
                    </div>
                ` : undefined}
            </div>
        `;
    }

    @confirmationDialog({title: 'Send money', body: 'Are you sure you want to mark this as being sent?'})
    async doSendMoney() {
        this.dispatchEvent(new CustomEvent('sendMessageRequest', {
            detail: {
                newMessage: {
                    type: 'bankSent',
                    body: `We have now made the bank transfer to ${this.item.meta?.bankDetails?.accountNumber} ${this.item.meta?.bankDetails?.sortCode} so hopefully that should be sat in your account ready for you to treat yourself!`,
                },
            },
        }));

        this.dispatchEvent(new CustomEvent('sendMessageRequest', {
            detail: {
                newMessage: {
                    type: 'winnerPicRequest',
                    body: `Would it be possible for you to upload a winners photo of you holding the following image? Just displaying it on a screen like your phone or tablet would be perfect! We will then add £10 website credit to your account as points to say a very big thank you.`,
                    meta: {
                        embeds: [
                            {
                                src: this.winnerPicSrc,
                            },
                        ],
                    },
                },
            },
        }));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-confirm-bank-details-response': ComponentPrizeClaimingMessagesConfirmBankDetailsResponse;
    }
}