import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

const stepIndex: { [key: string]: number } = {
    'basket': 0,
    'login': 1,
    'your-details': 2,
    'payment': 3,
};

@customElement('component-shop-checkout-steps')
class ComponentShopCheckoutSteps extends BunnyElement {

    @property({type: String})
    step: string = 'basket';

    @property({type: String})
    cartId: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `
    ];

    // language=HTML
    override render(){
        return html`
            <ul class="breadcrumbs angledContainer forceMobileAngledContainer">
                <li class="${this.stepClass('basket', this.step)}">
                    <a href="/shop/cart">
                        Basket
                    </a>
                </li>
                <li class="${this.stepClass('login', this.step)}">
                    <a href="/account">
                        Account
                    </a>
                </li>
                <li class="${this.stepClass('your-details', this.step)}">
                    <a href="/shop/checkout/${this.cartId}">
                        Checkout
                    </a>
                </li>
                <li class="${this.stepClass('payment', this.step)}">
                    <span>
                        Payment
                    </span>
                </li>
            </ul>
        `;
    }

    stepClass(step: string, currentStep: string) {
        if (step === currentStep) {
            return 'active';
        }

        return stepIndex[step] <= stepIndex[currentStep] ? 'prev' : 'next';
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-checkout-steps': ComponentShopCheckoutSteps;
    }
}