import LocalPackage from '../../__internal/local/LocalPackage';
import {AspireCompsCompetitionsPackage} from '../shared';
import './components/component-aspire-comps-entry-list';
import './components/component-aspire-comps-entry-list-item';
import './components/component-aspire-comps-tickets-renderer';
import './components/component-aspire-comps-entry-list-mine';
import './components/component-aspire-comps-entry-list-mine-item';
import './components/component-aspire-comps-entry-list-mine-quick-view';
import './components/component-aspire-comps-winner-item';
import './components/component-aspire-comps-winners-map';
import './components/component-aspire-comps-charity-item';
import './components/component-aspire-comps-livestream-up-next';
import './components/component-aspire-comps-livestream-manage';
import './components/component-aspire-comps-livestream-overview';
import './components/component-aspire-comps-livestream-overview-item';
import './components/component-winners-collection-header';
import './components/component-charities-collection-header';
import './components/component-data-collection-list-item-cell-competition-export';
import './components/component-firestore-collection-list-item-cell-draw-date';
import './components/component-data-collection-list-item-cell-download-winners';
import './components/component-aspire-comps-winner';
import './components/component-aspire-comps-charity';


export class AspireCompsCompetitionsLocalPackage extends AspireCompsCompetitionsPackage implements LocalPackage {

    constructor() {
        super();

    }
}
