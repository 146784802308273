import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ElasticsearchQueryRequest} from '../../../elasticsearch/shared/helpers/ElasticsearchHelper';
import {
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subWeeks,
    subYears,
} from 'date-fns';
import {bracketPut} from '../../../__internal/shared/helpers/DotHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {ComponentElasticsearchQuery} from '../../../elasticsearch/local/components/component-elasticsearch-query';

type Period =
    'today'
    | 'week'
    | 'month'
    | 'quarter'
    | 'year'
    | 'yesterday'
    | 'lastWeek'
    | 'lastMonth'
    | 'lastQuarter'
    | 'lastYear'
    | 'custom'
    | 'all';

const DATE_INTERVALS: Record<string, { seconds: number, name: string }> = {
    '1m': {
        seconds: 60,
        name: 'Minute',
    },
    '1h': {
        seconds: 3600,
        name: 'Hour',
    },
    '1d': {
        seconds: 86400,
        name: 'Day',
    },
    '1w': {
        seconds: 604800,
        name: 'Week',
    },
    '1M': {
        seconds: 2592000,
        name: 'Month',
    },
    '1q': {
        seconds: 10368000,
        name: 'Quarter',
    },
    '1y': {
        seconds: 31536000,
        name: 'Year',
    },
};

type DateInterval = keyof typeof DATE_INTERVALS;

@customElement('component-analytics-data-supplier-elasticsearch')
class ComponentAnalyticsDataSupplierElasticsearch extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Object, notify: true})
    @computed('response')
    get data() {
        return {aggregations: this.response.aggregations};
    }

    @property({type: Object})
    query: ElasticsearchQueryRequest;

    @property({type: Object})
    response: any;

    @property({type: String, notify: true})
    @storageBoundQueryString('period', 'week')
    period: Period = 'week';

    @property({type: String, notify: true})
    @storageBoundQueryString('dateFrom', false)
    dateFrom = '';

    @property({type: String, notify: true})
    @storageBoundQueryString('dateTo', false)
    dateTo = '';

    @property({type: String, notify: true})
    @storageBoundQueryString('dateInterval', '1d')
    dateInterval: DateInterval = '1d';

    @property({type: Boolean})
    autoRefresh: boolean = false;

    @property({type: Object})
    @computed('route')
    get routeSearchParams(): { [key: string]: any } {
        let queryParams = this.route.current.query;

        let ret: { [key: string]: any } = {};
        for (let i in queryParams) {
            bracketPut(i, queryParams[i], ret);
        }

        return ret.params || {};
    }

    @property({type: Array})
    @computed('period', 'dateFrom', 'dateTo', 'dateInterval')
    get dateIntervals() {
        let dateFrom = this.dateFrom ? new Date(this.dateFrom) : undefined;
        let dateTo = this.dateTo ? new Date(this.dateTo) : undefined;


        return Object.entries(DATE_INTERVALS)
            .map(([key, value]) => ({key: key as DateInterval, value: value}))
            .map(_ => {
                if (this.period === 'all') {
                    let estimatedBuckets = this.estimateBuckets(new Date(2020), new Date(), _.key);
                    (_.value as any).disabled = estimatedBuckets > 65535;

                } else if (dateFrom && dateTo) {
                    let estimatedBuckets = this.estimateBuckets(dateFrom, dateTo, _.key);
                    (_.value as any).disabled = estimatedBuckets > 65535;
                }

                return _;
            });
    }

    @property({type: Boolean})
    showPeriods = false;

    private _autoRefreshIntervalId: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .periods {
                width: 400px;
                max-width: 100%;
                position: absolute;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                z-index: 9999;
                background-color: white;
            }

            .periods component-input-radio-group {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding: 0;
            }

            .periods component-input-radio-group component-input-radio-item {
                width: 50%;
                box-sizing: border-box;
                border: solid rgba(32, 32, 32, .1) 1px;
                padding: 10px;
            }
            
            .periods .items component-input-radio-item[active] {
                background-color: var(--attention-color);
                color: white;
            }

            .periods .sections component-input-radio-item[active] {
                border-bottom: solid var(--primary-color) 2px;
            }
        `,
    ];

    override render() {
        let searchMustRangeCreated = (this.query.params.searchMust as any)?.range?.created;
        return html`
            <component-elasticsearch-query id="elasticsearchQuery"
                                           .query="${this.query}"
                                           .response="${this.bind.response}"></component-elasticsearch-query>

            <component-button-toggle .active="${this.bind.showPeriods}" style="margin: 0; text-transform: none">
                <div>
                    Date range &middot;
                    ${this.period}
                    ${searchMustRangeCreated?.gte ? html`
                        <small>
                                (${RenderingHelper._dateFormat(searchMustRangeCreated.gte, 'dd/MM/yyyy')}
                            -
                            ${RenderingHelper._dateFormat(searchMustRangeCreated.lt, 'dd/MM/yyyy')})
                        </small>
                    ` : undefined}
                </div>
            </component-button-toggle>

            
            ${this.showPeriods ? html`
                <div class="periods">
                    <component-input-radio-group .value="${this.bind.period}"
                                            style="background-color: var(--primary-text-color); color: white; "
                                            class="sections">
                        <component-input-radio-item name="today">Preset</component-input-radio-item>
                        <component-input-radio-item name="custom">Custom</component-input-radio-item>
                    </component-input-radio-group>

                    ${this.period === 'custom' ? html`
                        <div style="padding: 15px; display: flex">
                            <component-input-date label="From"
                                                  .value="${this.bind.dateFrom}"
                                                  style="width: 49%"></component-input-date>
                            <component-input-date label="To"
                                                  .value="${this.bind.dateTo}"
                                                  style="width: 49%; margin-left: 2%"
                                                  min="${this.dateFrom}"></component-input-date>
                        </div>
                    ` : html`
                        <component-input-radio-group .value="${this.bind.period}" class="items">
                            <component-input-radio-item name="today">Today</component-input-radio-item>
                            <component-input-radio-item name="yesterday">Yesterday</component-input-radio-item>
                            <component-input-radio-item name="week">This week</component-input-radio-item>
                            <component-input-radio-item name="lastWeek">Last week</component-input-radio-item>
                            <component-input-radio-item name="month">This month</component-input-radio-item>
                            <component-input-radio-item name="lastMonth">Last month</component-input-radio-item>
                            <component-input-radio-item name="quarter">This quarter</component-input-radio-item>
                            <component-input-radio-item name="lastQuarter">Last quarter
                            </component-input-radio-item>
                            <component-input-radio-item name="year">This year</component-input-radio-item>
                            <component-input-radio-item name="lastYear">Last year</component-input-radio-item>
                            <component-input-radio-item name="all" style="width: 100%">All
                            </component-input-radio-item>
                        </component-input-radio-group>
                    `}
                </div>
            ` : undefined}

            <component-input-select label="Date interval"
                                    class="dropdown-content"
                                    .value="${this.bind.dateInterval}"
                                    style="margin-left: 15px; display: inline-block">
                ${this.dateIntervals?.map(item => html`
                    <component-input-select-item .value="${item.key}"
                                                 ?disabled="${(item.value as any).disabled}">
                        ${item.value.name}
                    </component-input-select-item>
                `)}
            </component-input-select>

            <component-button @click="${this.refresh}">Refresh</component-button>
            <component-input-checkbox .checked="${this.bind.autoRefresh}" style="display: inline-block">Auto</component-input-checkbox>
        `;
    }

    periodToDateRange(period: Period) {
        let now = new Date();
        switch (period) {
            // @ts-ignore
            case 'yesterday':
                now = subDays(now, 1);
            case 'today':
                return [startOfDay(now), startOfDay(subDays(now, -1))];

            // @ts-ignore
            case 'lastWeek':
                now = subWeeks(now, 1);
            case 'week':
                return [startOfWeek(now), startOfWeek(subWeeks(now, -1))];

            // @ts-ignore
            case 'lastMonth':
                now = subMonths(now, 1);
            case 'month':
                return [startOfMonth(now), startOfMonth(subMonths(now, -1))];

            // @ts-ignore
            case 'lastQuarter':
                now = subMonths(now, 4);
            case 'quarter':
                return [startOfQuarter(now), startOfQuarter(subMonths(now, -4))];

            // @ts-ignore
            case 'lastYear':
                now = subYears(now, 1);
            case 'year':
                return [startOfYear(now), startOfYear(subYears(now, -1))];

            case 'all':
                return [null, null];
        }

        return [];
    }

    @observe('period')
    populateDateRange(period: Period) {
        if (period == 'custom') return;
        let [dateFrom, dateTo] = this.periodToDateRange(period);


        this.dateFrom = dateFrom ? dateFrom.toISOString().split('T')[0] : '';
        this.dateTo = dateTo ? dateTo.toISOString().split('T')[0] : '';
    }

    @observe('dateFrom', 'dateTo', 'dateInterval', 'routeSearchParams')
    populateQuery(dateFrom: string | null, dateTo: string | null, dateInterval: string | null, routeSearchParams: any) {
        if (dateFrom && dateTo) {
            this.query.params.searchMust = {
                range: {
                    created: {
                        gte: new Date(dateFrom),
                        lt: new Date(dateTo),
                    },
                },
            };

        } else {
            this.query.params.searchMust = null;
        }


        this.query.params.dateInterval = dateInterval;
        for (let i in routeSearchParams) {
            this.query.params[i] = routeSearchParams[i];
        }


        this.requestUpdate('query');
    }

    @bind()
    refresh() {
        this.query.params._refresh = Date.now();
        this.requestUpdate('query');
    }

    @observe('autoRefresh')
    handleAutoRefresh(autoRefresh: boolean) {
        clearInterval(this._autoRefreshIntervalId);

        if (autoRefresh) {
            this._autoRefreshIntervalId = window.setInterval(this.refresh, 5000);
        }
    }

    estimateBuckets(start: Date, end: Date, interval: DateInterval) {
        let intervalMs = DATE_INTERVALS[interval].seconds * 1000;
        let rangeMs = end.getTime() - start.getTime();

        return Math.ceil(rangeMs / intervalMs);
    }

    @observe('dateInterval', 'dateIntervals')
    fallbackSelectedDateIntervalOnDateIntervalsChangeToDisabled(dateInterval: DateInterval, dateIntervals: { key: DateInterval, value: { name: string, seconds: number, disabled: boolean } }[]) {
        if (!dateInterval) return;
        if (!dateIntervals) return;
        let currentIndex = dateIntervals.findIndex(_ => _.key === dateInterval);

        for (let i = currentIndex; i < dateIntervals.length; i++) {
            let interval = dateIntervals[i];
            if (interval.value.disabled) continue;

            this.dateInterval = interval.key;
            break;
        }
    }

    @observe('query')
    queryUpdatedFlushChildUpdates(){
        //TODO maybe add a deep property notifier that passes down the request update to the property when ever it changes/(or add a way to detect the change to the object to see if it actually has changed)
        this.shadowRoot?.querySelector<ComponentElasticsearchQuery>('#elasticsearchQuery')?.requestUpdate('query');
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-data-supplier-elasticsearch': ComponentAnalyticsDataSupplierElasticsearch;
    }
}