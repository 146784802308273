import {customElement} from 'lit/decorators.js';
import {getApp} from 'firebase/app';
import {getAnalytics, logEvent, setCurrentScreen, setUserId, setUserProperties} from 'firebase/analytics';
import {getPerformance} from 'firebase/performance';
import {ComponentAnalytics} from './component-analytics';
import {EventType, TypeParams} from '../helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-firebase-analytics')
class ComponentFirebaseAnalytics extends ComponentAnalytics {

    @property({type: Object})
    private analytics: any = false;

    @property({type: Object})
    performance: any = false;

    protected async startup() {
        let firebaseApp = getApp();
        this.analytics = getAnalytics(firebaseApp);
        this.performance = getPerformance(firebaseApp);

        if ('gtag' in window && typeof window.gtag === 'function') {
            window.gtag('consent', 'default', {
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'analytics_storage': 'granted',
            });
        }
    }

    protected setUserId(id: string, options: any = {}) {
        this.receiveEvent(() => {
            setUserId(this.analytics, id, options);
        });
    }

    protected setCurrentScreen(id: string, options: any = {}) {
        this.receiveEvent(() => {
            setCurrentScreen(this.analytics, id, options);
        });
    }

    protected setUserProperties(properties: any, options: any = {} as any) {
        this.receiveEvent(() => {
            setUserProperties(this.analytics, properties, options);
        });
    }

    protected generateEventHandlers(): { [key in EventType]: (params: TypeParams[key]) => void } {
        let track = (name: any, params: any) => {
            logEvent(this.analytics, name, params);
        };

        return {
            appPerformance() {
            },
            appError() {
            },
            viewContent(params) {
                if (params.type === 'product') {
                    let categories = params.categories;
                    track('view_item', {
                        currency: params.currency,
                        value: params.value,
                        items: [
                            {
                                item_id: params.id,
                                item_name: params.name,
                                price: params.value,
                                quantity: 1,
                                item_category: categories ? categories[0] : undefined,
                                item_category2: categories ? categories[1] : undefined,
                                item_category3: categories ? categories[2] : undefined,
                                item_category4: categories ? categories[3] : undefined,
                                item_category5: categories ? categories[4] : undefined,
                            },
                        ],
                    });

                } else {
                    track('select_content', {
                        content_type: params.type,
                        item_id: params.id,
                    });
                }
            },
            shopAddToCart(params) {
                let categories = params.categories;
                track('add_to_cart', {
                    currency: params.currency,
                    value: params.value,
                    items: [
                        {
                            item_id: params.id,
                            item_name: params.name,
                            price: params.value,
                            quantity: params.quantity,
                            item_category: categories ? categories[0] : undefined,
                            item_category2: categories ? categories[1] : undefined,
                            item_category3: categories ? categories[2] : undefined,
                            item_category4: categories ? categories[3] : undefined,
                            item_category5: categories ? categories[4] : undefined,
                        },
                    ],
                });
            },
            pageView(params) {
                track('page_view', {
                    page_location: params.path,
                });
            },
            accountRegister() {
                track('sign_up', {});
            },
            accountLogin() {
            },
            shopPurchase(params) {
                track('purchase', {
                    currency: params.currency,
                    value: params.value,
                    transaction_id: params.orderId,
                    items: params.items.map(_ => {
                        let categories = _.categories;
                        return {
                            item_id: _.id,
                            item_name: _.name,
                            quantity: _.quantity,
                            discount: (Math.abs(_.discount || 0) / _.quantity).toFixed(2),
                            price: _.productPrice,
                            item_category: categories ? categories[0] : undefined,
                            item_category2: categories ? categories[1] : undefined,
                            item_category3: categories ? categories[2] : undefined,
                            item_category4: categories ? categories[3] : undefined,
                            item_category5: categories ? categories[4] : undefined,
                        };
                    }),
                });
            },
            shopStartCheckout(params) {
                track('begin_checkout', {
                    currency: params.currency,
                    value: params.value,
                    items: params.items.map(_ => {
                        let categories = _.categories;
                        return {
                            item_id: _.id,
                            item_name: _.name,
                            quantity: _.quantity,
                            item_category: categories ? categories[0] : undefined,
                            item_category2: categories ? categories[1] : undefined,
                            item_category3: categories ? categories[2] : undefined,
                            item_category4: categories ? categories[3] : undefined,
                            item_category5: categories ? categories[4] : undefined,
                        };
                    }),
                });
            },
            interactableClick() {
            },
            interactableView() {
            },
            pwaPrompt(params) {
                track('pwa_prompt', {
                    state: params.state,
                    platform: params.platform,
                });
            },
            toastShown() {
            },
        };
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-firebase-analytics': ComponentFirebaseAnalytics;
    }
}
