import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ComponentElasticsearchCollectionList} from './component-elasticsearch-collection-list';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {ComponentInputNumber} from '../../../inputs/local/components/component-input-number';


@customElement('component-elasticsearch-collection-list-control-pagination')
class ComponentElasticsearchCollectionListControlPagination extends BunnyElement {

    @property({type: Object})
    list: ComponentElasticsearchCollectionList;

    @property({type: Number})
    totalResults: number;

    @property({type: Number})
    limit: number;

    @property({type: Number})
    from: number;

    @property({type: Number})
    currentPage: number = 1;

    @property({type: Number})
    itemsCount: number = 1;

    @property({type: Number})
    @computed('totalResults', 'limit')
    get totalPages() {
        return Math.ceil(this.totalResults / this.limit) || 0;
    };

    @property({type: Boolean})
    readonly = navigator.maxTouchPoints > 0;

    static override styles = [
        // language=SCSS
        scss`
            component-input-number {
                display: inline-block;
                color: var(--primary-text-color);
                --input-container-border: solid var(--primary-text-color) 1px;
                --input-container-border-radius: 0;

                input {
                    text-align: right;
                    line-height: 24px;
                    padding: 0;
                }


                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input {
                    -moz-appearance: textfield;
                }
            }

            #suffix {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: var(--secondary-text-color);
            }

            component-button {
                color: var(--primary-text-color);
                background: none;
                min-width: 0;
                vertical-align: middle;
                padding: 5px;
            }

            component-button[disabled] {
                opacity: 0.15;
            }
        `,
    ];

    @computed('currentPage')
    get isFirstPage() {
        return this.currentPage === 1;
    }

    @computed('currentPage', 'totalPages')
    get isLastPage() {
        return this.currentPage === this.totalPages;
    }

    override render() {
        return html`
            ${this.totalResults ? html`
                <component-input-number
                        class="borderBottomOnly"
                        @change="${this._onInputValueChange}"
                        label="Showing ${this.from + 1} - ${this.from + this.itemsCount} of ${this.totalResults} results"
                        value="${this.currentPage}"
                        min="1"
                        max="${this.totalPages}"
                        auto-validate
                        allowed-pattern="[0-9]"
                        style="--paper-input-container-input-webkit-spinner: {-webkit-appearance: none;}"
                        .readonly="${this.readonly}">
                    <div slot="prefix" style="font-size: 0">
                        <component-button @click="${(_: MouseEvent) => this.__doSetPage(1)}"
                                          .disabled="${this.isFirstPage}"
                                          .raised="${false}">
                            <component-icon icon="icons:first-page"></component-icon>
                        </component-button>
                        <component-button @click="${(_: MouseEvent) => this.__doSetPage(this.currentPage - 1)}"
                                          .disabled="${this.isFirstPage}"
                                          style="margin-right: 5px"
                                          .raised="${false}">
                            <component-icon icon="icons:chevron-left"></component-icon>
                        </component-button>
                    </div>
                    <div slot="suffix" style="font-size: 0">
                        <span style="vertical-align: middle; display: inline-block; text-align: left; font-size: 16px">
                            &nbsp;<small>of</small> <span
                                style="width: 30px; display: inline-block">${this.totalPages}</span>
                        </span>
                        <component-button @click="${(_: MouseEvent) => this.__doSetPage(this.currentPage + 1)}"
                                          .disabled="${this.isLastPage}"
                                          style="margin-left: 5px"
                                          .raised="${false}">
                            <component-icon icon="icons:chevron-right"></component-icon>
                        </component-button>
                        <component-button @click="${(_: MouseEvent) => this.__doSetPage(this.totalPages)}"
                                          .disabled="${this.isLastPage}"
                                          .raised="${false}">
                            <component-icon icon="icons:last-page"></component-icon>
                        </component-button>
                    </div>
                </component-input-number>
            ` : undefined}
        `;
    }

    __doSetPage(page: number) {
        if (page < 1) page = 1;
        if (page > this.totalPages) page = this.totalPages;

        this.currentPage = page;


        this.list.from = (this.currentPage - 1) * this.limit;
    }


    @observe('list')
    listUpdated(list: ComponentElasticsearchCollectionList) {
        list.addEventListener('total-results-changed', this.updateTotalResults);
        list.addEventListener('limit-changed', this.updateLimit);
        list.addEventListener('from-changed', this.updateFrom);
        list.addEventListener('items-changed', this.updateItemsCount);

        this.updateTotalResults();
        this.updateLimit();
        this.updateFrom();
    }

    @bind()
    updateTotalResults() {
        this.totalResults = this.list.totalResults;
    }

    @bind()
    updateLimit() {
        this.limit = this.list.limit;
    }

    @bind()
    updateFrom() {
        this.from = this.list.from;
    }

    @bind()
    updateItemsCount() {
        this.itemsCount = this.list.items.length;
    }

    @observe('limit', 'from')
    updatePageFromList() {
        this.currentPage = (this.from / this.limit) + 1;
    }

    _onInputValueChange(e: Event) {
        let value = (e.currentTarget as ComponentInputNumber).value;

        this.__doSetPage(parseInt(value || '0', 10));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-elasticsearch-collection-list-control-pagination': ComponentElasticsearchCollectionListControlPagination;
    }
}