import {customElement} from 'lit/decorators.js';
import {
    ComponentNotificationsPreferenceChannelBase,
    CONFIGURATION,
} from '../component-notifications-preference-channel-base';
import {html} from 'lit';
import {computed} from '../../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';

@customElement('component-notifications-preference-channel-fbmessenger')
class ComponentNotificationsPreferenceChannelFbmessenger extends ComponentNotificationsPreferenceChannelBase {

    get name() {
        return 'Facebook messenger';
    }

    renderSetupHtml() {
        return html`TODO facebook setup button`;
    }

    @computed('item')
    get configuration(): CONFIGURATION {
        return this.item?.meta?.token ?
            CONFIGURATION.AVAILABLE:
            CONFIGURATION.AWAITING_SETUP;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-preference-channel-fbmessenger': ComponentNotificationsPreferenceChannelFbmessenger;
    }
}