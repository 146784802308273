import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {JSONParseLocal} from '../../../__internal/local/helpers/DataHelper';
import {previousSunday} from 'date-fns';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {Auth} from '../../../auth/local/controllers/Auth';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

interface CompetitionDraw {
    drawn: Date;
    assigned: Date;
    morningAfterDraw: Date;
    competitions: {
        name: string;
        tickets: {
            name: string;
            number: number;
            ownerId: string;
        }[];
        winningTickets: {
            name: string;
            number: number;
            ownerId: string;
        }[];
    }[];
}

@customElement('component-aspire-comps-entry-list-mine')
class ComponentAspireCompsEntryListMine extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Boolean})
    myTicketsLoading: boolean = true;

    @property({type: Array})
    competitionDraws: CompetitionDraw[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .competitionDraws {
                margin-bottom: 100px;
            }

            .competitionDraws h2 {
                background-color: var(--primary-text-color);
                color: white;
                padding: 5px 0;
                box-shadow: -25vw 0 0 var(--primary-text-color), 25vw 0 0 var(--primary-text-color);
            }
        `,
    ];

    override render() {
        return html`
            <component-loading .loading="${this.myTicketsLoading}">
                ${!this.competitionDraws?.length ? html`
                    <p>
                        Looks like you have not entered any competitions lately
                    </p>
                    <a href="/shop/products">
                        <component-button>Browse competitions</component-button>
                    </a>
                ` : undefined}

                ${this.competitionDraws?.map(item => html`
                    <div class="competitionDraws">
                        <h2>
                            Competitions on ${RenderingHelper._dateFormat(item.drawn, 'dd/MM/yyyy')}
                            ${this.isInPast(item.morningAfterDraw) ? html`
                                <span style="background-color: var(--attention-color); border-radius: 3px; margin-left: 15px; text-transform: uppercase; padding: 5px; font-size: 50%; vertical-align: middle">Drawn</span>
                            ` : undefined}
                        </h2>
                        <p style="text-align: center">
                            ${this.isInPast(item.drawn) ? html`
                                You had ${item.competitions.length}
                                competition${item.competitions.length === 1 ? '' : 's'}
                                drawn on ${RenderingHelper._dateFormat(item.drawn, 'dd/MM/yyyy')}
                                at ${RenderingHelper._dateFormat(item.drawn, 'hh:mma')} <br>
                            ` : html`
                                You have ${item.competitions.length}
                                competition${item.competitions.length === 1 ? '' : 's'}
                                being drawn on ${RenderingHelper._dateFormat(item.drawn, 'dd/MM/yyyy')}
                                at ${RenderingHelper._dateFormat(item.drawn, 'hh:mma')} <br>

                                Ticket assigning will happen daily by 10pm <br>
                            `}

                            <br>
                            <a href="/account/notifications/preferences">
                                <component-button style="flex-direction: column">
                                    <span style="display: block">
                                        ${this.isInPast(item.drawn) ? html`
                                            Missed the live? Get notified next time we go live
                                        ` : html`
                                            Get notified when we go live
                                        `}
                                    </span>
                                    <small style="text-transform: none">
                                        Manage notification preferences
                                    </small>
                                </component-button>
                            </a>
                        </p>

                        ${this.isInPast(item.morningAfterDraw) ? html`
                            <div style="text-align: center; margin-top: 25px; margin-bottom: 50px" class="contentStep">
                                ${this.hadWinningCompetition(item.competitions) ? html`
                                    <h4 style="background-color: var(--attention-color); color: white; padding: 5px 15px; margin: -25px -15px 15px -15px">
                                        !! Winner winner badger dinner !!
                                    </h4>
                                    <p>
                                        We hopefully got in contact with you during the draw, but in case you were busy
                                        you will be contacted soon
                                    </p>
                                    <a href="mailto: prizes@aspirecomps.co.uk">
                                        <component-button>
                                            Send us winner pics
                                        </component-button>
                                    </a>
                                ` : undefined}
                            </div>
                        ` : undefined}


                        <div class="competitions" style="margin-top: 50px">
                            ${item.competitions.map(item => html`
                                <div class="contentStep" style="margin: 0; padding: 0; margin-bottom: 5px">
                                    <component-aspire-comps-entry-list-mine-item
                                            .competition="${item}"></component-aspire-comps-entry-list-mine-item>
                                </div>
                            `)}
                        </div>
                    </div>
                `)}
            </component-loading>
        `;
    }

    hadWinningCompetition(competitions: any[]) {
        return !!competitions.find(_ => _.winningTickets.length);
    }

    isInPast(date: Date) {
        return date.getTime() < Date.now();
    }

    @observe('auth')
    async loadMyCompetitions(auth: Auth) {
        if (!auth.user) return;

        let accountId = auth.user.uid;
        let myTicketsUrl = `/_/accountTickets?accountId=${accountId}&fetchRecentCompetitions=1&fetchIncludePending=1&includeCompetitionMeta=1`;

        this.myTicketsLoading = true;
        let response = await fetch(myTicketsUrl);
        let data = JSONParseLocal(await response.text());

        let competitionDraws: CompetitionDraw[] = [];
        for (let competitionId in data.competitionsMeta) {
            let competitionMeta = data.competitionsMeta[competitionId] as any;

            let competitionDraw = competitionDraws.find(_ => _.drawn.getTime() === competitionMeta.drawn.getTime());
            if (!competitionDraw) {
                let drawn = competitionMeta.drawn;
                let assigned = previousSunday(drawn);
                assigned.setHours(22, 0, 0);
                let morningAfterDraw = new Date(drawn.getFullYear(), drawn.getMonth(), drawn.getDate() + 1, 6, 0, 0, 0);
                competitionDraw = {
                    drawn: drawn,
                    assigned: assigned,
                    morningAfterDraw: morningAfterDraw,
                    competitions: [],
                };
                competitionDraws.push(competitionDraw);
            }

            if (data.competitions[competitionId]?.length) {
                competitionDraw.competitions.push({
                    name: competitionMeta.name,
                    tickets: data.competitions[competitionId],
                    winningTickets: (competitionMeta.winningTickets || []).filter((winningTicket: any) => winningTicket.owner === accountId),
                });
            }
        }

        this.competitionDraws = competitionDraws.filter(_ => _.competitions.length)
            .sort((a, b) => b.drawn.getTime() - a.drawn.getTime());
        this.myTicketsLoading = false;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-entry-list-mine': ComponentAspireCompsEntryListMine;
    }
}