import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html, PropertyValues} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

const cloneDate = (date: Date) => {
    return new Date(date.getTime());
};

@customElement('component-app-state')
class ComponentAppState extends BunnyElement {

    @property({type: String})
    offlineMessage: string;

    @property({type: String})
    updateMessage: string;

    @property({type: Boolean, reflect: true})
    isOffline: boolean = false;

    @property({type: Boolean, reflect: true})
    isUpdating: boolean = false;

    @property({type: Boolean, reflect: true})
    isUpdateIncoming = false;

    @property({type: Boolean, reflect: true})
    @computed('isOffline', 'isUpdating')
    get showOverlay() {
        return this.isOffline || this.isUpdating;
    }

    @property({type: Object})
    appState: any;

    @property({type: Object})
    buildConfig: any;

    _rerenderIntervalId: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            #overlay {
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: white;
                text-align: center;
                display: none;
                align-items: center;
                justify-content: center;
                z-index: 10000000000000;
                flex-direction: column;
            }

            :host([show-overlay]) #overlay {
                display: flex;
            }

            .progressBar {
                position: relative;
                background: rgba(32, 32, 32, .3);
                height: 10px;
                width: 300px;
                max-width: 95vw;
                margin: 35px 10px;
                font-size: 150%;
                white-space: nowrap;
            }

            .progressBar .bar {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background: var(--primary-color);
                width: 0%;
                transition: 15s linear;
            }

            .progressBar .expected {
                position: absolute;
                left: 80%;
                top: 0;
                bottom: 0;
                width: 2px;
                background: var(--attention-color);
            }

            .progressBar .startText {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                text-align: left;
            }

            .progressBar .expectedText {
                position: absolute;
                top: 50%;
                left: 80%;
                transform: translate(-50%, -50%);
            }
        `,
    ];

    override render() {
        return html`
            <component-firebase-remote-config key="appState"
                                              .value="${this.bind.appState}"></component-firebase-remote-config>
            <component-firebase-remote-config key="nextBuildConfig"
                                              .value="${this.bind.buildConfig}"></component-firebase-remote-config>

            <div id="overlay">
                ${this.isOffline ? html`
                    <h2>Aspire competitions offline</h2>
                    <div style="font-size: 20px; ">${this.offlineMessage}</div>
                ` : undefined}
                ${this.isUpdating ? html`
                    <h2>Aspire competitions updating</h2>
                    <div style="font-size: 20px; ">${this.updateMessage}</div>
                    <div style="font-size: 10px">
                        <div class="progressBar">
                            <div class="bar"
                                 style="width: ${this.updateProgressPercentage(this.buildConfig)}"></div>
                            <div class="expected"></div>

                            <div class="startText"
                                 title="Started ${this.calculateUpdateStart(this.buildConfig, false)}">
                                Started <br><br> ${this.calculateUpdateStart(this.buildConfig)}
                            </div>
                            <div class="expectedText"
                                 title="Expected ${this.calculateUpdateExpected(this.buildConfig, false)}">
                                Expected <br><br>${this.calculateUpdateExpected(this.buildConfig)}
                            </div>
                        </div>


                        Tech badger things <br>
                        Version: ${this.buildConfig?.fullVersion} <br>
                        Build: ${this.buildConfig?.buildId} <br>
                        <pre style="display: none">
                            ${JSON.stringify(this.buildConfig?.timing)}
                        </pre>
                    </div>
                ` : undefined}
            </div>
        `;
    }

    calculateUpdateStart(buildConfig: any, ago: boolean = true) {
        if (!buildConfig) return;

        let date = cloneDate(buildConfig.timing._start);
        if (ago) {
            return RenderingHelper._dateFromNow(date);

        } else {
            return RenderingHelper._dateFormat(date, 'dd/MM/yyyy HH:mm');
        }
    }

    calculateUpdateExpected(buildConfig: any, ago: boolean = true) {
        if (!buildConfig) return;

        let date = cloneDate(buildConfig.timing._start);
        date.setSeconds(date.getSeconds() + buildConfig.timing._estimate);

        if (ago) {
            return RenderingHelper._dateFromNow(date);

        } else {
            return RenderingHelper._dateFormat(date, 'dd/MM/yyyy HH:mm');
        }
    }


    @observe('isUpdating', 'isUpdateIncoming', 'updateMessage')
    displayUpdatingToast(isUpdating: boolean, isUpdateIncoming: boolean, updateMessage: string) {
        if (!isUpdateIncoming) return;
        if (isUpdating) return;


        showToast(updateMessage, {dismissable: false});
    }

    override updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);

        if (changedProperties.has('showOverlay')) {
            this.disableBodyScrollOnOverlay(this.showOverlay, changedProperties.get('showOverlay') as boolean);
        }
    }

    async disableBodyScrollOnOverlay(showOverlay: boolean, oldShowOverlay: boolean) {
        oldShowOverlay = oldShowOverlay || false;
        if (showOverlay === oldShowOverlay) return;


        if (showOverlay && !document.getElementById('appStateStyles')) {
            let appStateStyles = document.createElement('style');
            appStateStyles.appendChild(document.createTextNode(`
            html.appStateShown, html.appStateShown body{
                overflow: hidden!important;
            }
            `));
            appStateStyles.textContent = appStateStyles.innerHTML;
            document.head.appendChild(appStateStyles);
        }


        let htmlElement = document.documentElement.classList;
        clearInterval(this._rerenderIntervalId);
        if (showOverlay) {
            htmlElement.add('appStateShown');
            this._rerenderIntervalId = window.setInterval(() => {
                this.requestUpdate();
            }, 15000);

        } else {
            //Cache invalidate
            await fetch('/_/config?callback=populateRemoteConfig', {
                headers: {
                    'Pragma': 'no-cache',
                    'Expires': '-1',
                    'Cache-Control': 'no-cache',
                },
            });
            // @ts-ignore
            location.reload(true);
        }
    }

    @observe('appState')
    loadAppState(appState: any) {
        this.updateMessage = appState?.updateMessage || '';
        this.isUpdateIncoming = !!appState?.isUpdatePending;
        this.isUpdating = !!appState?.isUpdating;
        this.isOffline = !!appState?.isOffline;
        this.offlineMessage = appState?.offlineMessage || '';
    }

    updateProgressPercentage(buildConfig: any) {
        if (!buildConfig) return;

        let start = cloneDate(buildConfig.timing._start);
        let expected = cloneDate(buildConfig.timing._start);
        expected.setSeconds(expected.getSeconds() + buildConfig.timing._estimate);

        let percentage = ((new Date).getTime() - start.getTime()) / (expected.getTime() - start.getTime());
        if (percentage > 1) {
            percentage = 1 + ((percentage - 1) / 4);
        }

        return `${Math.min((percentage * 100) * .8, 100)}%`;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-app-state': ComponentAppState;
    }
}